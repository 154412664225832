import { Box, Button, Grid, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import "./StandardOne.scss";
import * as Yup from "yup";
import React, { forwardRef, useEffect, useImperativeHandle, useContext, useState, useRef } from "react";
import { PreferencesContext } from "../../../PreferenceContext";
import { GetTeamdetail, UpdateTeam, UpdateTeamPurpose } from "../../../services/TeamService";
import { AlertType } from "../../../shared/AlertType";
import { GetProjdetail, GetStatusList, UpdateProj, UpdateProjPurpose } from "../../../services/ProjectService";
import Select from "react-select";
import { getSingleSelected } from "../../../services/common/commonFunction";
import { GetRoledetail, UpdateRole } from "../../../services/RoleService";
import { GetActivitydetail, UpdateActivity } from "../../../services/ActivityService";
import { GetCessdetail, UpdateCess } from "../../../services/CessService";
import { GetHiagramById, UpdateHiagram } from "../../../services/HiagramService";
import { RephraseText } from "../../../services/OpenAIService";
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { CUR_ENV } from "../../../services/common/const";

const formInitialValues = {
    iteamAnswerId: 0,
    itemStatus: "",
    itemValue: "",
    isDeleted: false,
};
type DirectionType = "right" | "left" | "up" | "down";
// export default function StandardOne(param: any, ref: any) {
const StandardOne = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [forminitial, setFormInitial] = useState(formInitialValues);
    const [statusData, setStatusData] = useState([]);
    const [isFocused, setFocused] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [gptAnswers, setGptAnswers] = useState([""]);
    const [curIndex, setCurIndex] = useState(0);
    const [userDescription, setUserDescription] = useState("");
    const [direction, setDirection] = useState<DirectionType>("up");
    const containerRef: any = useRef();
    const mainTheme = useTheme();

    const formValidation = Yup.object().shape({
        itemValue: Yup.string().required(param.standardOneConfig.label + " is required."),
    });
    useImperativeHandle(ref, () => ({
        submitClick() {
            let element: HTMLElement = document.getElementsByClassName('inner-button')[0] as HTMLElement;
            element.click();
        },
        getData() {
            if (param.standardOneConfig.isDropdownVisible === true) {
                fetchStatus();
            }
            fetchData();
        }
    }),);

    async function fetchStatus() {
        try {
            const statusGetData: any = await GetStatusList();
            if (statusGetData !== undefined && statusGetData !== null && statusGetData.data !== null && statusGetData.data.data !== null && statusGetData.data.data.data !== null) {
                setStatusData(statusGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function submitForm(values: any, resetForm: any) {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            try {
                let requestData = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: values.iteamAnswerId,
                    roleUid: userContext.roleId,
                    itemId: userContext.teamProjId,
                    itemStatus: values.itemStatus,
                    itemValue: values.itemValue,
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }
                let data: any;

                if (userContext.isProj.toString() === "false" && +param.curQuestion.number === 2) {
                    data = await UpdateTeam(requestData);

                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                } else if (userContext.isProj.toString() === "true" && (+param.curQuestion.number === 2 || +param.curQuestion.number === 3)) {
                    requestData.questionUid = 4;
                    data = await UpdateProj(requestData);
                    // if (data !== null && data.data === true) {
                    //     param.changeNextQuestion();
                    // }

                    // hp code start
                    if (data !== undefined && data.data !== undefined && data.data.dataTrue === true) {
                        param.changeNextQuestion();
                    } else if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                    // hp code end

                } else if (+param.curQuestion.number === 9 || +param.curQuestion.number === 45) {
                    requestData.itemId = userContext.roleId;
                    data = await UpdateRole(requestData);

                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                } else if (+param.curQuestion.number === 15 || +param.curQuestion.number === 43) {
                    requestData.itemId = userContext.activityId;
                    data = await UpdateActivity(requestData);

                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                } else if (+param.curQuestion.number === 25) {
                    requestData.itemId = userContext.cessId;
                    data = await UpdateCess(requestData);

                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                } else if (+param.curQuestion.number === 38) {
                    requestData.itemId = userContext.hiagramId;
                    data = await UpdateHiagram(requestData);

                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                } else if (userContext.isProj.toString() === "false" && +param.curQuestion.number === 42) {
                    updateUserContext({
                        ...userContext,
                        teamProjPurpose: values.itemValue,
                    });

                    data = await UpdateTeamPurpose(requestData);

                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                } else if (userContext.isProj.toString() === "true" && +param.curQuestion.number === 42) {
                    //requestData.questionUid = 4;
                    updateUserContext({
                        ...userContext,
                        teamProjPurpose: values.itemValue,
                    });
                    data = await UpdateProjPurpose(requestData);

                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                }
                resetForm();
                param.changeNextQuestion();
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            resetForm();
            param.changeNextQuestion();
        }
    }


    async function fetchData() {
        // debugger
        //setShowLoder(isPageloading);
        try {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            let data: any;
            if (userContext.isProj.toString() === "false" && (+param.curQuestion.number === 2 || +param.curQuestion.number === 42)) {
                params.append("quid", param.curQuestion.uid.toString());
                params.append("uid", userContext.teamProjId.toString());
                data = await GetTeamdetail(params);
            } else if (userContext.isProj.toString() === "true" && (+param.curQuestion.number === 2 || +param.curQuestion.number === 3 || +param.curQuestion.number === 42)) {
                if (+param.curQuestion.number === 42) {
                    params.append("quid", "61");
                } else {
                    params.append("quid", "4");
                }
                params.append("uid", userContext.teamProjId.toString());
                data = await GetProjdetail(params);
            } else if (+param.curQuestion.number === 9 || +param.curQuestion.number === 45) {
                params.append("quid", param.curQuestion.uid.toString());
                params.append("uid", userContext.roleId.toString());
                data = await GetRoledetail(params);
            } else if (+param.curQuestion.number === 15 || +param.curQuestion.number === 43) {
                params.append("quid", param.curQuestion.uid.toString());
                params.append("uid", userContext.activityId.toString());
                data = await GetActivitydetail(params);
            } else if (+param.curQuestion.number === 25) {
                params.append("quid", param.curQuestion.uid.toString());
                params.append("uid", userContext.cessId.toString());
                data = await GetCessdetail(params);
            } else if (+param.curQuestion.number === 38) {
                params.append("quid", param.curQuestion.uid.toString());
                data = await GetHiagramById(userContext.hiagramId);
            }

            if (data !== undefined && data !== null && data.data != null && data.data.data != null) {
                let item = data.data.data as any;
                if (+param.curQuestion.number === 38) {
                    let formItem = {
                        iteamAnswerId: 0,
                        itemValue: item.free_text === null ? "" : item.free_text,
                        itemStatus: "",
                        isDeleted: false,
                    };
                    setUserDescription(formItem.itemValue);
                    setFormInitial(formItem);
                } else if (+param.curQuestion.number === 42) {
                    let formItem = {
                        iteamAnswerId: item.questionAnswer.length > 0 ? +item.questionAnswer[0].uid : 0,
                        itemValue: item.purpose === null ? "" : item.purpose,
                        itemStatus: "",
                        isDeleted: false,
                    };
                    setUserDescription(formItem.itemValue);
                    setFormInitial(formItem);
                } else {
                    let formItem = {
                        iteamAnswerId: item.questionAnswer.length > 0 ? +item.questionAnswer[0].uid : 0,
                        itemValue: item.description === null ? "" : item.description,
                        itemStatus: item.proj_status,
                        isDeleted: false,
                    };
                    setUserDescription(formItem.itemValue);
                    setFormInitial(formItem);
                }
            } else {
                setFormInitial(formInitialValues);
            }
            if (+param.curQuestion.number !== 38) {
                if (param.standardOneConfig.isAIVisible === true) {
                    getdefaultDescription(true);
                }
            }

        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        //setShowLoder(false);
    }

    async function getdefaultDescription(isInit: boolean) {
        //setGptAnswer("I'm thinking. . . . .");
        setLoading(true);
        var plainString = "";
        if (+param.curQuestion.number === 2 || +param.curQuestion.number === 6) {
            // plainString = `For a team named "${userContext.teamProjName}" that has the purpose "${userContext.teamProjPurpose}", write me an example description in no more than two paragraphs.`            
            // plainString = `For a team named "${userContext.teamProjName}"  that has the purpose "${userContext.teamProjPurpose}", write me an example description in no more than two paragraphs. Each paragraph should have no more than three sentences.  This is not a sales pitch or job description. I'm handing over my job to the next guy. Please don't just rely on the provided purpose, but use your own knowledge too. Don't talk about the transition or handover itself.`
            if (+userContext.tempUid === 3 && +param.curQuestion.number === 2) {
                // plainString = `For a team named "${userContext.teamProjName}", write me an example description in two paragraphs of what this team does. Each paragraph should have no more than three sentences, with line breaks.This is not a sales pitch or job description. I'm handing over my job to the next guy. Please don't just rely on the my provided information, but use your own knowledge too. Don't talk about the transition or handover itself. Use simple language.`
                plainString = `For a team named "${userContext.teamProjName}", write me an example description in two paragraphs of what this team does. Each paragraph should have no more than three sentences, with line breaks.This is not a sales pitch or job description. I'm handing over my job to the next guy. Please don't just rely on my provided information, but use your own knowledge too. Don't talk about the transition or handover itself. Use simple language.`
            } else {
                plainString = `For a team named "${userContext.teamProjName}" that has the purpose "${userContext.teamProjPurpose}", write me an example description in two paragraphs. Each paragraph should have no more than three sentences. This is not a sales pitch or job description. I'm handing over my job to the next guy. Please don't just rely on the provided purpose, but use your own knowledge too. Don't talk about the transition or handover itself.`
            }
            {/* NP_Doubt */ }
        } else if (+param.curQuestion.number === 9) {
            plainString = `For role named "${userContext.roleName}" within a team named "${userContext.teamProjName}"that has the purpose "${userContext.teamProjPurpose}", write me an example description in no more than two paragraphs.`
        } else if (+param.curQuestion.number === 15) {
            // plainString = `For a role named "${userContext.activityName}" undertaken within a team named "${userContext.teamProjName}", write me an example description for the role in no more than two paragraphs, with line breaks. Use simple language.This is not a sales pitch or job description. I'm handing over my job to the next guy. Please don't just rely on the my provided information, but use your own knowledge too. Don't talk about the transition or handover itself. Use simple language.`;
            plainString = `For a role named "${userContext.activityName}" undertaken within a team named "${userContext.teamProjName}", write me an example description for the role in no more than two paragraphs, with line breaks. Use simple language.This is not a sales pitch or job description. I'm handing over my job to the next guy. Please don't just rely on my provided information, but use your own knowledge too. Don't talk about the transition or handover itself. Use simple language.`;
        } else if (+param.curQuestion.number === 25) {
            plainString = `For a process named "${userContext.cessName}" taking place within a role named "${userContext.activityName}", write me an example description for the process in no more than two paragraphs, with line breaks.`;
        } else if (+param.curQuestion.number === 43) {
            plainString = `For a person undertaking a role named "${userContext.activityName}" within their team named "${userContext.teamProjName}", what are the top 5 non-standard questions you would ask them during a handover? Use simple language.`;
        } else if (+param.curQuestion.number === 45) {
            plainString = `For a person in a role named "${userContext.roleName}" within a team named "${userContext.teamProjName}"that has the purpose "${userContext.teamProjPurpose}", what are the top 5 non-standard questions you would ask them during a handover?`;
        } else if (+param.curQuestion.number === 3) {
            plainString = `You are an employee leaving your organization and writing a handover note for your replacement. You are in a team named "${userContext.teamProjName}" which has the purpose "${userContext.teamProjPurpose}". Write me a helpful description about this team in no more than two paragraphs for my replacement.`;
        }
        // else if (+param.curQuestion.number === 3) {
        //     plainString = `You are an employee leaving your organization and writing a handover note for your replacement. You are in a team named "${userContext.teamProjName}" which has the purpose "${userContext.teamProjPurpose}". Write me a helpful description about this team in no more than two paragraphs for my replacement.`;
        // }

        let requestData = {
            text: plainString
        }

        if (CUR_ENV !== "LOCAL") {
            if (plainString !== "") {
                let data: any = await RephraseText(requestData);
                if (data !== null && data.choices !== null && data.choices.length > 0) {
                    let answers = data.choices;
                    let firstAnswer = answers[0].message.content.replace(/^\s+|\s+$/g, '');
                    //let firstAnswer = answers[0].text;
                    if (isInit) {
                        setGptAnswers([firstAnswer]);
                    } else {
                        setGptAnswers([...gptAnswers, firstAnswer]);
                    }

                } else {
                    // setGptAnswers(["Hello . . ."]);

                }
            }
        }
        else {
            console.log(requestData);
        }
        setLoading(false);
    }

    async function getAIDescription() {
        setLoading(true);
        let requestData = {
            text: `rewrite this description, without losing any detail: ` + userDescription
        }
        if (CUR_ENV !== "LOCAL") {
            let data: any = await RephraseText(requestData);
            if (data !== null && data.choices !== null && data.choices.length > 0) {
                let answers = data.choices;
                let firstAnswer = answers[0].text.replace(/^\s+|\s+$/g, '');
                setGptAnswers([...gptAnswers, firstAnswer]);
            }
        }
        else {
            console.log(requestData);
        }
        setLoading(false);
    }

    function getPreviousDescription() {
        let index = curIndex;
        if (index > 0) {
            setCurIndex(index - 1);
        }
    }

    useEffect(() => {
    }, [forminitial, curIndex]);

    useEffect(() => {
        setCurIndex(gptAnswers.length - 1);
    }, [gptAnswers]);

    return (
        <Formik
            enableReinitialize
            initialValues={forminitial}
            validationSchema={formValidation}
            onSubmit={(values: any, { resetForm }) => {
                submitForm(values, resetForm);
            }}
        >{(props: any) => {
            return (
                <Box className="textfield-one">
                    {/* <Box className="left-box">
                        <Grid container>
                            {param.standardOneConfig.isDropdownVisible === true ?
                                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                                    <Typography className="textfield-label">
                                        {param.standardOneConfig.dropdownLabel}
                                    </Typography>
                                </Grid> : <></>}
                            <Grid item xs={12}>
                                <Typography className="textfield-label">
                                    {param.standardOneConfig.label}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box> */}
                    <Box className="right-box">
                        <Form onSubmit={props.handleSubmit} noValidate style={{ position: 'relative' }}>
                            <Grid container>
                                {param.standardOneConfig.isDropdownVisible === true ?
                                    <Grid item xs={12} style={{ paddingBottom: '20px' }} className="grid-status">
                                        <Typography className="textfield-label">
                                            {param.standardOneConfig.dropdownLabel}
                                        </Typography>
                                        <Select
                                            menuPortalTarget={document.querySelector('body')}
                                            className="status-select"
                                            classNamePrefix="select"
                                            isDisabled={!userContext.isEditAccess}
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder={''}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            value={getSingleSelected(
                                                props.values.itemStatus,
                                                statusData
                                            )}
                                            onChange={(e, value) => {
                                                props.setFieldValue(
                                                    "itemStatus",
                                                    e !== null ? e.value : "",
                                                    false
                                                );
                                            }}
                                            options={statusData}
                                            styles={selectStyles}
                                            // styles={{
                                            //     control: (baseStyles, state) => ({
                                            //         ...baseStyles,
                                            //         background: '#FFFFFF'
                                            //     }),
                                            // }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: mainTheme.palette.primary.main,
                                                    primary25: '#FEF4F4',
                                                    primary50: '#FEF4F4',
                                                },
                                            })}
                                        />
                                    </Grid>
                                    : <></>}
                                <Grid item xs={12} className="grid-description">
                                    <Box className="user-description-box">
                                        <Box className="user-description-upper">
                                            <Typography className="textfield-label">
                                                {param.standardOneConfig.label}
                                            </Typography>
                                            {/* <Button
                                                className="btn-rewrite"
                                                variant="outlined"
                                                startIcon={<OfflineBoltOutlinedIcon />}
                                                onClick={getAIDescription}
                                            >
                                                AI rewrite
                                            </Button> */}
                                        </Box>
                                        <TextField
                                            id="itemValue"
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontFamily: 'Roboto'
                                                }
                                            }}
                                            className="standardone-input"
                                            label={param.standardOneConfig.placeholder}
                                            inputProps={{ maxLength: param.curQuestion.max_length !== null ? (param.standardOneConfig.isWordCount === true ? (props.values.itemValue.split(" ").length > 31 ? props.values.itemValue.length : 1000000) : param.curQuestion.max_length) : 100 }}
                                            fullWidth
                                            multiline={true}
                                            disabled={!userContext.isEditAccess}
                                            rows={param.standardOneConfig.numberOfLines}
                                            placeholder={param.standardOneConfig.placeholder}
                                            onChange={(e) => {
                                                props.setFieldValue(
                                                    "itemValue",
                                                    e !== null ? e.target.value : "",
                                                    true
                                                );
                                                setUserDescription(e.target.value);
                                                //props.handleChange.bind("itemValue");
                                            }}
                                            onBlur={() => setFocused(false)}
                                            value={props.values.itemValue}
                                            onFocus={() => setFocused(true)}
                                            error={
                                                props.values.itemValue === "" && props.errors.itemValue && props.touched.itemValue
                                                    ? true
                                                    : false
                                            }
                                        >
                                        </TextField>
                                        {isFocused && <Typography className="length-counter">{(param.standardOneConfig.isWordCount === true ? (props.values.itemValue !== "" ? props.values.itemValue.match(/(\w+)/g).length : 0) : props.values.itemValue.length) + "/" + (param.curQuestion.max_length !== null ? param.curQuestion.max_length : 100)}</Typography>}
                                        {props.errors.itemValue &&
                                            props.touched.itemValue && (
                                                <Typography
                                                    className="validation-message text-input error">
                                                    {props.errors.itemValue}
                                                </Typography>
                                            )}
                                    </Box>
                                    {param.standardOneConfig.isAIVisible === true && param.loading === false ?
                                        <Box className="gpt-description-box">
                                            <Box className="gpt-description-upper">
                                                <Typography className="textfield-label">
                                                    imprend AI: this is NOT saved
                                                </Typography>
                                                <Box>
                                                    <Tooltip title="Undo">
                                                        <Button
                                                            className="btn-undo"
                                                            variant="outlined"
                                                            disableRipple={true}
                                                            onClick={getPreviousDescription}
                                                        >
                                                            <ReplayIcon sx={{ color: '#a7679b' }} />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Another example">
                                                        <Button
                                                            className="btn-new-ex"
                                                            variant="outlined"
                                                            disableRipple={true}
                                                            onClick={() => getdefaultDescription(false)}
                                                        >
                                                            <OfflineBoltOutlinedIcon sx={{ color: '#a7679b' }} />
                                                        </Button>
                                                    </Tooltip>
                                                    {/* <IconButton color="primary" aria-label="upload picture" component="label">
                                                    <AutorenewIcon />
                                                </IconButton> */}
                                                </Box>
                                            </Box>
                                            {/* <img src={glitter} className="glitter-img"/> */}
                                            {/* <TextField
                                            id="gptAnswer"
                                            className="gpt-answer"
                                            fullWidth
                                            multiline={true}
                                            rows={param.standardOneConfig.numberOfLines}
                                            value={gptAnswer}
                                        >
                                        </TextField> */}

                                            <Typography className="textfield-gpt-label">
                                                {isLoading ? "I'm thinking . . ." : gptAnswers[curIndex]}
                                            </Typography>
                                        </Box>
                                        : <></>}
                                </Grid>
                            </Grid>

                            <Button className="inner-button" variant="contained" type="submit">
                            </Button>
                        </Form>
                    </Box>
                </Box>
            );
        }}
        </Formik>
    );
});

const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#FFFFFF'
    })
};

export default StandardOne;