import { Box, Fade, Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses, useTheme } from '@mui/material';
import * as React from 'react';
import "./FreeUserList.scss";
import { Link, useLocation } from 'react-router-dom';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useContext, useEffect, useState } from 'react';
import { PreferencesContext } from '../../PreferenceContext';
import _ from 'lodash';
import { GetHiagramListByFreeUserId } from '../../services/HiagramService';
import { EncryptDecryptService } from '../../services/common/EncryptionService';
import { CUR_ENV } from '../../services/common/const';

export default function FreeUserList() {
    const { innerHeight: windowHeight } = window;
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = useState(false);
    const [adminClick, setAdminClick] = useState(0);
    const [freeUserHiagramData, setFreeUserHiagramData] = useState([]);
    const [flowDivHeight, setFlowDivHeight] = useState((windowHeight - 144) + "px");
    const [tableHeight, setTableDivHeight] = useState((windowHeight - 344) + "px");
    const mainTheme = useTheme();

    const edService = new EncryptDecryptService();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: mainTheme.palette.primary.main,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            paddingTop: "0px ! important",
            paddingBottom: "0px ! important"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAF9F6",
            paddingTop: "0px ! important",
            paddingBottom: "0px ! important",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
            paddingTop: "0px ! important",
            paddingBottom: "0px ! important"
        },
    }));

    const location = useLocation();

    function formateDate(date: any) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        let curDate = new Date(date);
        let curDay = curDate.getDate();
        let curMonth = monthNames[curDate.getMonth()];
        let curYear = curDate.getFullYear();
        let curHour = curDate.getHours();
        let curMin = curDate.getMinutes();

        return (curMonth + " " + curDay + " " + curYear + " " + String(curHour).padStart(2, '0') + ":" + String(curMin).padStart(2, '0'));
    }

    async function whoClick(loginId: any) {
        let freeClientId = 0;
        if (CUR_ENV === "LOCAL") {
            freeClientId = 14;
            if (+loginId === 33) {
                setAdminClick(loginId)
            } else {
                setAdminClick(userContext.userId)
            }
        }
        else if (CUR_ENV === "PROD") {
            freeClientId = 34;
            if (+loginId === 27) {
                setAdminClick(loginId)
            } else {
                setAdminClick(userContext.userId)
            }
        }
        else if (CUR_ENV === "STAGING") {
            freeClientId = 15;
            if (+loginId === 19) {
                setAdminClick(loginId)
            } else {
                setAdminClick(userContext.userId)
            }
        }


    }


    async function fetchData() {
        let uid = location.pathname.split('/')[2]
        let freeUserHiagramList: any = await GetHiagramListByFreeUserId(+uid);
        let data = freeUserHiagramList.data.data;
        const hiagramList: any = [];
        data.forEach((element: any) => {
            let curUrlOrigin = window.location.origin
            let freeClientId = 0;
            let curLoginUser = userContext.userId
            if (CUR_ENV === "LOCAL") {
                freeClientId = 14;
            }
            else if (CUR_ENV === "PROD") {
                freeClientId = 34;
            }
            else if (CUR_ENV === "STAGING") {
                freeClientId = 15;
            }

            
            let firstStringEncrypt: any = edService.encrypt(userContext.email);
            let encHiahramId: any = edService.encrypt(element.uid);
            let encCurrentClientId: any = edService.encrypt(freeClientId);
            let encCurLoginUserId: any = edService.encrypt(curLoginUser);
            let higramGenratedLink = `${curUrlOrigin}/hiagram/share?u=${encodeURIComponent(firstStringEncrypt)}&h=${encodeURIComponent(encHiahramId)}&cc=${encodeURIComponent(encCurrentClientId)}&&cu=${encodeURIComponent(encCurLoginUserId)}`;

            let item = {
                hiagramName: element.hiagram_name,
                createdDate: element.created_date,
                lastUpdatedDate: element.modified_date,
                linkuse: element.link_use,
                lastAccessed: element.last_accessed,
                hiagramId: element.uid,
                shareLink: higramGenratedLink
            }
            hiagramList.push(item);
        });
        setFreeUserHiagramData(hiagramList)
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [])

    return (<>
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="container-free-user-hiagram">
                <Paper elevation={3}
                    sx={{ borderRadius: '10px', minHeight: '640px' }}
                    className="wrapper free-user-hiagram-paper"
                    style={{ height: flowDivHeight }}
                >
                    <Grid className="list-grid" container>
                        <Grid item xs={4} sx={{ textAlign: "start" }}>
                            {/* <Link className="backspace-Button-Back" to={"/myprofile"}> */}
                            <Link className="backspace-Button-Back" to={"/user"}>
                                <KeyboardBackspaceIcon className="icon-css" />
                                <b>Back</b>
                            </Link>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: "center" }}>
                            <Typography variant='h5' className='user-title'> <b>{decodeURIComponent(location.pathname.split('/')[3])}'s Hiagram</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>

                    <Grid className="line-up"></Grid>

                    <Grid container className="list-grid">
                        <TableContainer
                            sx={{ marginTop: "10px" }}
                            className='tablecontainer'
                            style={{ height: tableHeight }}
                            component={Paper}>
                            <Table stickyHeader sx={{ width: ' 100%' }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Name</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Created</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Last updated</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '15%', fontSize: 13 }}>Last accessed</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}>Link use</StyledTableCell>
                                        <StyledTableCell sx={{ width: '15%', fontSize: 13 }}>Link</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {isLoading === true ? (
                                            _.times(4, (i) => (
                                                <TableRow key={i}>
                                                    <TableCell colSpan={5}>
                                                        <Skeleton variant="rounded" width='auto' height={25} />
                                                    </TableCell>
                                                </TableRow>
                                            ))) : freeUserHiagramData.length > 0 ? (freeUserHiagramData && freeUserHiagramData.map((row: any, index: any) => {
                                                return (
                                                    <StyledTableRow
                                                        sx={{ fontSize: 12, height: '50px' }}
                                                        key={index}>
                                                        <StyledTableCell align="left">
                                                            {row.hiagramName}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {formateDate(row.createdDate)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {formateDate(row.lastUpdatedDate === null ? row.createdDate : row.lastUpdatedDate)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {/* {formateDate(row.lastAccessed === null ? '' : row.lastAccessed)} */}
                                                            {row.lastAccessed === null ? '' : formateDate(row.lastAccessed)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row.linkuse === null ? 0 : row.linkuse}
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            <a href={row.shareLink} target="_blank" style={{ color: "blue", textDecoration: "underline" }} onClick={() => whoClick(userContext.userId)}>
                                                                Click here to view hiagram
                                                            </a>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })) : (
                                            <TableRow key={1}>
                                                <TableCell colSpan={5} align='center'>
                                                    None added yet!
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>
                </Paper>
            </Box>
        </Fade>
    </>)
}