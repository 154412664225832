import * as React from 'react';
import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Fade, Grid, Paper, Skeleton, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./InvitedUser.scss";
import trashIcon from '../../assets/icons/trash.svg'
import { styled, useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { PAGE_SIZE } from '../../services/common/const';
import { PreferencesContext } from "../../PreferenceContext";
import { AlertType } from '../../shared/AlertType';
import { DeleteInviteUser, GetInvitedUserList, ReinviteUser } from '../../services/InviteService';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#FAF9F6",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const parameters: any = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
};

export default function InvitedUser() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = React.useState(false);
    const [isBtnLoading, setBtnLoading] = React.useState(false);
    const [param, setParam] = useState(parameters);
    const [invitedUserData, setInvitedUserData] = useState([]);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [curUserId, setCurUserId] = React.useState(0);
    const [openUserDeleteModal, setUserDeleteModal] = React.useState(false);

    const handleOpenUserDeleteModal = (id: number) => {
        setUserDeleteModal(true);
        setCurUserId(id);
    };

    const handleCloseUserDeleteModal = (load: boolean) => {
        setUserDeleteModal(false);
        if (load) {
            fetchInvitedUserList();
        }
    };

    // INVITED USER LIST BINDING
    async function fetchInvitedUserList() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("pageIndex", param.pageIndex);
            params.append("pageSize", param.pageSize);
            const result: any = await GetInvitedUserList(userContext.clientId, params);

            if (result.data.data != null) {
                let invitedUsersDataRows = result.data.data.data;
                invitedUsersDataRows.forEach((data: any) => {
                    data.isloading = false;
                });
                setInvitedUserData(invitedUsersDataRows);
                let totalRecords = result.data.data.total_data_count;
                setTotalRecords(totalRecords);
            }
        } catch (e: any) {
            //API error
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.message,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    async function deleteUser() {
        setBtnLoading(true);
        try {
            const user: any = await DeleteInviteUser(curUserId, {
                isDeleted: true,
                dataManagedBy: userContext.userId
            });
            if (user.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: user.message,
                    alertType: AlertType.Success,
                });
                handleCloseUserDeleteModal(true);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function reinviteUser(id: any, index: any) {
        let data: any = invitedUserData;
        data[index].isloading = true;
        setInvitedUserData(data);
        try {
            const user: any = await ReinviteUser(id);

            if (user !== undefined && user !== null && user.data !== null && user.data.data !== null && user.data.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: user.data.message,
                    alertType: AlertType.Success,
                });
            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Somthing went wrong!",
                    alertType: AlertType.Error,
                });
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        data[index].isloading = false;
        setInvitedUserData(data);
    }

    useEffect(() => {
        fetchInvitedUserList();
    }, []);

    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box className="inviteduser-container">
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: "10px" }}
                        className="inviteduser-wrapper"
                    >
                        <Grid className="list-grid" container>
                            <Grid item xs={4} sx={{ textAlign: "start" }}>
                                {/* <Link className="backspace-Button-Back" to={"/myprofile"}> */}
                                {/* <Link className="backspace-Button-Back" to={"/all-hiagram"}> */}
                                <Link className="backspace-Button-Back" to={"/dashboard"}>
                                    <KeyboardBackspaceIcon className="icon-css" />
                                    <b>Back</b>
                                </Link>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "center" }}>
                                <Typography variant='h5' className='inviteduser-title'> <b>Invited users</b></Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                        </Grid>

                        <Grid className="line-up"></Grid>

                        <Grid container className="list-grid">
                            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ width: '5%' }}>#</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '40%', fontSize: 13 }}>Email</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '30%', fontSize: 13 }}>Role</StyledTableCell>
                                            <StyledTableCell align="center" sx={{ width: '30%', fontSize: 13 }}>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <>
                                            {isLoading === true ? (
                                                _.times(5, (i) => (
                                                    <TableRow key={i} >
                                                        <TableCell colSpan={4}>
                                                            <Skeleton variant="rounded" animation="wave" width='auto' height={25} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                invitedUserData.length > 0 ? (
                                                    invitedUserData.map((row: any, index: any) => (
                                                        <StyledTableRow
                                                            key={index}
                                                        >
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {row.INVITED_EMAIL}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {row.INVITATION_ROLE}
                                                            </StyledTableCell>

                                                            <StyledTableCell>
                                                                {/* <Grid container spacing={2}> */}
                                                                <Box className='btn-box'>
                                                                    <Tooltip title='Reinvite'>
                                                                        <LoadingButton
                                                                            variant='contained'
                                                                            className='reinvite-btn'
                                                                            loadingPosition='center'
                                                                            loading={row.isloading}
                                                                            onClick={() => reinviteUser(row.UID, index)}
                                                                        >
                                                                            Reinvite

                                                                        </LoadingButton>
                                                                    </Tooltip>
                                                                    <Tooltip title='Delete'>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='delete-btn'
                                                                            onClick={() => handleOpenUserDeleteModal(row.UID)}
                                                                        >
                                                                            <img className='delete-icon' src={trashIcon} />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Box>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))) : (
                                                    <TableRow key={1}>
                                                        <TableCell colSpan={4} align='center'>
                                                            None added yet!
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Paper>
                </Box>
            </Fade>
            {/* delete modal start */}
            <Dialog
                open={openUserDeleteModal}
                onClose={() => handleCloseUserDeleteModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-invited-user-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='contained'
                        onClick={() => deleteUser()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        onClick={() => handleCloseUserDeleteModal(false)}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {/* delete modal end */}
        </>)
}