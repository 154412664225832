import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetTypeList = () =>
    GET(`${BASE_URL}/action/types?`, null);

export const GetStatusList = () =>
    GET(`${BASE_URL}/action/status?`, null);

export const CreateAction = (data: any) =>
    POST(`${BASE_URL}/action/create`, data);

export const UpdateAction = (data: any) =>
    POST(`${BASE_URL}/action/update`, data);

export const GetActionList = (params: any) =>
    GET(`${BASE_URL}/action/list?` + params, null);

export const GetActionDetail = (params: any) =>
    GET(`${BASE_URL}/action/getdetail?` + params, null);

export const DeleteAction = (id: any, data: any) =>
    DELETE(`${BASE_URL}/action/` + id, data);

export const GetQuestionAnswer = (params: any) =>
    GET(`${BASE_URL}/action/getquestionanswer?` + params, null);

export const CreateQusAnswer = (data: any) =>
    POST(`${BASE_URL}/action/createqusanswer`, data);

export const CreateActionMapping = (data: any) =>
    POST(`${BASE_URL}/action/createmapping`, data);

export const GetAllActionList = (params: any) =>
    GET(`${BASE_URL}/action/allactionlist?` + params, null);

export const GetHiagramActionList = (params: any) =>
    GET(`${BASE_URL}/action/hiagramallactionlist?` + params, null);