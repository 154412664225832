import React, { memo, useContext, useState } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import cx from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './NodeTypes.module.css';
import profile from '../../assets/icons/profile.svg';
// import Project from '../../assets/icons/note-project.svg';
// import Role from '../../assets/icons/tick-role.svg';
// import Activity from '../../assets/icons/brifecase-activity.svg';
// import Process from '../../assets/icons/process-hiagram.svg';
import peopleTeam from '../../assets/icons/people-team.svg';
import Project from '../../assets/icons/hiagramnode-project.svg';
import Activity from '../../assets/icons/hiagramnode-role.svg';
// import Role from '../../assets/icons/hiagramnode-activity.svg';
import Process from '../../assets/icons/hiagramnode-process.svg';
// import Event from '../../assets/icons/events.svg'
import TRG from '../../assets/icons/bookmark.svg'
import eye from '../../assets/icons/eye.svg'
// import RoleCount from '../../assets/icons/role-bg.svg';
// import ActivityCount from '../../assets/icons/activity-bg.svg';
// import ProcessCount from '../../assets/icons/process-bg.svg';
// import ActivityCount from '../../assets/icons/hiagramnode-role.svg';
// import RoleCount from '../../assets/icons/hiagramnode-activity.svg';
import RoleCount from '../../assets/icons/hiagramnode-role.svg';
import ProcessCount from '../../assets/icons/hiagramnode-process.svg';
// import BreifIcon from '../../assets/icons/card-role-icon.svg'
import StepCount from '../../assets/icons/step-bg.svg';
import Add from '../../assets/icons/add-black.svg'
// import Edit from '../../assets/icons/edit.svg'
// import DeleteIcon from '../../assets/icons/trash-step.svg'
import AssetIcon from '../../assets/icons/autonio.svg'
// import ActionIcon from '../../assets/icons/action-sq.svg'
import ActionIcon from '../../assets/icons/action-wb.svg';
import DocIcon from '../../assets/icons/clipboard-text.svg'
import EventIcon from '../../assets/icons/calendar.svg'
import HintIcon from '../../assets/icons/information.svg'
import StakeIcon from '../../assets/icons/person-2.svg'
import { Box, Button, Card, Dialog, Grid, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useFocusNode from '../../hooks/useFocusNode';
import ClearIcon from '@mui/icons-material/Clear';
// import RoleDetail from '../CardDetail/RoleDetail/RoleDetail';
import ProcessDetail from '../CardDetail/ProcessDetail/ProcessDetail';
import ActivityDetail from '../CardDetail/ActivityDetail/ActivityDetail';
import TeamDetail from '../CardDetail/TeamDetail/TeamDetail';
import ProjectDetail from '../CardDetail/ProjectDetail/ProjectDetail';
import { PreferencesContext } from '../../PreferenceContext';
import { useNodeOpenMenu } from '../../hooks/useNodeOpenMenu';
import { useNavigate } from 'react-router-dom';
import HiagramDetail from '../CardDetail/HiagramDetail/HiagramDetail';

const HiagramNode = ({ id, data }: NodeProps) => {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isVisibaleCount, setIsVisibaleCount] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  // const [isExpanded, setIsExpanded] = useState(false);
  const SelectNode = useFocusNode(id);
  const setCloseMenu = useNodeOpenMenu(id, false);
  const setMenu = useNodeOpenMenu(id);
  let navigate = useNavigate();

  const handleOpenInfoModal = () => {
    // if (id !== "0") {
    SelectNode();
    if (!(+userContext.tempUid === 3 && data.nodeType === "Role")) {
      setOpenInfoModal(true);
    }

    // }
  };

  const handleCloseInfoModal = (isUpdate: any) => {
    setOpenInfoModal(false);
    setCloseMenu();
    SelectNode();
    if (isUpdate === true) {
      updateUserContext({
        ...userContext, isDataUpdated: true
      });
    }
  };

  const ClosePopupMenu = () => {
    setOpenInfoModal(false);
    setCloseMenu();
    SelectNode();
  }

  const openCloseMenu = () => {
    if (!(+userContext.tempUid === 3 && data.nodeType === "Role")) {
      setMenu();
    }
    SelectNode();
  };

  function addTeam() {
    let lastQuestionData: any = {
      questionUid: 1,
      totalTeamProj: 0,
      totalRole: 0,
      teamProjName: "",
      teamProjPurpose: "",
      teamProjId: 0,
      isProj: "false",
      roleId: 0,
      roleName: "",
      activityId: 0,
      activityName: "",
      cessId: 0,
      cessName: "",
      stepId: 0,
      stepName: ""
    };
    updateUserContext({
      ...userContext,
      lastQuestionInfo: lastQuestionData,
      isComeFromHiagram: true
    });
    navigate("/question/" + userContext.hiagramId);
  }

  function addProject() {
    let lastQuestionData: any = {
      questionUid: 3,
      totalTeamProj: 0,
      totalRole: 0,
      teamProjName: "",
      teamProjPurpose: "",
      teamProjId: 0,
      isProj: "false",
      roleId: 0,
      roleName: "",
      activityId: 0,
      activityName: "",
      cessId: 0,
      cessName: "",
      stepId: 0,
      stepName: ""
    };
    updateUserContext({
      ...userContext,
      lastQuestionInfo: lastQuestionData
    });
    navigate("/question/" + userContext.hiagramId);
  }

  function addActivityFromTeam() {
    let lastQuestionData: any = {
      questionUid: 68,
      totalTeamProj: 0,
      totalRole: 0,
      teamProjName: data.title,
      teamProjPurpose: '',
      teamProjId: data.dataId.split('-')[1],
      isProj: false,
      roleId: data.allData.roles[0].roleId,
      roleName: data.allData.roles[0].roleName,
      activityId: 0,
      activityName: "",
      cessId: 0,
      cessName: "",
      stepId: 0,
      stepName: ""
    };
    updateUserContext({
      ...userContext,
      lastQuestionInfo: lastQuestionData
    });
    navigate("/question/" + userContext.hiagramId);
  }

  function addActivityFromProj() {
    let lastQuestionData: any = {
      questionUid: 68,
      totalTeamProj: 0,
      totalRole: 0,
      teamProjName: data.title,
      teamProjPurpose: '',
      teamProjId: data.dataId.split('-')[1],
      isProj: true,
      roleId: data.allData.roles[0].roleId,
      roleName: data.allData.roles[0].roleName,
      activityId: 0,
      activityName: "",
      cessId: 0,
      cessName: "",
      stepId: 0,
      stepName: ""
    };
    updateUserContext({
      ...userContext,
      lastQuestionInfo: lastQuestionData
    });
    navigate("/question/" + userContext.hiagramId);
  }
  // function addRole() {
  //   let lastQuestionData: any = {
  //     questionUid: 13,
  //     totalTeamProj: 0,
  //     totalRole: 0,
  //     teamProjName: data.allData.parentData.teamProjName,
  //     teamProjPurpose: data.allData.parentData.teamProjPurpose,
  //     teamProjId: data.allData.parentData.teamProjId,
  //     isProj: data.allData.parentData.isProj,
  //     roleId: 0,
  //     roleName: "",
  //     activityId: 0,
  //     activityName: "",
  //     cessId: 0,
  //     cessName: "",
  //     stepId: 0,
  //     stepName: ""
  //   };
  //   updateUserContext({
  //     ...userContext,
  //     lastQuestionInfo: lastQuestionData
  //   });
  //   navigate("/question/" + userContext.hiagramId);
  // }

  function addActivity() {
    let lastQuestionData: any = {
      // questionUid: 21,
      questionUid: +userContext.tempUid === 3 ? 68 : 21,
      totalTeamProj: 0,
      totalRole: 0,
      teamProjName: data.allData.parentData.teamProjName,
      teamProjPurpose: data.allData.parentData.teamProjPurpose,
      teamProjId: data.allData.parentData.teamProjId,
      isProj: data.allData.parentData.isProj,
      roleId: data.allData.parentData.roleId,
      roleName: data.allData.parentData.roleName,
      activityId: 0,
      activityName: "",
      cessId: 0,
      cessName: "",
      stepId: 0,
      stepName: ""
    };
    updateUserContext({
      ...userContext,
      lastQuestionInfo: lastQuestionData
    });
    navigate("/question/" + userContext.hiagramId);
  }

  function addProcess() {
    let lastQuestionData: any = {
      questionUid: 33,
      totalTeamProj: 0,
      totalRole: 0,
      teamProjName: data.allData.parentData.teamProjName,
      teamProjPurpose: data.allData.parentData.teamProjPurpose,
      teamProjId: data.allData.parentData.teamProjId,
      isProj: data.allData.parentData.isProj,
      roleId: data.allData.parentData.roleId,
      roleName: data.allData.parentData.roleName,
      activityId: data.allData.parentData.activityId,
      activityName: data.allData.parentData.activityName,
      cessId: 0,
      cessName: "",
      stepId: 0,
      stepName: ""
    };
    updateUserContext({
      ...userContext,
      lastQuestionInfo: lastQuestionData
    });
    navigate("/question/" + userContext.hiagramId);
  }

  function addProcessFromActivity() {
    let lastQuestionData: any = {
      questionUid: 33,
      totalTeamProj: 0,
      totalRole: 0,
      teamProjName: data.allData.parentData.teamProjName,
      teamProjPurpose: data.allData.parentData.teamProjPurpose,
      teamProjId: data.allData.parentData.teamProjId,
      isProj: data.allData.parentData.isProj,
      roleId: data.allData.parentData.roleId,
      roleName: data.allData.parentData.roleName,
      activityId: data.dataId.split('-')[1],
      activityName: data.title,
      cessId: 0,
      cessName: "",
      stepId: 0,
      stepName: ""
    };
    updateUserContext({
      ...userContext,
      lastQuestionInfo: lastQuestionData
    });
    navigate("/question/" + userContext.hiagramId);
  }
  
  return (
    //NEW CODE
    <>
      <div className={cx(styles.hiagramnodebox)}>
        {data.isMenuOpen ?
          <Paper sx={{ zIndex: 99, position: 'absolute', backgroundColor: '#fff', bottom: 82, right: 32, width: '140px', borderRadius: '10px 10px 0px 10px' }}>
            <MenuList>

              <MenuItem onClick={ClosePopupMenu} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <Box sx={{ display: 'flex' }}>
                  {/* <img loading="lazy" alt="imprend" src={eye} style={{ height: 'auto', width: '14px' }} /> */}
                  <ClearIcon sx={{ height: "15px", width: "15px", color: "#292D32" }} />
                </Box>
              </MenuItem>
              <MenuItem onClick={handleOpenInfoModal}>
                <Box sx={{ display: 'flex', }}>
                  <img loading="lazy" alt="imprend" src={eye} style={{ height: 'auto', width: '14px' }} />
                  <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                    Quick view
                  </Typography>
                </Box>
              </MenuItem>
              {data.nodeType === "Team" ?
                <>
                  <MenuItem onClick={addTeam}>
                    <Box sx={{ display: 'flex', }}>
                      <img loading="lazy" alt="imprend" src={Add} style={{ height: 'auto', width: '14px' }} />
                      <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                        Add another team
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={addActivityFromTeam}>
                    <Box sx={{ display: 'flex', }}>
                      <img loading="lazy" alt="imprend" src={Add} style={{ height: 'auto', width: '14px' }} />
                      <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                        Add another role
                      </Typography>
                    </Box>
                  </MenuItem>
                </> :
                data.nodeType === "Project" ?
                  <>
                    <MenuItem onClick={addProject}>
                      <Box sx={{ display: 'flex', }}>
                        <img loading="lazy" alt="imprend" src={Add} style={{ height: 'auto', width: '14px' }} />
                        <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                          Add another project
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={addActivityFromProj}>
                      <Box sx={{ display: 'flex', }}>
                        <img loading="lazy" alt="imprend" src={Add} style={{ height: 'auto', width: '14px' }} />
                        <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                          Add another role
                        </Typography>
                      </Box>
                    </MenuItem>
                  </> :
                  // data.nodeType === "Role" ?
                  //   <MenuItem onClick={addRole}>
                  //     <Box sx={{ display: 'flex', }}>
                  //       <img loading="lazy" alt="imprend" src={Add} style={{ height: 'auto', width: '14px' }} />
                  //       <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                  //         Add another role
                  //       </Typography>
                  //     </Box>
                  //   </MenuItem> :
                  data.nodeType === "Activity" ?
                    <>
                      <MenuItem onClick={addActivity}>
                        <Box sx={{ display: 'flex', }}>
                          <img loading="lazy" alt="imprend" src={Add} style={{ height: 'auto', width: '14px' }} />
                          <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                            {/* Add another activity */}
                            Add another role
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem onClick={addProcessFromActivity}>
                        <Box sx={{ display: 'flex', }}>
                          <img loading="lazy" alt="imprend" src={Add} style={{ height: 'auto', width: '14px' }} />
                          <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                            Add another process
                          </Typography>
                        </Box>
                      </MenuItem>
                    </> :
                    data.nodeType === "Process" ?
                      <MenuItem onClick={addProcess}>
                        <Box sx={{ display: 'flex', }}>
                          <img loading="lazy" alt="imprend" src={Add} style={{ height: 'auto', width: '14px' }} />
                          <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                            Add another process
                          </Typography>
                        </Box>
                      </MenuItem>
                      : <></>}
            </MenuList>
          </Paper>
          : <></>}
        <div className={cx(styles.hiagramdiv)}>
          <Card
            className={cx(styles.hiagramcomponent)}
            sx={{ backgroundColor: '#FAF9F6', padding: "2px" }}
            elevation={0}
            // onClick={handleOpenInfoModal}
            onMouseEnter={() => {
              if (data.config.isHoverable === true) { setIsVisibaleCount(true) }
            }}
            onMouseLeave={() => {
              if (data.config.isHoverable === true) { setIsVisibaleCount(false) }
            }}
          >
            {isVisibaleCount ?
              <Card
                className={cx(styles.hiagramtopcount)}
                sx={{ borderRadius: '10px 10px 0px 0px', boxShadow: 'none', marginTop: '2px', marginRight: '2px' }}
              >
                <>
                  <Grid container sx={{ marginTop: '1px' }}>
                    <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 8px' }} xs={12}>
                      <img loading="lazy" alt="imprend" style={{ height: '17px', width: '17px' }} src={
                        data.nodeType === "Team" ? RoleCount :
                          data.nodeType === "Project" ? RoleCount :
                            // data.nodeType === "Role" ? ActivityCount :
                            data.nodeType === "Activity" ? ProcessCount :
                              data.nodeType === "Process" ? StepCount : RoleCount
                      } />
                      <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                        {
                          data.nodeType === "Team" ? data.childrenCount + " roles" :
                            data.nodeType === "Project" ? data.childrenCount + " roles" :
                              // data.nodeType === "Role" ? data.childrenCount + " jobs" :
                              data.nodeType === "Activity" ? data.childrenCount + " process" :
                                data.nodeType === "Process" ? data.allData.steps + " steps" : ""
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              </Card> :
              <>
                <Card
                  className={cx(styles.hiagramtopcountempty)}
                  sx={{ borderRadius: '0px', backgroundColor: '#FAF9F6', boxShadow: 'none' }}
                ></Card></>}
            <Card
              className={cx(styles.hiagramdetail)}
              sx={{ backgroundColor: data.config.bgColor, borderRadius: '10px 0px 0px 0px' }}
              onClick={handleOpenInfoModal}
            >
              <Grid container sx={{ padding: '5px' }}>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', height: '45px' }}>
                  <Typography className={cx(styles.hiagramiconbox)}>
                    <img loading="lazy" alt="imprend" className={cx(styles.hiagramicon)}
                      src={data.nodeType === "Team" ? peopleTeam :
                        data.nodeType === "Team" ? peopleTeam :
                          data.nodeType === "Project" ? Project :
                            // data.nodeType === "Role" ? Role :
                            data.nodeType === "Activity" ? Activity :
                              data.nodeType === "Process" ? Process : profile} />
                  </Typography>
                </Grid>
                <Grid item xs={9} className={cx(styles.hiagramdetailright)} >
                  <Typography className={cx(styles.hiagramtext)}
                    sx={{ fontSize: "12px", paddingLeft: "5px", width: "160px", maxHeight: "34px", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {data.title}
                  </Typography>
                  <Typography
                    className={cx(styles.hiagrammenu)}
                  >
                    {/* {data.config.isAccess === true ?
                      <MoreVertIcon
                        sx={{ color: "#ffffff" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          openCloseMenu();
                        }}
                      /> : <></>} */}

                    {/* // hp change start 11/06 */}
                    <MoreVertIcon
                      sx={{ color: "#ffffff" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        openCloseMenu();
                      }}
                    />
                    {/* // hp change end 11/06 */}

                  </Typography>
                </Grid>
              </Grid>
            </Card>
            {isVisibaleCount ?
              <Card
                className={cx(styles.hiagramdetailcount)}
                sx={{ borderRadius: '0px 0px 10px 10px', boxShadow: 'none' }}
              >
                <>
                  <Grid container sx={{ marginTop: '3px', justifyContent: 'space-around' }}>


                    {/* {+userContext.tempUid === 3 && (data.nodeType === "Team" || data.nodeType === "Project") ? <></> : (data.allData.stakes >= 0 ?
                      <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={StakeIcon} />
                        <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                          {data.allData.stakes}
                        </Typography>
                      </Grid> : <></>)} */}

                    {/* 04-06 Manny change start */}

                    {+userContext.tempUid !== 3 ? <></> : (data.allData.stakes >= 0 ?
                      <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={StakeIcon} />
                        <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                          {data.allData.stakes}
                        </Typography>
                      </Grid> : <></>)}
                    {/* 04-06 Manny change end */}


                    {+userContext.tempUid === 3 && (data.nodeType === "Team" || data.nodeType === "Project" || data.nodeType === "Role") ? <></> : (data.allData.docs >= 0 ?
                      <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={DocIcon} />
                        <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                          {data.allData.docs}
                        </Typography>
                      </Grid> : <></>)}
                    {+userContext.tempUid === 3 && (data.nodeType === "Team" || data.nodeType === "Project") ? <></> : (data.allData.hints >= 0 ? <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                      <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={HintIcon} />
                      <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                        {data.allData.hints}
                      </Typography>
                    </Grid> : <></>)}
                    {/* {data.allData.trgs >= 0 ? <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={TRG} />
                      <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                        {data.allData.trgs}
                      </Typography>
                    </Grid> : <></>}
                    {data.allData.assets >= 0 ? <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={AssetIcon} />
                      <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                        {data.allData.assets}
                      </Typography>
                    </Grid> : <></>} */}

                    {/* hp code start */}

                    {+userContext.tempUid === 3 || data.nodeType === "Process" ? <></> : (data.allData.trgs >= 0 ? <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={TRG} />
                      <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                        {data.allData.trgs}
                      </Typography>
                    </Grid> : <></>)}

                    {+userContext.tempUid === 3 ? <></> : (data.allData.assets >= 0 ? <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={AssetIcon} />
                      <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                        {data.allData.assets}
                      </Typography>
                    </Grid> : <></>)}

                    {/* hp code end */}

                    {data.allData.actions >= 0 ? <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={ActionIcon} />
                      <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                        {data.allData.actions}
                      </Typography>
                    </Grid> : <></>}
                    {data.allData.events >= 0 ? <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img loading="lazy" alt="imprend" style={{ height: 'auto', width: '17px' }} src={EventIcon} />
                      <Typography sx={{ fontSize: '10px', paddingLeft: '5px' }} >
                        {data.allData.events}
                      </Typography>
                    </Grid> : <></>}
                  </Grid>
                </>
              </Card> :
              <>
                <Card
                  className={cx(styles.hiagramdetailcountempty)}
                  sx={{ borderRadius: '0px', backgroundColor: '#FAF9F6', boxShadow: 'none' }}
                ></Card>
              </>}
          </Card>
          {data.childrenCount > 0 ?
            <Card
              className={cx(styles.hiagrambuttondiv)}
              sx={{ backgroundColor: data.config.bgColor, boxShadow: 'none' }}
            >
              <Button
                className={cx(styles.hiagrambutton)}
                defaultValue={id}
                onClick={(e) => {
                  e.detail = +id;
                  data.handleExpand(e)
                }}
                sx={{ width: '5px', height: '5px' }}
                startIcon={(data.config.isAllExpanded === true || +id === 0) ? <RemoveIcon sx={{ color: "#fff", height: '16px' }} /> : <AddIcon sx={{ color: "#fff", height: '16px' }} />}>
              </Button>
            </Card>
            : <Card
              className={cx(styles.hiagrambuttondiv)}
              sx={{ backgroundColor: "#FAF9F6", boxShadow: 'none' }}
            ></Card>}
        </div>
        <Handle className={styles.handle} type="target" position={Position.Top} isConnectable={false} />
        <Handle className={styles.handle} type="source" position={Position.Bottom} isConnectable={false} />
      </div >
      {openInfoModal === true ?
        <Dialog
          open={openInfoModal}
          fullWidth={true}
          //TransitionComponent={Transition}
          keepMounted
          // maxWidth="xl"
          maxWidth={data.nodeType === "Hiagram" ? "md" : "xl"}
          scroll="body"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='hiagram-all-node-dialog'
        >
          <Box className='detail-dialog'>
            {/* <Box sx={{ fontSize: '24px', fontWeight: '700', margin: '15px 30px 0px 30px', padding: '10px', borderBottom: '2px solid #CACACA', display: 'flex', justifyContent: 'space-between' }}>
            <Box>{data.nodeType} : {data.title}</Box>
            <Box className='clear-icon-box'>
              <Button
                disableRipple
                className="btn-cancel"
                onClick={handleCloseInfoModal}
              >
                <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
              </Button>
            </Box>
          </Box>
          <DialogContent sx={{ marginBottom: '30px', padding: '0px' }}>
            <Grid container spacing={2} sx={{ margin: '10px' }}>
              <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
                  Name :
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ marginTop: '3px' }}>
                  {data.title}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', alignItems: 'start', justifyContent: 'end' }}>
                <Typography sx={{ marginTop: '5px', fontSize: '16px', fontWeight: '700' }}>
                  Description :
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ marginTop: '8px' }}>
                  {data.allData.description}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent> */}
            {data.nodeType === "Hiagram" ? <HiagramDetail onClose={(update: any) => handleCloseInfoModal(update)} /> :
              data.nodeType === "Team" ? <TeamDetail onClose={(update: any) => handleCloseInfoModal(update)} dataId={data.dataId} /> :
                data.nodeType === "Project" ? <ProjectDetail onClose={(update: any) => handleCloseInfoModal(update)} dataId={data.dataId} /> :
                  // data.nodeType === "Role" ? <RoleDetail onClose={(update: any) => handleCloseInfoModal(update)} dataId={data.dataId} /> :
                  data.nodeType === "Activity" ? <ActivityDetail onClose={(update: any) => handleCloseInfoModal(update)} dataId={data.dataId} /> :
                    data.nodeType === "Process" ? <ProcessDetail onClose={(update: any) => handleCloseInfoModal(update)} dataId={data.dataId} /> : <></>}

            {/* <ProjectDetail onClose={handleCloseInfoModal} /> */}
            {/* <RoleDetail onClose={handleCloseInfoModal} /> */}
            {/* <ActivityDetail onClose={handleCloseInfoModal}/> */}
            {/* <ProcessDetail onClose={handleCloseInfoModal}/> */}
          </Box>
        </Dialog>
        : <></>
      }
    </>
  );
};

export default memo(HiagramNode);
