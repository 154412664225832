import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetTypeList = () =>
    GET(`${BASE_URL}/asset/types?`, null);

export const CreateAsset = (data: any) =>
    POST(`${BASE_URL}/asset/create`, data);

export const UpdateAsset = (data: any) =>
    POST(`${BASE_URL}/asset/update`, data);

export const GetAssetList = (params: any) =>
    GET(`${BASE_URL}/asset/list?` + params, null);

export const GetAssetDetail = (params: any) =>
    GET(`${BASE_URL}/asset/getdetail?` + params, null);

export const DeleteAsset = (id: any, data: any) =>
    DELETE(`${BASE_URL}/asset/` + id, data);

export const GetQuestionAnswer = (params: any) =>
    GET(`${BASE_URL}/asset/getquestionanswer?` + params, null);

export const CreateQusAnswer = (data: any) =>
    POST(`${BASE_URL}/asset/createqusanswer`, data);

export const CreateAssetMapping = (data: any) =>
    POST(`${BASE_URL}/asset/createmapping`, data);

export const GetAllAssetList = (params: any) =>
    GET(`${BASE_URL}/asset/allassetlist?` + params, null);

export const GetHiagramAssetList = (params: any) =>
    GET(`${BASE_URL}/asset/hiagramallassetlist?` + params, null);