import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactFlow, { ProOptions, Edge, useReactFlow, MarkerType, useViewport, useOnSelectionChange } from "reactflow";
import nodeTypes from '../NodeTypes';
import useAnimatedNodes from '../../hooks/useAnimatedNodes';
import { hierarchy as d3Hierarchy, tree as d3Tree } from 'd3-hierarchy';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PreferencesContext } from '../../PreferenceContext';

//React Flow
const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

type DataType = {
    name: string;
    dataId: string;
    colourType: string;
    expanded?: boolean;
    allexpanded?: boolean;
    unexpanded?: boolean;
    type: string;
    isHover: boolean;
    isAccess: boolean;
    data?: any
    id?: string;
    children?: any;
};

type EdgeType = {
    id: string;
    source: string | undefined;
    target: string | undefined;
    type: string;
    style: {
        stroke: string;
        strokeDasharray: number;
    };
    markerEnd: {
        type: MarkerType;
        color: string;
        height: number;
        width: number;
    };
}
let initialEdge: EdgeType;

export default function HiagramFlowComponent(props: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [hierarchy, setHierarchy] = useState(d3Hierarchy<DataType>(props.hiagramData));
    const mainTheme = useTheme();
    const layout = d3Tree<DataType>().nodeSize([350, 180]);
    let navigate = useNavigate();

    function gotoQuestions() {
        navigate("/question/" + userContext.hiagramId);
    }

    const expandAllData = (children: any) => {
        children?.forEach((child: any) => {
            child.data.expanded = true;
            child.data.allexpanded = true;
            child.children = child.data.children;
            if (child.children !== null) {
                expandAllData(child.children);
            }
        });
    }

    function getElements(handleNodeClick: any, type: any) {
        let tempHierarchy = hierarchy;
        if (type === true) {
            tempHierarchy.descendants().forEach((d: any, i: any) => {
                d.data.expanded = d.data.dataId === "0" ? true : false;
                d.data.unexpanded = false;
                d.data.id = `${i}`;
                d.data.children = d.children;
                // d.data.name = d.data.name;
                // d.data.colourType = d.data.colourType;
                d.data.allexpanded = false;
                d.children = undefined;
            });
        }

        // tempHierarchy.descendants().forEach((d: any, i: any) => {
        //     d.children = d.data.expanded ? d.data.children : null;
        // });

        //for new logic
        tempHierarchy.descendants().forEach((d: any, i: any) => {
            let expandCount = 0;
            if (d.data.children !== undefined && d.data.children !== null) {
                d.data.children.forEach((c: any, i: any) => {
                    if (c.data.expanded === false) {
                        expandCount++;
                    }
                });
                if (expandCount === d.data.children.length && d.data.expanded) {
                    d.data.allexpanded = true;
                } else {
                    d.data.allexpanded = false;
                }
            } else {
                if (d.children !== undefined && d.children !== null) {
                    d.children.forEach((c: any, i: any) => {
                        if (c.data.expanded === false) {
                            expandCount++;
                        }
                    });
                    if (expandCount === d.children.length && d.data.expanded) {
                        d.data.allexpanded = true;
                    } else {
                        d.data.allexpanded = false;
                    }
                }
            }

            d.children = d.data.expanded ? d.data.unexpanded ? d.children : d.data.children : null;
        });

        const root = layout(tempHierarchy);

        const nodes = root.descendants().map((d) => ({
            id: d.data.id,
            data: {
                title: d.data.name,
                depth: d.depth,
                handleExpand: handleNodeClick,
                config: {
                    bgColor: d.data.colourType,
                    isHoverable: d.data.isHover,
                    isExpanded: d.data.expanded,
                    isAllExpanded: d.data.allexpanded,
                    isAccess: d.data.isAccess
                },
                dataId: d.data.dataId,
                nodeType: d.data.type,
                allData: d.data.data,
                childrenCount: d.data.children === undefined ? 0 : d.data.children.length,
            },
            position: { x: d.x, y: d.y },
            type: 'hiagram',
        }));

        const edges = root.links().map((d, i) => ({
            id: `${i}`,
            source: d.source.data.id,
            target: d.target.data.id,
            type: 'step',
            style: { stroke: mainTheme.palette.primary.main, strokeDasharray: 3 },
            markerEnd: {
                type: MarkerType.Arrow,
                color: mainTheme.palette.primary.main,
                height: 20,
                width: 20,
            },
        }));

        return { nodes, edges, tempHierarchy };
    }

    function getExpanedElements(handleNodeClick: any) {
        let tempHierarchy = hierarchy;

        expandAllData(tempHierarchy.descendants());

        const root = layout(tempHierarchy);
        const nodes = root.descendants().map((d) => ({
            id: d.data.id,
            data: {
                title: d.data.name,
                depth: d.depth,
                handleExpand: handleNodeClick,
                config: {
                    bgColor: d.data.colourType,
                    isHoverable: d.data.isHover,
                    isExpanded: d.data.expanded,
                    isAllExpanded: true,
                    isAccess: d.data.isAccess
                },
                dataId: d.data.dataId,
                nodeType: d.data.type,
                allData: d.data.data,
                childrenCount: d.data.children === undefined ? 0 : d.data.children.length,
            },
            position: { x: d.x, y: d.y },
            type: 'hiagram',
        }));

        const edges = root.links().map((d, i) => ({
            id: `${i}`,
            source: d.source.data.id,
            target: d.target.data.id,
            type: 'step',
            style: { stroke: mainTheme.palette.primary.main, strokeDasharray: 3 },
            markerEnd: {
                type: MarkerType.Arrow,
                color: mainTheme.palette.primary.main,
                height: 20,
                width: 20,
            },
        }));

        return { nodes, edges, tempHierarchy };
    }

    useEffect(() => {
        const nextElements = getElements(handleNodeClick, true);
        setNodes(nextElements.nodes);
        setEdges(nextElements.edges);
        setHierarchy(nextElements.tempHierarchy);
    }, []);
    //tree = props.hiagramData;
    const unExpandData = (children: any) => {
        children?.forEach((child: any) => {
            child.data.expanded = false;
            //for new logic
            child.data.unexpanded = false;
            if (child.children !== null) {
                unExpandData(child.children);
            }
        });
    }

    const handleNodeClick = (event: any) => {
        const hierarchyNode = hierarchy.find((n: any) => n.data.id === event.detail.toString());
        if (!hierarchyNode) {
            return;
        }
        if (hierarchyNode.data.id !== "0") {
            //hierarchyNode.data.expanded = !hierarchyNode.data.expanded;
            if (hierarchyNode.data.expanded) {
                if (hierarchyNode.data.allexpanded === true) {
                    hierarchyNode.data.expanded = false;
                    const parentNode = hierarchy.find((n: any) => n.data.id === hierarchyNode.parent?.data.id);
                    if (!parentNode) {
                        return;
                    }
                    parentNode.children = parentNode.data.children;
                } else {
                    hierarchyNode.data.expanded = true;
                    unExpandData(hierarchyNode.children);
                    hierarchyNode.data.unexpanded = false;
                }

            } else {
                hierarchyNode.data.expanded = true;
                if (hierarchyNode.parent !== null) {
                    const parentNode = hierarchy.find((n: any) => n.data.id === hierarchyNode.parent?.data.id);
                    if (!parentNode) {
                        return;
                    }
                    if (hierarchyNode.data.expanded === true) {
                        let allChildren = parentNode?.children;
                        parentNode.children = allChildren?.filter((x: any) => x.data.id === hierarchyNode.data.id);
                        parentNode.data.unexpanded = true;
                    } else {
                        parentNode.children = parentNode.data.children
                    }
                }
            }
            if (hierarchyNode.data.expanded === false) {
                unExpandData(hierarchyNode.children);
                hierarchyNode.data.unexpanded = false;
            }
            const nextElements = getElements(handleNodeClick, false);

            setNodes(nextElements.nodes);
            setEdges(nextElements.edges);
            setHierarchy(nextElements.tempHierarchy);
        }
    };

    // useEffect(() => {
    //     console.log('------- props --------=>', props.hiagramData)
    // })

    //const initialElements = getElements(handleNodeClick, "out");
    const [nodes, setNodes] = useAnimatedNodes([], { duration: 400 });
    const [edges, setEdges] = useState([initialEdge]);
    const { fitView, setViewport } = useReactFlow();
    const { x, y, zoom } = useViewport();
    const [viewPortX, setNewViewPortX] = useState(0);
    const [viewPortZoom, setNewViewPortZoom] = useState(0);

    const handleTransform = useCallback(() => {
        let newX = viewPortX;
        //let newX = viewPortX + (200 / viewPortZoom);
        setViewport({ x: newX, y: 50, zoom: viewPortZoom }, { duration: 800 });
    }, [setViewport, viewPortX, viewPortZoom]);

    const handleReset = () => {
        const hierarchyNode = hierarchy.find((n: any) => n.data.id === "0");
        if (!hierarchyNode) {
            return;
        }
        hierarchyNode.data.expanded = true;
        if (hierarchyNode.data.expanded === true) {
            unExpandData(hierarchyNode.children)
            hierarchyNode.data.unexpanded = false;
        }

        const nextElements = getElements(handleNodeClick, false);

        setNodes(nextElements.nodes);
        setEdges(nextElements.edges);
        setHierarchy(nextElements.tempHierarchy);
    };

    const handleExpandAll = () => {
        const nextElements = getExpanedElements(handleNodeClick);
        setNodes(nextElements.nodes);
        setEdges(nextElements.edges);
        setHierarchy(nextElements.tempHierarchy);
    };
    useOnSelectionChange({
        onChange: ({ nodes, edges }) => {
            if (nodes.length === 0) {
                // setCloseMenu();
            }
        },
    });

    useEffect(() => {
        fitView({ duration: 200 });
    }, [nodes, fitView]);

    useEffect(() => {
        if (viewPortX === 0) {
            setNewViewPortX(x);
            setNewViewPortZoom(zoom);
        }
    }, [x, y, zoom]);

    return (
        <>

            <ReactFlow
                // minZoom={-Infinity}
                maxZoom={1.2}
                fitView
                nodes={nodes}
                edges={edges as Edge[]}
                elementsSelectable={false}
                proOptions={proOptions}
                nodeTypes={nodeTypes}


            >
                <div className="hiagram-controls">
                    <div>
                        <button className="hiagram-control-reset-btn" onClick={handleReset}>Reset view</button>
                        <button className="hiagram-control-center-btn" onClick={handleTransform}>Re-center</button>
                        <button className="hiagram-control-expand-btn" onClick={handleExpandAll}>Expand all nodes</button>
                    </div>
                </div>

                {/* <div className="hiagram-process">
                    <div className='hiagram-process-main-div'>
                        <Stack spacing={2} direction="row" >
                            <CircularProgress
                                variant="determinate"
                                value={userContext.hiagramProgress}
                                size={150}
                                sx={{ zIndex: 10 }}
                                thickness={2} />
                            <div className='hiagram-process-backgroud'>
                                <CircularProgress
                                    variant="determinate"
                                    value={100}
                                    size={150}
                                    sx={{
                                        color: 'rgb(238 238 238)'
                                    }}
                                    thickness={2} />
                            </div>
                            <div className='hiagram-process-text'>
                                {userContext.hiagramProgress > 0 ? <><label className='label-per'>
                                    {userContext.hiagramProgress}%
                                </label>
                                    <label className='label-complete'>
                                        complete
                                    </label>
                                </> : <></>}
                                <div className='div-label-resume' onClick={gotoQuestions}>
                                    <label className='label-resume'>
                                        resume
                                    </label>
                                    <label className='label-resume'>
                                        question flow
                                    </label>
                                </div>
                            </div>
                        </Stack>
                    </div>
                </div> */}
            </ReactFlow>
        </>
    );
}