import { useContext, useEffect, useState } from 'react';
import './ManageAccount.scss';
import { Box, Button, Fade, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import subscriptionPlanIcon from '../../../assets/icons/subscriptionPlanIcon.svg';
import workspaceIcon from '../../../assets/icons/workspaceIcon.svg';
import pauseAccount from '../../../assets/icons/pauseAccount.svg';
import deleteAccount from '../../../assets/icons/deleteAccount.svg';

export default function ManageAccount() {
    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className='manageaccount-wrapper'>
                <Box className='manageaccount-head-box'>
                    <Typography className='manageaccount-title'>Manage account</Typography>
                </Box>

                <Box>
                    <Typography sx={{ fontSize: '16px !important', fontWeight: '500' }}>This page is coming soon - apologies. Please email us at <a href="mailto:hello@imprend.com">hello@imprend.com</a> to pause or cancel your account swiftly, with no haggling or sneaky sales tactics.</Typography>
                </Box>

                {/* <Grid container spacing={3} className='manage-account-horizontal-box'>
                <Grid item xs={4} >
                    <Box className='grid-main-box'>
                        <img loading="lazy" alt='imprend' src={subscriptionPlanIcon} />
                        <Box className='grid-inner-box'>
                            <Typography className='upper-text'>Pro</Typography>
                            <Typography className='lower-text'>Your plan</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4} >
                    <Box className='grid-main-box'>
                        <img loading="lazy" alt='imprend' src={workspaceIcon} />
                        <Box className='grid-inner-box'>
                            <Typography className='upper-text'>5</Typography>
                            <Typography className='lower-text'>Workspace</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box className='manageaccount-head-box'>
                <Typography className='manageaccount-title'>Pause or Delete account</Typography>
            </Box>

            <Grid container spacing={2} className='manage-account-vartical-box'>
                <Grid item xs={12}>
                    <Box className='grid-account-box'>
                        <img loading="lazy" alt='imprend' src={pauseAccount} />
                        <Box className='grid-account-inner-box'>
                            <Typography className='account-upper-text'>Pause your account</Typography>
                            <List
                                sx={{
                                    listStyleType: 'disc',
                                    pl: 2,
                                }}
                            >
                                <ListItem disablePadding sx={{ display: 'list-item' }}>
                                    <ListItemText className="account-inner-text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ display: 'list-item' }}>
                                    <ListItemText className="account-inner-text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ display: 'list-item' }}>
                                    <ListItemText className="account-inner-text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</ListItemText>
                                </ListItem>
                            </List>

                            <Button variant='contained' className='pause-account-button'>Pause account</Button>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className='grid-account-box'>
                        <img loading="lazy" alt='imprend' src={deleteAccount} />
                        <Box className='grid-account-inner-box'>
                            <Typography className='account-upper-text'>Delete your account</Typography>
                            <List
                                sx={{
                                    listStyleType: 'disc',
                                    pl: 2,
                                }}
                            >
                                <ListItem disablePadding sx={{ display: 'list-item' }}>
                                    <ListItemText className="account-inner-text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ display: 'list-item' }}>
                                    <ListItemText className="account-inner-text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</ListItemText>
                                </ListItem>
                                <ListItem disablePadding sx={{ display: 'list-item' }}>
                                    <ListItemText className="account-inner-text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</ListItemText>
                                </ListItem>
                            </List>

                            <Button variant='contained' className='delete-account-button'>Delete account</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid> */}

            </Box>
        </Fade>
    );
}