import { useCallback } from 'react';
import { NodeProps, useReactFlow, getOutgoers, MarkerType, getIncomers } from 'reactflow';

export function useNodeOpenMenu(id: NodeProps['id'], close?: boolean) {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();

  const onClick = useCallback(() => {

    const currentNode = getNode(id);

    if (!currentNode) {
      return;
    }
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            isMenuOpen: close !== undefined ? close : !node.data.isMenuOpen,
          };
        } else {
          node.data = {
            ...node.data,
            isMenuOpen: false,
          };
        }

        return node;
      })
    );

  }, [getEdges, getNode, getNodes, id, setEdges, setNodes]);

  return onClick;
}

export default useNodeOpenMenu;
