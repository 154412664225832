import React, { useContext, useEffect, useState } from "react";
import "./Plans.scss"
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Grid, IconButton, Slide, Step, StepConnector, StepLabel, Stepper, TextField, Typography, stepConnectorClasses, styled } from "@mui/material";
import Plan from "../../Components/Plan/Plan";
import { GetPlansDetail, CreateSubscription, GetAllCountries } from '../../services/PlansService';
import { PreferencesContext } from '../../PreferenceContext';
import { GetUserDataById } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { AlertType } from "../../shared/AlertType";
import _ from "lodash";
import { TransitionProps } from "@mui/material/transitions";
import ThankYouIcon from '../../assets/icons/highFiveLogo.svg'
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { IMaskInput } from 'react-imask';
import { LoadingButton } from "@mui/lab";
import { getValueFromName } from "../../services/common/commonFunction";
import ClearIcon from '@mui/icons-material/Clear';
import { useAuth0 } from "@auth0/auth0-react";
import { Context } from "../../services/models/Context";
import { Reset } from "../../services/UserService";

const initialUserValues = {
    fullName: "",
    email: "",
    clientUid: 0,
};

const billingInitialValues = {
    name: "",
    email: "",
    address: "",
    address2: "",
    city: "",
    zipcode: "",
    state: "",
    country: ""
};

const paymentCardInitialValues = {
    cardnumber: "",
    cardholdername: "",
    expiredate: "",
    cvc: ""
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const CardNumberMask = React.forwardRef<HTMLElement, CustomProps>(
    function CardNumberMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="0000 0000 0000 00000000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const CardDateMask = React.forwardRef<HTMLElement, CustomProps>(
    function CardNumberMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00/00"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const CardCVCMask = React.forwardRef<HTMLElement, CustomProps>(
    function CardNumberMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const Connector = styled(StepConnector)(({ theme }: any) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#680001',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#680001',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const countryInitialValues = {
    name: "",
    code: "",
}


const steps = ['Add billing address', 'Add card details'];

export default function Plans() {
    const [userData, setUserData] = useState(initialUserValues);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [plansData, setPlansData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [planLoading, setPlanLoading] = useState(false);
    const [openThankYouModal, setThankYouModal] = useState(false);
    const [openPaymentModal, setPaymentModal] = useState(false);
    const [billingData, setBillingData] = useState(billingInitialValues);
    const [countryData, setCountryData] = useState([countryInitialValues]);
    const [activeStep, setActiveStep] = useState(0);
    const [focusField, setFocusField] = useState();
    const [pageChanged, setPageChanged] = useState(true);
    const [planPriceId, setPlanPriceId] = useState('');
    const [planId, setPlanId] = useState('');
    const [isProceedBtnLoading, setProceedBtnLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const { logout } = useAuth0();

    const billingValidateSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email("Enter an valid email").required("Email is required"),
        address: Yup.string().required("Address is required"),
        city: Yup.string().required("City is required"),
        zipcode: Yup.string().required("Zip code is required"),
        state: Yup.string().required("State is required"),
        country: Yup.string().required("Country is required"),
    });

    const paymentCardValidateSchema = Yup.object().shape({
        cardnumber: Yup.string().required("Number is required"),
        cardholdername: Yup.string().required("Name is required"),
        expiredate: Yup.string().required("Date is required"),
        cvc: Yup.string().required("CVC number is required")
    });


    let navigate = useNavigate();

    const goToLogin = () => {
        navigate("/");
    };

    const handleOpenPaymentModal = () => {
        setPaymentModal(true)
        setActiveStep(0);
    }

    const handleClosePaymentModal = () => {
        setPaymentModal(false);
    }

    const handleOpenThankYouModal = () => {
        setThankYouModal(true);
    };

    const handleCloseThankYouModal = () => {
        setThankYouModal(false);
        goToLogin();
    };

    // const handleCloseModal = () => {
    //     setPaymentModal(false);
    // };

    async function fetchData() {
        try {
            setPlanLoading(true);
            let userDatafetched = false;
            let planDatafetched = false;
            let [userApiData, planApiData]: [any, any] = await Promise.all([
                GetUserDataById(userContext.userId),
                GetPlansDetail()
            ]);

            if (userApiData !== null && userApiData.ok === true && userApiData.data !== null && userApiData.data !== undefined) {
                let data = userApiData.data;
                let user = initialUserValues;
                user.fullName = data.first_name + " " + data.last_name;
                user.email = data.email;
                user.clientUid = data.client_uid;
                setUserData(user);
                userDatafetched = true;
                let billingPaymentData: any = {
                    name: user.fullName,
                    email: data.email,
                    address: "",
                    address2: "",
                    city: "",
                    zipcode: "",
                    state: "",
                    country: "",
                }
                setBillingData(billingPaymentData);
            }

            if (planApiData !== null && planApiData.data !== null && planApiData.data.data !== null && planApiData.data.data !== undefined) {
                let plans = planApiData.data.data;
                plans.sort((a: any, b: any) => {
                    return a.metadata.order - b.metadata.order;
                });
                plans.forEach((plan: any) => {
                    plan.feature_list = plan.metadata.features.split("~");
                    plan.loading = false;
                });
                setPlansData(plans);
                planDatafetched = true;
            }
            if (userDatafetched === true && planDatafetched === true) {
                setPlanLoading(false);
            }

        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
            setPlanLoading(false);
        }
    }


    async function onPlanSelect(data: any) {
        // try {
        // let subscriptionData = {
        //     clientId: userData.clientUid,
        //     priceId: data.default_price.id,
        //     planId: data.id,
        //     fullName: userData.fullName,
        //     email: userData.email,
        //     dataManagedBy: userContext.userId,
        // }
        setPlanPriceId(data.default_price.id);
        setPlanId(data.id)
        // let subscriptionSendData: any;
        //subscriptionSendData = await CreateSubscription(subscriptionData);
        //setLoading(false);
        //  goToLogin();
        // handleOpenThankYouModal();
        handleOpenPaymentModal();

        // }
        // catch (error: any) {
        //     setLoading(false);
        // }
    }

    const handleNext = (values: any, resetForm: any) => {
        let billingPaymentData: any = {
            name: values.name,
            email: values.email,
            address: values.address,
            address2: values.address2,
            city: values.city,
            zipcode: values.zipcode,
            state: values.state,
            country: values.country,
        }

        setBillingData(billingPaymentData);
        setPageChanged(false);
        setActiveStep(1);
    };

    async function fetchAllCountries() {
        try {
            const country: any = await GetAllCountries();

            if (country !== undefined && country !== null && country.data !== null && country.data.data !== null) {
                setCountryData(country.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function submitForm(values: any, resetForm: any) {
        setProceedBtnLoading(true);
        try {
            let requestData = {
                cardNumber: values.cardnumber,
                cardHolderName: values.cardholdername,
                expireYear: values.expiredate.split('/')[1],
                expireMonth: values.expiredate.split('/')[0],
                cvc: values.cvc,
                name: billingData.name,
                email: billingData.email,
                address: billingData.address,
                address2: billingData.address2,
                city: billingData.city,
                zipCode: billingData.zipcode,
                state: billingData.state,
                country: getValueFromName(billingData.country, countryData).code,
                clientId: userData.clientUid,
                customerFullName: userData.fullName,
                customerEmail: userData.email,
                dataManagedBy: userContext.userId,
                priceId: planPriceId,
                planId: planId,
            }
            let data: any = await CreateSubscription(requestData);
            setPaymentModal(false);
            setLoading(false);
            handleOpenThankYouModal();
            //goToLogin();
        }
        catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
            setLoading(false);
        }
        setProceedBtnLoading(false);
    };

    function handleFocus(target: any) {
        setFocusField(target.target.name);
    }

    async function startAgain() {
        setPageLoading(true);
        try {
            const user: any = await Reset({
                clientId: userContext.clientId,
                userId: userContext.userId
            });
            if (user) {
                let curuserInfo: Context = new Context();
                updateUserContext(curuserInfo);
                logout({ logoutParams: { returnTo: window.location.origin } });
                //setPageLoading(false);
            }

        } catch (error: any) {
            setPageLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    useEffect(() => {
        fetchData();
        fetchAllCountries();
    }, []);

    useEffect(() => {
        if (activeStep === 0) {
            setPageChanged(true);
        }
    }, [activeStep]);
    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box className='plans-container'>
                    {pageLoading ?
                        <Box className="main-loding">
                            <img loading="lazy" alt="imprend" src='/loader.gif' style={{ height: '200px', width: 'auto' }}></img>
                        </Box> : <>
                            <Box className='plans-start-again-box'>
                                <LoadingButton
                                    color="primary"
                                    loadingPosition="start"
                                    variant="outlined"
                                    type="submit"
                                    startIcon={<></>}
                                    onClick={startAgain}
                                >
                                    Start sign up process again
                                </LoadingButton>
                            </Box>
                            <Box className='plans-main-box'>
                                <Box>
                                    <Typography variant="h6" className="plans-first-head">imprend handover and transition plans</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h1" className="plans-second-head">Free to try. Fair to run.</Typography>
                                </Box>
                                <Box className="plans-text">
                                    Our metered plan means you only get charged per handover, with the first 5 completely free.
                                </Box>
                            </Box>
                            <Box className='plans-box'>
                                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="plans-grid">
                                    {planLoading === false ? plansData !== undefined && plansData.length > 0 && plansData.map((plan: any, index: any) => (
                                        // <Box key={index}>
                                        //     <Box className="plans-sub-box">
                                        <Grid item xs={4} key={index}>
                                            <Plan planData={plan} isloading={loading} planLoading={planLoading} onPlanClick={onPlanSelect} clientId={0} />
                                        </Grid>
                                        //     </Box>
                                        // </Box>
                                    )) :

                                        (
                                            _.times(3, (i) => (
                                                // <Box key={i}>
                                                //     <Box className="plans-sub-box">
                                                <Grid item xs={4} key={i}>
                                                    <Plan planData={null} isloading={loading} planLoading={planLoading} onPlanClick={onPlanSelect} clientId={0} />
                                                </Grid>

                                            )))}
                                </Grid>
                            </Box>
                        </>}
                </Box>
            </Fade>
            {/* Thank you dialog */}
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={openThankYouModal}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='thank-you-dialog-box'
            >
                <Box className='img-box'>
                    <img loading="lazy" alt="imprend" className='thank-you-icon' src={ThankYouIcon} />
                </Box>
                <DialogTitle className='dialog-title'>Welcome!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        You've taken a big step towards streamlined transition.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={handleCloseThankYouModal}
                        className="login-btn"
                    >
                        Sign in
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                scroll={"body"}
                open={openPaymentModal}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='stepper-dialog-box'
            >
                <Box className="clear-icon-box">
                    <IconButton
                        disableRipple
                        className="close-btn"
                        onClick={handleClosePaymentModal}
                    >
                        <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                    </IconButton>
                </Box>
                <Box className="stepper-dialog-box-head">
                    <Typography className="stepper-dialog-box-title">{activeStep === 0 ? "Billing address" : "Add card"}</Typography>
                    <Box className="stepper-dialog-box-step">
                        <Stepper activeStep={activeStep} className="stepper" connector={<Connector />}>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                return (
                                    <Step key={label} {...stepProps} className="stepper-step">
                                        <StepLabel className="stepper-step-label" {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>

                </Box>


                <DialogContent className='payment-dialog-content'>

                    {activeStep === 0 ? (
                        <Formik
                            initialValues={billingData}
                            validationSchema={billingValidateSchema}
                            onSubmit={(values: any, { resetForm }) => {
                                if (pageChanged === true) {
                                    handleNext(values, resetForm);
                                }
                            }}
                        >
                            {(props: any) => {
                                return (
                                    <Form id="billing" className="billing-form">

                                        <Box className="billing-step-main-box">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="billing-step-main-grid-textfield">
                                                    <Typography>Name</Typography>
                                                    <TextField
                                                        id='name'
                                                        fullWidth
                                                        type='text'
                                                        placeholder='Name'
                                                        onChange={props.handleChange.bind("name")}
                                                        value={props.values.name}
                                                        // onChange={(e: any) => {
                                                        //     props.setFieldValue(
                                                        //         `name`,
                                                        //         e.target.value !== null ? e.target.value : "",
                                                        //         false
                                                        //     );
                                                        // }}
                                                        // value={userData.fullName}
                                                        error={
                                                            props.errors.name && props.touched.name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.name && props.touched.name ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.name}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="billing-step-main-grid-textfield">
                                                    <Typography>Email</Typography>
                                                    <TextField
                                                        id='email'
                                                        fullWidth
                                                        type='email'
                                                        placeholder='Email'
                                                        value={props.values.email}
                                                        onChange={props.handleChange.bind("email")}
                                                        error={
                                                            props.errors.email && props.touched.email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.email && props.touched.email ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.email}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="billing-step-main-grid-textfield">
                                                    <Typography>Address</Typography>
                                                    <TextField
                                                        id='address'
                                                        fullWidth
                                                        type='text'
                                                        multiline={true}
                                                        rows={3}
                                                        placeholder='Address'
                                                        onChange={props.handleChange.bind("address")}
                                                        value={props.values.address}
                                                        error={
                                                            props.errors.address && props.touched.address
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.address && props.touched.address ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.address}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="billing-step-main-grid-textfield">
                                                    <Typography>Address (line 2)</Typography>
                                                    <TextField
                                                        id='address2'
                                                        fullWidth
                                                        type='text'
                                                        multiline={true}
                                                        rows={3}
                                                        placeholder='Address (optional)'
                                                        onChange={props.handleChange.bind("address2")}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="billing-step-main-grid-textfield">
                                                    <Typography>City</Typography>
                                                    <TextField
                                                        id='city'
                                                        fullWidth
                                                        type='text'
                                                        placeholder='City'
                                                        onChange={props.handleChange.bind("city")}
                                                        value={props.values.city}
                                                        error={
                                                            props.errors.city && props.touched.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.city && props.touched.city ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.city}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="billing-step-main-grid-textfield">
                                                    <Typography>Zip/Postal code</Typography>
                                                    <TextField
                                                        id='zipcode'
                                                        fullWidth
                                                        type='text'
                                                        placeholder='Zip/postal code'
                                                        onChange={props.handleChange.bind("zipcode")}
                                                        value={props.values.zipcode}
                                                        error={
                                                            props.errors.zipcode && props.touched.zipcode
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.zipcode && props.touched.zipcode ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.zipcode}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="billing-step-main-grid-textfield">
                                                    <Typography>State/Province</Typography>
                                                    <TextField
                                                        id='state'
                                                        fullWidth
                                                        type='text'
                                                        placeholder='State/province'
                                                        onChange={props.handleChange.bind("state")}
                                                        value={props.values.state}
                                                        error={
                                                            props.errors.state && props.touched.state
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.state && props.touched.state ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.state}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="billing-step-main-grid-textfield">
                                                    <Typography>Country</Typography>

                                                    {/* <Autocomplete
                                                        id="country"
                                                        // sx={{ width: 300 }}
                                                        fullWidth
                                                        inputValue={props.values.country}
                                                        options={countries}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.code}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                <img
                                                                    loading="lazy"
                                                                    width="20"
                                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                    alt=""
                                                                />
                                                                {option.label} ({option.code})
                                                            </Box>
                                                        )}
                                                        onInputChange={(e, value, reason) => {
                                                            props.setFieldValue(
                                                                "country",
                                                                value,
                                                                true
                                                            );
                                                        }}
                                                        forcePopupIcon={false}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                
                                                                placeholder="Country"
                                                                error={
                                                                    props.errors.country && props.touched.country
                                                                        ? true
                                                                        : false
                                                                }

                                                            />
                                                        )}
                                                    /> */}
                                                    <Autocomplete
                                                        id="country"
                                                        // sx={{ width: 300 }}
                                                        fullWidth
                                                        inputValue={props.values.country}
                                                        options={countryData}
                                                        value={getValueFromName(props.values.country, countryData)}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.name}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                <img
                                                                    loading="lazy"
                                                                    width="20"
                                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                    alt=""
                                                                />
                                                                {option.name} ({option.code})
                                                            </Box>
                                                        )}
                                                        onInputChange={(e, value, reason) => {
                                                            props.setFieldValue(
                                                                "country",
                                                                value,
                                                                true
                                                            );
                                                        }}
                                                        forcePopupIcon={false}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder="Country"
                                                                error={
                                                                    props.errors.country && props.touched.country
                                                                        ? true
                                                                        : false
                                                                }

                                                            />
                                                        )}
                                                    />
                                                    {props.errors.country && props.touched.country ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.country}
                                                        </Typography> : <></>}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="billing-button-box">
                                            <Button
                                                variant='contained'
                                                type="submit"
                                                className="stepper-procced-button"
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    ) : (
                        <Formik
                            initialValues={paymentCardInitialValues}
                            validationSchema={paymentCardValidateSchema}
                            onSubmit={(values: any, { resetForm }) => {
                                submitForm(values, resetForm);
                            }}
                        >
                            {(props: any) => {
                                return (
                                    <Form id="payment" className="payment-form">
                                        <Box className="payment-step-main-box">
                                            <Box>
                                                <Cards
                                                    number={props.values.cardnumber === undefined ? "" : props.values.cardnumber}
                                                    expiry={props.values.expiredate === undefined ? "" : props.values.expiredate}
                                                    cvc={props.values.cvc === undefined ? "" : props.values.cvc}
                                                    name={props.values.cardholdername === undefined ? "" : props.values.cardholdername}
                                                    focused={focusField}
                                                />
                                            </Box>
                                            <Box className="payment-step-detail-box">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} className="payment-step-main-grid-textfield">
                                                        <Typography>Card number</Typography>
                                                        <TextField
                                                            id='cardnumber'
                                                            fullWidth
                                                            name="number"
                                                            //type='number'
                                                            // inputProps={{ pattern: '[\\d| ]{16,22}', maxLength: 16 }}
                                                            InputProps={{
                                                                inputComponent: CardNumberMask as any,
                                                            }}
                                                            placeholder='Card number'
                                                            className="payment-step-grid-textfield"
                                                            onFocus={handleFocus}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `cardnumber`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                            }}
                                                            value={props.values.cardnumber}
                                                            error={
                                                                props.errors.cardnumber && props.touched.cardnumber
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {
                                                            props.errors.cardnumber && props.touched.cardnumber ?
                                                                <Typography className="validation-message" >
                                                                    {props.errors.cardnumber}
                                                                </Typography> : <></>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} className="payment-step-main-grid-textfield">
                                                        <Typography>Card holder name</Typography>
                                                        <TextField
                                                            id='cardholdername'
                                                            fullWidth
                                                            type='text'
                                                            name="name"
                                                            inputProps={{ pattern: '[a-z A-Z-]+' }}
                                                            placeholder='Card holder name'
                                                            className="payment-step-grid-textfield"
                                                            onFocus={handleFocus}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `cardholdername`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                            }}
                                                            value={props.values.cardholdername}
                                                            error={
                                                                props.errors.cardholdername && props.touched.cardholdername
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {props.errors.cardholdername && props.touched.cardholdername ?
                                                            <Typography className="validation-message" >
                                                                {props.errors.cardholdername}
                                                            </Typography> : <></>}
                                                    </Grid>

                                                    <Grid item xs={12} className="payment-step-main-grid-textfield2">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} className="payment-step-main-grid-textfield">
                                                                <Typography>Expire </Typography>
                                                                <TextField
                                                                    id='expiredate'
                                                                    fullWidth
                                                                    type='tel'
                                                                    name="expiry"
                                                                    InputProps={{
                                                                        inputComponent: CardDateMask as any,
                                                                    }}
                                                                    placeholder='MM/YY'
                                                                    className="payment-step-grid-textfield"
                                                                    onFocus={handleFocus}
                                                                    onChange={(e: any) => {
                                                                        props.setFieldValue(
                                                                            `expiredate`,
                                                                            e.target.value !== null ? e.target.value : "",
                                                                            true
                                                                        );
                                                                    }}
                                                                    value={props.values.expiredate}
                                                                    error={
                                                                        props.errors.expiredate && props.touched.expiredate
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {props.errors.expiredate && props.touched.expiredate ?
                                                                    <Typography className="validation-message" >
                                                                        {props.errors.expiredate}
                                                                    </Typography> : <></>}
                                                            </Grid>

                                                            <Grid item xs={6} className="payment-step-main-grid-textfield">
                                                                <Typography>CVC</Typography>
                                                                <TextField
                                                                    id='cvc'
                                                                    fullWidth
                                                                    name='cvc'
                                                                    InputProps={{
                                                                        inputComponent: CardCVCMask as any,
                                                                    }}
                                                                    placeholder='CVC'
                                                                    className="payment-step-grid-textfield"
                                                                    onFocus={handleFocus}
                                                                    onChange={(e: any) => {
                                                                        props.setFieldValue(
                                                                            `cvc`,
                                                                            e.target.value !== null ? e.target.value : "",
                                                                            true
                                                                        );
                                                                    }}
                                                                    value={props.values.cvc}
                                                                    error={
                                                                        props.errors.expiredate && props.touched.cvc
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {props.errors.cvc && props.touched.cvc ?
                                                                    <Typography className="validation-message" >
                                                                        {props.errors.cvc}
                                                                    </Typography> : <></>}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Box>
                                        </Box>

                                        <Box className="payment-button-box">
                                            <Button
                                                id="back"
                                                variant='outlined'
                                                onClick={() => setActiveStep(0)}
                                                className="stepper-procced-button"
                                                sx={{ mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                            <LoadingButton
                                                variant='contained'
                                                type="submit"
                                                className="stepper-procced-button"
                                                loading={isProceedBtnLoading}
                                            >
                                                Proceed
                                            </LoadingButton>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </DialogContent>
            </Dialog >
        </>
    );
}