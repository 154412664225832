import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const Login = (data: any) =>
    POST(`${BASE_URL}/auth/authenticate`, data);

export const Signup = (data: any) =>
    POST(`${BASE_URL}/auth/signup`, data);

export const CreateUser = (data: any) =>
    POST(`${BASE_URL}/users/createinviteduser`, data);

export const GetUserDataById = (id: any) =>
    GET(`${BASE_URL}/users/userbyid/` + id, null);

export const ForgotPasswordEmail = (data: any) =>
    POST(`${BASE_URL}/auth/forgotpassword`, data);
