import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH_CLIENTID, AUTH_DOMAIN, AUTH_FREECLIENTID } from './services/common/const';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId={window.location.pathname.includes('free') ? AUTH_FREECLIENTID : AUTH_CLIENTID}
      //clientId={AUTH_CLIENTID}
      //cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://dev-sw4y3mfdtdhy06gp.eu.auth0.com/api/v2/",
        // audience: "this is demo identifier",
        //scope: "read:current_user update:current_user_metadata", // only get id
        scope: "openid email profile read:current_user update:current_user_metadata", // get all data
        //scope: "openid email profile", // get all data
      }}
    >
      <App />
    </Auth0Provider>

  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
