import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetPlansDetail = () =>
    GET(`${BASE_URL}/subscription/getallplans?`, null);

export const GetAllCountries = () =>
    GET(`${BASE_URL}/subscription/getallcountries?`, null);

export const CreateSubscription = (data: any) =>
    POST(`${BASE_URL}/subscription/createsubscription`, data);

export const UpdateSubscription = (data: any) =>
    POST(`${BASE_URL}/subscription/updatesubscription`, data);

export const GetCustormerDetail = (id: any) =>
    GET(`${BASE_URL}/subscription/customerdetail/` + id, null);

export const GetCustomerSubscription = (id: any) =>
    GET(`${BASE_URL}/subscription/customersubscription/` + id, null);

export const DeleteCard = (data: any) =>
    POST(`${BASE_URL}/subscription/deletecard`, data);

export const AddPaymentMethod = (data: any) =>
    POST(`${BASE_URL}/subscription/addpaymentmethod`, data);