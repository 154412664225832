import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const AddStep = (data: any) => POST(`${BASE_URL}/step/create`, data);

export const AddStepEdge = (data: any) =>
  POST(`${BASE_URL}/step/createedge`, data);

export const StepEdgeList = (params: any) =>
  GET(`${BASE_URL}/step/edgelist?` + params, null);

export const StepList = (params: any) =>
  GET(`${BASE_URL}/step/list?` + params, null);

export const UpdateStep = (data: any) => POST(`${BASE_URL}/step/update`, data);

export const UpdateSteps = (data: any) =>
  POST(`${BASE_URL}/step/updatesteps`, data);

export const GetStepdetail = (params: any) =>
  GET(`${BASE_URL}/step/getdetail?` + params, null);

export const GetStepdetailById = (params: any) =>
  GET(`${BASE_URL}/step/getdetailbyuid?` + params, null);

export const UpdateStepRank = (data: any) =>
  POST(`${BASE_URL}/step/updaterank`, data);

export const GetHiagramStepList = (params: any) =>
  GET(`${BASE_URL}/step/hiagramallsteplist?` + params, null);
