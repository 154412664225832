import React, { useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Grid, styled, Tooltip, Table, tableCellClasses, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, Card, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import * as Yup from "yup";
import { useEffect, useState } from "react";
import "./SharepartyMin.scss";
import { Form, Formik } from "formik";
import editIcon from '../../assets/icons/edit-2.svg'
import trashIcon from '../../assets/icons/trash.svg'

const formInitialValues = {
    rowNo: 0,
    name: "",
    email: "",
    confirmEmail: "",
    isDeleted: false
};
const TableData = [{
    rowNo: 0,
    name: "",
    email: "",
    confirmEmail: "",
    isDeleted: false
}];

export default function SherepartyMin(props: any) {
    const [tableDataForShareParty, setTableDataForShareParty] = React.useState(TableData);
    const [ShareParty, setShareParty] = React.useState(formInitialValues);
    const [counter, setCounter] = useState(0);
    const mainTheme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#ffffff",
            color: mainTheme.palette.primary.main,
            fontSize:"13px",
            borderBottom: `1px solid ${mainTheme.palette.primary.main}`,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
            borderBottom: `1px solid ${mainTheme.palette.primary.main}`,
        },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#ffffff",
            borderBottom: `1px solid ${mainTheme.palette.primary.main}`,
        },
        // '&:last-child td, &:last-child th': {
        // },
    }));
    


    const addRow = (values: any) => {
        //Add
        const rowNos = tableDataForShareParty.map((o) => {
            return o.rowNo;
        });
        const maxRowNo = Math.max(...rowNos);
        let detailColl = TableData;
        let newRowNo = maxRowNo + 1;
        const userAdd = ({
            rowNo: newRowNo,
            name: values.name,
            email: values.email,
            confirmEmail: values.confirmEmail,
            isDeleted: false
        }
        );
        detailColl.push(userAdd);
        setTableDataForShareParty(detailColl);



        //Edit
        let tempOldRow = values.rowNo;
        detailColl[tempOldRow].isDeleted = true;
        setTableDataForShareParty(detailColl);
        let newCounter = counter + 1;
        setCounter(newCounter);
        let tempRoNo = rowNos;
        if (tempRoNo === tempOldRow) {
            let detailCollEdit = TableData;
            const userEdit = ({
                rowNo: newRowNo,
                name: values.name,
                email: values.email,
                confirmEmail: values.confirmEmail,
                isDeleted: false
            }
            );
            detailCollEdit.push(userEdit);
            setTableDataForShareParty(detailCollEdit);
        }

        const userCommon = ({
            rowNo: values.rowNo,
            name: "",
            email: "",
            confirmEmail: "",
            isDeleted: false
        }
        );
        setShareParty(userCommon);

    }

    const editRow = (id: any) => {
        //GetDetail
        let editDetailId = tableDataForShareParty.map((o) => {
            let tempRoNo = o.rowNo;
            if (tempRoNo === id) {
                const userDelete = ({
                    rowNo: id,
                    name: o.name,
                    email: o.email,
                    confirmEmail: o.confirmEmail,
                    isDeleted: false
                }
                );
                return setShareParty(userDelete);
            }
        });

    }

    const deleteRow = (i: any) => {
        //Delete
        let detailColl = TableData;
        detailColl[i].isDeleted = true;
        setTableDataForShareParty(detailColl);
        let newCounter = counter + 1;
        setCounter(newCounter);
    }

    //validation
    const formValidation = Yup.object().shape({
        name: Yup.string().required("Name is required."),
        email: Yup.string()
            .email("Enter a valid email.")
            .required("Email is required."),
        confirmEmail: Yup.string()
            .required("Confirm Email is required.")
            .oneOf([Yup.ref("email")], "confirmEmail does not match.")
            .nullable(),
    });

    return (
        <>
            <Box className='box-container-sharepartymin' >
                <Grid className='page-heading' item xs={12}>
                    Send hiagram to:
                </Grid>
                <Formik
                    enableReinitialize
                    initialValues={ShareParty}
                    onSubmit={(values, { resetForm }) => {
                        resetForm();
                        addRow(values);
                    }}
                    validationSchema={formValidation}

                >
                    {(props) => {
                        return (
                            <>
                                <Form onSubmit={props.handleSubmit}>
                                    <Box sx={{ height: "50%" }}>
                                        <Paper elevation={0} className="form-paper">

                                            <Grid container spacing={2} >
                                                <Grid item xs={3} className='lable-grid-css' >
                                                    <Typography><b>Name</b></Typography>
                                                </Grid>
                                                <Grid item xs={9} className="field-grid-css">
                                                    <TextField
                                                        className='input-field'
                                                        sx={{ marginTop: 2 }}
                                                        id='Name'
                                                        InputProps={{ sx: { height: "40px" } }}
                                                        fullWidth
                                                        placeholder='Name'
                                                        onChange={props.handleChange("name")}
                                                        value={props.values.name}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.name && props.touched.name
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                    </TextField>
                                                    {props.errors.name && props.touched.name ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.name}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={3} className='lable-grid-css' >
                                                    <Typography><b>Email</b></Typography>
                                                </Grid>
                                                <Grid item xs={9} className="field-grid-css">
                                                    <TextField
                                                        sx={{ marginTop: 2 }}
                                                        id='Email'
                                                        fullWidth
                                                        placeholder='Email'
                                                        InputProps={{ sx: { height: "40px" } }}
                                                        onChange={props.handleChange("email")}
                                                        value={props.values.email}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.email && props.touched.email
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                    </TextField>
                                                    {props.errors.email && props.touched.email ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.email}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={3} className='lable-grid-css' >
                                                    <Typography><b>Confirm email</b></Typography>
                                                </Grid>
                                                <Grid item xs={9} className="field-grid-css">
                                                    <TextField
                                                        sx={{ marginTop: 2 }}
                                                        id='Confirm email'
                                                        fullWidth
                                                        placeholder='Confirm email'
                                                        InputProps={{ sx: { height: "40px" } }}
                                                        value={props.values.confirmEmail}
                                                        onChange={props.handleChange("confirmEmail")}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.confirmEmail && props.touched.confirmEmail
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                    </TextField>
                                                    {props.errors.confirmEmail && props.touched.confirmEmail ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.confirmEmail}
                                                        </Typography> : <></>}
                                                </Grid>

                                            </Grid>

                                        </Paper>
                                        <Grid item xs={12} className='add-button-grid' >
                                            <Button className='button-add' type="submit" >
                                                <AddIcon sx={{ color: mainTheme.palette.primary.main }}></AddIcon>
                                                <Typography className='typography-text' > Add </Typography>
                                            </Button>
                                        </Grid>
                                    </Box>
                                </Form>
                                <Box sx={{ height: "50%", marginTop: "20px" }}>
                                    <Paper elevation={0} className="table-container">
                                        <TableContainer>
                                            <Table className="table-css" aria-label="customized table">
                                                <TableHead >
                                                    <TableRow >
                                                        <StyledTableCell align="left" sx={{ width: '25%', }}>Name</StyledTableCell>
                                                        <StyledTableCell align="left" sx={{ width: '50%', }}>Email</StyledTableCell>
                                                        <StyledTableCell align="center" sx={{ width: '25%', }}>Action</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <>
                                                        {tableDataForShareParty.filter((x) => x.isDeleted === false).length > 0 ?
                                                            (tableDataForShareParty.filter((x) => x.isDeleted === false).map((row: any) =>
                                                                <>
                                                                    {row.rowNo > 0 ? (
                                                                        <StyledTableRow key={row.rowNo}>
                                                                            <StyledTableCell className='common-row' component="th" scope="row" align="left">{row.name}</StyledTableCell>
                                                                            <StyledTableCell className='common-row' align="left">{row.email}</StyledTableCell>
                                                                            <StyledTableCell className='table-buttons' >
                                                                                <Card  elevation={0} className='buttons-grid'>
                                                                                    <Grid  className='edit-button-grid'>
                                                                                        <Tooltip title='Edit'>
                                                                                            <Button
                                                                                                className='edit-btn'
                                                                                                onClick={() => editRow(row.rowNo)}
                                                                                            >
                                                                                                <img className='edit-icon' src={editIcon} />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                    <Grid  className='delete-button-grid' >
                                                                                        <Tooltip title='Delete'>
                                                                                            <Button
                                                                                                className='delete-btn'
                                                                                                onClick={() => deleteRow(row.rowNo)}
                                                                                            >
                                                                                                <img className='delete-icon' src={trashIcon} />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </Grid>
                                                                                </Card>
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    ) : (
                                                                        <>
                                                                            <StyledTableRow key={1}>
                                                                                <StyledTableCell sx={{ textAlign: "center" }} colSpan={3} rowSpan={3}>
                                                                                None added yet!
                                                                                </StyledTableCell>
                                                                            </StyledTableRow>
                                                                        </>
                                                                    )}
                                                                </>


                                                            )) : (
                                                                <>
                                                                    <StyledTableRow key={1}>
                                                                        <StyledTableCell sx={{ textAlign: "center" }} colSpan={3} rowSpan={3}>
                                                                        None added yet!
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                </>
                                                            )}
                                                    </>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                    <Box className='send-button-grid' >
                                        <Button className='button-send' variant='contained'>
                                            <Typography className='typography-text' >Send </Typography>
                                        </Button>
                                    </Box>

                                </Box>
                            </>

                        );
                    }}
                </Formik>
            </Box>
        </>

    );
}
