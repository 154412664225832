import { useContext, useEffect, useState } from 'react';
import './BillingInformation.scss';
import { Box, Fade, Grid, Tab, Tabs } from '@mui/material';
import Plan from '../../Plan/Plan';
import { GetUserDataById } from '../../../services/AuthService';
import { GetCustomerSubscription, GetPlansDetail, UpdateSubscription } from '../../../services/PlansService';
import { PreferencesContext } from '../../../PreferenceContext';
import { AlertType } from '../../../shared/AlertType';
import BillingHistory from '../BillingHistory/BillingHistory';
import _ from 'lodash';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <> {children}</>
            )}
        </div>
    );
}


function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
let initData: any = [];
export default function BillingInformation() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [planLoading, setPlanLoading] = useState(false);
    const [plansData, setPlansData] = useState(initData);
    const [usersubscription, setUsersubscription] = useState(initData);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    async function fetchData() {
        try {
            setPlanLoading(true);
            let userDatafetched = false;
            let planDatafetched = false;
            let subscriptionDatafetched = false;
            let subscription: any;
            let [userApiData, planApiData, subscriptionData]: [any, any, any] = await Promise.all([
                GetUserDataById(userContext.userId),
                GetPlansDetail(),
                GetCustomerSubscription(userContext.clientId)
            ]);

            if (userApiData !== null && userApiData.ok === true && userApiData.data !== null && userApiData.data !== undefined) {
                let data = userApiData.data;
                userDatafetched = true;
                // let billingPaymentData: any = {
                //     name: user.fullName,
                //     email: data.email,
                //     address: "",
                //     address2: "",
                //     city: "",
                //     zipcode: "",
                //     state: "",
                //     country: "",
                // }
                // setBillingData(billingPaymentData);
            }
            if (subscriptionData !== null && subscriptionData.data !== null && subscriptionData.data.data !== null && subscriptionData.data.data !== undefined) {
                subscription = subscriptionData.data.data;
                setUsersubscription(subscriptionData.data.data);
                subscriptionDatafetched = true;
            }
            if (planApiData !== null && planApiData.data !== null && planApiData.data.data !== null && planApiData.data.data !== undefined) {
                let plans = planApiData.data.data;
                plans.sort((a: any, b: any) => {
                    return a.metadata.order - b.metadata.order;
                });
                plans.forEach((plan: any) => {
                    if (plan.id === subscription.plan_id) {
                        plan.metadata.highlighted = "1";
                        plan.metadata.button = "1";
                        plan.metadata.label = "Current";
                    } else {
                        plan.metadata.highlighted = "0";
                        plan.metadata.button = "0";
                        if (plan.metadata.ready === "1") {
                            plan.metadata.label = "Change";
                        }
                    }
                    plan.feature_list = plan.metadata.features.split("~");
                    plan.loading = false;
                });
                setPlansData(plans);
                planDatafetched = true;
            }

            if (userDatafetched === true && planDatafetched === true && subscriptionDatafetched === true) {
                setPlanLoading(false);
            }

        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
            setPlanLoading(false);
        }
    }
    async function onPlanSelect(data: any) {
        try {
            let requestData = {
                planId: data.id,
                PRICE_ID: data.default_price.id,
                clientId: userContext.clientId,
                customerId: usersubscription.customer_id
            }
            let response: any = await UpdateSubscription(requestData);
            if (response.data !== null) {
                fetchData();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (value === 0)
            fetchData();
    }, [value]);
    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className='billing-wrapper'>
                <Box className='billing-head-box'>
                    <Tabs
                        indicatorColor="primary"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                    >
                        <Tab className='billing-tabs-title' sx={{ color: '#232323' }} label="Plan" {...a11yProps(0)} />
                        <Tab className='billing-tabs-title' sx={{ color: '#232323' }} label="Billing history" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <Box className='billing-body-box'>
                    <TabPanel value={value} index={0}>
                        <Fade in={true} timeout={{ enter: 500 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="billing-plans-box">
                                    {planLoading === false ? plansData !== undefined && plansData.length > 0 && plansData.map((plan: any, index: any) => (
                                        <Grid item xs={4} key={index}>
                                            <Plan
                                                planData={plan}
                                                isloading={loading}
                                                planLoading={planLoading}
                                                onPlanClick={onPlanSelect}
                                                clientId={userContext.clientId}
                                            />
                                        </Grid>
                                    )) :
                                        (
                                            _.times(3, (i) => (
                                                <Grid key={i} item xs={4}>
                                                    <Plan
                                                        planData={null}
                                                        isloading={loading}
                                                        planLoading={planLoading}
                                                        onPlanClick={onPlanSelect}
                                                        clientId={0}
                                                    />
                                                </Grid>
                                            ))
                                        )
                                    }
                                </Grid>
                                {/* <Typography sx={{ fontSize: '20px', color: '#680001', fontWeight: '600' }}>Coming soon</Typography> */}
                            </Box>
                        </Fade>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <BillingHistory />
                    </TabPanel>
                </Box>
            </Box>
        </Fade>
    );
}
