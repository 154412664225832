import React, { useContext, useEffect, useState } from 'react';
import { Alert, Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import LoadingButton from "@mui/lab/LoadingButton";
import eye from '../../assets/icons/eye.svg';
import eyeslash from '../../assets/icons/eye-slash.svg';
import * as Yup from "yup";
import { useLocation, useNavigate } from 'react-router-dom';
import { PreferencesContext } from '../../PreferenceContext';
import { getInviteDetail } from '../../services/InviteService';
import { CreateUser } from '../../services/AuthService'
import { AlertType } from '../../shared/AlertType';
import usePasswordValidation from '../../hooks/usePasswordValidation';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useAuth0 } from '@auth0/auth0-react';

const formInitialValues = {
    inviteUid: 0,
    clientUid: 0,
    firstName: "",
    lastName: "",
    isDeleted: false,
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
};

export default function SetupAccount() {
    const [values, setValues] = useState(formInitialValues);
    const [email, setEmail] = useState();
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const { loginWithRedirect, isAuthenticated, isLoading, user, logout } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [isVisible, setVisible] = useState(true);
    const [isExpired, setExpired] = useState(false);
    const [openWelcomeModal, setWelcomeModal] = useState(false);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        type: "",
        message: "",
    });
    const [isDataLoading, setDataLoading] = useState(false);
    const [password, setPassword] = useState("");

    const [validLength, hasNumber, upperCase, lowerCase, specialChar,
    ] = usePasswordValidation({
        firstPassword: password
    });


    // let navigate = useNavigate();

    // const goToLogin = () => {
    //     navigate("/");
    // };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const CloseWelcomeModal = () => {
        setWelcomeModal(false);
        loginWithRedirect({
            appState: {
                returnTo: window.location.pathname,
            },
        })
    }

    const handleClickShowCConfPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const passwordValidation: any = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*]).{8,}$/;
    const valildationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required."),
        lastName: Yup.string().required("Last name is required."),
        newPassword: Yup.string().matches(passwordValidation, 'Must include an uppercase letter, a lowercase letter and a number')
            .required("New password is required.")
        ,
        //newPassword: Yup.string().required("New password is required."),
        confirmPassword: Yup.string()
            .required("Confirm password is required.")
            .oneOf([Yup.ref("newPassword"), null], "Confirm password doesn't match with the New password."),
    });

    let urlParams = useQuery();

    async function fetchInviteDetail(auth_token: any) {
        setDataLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("auth_token", auth_token.toString());
            params.append("clientUid", userContext.clientId.toString());
            const inviteDetail: any = await getInviteDetail(params);
            if (inviteDetail !== null && inviteDetail.data !== null && inviteDetail.data.data !== null && inviteDetail.data.data !== undefined) {
                let data = inviteDetail.data.data;
                if (data === false) {
                    setExpired(true);
                    setVisible(false);
                } else {
                    let invited_email = data.INVITED_EMAIL;
                    setEmail(invited_email);
                    let userForm = formInitialValues;
                    userForm.clientUid = data.CLIENT_UID;
                    userForm.inviteUid = data.UID;
                    setValues(userForm);
                    setVisible(true);
                    setExpired(false);
                }
            }
            else {
                setVisible(false);
                setExpired(false);
            }
            setDataLoading(false);
            //handleClickShowPassword();
            //handleClickShowCConfPassword();
        } catch (error) {
            setDataLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: false,
                    type: AlertType.Error,
                    message: error as string,
                });
            }
        }
    }
    async function submitSetPasswordForm(values: any, resetForm: any) {
        setLoading(true);
        try {
            const user: any = await CreateUser({
                clientId: values.clientUid,
                inviteUid: values.inviteUid,
                firstName: values.firstName,
                lastName: values.lastName,
                email: email,
                password: values.newPassword
            });

            if (user) {
                // setAlertMessage({
                //     isSuccess: true,
                //     type: AlertType.Success,
                //     message: "Registration successfully completed.",
                // });
                // goToLogin();
                setWelcomeModal(true)
            } else {
                setAlertMessage({
                    isSuccess: false,
                    type: AlertType.Error,
                    message: "Something went wrong!",
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: false,
                    type: AlertType.Error,
                    message: error as string,
                });
            }
        }
        setTimeout(() => {
            setAlertMessage({
                type: "",
                isSuccess: false,
                message: "",
            });
        }, 7000);
        resetForm(formInitialValues);
    }


    useEffect(() => {
        let auth_token = urlParams.get("t");

        if (auth_token !== null && auth_token !== undefined) {
            fetchInviteDetail(auth_token)
        }

    }, []);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isDataLoading}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img loading="lazy" src='/loader.gif' style={{ height: '250px', width: 'auto' }} alt="imprend" />
            </Backdrop>
            <Grid className="set-up-account-main-grid">
                <Paper elevation={3} className="set-up-account-paper">
                    <Grid className="set-up-account-wrapper">
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues}
                            onSubmit={(values, { resetForm }) => {
                                submitSetPasswordForm(values, resetForm);
                            }}
                            validationSchema={valildationSchema}>
                            {(props) => {
                                return (
                                    <Form style={{ width: '100%' }}>
                                        <React.Fragment>
                                            {/* <Grid className="set-up-account-detail-form">
                                                <Link
                                                    className='set-up-account-backspace-Button'
                                                    to={'/sign-in'}>
                                                    <KeyboardBackspaceIcon className='set-up-account-icon-css' /><b>Back</b>
                                                </Link>
                                            </Grid> */}

                                            <Box className="set-up-account-box-container" sx={{ my: 2 }}>
                                                {isVisible === true ?
                                                    <Paper className="set-up-account-paper-container">

                                                        <Grid justifyContent="center">
                                                            <Typography className="set-up-account-title"><b>Set up your account</b></Typography>
                                                            <Typography className="set-up-account-email"><b>Email:</b> {email}</Typography>
                                                        </Grid>
                                                        {showAlert.isSuccess === true &&
                                                            showAlert.type === AlertType.Success ? (
                                                            <Grid marginBottom={1}>
                                                                <Alert severity="success">{showAlert.message}</Alert>
                                                            </Grid>) : showAlert.isSuccess === false &&
                                                                showAlert.type === AlertType.Error ? (
                                                            <Grid marginBottom={1}>
                                                                <Alert severity="error">{showAlert.message}</Alert>
                                                            </Grid>) : <></>}

                                                        <Paper className={showAlert.type !== "" ? "set-up-account-paper-scrollfrom set-up-account-alert-div-space" : "set-up-account-paper-scrollfrom"}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} className="set-up-account-gird">
                                                                    <Box className="set-up-account-fields">
                                                                        <Typography>First name</Typography>
                                                                        <TextField
                                                                            sx={{ marginTop: 1, }}
                                                                            id='firstName'
                                                                            fullWidth
                                                                            autoComplete='no'
                                                                            placeholder='First name'
                                                                            value={props.values.firstName}
                                                                            onChange={props.handleChange("firstName")}
                                                                            onBlur={props.handleBlur}
                                                                            error={
                                                                                props.errors.firstName && props.touched.firstName
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        >
                                                                        </TextField>
                                                                        {props.errors.firstName && props.touched.firstName ?
                                                                            <Typography className="validation-message" >
                                                                                {props.errors.firstName}
                                                                            </Typography> : <></>}
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item xs={12} className="set-up-account-gird">
                                                                    <Box className="set-up-account-fields">
                                                                        <Typography>Last name</Typography>
                                                                        <TextField
                                                                            id='lastName'
                                                                            sx={{ marginTop: 1, }}
                                                                            fullWidth
                                                                            autoComplete='no'
                                                                            placeholder='Last name'
                                                                            value={props.values.lastName}
                                                                            onChange={props.handleChange("lastName")}
                                                                            onBlur={props.handleBlur}
                                                                            error={
                                                                                props.errors.lastName && props.touched.lastName
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        >
                                                                        </TextField>
                                                                        {props.errors.lastName && props.touched.lastName ?
                                                                            <Typography className="validation-message" >
                                                                                {props.errors.lastName}
                                                                            </Typography> : <></>}
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item xs={12} className="set-up-account-gird">
                                                                    <Box className="set-up-account-fields">
                                                                        <Typography>New password</Typography>

                                                                        <TextField
                                                                            id='newPassword'
                                                                            sx={{ marginTop: 1 }}
                                                                            fullWidth
                                                                            autoComplete='new-password'
                                                                            inputProps={{
                                                                                autoComplete: 'new-password',
                                                                            }}
                                                                            placeholder='New password'
                                                                            value={props.values.newPassword}
                                                                            //onChange={props.handleChange("newPassword")}
                                                                            onChange={(event) => {
                                                                                setPassword(event.target.value);
                                                                                props.setFieldValue(
                                                                                    "newPassword",
                                                                                    event !== null ? event.target.value : "",
                                                                                    true
                                                                                );
                                                                            }}
                                                                            onBlur={props.handleBlur}
                                                                            type={values.showPassword ? "text" : "password"}
                                                                            error={
                                                                                props.errors.newPassword && props.touched.newPassword
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            InputProps={{
                                                                                // startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton
                                                                                            aria-label="toggle password visibility"
                                                                                            onClick={handleClickShowPassword}
                                                                                            edge="end"
                                                                                        >
                                                                                            {values.showPassword ? (
                                                                                                <img loading="lazy" className='set-up-account-eye-icon' alt="imprend" src={eyeslash} />
                                                                                            ) : (
                                                                                                <img loading="lazy" className='set-up-account-eye-icon' alt="imprend" src={eye} />
                                                                                            )}
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        > </TextField>
                                                                        <Box className='validation-list'>
                                                                            <ul>
                                                                                <li className={validLength ? "success" : "normal"}>
                                                                                    At least 8 characters
                                                                                </li>
                                                                                <li className={lowerCase ? "success" : "normal"}>
                                                                                    Lower case letters (a-z)
                                                                                </li>
                                                                                <li className={upperCase ? "success" : "normal"}>
                                                                                    Upper case letters (A-Z)
                                                                                </li>
                                                                                <li className={hasNumber ? "success" : "normal"}>
                                                                                    Numbers (0-9)
                                                                                </li>
                                                                                <li className={specialChar ? "success" : "normal"}>
                                                                                    Special characters (e.g. !@#$%^&*)
                                                                                </li>
                                                                            </ul>
                                                                        </Box>

                                                                        {/* {props.errors.newPassword && props.touched.newPassword ?
                                                                            <Typography className="set-up-account-validation-message" >
                                                                                {props.errors.newPassword}
                                                                            </Typography> : <></>} */}
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item xs={12} className="set-up-account-gird">
                                                                    <Box className="set-up-account-fields">
                                                                        <Typography>Confirm password</Typography>
                                                                        <TextField
                                                                            id='confirmPassword'
                                                                            sx={{ marginTop: 1 }}
                                                                            fullWidth
                                                                            placeholder='Confirm password'
                                                                            value={props.values.confirmPassword}
                                                                            onChange={props.handleChange("confirmPassword")}
                                                                            onBlur={props.handleBlur}
                                                                            type={values.showConfirmPassword ? "text" : "password"}
                                                                            error={
                                                                                props.errors.confirmPassword &&
                                                                                    props.touched.confirmPassword
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            InputProps={{
                                                                                // startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton
                                                                                            aria-label="toggle password visibility"
                                                                                            onClick={handleClickShowCConfPassword}
                                                                                            edge="end"
                                                                                        >
                                                                                            {values.showConfirmPassword ? (
                                                                                                <img loading="lazy" className='set-up-account-eye-icon' alt="imprend" src={eyeslash} />
                                                                                            ) : (
                                                                                                <img loading="lazy" className='set-up-account-eye-icon' alt="imprend" src={eye} />
                                                                                            )}
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        > </TextField>
                                                                        {props.errors.confirmPassword && props.touched.confirmPassword ?
                                                                            <Typography className="set-up-account-validation-message" >
                                                                                {props.errors.confirmPassword}
                                                                            </Typography> : <></>}
                                                                    </Box>
                                                                </Grid>

                                                            </Grid>
                                                        </Paper>

                                                        <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                                            <LoadingButton
                                                                color="primary"
                                                                sx={{ marginTop: 3 }}
                                                                loading={loading}
                                                                loadingPosition="start"
                                                                variant="contained"
                                                                //type="submit"
                                                                onClick={() => {
                                                                    props.submitForm();
                                                                    const err = Object.keys(props.errors);
                                                                    if (err.length) {
                                                                        const input = document.querySelector(
                                                                            `input[name=${err[0]}]`
                                                                        );

                                                                        if (input != null)
                                                                            input.scrollIntoView({
                                                                                behavior: 'smooth',
                                                                                block: 'center',
                                                                                inline: 'start',
                                                                            });
                                                                    }

                                                                }}
                                                                startIcon={<></>}
                                                            >
                                                                Submit
                                                            </LoadingButton>
                                                        </Grid>

                                                    </Paper>
                                                    :
                                                    <>
                                                        {isExpired === true ? <Grid className='set-up-password-not-found'><Typography className='set-up-password-not-found-text'>Invitation expired</Typography></Grid>
                                                            : <Grid className='set-up-password-not-found'><Typography className='set-up-password-not-found-text'>Something went wrong</Typography></Grid>}
                                                    </>
                                                }

                                            </Box>
                                        </React.Fragment>
                                    </Form>
                                )
                            }
                            }
                        </Formik>
                    </Grid>
                    <Grid className='set-up-account-img-wrapper' item>
                    </Grid>
                </Paper>
            </Grid>

            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={openWelcomeModal}
                // TransitionComponent={Transition}
                //onClose={handleCloseWelcomeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='set-up-account-welcome-dialog-box'
            >
                <Box className='set-up-account-welcome-img-box'>
                    <TaskAltIcon className='set-up-account-welecome-icon' />
                </Box>
                {/* <DialogTitle className='set-up-account-welcome-dialog-title'>Sign up success!</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='set-up-account-welcome-popup-text'>
                        <Typography className='set-up-account-welcome-popup-text1'>Sign up success!</Typography>
                        <Typography className='set-up-account-welcome-popup-text2'>Please login on next page.</Typography>

                    </DialogContentText>

                </DialogContent>
                <DialogActions className="set-up-account-welcome-dialog-action">
                    <Button
                        variant='contained'
                        onClick={CloseWelcomeModal}
                        className="set-up-account-welcome-close-btn"
                    >
                        SignIn
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    )
}
