import { Grid, Card, Typography } from "@mui/material";
import * as React from "react";
import Radio from '@mui/material/Radio';
import "./Decision.scss";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { PreferencesContext } from "../../../PreferenceContext";
import { AlertType } from "../../../shared/AlertType";
import { GetCessdetail, UpdateCessDetail } from "../../../services/CessService";

//export default function Decision() {
const Decision = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [selectedValue, setSelectedValue] = useState(false);
    const [questionAnswerId, setQuestionAnswerId] = useState(0);

    useImperativeHandle(ref, () => ({
        submitClick() {
            submitData();
        },
        getData() {
            fetchData();
        }
    }),);

    async function submitData() {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            try {
                let requestData = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: questionAnswerId,
                    roleUid: userContext.roleId,
                    itemId: userContext.cessId,
                    itemStatus: null,
                    itemValue: selectedValue.toString(),
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }
                let data: any;

                if (+param.curQuestion.number === 28) {
                    data = await UpdateCessDetail(requestData);

                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                }
                param.changeNextQuestion();
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            param.changeNextQuestion();
        }
    }

    async function fetchData() {
        //setShowLoder(isPageloading);
        try {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            let data: any;
            if (+param.curQuestion.number === 28) {
                params.append("uid", userContext.cessId.toString());
                data = await GetCessdetail(params);
            }

            if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
                let item = data.data.data as any;
                let value = item.detail_skip !== null ? item.detail_skip : false;
                let questionAnswerId = item.questionAnswer.length > 0 ? +item.questionAnswer[0].uid : 0;
                setQuestionAnswerId(questionAnswerId);
                setSelectedValue(value);
            } else {
                setQuestionAnswerId(0);
                // setSelectedValue(true);
                setSelectedValue(false);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        //setShowLoder(false);
    }

    return (
        <>
            <Card elevation={0} className="main-grid" >
                <Card elevation={0} className="card-main-grid" >
                    <Card elevation={0} className={selectedValue === true ? "card-one-box selected" : "card-one-box"} onClick={() => setSelectedValue(true)}>
                        <Radio
                            checked={selectedValue === true}
                            value={true}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                        />
                        <Grid className="text-css">
                            {/* <Typography className="typography-text">
                                Proceed is already well understand
                            </Typography> */}
                            <Typography className="typography-text">
                                Process is already well understood
                            </Typography>
                        </Grid>
                    </Card>
                </Card>
                <Card elevation={0} className={selectedValue === false ? "card-two-box selected" : "card-two-box"} onClick={() => setSelectedValue(false)}>
                    <Radio
                        checked={selectedValue === false}
                        value={false}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'B' }}
                    />
                    <Grid className="text-css">
                        <Typography className="typography-text">
                            Proceed to proceed builder
                        </Typography>
                    </Grid>
                </Card>
            </Card>
        </>
    );
});
export default Decision;