import { GET, POST, PUT,DELETE} from "./common/http";
import { BASE_URL } from "./common/const";


export const GetClientList = (params: any) =>
GET(`${BASE_URL}/clients?` + params, null);

export const GetClientDetail = (id: any) =>
GET(`${BASE_URL}/clients/` + id, null);

export const UpdateClient = (id: any, data: any) =>
PUT(`${BASE_URL}/clients/`+ id, data);

export const DeleteClient = (id: any, data: any) =>
DELETE(`${BASE_URL}/clients/` + id, data);

export const UpdateClientInfo = (data: any) =>
POST(`${BASE_URL}/clients/updateclientinfo`, data);