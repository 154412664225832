import React, { useContext, useEffect, useState } from 'react';
import { Box, Fade, Grid, Paper, Skeleton, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import "./DeletedHiagram.scss";
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { PAGE_SIZE } from '../../services/common/const';
import { GetArchivedHiagramByClientID, GetHiagramByClientId, ReinstateHiagram } from '../../services/HiagramService';
import { PreferencesContext } from '../../PreferenceContext';
import { AlertType } from '../../shared/AlertType';
import _ from 'lodash';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#FAF9F6",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const parameters: any = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
};

function formateDate(date: any) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    let curDate = new Date(date);
    let curDay = curDate.getDate();
    let curMonth = monthNames[curDate.getMonth()];
    let curYear = curDate.getFullYear();
    let curHour = curDate.getHours();
    let curMin = curDate.getMinutes();

    return (curMonth + " " + curDay + " " + curYear + " " + String(curHour).padStart(2, '0') + ":" + String(curMin).padStart(2, '0'));
}

export default function DeletedHiagram() {

    const [isLoading, setLoading] = React.useState(false);
    const [param, setParam] = useState(parameters);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [deletedHiagramData, setDeletedHiagramData] = useState([]);
    const [totalRecords, setTotalRecords] = React.useState(0);

    // DELETED HIAGRAM LIST BINDING
    async function fetchDeletedHigagramList() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("uId", userContext.userId.toString());
            params.append("cId", userContext.clientId.toString())
            params.append("pageIndex", param.pageIndex);
            params.append("pageSize", param.pageSize);
            const result: any = await GetArchivedHiagramByClientID(params);

            if (result.data.data != null) {

                let deletedHiagramDataRows = result.data.data.data;
                deletedHiagramDataRows.forEach((data: any) => {
                    data.isloading = false;
                });
                setDeletedHiagramData(deletedHiagramDataRows);
                let totalRecords = result.data.data.total_data_count;
                setTotalRecords(totalRecords);
            }
        }
        catch (e: any) {
            //API error
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.message,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }


    async function reinstateHiagram(id: any, uId: any, index: any) {

        let data: any = deletedHiagramData;

        data[index].isloading = true;
        setDeletedHiagramData(data);
        try {
            let requestData = {
                dataManagedBy: uId
            }
            const hiagram: any = await ReinstateHiagram(id, requestData);

            if (hiagram !== undefined && hiagram !== null && hiagram.data !== null && hiagram.data.data !== null && hiagram.data.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: hiagram.data.message,
                    alertType: AlertType.Success,
                });
                fetchDeletedHigagramList();

            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Somthing went wrong!",
                    alertType: AlertType.Error,
                });
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        data[index].isloading = false;
        setDeletedHiagramData(data);
    }


    useEffect(() => {
        fetchDeletedHigagramList();
    }, []);

    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box className="deletedhiagram-container">
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: "10px" }}
                        className="deletedhiagram-wrapper"
                    >
                        <Grid className="deletedhiagram-list-grid" container>
                            <Grid item xs={4} sx={{ textAlign: "start" }}>
                                {/* <Link className="deletedhiagram-backspace-Button-Back" to={"/myprofile"}> */}
                                {/* <Link className="deletedhiagram-backspace-Button-Back" to={"/all-hiagram"}> */}
                                <Link className="deletedhiagram-backspace-Button-Back" to={"/dashboard"}>
                                    <KeyboardBackspaceIcon />
                                    <b>Back</b>
                                </Link>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "center" }}>
                                <Typography variant='h5' className='deletedhiagram-title'><b>Archived hiagrams</b></Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                        </Grid>

                        <Grid></Grid>

                        <Grid container className="deletedhiagram-list-grid">
                            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ width: '5%' }}>#</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '40%', fontSize: 13 }}>Name</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Type</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Deleted date</StyledTableCell>
                                            <StyledTableCell align="center" sx={{ width: '20%', fontSize: 13 }}>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <>
                                            {isLoading === true ? (
                                                _.times(5, (i) => (
                                                    <TableRow key={i} >
                                                        <TableCell colSpan={5}>
                                                            <Skeleton variant="rounded" animation="wave" width='auto' height={25} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                deletedHiagramData.length > 0 ? (
                                                    deletedHiagramData.map((row: any, index: any) => (
                                                        <StyledTableRow
                                                            key={index}
                                                        >
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {row.hiagram_name}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {row.hiagram_type}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {formateDate(row.modified_date)}
                                                            </StyledTableCell>

                                                            <StyledTableCell>
                                                                {/* <Grid container spacing={2}> */}
                                                                <Box className='deletedhiagram-btn-box'>
                                                                    <Tooltip title='Reinstate'>
                                                                        <LoadingButton
                                                                            variant='contained'
                                                                            className='deletedhiagram-reinstate-btn'
                                                                            loadingPosition='center'
                                                                            loading={row.isloading}
                                                                            onClick={() => reinstateHiagram(row.hiagram_uid, row.user_uid, index)}
                                                                        >
                                                                            Reinstate
                                                                        </LoadingButton>
                                                                    </Tooltip>

                                                                </Box>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))) : (
                                                    <TableRow key={1}>
                                                        <TableCell colSpan={5} align='center'>
                                                            None added yet!
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Paper>
                </Box>
            </Fade>
        </>
    );
}
