import React, { useContext, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Fade, Slide, Typography } from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { PreferencesContext } from '../../PreferenceContext';
import { useAuth0 } from '@auth0/auth0-react';
import { VerifyClientEmail } from '../../services/UserService';
import { AlertType } from '../../shared/AlertType';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function VerifyEmail() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [pageLoading, setPageLoading] = React.useState(true);
    const [openModal, setModal] = React.useState(false);
    const [wrong, setwrong] = React.useState(false);
    const { logout } = useAuth0();
    let navigate = useNavigate();

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let urlParams = useQuery();

    const onClickReturn = () => {
        navigate("/");
    };
    const gotoHome = () => {
        logout();
        navigate("/");
    };

    async function verifyUserEmail(auth_token: any) {
        try {
            let requestData = {
                token: auth_token
            }
            let data: any = await VerifyClientEmail(requestData);
            if (data !== null && data.data === true) {
                setModal(true);
                setwrong(false);
                updateUserContext({
                    ...userContext,
                    emailConfirmed: true
                });
            } else {
                setModal(true);
                setwrong(true);
            }
            setPageLoading(false);
        } catch (error: any) {
            setPageLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    useEffect(() => {
        let auth_token = urlParams.get("t");
        if (auth_token !== null && auth_token !== undefined) {
            verifyUserEmail(auth_token);
        } else {
            setPageLoading(false);
            setwrong(true);
            setModal(true);
        }

    }, []);

    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box className='verify-email-main-box'>
                    {pageLoading ?
                        <Box className="main-loding">
                            <img loading="lazy" alt="imprend" src='/loader.gif' style={{ height: '200px', width: 'auto' }}></img>
                        </Box> : <></>
                    }

                    <Dialog
                        maxWidth="xs"
                        fullWidth={true}
                        open={openModal}
                        TransitionComponent={Transition}
                        // TransitionComponent={Transition}
                        //onClose={handleCloseWelcomeModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className='email-verify-dialog-box'
                    >
                        <Box className='img-box'>
                            {wrong ?
                                <ErrorOutlineIcon className='error-icon' /> :
                                <TaskAltIcon className='icon' />
                            }
                        </Box>
                        {/* <DialogTitle className='set-up-account-welcome-dialog-title'>Sign up success!</DialogTitle> */}
                        <DialogContent>
                            <Box id="alert-dialog-description" className='popup-text'>
                                {wrong ?
                                    <Typography className='popup-text1'>Somthing went wrong!</Typography>
                                    : <>
                                        <Typography className='popup-text1'>Email confirmed!</Typography>
                                        <Typography className='popup-text2'>Continue through to set your organization up for success.</Typography>
                                    </>}

                            </Box>

                        </DialogContent>
                        <DialogActions className="dialog-action">
                            {wrong ?
                                <Button
                                    variant='contained'
                                    onClick={gotoHome}
                                    className="close-btn"
                                >
                                    Home
                                </Button>
                                : <Button
                                    variant='contained'
                                    onClick={onClickReturn}
                                    className="close-btn"
                                >
                                    Continue
                                </Button>
                            }

                        </DialogActions>
                    </Dialog>
                </Box>

            </Fade>
        </>
    );
}