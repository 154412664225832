import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Grid, Paper, Skeleton, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, styled, tableCellClasses, useTheme } from '@mui/material';
import "./AllHiagram.scss";
import { LoadingButton } from "@mui/lab";
import logouticon from "../../assets/icons/logout.svg";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AddIcon from '@mui/icons-material/Add';
import editIcon from '../../assets/icons/edit-2.svg';
import viewIcon from '../../assets/icons/view-hiagram.svg'
import trashIcon from '../../assets/icons/trash.svg';
import trashRedIcon from '../../assets/icons/trash-red.svg';
import userImage from '../../assets/icons/inviteUserImage.png'
import { PreferencesContext } from '../../PreferenceContext';
import _ from 'lodash';
import { ArchiveHiagram, DeleteShareHiagram, GetHiagramList, GetShareHiagramList } from '../../services/HiagramService';
import { AlertType } from '../../shared/AlertType';
import { TransitionProps } from '@mui/material/transitions/transition';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AllHiagram() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const { innerHeight: windowHeight } = window;
    const [flowDivHeight, setFlowDivHeight] = useState((windowHeight - 144) + "px");
    const [tableHeight, setTableDivHeight] = useState((windowHeight - 344) + "px");
    const { logout, isAuthenticated, user } = useAuth0();
    const [isLoading, setLoading] = useState(false);
    const [isBtnLoading, setBtnLoading] = React.useState(false);
    const [openDeleteModal, setDeleteModal] = React.useState(false);
    const [openWelcomeModal, setWelcomeModal] = useState(false);
    const [shareHiagramDeleteModal, setShareHiagramDeleteModal] = useState(false);
    const [selectedHiagram, setSelectedHiagram] = useState(0);
    const [selectedShareHiagram, setSelectedShareHiagram] = useState(0);
    const [hiagramList, setHiagramList] = useState([]);
    const [sharedHiagramList, setSharedHiagramList] = useState([]);
    const mainTheme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: mainTheme.palette.primary.main,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            paddingTop: "0px ! important",
            paddingBottom: "0px ! important"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAF9F6",
            paddingTop: "0px ! important",
            paddingBottom: "0px ! important"
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
            paddingTop: "0px ! important",
            paddingBottom: "0px ! important"
        },
    }));

    let navigate = useNavigate();

    const gotoDashboard = () => {
        navigate("/dashboard");
    };

    const gotoDeletedHiagram = () => {
        navigate("/archive-hiagram");
    }

    const signOut = () => {
        logout({ logoutParams: { returnTo: userContext.clientIsFree === true ? window.location.origin + "/free-login" : window.location.origin } });
    };

    function formateDate(date: any) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        let curDate = new Date(date);
        let curDay = curDate.getDate();
        let curMonth = monthNames[curDate.getMonth()];
        let curYear = curDate.getFullYear();
        let curHour = curDate.getHours();
        let curMin = curDate.getMinutes();

        return (curMonth + " " + curDay + " " + curYear + " " + String(curHour).padStart(2, '0') + ":" + String(curMin).padStart(2, '0'));
    }

    const handleWelcomeModal = () => {
        setWelcomeModal(true);
    }

    const handleOpenDeleteModal = () => {
        setDeleteModal(true);
    };

    const handleOpenDeleteShareHiagramModal = () => {
        setShareHiagramDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
        setSelectedHiagram(0);
    };

    const handleCloseWelcomeModal = () => {
        setWelcomeModal(false);
        updateUserContext({
            ...userContext,
            firstLogin: false
        });
    }
    const handleCloseDeleteShareHiagramModal = () => {
        setShareHiagramDeleteModal(false);
        setSelectedShareHiagram(0);
    };

    function editHiagram(data: any) {
        updateUserContext({
            ...userContext,
            // hp code start
            tempUid: +data.temp_uid,
            // hp code end
            hiagramId: +data.hiagram_uid,
            hiagramName: data.hiagram_name,
            hiagramType: data.hiagram_type,
            hiagramProgress: data.progress === null ? 0 : data.progress,
            isEditAccess: true
        });
        navigate("/resumehiagram");
    }

    function viewHiagram(data: any) {
        updateUserContext({
            ...userContext,
            hiagramId: +data.hiagram_uid,
            hiagramName: data.hiagram_name,
            hiagramType: data.hiagram_type,
            hiagramProgress: data.progress === null ? 0 : data.progress,
            tempUid: +data.temp_uid,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjId: 0,
            teamProjName: "",
            roleId: 0,
            roleName: "",
            activityId: 0,
            activityName: "",
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: "",
            isProj: "false",
            lastQuestionInfo: null,
            isEditAccess: false
        });
        navigate("/hiagram/" + userContext.hiagramId);
    }

    function deleteHiagramClick(data: any) {
        setSelectedHiagram(+data.hiagram_uid);
        // handleOpenDeleteModal()
    }

    function deleteShareHiagramClick(data: any) {
        setSelectedShareHiagram(+data.sharehiagram[0].sharehiagram_uid);
    }

    async function archiveHiagram() {
        setBtnLoading(true);
        try {
            let requestData = {
                clientUid: userContext.clientId,
                userUid: userContext.userId,
                isArchived: true,
                dataManagedBy: userContext.userId
            }
            let data: any;
            data = await ArchiveHiagram(selectedHiagram, requestData);
            if (data !== null && data.data === true) {
                setBtnLoading(false);
                fetchHiagramList();
                handleCloseDeleteModal();
            }
        }
        catch (error: any) {
            setBtnLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function deleteShareHiagram() {
        setBtnLoading(true);
        try {
            let requestData = {
                clientUid: userContext.clientId,
                userUid: userContext.userId,
                isDeleted: true,
                dataManagedBy: userContext.userId
            }
            let data: any;
            data = await DeleteShareHiagram(selectedShareHiagram, requestData);
            if (data !== null && data.data === true) {
                setBtnLoading(false);
                fetchShareHiagramList();
                handleCloseDeleteShareHiagramModal();
            }
        }
        catch (error: any) {
            setBtnLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchHiagramList() {
        try {
            setLoading(true);
            let params = new URLSearchParams();
            params.append("cuid", userContext.clientId.toString());
            params.append("uuid", userContext.userId.toString());
            const hiagrams: any = await GetHiagramList(params);
            if (hiagrams !== null && hiagrams.data !== null && hiagrams.data.data !== null) {
                setHiagramList(hiagrams.data.data);
                setLoading(false);
            }

        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchShareHiagramList() {
        try {
            setLoading(true);
            let params = new URLSearchParams();
            params.append("cuid", userContext.clientId.toString());
            params.append("uuid", userContext.userId.toString());
            const sharehiagrams: any = await GetShareHiagramList(params);
            if (sharehiagrams !== null && sharehiagrams.data !== null && sharehiagrams.data.data !== null) {
                setSharedHiagramList(sharehiagrams.data.data);
                setLoading(false);
            }

        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    useEffect(() => {
        fetchHiagramList();
        fetchShareHiagramList();
    }, []);

    useEffect(() => {
        if (userContext.firstLogin === true) {
            handleWelcomeModal()
        }
    }, []);
    useEffect(() => {
        if (selectedHiagram > 0) {
            handleOpenDeleteModal()
        }
    }, [selectedHiagram]);

    useEffect(() => {
        if (selectedShareHiagram > 0) {
            handleOpenDeleteShareHiagramModal()
        }
    }, [selectedShareHiagram]);

    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box className='all-hiagram-container'>
                    <Paper elevation={3}
                        sx={{ borderRadius: '10px', minHeight: '640px' }}
                        className="wrapper all-hiagram-paper"
                        style={{ height: flowDivHeight }}
                    >
                        <Box className='all-hiagram-logout'>
                            <LoadingButton
                                className="button-sign-out"
                                variant="contained"
                                onClick={signOut}
                                endIcon={
                                    <img loading="lazy" src={logouticon} alt="logo" />
                                }
                            >
                                <Typography >Sign Out</Typography>
                            </LoadingButton>
                        </Box>

                        <Box className="all-hiagram-main-box">
                            <Box className={userContext.clientIsFree === true ? "all-hiagram-left" : "all-hiagram-left higram-right-border"}>
                                <Box className='all-hiagram-left-title'>
                                    <Typography className='all-hiagram-left-title-text'>Your hiagrams</Typography>
                                </Box>
                                <Box className='all-hiagram-left-button'>
                                    <Button className="profile-add"
                                        startIcon={<AddIcon sx={{ color: mainTheme.palette.primary.main }} />}
                                        onClick={() => gotoDashboard()} >
                                        New
                                    </Button>

                                    <Button className="archived-hiagrams"
                                        // startIcon={<AddIcon sx={{ color: mainTheme.palette.primary.main }} />}
                                        onClick={() => gotoDeletedHiagram()} >
                                        Archived hiagrams
                                    </Button>
                                </Box>
                                <TableContainer
                                    sx={{ marginTop: "10px" }}
                                    className='tablecontainer'
                                    style={{ height: tableHeight }}
                                    component={Paper}>
                                    <Table stickyHeader sx={{ width: ' 100%' }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Name</StyledTableCell>
                                                <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Last updated</StyledTableCell>
                                                <StyledTableCell align="center" sx={{ width: '20%', fontSize: 13 }}>Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <>
                                                {isLoading === true ? (
                                                    _.times(4, (i) => (
                                                        <TableRow key={i}>
                                                            <TableCell colSpan={3}>
                                                                <Skeleton variant="rounded" width='auto' height={25} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))) : hiagramList.length > 0 ? (hiagramList && hiagramList.map((row: any, index: any) => {
                                                        return (
                                                            <StyledTableRow
                                                                sx={{ fontSize: 12 }}
                                                                key={index}
                                                            // onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                            // onMouseLeave={handlePopoverClose}
                                                            >
                                                                <StyledTableCell align="left">
                                                                    {row.hiagram_name}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    {formateDate(row.modified_date === null ? row.created_date : row.modified_date)}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                // onMouseEnter={handlePopoverClose}
                                                                >
                                                                    <Grid container spacing={2} className='profile-left-btn-grid'>
                                                                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                                            <Tooltip title='Edit'>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    className='profile-left-edit-btn'
                                                                                    onClick={() => editHiagram(row)}
                                                                                >
                                                                                    <img loading="lazy" alt="imprend" className='profile-left-edit-btn-logo' src={editIcon} />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                        <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                                            <Tooltip title='Archive'>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    className='profile-left-delete-btn'
                                                                                    onClick={() => deleteHiagramClick(row)}
                                                                                >
                                                                                    <img loading="lazy" alt="imprend" className='profile-left-delete-btn-logo' src={trashIcon} />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })) : (
                                                    <TableRow key={1}>
                                                        <TableCell colSpan={3} align='center'>
                                                            None added yet!
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            {userContext.clientIsFree === true ? <></> :
                                <Box className='all-hiagram-right'>
                                    <Box className='all-hiagram-right-title'>
                                        <Typography className='all-hiagram-right-title-text'>Shared with you</Typography>
                                    </Box>
                                    {/* <Box className='all-hiagram-right-button'>
                                <Button className="archived-hiagrams"
                                    // startIcon={<AddIcon sx={{ color: mainTheme.palette.primary.main }} />
                                    onClick={() => gotoDeletedHiagram()} >
                                    Archived hiagrams
                                </Button>
                            </Box> */}
                                    <TableContainer
                                        sx={{ marginTop: "10px" }}
                                        className='tablecontainer'
                                        style={{ height: tableHeight }}
                                        component={Paper}
                                    >
                                        <Table stickyHeader sx={{ width: ' 100%' }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Name</StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Last updated</StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ width: '20%', fontSize: 13 }}>Action</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <>
                                                    {isLoading === true ? (
                                                        _.times(4, (i) => (
                                                            <TableRow key={i}>
                                                                <TableCell colSpan={3}>
                                                                    <Skeleton variant="rounded" width='auto' height={25} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))) : sharedHiagramList.length > 0 ? (sharedHiagramList && sharedHiagramList.map((row: any, index: any) => {
                                                            return (
                                                                <StyledTableRow
                                                                    sx={{ fontSize: 12 }}
                                                                    key={index}
                                                                // onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                                // onMouseLeave={handlePopoverClose}
                                                                >
                                                                    <StyledTableCell align="left">
                                                                        {row.hiagram_name}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        {formateDate(row.modified_date === null ? row.created_date : row.modified_date)}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                    // onMouseEnter={handlePopoverClose}
                                                                    >
                                                                        <Grid container spacing={2} className='profile-right-btn-grid'>
                                                                            <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                                                <Tooltip title='View'>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        className='profile-right-view-btn'
                                                                                        onClick={() => viewHiagram(row)}
                                                                                    >
                                                                                        <img loading="lazy" alt="imprend" className='profile-right-view-btn-logo' src={viewIcon} />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                            <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                                                <Tooltip title='Delete'>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        className='profile-right-delete-btn'
                                                                                        onClick={() => deleteShareHiagramClick(row)}
                                                                                    >
                                                                                        <img loading="lazy" alt="imprend" className='profile-right-delete-btn-logo' src={trashIcon} />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                        })) : (
                                                        <TableRow key={1}>
                                                            <TableCell colSpan={3} align='center'>
                                                                None added yet!
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>}
                        </Box>
                    </Paper>
                </Box >
            </Fade>
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={openDeleteModal}
                TransitionComponent={Transition}
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='reset-dialog-box'
            >
                <Box className='img-box'>
                    <img loading="lazy" alt="imprend" className='delete-icon' src={trashRedIcon} />
                </Box>
                <DialogTitle className='dialog-title'>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Hiagram will be archived.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        // onClick={() => deleteHiagram()}
                        onClick={() => archiveHiagram()}
                        className="delete-btn"
                        loading={isBtnLoading}
                    >
                        Archive
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseDeleteModal}
                        className="keep-btn"
                    >
                        Keep
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={shareHiagramDeleteModal}
                TransitionComponent={Transition}
                onClose={handleCloseDeleteShareHiagramModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='reset-dialog-box'
            >
                <Box className='img-box'>
                    <img loading="lazy" alt="imprend" className='delete-icon' src={trashRedIcon} />
                </Box>
                <DialogTitle className='dialog-title'>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Share hiagram will be archived.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        // onClick={() => deleteHiagram()}
                        onClick={() => deleteShareHiagram()}
                        className="delete-btn"
                        loading={isBtnLoading}
                    >
                        Delete
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseDeleteShareHiagramModal}
                        className="keep-btn"
                    >
                        Keep
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={openWelcomeModal}
                // TransitionComponent={Transition}
                //onClose={handleCloseWelcomeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='welcome-dialog-box'
            >
                <Box className='welcome-img-box'>
                    <img loading="lazy" alt="imprend" className='welecome-icon' src={userImage} />
                </Box>
                <DialogTitle className='welcome-dialog-title'>Welcome {userContext.firstName}!</DialogTitle>
                <DialogContent>
                    {userContext.userRoleId === '3' ?
                        <DialogContentText id="alert-dialog-description" className='welcome-popup-text'>
                            Use the top right menu to set up your staff with access.
                        </DialogContentText>
                        :
                        (
                            userContext.clientIsFree === true ?
                                <DialogContentText id="alert-dialog-description" className='welcome-popup-text'>
                                    You can see all your hiagrams on your home page here.
                                </DialogContentText>
                                :
                                <DialogContentText id="alert-dialog-description" className='welcome-popup-text'>
                                    You can see all the higrams you’ve made or have been shared with you on your home page.
                                </DialogContentText>
                        )

                    }
                </DialogContent>
                <DialogActions className="welcome-dialog-action">
                    <Button
                        variant='contained'
                        onClick={handleCloseWelcomeModal}
                        className="welcome-close-btn"
                    >
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    )
}