import { useCallback } from 'react';
import { NodeProps, useReactFlow, getOutgoers, MarkerType, getIncomers } from 'reactflow';

import { uuid, randomLabel } from '../utils';

// this hook implements the logic for clicking a workflow node
// on workflow node click: create a new child node of the clicked node
export function useNodeAddChild(id: NodeProps['id'], title: any, description: any) {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();

  const onClick = useCallback(() => {
    const parentNode = getNode(id);

    if (!parentNode) {
      return;
    }
    if (id === '1') {
      const allStartChilds = getOutgoers(parentNode, getNodes(), getEdges()).filter((node) =>  node.id !== '2');
      if (allStartChilds !== null && allStartChilds !== undefined && allStartChilds.length > 0) {
        return;
      }
    }
    let allSteps = getNodes();
  
    //const childNodeId = uuid();
    const childNodeId = (Math.max(...allSteps.map(step => +step.id)) + 1).toString();
    //console.log(childNodeId);
    const childNode = {
      id: childNodeId,
      position: { x: parentNode.position.x, y: parentNode.position.y + 200 },
      type: 'step',
      data: { title: title === "" ? randomLabel() : title, isMenuOpen: false ,description: description},
    };

    const childEdge = {
      id: `${parentNode.id}=>${childNodeId}`,
      source: parentNode.id,
      target: childNodeId,
      type: 'step',
      style: { stroke: '#680001', strokeDasharray: 3 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: '#680001',
        height: 20,
        width: 20,
      },
    };

    setNodes((nodes) =>
      nodes.concat([childNode])
    );

    setEdges((edges) =>
      edges.concat([childEdge])
    );

    //add mapping to all parent
    const allParentIds = getIncomers(parentNode, getNodes(), getEdges()).map((node) => node.id);

    const topParentNode = getNode(allParentIds[0]);

    if (topParentNode) {
      const allChilds = getOutgoers(topParentNode, getNodes(), getEdges());
      if (allChilds.length > 0) {
        //Connect with parents
        allChilds.forEach(child => {
          if (child.id !== id) {
            const childEdge = {
              id: `${child.id}=>${childNodeId}`,
              source: child.id,
              target: childNodeId,
              type: 'step',
              style: { stroke: '#680001', strokeDasharray: 3 },
              markerEnd: {
                type: MarkerType.Arrow,
                color: '#680001',
                height: 20,
                width: 20,
              },
            };
            setEdges((edges) =>
              edges.concat([childEdge])
            );
          }
        });

        //Connect with parent childs
        const allInnerChilds = getOutgoers(allChilds[0], getNodes(), getEdges());
        if (allInnerChilds.length > 0) {
          const allMapChilds = getOutgoers(allInnerChilds[0], getNodes(), getEdges());
          if (allMapChilds.length > 0) {
            allMapChilds.forEach(child => {
              const childEdge = {
                id: `${childNodeId}=>${child.id}`,
                source: childNodeId,
                target: child.id,
                type: 'step',
                style: { stroke: '#680001', strokeDasharray: 3 },
                markerEnd: {
                  type: MarkerType.Arrow,
                  color: '#680001',
                  height: 20,
                  width: 20,
                },
              };
              setEdges((edges) =>
                edges.concat([childEdge])
              );
            });
          }
        }
      }
    }

    //Remove all end edges
    const endNode = getNode('2');
    if (endNode) {
      const allParentIds = getIncomers(endNode, getNodes(), getEdges()).map((node) => node.id);
      if (allParentIds.length > 0) {
        allParentIds.forEach(node => {
          setEdges((edges) =>
            edges.filter((edge) => edge.id !== `${node}=>2`));
        });
      }
    }

    //Connect to end node
    const allNodes = getNodes();
    if (allNodes.length > 0) {
      allNodes.forEach(node => {
        const allChilds = getOutgoers(node, getNodes(), getEdges());
        if (allChilds.length === 0 && node.id != '2') {
          const endEdge = {
            id: `${node.id}=>2`,
            source: node.id,
            target: '2',
            type: 'step',
            style: { stroke: '#680001', strokeDasharray: 3 },
            markerEnd: {
              type: MarkerType.Arrow,
              color: '#680001',
              height: 20,
              width: 20,
            },
          };
          setEdges((edges) =>
            edges.concat([endEdge])
          );
        }
      });
    }

  }, [getEdges, getNode, getNodes, id, setEdges, setNodes]);

  return onClick;
}

export default useNodeAddChild;
