import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetUserList = (id: any, params: any) =>
    GET(`${BASE_URL}/users/` + id + "?" + params, null);

export const getUserDetail = (params: any) =>
    GET(`${BASE_URL}/users/getdetail?` + params, null);

export const updateUserPassword = (data: any) =>
    POST(`${BASE_URL}/users/updateuserpassword`, data);

export const UpdateUser = (data: any) =>
    POST(`${BASE_URL}/users/updateuser`, data);

export const Reset = (data: any) =>
    POST(`${BASE_URL}/users/reset`, data);

export const DeleteUser = (id: any, data: any) =>
    DELETE(`${BASE_URL}/users/` + id, data);

export const VerifyClientEmail = (data: any) =>
    POST(`${BASE_URL}/users/verifyemail`, data);