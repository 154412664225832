import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetRaciList = () =>
    GET(`${BASE_URL}/activity/getAllRaci?`, null);

export const GetActivityList = (params: any) =>
    GET(`${BASE_URL}/activity/list?` + params, null);

export const CreateActivity = (data: any) =>
    POST(`${BASE_URL}/activity/create`, data);

export const GetActivitydetail = (params: any) =>
    GET(`${BASE_URL}/activity/getdetail?` + params, null);

export const UpdateActivity = (data: any) =>
    POST(`${BASE_URL}/activity/update`, data);

export const UpdateActivityRank = (data: any) =>
    POST(`${BASE_URL}/activity/updaterank`, data);

export const GetActivitydetailById = (params: any) =>
    GET(`${BASE_URL}/activity/getdetailbyuid?` + params, null);

export const ActivityGetAllDetail = (params: any) =>
    GET(`${BASE_URL}/activity/hiagramgetalldetail?` + params, null);

export const GetActivityDetailForEdit = (params: any) =>
    GET(`${BASE_URL}/activity/getdetailforedit?` + params, null);

export const UpdateActivityDataOfActivityCard = (data: any) =>
    POST(`${BASE_URL}/activity/updatecard`, data);

export const UpdateCurActivityDetail = (data: any) =>
    POST(`${BASE_URL}/activity/updatecuractivitydetail`, data);

export const DeleteActivity = (id: any, data: any) =>
    DELETE(`${BASE_URL}/activity/` + id, data);