import React from 'react';
import "./NodeComponent.scss";
import { Avatar, Box, Grid, Paper, styled, Slide, Card, Tooltip, Typography, Skeleton } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useContext, useEffect, useState } from "react";
import { TransitionProps } from '@mui/material/transitions';
import EventTimeLine from '../EventTimeLine/EventTimeLine';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NodeComponent(props: any) {
    const [isUpdate, setIsUpdate] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialog(false);
    };

    return (
        <>
            <Card className='card-one-box'>
                <Grid container >
                    <Grid item xs={4} className='icon-css'>
                        {props.isLoading === true ?
                            <Skeleton variant="circular" animation="wave" width={42} height={42} sx={{ marginBottom: '5px' }} /> :
                            <img loading="lazy" alt="imprend" src={props.iconSrc} />
                        }
                    </Grid>
                    <Grid item xs={8} className="text-css">
                        {props.isLoading === true ?
                            <Skeleton variant="text" animation="wave" sx={{ fontSize: '12px', width: "70%" }} /> :
                            <Typography className="typography-text" >
                                {props.text}
                            </Typography>
                        }
                        {props.isLoading === true ?
                            <Skeleton variant="text" animation="wave" sx={{ fontSize: '18px', width: "30%" }} /> :
                            <Typography className="typography-number" >
                                {props.number}
                            </Typography>
                        }
                        <Box className='border-div' sx={{ backgroundColor: props.isLoading === true ? "#0000001c" : props.border }}>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            <Dialog
                maxWidth={isUpdate === true ? "md" : "xl"}
                fullWidth={true}
                open={openDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ paddingBottom: "50px" }}>
                    {props.openActionDeleteModal === 'Event' ?
                        <EventTimeLine
                            onClose={handleClose} />
                        : <></>}
                </DialogContent>
            </Dialog>
        </>
    );
}

