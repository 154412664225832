import { Button, Grid, Paper, styled, Table, Skeleton, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme, Popover, IconButton, Box } from "@mui/material";
import * as React from "react";
import Doc from '../../../assets/icons/doc.svg'
import ClearIcon from '@mui/icons-material/Clear';
import "./ItemDocAddmodel.scss";
import * as Yup from "yup";
import { AlertType } from "../../../shared/AlertType";
import { useContext, useEffect, useState } from "react";
import { PreferencesContext } from "../../../PreferenceContext";
import AddIcon from '@mui/icons-material/Add';
import { Form, Formik } from "formik";
import { CreateDoc, CreateDocMapping, GetAllDocList, GetDocDetail, GetTypeList, UpdateDoc } from "../../../services/DocService";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import _ from "lodash";
import { LoadingButton } from "@mui/lab";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4D81B4",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#F7F9FB",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formInitialValues = {
    id: 0,
    // type: "",
    name: "",
    fileName: "",
    location: "",
    // link: "",
    description: "",
    mappingId: 0,
    appendToType: "",
    appendUid: 0
};

export default function ItemDocAddModel(param: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [typeData, setTypeData] = useState([]);
    const [formInitial, setFormInitial] = useState(formInitialValues);
    const [allDocData, setAllDocData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [openGuidanceDialog, setOpenGuidanceDialog] = useState<HTMLElement | null>(null);
    const [guidance, setGuidance] = useState("");
    // const mainTheme = useTheme();
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [isFocusedName, setFocusedName] = useState(false);
    const [isFocusedFileName, setFocusedFileName] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);
    const [isFocusedLocation, setFocusedLocation] = useState(false);

    const handlePopoverOpenForDoc = (event: React.MouseEvent<HTMLElement>) => {
        setOpenGuidanceDialog(event.currentTarget);
        setGuidance(param.curQuestion.guidance);
    };

    const handlePopoverCloseForDoc = () => {
        setOpenGuidanceDialog(null);
    };

    const open = Boolean(openGuidanceDialog);

    async function fetchType() {
        try {
            const typeGetData: any = await GetTypeList();
            if (typeGetData !== undefined && typeGetData !== null && typeGetData.data !== null && typeGetData.data.data !== null && typeGetData.data.data.data !== null) {
                setTypeData(typeGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchDoc(id: any, aId: any) {
        setDataLoading(true);
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("dmuid", aId.toString());
        params.append("duid", id.toString());
        const docres: any = await GetDocDetail(params);
        if (docres !== null && docres.data !== null && docres.data.data !== null) {
            let data = docres.data.data;
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            if (+param.curQuestion.number === 11) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 18) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 26) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            } else if (+param.curQuestion.number === 35) {
                appendType = "STEP";
                appendUID = userContext.stepId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            const formValues = {
                id: +data.uid,
                // type: data.type,
                name: data.name,
                fileName: data.file_name,
                location: data.location,
                // link: data.link,
                description: data.description,
                mappingId: +data.docMapping[0].uid,
                appendToType: appendType,
                appendUid: appendUID
            };
            setFormInitial(formValues);
        }
        setDataLoading(false);
    }

    async function fetchDocList() {
        setLoading(true);
        let appendType = "";
        let appendUID = 0;
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        if (+param.curQuestion.number === 6) {
            appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            appendUID = userContext.teamProjId;
        } else if (+param.curQuestion.number === 11) {
            appendType = "ROLE";
            appendUID = userContext.roleId;
        } else if (+param.curQuestion.number === 18) {
            appendType = "ACTIVITY";
            appendUID = userContext.activityId;
        } else if (+param.curQuestion.number === 26) {
            appendType = "CESS";
            appendUID = userContext.cessId;
        } else if (+param.curQuestion.number === 35) {
            appendType = "STEP";
            appendUID = userContext.stepId;
        }
        if (param.curQuestion.hiagram !== undefined) {
            appendType = param.curQuestion.hiagram.type;
            appendUID = param.curQuestion.hiagram.typeId;
        }
        params.append("appendType", appendType.toString());
        params.append("appendUid", appendUID.toString());
        const Docs: any = await GetAllDocList(params);
        if (Docs !== null && Docs.data !== null && Docs.data.data !== null) {
            let data = Docs.data.data;
            setAllDocData(data);
        }
        setLoading(false);
    }

    async function submitForm(values: any, resetForm: any) {
        setBtnLoading(true);
        try {
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            let isFromExisting = false;
            if (+param.curQuestion.number === 11) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 18) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 26) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            } else if (+param.curQuestion.number === 35) {
                appendType = "STEP";
                appendUID = userContext.stepId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            if (values.id > 0 && values.mappingId === 0) {
                let curDoc: any = allDocData.find((x: any) => x.uid === values.id);
                if (
                    // values.type === curDoc.type && 
                    values.name === curDoc.name &&
                    // values.link === curDoc.link && 
                    values.fileName === curDoc.file_name && values.location === curDoc.location
                    && values.description === curDoc.description) {
                    isFromExisting = true;
                } else {
                    values.id = 0;
                }
            }

            let requestData = {
                docUid: values.id,
                docMappingUid: values.mappingId,
                hiagramUid: userContext.hiagramId,
                // type: values.type,
                name: values.name,
                fileName: values.fileName,
                location: values.location,
                // link: values.link,
                description: values.description,
                appendToType: appendType,
                appendUid: appendUID,
                isDeleted: false,
                dataManagedBy: userContext.userId
            }
            let data: any;
            if (isFromExisting) {
                data = await CreateDocMapping(requestData);
            } else if (values.id !== null && values.id > 0) {
                data = await UpdateDoc(requestData);
            } else {
                data = await CreateDoc(requestData);
            }
            //
            setFormInitial(formInitialValues);
            resetForm();
            param.onSubmitClose();
            updateUserContext({
                ...userContext,
                isItemCountUpdated: true
            });
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    const handleClickAdd = (id: any) => {
        let curDoc: any = allDocData.find((x: any) => x.uid === id);
        const formInitial = {
            id: curDoc.uid,
            // type: curDoc.type,
            name: curDoc.name,
            fileName: curDoc.file_name,
            location: curDoc.location,
            // link: curDoc.link,
            description: curDoc.description,
            mappingId: 0,
            appendToType: "",
            appendUid: 0
        };
        setFormInitial(formInitial);
    }

    const formValidation = Yup.object().shape({
        name: Yup.string().required("Name is required."),
        // type: Yup.string().required("Type is required."),
    });
    useEffect(() => {
        if (param.isOpen) {
            setFormInitial(formInitialValues);
            fetchType();
            if (param.id > 0) {
                fetchDoc(param.id, param.mappingId);
            } else {
                fetchDocList();
            }
        }
    }, [param.isOpen]);


    return (
        <Formik
            enableReinitialize
            initialValues={formInitial}
            validationSchema={formValidation}
            onSubmit={(values: any, { resetForm }) => {
                submitForm(values, resetForm);
            }}
        >{(props: any) => {
            return (
                <>
                    <Grid container className="main-grid-model-doc-add">
                        <Grid className="border-grid-left" item xs={param.isUpdate === true ? 12 : 6} paddingRight={param.isUpdate === true ? "30px" : "50px"}>
                            <Form onSubmit={props.handleSubmit} noValidate>
                                <Grid container>
                                    <Grid className="header-left" sx={{ display: "flex", padding: 1 }} item xs={12}>
                                        <img loading="lazy" alt="imprend" className='icon-stakeholders' src={Doc} />
                                        {param.isUpdate === true ?
                                            <Typography className="icon-text">Edit Doc</Typography>
                                            :
                                            <Typography className="icon-text">Add new Doc</Typography>
                                        }
                                        {param.QuickView === false ?
                                            <Tooltip title="Guidance">
                                                <IconButton
                                                    className="doc-guidance-icon"
                                                    aria-label="Guidance"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    disableRipple
                                                    onClick={handlePopoverOpenForDoc}
                                                >
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : <></>}
                                    </Grid>
                                    {/* {param.isUpdate === true ?
                                        <Grid item xs={2} className="clear-icon-edit">
                                            <Button
                                                disableRipple
                                                className="btn-cancel"
                                                onClick={() => {
                                                    setFormInitial(formInitialValues);
                                                    props.resetForm();
                                                    param.onClose();
                                                }}>
                                                <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                            </Button>
                                        </Grid> :
                                        <></>} */}
                                </Grid>



                                {/* <Grid item xs={12} sx={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                    <Grid item xs={2} className="text-grid-textfield">
                                        <Typography className="text-typography">Type</Typography>
                                    </Grid>
                                    <Grid className="textfield-grid" item xs={10}>
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Select
                                                classNamePrefix="select"
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                styles={
                                                    props.values.type === "" &&
                                                        props.errors.type &&
                                                        props.touched.type
                                                        ? selectStyles
                                                        : undefined
                                                }
                                                // placeholder={'type'}
                                                placeholder={''}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                value={
                                                    getSingleSelected(
                                                        props.values.type,
                                                        typeData
                                                    )
                                                }
                                                onChange={(e, value) => {
                                                    props.setFieldValue(
                                                        "type",
                                                        e !== null ? e.value : "",
                                                        false
                                                    );
                                                }}
                                                options={typeData}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: mainTheme.palette.primary.main,
                                                        primary25: mainTheme.palette.primary.light,
                                                        primary50: mainTheme.palette.primary.light,
                                                    },
                                                })}
                                            />}
                                        {props.values.type === "" &&
                                            props.errors.type &&
                                            props.touched.type && (
                                                <Typography
                                                    className={
                                                        props.values.type === "" &&
                                                            props.errors.type &&
                                                            props.touched.type
                                                            ? "validation-message text-input error"
                                                            : "validation-message text-input"
                                                    }
                                                >
                                                    {props.errors.type}
                                                </Typography>
                                            )}
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={12} className="textfield-main-grid">
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Name</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="name"
                                                    fullWidth
                                                    placeholder='Name'
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedName(false)}
                                                    onFocus={() => setFocusedName(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("name")}
                                                    value={props.values.name}
                                                    error={
                                                        props.errors.name && props.touched.name
                                                            ? true
                                                            : false
                                                    }
                                                    className="item-text-fields"
                                                >
                                                </TextField>
                                                {isFocusedName && <Typography className="textfield-counter">{(props.values.name !== null ? props.values.name.length : 0) + "/" + 90}</Typography>}
                                            </Box>}
                                        {props.errors.name && props.touched.name ?
                                            <Typography className="validation-message" >
                                                {props.errors.name}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid">
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Filename</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="fileName"
                                                    fullWidth
                                                    placeholder='Filename'
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedFileName(false)}
                                                    onFocus={() => setFocusedFileName(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("fileName")}
                                                    value={props.values.fileName}
                                                    className="item-text-fields"
                                                >
                                                </TextField>
                                                {isFocusedFileName && <Typography className="textfield-counter">{(props.values.fileName !== null ? props.values.fileName.length : 0) + "/" + 90}</Typography>}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid">
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Location/Link</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="location"
                                                    fullWidth
                                                    placeholder='Location/Link'
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 500 }}
                                                    onBlur={() => setFocusedLocation(false)}
                                                    onFocus={() => setFocusedLocation(true)}
                                                    onChange={props.handleChange.bind("location")}
                                                    value={props.values.location}
                                                    className="item-text-fields"
                                                >
                                                </TextField>
                                                {isFocusedLocation && <Typography className="textfield-counter">{(props.values.location !== null ? props.values.location.length : 0) + "/" + 500}</Typography>}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                {/* <Grid item xs={12} sx={{ display: "flex", alignItems: "center", marginTop: 3 }}>
                                    <Grid item xs={2} className="text-grid-textfield">
                                        <Typography className="text-typography">Link</Typography>
                                    </Grid>
                                    <Grid className="textfield-grid" item xs={10}>
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <TextField
                                                id="link"
                                                fullWidth
                                                placeholder='Link'
                                                autoComplete="off"
                                                onChange={props.handleChange.bind("link")}
                                                value={props.values.link}
                                            >
                                            </TextField>}
                                    </Grid>
                                </Grid> */}

                                <Grid item xs={12} className="textfield-main-grid">
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography-decription">Description</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="description"
                                                    multiline={true}
                                                    rows={3}
                                                    className="description-textfield item-text-fields"
                                                    fullWidth
                                                    placeholder='Description'
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 500 }}
                                                    onBlur={() => setFocusedDescription(false)}
                                                    onFocus={() => setFocusedDescription(true)}
                                                    onChange={props.handleChange.bind("description")}
                                                    value={props.values.description}
                                                >
                                                </TextField>

                                                {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 500}</Typography>}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="button-main-grid">
                                    <Grid>
                                        <Button
                                            className='button-cancel'
                                            onClick={() => {
                                                setFormInitial(formInitialValues);
                                                props.resetForm();
                                                param.onClose();
                                            }}>Cancel</Button>
                                    </Grid>
                                    <Grid sx={{ paddingLeft: 4 }}>
                                        {/* <Button variant="contained" className='button-submit' type="submit">Submit</Button> */}
                                        <LoadingButton
                                            loading={isBtnLoading}
                                            variant="contained"
                                            type="submit"
                                            className='button-submit'
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid >
                        {
                            param.isUpdate !== true ?
                                <Grid container className="border-grid-right" spacing={1} item xs={6}>
                                    <Grid item sx={{ padding: 5 }} className="table-main" xs={12}>
                                        <Grid container className="header-right" sx={{ display: "flex", padding: 1 }} >
                                            <Grid item xs={10}>
                                                <Typography className="clear-text"><b>Docs already in hiagram</b></Typography>
                                            </Grid>
                                            {/* <Grid item xs={2}>
                                                <Button
                                                    disableRipple
                                                    className="btn-cancel"
                                                    onClick={() => {
                                                        setFormInitial(formInitialValues);
                                                        props.resetForm();
                                                        param.onClose();
                                                    }}>
                                                    <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                        <Grid container paddingTop={5}>
                                            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                                <Table className="table-css" aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow >
                                                            {/* <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Type</StyledTableCell> */}
                                                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Name</StyledTableCell>
                                                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Filename</StyledTableCell>
                                                            {/* <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}>Add</StyledTableCell> */}
                                                            <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}></StyledTableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <>
                                                            {isLoading === true ? (
                                                                _.times(4, (i) => (
                                                                    <TableRow key={i}>
                                                                        <TableCell colSpan={4}>
                                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={25} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))) : allDocData.length > 0 ? allDocData.map((row: any, index: any) => {
                                                                    return (<>
                                                                        <StyledTableRow sx={{ fontSize: 12 }} key={index}>
                                                                            {/* <StyledTableCell align="left">{getNameSelected(
                                                                            row.type,
                                                                            typeData
                                                                        )}</StyledTableCell> */}
                                                                            <StyledTableCell align="left" className="text-show-less">{row.name}</StyledTableCell>
                                                                            <StyledTableCell align="left" className="text-show-less">{row.file_name}</StyledTableCell>
                                                                            <StyledTableCell className="btn-add">
                                                                                <Tooltip title='Add'>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        className='edit-btn'
                                                                                        onClick={() => handleClickAdd(row.uid)}
                                                                                    >
                                                                                        <AddIcon ></AddIcon>
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    </>
                                                                    );
                                                                }) : <>
                                                                <TableRow key={1}>
                                                                    <TableCell colSpan={5} align='center'>
                                                                        None added yet!
                                                                    </TableCell>
                                                                </TableRow></>}
                                                        </>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                : <></>
                        }
                        <Box className="close-btn-box">
                            <Button
                                disableRipple
                                className="btn-cancel"
                                onClick={() => {
                                    setFormInitial(formInitialValues);
                                    props.resetForm();
                                    param.onClose();
                                }}>
                                <ClearIcon className="close-btn-icon" />
                            </Button>
                        </Box>
                        < Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={openGuidanceDialog}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: { width: '400px', padding: '15px', borderRadius: '10px' },
                            }}
                            onClose={handlePopoverCloseForDoc}
                            disableRestoreFocus
                        >
                            <Typography className="popover-guidance-body" dangerouslySetInnerHTML={{ __html: guidance }}></Typography>
                        </Popover>

                    </Grid >
                </>
            );
        }}
        </Formik >
    );
}


// const selectStyles = {
//     control: (provided: any, state: any) => ({
//         ...provided,
//         border: state.isFocused ? "1px solid #0b0b45" : "1px solid #d32f2f",
//     }),
// };
