import * as React from 'react';
import { Box, Paper, Grid, Stack, Skeleton, Typography, Button, Dialog, DialogContent, DialogContentText, DialogActions, Backdrop, CircularProgress, TextField, DialogTitle, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PAGE_SIZE } from '../../services/common/const';
import { useContext, useEffect, useState } from 'react';
import { GetClientList, GetClientDetail, UpdateClient, DeleteClient } from '../../services/ClientService';
import { PreferencesContext } from "../../PreferenceContext";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import _ from "lodash";
import { AlertType } from '../../shared/AlertType';
import "./Client.scss";
import editIcon from '../../assets/icons/edit-2.svg'
import trashIcon from '../../assets/icons/trash.svg'
import * as Yup from "yup";
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    // [`&.${tableCellClasses.body}`]: {
    //     fontSize: 14,
    // },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.primary.light,
    // },
    // // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0,
    // },
    '&:nth-of-type(odd)': {
        backgroundColor: "#FAF9F6",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const parameters: any = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
};

const formInitialValues = {
    organizationName: "",
    contactPerson: "",
    address1: "",
    address2: "",
    city: "",
    stateUid: 0,
    countryUid: 0,
    zipCode: "",
    contactNumber: ""
};


export default function Client() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = React.useState(false);
    const [isShowLoder, setShowLoder] = React.useState(false);
    const [isBtnLoading, setBtnLoading] = React.useState(false);
    const [clientData, setClientData] = useState([]);
    const [param, setParam] = useState(parameters);
    const [totalRecords, setTotalRecords] = React.useState(0);

    //Modal
    const [curClientId, setCurClientId] = React.useState(0);
    const [openClientModal, setClientModal] = React.useState(false);
    const [openClientDeleteModal, setClientDeleteModal] = React.useState(false);
    const [clientinitial, setClientInitial] = React.useState(formInitialValues);

    const handleOpenClientModal = (id: number) => {
        setCurClientId(id);
        fetchClientDetail(id);
    };

    const handleCloseClientModal = () => {
        setClientModal(false);
        fetchClientList();
    };

    const handleOpenClientDeleteModal = (id: number) => {
        setCurClientId(id);
        setClientDeleteModal(true);
    };

    const handleCloseClientDeleteModal = () => {
        setClientDeleteModal(false);
        fetchClientList();
    };

    const valildationSchema = Yup.object().shape({
        organizationName: Yup.string().required("Organization name is required."),
        contactPerson: Yup.string().required("Contact person is required."),
    });

    // CLIENT LIST BINDING
    async function fetchClientList() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("pageIndex", param.pageIndex);
            params.append("pageSize", param.pageSize);
            const result: any = await GetClientList(params);

            if (result.data.data != null) {
                let clientsDataRows = result.data.data.data;
                setClientData(clientsDataRows);
                let totalRecords = result.data.data.total_data_count;
                setTotalRecords(totalRecords);
            }
        } catch (e: any) {
            //API error
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.response.message,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    async function fetchClientDetail(id: number) {
        setShowLoder(true);
        try {
            const result: any = await GetClientDetail(id);

            if (result.data.data != null) {
                let clientData = result.data.data;
                let clientForm = formInitialValues;
                clientForm.organizationName = clientData.organization_name;
                clientForm.contactPerson = clientData.contact_person;
                clientForm.address1 = clientData.address1;
                clientForm.address2 = clientData.address2;
                clientForm.city = clientData.city;
                clientForm.stateUid = clientData.state_uid;
                clientForm.countryUid = clientData.country_uid;
                clientForm.zipCode = clientData.zip_code;
                clientForm.contactNumber = clientData.contact_number;
                setClientInitial(clientForm);
                // let clientsDataRows = result.data.data.data;
                // setClientData(clientsDataRows);
                // let totalRecords = result.data.data.total_data_count;
                // setTotalRecords(totalRecords);
            }
            setClientModal(true);
        } catch (e: any) {
            //API error
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.response.message,
                alertType: AlertType.Error,
            });
        }
        setShowLoder(false);
    }

    async function submitClientForm(values: any) {
        setBtnLoading(true);
        try {
            const client: any = await UpdateClient(curClientId, {
                organization_name: values.organizationName,
                contact_person: values.contactPerson,
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                state: values.stateUid,
                country: values.countryUid,
                zip_code: values.zipCode,
                contact_number: values.contactNumber,
                row_managed_by: userContext.userId
            });
            if (client.data == true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: client.message,
                    alertType: AlertType.Success,
                });
                handleCloseClientModal();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function deleteClient() {
        setBtnLoading(true);
        try {
            const client: any = await DeleteClient(curClientId, {
                is_deleted: true,
                row_managed_by: userContext.userId
            });
            if (client.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: client.message,
                    alertType: AlertType.Success,
                });
                handleCloseClientDeleteModal();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    useEffect(() => {
        fetchClientList();
    }, []);

    useEffect(() => {
    }, [param, totalRecords, clientData, curClientId, clientinitial]);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isShowLoder}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src='/loader.gif' style={{ height: '250px', width: 'auto' }}></img>
            </Backdrop>
            <Box className="container-client">
                <Paper
                    elevation={3}
                    sx={{ borderRadius: "10px" }}
                    className="wrapper-client"
                >
                    <Grid className="list-grid" container>
                        <Grid item xs={4} sx={{ textAlign: "start" }}>
                            {/* <Link className="backspace-Button-Back" to={"/myprofile"}> */}
                            {/* <Link className="backspace-Button-Back" to={"/all-hiagram"}> */}
                            <Link className="backspace-Button-Back" to={"/dashboard"}>
                                <KeyboardBackspaceIcon className="icon-css" />
                                <b>Back</b>
                            </Link>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: "center" }}>
                            <Typography variant='h5' className='client-title'> <b>Client</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                    <Grid className="line-up"></Grid>

                    <Grid container className="list-grid" >
                        {/* <TableContainer component={Paper}> */}
                        <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {/* <StyledTableCell sx={{ width: '5%' }}>#</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '30%' }}>Organization Name</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '25%' }}>Contact Person</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '20%' }}>Contact Number</StyledTableCell>
                                        <StyledTableCell align="center" sx={{ width: '20%' }}>Action</StyledTableCell> */}

                                        <StyledTableCell sx={{ width: '5%' }}>#</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '30%', fontSize: 13 }}>Organization Name</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Contact Person</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Contact Number</StyledTableCell>
                                        <StyledTableCell align="center" sx={{ width: '20%', fontSize: 13 }}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {isLoading === true ? (
                                            _.times(5, (i) => (
                                                <TableRow key={i}>
                                                    <TableCell colSpan={5}>
                                                        <Skeleton variant="rounded" width='auto' height={25} />
                                                    </TableCell>
                                                </TableRow>
                                            ))) : (
                                            clientData.length > 0 ? (
                                                clientData.map((row: any, index: any) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.organization_name}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.contact_person}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.contact_number}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {/* <Grid container spacing={2}> */}
                                                            <Grid container spacing={2} className='btn-grid'>
                                                                <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                                    <Tooltip title='Edit'>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='edit-btn'
                                                                            onClick={() => handleOpenClientModal(row.uid)}
                                                                        >
                                                                            <img className='edit-icon' src={editIcon} />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Grid>
                                                                {/* <Grid item xs={6} sx={{ textAlign: "start" }}> */}
                                                                <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                                    <Tooltip title='Delete'>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='delete-btn'
                                                                            onClick={() => handleOpenClientDeleteModal(row.uid)}
                                                                        >
                                                                            <img className='delete-icon' src={trashIcon} />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))) : (
                                                <TableRow key={1}>
                                                    <TableCell colSpan={5} align='center'>
                                                        None added yet!
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Paper>
            </Box>

            {/* Add/Edit dialog */}
            <Dialog
                fullWidth
                open={openClientModal}
                onClose={handleCloseClientModal}
                scroll='body'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='client-dialog-box'
            >
                <DialogTitle id="scroll-dialog-title">
                    <Grid className="list-grid" container>
                        <Grid item xs={4} sx={{ textAlign: "start" }}>
                            <Typography variant='h5' className='client-title'> <b>Edit Client</b></Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: "center" }}>

                        </Grid>
                        <Grid item xs={4}>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseClientModal}
                                sx={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid className="line-up"></Grid>
                </DialogTitle>
                <DialogContent>

                    <Formik
                        enableReinitialize
                        initialValues={clientinitial}
                        onSubmit={(values, { resetForm }) => {
                            submitClientForm(values);
                        }}
                        validationSchema={valildationSchema}
                    >
                        {(props) => {
                            return (
                                <Form style={{ width: '100%', padding: '10px' }}>
                                    <React.Fragment>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Organization name</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='organizationName'
                                                    fullWidth
                                                    placeholder='Organization name'
                                                    value={props.values.organizationName}
                                                    onChange={props.handleChange("organizationName")}
                                                    onBlur={props.handleBlur}
                                                    error={
                                                        props.errors.organizationName && props.touched.organizationName
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                </TextField>
                                                {props.errors.organizationName && props.touched.organizationName ?
                                                    <Typography className="validation-message" >
                                                        {props.errors.organizationName}
                                                    </Typography> : <></>}
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Contact person name</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='contactPerson'
                                                    fullWidth
                                                    placeholder='Contact person name'
                                                    value={props.values.contactPerson}
                                                    onChange={props.handleChange("contactPerson")}
                                                    onBlur={props.handleBlur}
                                                    error={
                                                        props.errors.contactPerson && props.touched.contactPerson
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                </TextField>
                                                {props.errors.contactPerson && props.touched.contactPerson ?
                                                    <Typography className="validation-message" >
                                                        {props.errors.contactPerson}
                                                    </Typography> : <></>}
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Address1</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='address1'
                                                    fullWidth
                                                    placeholder='Address1'
                                                    value={props.values.address1}
                                                    onChange={props.handleChange("address1")}
                                                    onBlur={props.handleBlur}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Address2</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='address2'
                                                    fullWidth
                                                    placeholder='Address2'
                                                    value={props.values.address2}
                                                    onChange={props.handleChange("address2")}
                                                    onBlur={props.handleBlur}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>City</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='city'
                                                    fullWidth
                                                    placeholder='City'
                                                    value={props.values.city}
                                                    onChange={props.handleChange("city")}
                                                    onBlur={props.handleBlur}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>State</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='stateUid'
                                                    fullWidth
                                                    placeholder='State'
                                                    value={props.values.stateUid}
                                                    onChange={props.handleChange("stateUid")}
                                                    onBlur={props.handleBlur}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Country</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='countryUid'
                                                    fullWidth
                                                    placeholder='Country'
                                                    value={props.values.countryUid}
                                                    onChange={props.handleChange("countryUid")}
                                                    onBlur={props.handleBlur}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Zip code</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='zipCode'
                                                    fullWidth
                                                    placeholder='Zip code'
                                                    value={props.values.zipCode}
                                                    onChange={props.handleChange("zipCode")}
                                                    onBlur={props.handleBlur}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Contact number</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    sx={{ marginTop: 1, }}
                                                    id='contactNumber'
                                                    fullWidth
                                                    placeholder='Contact number'
                                                    value={props.values.contactNumber}
                                                    onChange={props.handleChange("contactNumber")}
                                                    onBlur={props.handleBlur}
                                                >
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                                                <LoadingButton
                                                    variant='contained'
                                                    type='submit'
                                                    className="popup-btn"
                                                    loading={isBtnLoading}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

            {/* Delete dialog */}
            <Dialog
                open={openClientDeleteModal}
                onClose={handleCloseClientDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this client?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='outlined'
                        onClick={() => deleteClient()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseClientDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
}