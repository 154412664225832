import * as React from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControl, Grid, IconButton, Paper, Skeleton, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import "./User.scss";
import { useContext, useEffect, useState } from 'react';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { styled, useTheme } from '@mui/material/styles';
import editIcon from '../../assets/icons/edit-2.svg'
import trashIcon from '../../assets/icons/trash.svg'
import inviteUserImage from '../../assets/icons/inviteUserImage.png'
import userCountIcon from '../../assets/icons/userCountImg.svg';
import hiagramMadeCountIcon from '../../assets/icons/hiagramMadeCountImg.svg';
import hiagramViewedCountIcon from '../../assets/icons/hiagramViewedCountImg.svg';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from '@mui/lab';
import { PreferencesContext } from "../../PreferenceContext";
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { CreateInvite, DeleteInviteUser, ReinviteUser } from '../../services/InviteService';
import { AlertType } from '../../shared/AlertType';
import { DeleteUser, GetUserList, UpdateUser } from '../../services/UserService';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { PAGE_SIZE } from '../../services/common/const';
import { GetUserDataById } from '../../services/AuthService';
import _ from 'lodash';
import { GetClientHiagramCount, GetHiagramList } from '../../services/HiagramService';
import viewIcon from '../../assets/icons/view-hiagram.svg'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#FAF9F6",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const parameters: any = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
};

const formInitialValues = {
    id: 0,
    email: ""
};

const userFormInitialValues = {
    firstName: "",
    lastName: "",
    email: ""
};


export default function User() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = React.useState(false);
    const [isBtnLoading, setBtnLoading] = React.useState(false);
    const [Open, setOpen] = React.useState(false);
    const [param, setParam] = useState(parameters);
    const [userData, setUserData] = useState([]);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [totalHiagramRecords, setTotalHiagramRecords] = React.useState(0);
    const [isFocusedFirstName, setFocusedFirstName] = useState(false);
    const [isFocusedLastName, setFocusedLastName] = useState(false);
    const [isFocusedInvitEmail, setFocusedInvitEmail] = useState(false);
    const [showAlert, setAlertMessage] = React.useState({
        isSuccess: false,
        type: "",
        message: "",
    });
    const mainTheme = useTheme();
    let navigate = useNavigate();

    // modal
    const [curUserId, setCurUserId] = React.useState(0);
    const [curInviteUserId, setCurInviteUserId] = React.useState(0);
    const [openUserDeleteModal, setUserDeleteModal] = React.useState(false);
    const [openInviteUserDeleteModal, setInviteUserDeleteModal] = React.useState(false);
    const [openUserModal, setUserModal] = React.useState(false);
    const [userinitial, setUserInitial] = React.useState(userFormInitialValues);
    const [dataLoding, setDataLoding] = React.useState(false);


    const validateSchema = Yup.object().shape({
        email: Yup.string().email("Enter an valid email").required("Email is required"),
    });

    const userValildationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        email: Yup.string().email("Enter an valid email").required("Email is required"),
    });

    const addUser = () => {
        setOpen(true);
    };

    const handleClose = (val: any) => {
        setOpen(false);
    };

    const handleOpenUserDeleteModal = (id: number) => {
        setUserDeleteModal(true);
        setCurUserId(id);
    };

    const handleCloseUserDeleteModal = (load: boolean) => {
        setUserDeleteModal(false);
        if (load) {
            fetchUserList();
        }
    };

    const handleOpenInviteUserDeleteModal = (id: number) => {
        setInviteUserDeleteModal(true);
        setCurInviteUserId(id);
    };

    const handleCloseInviteUserDeleteModal = (load: boolean) => {
        setInviteUserDeleteModal(false);
        if (load) {
            fetchUserList();
        }
    };

    const goToViewUserHiagram = (id: any, fullName: string) => {
        navigate(`/view-user-hiagram/${id}/${fullName}`);
    }

    const handleOpenUserModal = (id: number) => {
        setUserModal(true);
        setCurUserId(id);
        fetchUserDetail(id);
    };

    const handleCloseUserModal = (load: boolean) => {
        setUserModal(false);
        setCurUserId(0);
        if (load) {
            fetchUserList();
        }

    };

    //Add Invite
    async function submitForm(values: any, resetForm: any) {

        let email = userContext.email
        let ind = email.split("@");
        let clientEmail = ind[1];

        let userInvitedEmail = values.email
        let userInvitedEmailInd = userInvitedEmail.split("@");
        let userEmail = userInvitedEmailInd[1];

        if (clientEmail === userEmail && email !== userInvitedEmail) {
            try {
                setBtnLoading(true);
                let requestData = {
                    inviteUid: values.id,
                    clientUid: userContext.clientId,
                    userUid: userContext.userId,
                    invitedEmail: values.email,
                    invitationRole: "USER",
                    dataManagedBy: userContext.userId
                }
                let data: any = await CreateInvite(requestData);
                if (data !== null && data.data === null) {
                    setAlertMessage({
                        isSuccess: false,
                        type: AlertType.Error,
                        message: data.message,
                    });
                } else {
                    setAlertMessage({
                        isSuccess: true,
                        type: AlertType.Success,
                        message: "User invited successfully.",
                    });
                }
                //setOpen(false);
                resetForm();
                setBtnLoading(false);

                setTimeout(() => {
                    setAlertMessage({
                        type: "",
                        isSuccess: false,
                        message: "",
                    });
                }, 5000);
                fetchUserList();
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
                setBtnLoading(false);
            }
        }
        else {
            setAlertMessage({
                isSuccess: false,
                type: AlertType.Error,
                message: "You can't add user with this domain.",
            });

            setTimeout(() => {
                setAlertMessage({
                    type: "",
                    isSuccess: false,
                    message: "",
                });
            }, 5000);
        }
    }

    //Update user
    async function submitUserForm(values: any, resetForm: any) {
        setBtnLoading(true);
        try {
            const user: any = await UpdateUser({
                uid: curUserId,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                dataManagedBy: userContext.userId
            });
            if (user.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: user.message,
                    alertType: AlertType.Success,
                });
                handleCloseUserModal(true);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
        resetForm();
    }

    async function fetchUserDetail(id: number) {
        setDataLoding(true);
        try {
            const result: any = await GetUserDataById(id);
            if (result !== undefined && result !== null && result.data !== null) {
                let userData = result.data;
                let userForm = userFormInitialValues;
                userForm.firstName = userData.first_name;
                userForm.lastName = userData.last_name;
                userForm.email = userData.email;
                setUserInitial(userForm);
            }
        } catch (e: any) {
            //API error
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.response.message,
                alertType: AlertType.Error,
            });
        }
        setDataLoding(false);
    }

    async function deleteUser() {
        setBtnLoading(true);
        try {
            const user: any = await DeleteUser(curUserId, {
                isDeleted: true,
                dataManagedBy: userContext.userId
            });
            if (user.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: user.message,
                    alertType: AlertType.Success,
                });
                handleCloseUserDeleteModal(true);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function deleteInviteUser() {
        setBtnLoading(true);
        try {
            const user: any = await DeleteInviteUser(curInviteUserId, {
                isDeleted: true,
                dataManagedBy: userContext.userId
            });
            if (user.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: user.message,
                    alertType: AlertType.Success,
                });
                handleCloseInviteUserDeleteModal(true);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    // USER LIST BINDING
    async function fetchUserList() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("pageIndex", param.pageIndex);
            params.append("pageSize", param.pageSize);
            params.append("uuid", userContext.userId.toString());
            const result: any = await GetUserList(userContext.clientId, params);
            if (result.data != null) {
                let usersDataRows = result.data;
                usersDataRows.forEach((data: any) => {
                    data.isloading = false;
                });
                setUserData(usersDataRows);
                let totalRecords = usersDataRows.length;
                setTotalRecords(totalRecords);
            }
        } catch (e: any) {
            //API error
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: 'Something went wrong',
                // alertMessage: e.response.message,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    async function reinviteUser(id: any, index: any) {
        let data: any = userData;
        data[index].isloading = true;
        setUserData(data);
        try {
            const user: any = await ReinviteUser(id);

            if (user !== undefined && user !== null && user.data !== null && user.data.data !== null && user.data.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: user.data.message,
                    alertType: AlertType.Success,
                });
            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Somthing went wrong!",
                    alertType: AlertType.Error,
                });
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        data[index].isloading = false;
        setUserData(data);
    }

    async function fetchClientHiagramCount() {
        try {
            setLoading(true);
            let params = new URLSearchParams();
            params.append("cuid", userContext.clientId.toString());
            // params.append("uuid", userContext.userId.toString());
            // const hiagrams: any = await GetHiagramList(params);
            const hiagrams: any = await GetClientHiagramCount(params);
            if (hiagrams !== null && hiagrams.data !== null && hiagrams.data.data !== null) {
                let totalRecords = hiagrams.data.data.length;
                setTotalHiagramRecords(totalRecords);
                setLoading(false);
            }

        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    useEffect(() => {
        fetchUserList();
        fetchClientHiagramCount();
    }, []);

    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box className="container-user">
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: "10px" }}
                        className="wrapper-user"
                    >
                        <Grid className="list-grid" container>
                            <Grid item xs={4} sx={{ textAlign: "start" }}>
                                {/* <Link className="backspace-Button-Back" to={"/myprofile"}> */}
                                {/* <Link className="backspace-Button-Back" to={"/all-hiagram"}> */}
                                <Link className="backspace-Button-Back" to={"/dashboard"}>
                                    <KeyboardBackspaceIcon className="icon-css" />
                                    <b>Back</b>
                                </Link>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "center" }}>
                                <Typography variant='h5' className='user-title'> <b>Users</b></Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                        </Grid>

                        <Grid className="line-up"></Grid>

                        <Box className='total-count-main-box'>
                            <Box className='user-count'>
                                <img loading="lazy" alt='imprend' src={userCountIcon} />
                                <Box className='user-count-text-box'>
                                    <Typography className='user-count-text1'>Total users</Typography>
                                    <Typography className='user-count-text2'>{totalRecords}</Typography>
                                </Box>
                            </Box>
                            <Box className='hiagram-made-count'><img loading="lazy" alt='imprend' src={hiagramMadeCountIcon} />
                                <Box className='user-count-text-box'>
                                    <Typography className='user-count-text1'>Total hiagrams made</Typography>
                                    <Typography className='user-count-text2'>{totalHiagramRecords}</Typography>
                                </Box>
                            </Box>
                            <Box className='hiagram-view-count'>
                                {/* <img alt='imprend' src={hiagramViewedCountIcon} />
                                <Box className='user-count-text-box'>
                                    <Typography className='user-count-text1'>Total hiagrams viewed</Typography>
                                    <Typography className='user-count-text2'>9</Typography>
                                </Box> */}
                            </Box>
                        </Box>

                        <Grid container className="list-grid">

                            <Box className='user-add-btn-box'>
                                <Tooltip title="New">
                                    <Button className="user-add"
                                        startIcon={<AddIcon sx={{ color: mainTheme.palette.primary.main }} />}
                                        onClick={addUser}
                                    >
                                        New
                                    </Button>
                                </Tooltip>
                            </Box>

                            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ width: '5%' }}>#</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Name</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Email</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: userContext.clientIsFree ? '10%' : '20%', fontSize: 13 }}>Status</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ width: '15%', fontSize: 13 }}>Role</StyledTableCell>
                                            {userContext.clientIsFree && <StyledTableCell align="left" sx={{ width: '15%', fontSize: 13 }}>PDF download</StyledTableCell>}
                                            {userContext.clientIsFree && <StyledTableCell align="center" sx={{ width: '10%', fontSize: 13 }}>Link usage</StyledTableCell>}
                                            <StyledTableCell align="center" sx={{ width: '15%', fontSize: 13 }}>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <>
                                            {isLoading === true ? (
                                                _.times(6, (i) => (
                                                    <TableRow key={i} >
                                                        <TableCell colSpan={userContext.clientIsFree ? 8 : 7}>
                                                            <Skeleton variant="rounded" animation="wave" width='auto' height={25} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                userData.length > 0 ? (
                                                    userData.map((row: any, index: any) => (
                                                        <StyledTableRow
                                                            key={index}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            {row.FIRST_NAME === null && row.LAST_NAME === null ?
                                                                <StyledTableCell align="left">

                                                                </StyledTableCell>
                                                                :
                                                                <StyledTableCell align="left">
                                                                    {row.FIRST_NAME + " " + row.LAST_NAME}
                                                                </StyledTableCell>
                                                            }

                                                            {row.USER_EMAIL === null ?
                                                                <StyledTableCell align="left">
                                                                    {row.INVITED_EMAIL}
                                                                </StyledTableCell>
                                                                :
                                                                <StyledTableCell align="left">
                                                                    {row.USER_EMAIL}
                                                                </StyledTableCell>
                                                            }

                                                            {row.USER_UID > 0 && row.IS_DELETED === true ?
                                                                <StyledTableCell align="left">
                                                                    Revoked
                                                                </StyledTableCell>
                                                                :
                                                                <>
                                                                    {
                                                                        row.USER_UID === '0' && row.INVITE_UID > 0 && row.INVITED_IS_DELETE === false ?
                                                                            <StyledTableCell align="left">
                                                                                Invited
                                                                            </StyledTableCell> : <StyledTableCell align="left">
                                                                                Confirmed
                                                                            </StyledTableCell>
                                                                    }
                                                                </>

                                                            }
                                                            {row.USER_UID === userContext.userId ?
                                                                <StyledTableCell align="left">
                                                                    Admin
                                                                </StyledTableCell> :
                                                                <StyledTableCell align="left">
                                                                    Standard user
                                                                </StyledTableCell>
                                                            }
                                                            {userContext.clientIsFree &&
                                                                <StyledTableCell align="center">
                                                                    {row.PDF_COUNT}
                                                                </StyledTableCell>
                                                            }
                                                            {userContext.clientIsFree &&
                                                                <StyledTableCell align="center">
                                                                    {row.LINK_USE}
                                                                </StyledTableCell>
                                                            }
                                                            <StyledTableCell>
                                                                {/* <Grid container spacing={2}> */}
                                                                <Box className='btn-box'>
                                                                    {row.USER_UID > 0 && row.IS_DELETED === true ? <></>
                                                                        :
                                                                        <>

                                                                            {row.USER_UID === '0' && row.INVITE_UID > 0 && row.INVITED_IS_DELETE === false ?
                                                                                <>
                                                                                    <Tooltip title='Reinvite'>
                                                                                        <LoadingButton
                                                                                            variant='contained'
                                                                                            className='reinvite-btn'
                                                                                            loadingPosition='center'
                                                                                            loading={row.isloading}
                                                                                            onClick={() => reinviteUser(row.INVITE_UID, index)}
                                                                                        >
                                                                                            <MailOutlinedIcon sx={{ color: '#ffffff' }} />

                                                                                        </LoadingButton>
                                                                                    </Tooltip>

                                                                                    <Tooltip title='Delete'>
                                                                                        <Button
                                                                                            variant='contained'
                                                                                            className='delete-btn'
                                                                                            onClick={() => handleOpenInviteUserDeleteModal(row.INVITE_UID)}
                                                                                        >
                                                                                            <img loading="lazy" className='delete-icon' src={trashIcon} alt='imprend' />
                                                                                        </Button>
                                                                                    </Tooltip>

                                                                                </>
                                                                                :
                                                                                <>

                                                                                    {/* <Tooltip title='Edit'>
                                                                                        <Button
                                                                                            variant='contained'
                                                                                            className='edit-btn'
                                                                                            onClick={() => handleOpenUserModal(row.USER_UID)}
                                                                                        >
                                                                                            <img className='edit-icon' src={editIcon} />
                                                                                        </Button>
                                                                                    </Tooltip> */}


                                                                                    {row.USER_UID === userContext.userId ? <></> :
                                                                                        <>

                                                                                            {userContext.clientIsFree && <Tooltip title='View'>
                                                                                                <Button
                                                                                                    variant='contained'
                                                                                                    className='view-btn'
                                                                                                    onClick={() => goToViewUserHiagram(row.USER_UID, row.FIRST_NAME + " " + row.LAST_NAME)}
                                                                                                >
                                                                                                    <img loading="lazy" alt="imprend" className='view-icon' src={viewIcon} />
                                                                                                </Button>
                                                                                            </Tooltip>}
                                                                                            <Tooltip title='Edit'>
                                                                                                <Button
                                                                                                    variant='contained'
                                                                                                    className='edit-btn'
                                                                                                    onClick={() => handleOpenUserModal(row.USER_UID)}
                                                                                                >
                                                                                                    <img loading="lazy" className='edit-icon' src={editIcon} alt='imprend' />
                                                                                                </Button>
                                                                                            </Tooltip>
                                                                                            <Tooltip title='Delete'>
                                                                                                <Button
                                                                                                    variant='contained'
                                                                                                    className='delete-btn'
                                                                                                    onClick={() => handleOpenUserDeleteModal(row.USER_UID)}
                                                                                                >
                                                                                                    <img loading="lazy" className='delete-icon' src={trashIcon} alt='imprend' />
                                                                                                </Button>
                                                                                            </Tooltip>
                                                                                        </>

                                                                                    }
                                                                                </>

                                                                            }

                                                                        </>
                                                                    }
                                                                    {/* <Tooltip title='Edit'>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='edit-btn'
                                                                            onClick={() => handleOpenUserModal(row.UID)}
                                                                        >
                                                                            <img className='edit-icon' src={editIcon} />
                                                                        </Button>
                                                                    </Tooltip>


                                                                    {row.USER_UID === userContext.userId ? <></> :

                                                                        <Tooltip title='Delete'>
                                                                            <Button
                                                                                variant='contained'
                                                                                className='delete-btn'
                                                                                onClick={() => handleOpenUserDeleteModal(row.UID)}
                                                                            >
                                                                                <img className='delete-icon' src={trashIcon} />
                                                                            </Button>
                                                                        </Tooltip>

                                                                    } */}
                                                                    {/* <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                                    <Tooltip title='Delete'>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='delete-btn'
                                                                            onClick={() => handleOpenUserDeleteModal(row.UID)}
                                                                        >
                                                                            <img className='delete-icon' src={trashIcon} />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Grid> */}
                                                                </Box>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))) : (
                                                    <TableRow key={1}>
                                                        <TableCell colSpan={6} align='center'>
                                                            None added yet!
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </Paper>
                </Box>
            </Fade>
            {/* delete modal start */}
            <Dialog
                open={openUserDeleteModal}
                onClose={() => handleCloseUserDeleteModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-user-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='outlined'
                        onClick={() => deleteUser()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={() => handleCloseUserDeleteModal(false)}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {/* delete modal end */}

            {/* invite delete modal start */}
            <Dialog
                open={openInviteUserDeleteModal}
                onClose={() => handleCloseInviteUserDeleteModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-user-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='outlined'
                        onClick={() => deleteInviteUser()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={() => handleCloseInviteUserDeleteModal(false)}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {/* invite delete modal end */}

            {/* add modal start */}
            <Dialog sx={{
                "& .MuiDialog-paper": { width: "80%", maxHeight: 455 },
            }}
                maxWidth="xs"
                open={Open}
                onClose={handleClose}
                className='user-dialog-box'
            >
                <Grid textAlign="end">
                    <IconButton
                        //sx={{ marginRight: "4px !important", marginTop: "7px !important" }}
                        className='close-btn'
                        aria-label="close"
                        onClick={handleClose}
                        style={{ marginTop: "-10px !important" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>

                <Grid marginTop={2}>
                    <Box className='img-box'>
                        <img loading="lazy" className='invite-user-image' src={inviteUserImage} alt='imprend' />
                    </Box>

                    <Typography variant="h6" gutterBottom align="center">
                        Add a user in
                    </Typography>
                    <Typography
                        variant="h3"
                        gutterBottom
                        align="center"
                        fontSize={"22px !important"}
                        fontWeight="bold"
                        color={"#0693e3"}
                    >
                        {userContext.clientOrgName}
                    </Typography>
                </Grid>

                {/*--For AlertSuccess--*/}
                {showAlert.isSuccess === true &&
                    showAlert.type === AlertType.Success ? (
                    <Grid paddingLeft={4} paddingRight={4} marginTop={2} className="popup-alert">
                        <Stack sx={{ width: "100%" }}>
                            {/* <Alert severity="success">{showAlert.message}timeout={5000}</Alert> */}
                            <Alert severity="success">{showAlert.message}</Alert>
                        </Stack>
                    </Grid>
                ) : showAlert.isSuccess === false &&
                    showAlert.type === AlertType.Error ? (
                    <Grid paddingLeft={4} paddingRight={4} marginTop={2} className="popup-alert">
                        <Stack sx={{ width: "100%" }}>
                            <Alert severity="error">{showAlert.message}</Alert>
                        </Stack>
                    </Grid>
                ) : (
                    <></>
                )}

                <DialogContent className='user-form-dialog-content'>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={validateSchema}
                        onSubmit={(values: any, { resetForm }) => {
                            submitForm(values, resetForm);
                        }}
                    >
                        {(props: any) => {
                            return (
                                <Form
                                    onSubmit={props.handleSubmit}>

                                    <Grid container margin={1} paddingRight={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth className='add-user-form'>
                                                <Box sx={{ position: 'relative' }}>
                                                    <TextField
                                                        id="email"
                                                        placeholder="example@gmail.com"
                                                        label="Email*"
                                                        fullWidth
                                                        margin="dense"
                                                        value={props.values.email}
                                                        autoFocus
                                                        onChange={props.handleChange("email")}
                                                        inputProps={{ maxLength: 100 }}
                                                        onBlur={() => setFocusedInvitEmail(true)}
                                                        onFocus={() => setFocusedInvitEmail(true)}
                                                        error={
                                                            props.errors.email && props.touched.email
                                                                ? true
                                                                : false
                                                        }

                                                    />
                                                    {isFocusedInvitEmail && <Typography sx={{ position: 'absolute', right: 0 }}>{(props.values.email !== null ? props.values.email.length : 0) + "/" + 100}</Typography>}
                                                </Box>
                                                {props.errors.email && props.touched.email && (
                                                    <Typography
                                                        className={
                                                            props.errors.email && props.touched.email
                                                                ? "validation-message text-input error"
                                                                : "validation-message text-input"
                                                        }
                                                    >
                                                        {props.errors.email}
                                                    </Typography>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        className='add-user-submit-btn'
                                        spacing={1}
                                        marginTop={0}
                                        textAlign="right"
                                        paddingRight={12}
                                        paddingLeft={12}
                                    >
                                        {/* Submit button in MUI */}
                                        <LoadingButton
                                            color="primary"
                                            // onClick={() => setLoading(false)}
                                            //onClick={handleClick(getCountTimeout)}
                                            loading={isBtnLoading}
                                            //loading={loading}
                                            loadingPosition="start"
                                            fullWidth
                                            startIcon={<></>}
                                            variant="contained"
                                            type="submit"
                                            className='submit-btn'
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Grid>

                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>

            </Dialog>
            {/* add modal end */}


            {/* Add/Edit dialog */}
            <Dialog
                fullWidth
                open={openUserModal}
                onClose={() => handleCloseUserModal(false)}
                scroll='body'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='user-edit-dialog-box'
            >
                <DialogTitle id="scroll-dialog-title">
                    <Grid className="list-grid" container>
                        <Grid item xs={4} sx={{ textAlign: "start" }}>
                            <Typography variant='h5' className='client-title'> <b>Edit user</b></Typography>
                        </Grid>

                        <Grid item xs={4} sx={{ textAlign: "center" }}></Grid>

                        <Grid item xs={4}
                            sx={{
                                position: 'absolute',
                                // right: '10px',
                                right: 0,
                                top: 0
                            }}
                        >
                            <IconButton
                                aria-label="close"
                                disableRipple
                                onClick={() => handleCloseUserModal(false)}>

                                <CloseIcon sx={{
                                    height: 30,
                                    width: 30,
                                    color: "#292D32"
                                }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid className="line-up"></Grid>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        enableReinitialize
                        initialValues={userinitial}
                        onSubmit={(values, { resetForm }) => {
                            submitUserForm(values, resetForm);
                        }}
                        validationSchema={userValildationSchema}
                    >
                        {(props) => {
                            return (
                                <Form style={{ width: '100%', padding: '10px' }}>
                                    <React.Fragment>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>First name</Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{ position: 'relative' }}>
                                                {dataLoding ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                                    <>
                                                        <TextField
                                                            sx={{ marginTop: 1, }}
                                                            id='firstName'
                                                            fullWidth
                                                            placeholder='First name'
                                                            value={props.values.firstName}
                                                            onChange={props.handleChange("firstName")}
                                                            inputProps={{ maxLength: 100 }}
                                                            onBlur={() => setFocusedFirstName(false)}
                                                            onFocus={() => setFocusedFirstName(true)} error={
                                                                props.errors.firstName && props.touched.firstName
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                        </TextField>
                                                        {isFocusedFirstName && <Typography sx={{ position: 'absolute', right: 0 }}>{(props.values.firstName !== null ? props.values.firstName.length : 0) + "/" + 100}</Typography>}
                                                    </>
                                                }
                                                {props.errors.firstName && props.touched.firstName ?
                                                    <Typography className="validation-message" >
                                                        {props.errors.firstName}
                                                    </Typography> : <></>}
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Last name</Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{ position: 'relative' }}>
                                                {dataLoding ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                                    <>
                                                        <TextField
                                                            sx={{ marginTop: 1, }}
                                                            id='lastName'
                                                            fullWidth
                                                            placeholder='Last name'
                                                            value={props.values.lastName}
                                                            onChange={props.handleChange("lastName")}
                                                            inputProps={{ maxLength: 100 }}
                                                            onBlur={() => setFocusedLastName(false)}
                                                            onFocus={() => setFocusedLastName(true)}
                                                            error={
                                                                props.errors.lastName && props.touched.lastName
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                        </TextField>
                                                        {isFocusedLastName && <Typography sx={{ position: 'absolute', right: 0 }}>{(props.values.firstName !== null ? props.values.firstName.length : 0) + "/" + 100}</Typography>}
                                                    </>
                                                }
                                                {props.errors.lastName && props.touched.lastName ?
                                                    <Typography className="validation-message" >
                                                        {props.errors.lastName}
                                                    </Typography> : <></>}
                                            </Grid>

                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <Typography>Email</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {dataLoding ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                                    <TextField
                                                        sx={{ marginTop: 1, }}
                                                        id='email'
                                                        disabled={true}
                                                        fullWidth
                                                        placeholder='Email'
                                                        value={props.values.email}
                                                        onChange={props.handleChange("email")}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.email && props.touched.email
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                    </TextField>
                                                }
                                                {props.errors.email && props.touched.email ?
                                                    <Typography className="validation-message" >
                                                        {props.errors.email}
                                                    </Typography> : <></>}
                                            </Grid>

                                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                                                <LoadingButton
                                                    variant='contained'
                                                    type='submit'
                                                    className="popup-btn"
                                                    loading={isBtnLoading}
                                                    disabled={dataLoding}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog >
        </>
    )
}
