import React from 'react';
import "./EventTimeLine.scss";
import { Grid, DialogContentText, DialogActions, Fab, Box, Button, Popover, TableContainer, Paper, Table, TableHead, TableRow, Tooltip, TableCell, TableBody, tableCellClasses, styled, Typography, Card, useTheme } from "@mui/material";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from "react-router-dom";
import Event from '../../../assets/icons/events.svg'
import editIcon from '../../../assets/icons/edit-2.svg'
import trashIcon from '../../../assets/icons/trash.svg'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useContext, useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4FA79B",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E2FAFF",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));


function createData(
    id: number,
    name: string,
    type: string,
    day: string,
    time: string,
    firstDate: string,
    occuring: string,
    schedule: string,
) {
    return { id, name, type, day, time, firstDate, occuring, schedule };
}

const rows = [
    createData(1, 'Frozen yoghurt', 'App1', 'SUNDAY', '7:59 AM', '15/12/2022', 'YES', 'Weekly'),
    createData(2, 'Frozen yoghurt', 'App2', 'SUNDAY', '7:59 AM', '16/12/2022', 'NO', 'Weekly'),
    createData(3, 'Frozen yoghurt', 'App3', 'SUNDAY', '7:59 AM', '17/12/2022', 'YES', 'Weekly'),
    createData(4, 'Frozen yoghurt', 'App4', 'SUNDAY', '7:59 AM', '18/12/2022', 'NO', 'Weekly'),
    createData(5, 'Frozen yoghurt', 'App5', 'SUNDAY', '7:59 AM', '19/12 / 2022', 'YES', 'Weekly'),
    // createData(6, 'Frozen yoghurt', 'App6', 'SUNDAY', '7:59 AM', '20/12/2022', 'NO', 'Weekly'),
    // createData(7, 'Frozen yoghurt', 'App7', 'SUNDAY', '7:59 AM', '20/12/2022', 'NO', 'Weekly'),
    // createData(8, 'Frozen yoghurt', 'App8', 'SUNDAY', '7:59 AM', '20/12/2022', 'NO', 'Weekly'),
    // createData(9, 'Frozen yoghurt', 'App9', 'SUNDAY', '7:59 AM', '20/12/2022', 'NO', 'Weekly'),
    // createData(10, 'Frozen yoghurt', 'App10', 'SUNDAY', '7:59 AM', '20/12/2022', 'NO', 'Weekly'),
    // createData(11, 'Frozen yoghurt', 'App11', 'SUNDAY', '7:59 AM', '20/12/2022', 'NO', 'Weekly'),
    // createData(12, 'Frozen yoghurt', 'App12', 'SUNDAY', '7:59 AM', '20/12/2022', 'NO', 'Weekly'),
];

export default function EventTimeLine(params: any) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [anchorE2, setAnchorE2] = useState<HTMLElement | null>(null);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [openEventModal, setOpenEventModal] = useState(false);
    const [discriptionData, setDiscriptionData] = useState("");
    const [endDateData, setEndDateData] = useState("");
    const mainTheme = useTheme();

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, row: any) => {
        setAnchorEl(event.currentTarget);
        setDiscriptionData(row.description);
        setEndDateData(row.endDate);
    };
    const open = Boolean(anchorEl);
    const handlePopoverCloseTable = () => {
        setAnchorE2(null);
    };
    const handlePopoverOpenTable = (event: React.MouseEvent<HTMLElement>, row: any) => {
        setAnchorE2(event.currentTarget);
        setDiscriptionData(row.description);
        setEndDateData(row.endDate);
    };
    const openTable = Boolean(anchorE2);

    const handleCloseEventModal = () => {
        setOpenEventModal(false);
    };
    let evenCounter = 0;
    let oddCounter = 0;
    return (
        <>
            <Grid container className="heading-container-grid">
                <Grid className="header-css" item xs={11}>
                    <img alt="imprend" src={Event} />
                    <Typography className="icon-text">Events</Typography>
                    <Fab className="fab-circule">
                        <Typography><b>2</b></Typography>
                    </Fab>
                </Grid>
                <Grid item xs={1} className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={params.onClose}
                    >
                        <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                    </Button>
                </Grid>
            </Grid>
            <Grid container className='table-container-grid'>
                <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead >
                            <TableRow>
                                <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Name</StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: '10%', fontSize: 12 }}>Type</StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: '10%', fontSize: 12 }}>Day</StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: '10%', fontSize: 12 }}>Time</StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: '10%', fontSize: 12 }}>Date</StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: '10%', fontSize: 12 }}>Recurring</StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: '10%', fontSize: 12 }}>Schedule</StyledTableCell>
                                <StyledTableCell align="center" sx={{ width: '10%', fontSize: 12 }}>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <StyledTableRow
                                    sx={{ fontSize: 12 }}
                                    key={index}
                                    onMouseEnter={(e) => handlePopoverOpenTable(e, row)}
                                    onMouseLeave={handlePopoverCloseTable}
                                >
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell align="left">{row.type}</StyledTableCell>
                                    <StyledTableCell align="left">{row.day}</StyledTableCell>
                                    <StyledTableCell align="left">{row.time}</StyledTableCell>
                                    <StyledTableCell align="left">{row.firstDate}</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ paddingLeft: "32px !important" }}>{row.occuring}</StyledTableCell>
                                    <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                                    <StyledTableCell
                                        onMouseEnter={handlePopoverCloseTable}
                                    >
                                        <Grid container spacing={2} className='btn-grid'>
                                            <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                <Tooltip title='Edit'>
                                                    <Typography
                                                        className='edit-btn'
                                                    >
                                                        <img alt="imprend" className='edit-btn-logo' src={editIcon} />
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: "start" }}>
                                                <Tooltip title='Delete'>
                                                    <Typography
                                                        className='delete-btn'
                                                    >
                                                        <img alt="imprend" className='delete-btn-logo' src={trashIcon} />
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid className='time-line-container-grid-eventtime'>
                <Timeline position="alternate">
                    <Box className='timeline-start'></Box>
                    <TimelineItem className='timeline-item-upper-grid'>
                        <TimelineSeparator className='timeline-seprator' >
                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: mainTheme.palette.primary.main }} />
                        </TimelineSeparator>
                        <TimelineContent></TimelineContent>
                    </TimelineItem>
                    {rows.map((row, index) => {
                        return (
                            index % 2 === 0 ?
                                (evenCounter++) % 2 === 0 ?
                                    <TimelineItem key={index}>
                                        <TimelineSeparator sx={{ height: '70px' }}>
                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: mainTheme.palette.primary.main }}>
                                            </TimelineDot>
                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingRight: "5px !important", marginLeft: "11px" }}>
                                            <Box className='card-content-right-side' color={mainTheme.palette.primary.main}>
                                                <Box onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                    onMouseLeave={handlePopoverClose} className='card-one' sx={{ backgroundColor: mainTheme.palette.primary.main }} >
                                                    <Typography className='typography-text'>{row.firstDate}</Typography>
                                                    <Typography className='typography-text'>{row.type}</Typography>
                                                </Box>
                                                <Card onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                    onMouseLeave={handlePopoverClose} elevation={0} className='triangle triangle-right' sx={{ borderColor: `transparent transparent transparent ${mainTheme.palette.primary.main}` }}>
                                                </Card>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                    :
                                    <TimelineItem key={index}>
                                        <TimelineSeparator sx={{ height: '70px' }}>
                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#4D81B4" }}>
                                            </TimelineDot>
                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingRight: "5px !important", marginLeft: "11px" }}>
                                            <Box className='card-content-right-side' color="#4D81B4">
                                                <Box onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                    onMouseLeave={handlePopoverClose} className='card-one' sx={{ backgroundColor: "#4D81B4" }} >
                                                    <Typography className='typography-text'>{row.firstDate}</Typography>
                                                    <Typography className='typography-text'>{row.type}</Typography>
                                                </Box>
                                                <Card onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                    onMouseLeave={handlePopoverClose} elevation={0} className='triangle triangle-right' sx={{ borderColor: 'transparent transparent transparent #4D81B4' }}>
                                                </Card>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>


                                :

                                (oddCounter++) % 2 === 0 ?
                                    <TimelineItem key={index}>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: "#4FA79B" }} />
                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#4FA79B" }}>
                                            </TimelineDot >
                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingLeft: "5px!important", marginRight: "11px" }}>
                                            <Box className='card-content-left-side'>
                                                <Card onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                    onMouseLeave={handlePopoverClose} elevation={0} className='triangle triangle-left' sx={{ borderColor: '#ffffff  #4FA79B  #ffffff #ffffff' }}>
                                                </Card>
                                                <Box onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                    onMouseLeave={handlePopoverClose} className='card-two' sx={{ backgroundColor: "#4FA79B" }} >
                                                    <Typography className='typography-text'>{row.firstDate}</Typography>
                                                    <Typography className='typography-text'>{row.type}</Typography>
                                                </Box>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                    :
                                    <TimelineItem key={index}>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: "#A7679B" }} />
                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#A7679B" }}>
                                            </TimelineDot >
                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingLeft: "5px!important", marginRight: "11px" }}>
                                            <Box className='card-content-left-side'>
                                                <Card onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                    onMouseLeave={handlePopoverClose} elevation={0} className='triangle triangle-left' sx={{ borderColor: '#ffffff  #A7679B  #ffffff #ffffff' }}>
                                                </Card>
                                                <Box onMouseEnter={(e) => handlePopoverOpen(e, row)}
                                                    onMouseLeave={handlePopoverClose} className='card-two' sx={{ backgroundColor: "#A7679B" }} >
                                                    <Typography className='typography-text'>{row.firstDate}</Typography>
                                                    <Typography className='typography-text'>{row.type}</Typography>
                                                </Box>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                        )
                    })}
                    <TimelineItem className='timeline-item-upper-grid'>
                        <TimelineSeparator className='timeline-seprator' >
                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                        </TimelineSeparator>
                        <TimelineContent></TimelineContent>
                    </TimelineItem>
                    <Box className='timeline-start'></Box>
                </Timeline>
            </Grid>

            <Dialog
                open={openEventModal}
                onClose={handleCloseEventModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this Stackholder?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='contained'
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        onClick={handleCloseEventModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                PaperProps={{
                    elevation: 1,
                    style: { width: '250px', padding: '15px', borderRadius: '10px' },
                    sx: {
                        left: 'auto',
                        borderRadius: '10px !important',
                        mt: -1.5,
                    },
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}><b>Description:</b> {discriptionData}</Typography>
                <Typography sx={{ p: 1 }}><b>End Date:</b> {endDateData}</Typography>
            </Popover>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={openTable}
                anchorEl={anchorE2}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                PaperProps={{
                    elevation: 1,
                    style: { width: '250px', padding: '15px', borderRadius: '10px' },
                    sx: {
                        left: 'auto',
                        borderRadius: '10px !important',
                        //mt: -1.5,
                    },
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}><b>Description:</b> {discriptionData}</Typography>
                <Typography sx={{ p: 1 }}><b>End Date:</b> {endDateData}</Typography>
            </Popover>
        </>
    );
}

