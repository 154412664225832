import React, { useContext, useEffect } from 'react';
import * as Yup from "yup";
import email from '../../assets/icons/inbox.svg';
import { Alert, Fade, Grid, Paper, TextField, Typography } from '@mui/material';
import { Form, Formik } from "formik";
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { PreferencesContext } from '../../PreferenceContext';
import { UpdateClientInfo } from '../../services/ClientService';
import { AlertType } from '../../shared/AlertType';
import { useAuth0 } from '@auth0/auth0-react';
import { Reset } from '../../services/UserService';
import { Context } from '../../services/models/Context';

const formInitialValues = {
    firstName: "",
    lastName: "",
    organization: "",
    email: "",
    emailConfirmation: "",
    password: "",
    passwordConfirmation: "",
    showPassword: false,
    ShowConfirmationPassword: false,
    isUserFree: false
};


export default function SignUp() {
    const [values, setValues] = React.useState(formInitialValues);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = React.useState(false);
    const [pageLoading, setPageLoading] = React.useState(false);
    const { logout } = useAuth0();
    const [showAlert, setAlertMessage] = React.useState({
        isVisible: false,
        message: "",
    });

    let navigate = useNavigate();

    // const onClickReturn = () => {
    //     navigate("/sign-up");
    // };

    const gotoPlans = () => {
        navigate("/plans");
    };
    const gotoAllHiagram = () => {
        // navigate("/all-hiagram");
        navigate("/dashboard");
    };
    const valildationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required."),
        lastName: Yup.string().required("Last name is required."),
        // organization: Yup.string().required("Organization is required."),
        organization: Yup.string().when("isUserFree", {
            is: (isUserFree: any) => isUserFree === true,
            then: Yup.string(),
            otherwise: Yup.string().required("Organization is required."),
        })
    });

    // const handleClickShowPassword = () => {
    //     setValues({
    //         ...values,
    //         showPassword: !values.showPassword,
    //     });

    // };
    // const handleClickShowConfirmationPassword = () => {
    //     setValues({
    //         ...values,
    //         ShowConfirmationPassword: !values.ShowConfirmationPassword,
    //     });

    // };

    async function submitSignUpForm(values: any, resetForm: any) {
        setLoading(true);
        try {
            const client: any = await UpdateClientInfo({
                clientId: userContext.clientId,
                userId: userContext.userId,
                firstName: values.firstName,
                lastName: values.lastName,
                organizationName: values.organization,
                isFree: userContext.clientIsFree
            });
            if (client) {
                if (userContext.clientIsFree === true) {
                    updateUserContext({
                        ...userContext,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        fullName: values.firstName + " " + values.lastName,
                    });
                    gotoAllHiagram();
                } else {
                    updateUserContext({
                        ...userContext,
                        clientOrgName: values.organization,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        fullName: values.firstName + " " + values.lastName,
                    });
                    gotoPlans();
                }

            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isVisible: true,
                    message: error as string,
                });
            }
        }
        setTimeout(() => {
            setAlertMessage({
                isVisible: false,
                message: "",
            })
        }, 7000);
        resetForm(formInitialValues);
    }

    async function startAgain() {
        setPageLoading(true);
        try {
            const user: any = await Reset({
                clientId: userContext.clientId,
                userId: userContext.userId
            });
            if (user) {
                let curuserInfo: Context = new Context();
                updateUserContext(curuserInfo);
                logout({ logoutParams: { returnTo: window.location.origin } });
                //setPageLoading(false);
            }

        } catch (error: any) {
            setPageLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    useEffect(() => {
        if (userContext.clientIsFree === true) {
            setValues({
                ...values,
                firstName: "",
                lastName: "",
                organization: userContext.clientOrgName,
                email: "",
                emailConfirmation: "",
                password: "",
                passwordConfirmation: "",
                showPassword: false,
                ShowConfirmationPassword: false,
                isUserFree: userContext.clientIsFree
            })
        }
    }, [])
    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Grid className="signup-main-grid">
                    {pageLoading ?
                        <Box className="main-loding">
                            <img loading="lazy" alt="imprend" src='/loader.gif' style={{ height: '200px', width: 'auto' }}></img>
                        </Box> :
                        <Paper elevation={3} className="signup-paper">
                            <Grid className="signup-wrapper">
                                <Formik
                                    enableReinitialize
                                    initialValues={values}
                                    onSubmit={(values, { resetForm }) => {
                                        submitSignUpForm(values, resetForm);
                                    }}
                                    validationSchema={valildationSchema}
                                >
                                    {(props) => {
                                        return (
                                            <Form style={{ width: '100%' }}>
                                                <React.Fragment>
                                                    <Grid className="signup-back">
                                                        {/* <Link
                                                        className='signup-backspace-button'
                                                        to={'/sign-in'}
                                                    >
                                                        <KeyboardBackspaceIcon className='signup-icon-css' /><b>Back</b>
                                                    </Link> */}
                                                    </Grid>
                                                    <Box className="signup-box-container" >
                                                        {userContext.emailConfirmed ?
                                                            <Paper className="signup-paper-container">
                                                                <Grid justifyContent="start">
                                                                    <Typography className="signup-title"><b>Set up your account</b></Typography>
                                                                </Grid>
                                                                {showAlert.isVisible ? (
                                                                    <Grid marginBottom={1}>
                                                                        <Alert severity="success">{showAlert.message}</Alert>
                                                                    </Grid>) : <></>}
                                                                <Paper
                                                                    className={showAlert.isVisible ? "signup-paper-scrollfrom signup-alert-div-space" : "signup-paper-scrollfrom"}>
                                                                    <Grid>
                                                                        <Grid container spacing={1} className={userContext.clientIsFree === true ? "signup-grid-main" : "signup-grid-main signup-paid-user"}>
                                                                            <Grid item xs={12} className="signup-grid">
                                                                                <Box className="signup-fields">
                                                                                    <Typography>First name</Typography>
                                                                                    <TextField
                                                                                        sx={{ marginTop: 1, }}
                                                                                        id='firstName'
                                                                                        fullWidth
                                                                                        placeholder='First name'
                                                                                        value={props.values.firstName}
                                                                                        onChange={props.handleChange("firstName")}
                                                                                        onBlur={props.handleBlur}
                                                                                        error={
                                                                                            props.errors.firstName && props.touched.firstName
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        autoComplete='off'
                                                                                    >
                                                                                    </TextField>
                                                                                    {props.errors.firstName && props.touched.firstName ?
                                                                                        <Typography className="signup-validation-message" >
                                                                                            {props.errors.firstName}
                                                                                        </Typography> : <></>}
                                                                                </Box>
                                                                            </Grid>

                                                                            <Grid item xs={12} className="signup-grid">
                                                                                <Box className="signup-fields">
                                                                                    <Typography>Last name</Typography>
                                                                                    <TextField
                                                                                        id='lastName'
                                                                                        sx={{ marginTop: 1, }}
                                                                                        fullWidth
                                                                                        placeholder='Last name'
                                                                                        value={props.values.lastName}
                                                                                        onChange={props.handleChange("lastName")}
                                                                                        onBlur={props.handleBlur}
                                                                                        error={
                                                                                            props.errors.lastName && props.touched.lastName
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        autoComplete='off'
                                                                                    >
                                                                                    </TextField>
                                                                                    {props.errors.lastName && props.touched.lastName ?
                                                                                        <Typography className="validation-message" >
                                                                                            {props.errors.lastName}
                                                                                        </Typography> : <></>}
                                                                                </Box>
                                                                            </Grid>

                                                                            <Grid item xs={12} className="signup-grid">
                                                                                <Box className="signup-fields">
                                                                                    {userContext.clientIsFree === true && userContext.clientOrgName !== '' ?
                                                                                        <></> :
                                                                                        <>
                                                                                            <Typography>Organization name</Typography>
                                                                                            <TextField
                                                                                                id='organization'
                                                                                                sx={{ marginTop: 1 }}
                                                                                                fullWidth
                                                                                                placeholder='Organization'
                                                                                                value={props.values.organization}
                                                                                                onChange={props.handleChange("organization")}
                                                                                                onBlur={props.handleBlur}
                                                                                                error={
                                                                                                    props.errors.organization && props.touched.organization
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                autoComplete='off'
                                                                                            >
                                                                                            </TextField>
                                                                                            {props.errors.organization && props.touched.organization ?
                                                                                                <Typography className="validation-message" >
                                                                                                    {props.errors.organization}
                                                                                                </Typography> : <></>}
                                                                                        </>
                                                                                    }
                                                                                </Box>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                                <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                                                    <LoadingButton
                                                                        className='signup-submit-button'
                                                                        color="primary"
                                                                        loading={loading}
                                                                        //fullWidth
                                                                        loadingPosition="start"
                                                                        variant="contained"
                                                                        type="submit"
                                                                        startIcon={<></>}
                                                                    >
                                                                        Submit
                                                                    </LoadingButton>
                                                                </Grid>
                                                            </Paper> :
                                                            <Paper className="email-paper-container">
                                                                <Box className="email-box">
                                                                    <img loading="lazy" className='email-img' src={email} alt="logo" />
                                                                    <Typography variant='h4'>Check your email</Typography>
                                                                    <Typography>Let's confirm your email address first.</Typography>
                                                                </Box>
                                                            </Paper>
                                                        }
                                                        {userContext.clientIsFree === true ? <></> :
                                                            <Box
                                                                className="start-again-box"
                                                                onClick={startAgain}
                                                            >
                                                                <Typography>Start sign up process again</Typography>
                                                            </Box>}
                                                    </Box>
                                                </React.Fragment>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </Grid>
                            <Grid className='img-wrapper' item >
                                {/* <img className="home-img" src={home} alt="home" /> */}
                            </Grid>
                        </Paper>
                    }
                </Grid >
            </Fade>
        </>
    );
}