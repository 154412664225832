import { Button, Grid, Paper, styled, Skeleton, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme, IconButton, Dialog, DialogContent, DialogContentText, Slide, DialogActions, Box, Popover } from "@mui/material";
import * as React from "react";
import Hint from '../../../assets/icons/hint.svg';
import ClearIcon from '@mui/icons-material/Clear';
import * as Yup from "yup";
import "./ItemHintAddModel.scss";
import AddIcon from '@mui/icons-material/Add';
import Select from "react-select";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { PreferencesContext } from "../../../PreferenceContext";
import { AlertType } from "../../../shared/AlertType";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CreateHint, CreateHintMapping, GetAllHintList, GetHintDetail, UpdateHint } from "../../../services/HintService";
import _ from "lodash";
import { getSingleSelected } from "../../../services/common/commonFunction";
import { LoadingButton } from "@mui/lab";

const formInitialValues = {
    id: 0,
    title: "",
    name: "",
    resolution: "",
    description: "",
    mappingId: 0,
    appendToType: "",
    appendUid: 0
};


export default function ItemHintAddModel(param: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [formInitial, setFormInitial] = useState(formInitialValues);
    const [allHintData, setAllHintData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [openGuidanceDialog, setOpenGuidanceDialog] = useState<HTMLElement | null>(null);
    const [guidance, setGuidance] = useState("");
    const [isResolutionStatus, setResolutionStatus] = useState(true);
    const mainTheme = useTheme();
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [isFocusedTitle, setFocusedTitle] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);
    const [isFocusedResolution, setFocusedResolution] = useState(false);

    const handlePopoverOpenForHint = (event: React.MouseEvent<HTMLElement>) => {
        setOpenGuidanceDialog(event.currentTarget);
        setGuidance(param.curQuestion.guidance);
    };

    const handlePopoverCloseForHint = () => {
        setOpenGuidanceDialog(null);
    };

    const open = Boolean(openGuidanceDialog);

    const hintAddModelOptions = [
        { value: 'Hint', label: 'Hint' },
        { value: 'Issue', label: 'Issue' }
    ]

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: mainTheme.palette.primary.main,
            backgroundColor: "#8c3939",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#FEF4F4",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    async function fetchHint(id: any, aId: any) {
        setDataLoading(true);
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("hnmuid", aId.toString());
        params.append("hnuid", id.toString());
        const hints: any = await GetHintDetail(params);
        if (hints !== null && hints.data !== null && hints.data.data !== null) {
            let data = hints.data.data;
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            if (+param.curQuestion.number === 13) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 22) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 31) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            } else if (+param.curQuestion.number === 36) {
                appendType = "STEP";
                appendUID = userContext.stepId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            const formValues = {
                id: +data.uid,
                title: data.title,
                name: data.name,
                resolution: data.resolution,
                description: data.description,
                mappingId: +data.hintMapping[0].uid,
                appendToType: appendType,
                appendUid: appendUID
            };
            if (data.name === 'Issue') {
                setResolutionStatus(false);
            } else {
                setResolutionStatus(true);
            }
            setFormInitial(formValues);
        }
        setDataLoading(false);
    }

    async function fetchHintList() {
        setLoading(true);
        let appendType = "STEP";
        let appendUID = 0;
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        if (+param.curQuestion.number === 7) {
            appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            appendUID = userContext.teamProjId;
        } else if (+param.curQuestion.number === 13) {
            appendType = "ROLE";
            appendUID = userContext.roleId;
        } else if (+param.curQuestion.number === 22) {
            appendType = "ACTIVITY";
            appendUID = userContext.activityId;
        } else if (+param.curQuestion.number === 31) {
            appendType = "CESS";
            appendUID = userContext.cessId;
        } else if (+param.curQuestion.number === 36) {
            appendType = "STEP";
            appendUID = userContext.stepId;
        }

        if (param.curQuestion.hiagram !== undefined) {
            appendType = param.curQuestion.hiagram.type;
            appendUID = param.curQuestion.hiagram.typeId;
        }
        params.append("appendType", appendType.toString());
        params.append("appendUid", appendUID.toString());

        const hints: any = await GetAllHintList(params);
        if (hints !== null && hints.data !== null && hints.data.data !== null) {
            let data = hints.data.data;
            setAllHintData(data);
        }
        setLoading(false);
    }

    async function submitForm(values: any, resetForm: any) {
        setBtnLoading(true);
        try {
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            let isFromExisting = false;
            if (+param.curQuestion.number === 13) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 22) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 31) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            } else if (+param.curQuestion.number === 36) {
                appendType = "STEP";
                appendUID = userContext.stepId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            if (values.id > 0 && values.mappingId === 0) {
                let curHint: any = allHintData.find((x: any) => x.uid === values.id);
                if (values.title === curHint.title && values.name === curHint.name && values.resolution === curHint.resolution
                    && values.description === curHint.description) {
                    isFromExisting = true;
                } else {
                    values.id = 0;
                }
            }

            let requestData = {
                hintUid: values.id,
                hintMappingUid: values.mappingId,
                hiagramUid: userContext.hiagramId,
                title: values.title,
                name: values.name,
                resolution: values.resolution,
                description: values.description,
                appendToType: appendType,
                appendUid: appendUID,
                isDeleted: false,
                dataManagedBy: userContext.userId
            }
            let data: any;
            if (isFromExisting) {
                data = await CreateHintMapping(requestData);
            } else if (values.id !== null && values.id > 0) {
                data = await UpdateHint(requestData);
            } else {
                data = await CreateHint(requestData);
            }
            setFormInitial(formInitialValues);
            resetForm();
            param.onSubmitClose();
            updateUserContext({
                ...userContext,
                isItemCountUpdated: true
            });
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    const handleClickAdd = (id: any) => {
        let curHint: any = allHintData.find((x: any) => x.uid === id);
        const formInitial = {
            id: curHint.uid,
            title: curHint.title,
            name: curHint.name,
            resolution: curHint.resolution,
            description: curHint.description,
            mappingId: 0,
            appendToType: "",
            appendUid: 0
        };

        if (curHint.name === 'Issue') {
            setResolutionStatus(false);
        } else {
            setResolutionStatus(true);
        }
        setFormInitial(formInitial);
    }

    const formValidation = Yup.object().shape({
        name: Yup.string().required("Type is required."),
        title: Yup.string().required("Hint/Issue is required."),
    });

    useEffect(() => {
        if (param.isOpen) {
            setResolutionStatus(true);
            if (param.id > 0) {
                fetchHint(param.id, param.mappingId);
            } else {
                fetchHintList();
            }
        }
    }, [param.isOpen]);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={formInitial}
                validationSchema={formValidation}
                onSubmit={(values: any, { resetForm }) => {
                    submitForm(values, resetForm);
                }}
            >{(props: any) => {
                return (

                    <Grid container className="main-grid-model-hint-add">
                        <Grid className="border-grid-left" item xs={param.isUpdate === true ? 12 : 6} paddingRight={param.isUpdate === true ? "30px" : "50px"}>
                            <Form onSubmit={props.handleSubmit} noValidate>
                                <Grid container className="edit-hint">
                                    <Grid className="header-left" sx={{ display: "flex", padding: 1 }} item xs={12}>
                                        <img loading="lazy" alt="imprend" className='icon-stakeholders' src={Hint} />
                                        {param.isUpdate === true ?
                                            <Typography className="icon-text">Edit Hint</Typography>
                                            :
                                            <Typography className="icon-text">Add Hint</Typography>
                                        }
                                        {param.QuickView === false ?
                                            <Tooltip title="Guidance">
                                                <IconButton
                                                    className="hint-guidance-icon"
                                                    aria-label="Guidance"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    disableRipple
                                                    onClick={handlePopoverOpenForHint}
                                                >
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : <></>}
                                    </Grid>
                                    {/* {param.isUpdate === true ?
                                            <Grid item xs={2} className="clear-icon-edit">
                                                <Button
                                                    disableRipple
                                                    className="btn-cancel"
                                                    onClick={() => {
                                                        setFormInitial(formInitialValues);
                                                        props.resetForm();
                                                        param.onClose();
                                                    }}>
                                                    <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                                </Button>
                                            </Grid> :
                                            <></>} */}
                                </Grid>
                                <Grid item xs={12} className="textfield-main-grid">
                                    <Box className="text-grid-textfield">
                                        {/* <Typography className="text-typography">Name</Typography> */}
                                        <Typography className="text-typography">Type</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            // <TextField
                                            //     id="name"
                                            //     fullWidth
                                            //     // placeholder='Belief'
                                            //     placeholder='Hint/Issue'
                                            //     autoComplete="off"
                                            //     onChange={props.handleChange.bind("name")}
                                            //     value={props.values.name}
                                            //     error={
                                            //         props.errors.name && props.touched.name
                                            //             ? true
                                            //             : false
                                            //     }
                                            // >
                                            // </TextField>
                                            <Select
                                                classNamePrefix="select"
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                styles={
                                                    props.values.name === "" &&
                                                        props.errors.name &&
                                                        props.touched.name
                                                        ? selectStyles
                                                        : selectFontFamily
                                                }
                                                placeholder={''}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                options={hintAddModelOptions}
                                                value={getSingleSelected(
                                                    props.values.name,
                                                    hintAddModelOptions
                                                )}
                                                onChange={(e, value) => {
                                                    props.setFieldValue(
                                                        "name",
                                                        e !== null ? e.value : "",
                                                        false
                                                    );
                                                    if (e !== null && (e.value === 'Issue')) {
                                                        setResolutionStatus(false);
                                                    } else {
                                                        setResolutionStatus(true);
                                                        props.setFieldValue(
                                                            "resolution",
                                                            "",
                                                            true
                                                        );
                                                    }


                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: mainTheme.palette.primary.main,
                                                        primary25: mainTheme.palette.primary.light,
                                                        primary50: mainTheme.palette.primary.light,
                                                    },
                                                })}
                                            />
                                        }
                                        {props.errors.name && props.touched.name ?
                                            <Typography className="validation-message" >
                                                {props.errors.name}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid">
                                    <Box className="text-grid-textfield">
                                        {/* <Typography className="text-typography">Name</Typography> */}
                                        <Typography className="text-typography">Hint/Issue</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="title"
                                                    fullWidth
                                                    // placeholder='Belief'
                                                    placeholder='Title'
                                                    autoComplete="off"
                                                    className="item-text-fields"
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedTitle(false)}
                                                    onFocus={() => setFocusedTitle(true)}
                                                    onChange={props.handleChange.bind("title")}
                                                    value={props.values.title}
                                                >
                                                </TextField>
                                                {isFocusedTitle && <Typography className="textfield-counter">{(props.values.title !== null ? props.values.title.length : 0) + "/" + 90}</Typography>}
                                            </Box>
                                        }
                                        {props.errors.title && props.touched.title ?
                                            <Typography className="validation-message" >
                                                {props.errors.title}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid">
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Resolution</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="resolution"
                                                    fullWidth
                                                    // placeholder='Belief'
                                                    disabled={isResolutionStatus}
                                                    placeholder='Resolution'
                                                    className="item-text-fields"
                                                    inputProps={{ maxLength: 100 }}
                                                    onBlur={() => setFocusedResolution(false)}
                                                    onFocus={() => setFocusedResolution(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("resolution")}
                                                    value={props.values.resolution}
                                                >
                                                </TextField>
                                                {isFocusedResolution && <Typography className="textfield-counter">{(props.values.resolution !== null ? props.values.resolution.length : 0) + "/" + 100}</Typography>}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid">
                                    <Box className="text-grid-textfield">
                                        {/* <Typography className="text-typography-decription">Description</Typography> */}
                                        <Typography className="text-typography-decription">Further details</Typography>

                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="description"
                                                    multiline={true}
                                                    rows={3}
                                                    className="description-textfield item-text-fields"
                                                    fullWidth
                                                    placeholder='Further details'
                                                    inputProps={{ maxLength: 500 }}
                                                    onBlur={() => setFocusedDescription(false)}
                                                    onFocus={() => setFocusedDescription(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("description")}
                                                    value={props.values.description}
                                                >
                                                </TextField>
                                                {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 500}</Typography>}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="button-main-grid">
                                    <Grid>
                                        <Button className='button-cancel'
                                            onClick={() => {
                                                setFormInitial(formInitialValues);
                                                props.resetForm();
                                                param.onClose();
                                            }}>Cancel</Button>
                                    </Grid>
                                    <Grid sx={{ paddingLeft: 4 }}>
                                        {/* <Button variant="contained" className='button-submit' type="submit">Submit</Button> */}
                                        <LoadingButton
                                            loading={isBtnLoading}
                                            variant="contained"
                                            type="submit"
                                            className='button-submit'
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid >

                        {
                            param.isUpdate !== true ?
                                <Grid container className="border-grid-right" spacing={1} item xs={6}>
                                    <Grid item sx={{ padding: 5 }} className="table-main" xs={12}>
                                        <Grid container className="header-right" sx={{ display: "flex", padding: 1 }} >
                                            <Grid item xs={10}>
                                                <Typography className="clear-text"><b>Hints already in hiagram</b></Typography>
                                            </Grid>
                                            {/* <Grid item xs={2}>
                                                    <Button
                                                        disableRipple
                                                        className="btn-cancel"
                                                        onClick={() => {
                                                            setFormInitial(formInitialValues);
                                                            props.resetForm();
                                                            param.onClose();
                                                        }}
                                                    >
                                                        <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                                    </Button>
                                                </Grid> */}
                                        </Grid>
                                        <Grid container paddingTop={5}>
                                            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                                <Table className="table-css" aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow >
                                                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Title</StyledTableCell>
                                                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Resolution</StyledTableCell>
                                                            {/* <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}>Add</StyledTableCell> */}
                                                            <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}></StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <>
                                                            {isLoading === true ? (
                                                                _.times(4, (i) => (
                                                                    <TableRow key={i}>
                                                                        <TableCell colSpan={3}>
                                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={25} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))) : allHintData.length > 0 ? allHintData.map((row: any, index: any) => {
                                                                    return (<>
                                                                        <StyledTableRow sx={{ fontSize: 12 }} key={index}>
                                                                            <StyledTableCell align="left" className="text-show-less">{row.title}</StyledTableCell>
                                                                            <StyledTableCell align="left" className="text-show-less">{row.resolution}</StyledTableCell>
                                                                            <StyledTableCell className="btn-add">
                                                                                <Tooltip title='Add'>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        className='edit-btn'
                                                                                        onClick={() => handleClickAdd(row.uid)}
                                                                                    >
                                                                                        <AddIcon ></AddIcon>
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    </>
                                                                    );
                                                                }) : <>
                                                                <TableRow key={1}>
                                                                    <TableCell colSpan={5} align='center'>
                                                                        None added yet!
                                                                    </TableCell>
                                                                </TableRow></>}
                                                        </>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                : <></>
                        }



                        <Box className="close-btn-box">
                            <Button
                                disableRipple
                                className="btn-cancel"
                                onClick={() => {
                                    setFormInitial(formInitialValues);
                                    props.resetForm();
                                    param.onClose();
                                }}
                            >
                                <ClearIcon className="close-btn-icon" />
                            </Button>
                        </Box>

                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={openGuidanceDialog}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: { width: '400px', padding: '15px', borderRadius: '10px' },
                            }}
                            onClose={handlePopoverCloseForHint}
                            disableRestoreFocus
                        >
                            <Typography className="popover-guidance-body" dangerouslySetInnerHTML={{ __html: guidance }}></Typography>
                        </Popover>
                    </Grid>
                );
            }}


            </Formik >

        </>
    );
}

const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0b0b45" : "1px solid #8c3939",
    }),
};

const selectFontFamily = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        fontFamily: 'Roboto'
    }),

    menu: (provided: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    }),
    singleValue: (provided: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    })
};