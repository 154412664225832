import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.png';
import { Button, Fade, Grid, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { PreferencesContext } from '../../PreferenceContext';
import { Context } from '../../services/models/Context';
import { useAuth0 } from '@auth0/auth0-react';
import { Login } from '../../services/AuthService';

export default function PreSignIn() {
    const { userContext, updateUserContext } = React.useContext(PreferencesContext);
    const { loginWithRedirect, isAuthenticated, isLoading, user, logout } = useAuth0();
    const [isDataLoading, setDataLoading] = useState(true);
    let navigate = useNavigate();
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let urlParams = useQuery();

    const gotoSignup = () => {
        navigate("/sign-up");
    };

    const gotoMyprofile = () => {
        // navigate("/myprofile");
        // navigate('/all-hiagram');
        navigate('/dashboard');
    };

    const gotoPlans = () => {
        navigate("/plans");
    };

    async function getUserData() {
        setDataLoading(true);
        try {
            const userData: any = await Login({
                authId: user !== undefined ? user.sub : ""
            });
            if (userData.user_id !== undefined) {
                let curuserInfo: Context = new Context();
                curuserInfo.userId = userData.user_id;
                curuserInfo.clientId = userData.client_id;
                curuserInfo.clientOrgName = userData.client_org;
                curuserInfo.clientAddress = userData.client_address;
                curuserInfo.clientContactNumber = userData.client_contact_number;
                curuserInfo.email = userData.email;
                curuserInfo.firstName = userData.first_name;
                curuserInfo.lastName = userData.last_name;
                curuserInfo.fullName = userData.first_name + " " + userData.last_name;
                curuserInfo.token = userData.token;
                curuserInfo.userRole = userData.user_role_name;
                curuserInfo.userRoleId = userData.role_id;
                curuserInfo.subscriptions = userData.subscriptions;
                curuserInfo.emailConfirmed = userData.email_confirmed;
                curuserInfo.clientIsFree = userData.is_free;
                curuserInfo.firstLogin = true;
                updateUserContext(curuserInfo);
                setDataLoading(false);
                //gotoMyprofile();  
                if (+userData.role_id === 3) {
                    if (userData.client_org === null) {
                        gotoSignup();
                    } else if (userData.subscriptions.length === 0) {
                        gotoPlans();
                    } else {
                        gotoMyprofile();
                    }
                }
                else if (+userData.role_id === 4) {
                    if (userData.is_free === true) {
                        if (userData.first_name === null && userData.last_name === null) {
                            gotoSignup();
                        }
                        else {
                            gotoMyprofile();
                        }
                    }

                } else {
                    gotoMyprofile();
                }
            }
        } catch (error) {
            setDataLoading(false);
            logout();
        }
    }

    function Init() {
        if (window.location.pathname == '/free') {
            loginWithRedirect({
                authorizationParams: {
                    prompt: 'login',
                    screen_hint: 'signup'
                },
            });
        } else if (window.location.pathname == '/free-login') {
            loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname,
                },
            });
        }
        else {
            if (isAuthenticated) {
                getUserData();
            } else {
                // let code = urlParams.get("code");
                // let state = urlParams.get("state");
                // if (code !== null && state !== null && !isLoading) {
                //     loginWithRedirect({
                //         appState: {
                //             returnTo: window.location.pathname,
                //         },
                //     });
                // }
                // logout();
                //setDataLoading(false);
            }
        }
    }

    useEffect(() => {
        let initContext: Context = new Context();
        updateUserContext(initContext);
    }, []);

    useEffect(() => {
        Init();
    }, [user]);

    useEffect(() => {
        if (!window.location.pathname.includes('free')) {
            let code = urlParams.get("code");
            let state = urlParams.get("state");
            if (code !== null && state !== null && !isLoading && !isAuthenticated) {
                loginWithRedirect({
                    appState: {
                        returnTo: window.location.pathname,
                    },
                });
            } else {
                if (!isAuthenticated) {
                    setDataLoading(isLoading);
                }
            }
        }
    }, [isLoading]);
    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box sx={{
                    backgroundColor: "#E5E5E5",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    height: "100%",
                    width: "100%",
                }}>
                    {isDataLoading ? <Box className="main-loding">
                        <img loading="lazy" alt="imprend" src='/loader.gif' style={{ height: '200px', width: 'auto' }}></img>
                    </Box> :
                        <Paper
                            elevation={3}
                            className="paper-singin"
                        >
                            <Grid item className="signin-wrapper" xs={4}>

                                <React.Fragment>
                                    <Box
                                        className="box-container pre-signin-wrapper"
                                        sx={{ my: 2 }}
                                    >
                                        <Paper className="paper-container-forgot paper-pre-signin">
                                            <Grid
                                                marginTop={3}
                                                justifyContent="center"
                                                item
                                            > <a href="https://www.imprend.com"
                                            >
                                                    <img loading="lazy" className='img-page'
                                                        src={logo} alt="logo" /></a>
                                            </Grid>
                                            <Grid item sx={{ display: 'flex', fontSize: '20px' }}><b>Welcome!</b></Grid>
                                            <Grid item sx={{ display: 'flex', marginTop: '20px' }}>Have we met before?</Grid>

                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Button
                                                        sx={{ marginTop: 6 }}
                                                        fullWidth
                                                        variant="contained"
                                                        type="submit"
                                                        onClick={() => loginWithRedirect({
                                                            authorizationParams: {
                                                                prompt: 'login',
                                                                screen_hint: 'signup'
                                                            }
                                                        })}
                                                    >
                                                        New user
                                                    </Button>
                                                </Grid>
                                                <Grid container sx={{ display: 'flex' }}>
                                                    <Grid item xs={5.5} sx={{ borderBottom: '1px solid #e9e9e9' }}></Grid>
                                                    <Grid item xs={1} sx={{ paddingTop: '5px', color: '#e9e9e9' }}><Typography sx={{ position: 'relative', top: '7px' }}>or</Typography></Grid>
                                                    <Grid item xs={5.5} sx={{ borderBottom: '1px solid #e9e9e9' }}></Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        sx={{ marginTop: 3, border: '1px solid' }}
                                                        fullWidth
                                                        //variant="contained"
                                                        type="submit"
                                                        onClick={() => loginWithRedirect({
                                                            appState: {
                                                                returnTo: window.location.pathname,
                                                            },
                                                        })}
                                                    >
                                                        Existing
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Box>
                                </React.Fragment>

                            </Grid>
                            <Grid className='img-wrapper' item xs={6} >
                            </Grid>

                        </Paper>}
                </Box>
            </Fade>
        </>
    );
}