import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Handle, Position, NodeProps, useReactFlow, getOutgoers, MarkerType, getIncomers } from 'reactflow';
import cx from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import styles from './NodeTypes.module.css';
import useNodeAddChild from '../../hooks/useNodeAddChild';
import useNodeAddParent from '../../hooks/useNodeAddParent';
import useNodeAddConcurrent from '../../hooks/useNodeAddConcurrent';
import useNodeClickDelete from '../../hooks/useNodeDelete';
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography, useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
// import { uuid, randomLabel } from './../../utils';
import { AlertType } from '../../shared/AlertType';
import { PreferencesContext } from '../../PreferenceContext';
import { LoadingButton } from '@mui/lab';
import { AddStep, AddStepEdge, StepEdgeList } from '../../services/StepService';
// import useLayout from '../../hooks/useLayout';

const formInitialValues = {
  answerId: 0,
  description: "",
  title: "",
  stepId: 0
};

const InitialNode = ({ id, data }: NodeProps) => {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [forminitial, setFormInitial] = useState(formInitialValues);
  const [loading, setLoading] = useState(false);
  const [isFocusedName, setFocusedName] = useState(false);
  const [isFocusedDescription, setFocusedDescription] = useState(false);
  const formValidation = Yup.object().shape({
    title: Yup.string().required("Step title is required."),
  });
  const titleInput: any = useRef(null);
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();

  const onDeleteClick = useNodeClickDelete(id);
  const onClick = useNodeAddChild(id, "", "");
  const onUpClick = useNodeAddParent(id);
  //const onUpClick = useNodeAddParent('2');
  const onSideClick = useNodeAddConcurrent(id);
  // const onUseLayout = useLayout();
  const mainTheme = useTheme();

  const onAddClick = () => {
    if (id !== '2') {
      // onClick();
      const parentNode = getNode(id);
      if (parentNode) {
        const allStartChilds = getOutgoers(parentNode, getNodes(), getEdges()).filter((node) => node.id !== '2');
        if (allStartChilds !== null && allStartChilds !== undefined && allStartChilds.length > 0) {
        } else {
          onAddAfterClick();
        }
      }
    }
  }

  // async function addAfterHook (title: any, description: any) {
  //   setLoading(true);
  //   try {
  //     const parentNode = getNode(id);

  //     if (!parentNode) {
  //       return;
  //     }
  //     if (id === '1') {
  //       const allStartChilds = getOutgoers(parentNode, getNodes(), getEdges()).filter((node) => node.id !== '2');
  //       if (allStartChilds !== null && allStartChilds !== undefined && allStartChilds.length > 0) {
  //         return;
  //       }
  //     }
  //     let requestData = {
  //       stepUid: 0,
  //       hiagramUid: userContext.hiagramId,
  //       cessUid: 1,
  //       name: title,
  //       description: description,
  //       isDeleted: false,
  //       dataManagedBy: userContext.userId
  //     }
  //     let data: any;
  //     data = await AddStep(requestData);

  //     if (data !== null && data.data !== null) {

  //       //const childNodeId = uuid();

  //       const childNodeId = data.data.toString();

  //       const childNode = {
  //         id: childNodeId,
  //         position: { x: parentNode.position.x, y: parentNode.position.y + 200 },
  //         type: 'step',
  //         data: { title: title, isMenuOpen: false, description: description },
  //       };

  //       const childEdge = {
  //         id: `${parentNode.id}=>${childNodeId}`,
  //         source: parentNode.id,
  //         target: childNodeId,
  //         data: { uid: 0 },
  //         type: 'step',
  //         style: { stroke: '#8C0003', strokeDasharray: 3 },
  //         markerEnd: {
  //           type: MarkerType.Arrow,
  //           color: '#8C0003',
  //           height: 20,
  //           width: 20,
  //         },
  //       };

  //       setNodes((nodes) =>
  //         nodes.concat([childNode])
  //       );

  //       setEdges((edges) =>
  //         edges.concat([childEdge])
  //       );

  //       //add mapping to all parent
  //       const allParentIds = getIncomers(parentNode, getNodes(), getEdges()).map((node) => node.id);

  //       const topParentNode = getNode(allParentIds[0]);

  //       if (topParentNode) {
  //         const allChilds = getOutgoers(topParentNode, getNodes(), getEdges());
  //         if (allChilds.length > 0) {
  //           //Connect with parents
  //           allChilds.forEach(child => {
  //             if (child.id !== id) {
  //               const childEdge = {
  //                 id: `${child.id}=>${childNodeId}`,
  //                 source: child.id,
  //                 target: childNodeId,
  //                 data: { uid: 0 },
  //                 type: 'step',
  //                 style: { stroke: '#8C0003', strokeDasharray: 3 },
  //                 markerEnd: {
  //                   type: MarkerType.Arrow,
  //                   color: '#8C0003',
  //                   height: 20,
  //                   width: 20,
  //                 },
  //               };
  //               setEdges((edges) =>
  //                 edges.concat([childEdge])
  //               );
  //             }
  //           });

  //           //Connect with parent childs
  //           const allInnerChilds = getOutgoers(allChilds[0], getNodes(), getEdges());
  //           if (allInnerChilds.length > 0) {
  //             const allMapChilds = getOutgoers(allInnerChilds[0], getNodes(), getEdges());
  //             if (allMapChilds.length > 0) {
  //               allMapChilds.forEach(child => {
  //                 const childEdge = {
  //                   id: `${childNodeId}=>${child.id}`,
  //                   source: childNodeId,
  //                   target: child.id,
  //                   data: { uid: 0 },
  //                   type: 'step',
  //                   style: { stroke: '#8C0003', strokeDasharray: 3 },
  //                   markerEnd: {
  //                     type: MarkerType.Arrow,
  //                     color: '#8C0003',
  //                     height: 20,
  //                     width: 20,
  //                   },
  //                 };
  //                 setEdges((edges) =>
  //                   edges.concat([childEdge])
  //                 );
  //               });
  //             }
  //           }
  //         }
  //       }

  //       //Remove all end edges
  //       const endNode = getNode('2');
  //       if (endNode) {
  //         const allParentIds = getIncomers(endNode, getNodes(), getEdges()).map((node) => node.id);
  //         if (allParentIds.length > 0) {
  //           allParentIds.forEach(node => {
  //             setEdges((edges) =>
  //               edges.filter((edge) => edge.id !== `${node}=>2`));
  //           });
  //         }
  //       }

  //       //Connect to end node
  //       const allNodes = getNodes();
  //       if (allNodes.length > 0) {
  //         allNodes.forEach(node => {
  //           const allChilds = getOutgoers(node, getNodes(), getEdges());
  //           if (allChilds.length === 0 && node.id != '2') {
  //             const endEdge = {
  //               id: `${node.id}=>2`,
  //               source: node.id,
  //               target: '2',
  //               data: { uid: 0 },
  //               type: 'step',
  //               style: { stroke: '#8C0003', strokeDasharray: 3 },
  //               markerEnd: {
  //                 type: MarkerType.Arrow,
  //                 color: '#8C0003',
  //                 height: 20,
  //                 width: 20,
  //               },
  //             };
  //             setEdges((edges) =>
  //               edges.concat([endEdge])
  //             );
  //           }
  //         });
  //       }
  //       setOpenAddEditModal(false);
  //     }
  //     setLoading(false);
  //   }
  //   catch (error: any) {
  //     setLoading(false);
  //     if (error) {
  //       updateUserContext({
  //         ...userContext,
  //         isAlert: true,
  //         alertMessage: error.response.message,
  //         alertType: AlertType.Error,
  //       });
  //     }
  //   }
  // }

  async function addAfterHook(title: any, description: any) {
    setLoading(true);
    let allEdgesData: any[] = [];
    try {
      const parentNode = getNode(id);

      if (!parentNode) {
        return;
      }
      if (id === '1') {
        const allStartChilds = getOutgoers(parentNode, getNodes(), getEdges()).filter((node) => node.id !== '2');
        if (allStartChilds !== null && allStartChilds !== undefined && allStartChilds.length > 0) {
          return;
        }
      }

      let requestData = {
        stepUid: 0,
        hiagramUid: userContext.hiagramId,
        cessUid: data.cessId,
        name: title,
        description: description,
        position: (parentNode.position.x + "," + (parentNode.position.y + 200)).toString(),
        isDeleted: false,
        dataManagedBy: userContext.userId
      }
      let stepData: any;
      stepData = await AddStep(requestData);

      if (stepData !== null && stepData.data !== null) {

        //const childNodeId = uuid();

        const childNodeId = stepData.data.toString();

        const childNode = {
          id: childNodeId,
          position: { x: parentNode.position.x, y: parentNode.position.y + 200 },
          type: 'step',
          data: { title: title, isMenuOpen: false, description: description, cessId: data.cessId, stakeCount: 0, hintCount: 0, assetCount: 0, docCount: 0 },
        };

        const childEdge = {
          id: `${parentNode.id}=>${childNodeId}`,
          source: parentNode.id,
          target: childNodeId,
          data: { uid: 0 },
          type: 'step',
          style: { stroke: mainTheme.palette.primary.main, strokeDasharray: 3 },
          markerEnd: {
            type: MarkerType.Arrow,
            color: mainTheme.palette.primary.main,
            height: 20,
            width: 20,
          },
        };
        allEdgesData.push(childEdge);

        //add mapping to all parent
        const allParentIds = getIncomers(parentNode, getNodes(), getEdges()).map((node) => node.id);

        const topParentNode = getNode(allParentIds[0]);

        if (topParentNode) {
          const allChilds = getOutgoers(topParentNode, getNodes(), getEdges());
          if (allChilds.length > 0) {
            //Connect with parents
            allChilds.forEach(child => {
              if (child.id !== id) {
                const childEdge = {
                  id: `${child.id}=>${childNodeId}`,
                  source: child.id,
                  target: childNodeId,
                  data: { uid: 0 },
                  type: 'step',
                  style: { stroke: mainTheme.palette.primary.main, strokeDasharray: 3 },
                  markerEnd: {
                    type: MarkerType.Arrow,
                    color: mainTheme.palette.primary.main,
                    height: 20,
                    width: 20,
                  },
                };
                allEdgesData.push(childEdge);
                // setEdges((edges) =>
                //   edges.concat([childEdge])
                // );
              }
            });

            //Connect with parent childs
            const allInnerChilds = getOutgoers(allChilds[0], getNodes(), getEdges());
            if (allInnerChilds.length > 0) {
              const allMapChilds = getOutgoers(allInnerChilds[0], getNodes(), getEdges());
              if (allMapChilds.length > 0) {
                allMapChilds.forEach(child => {
                  const childEdge = {
                    id: `${childNodeId}=>${child.id}`,
                    source: childNodeId,
                    target: child.id,
                    data: { uid: 0 },
                    type: 'step',
                    style: { stroke: mainTheme.palette.primary.main, strokeDasharray: 3 },
                    markerEnd: {
                      type: MarkerType.Arrow,
                      color: mainTheme.palette.primary.main,
                      height: 20,
                      width: 20,
                    },
                  };
                  allEdgesData.push(childEdge);
                  // setEdges((edges) =>
                  //   edges.concat([childEdge])
                  // );
                });
              }
            }
          }
        }
        const endEdge = {
          id: `${childNodeId}=>2`,
          source: childNodeId,
          target: 2,
          data: { uid: 0 },
          type: 'step',
          style: { stroke: mainTheme.palette.primary.main, strokeDasharray: 3 },
          markerEnd: {
            type: MarkerType.Arrow,
            color: mainTheme.palette.primary.main,
            height: 20,
            width: 20,
          },
        };
        allEdgesData.push(endEdge);

        if (allEdgesData.length > 0) {
          let requestData: any[] = [];
          allEdgesData.forEach(edge => {
            let edgeData = {
              stepEdgeUid: 0,
              hiagramUid: userContext.hiagramId,
              cessUid: data.cessId,
              name: edge.id,
              isDeleted: false,
              dataManagedBy: userContext.userId
            }
            requestData.push(edgeData);
          });
          let stepData: any;
          stepData = await AddStepEdge(requestData);
          if (stepData !== null && stepData.data !== null && stepData.data === true) {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("cuid", data.cessId.toString());
            let listData: any;
            listData = await StepEdgeList(params);
            if (listData !== null && listData.data !== null && listData.data.data !== null) {
              let allEdgeListData = listData.data.data as any[];
              if (allEdgeListData.length > 0) {
                allEdgesData.forEach(edge => {
                  edge.data.uid = allEdgeListData.find((x) => x.name === edge.id).uid;
                });


                setNodes((nodes) =>
                  nodes.concat([childNode])
                );
                const endStep = {
                  id: '2',
                  data: { label: 'End' },
                  position: { x: 0, y: 150 },
                  type: 'initial',
                };
                setNodes((nodes) =>
                  nodes.concat([endStep])
                );

                setEdges((edges) =>
                  edges.concat([...allEdgesData])
                );


                // //Remove all end edges
                // const endNode = getNode('2');
                // if (endNode) {
                //   const allParentIds = getIncomers(endNode, getNodes(), getEdges()).map((node) => node.id);
                //   if (allParentIds.length > 0) {
                //     allParentIds.forEach(node => {
                //       setEdges((edges) =>
                //         edges.filter((edge) => edge.id !== `${node}=>2`));
                //     });
                //   }
                // }

                //Connect to end node
                const allNodes = getNodes();
                if (allNodes.length > 0) {
                  allNodes.forEach(node => {
                    const allChilds = getOutgoers(node, getNodes(), getEdges());
                    if (allChilds.length === 0 && node.id !== '2') {
                      const endEdge = {
                        id: `${node.id}=>2`,
                        source: node.id,
                        target: '2',
                        data: { uid: 0 },
                        type: 'step',
                        style: { stroke: mainTheme.palette.primary.main, strokeDasharray: 3 },
                        markerEnd: {
                          type: MarkerType.Arrow,
                          color: mainTheme.palette.primary.main,
                          height: 20,
                          width: 20,
                        },
                      };
                      setEdges((edges) =>
                        edges.concat([endEdge])
                      );
                    }
                  });
                }
              }
            }
          }

        }

        setOpenAddEditModal(false);
      }
      setLoading(false);
    }
    catch (error: any) {
      setLoading(false);
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  const onAddAfterClick = () => {
    setFormInitial(formInitialValues);
    setOpenAddEditModal(true);
  }

  const handleCloseAddEditModal = () => {
    setFormInitial(formInitialValues);
    setOpenAddEditModal(false);
  }

  function submitForm(values: any, resetForm: any) {
    addAfterHook(values.title, values.description);
    resetForm();
  }
  useEffect(() => {
    if (titleInput.current !== undefined && titleInput.current !== null) {
      titleInput.current.focus();
    }
  }, []);

  return (
    <>
      <div
        className={cx(styles.initnodebox)}
      >
        <div
          className={cx(styles.initnode)}
          onClick={onAddClick}
        >
          {data.label}
        </div>
        <Handle className={styles.handle} type="target" position={Position.Top} isConnectable={false} />
        <Handle className={styles.handle} type="source" position={Position.Bottom} isConnectable={false} />
      </div>
      <Dialog
        open={openAddEditModal}
        fullWidth={true}
        keepMounted
        maxWidth={"md"}
        //onClose={handleCloseAddEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='delete-client-dialog-box'
      >
        <DialogTitle sx={{ fontSize: '20px', fontWeight: 'bold' }}>Add step after</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={forminitial}
            validationSchema={formValidation}
            onSubmit={(values: any, { resetForm }) => {
              submitForm(values, resetForm);
            }}
          >{(props: any) => {
            return (
              <>
                <Form onSubmit={props.handleSubmit} noValidate>
                  <Grid container spacing={2} sx={{ padding: '5px' }}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                      <Typography>
                        Step Title
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ position: 'relative' }}>
                      <TextField
                        autoFocus
                        // ref={(value) => value?.focus()}
                        id="title"
                        // label="Step Title"
                        inputProps={{ maxLength: 90 }}
                        fullWidth
                        // placeholder="Contrary to popular belief"
                        placeholder="Step Title"
                        onChange={props.handleChange.bind("title")}
                        onBlur={() => setFocusedName(false)}
                        value={props.values.title}
                        onFocus={() => setFocusedName(true)}
                        className={cx(styles.stepTextFields)}
                        error={
                          props.values.title === "" && props.errors.title && props.touched.title
                            ? true
                            : false
                        }
                      >
                      </TextField>
                      {isFocusedName && <Typography sx={{ position: 'absolute', right: '0px' }}>{(props.values.title !== null ? props.values.title.length : 0) + "/" + 90}</Typography>}
                      {props.errors.title && props.touched.title ?
                        <Typography className="validation-message" >
                          {props.errors.title}
                        </Typography> : <></>}
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'start', justifyContent: 'end', marginTop: '15px' }}>
                      <Typography sx={{ marginTop: '5px' }}>
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ position: 'relative', marginTop: '15px' }}>
                      <TextField
                        id="description"
                        // label="Description"
                        inputProps={{ maxLength: 2000 }}
                        fullWidth
                        multiline={true}
                        rows={4}
                        className={cx(styles.stepTextFields)}
                        placeholder="Description"
                        onChange={props.handleChange.bind("description")}
                        onBlur={() => setFocusedDescription(false)}
                        value={props.values.description}
                        onFocus={() => setFocusedDescription(true)}
                      >
                      </TextField>
                      {isFocusedDescription && <Typography sx={{ position: 'absolute', right: '0px' }}>{(props.values.description !== null ? props.values.description.length : 0) + "/" + 2000}</Typography>}
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          props.resetForm();
                          handleCloseAddEditModal();
                        }}
                        className="popup-btn"
                      >
                        Cancel
                      </Button>
                    </Grid>

                    <Grid item xs={6} >
                      <LoadingButton
                        variant='contained'
                        //onClick={deleteNode}
                        type='submit'
                        // className="popup-btn"
                        className={cx(styles.stepPopupBtn)}
                        loading={loading}
                        startIcon={!loading ? <AddIcon /> : <AddIcon sx={{ color: "transparent" }} />}
                      >
                        Add
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(InitialNode);
