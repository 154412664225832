import React from "react";
import "./Plan.scss"
import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
// import TickIcon from '../../assets/icons/tick-grey.svg'
import TickIcon from '../../assets/icons/plan-tick-icon.svg';
import getSymbolFromCurrency from 'currency-symbol-map'
import { LoadingButton } from "@mui/lab";

export default function Plan(props: any) {
    const [loading, setLoading] = React.useState(false);

    return (
        <>
            <Box className='plan-component-container'>
                {/* <Card sx={{ minWidth: 275, backgroundColor: props.planData !== null && props.planData.metadata.highlighted === "1" ? "#ffffff" : "#ffffff" }} className="plan-component-card"> */}
                <Card
                    sx={{ borderColor: props.planData !== null && props.planData.metadata.highlighted === "1" ? "#680001" : "#E9E9E9" }}
                    className="plan-component-card">
                    {props.planData !== null && props.planData.metadata.highlighted === "1" && props.clientId === 0 ?
                        <Box className="card-top-box"><Typography>Most popular plan</Typography></Box>
                        : <></>}
                    <CardContent className="plan-component-card-content">
                        <Box>
                            <Box>
                                {props.planData !== null ?
                                    <Typography variant="h4" sx={{ color: "#000000" }} className="plan-component-card-header">
                                        {
                                            props.planLoading === false ? props.planData.name : <Skeleton animation="wave" width={150} />
                                        }
                                    </Typography>
                                    :
                                    <></>
                                }
                            </Box>
                            <Box className="plan-component-card-subheader">
                                <Box className="plan-component-card-price-detail">
                                    {props.planData !== null ?
                                        <>
                                            {/* <Box className="plan-component-card-detail">From</Box> */}
                                            <Box className="plan-component-card-subdetail">
                                                {props.planData.metadata.ready === "1" ?
                                                    <><Box className="plan-component-card-span">
                                                        <Typography> {getSymbolFromCurrency(props.planData.default_price.currency)}{(props.planData.default_price.unit_amount / 100).toFixed(2)}/</Typography>
                                                    </Box>
                                                        <Box className="plan-component-card-out-of-span">
                                                            <Typography>  handover document</Typography>
                                                        </Box>
                                                    </> : <>
                                                        <Box className="plan-component-card-out-of-span">
                                                            <Typography> {props.planData.metadata.label}</Typography>
                                                        </Box></>}
                                            </Box>


                                            {/* <Box className="plan-component-card-subdetail">
                                                <Box className="plan-component-card-span">
                                                    {props.planData.metadata.highlighted === "1" ?
                                                        <Typography> {getSymbolFromCurrency(props.planData.default_price.currency)}{(props.planData.default_price.unit_amount / 100).toFixed(2)}/</Typography>
                                                        : <></>}
                                                </Box>
                                                <Box className="plan-component-card-out-of-span">
                                                    {props.planData.metadata.highlighted === "1" ?
                                                        <Typography>  handover document</Typography> : <></>}
                                                </Box>
                                            </Box> */}
                                        </>
                                        : props.planLoading === false ? <></> :
                                            <>
                                                <Skeleton animation="wave" width={100} height={40} />
                                                <Skeleton animation="wave" width={180} height={70} />

                                            </>
                                    }
                                </Box>
                            </Box>
                            <Box className="plan-component-card-subheader">
                                {
                                    props.planLoading === false ? props.planData.description :
                                        <>
                                            <br />
                                            <br />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" width="50%" />
                                        </>
                                }
                            </Box>

                            <Box className="plan-component-card-feature-list-main-box">
                                <Box className="plan-component-card-featur-list-main-box">
                                    {props.planLoading === false ?
                                        <Box className="plan-component-card-featur-list-sub-box">
                                            {props.planData.feature_list !== undefined && props.planData.feature_list.map((feature: any, index: any) => (
                                                <Box className="plan-component-card-feature-list-box" key={index}>
                                                    <Box className="plan-component-card-featur-list-left-box">
                                                        <img loading="lazy" className='tick-icon' alt='imprend' src={TickIcon} />
                                                    </Box>
                                                    <Box sx={{ color: "#606060" }} className="plan-component-card-featur-list-right-box">{feature}</Box>
                                                </Box>))
                                            }
                                        </Box> :
                                        <>
                                            <br />
                                            <br />
                                            <Skeleton animation="wave" width="100%" height={40} />
                                            <Skeleton animation="wave" width="100%" height={40} />
                                            <Skeleton animation="wave" width="100%" height={40} />
                                            <Skeleton animation="wave" width="100%" height={40} />
                                            <Skeleton animation="wave" width="100%" height={40} />
                                        </>}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            {props.planLoading === false ? (props.planData !== null && props.planData.metadata.button === "1") ?
                                <LoadingButton
                                    className="plan-component-card-button"
                                    fullWidth
                                    disableRipple
                                    disableFocusRipple
                                    disableElevation
                                    loading={props.planData !== null ? props.planData.loading === true ? true : false : false}
                                    loadingPosition="start"
                                    startIcon={<></>}
                                    variant='contained'
                                    onClick={() => {
                                        if (props.clientId === 0) {
                                            props.onPlanClick(props.planData);
                                        } else {
                                            if (props.planData.metadata.highlighted !== "1") {
                                                props.onPlanClick(props.planData);
                                                setLoading(!loading);
                                                props.planData.loading = true;
                                            }
                                        }
                                    }}
                                >
                                    {props.planData !== null ? props.planData.metadata.label : ""}
                                </LoadingButton>
                                :
                                <LoadingButton
                                    className="plan-component-card-typo"
                                    disableRipple
                                    disableFocusRipple
                                    disableElevation
                                    fullWidth
                                    loading={props.planData !== null ? props.planData.loading === true ? true : false : false}
                                    loadingPosition="start"
                                    startIcon={<></>}
                                    variant='contained'
                                    onClick={() => {
                                        // if (props.clientId > 0) {
                                        //     props.onPlanClick(props.planData);
                                        //     setLoading(!loading);
                                        //     props.planData.loading = true;
                                        // }
                                    }}
                                // onClick={() => {
                                //     //setLoading(!loading);
                                //     //props.planData.loading = true;
                                //     // props.onPlanClick(props.planData);
                                // }}
                                >
                                    {props.planData !== null ? props.planData.metadata.label : ""}
                                </LoadingButton>
                                :
                                <Skeleton animation="wave" width="100%" height={80} />
                            }
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
}