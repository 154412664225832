import { Grid, DialogContentText, DialogActions, Button, TableContainer, Paper, Skeleton, Table, TableHead, TableRow, Tooltip, TableCell, TableBody, tableCellClasses, styled, Typography, Box, useTheme, Card, TooltipProps, tooltipClasses } from "@mui/material";
import * as React from "react";
import editIcon from '../../../assets/icons/edit-2.svg'
import trashIcon from '../../../assets/icons/trash.svg'
import ActionWB from '../../../assets/icons/action-wb.svg'
import Action from '../../../assets/icons/time-icon.svg'
import { PreferencesContext } from "../../../PreferenceContext";
import ClearIcon from '@mui/icons-material/Clear';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { AlertType } from "../../../shared/AlertType";
import Slide from '@mui/material/Slide';
import _ from "lodash";
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import ItemActionAddModel from './ItemActionAddModel'
import { TransitionProps } from '@mui/material/transitions';
import "./ItemActionAdd.scss";
import { CreateQusAnswer, DeleteAction, GetActionList, GetQuestionAnswer, GetStatusList, GetTypeList } from "../../../services/ActionService";
import { getNameSelected } from "../../../services/common/commonFunction";


interface IActionDetail {
    id: any;
    actionMappingId: any;
    type: any;
    name: any;
    dueDate: any;
    description: any;
    mitigation: any;
    status: any;
}

const initActionDetail: IActionDetail = {
    id: 0,
    actionMappingId: 0,
    type: "",
    name: "",
    dueDate: "",
    description: "",
    mitigation: "",
    status: ""
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: "#F58A80",
        backgroundColor: "#f37368",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#FEF3F2",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        describeChild={true}
        {...props}
        classes={{ popper: className }}
        slotProps={{ popper: { disablePortal: true } }}
        PopperProps={{
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['right', 'left', 'top', 'bottom'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundariesElement: 'window',
                    },
                },
            ],
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        minWidth: 500,
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: '10px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    [`& .${tooltipClasses.popper}`]: {
        marginTop: theme.spacing(1),
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//export default function ItemActionAdd() {
const ItemActionAdd = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editMappingId, setEditMappingId] = useState(0);
    const [questionAnswerId, setQuestionAnswerId] = useState(0);
    const [actionList, setActionList] = useState([initActionDetail]);
    // const [data, setData] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [openActionDeleteModal, setActionDeleteModal] = useState(false);
    const [typeData, setTypeData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const mainTheme = useTheme();

    useImperativeHandle(ref, () => ({
        submitClick() {
            saveAnswer();
        },
        getData() {
            fetchType();
            fetchStatus();
            fetchActionList();
            if (+param.curQuestion.number > 0) {
                fetchActionQuestionAnswer();
            }
        }
    }),);

    const handleClickOpen = () => {
        setEditId(0);
        setEditMappingId(0);
        setIsUpdate(false);
        setOpenDialog(true);
    };
    const handleClickOpenForEdit = (id: any, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setIsUpdate(true);
        setOpenDialog(true);
    };

    const handleOpenActionDeleteModal = (id: number, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setActionDeleteModal(true);
    };
    const handleCloseActionDeleteModal = () => {
        setActionDeleteModal(false);
        // fetchActionList();
        // fetchActionQuestionAnswer();
    };

    const handleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialog(false);
        // fetchActionList();
        // fetchActionQuestionAnswer();
    };
    const handleSubmitClose = () => {
        setOpenDialog(false);
        fetchActionList();
        fetchActionQuestionAnswer();
    };

    async function fetchType() {
        try {
            const typeGetData: any = await GetTypeList();
            if (typeGetData !== undefined && typeGetData !== null && typeGetData.data !== null && typeGetData.data.data !== null && typeGetData.data.data.data !== null) {
                setTypeData(typeGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchStatus() {
        try {
            const statusGetData: any = await GetStatusList();
            if (statusGetData !== undefined && statusGetData !== null && statusGetData.data !== null && statusGetData.data.data !== null && statusGetData.data.data.data !== null) {
                setStatusData(statusGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchActionList() {
        try {
            setLoading(true);
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            if (+param.curQuestion.number === 4) {
                let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
                let appendUID = userContext.teamProjId;
                if (param.curQuestion.hiagram !== undefined) {
                    appendType = param.curQuestion.hiagram.type;
                    appendUID = param.curQuestion.hiagram.typeId;
                }
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            } else if (+param.curQuestion.number === 19) {
                let appendType = "ACTIVITY";
                let appendUID = userContext.activityId;
                if (param.curQuestion.hiagram !== undefined) {
                    appendType = param.curQuestion.hiagram.type;
                    appendUID = param.curQuestion.hiagram.typeId;
                }
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            } else if (+param.curQuestion.number === 0) {
                let appendType = "NONE";
                let appendUID = 0;
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            }

            const actuionres: any = await GetActionList(params);
            if (actuionres !== null && actuionres.data !== null && actuionres.data.data !== null) {
                let data = actuionres.data.data;
                let list: any[] = [];
                data.forEach((element: any) => {
                    let item: IActionDetail = {
                        id: element.uid,
                        type: element.type,
                        name: element.name,
                        dueDate: element.due_date != null ? element.due_date.split("-").reverse().join("-") : "",
                        description: element.description,
                        status: element.status,
                        mitigation: element.mitigation,
                        actionMappingId: element.actionMapping === undefined ? 0 : element.actionMapping[0].uid,
                    }
                    list.push(item);
                });
                setActionList(list);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchActionQuestionAnswer() {
        try {
            setLoading(true);
            let projUID = 0;
            let teamUID = 0;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            params.append("tuid", teamUID.toString());
            params.append("puid", projUID.toString());
            params.append("ruid", userContext.roleId.toString());
            params.append("auid", userContext.activityId.toString());
            const questionAnswer: any = await GetQuestionAnswer(params);
            if (questionAnswer !== null && questionAnswer.data !== null && questionAnswer.data.data !== null) {
                let data = questionAnswer.data.data;
                setQuestionAnswerId(data.uid);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function deleteAction() {
        setBtnLoading(true);
        try {
            const action: any = await DeleteAction(editId, {
                huid: userContext.hiagramId,
                mappingUid: editMappingId,
                is_deleted: true,
                row_managed_by: userContext.userId
            });
            if (action.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: action.message,
                    alertType: AlertType.Success,
                    isItemCountUpdated: true
                });
                // handleCloseActionDeleteModal();
                setActionDeleteModal(false);
                fetchActionList();
                fetchActionQuestionAnswer();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function saveAnswer() {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            let projUID = 0;
            let teamUID = 0;
            let roleUID = userContext.roleId;
            let activityUID = userContext.activityId;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            try {
                let requestData = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: questionAnswerId,
                    teamUid: teamUID,
                    projUid: projUID,
                    roleUid: roleUID,
                    activityUid: activityUID,
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }

                let data: any;
                data = await CreateQusAnswer(requestData);
                if (data !== null && data.data === true) {
                    param.changeNextQuestion();
                }
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            param.changeNextQuestion();
        }
    }

    // useEffect(() => {
    //     fetchActionList();
    //     fetchActionQuestionAnswer();
    // }, []);

    useEffect(() => {
        // if (param.QuickView === true) {
        //     fetchActionList();
        // }
    }, [actionList]);
    let evenCounter = 0;
    let oddCounter = 0;
    return (
        <Grid container className="action-list-grid" >
            {param.QuickView === false ?
                <Grid item xs={12} className="action-icon-group" ><img loading="lazy" alt="imprend" src={Action} />
                    <Typography className="action-icon-name">{+param.curQuestion.number === 4 ? 'Milestone' : 'Action'}</Typography></Grid>
                : <Grid item xs={12} className="heading-container-grid-action">
                    <Grid className="header-css" item xs={12}>
                        <img loading="lazy" alt="imprend" className='header-icon' src={Action} />
                        <Typography className="icon-text">{+param.curQuestion.number === 4 ? 'Milestone' : 'Action'}</Typography>
                    </Grid>
                    {/* <Grid item xs={1} className="clear-icon-grid">
                        <Button
                            disableRipple
                            className="btn-cancel"
                            onClick={param.onClose}
                        >
                            <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                        </Button>
                    </Grid> */}
                </Grid>}

            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Type</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Name</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Due Date</StyledTableCell>
                            {/* <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Description</StyledTableCell> */}
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Status</StyledTableCell>
                            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell align="center" sx={{ width: '20%', fontSize: 12 }}>Action</StyledTableCell> : <></>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {isLoading === true ? (
                                _.times(4, (i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={6}>
                                            <Skeleton variant="rounded" width='auto' height={25} />
                                        </TableCell>
                                    </TableRow>
                                ))) : actionList.filter((x) => x.id > 0).length > 0 ? (actionList && actionList.map((row: any, index: any) => {
                                    const placement = index < 3 ? 'bottom' : 'top';
                                    return (
                                        <HtmlTooltip
                                            key={index}
                                            placement={placement}
                                            title={
                                                <React.Fragment>
                                                    <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.type, typeData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Type:</b> {getNameSelected(row.type, typeData)}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.dueDate)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Due date:</b> {row.dueDate}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.status, statusData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Status:</b> {getNameSelected(row.status, statusData)}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.mitigation)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Mitigation:</b> {row.mitigation}</Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <StyledTableRow
                                                sx={{ fontSize: 12, height: 50, cursor: 'pointer' }}
                                                key={row.id}>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{getNameSelected(
                                                        row.type,
                                                        typeData
                                                    )}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.name}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.dueDate}</StyledTableCell>
                                                {/* <StyledTableCell align="left">{row.description}</StyledTableCell> */}
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{getNameSelected(
                                                        row.status,
                                                        statusData
                                                    )}</StyledTableCell>
                                                {+param.curQuestion.number > 0 && userContext.isEditAccess === true ?
                                                    <StyledTableCell
                                                        sx={{ minWidth: "115px" }}>
                                                        <Grid container spacing={2} className='btn-grid'>
                                                            <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                                <Tooltip title='Edit'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='edit-btn'
                                                                        onClick={() => handleClickOpenForEdit(row.id, row.actionMappingId)}
                                                                    >
                                                                        <img loading="lazy" alt="imprend" className='edit-btn-logo' src={editIcon} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                                <Tooltip title='Delete'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='delete-btn'
                                                                        onClick={() => handleOpenActionDeleteModal(row.id, row.actionMappingId)}
                                                                    >
                                                                        <img loading="lazy" alt="imprend" className='delete-btn-logo' src={trashIcon} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell> : <></>}
                                            </StyledTableRow>
                                        </HtmlTooltip>
                                    )
                                })) : (
                                <TableRow key={1}>
                                    <TableCell colSpan={6} align='center'>
                                        None added yet!
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    </TableBody>
                </Table>
            </TableContainer>

            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ?
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Button className="action-btn"
                        onClick={handleClickOpen}
                        // startIcon={<AddIcon sx={{ color: "#F58A80" }} />}
                        startIcon={<AddIcon sx={{ color: "#f37368" }} />}
                        endIcon={<img loading="lazy" alt="imprend" className='icon-Hint' src={ActionWB} />}
                    >
                        {+param.curQuestion.number === 4 ? 'Add Milestone' : 'Add Action'}
                    </Button>
                </Grid> : <></>}

            {(+param.curQuestion.number === 0 || param.curQuestion.hiagram !== undefined) && actionList.length > 0 ?
                <Grid className='time-line-container-grid-action'>
                    <Timeline position="alternate">
                        <Box className='timeline-start'></Box>
                        <TimelineItem className='timeline-item-upper-grid'>
                            <TimelineSeparator className='timeline-seprator' >
                                <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: mainTheme.palette.primary.main }} />
                            </TimelineSeparator>
                            <TimelineContent></TimelineContent>
                        </TimelineItem>
                        {actionList.length > 0 && actionList.map((row, index) => {
                            return (
                                <>
                                    {index % 2 === 0 ?
                                        <>
                                            {(evenCounter++) % 2 === 0 ?
                                                <HtmlTooltip
                                                    key={index}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.mitigation)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Mitigation:</b> {row.mitigation}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TimelineItem sx={{ cursor: 'pointer' }} key={index}>
                                                        <TimelineSeparator sx={{ height: '70px' }}>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: mainTheme.palette.primary.main }}>
                                                            </TimelineDot>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingRight: "5px !important", marginLeft: "11px" }}>
                                                            <Box className='card-content-right-side' color={mainTheme.palette.primary.main}>
                                                                <Box className='card-one' sx={{ backgroundColor: mainTheme.palette.primary.main }} >
                                                                    <Typography className='typography-text'>{row.dueDate}</Typography>
                                                                    <Typography className='typography-text text-show-less'>{row.name}</Typography>
                                                                    {/* <Typography className='typography-text'>{getNameSelected(
                                                                    row.type,
                                                                    typeData
                                                                )}</Typography> */}
                                                                </Box>
                                                                <Card elevation={0} className='triangle triangle-right' sx={{ borderColor: `transparent transparent transparent ${mainTheme.palette.primary.main}` }}>
                                                                </Card>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </HtmlTooltip>
                                                :
                                                <HtmlTooltip
                                                    key={index}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.mitigation)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Mitigation:</b> {row.mitigation}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TimelineItem sx={{ cursor: 'pointer' }}>
                                                        <TimelineSeparator sx={{ height: '70px' }}>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#4D81B4" }}>
                                                            </TimelineDot>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingRight: "5px !important", marginLeft: "11px" }}>
                                                            <Box className='card-content-right-side' color="#4D81B4">
                                                                <Box className='card-one' sx={{ backgroundColor: "#4D81B4" }} >
                                                                    <Typography className='typography-text'>{row.dueDate}</Typography>
                                                                    <Typography className='typography-text text-show-less'>{row.name}</Typography>
                                                                    {/* <Typography className='typography-text'>{getNameSelected(
                                                                row.type,
                                                                typeData
                                                            )}</Typography> */}
                                                                </Box>
                                                                <Card elevation={0} className='triangle triangle-right' sx={{ borderColor: 'transparent transparent transparent #4D81B4' }}>
                                                                </Card>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </HtmlTooltip>
                                            }
                                        </>
                                        :
                                        <>
                                            {(oddCounter++) % 2 === 0 ?
                                                <HtmlTooltip
                                                    key={index}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.mitigation)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Mitigation:</b> {row.mitigation}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TimelineItem sx={{ cursor: 'pointer' }}>
                                                        <TimelineSeparator >
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: "#4FA79B" }} />
                                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#4FA79B" }}>
                                                            </TimelineDot >
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingLeft: "5px!important", marginRight: "11px" }}>
                                                            <Box className='card-content-left-side'>
                                                                <Card elevation={0} className='triangle triangle-left' sx={{ borderColor: '#ffffff  #4FA79B  #ffffff #ffffff' }}>
                                                                </Card>
                                                                <Box className='card-two' sx={{ backgroundColor: "#4FA79B" }} >
                                                                    <Typography className='typography-text'>{row.dueDate}</Typography>
                                                                    <Typography className='typography-text text-show-less'>{row.name}</Typography>
                                                                    {/* <Typography className='typography-text'>{getNameSelected(
                                                                    row.type,
                                                                    typeData
                                                                )}</Typography> */}
                                                                </Box>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </HtmlTooltip>
                                                :
                                                <HtmlTooltip
                                                    key={index}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.mitigation)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Mitigation:</b> {row.mitigation}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TimelineItem sx={{ cursor: 'pointer' }}>
                                                        <TimelineSeparator>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: "#A7679B" }} />
                                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#A7679B" }}>
                                                            </TimelineDot >
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingLeft: "5px!important", marginRight: "11px" }}>
                                                            <Box className='card-content-left-side'>
                                                                <Card elevation={0} className='triangle triangle-left' sx={{ borderColor: '#ffffff  #A7679B  #ffffff #ffffff' }}>
                                                                </Card>
                                                                <Box className='card-two' sx={{ backgroundColor: "#A7679B" }} >
                                                                    <Typography className='typography-text'>{row.dueDate}</Typography>
                                                                    <Typography className='typography-text text-show-less'>{row.name}</Typography>
                                                                    {/* <Typography className='typography-text'>{getNameSelected(
                                                                    row.type,
                                                                    typeData
                                                                )}</Typography>  */}
                                                                </Box>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </HtmlTooltip>
                                            }
                                        </>
                                    }
                                </>
                            )
                        })}
                        <TimelineItem className='timeline-item-upper-grid'>
                            <TimelineSeparator className='timeline-seprator' >
                                <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                            </TimelineSeparator>
                            <TimelineContent></TimelineContent>
                        </TimelineItem>
                        <Box className='timeline-start'></Box>
                    </Timeline>
                </Grid>
                :
                <></>}

            <Dialog
                maxWidth={isUpdate === true ? "md" : "xl"}
                fullWidth={true}
                open={openDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ paddingBottom: "50px" }}>
                    <ItemActionAddModel
                        QuickView={param.QuickView}
                        isOpen={openDialog}
                        isUpdate={isUpdate}
                        id={editId}
                        mappingId={editMappingId}
                        onClose={handleClose}
                        onSubmitClose={handleSubmitClose}
                        curQuestion={param.curQuestion}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openActionDeleteModal}
                onClose={handleCloseActionDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        {+param.curQuestion.number === 4 ? 'Are you sure you want to delete this Milestone?' : 'Are you sure you want to delete this Action?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => deleteAction()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseActionDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            {param.QuickView === true ?
                <Box className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={param.onClose}
                    >
                        <ClearIcon className="close-btn-icon" />
                    </Button>
                </Box> : <></>}
        </Grid>
    );
});
export default ItemActionAdd;