import React, { useContext } from 'react';
import logo from '../../assets/images/logo.png';
import eye from '../../assets/icons/eye.svg';
import eyeslash from '../../assets/icons/eye-slash.svg';
import { ReactComponent as SmsIcon } from '../../assets/icons/sms.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import * as Yup from "yup";
import { Alert, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { Form, Formik } from "formik";
import { Link, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { Login } from '../../services/AuthService';
import LoadingButton from "@mui/lab/LoadingButton";
import { Context } from '../../services/models/Context';
import { PreferencesContext } from '../../PreferenceContext';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const formInitialValues = {
    email: "",
    password: ""
};


export default function SignIn() {
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [loading, setLoading] = React.useState(false);
    const { updateUserContext } = useContext(PreferencesContext);
    const [showAlert, setAlertMessage] = React.useState({
        isError: false,
        message: "",
    });
  
    let navigate = useNavigate();

    const gotoMyprofile = () => {
        // navigate("/myprofile");
        // navigate("/all-hiagram");
        navigate("/dashboard");
    }; 

    const valildationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required.")
            .email("Enter a valid email."),
        password: Yup.string().required("Password is required."),
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };


    async function submitLoginForm(values: any) {
        setLoading(true);
        let email = values.email;
        let password = values.password;
        try {
            const user: any = await Login({
                email: email,
                password: password,
            });
            if (user.user_id !== undefined) {
                let curuserInfo: Context = new Context();
                curuserInfo.userId = user.user_id;
                curuserInfo.clientId = user.client_id;
                curuserInfo.clientOrgName = user.client_org;
                curuserInfo.clientAddress = user.client_address;
                curuserInfo.clientContactNumber = user.client_contact_number;
                curuserInfo.email = user.email;
                curuserInfo.firstName = user.first_name;
                curuserInfo.lastName = user.last_name;
                curuserInfo.fullName = user.first_name + " " + user.last_name;
                curuserInfo.token = user.token;
                curuserInfo.userRole = user.user_role_name;
                curuserInfo.userRoleId = user.role_id;
                updateUserContext(curuserInfo);
                gotoMyprofile();
            } else {
                setAlertMessage({
                    isError: true,
                    message: user as string,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isError: true,
                    message: error as string,
                });
            }
        }
        setTimeout(() => {
            setAlertMessage({
                isError: false,
                message: "",
            })
        }, 7000);
    }

    return (
        <>
            <Grid sx={{
                backgroundColor: "#E5E5E5",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                height: "100%",
                width: "100%",
                minWidth: '991px'
            }}>

                <Paper
                    elevation={3}
                    className="paper-singin"
                >
                    <Grid className="signin-wrapper">
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues}
                            onSubmit={(values) => {
                                submitLoginForm(values);
                            }}
                            validationSchema={valildationSchema}
                        >
                            {(props) => {
                                return (
                                    <React.Fragment>
                                        <Grid className="forgot-password">
                                            <Link
                                                className='backspace-Button'
                                                to={'/'}
                                            >
                                                <KeyboardBackspaceIcon className='icon-css' /><b>Back</b>
                                            </Link>
                                        </Grid>
                                        <Box
                                            className="box-container"
                                            sx={{ my: 2 }}
                                        >
                                            <Paper className="paper-container">
                                                <Grid
                                                    justifyContent="center"
                                                >
                                                   <a href="https://www.imprend.com">
                                                        <img loading="lazy" className={showAlert.isError ? 'img-page-alert' : 'img-page'}
                                                            src={logo} alt="logo" />
                                                    </a>
                                                </Grid>
                                                {showAlert.isError ? (
                                                    <Grid
                                                        marginBottom={1}
                                                    >
                                                        <Alert severity="error">{showAlert.message}</Alert>
                                                    </Grid>) : <></>}

                                                <Form>
                                                    <Typography sx={{ textAlign: "left" }}>Email address</Typography>
                                                    <TextField
                                                        sx={{ marginTop: 1, }}
                                                        fullWidth
                                                        placeholder='Email address'
                                                        value={props.values.email}
                                                        onChange={props.handleChange("email")}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.email && props.touched.email
                                                                ? true
                                                                : false
                                                        }
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><SmsIcon stroke={'red'} /></InputAdornment>,
                                                        }}
                                                    >
                                                    </TextField>
                                                    {props.errors.email && props.touched.email ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.email}
                                                        </Typography> : <></>}

                                                    <Typography
                                                        sx={{ textAlign: "left", marginTop: 3, }}
                                                    >Password</Typography>
                                                    <TextField
                                                        sx={{ marginTop: 1 }}
                                                        fullWidth
                                                        placeholder='Password'
                                                        value={props.values.password}
                                                        onChange={props.handleChange("password")}
                                                        onBlur={props.handleBlur}
                                                        type={values.showPassword ? "text" : "password"}
                                                        error={
                                                            props.errors.password && props.touched.password
                                                                ? true
                                                                : false
                                                        }
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><LockIcon /></InputAdornment>,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {values.showPassword ? (
                                                                            <img loading="lazy" alt='imprend' className='eye-icon' src={eyeslash} />
                                                                        ) : (
                                                                            <img loading="lazy" alt='imprend' className='eye-icon' src={eye} />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    > </TextField>
                                                    {props.errors.password && props.touched.password ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.password}
                                                        </Typography> : <></>}
                                                    <Grid item xs={12} textAlign="right">
                                                        <Typography style={{ paddingTop: 5 }} >
                                                            <Link
                                                                to={'/forgot-password'}>
                                                                Forgot Password?
                                                            </Link>
                                                        </Typography>
                                                    </Grid>
                                                    <LoadingButton
                                                        color="primary"
                                                        sx={{ marginTop: 3 }}
                                                        loading={loading}
                                                        fullWidth
                                                        loadingPosition="start"
                                                        variant="contained"
                                                        type="submit"
                                                        startIcon={<></>}
                                                    >
                                                        Sign in
                                                    </LoadingButton>
                                                    <Grid item marginTop={3}>
                                                        <Typography gutterBottom align="center">
                                                            Don't have an account?&nbsp;
                                                            <Link
                                                                to={'/sign-up'}>
                                                                <b>Sign up</b>
                                                            </Link>
                                                        </Typography>
                                                    </Grid>
                                                </Form>
                                            </Paper>
                                        </Box>

                                    </React.Fragment>
                                );
                            }}
                        </Formik>
                    </Grid>
                    <Grid className='img-wrapper' item  >
                        {/* <img className="home-img" src={home} alt="home" /> */}
                    </Grid>

                </Paper>
            </Grid>
        </>
    );
}