import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetTeamList = (params: any) =>
    GET(`${BASE_URL}/team/list?` + params, null);

export const CreateTeam = (data: any) =>
    POST(`${BASE_URL}/team/create`, data);

export const GetTeamdetail = (params: any) =>
    GET(`${BASE_URL}/team/getdetail?` + params, null);

export const UpdateTeam = (data: any) =>
    POST(`${BASE_URL}/team/update`, data);

export const GetTeamdetailById = (params: any) =>
    GET(`${BASE_URL}/team/getdetailbyuid?` + params, null);

export const TeamGetAllDetail = (params: any) =>
    GET(`${BASE_URL}/team/hiagramgetalldetail?` + params, null);

export const UpdateTeamDataOfTeamCard = (data: any) =>
    POST(`${BASE_URL}/team/updatecard`, data);

export const UpdateTeamPurpose = (data: any) =>
    POST(`${BASE_URL}/team/updatepurpose`, data);