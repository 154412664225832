import { Button, Grid, Paper, styled, Table, Skeleton, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme, IconButton, Popover, Box } from "@mui/material";
import * as React from "react";
import Training from '../../../assets/icons/training.svg'
import ClearIcon from '@mui/icons-material/Clear';
import "./ItemTrgAddModel.scss";
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
// import editIcon from '../../assets/icons/edit-2.svg'
import * as Yup from "yup";
import AddIcon from '@mui/icons-material/Add';
import { PreferencesContext } from "../../../PreferenceContext";
import { AlertType } from "../../../shared/AlertType";
import { Form, Formik } from "formik";
import { CreateTrg, CreateTrgMapping, GetAllTrgList, GetTrgDetail, UpdateTrg } from "../../../services/TrgService";
import { getSingleSelected } from "../../../services/common/commonFunction";
import { GetNeedRatingList } from "../../../services/NeedRatingService";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import _ from "lodash";
import { LoadingButton } from "@mui/lab";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#EF4646",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#FFEFEF",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formInitialValues = {
    id: 0,
    needRating: "",
    name: "",
    description: "",
    estimateDays: "",
    mappingId: 0,
    appendToType: "",
    appendUid: 0
};


export default function ItemTRGAddModel(param: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [forminitial, setFormInitial] = useState(formInitialValues);
    const [needRatingData, setNeedRatingData] = useState([]);
    const [allTrgData, setAllTrgData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [openGuidanceDialog, setOpenGuidanceDialog] = useState<HTMLElement | null>(null);
    const [guidance, setGuidance] = useState("");
    const mainTheme = useTheme();
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [isFocusedName, setFocusedName] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);

    const handlePopoverOpenForTrg = (event: React.MouseEvent<HTMLElement>) => {
        setOpenGuidanceDialog(event.currentTarget);
        setGuidance(param.curQuestion.guidance);
    };

    const handlePopoverCloseForTrg = () => {
        setOpenGuidanceDialog(null);
    };
    const open = Boolean(openGuidanceDialog);

    async function fetchNeedRating() {
        try {
            const needRatingGetData: any = await GetNeedRatingList();
            if (needRatingGetData !== undefined && needRatingGetData !== null && needRatingGetData.data !== null && needRatingGetData.data.data !== null && needRatingGetData.data.data.data !== null) {
                setNeedRatingData(needRatingGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchTrg(id: any, sId: any) {
        setDataLoading(true);
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("tmuid", sId.toString());
        params.append("tuid", id.toString());
        const trgres: any = await GetTrgDetail(params);
        if (trgres !== null && trgres.data !== null && trgres.data.data !== null) {
            let data = trgres.data.data;
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM"
            let appendUID = userContext.teamProjId;
            if (+param.curQuestion.number === 12) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 21) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 27) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            const formValues = {
                id: +data.uid,
                needRating: data.trgMapping[0].need_rating,
                name: data.name,
                estimateDays: data.estimate_days,
                description: data.description,
                mappingId: +data.trgMapping[0].uid,
                appendToType: appendType,
                appendUid: appendUID
            };
            setFormInitial(formValues);
        }
        setDataLoading(false);
    }

    async function fetchTrgList() {
        setLoading(true);
        let appendType = "";
        let appendUID = 0;
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        if (+param.curQuestion.number === 12) {
            appendType = "ROLE";
            appendUID = userContext.roleId;
        } else if (+param.curQuestion.number === 21) {
            appendType = "ACTIVITY";
            appendUID = userContext.activityId;
        } else if (+param.curQuestion.number === 27) {
            appendType = "CESS";
            appendUID = userContext.cessId;
        }
        if (param.curQuestion.hiagram !== undefined) {
            appendType = param.curQuestion.hiagram.type;
            appendUID = param.curQuestion.hiagram.typeId;
        }
        params.append("appendType", appendType.toString());
        params.append("appendUid", appendUID.toString());

        const Trgs: any = await GetAllTrgList(params);
        if (Trgs !== null && Trgs.data !== null && Trgs.data.data !== null) {
            let data = Trgs.data.data;
            setAllTrgData(data);
        }
        setLoading(false);
    }

    async function submitForm(values: any, resetForm: any) {
        setBtnLoading(true);
        try {
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            let isFromExisting = false;
            if (+param.curQuestion.number === 12) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 21) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 27) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }

            if (values.id > 0 && values.mappingId === 0) {
                let curTrg: any = allTrgData.find((x: any) => x.uid === values.id);
                if (values.name === curTrg.name && values.estimateDays === curTrg.estimate_days
                    && values.description === curTrg.description) {
                    isFromExisting = true;
                } else {
                    values.id = 0;
                }
            }

            let requestData = {
                trgUid: values.id,
                trgMappingUid: values.mappingId,
                hiagramUid: userContext.hiagramId,
                needRating: values.needRating,
                name: values.name,
                estimateDays: values.estimateDays,
                description: values.description,
                appendToType: appendType,
                appendUid: appendUID,
                isDeleted: false,
                dataManagedBy: userContext.userId
            }
            let data: any;
            if (isFromExisting) {
                data = await CreateTrgMapping(requestData);
            } else if (values.id !== null && values.id > 0) {
                data = await UpdateTrg(requestData);
            } else {
                data = await CreateTrg(requestData);
            }
            setFormInitial(formInitialValues);
            resetForm();
            param.onSubmitClose();
            updateUserContext({
                ...userContext,
                isItemCountUpdated: true
            });
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    const handleClickAdd = (id: any) => {
        let curTrg: any = allTrgData.find((x: any) => x.uid === id);
        const formInitial = {
            id: curTrg.uid,
            needRating: "",
            name: curTrg.name,
            description: curTrg.description,
            estimateDays: curTrg.estimate_days,
            mappingId: 0,
            appendToType: "",
            appendUid: 0
        };
        setFormInitial(formInitial);
    }

    const formValidation = Yup.object().shape({
        name: Yup.string().required("Name is required."),
    });
    useEffect(() => {
        if (param.isOpen) {
            fetchNeedRating();
            if (param.id > 0) {
                fetchTrg(param.id, param.mappingId);
            } else {
                fetchTrgList();
            }
        }
    }, [param.isOpen]);

    return (
        <Formik
            enableReinitialize
            initialValues={forminitial}
            validationSchema={formValidation}
            onSubmit={(values: any, { resetForm }) => {
                submitForm(values, resetForm);
            }}
        >{(props: any) => {
            return (

                <Grid container className="main-grid-model-trg-add">
                    <Grid className="border-grid-left" item xs={param.isUpdate === true ? 12 : 6} paddingRight={param.isUpdate === true ? "30px" : "50px"}>
                        <Grid container>
                            <Grid className="header-left" sx={{ display: "flex", padding: 1 }} item xs={12}>
                                <img loading="lazy" alt="imprend" className='icon-stakeholders' src={Training} />
                                {param.isUpdate === true ?
                                    <Typography className="icon-text">Edit Skills</Typography>
                                    :
                                    <Typography className="icon-text">Add new Skills</Typography>
                                }
                                {param.QuickView === false ?
                                    <Tooltip title="Guidance">
                                        <IconButton
                                            className="trg-guidance-icon"
                                            aria-label="Guidance"
                                            aria-haspopup="true"
                                            color="inherit"
                                            disableRipple
                                            onClick={handlePopoverOpenForTrg}
                                        >
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip> : <></>}
                            </Grid>
                            {/* {param.isUpdate === true ?
                                    <Grid item xs={2} className="clear-icon-edit">
                                        <Button
                                            disableRipple
                                            className="btn-cancel"
                                            onClick={() => {
                                                setFormInitial(formInitialValues);
                                                props.resetForm();
                                                param.onClose();
                                            }}
                                        >
                                            <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                        </Button>
                                    </Grid> :
                                    <></>} */}
                        </Grid>

                        <Form onSubmit={props.handleSubmit} noValidate>
                            <Grid item xs={12} className="textfield-main-grid">
                                <Box className="text-grid-textfield">
                                    <Typography className="text-typography">Skill</Typography>
                                    <Typography className="text-typography-required">(required)</Typography>
                                </Box>
                                <Box className="textfield-grid">
                                    {isDataLoading ?
                                        <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                        <Box className="description-textfield-box">
                                            <TextField
                                                id="name"
                                                fullWidth
                                                // placeholder='Contrary to popular belief'
                                                placeholder='Skill'
                                                autoComplete="off"
                                                onChange={props.handleChange.bind("name")}
                                                inputProps={{ maxLength: 90 }}
                                                onBlur={() => setFocusedName(false)}
                                                onFocus={() => setFocusedName(true)}
                                                value={props.values.name}
                                                error={
                                                    props.errors.name && props.touched.name
                                                        ? true
                                                        : false
                                                }
                                            >
                                            </TextField>
                                            {isFocusedName && <Typography className="textfield-counter">{(props.values.name !== null ? props.values.name.length : 0) + "/" + 90}</Typography>}
                                        </Box>
                                    }
                                    {props.errors.name && props.touched.name ?
                                        <Typography className="validation-message" >
                                            {props.errors.name}
                                        </Typography> : <></>}
                                </Box>
                            </Grid>

                            <Grid item xs={12} className="textfield-main-grid">
                                <Box className="text-grid-textfield">
                                    <Typography className="text-typography-decription">Description</Typography>
                                </Box>
                                <Box className="textfield-grid">
                                    {isDataLoading ?
                                        <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                        <Box className="description-textfield-box">
                                            <TextField
                                                id="description"
                                                multiline={true}
                                                rows={3}
                                                className="description-textfield"
                                                fullWidth
                                                placeholder='Description'
                                                autoComplete="off"
                                                onChange={props.handleChange.bind("description")}
                                                inputProps={{ maxLength: 500 }}
                                                onBlur={() => setFocusedDescription(false)}
                                                onFocus={() => setFocusedDescription(true)}
                                                value={props.values.description}
                                            >
                                            </TextField>
                                            {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 500}</Typography>}
                                        </Box>
                                    }
                                </Box>
                            </Grid>

                            {/* <Grid item xs={12} sx={{ display: "flex", alignItems: "center", marginTop: 3 }}>
                                    <Grid item xs={2} className="text-grid-textfield">
                                        <Typography className="text-typography">Time needed</Typography>
                                    </Grid>
                                    <Grid className="textfield-grid" item xs={10}>
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <TextField
                                                type="text"
                                                //onWheel={(e) => e.preventDefault()}
                                                id="estimateDays"
                                                fullWidth
                                                // placeholder='Contrary to popular belief'
                                                placeholder='Time needed'
                                                autoComplete="off"
                                                onChange={props.handleChange.bind("estimateDays")}
                                                value={props.values.estimateDays}
                                            >
                                            </TextField>}
                                    </Grid>
                                </Grid> */}


                            <Grid item xs={12} className="textfield-main-grid">
                                <Box className="text-grid-textfield">
                                    <Typography className="text-typography">Must have skill?</Typography>
                                </Box>
                                <Box className="textfield-grid">
                                    {isDataLoading ?
                                        <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                        <Select
                                            classNamePrefix="select"
                                            isDisabled={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            // placeholder={'Contrary to popular belief'}
                                            placeholder={''}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            value={getSingleSelected(
                                                props.values.needRating,
                                                needRatingData
                                            )}
                                            onChange={(e, value) => {
                                                props.setFieldValue(
                                                    "needRating",
                                                    e !== null ? e.value : "",
                                                    false
                                                );
                                            }}
                                            options={needRatingData}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: mainTheme.palette.primary.main,
                                                    primary25: '#FEF4F4',
                                                    primary50: '#FEF4F4',
                                                },
                                            })}
                                        />}
                                </Box>
                            </Grid>

                            <Grid item xs={12} className="button-main-grid">
                                <Grid>
                                    <Button className='button-cancel'
                                        onClick={() => {
                                            setFormInitial(formInitialValues);
                                            props.resetForm();
                                            param.onClose();
                                        }}>Cancel</Button>
                                </Grid>
                                <Grid sx={{ paddingLeft: 4 }}>
                                    <LoadingButton
                                        loading={isBtnLoading}
                                        variant="contained"
                                        type="submit"
                                        className='button-submit'>
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>

                        </Form>
                    </Grid>
                    {param.isUpdate !== true ?
                        <Grid container className="border-grid-right" spacing={1} item xs={6}>
                            <Grid item sx={{ padding: 5 }} className="table-main" xs={12}>
                                <Grid container className="header-right" sx={{ display: "flex", padding: 1 }} >
                                    <Grid item xs={10}>
                                        <Typography className="clear-text"><b>Skills already in hiagram</b></Typography>
                                    </Grid>
                                    {/* <Grid item xs={2}>
                                            <Button
                                                disableRipple
                                                className="btn-cancel"
                                                onClick={() => {
                                                    setFormInitial(formInitialValues);
                                                    props.resetForm();
                                                    param.onClose();
                                                }}
                                            >
                                                <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                            </Button>
                                        </Grid> */}
                                </Grid>
                                <Grid container paddingTop={5}>
                                    <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                        <Table className="table-css" aria-label="customized table">
                                            <TableHead>
                                                <TableRow >
                                                    <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Skill</StyledTableCell>
                                                    {/* <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Time needed</StyledTableCell> */}
                                                    <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Description</StyledTableCell>
                                                    {/* <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}>Add</StyledTableCell> */}
                                                    <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <>
                                                    {isLoading === true ? (
                                                        _.times(4, (i) => (
                                                            <TableRow key={i}>
                                                                <TableCell colSpan={4}>
                                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={25} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))) : allTrgData.length > 0 ? allTrgData.map((row: any, index: any) => {
                                                            return (<>
                                                                <StyledTableRow sx={{ fontSize: 12 }} key={index}>
                                                                    <StyledTableCell align="left" className="text-show-less">{row.name}</StyledTableCell>
                                                                    {/* <StyledTableCell align="left">{row.estimate_days}</StyledTableCell> */}
                                                                    <StyledTableCell align="left" className="text-show-less">{row.description}</StyledTableCell>
                                                                    <StyledTableCell className="btn-add">
                                                                        <Tooltip title='Add'>
                                                                            <Button
                                                                                variant='contained'
                                                                                className='edit-btn'
                                                                                onClick={() => handleClickAdd(row.uid)}
                                                                            >
                                                                                <AddIcon ></AddIcon>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            </>
                                                            );
                                                        }) : <>
                                                        <TableRow key={1}>
                                                            <TableCell colSpan={5} align='center'>
                                                                None added yet!
                                                            </TableCell>
                                                        </TableRow></>}
                                                </>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                        </Grid>
                        : <></>}

                    <Box className="close-btn-box">
                        <Button
                            disableRipple
                            className="btn-cancel"
                            onClick={() => {
                                setFormInitial(formInitialValues);
                                props.resetForm();
                                param.onClose();
                            }}
                        >
                            <ClearIcon className="close-btn-icon" />
                        </Button>
                    </Box>
                    <Popover
                        id="mouse-over-popover"
                        open={open}
                        anchorEl={openGuidanceDialog}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            style: { width: '400px', padding: '15px', borderRadius: '10px' },
                        }}
                        onClose={handlePopoverCloseForTrg}
                        disableRestoreFocus
                    >
                        <Typography className="popover-guidance-body" dangerouslySetInnerHTML={{ __html: guidance }}></Typography>
                    </Popover>
                </Grid>
            );
        }}
        </Formik>
    );
}