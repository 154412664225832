import React, { useContext, useEffect, useRef, useState } from 'react';
import "./ProcessDetail.scss";
import { Grid, Slide, Box, Skeleton, TextField, Button, Paper, Typography, Card, CircularProgress, DialogContentText, DialogActions } from "@mui/material";
import TRG from '../../../assets/icons/training.svg'
import Document from '../../../assets/icons/doc.svg'
// import Asset from '../../../assets/icons/assets.svg'
// import Hint from '../../../assets/icons/hint.svg'
// import People from '../../../assets/icons/person-icon.svg'
// import Process from '../../../assets/icons/process.svg'
import Process from '../../../assets/icons/card-process-icon.svg'
import EditIcon2 from '../../../assets/icons/edit-red-icon.svg'
import editIcon from '../../../assets/icons/edit-2.svg'
import * as Yup from "yup";
import { Form, Formik } from "formik";
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import NodeComponent from '../../AnswerType/NodeComponent/NodeComponent';
// import HiagramNodeComponent from '../../AnswerType/HiagramNodeComponent/HiagramNodeComponent';
// import RoleQuickView from '../../AnswerType/RoleQuickView/RoleQuickView';
// import ItemEventAdd from '../../AnswerType/ItemEventAdd/ItemEventAdd';
// import ItemStakeAdd from '../../AnswerType/ItemStakeAdd/ItemStakeAdd';
// import ItemAssetAdd from '../../AnswerType/ItemAssetAdd/ItemAssetAdd';
import ItemTRGAdd from '../../AnswerType/ItemTrgAdd/ItemTrgAdd';
// import ItemHintAdd from '../../AnswerType/ItemHintAdd/ItemHintAdd';
import ItemDocAdd from '../../AnswerType/ItemDocAdd/ItemDocAdd';
import { AlertType } from '../../../shared/AlertType';
import { PreferencesContext } from '../../../PreferenceContext';
import { ProcessGetAllDetail, UpdateCessDataOfProcessCard } from '../../../services/CessService';
import ProcessBuilder from '../../ProcessBuilder/ProcessBuilder';
import tickicon from "../../../assets/icons/tick.svg";
// import deleteicon from "../../../assets/icons/trash.svg";
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const formInitialValues = {
    id: 0,
    name: "",
    description: "",
    widelyUnderstood: ""
};

const activityInitial = {
    id: 0,
    name: "",
};

const roleInitial = {
    id: 0,
    name: "",
};

const teamProjInitial = {
    id: 0,
    name: "",
    isProj: 0,
    purpose: "",
};


const formValidation = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    description: Yup.string().required("Description is required."),
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let initdata: any;

export default function ProcessDetail(params: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isAuthorize, setAuthorize] = useState(false);
    // const [isFieldEditName, setFieldEditName] = useState(false);
    const [isFieldEditCommon, setFieldEditCommon] = useState(false);
    // const [isFieldEditDescription, setFieldEditDescription] = useState(false);
    // const [isVisibleEditButtonName, setVisibleEditButtonName] = useState(true);
    // const [isVisibleEditButtonDescription, setisVisibleEditButtonDescription] = useState(true);
    const [processDetailData, setProcessDetailData] = useState(formInitialValues);
    const [roleDetail, setRoleDetail] = useState(roleInitial);
    const [teamProjDetail, setTeamProjDetail] = useState(teamProjInitial);
    const [activityDetail, setActivityDetail] = useState(activityInitial);

    const [isFieldNameEdit, setFieldNameEdit] = useState(false);
    const [isFieldNameSave, setFieldNameSave] = useState(false);

    const [isFieldDescriptionEdit, setFieldDescriptionEdit] = useState(false);
    const [isFieldDescriptionSave, setFieldDescriptionSave] = useState(false);

    const [isFocusedName, setFocusedName] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [isButtonLoading, setButtonLoading] = useState(false);
    const [isDeleteButtonLoading, setDeleteButtonLoading] = useState(false);

    const [isDataUpdated, setDataUpdated] = useState(false);

    const [openDialogBoxPeople, setOpenDialogBoxPeople] = useState(false);
    const [openDialogBoxAsset, setopenDialogBoxAsset] = useState(false);
    const [openDialogBoxHint, setopenDialogBoxHint] = useState(false);
    const [openDialogBoxTrg, setopenDialogBoxTrg] = useState(false);
    const [openDialogBoxDocument, setopenDialogBoxDocument] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [processData, setProcessData] = useState(initdata);
    // const childIteamAddActionCompRef: any = useRef();
    const childIteamAddStakeCompRef: any = useRef();
    const childIteamAddDocCompRef: any = useRef();
    const childIteamAddHintCompRef: any = useRef();
    const childIteamAddTrgCompRef: any = useRef();
    const childIteamAddAssetCompRef: any = useRef();
    // const childIteamAddEventCompRef: any = useRef();
    const processBuilderRef: any = React.useRef();
    // const mainTheme = useTheme();
    let navigate = useNavigate();

    // const handleClickPeopleNode = () => {
    //     setOpenDialogBoxPeople(true);
    // };
    // const handleClickAssetNode = () => {
    //     setopenDialogBoxAsset(true);
    // };
    const handleClickDocumentNode = () => {
        setopenDialogBoxDocument(true);
    };
    // const handleClickHintNode = () => {
    //     setopenDialogBoxHint(true);
    // };
    const handleClickTrainingNode = () => {
        setopenDialogBoxTrg(true);
    };
    // const handleClosePeopleNode = (event: any, reason: any) => {
    //     if (reason && reason === "backdropClick")
    //         return;
    //     setOpenDialogBoxPeople(false);
    // };
    // const handleCloseAssetNode = (event: any, reason: any) => {
    //     if (reason && reason === "backdropClick")
    //         return;
    //     setopenDialogBoxAsset(false);
    // };
    // const handleCloseHintNode = (event: any, reason: any) => {
    //     if (reason && reason === "backdropClick")
    //         return;
    //     setopenDialogBoxHint(false);
    // };
    const handleCloseDocumentNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxDocument(false);
        fetchData();
    };
    const handleCloseTrgNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxTrg(false);
        fetchData();
    };

    const handleCloseDeleteModal = (isCheck: any) => {
        if (isCheck) {
            updateUserContext({
                ...userContext,
                isCardCountUpdate: true
            })
            params.onClose(isDataUpdated);
        }
        setOpenDeleteModal(false);
    };

    const handleCloseModal = () => {
        if (isFieldDescriptionSave || isFieldNameSave) {
            setOpenDeleteModal(true);
        } else {
            updateUserContext({
                ...userContext,
                isCardCountUpdate: true
            })
            params.onClose(isDataUpdated);
        }
    };

    const curQuestionDoc = {
        number: 26,
        uid: 36,
        hiagram: {
            type: "CESS",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionTrg = {
        number: 27,
        uid: 38,
        hiagram: {
            type: "CESS",
            typeId: params.dataId.split('-')[1]
        }
    }
    async function editName() {
        setFieldNameEdit(true);
    }

    async function cancelName(props: any) {
        props.setFieldValue(
            `name`,
            processDetailData.name,
            true
        );
        setFieldNameEdit(false);
        setFieldNameSave(false);
    }

    async function editDescription() {
        setFieldDescriptionEdit(true);
    }

    async function cancelDescription(props: any) {
        props.setFieldValue(
            `description`,
            processDetailData.description,
            true
        );
        setFieldDescriptionEdit(false);
        setFieldDescriptionSave(false)
    }

    async function addRow(values: any) {
        //setLoading(true);
        setButtonLoading(true);
        try {
            let requestData = {
                userUid: params.dataId.split('-')[1].toString(),
                hiagramUid: userContext.hiagramId,
                dataManagedBy: userContext.userId,
                name: values.name,
                description: values.description
            }
            let data: any;
            data = await UpdateCessDataOfProcessCard(requestData);

            if (data) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Process updated!",
                    alertType: AlertType.Success,
                });
                setFieldNameEdit(false);
                setFieldNameSave(false);
                setFieldDescriptionEdit(false);
                setFieldDescriptionSave(false);
                setDataUpdated(true);
                fetchData();
            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Something went wrong!",
                    alertType: AlertType.Error,
                });
            }
            //setLoading(false);
            setButtonLoading(false);
        } catch (error) {
            //setLoading(false);
            setButtonLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Something went wrong!",
                    alertType: AlertType.Error,
                });
            }
        }
        setTimeout(() => {
            updateUserContext({
                ...userContext,
                isAlert: false,
                alertMessage: "",
                alertType: '',
            });
        }, 7000);
        // resetForm(formInitialValues);

    }
    async function fetchData() {
        setLoading(true);
        try {
            let urlparams = new URLSearchParams();
            urlparams.append("huid", userContext.hiagramId.toString());
            urlparams.append("cuid", params.dataId.split('-')[1].toString());
            const data: any = await ProcessGetAllDetail(urlparams);
            if (data !== null && data.data !== null && data.data.data !== null) {
                let process: any = data.data.data
                setProcessData(process);
                if (userContext.isItemCountUpdated === true) {
                    setDataUpdated(true);
                }
                const initialValues = {
                    id: process.processdetail.uid,
                    name: process.processdetail.name,
                    description: process.processdetail.description,
                    widelyUnderstood: process.processdetail.detail_skip
                };
                const activityValues = {
                    id: process.activitydetail.uid,
                    name: process.activitydetail.name,
                };
                const roleValues = {
                    id: process.roledetail.uid,
                    name: process.roledetail.name,
                    description: process.roledetail.description,
                };
                const teamProj = {
                    id: process.teamProjDetail.uid,
                    name: process.teamProjDetail.name,
                    isProj: process.teamProjDetail.is_proj,
                    purpose: process.teamProjDetail.purpose,
                };
                setRoleDetail(roleValues);
                setTeamProjDetail(teamProj);
                setActivityDetail(activityValues);
                setProcessDetailData(initialValues);
            }
            setLoading(false);
            updateUserContext({
                ...userContext,
                isItemCountUpdated: false
            });
        }
        catch (error: any) {
            if (error) {
                setLoading(false);
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    function addProcess() {
        let lastQuestionData: any = {
            questionUid: 33,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjName: teamProjDetail.name,
            teamProjPurpose: teamProjDetail.purpose,
            teamProjId: teamProjDetail.id,
            isProj: teamProjDetail.isProj === 1 ? "true" : "false",
            roleId: roleDetail.id,
            roleName: roleDetail.name,
            activityId: activityDetail.id,
            activityName: activityDetail.name,
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: ""
        };
        updateUserContext({
            ...userContext,
            lastQuestionInfo: lastQuestionData
        });
        navigate("/question/" + userContext.hiagramId);
    }

    function editProcess() {
        let lastQuestionData: any = {
            questionUid: 45,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjName: teamProjDetail.name,
            teamProjPurpose: teamProjDetail.purpose,
            teamProjId: teamProjDetail.id,
            isProj: teamProjDetail.isProj === 1 ? "true" : "false",
            roleId: roleDetail.id,
            roleName: roleDetail.name,
            activityId: activityDetail.id,
            activityName: activityDetail.name,
            cessId: processDetailData.id,
            cessName: processDetailData.name,
            stepId: 0,
            stepName: ""
        };
        updateUserContext({
            ...userContext,
            lastQuestionInfo: lastQuestionData
        });
        navigate("/question/" + userContext.hiagramId);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
    }, [processData]);

    useEffect(() => {
        if (openDialogBoxPeople === true && childIteamAddStakeCompRef.current !== undefined && childIteamAddStakeCompRef.current !== null) {
            childIteamAddStakeCompRef.current.getData();
        }
        else if (openDialogBoxDocument === true && childIteamAddDocCompRef.current !== undefined && childIteamAddDocCompRef.current !== null) {
            childIteamAddDocCompRef.current.getData();
        }
        else if (openDialogBoxHint === true && childIteamAddHintCompRef.current !== undefined && childIteamAddHintCompRef.current !== null) {
            childIteamAddHintCompRef.current.getData();
        }
        else if (openDialogBoxTrg === true && childIteamAddTrgCompRef.current !== undefined && childIteamAddTrgCompRef.current !== null) {
            childIteamAddTrgCompRef.current.getData();
        }
        else if (openDialogBoxAsset === true && childIteamAddAssetCompRef.current !== undefined && childIteamAddAssetCompRef.current !== null) {
            childIteamAddAssetCompRef.current.getData();
        }
        else if (processBuilderRef.current !== undefined && processBuilderRef.current !== null) {
            // processBuilderRef.current.getData();
        }
    })
    return (
        <>
            <Grid container className="heading-container-grid-process">
                <Grid className="header-css" item xs={11}>
                    <Typography className='header-icon-grid'>
                        <img loading="lazy" alt='imprend' className='header-icon' src={Process} />
                    </Typography>
                    <Typography className="icon-text">Process: {processDetailData.name}</Typography>
                    {userContext.isEditAccess === true ?
                        <Button className="add-btn"
                            onClick={addProcess}
                            startIcon={<AddIcon sx={{ color: "#2C2449" }} />}
                        >
                            add Process
                        </Button>
                        : <></>}
                </Grid>
                <Grid item xs={1} className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={handleCloseModal}
                    >
                        <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                    </Button>
                </Grid>
            </Grid>
            {+userContext.tempUid === 3 ?
                <Box className='box-node-process'>
                    <Paper elevation={0} className='paper-process'>
                        <Grid container className='node-component-container'>
                            <Card
                                className='card-component'
                                onClick={handleClickDocumentNode} >
                                <NodeComponent
                                    iconSrc={Document}
                                    isLoading={isLoading}
                                    text="Documents"
                                    number={processData !== undefined ? processData.doccount : 0}
                                    border='#94b3d2'
                                />
                            </Card>

                        </Grid>
                    </Paper>
                </Box>
                :
                <Box className='box-node-process'>
                    <Paper elevation={0} className='paper-process'>
                        <Grid container className='node-component-container'>
                            <Card
                                className='card-component'
                                onClick={handleClickDocumentNode}
                            >
                                <NodeComponent
                                    iconSrc={Document}
                                    isLoading={isLoading}
                                    text="Documents"
                                    number={processData !== undefined ? processData.doccount : 0}
                                    border='#94b3d2'
                                />
                            </Card>
                            {/* <Card
                                className='card-component'
                                onClick={handleClickTrainingNode}
                            >
                                <NodeComponent
                                    iconSrc={TRG}
                                    isLoading={isLoading}
                                    text="Skills"
                                    number={processData !== undefined ? processData.trgcount : 0}
                                    border='#ba6668	'
                                />
                            </Card> */}
                        </Grid>
                    </Paper>
                </Box>}
            <Box className='box-form-container-process'>
                <Formik
                    enableReinitialize
                    initialValues={processDetailData}
                    onSubmit={(values: any) => {
                        addRow(values);
                    }}
                    validationSchema={formValidation}

                >
                    {(props) => {
                        return (
                            <>
                                <Form onSubmit={props.handleSubmit}>
                                    <Grid container className='first-container'>
                                        <Grid item xs={2} className="text-grid-textfield">
                                            <Typography className="text-typography">Name</Typography>
                                        </Grid>
                                        <Grid className="textfield-grid" item xs={8}>
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={30} />
                                                : <>
                                                    {isFieldNameEdit === true ?
                                                        <Box className="description-textfield-box">
                                                            <TextField
                                                                id="name"
                                                                fullWidth
                                                                value={props.values.name}
                                                                // placeholder='Contrary to popular belief'
                                                                inputProps={{ maxLength: 90 }}
                                                                placeholder='Name'
                                                                autoComplete="off"
                                                                // onChange={props.handleChange("name")}
                                                                onBlur={() => setFocusedName(false)}
                                                                onFocus={() => setFocusedName(true)}
                                                                onChange={(e: any) => {
                                                                    props.setFieldValue(
                                                                        // "raci",
                                                                        `name`,
                                                                        e.target.value !== null ? e.target.value : "",
                                                                        true
                                                                    );
                                                                    if (e.target.value !== null && e.target.value !== undefined) {
                                                                        if (e.target.value !== processDetailData.name) {
                                                                            setFieldNameSave(true);
                                                                        } else {
                                                                            setFieldNameSave(false);
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                            </TextField>
                                                            {isFocusedName && <Typography className="textfield-counter">{(props.values.name !== null ? props.values.name.length : 0) + "/" + 90}</Typography>}
                                                        </Box>
                                                        : <Typography className="text-typography-description">{props.values.name}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid'>
                                            {isLoading === true ? <></> :
                                                userContext.isEditAccess === true ?
                                                    <>
                                                        {isFieldNameEdit === false ?
                                                            <Button
                                                                variant='contained'
                                                                className='edit-btn'
                                                                onClick={editName}
                                                            >
                                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                                            </Button> :
                                                            isFieldNameSave === true ?
                                                                <Box className='cess-card-button'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='tick-btn'
                                                                        type='submit'
                                                                    >
                                                                        {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                    </Button>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete'
                                                                        onClick={() => cancelName(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                                </Box>
                                                                :
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete'
                                                                    onClick={() => cancelName(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                        }</>
                                                    : <></>}
                                        </Grid>
                                    </Grid>
                                    <Grid container className='second-container' sx={{ marginTop: 2 }}>
                                        <Grid item xs={2} className="text-grid-textfield-description">
                                            <Typography className="text-typography">Description</Typography>
                                        </Grid>
                                        <Grid item xs={8} className="textfield-grid" >
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={60} />
                                                : <>
                                                    {isFieldDescriptionEdit === true ?
                                                        <Box className="description-textfield-box">
                                                            <TextField
                                                                id="description"
                                                                multiline={true}
                                                                rows={6}
                                                                fullWidth
                                                                inputProps={{ maxLength: 5000 }}
                                                                // onChange={props.handleChange("description")}
                                                                onBlur={() => setFocusedDescription(false)}
                                                                onFocus={() => setFocusedDescription(true)}
                                                                onChange={(e: any) => {
                                                                    props.setFieldValue(
                                                                        // "raci",
                                                                        `description`,
                                                                        e.target.value !== null ? e.target.value : "",
                                                                        true
                                                                    );
                                                                    if (e.target.value !== null && e.target.value !== undefined) {
                                                                        if (e.target.value !== processDetailData.description) {
                                                                            setFieldDescriptionSave(true);
                                                                        } else {
                                                                            setFieldDescriptionSave(false);
                                                                        }
                                                                    }
                                                                }}
                                                                value={props.values.description}
                                                                placeholder='Description'
                                                                autoComplete="off"
                                                            >
                                                            </TextField>
                                                            {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 5000}</Typography>}
                                                        </Box>
                                                        : <Typography className="text-typography-description">{props.values.description}</Typography>}
                                                </>}

                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid'>
                                            {isLoading === true ? <></> :
                                                userContext.isEditAccess === true ?
                                                    <>
                                                        {isFieldDescriptionEdit === false ?
                                                            <Button
                                                                variant='contained'
                                                                className='edit-btn'
                                                                onClick={editDescription}
                                                            >
                                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                                            </Button> :
                                                            isFieldDescriptionSave === true ?
                                                                <Box className='cess-card-button'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='tick-btn'
                                                                        //onClick={addRow}
                                                                        type='submit'
                                                                    >
                                                                        {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                    </Button>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete'
                                                                        onClick={() => cancelDescription(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                                </Box> :
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete'
                                                                    onClick={() => cancelDescription(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                        }</>
                                                    : <></>
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* {isFieldEditCommon === true && isAuthorize === true ?
                                        <Grid container className='last-container'>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={9} className="textfield-grid">
                                                <Button
                                                    className='add-btn'
                                                    type='submit'
                                                >
                                                    Save</Button>
                                            </Grid>
                                        </Grid>
                                        : <></>} */}
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </Box>
            <Grid container className={isFieldEditCommon === true ? "box-container-add-process" : "box-container-add-process-visible"}>
                <Grid container className="process-header" >
                    <Grid className='typography-text'>
                        <Box className='typography-text-box'>
                            <Typography className="text-label">Widely understood</Typography>
                            <Typography className="text-answer">{processData !== undefined ? processData.processdetail.detail_skip === true ? "Yes" : "No" : ""}</Typography>
                        </Box>
                        {(processData !== undefined && processData.processdetail.detail_skip !== true) && userContext.isEditAccess === true ?
                            <Button className="add-btn"
                                onClick={editProcess}
                                startIcon={<EditIcon sx={{ color: "#2C2449" }} />}
                            >
                                edit Process
                            </Button>
                            : <></>}
                    </Grid>
                    {isAuthorize === true ?
                        <Card className="add-btn"
                            onClick={params.onClose}>
                            <Typography className='icon-typography'>
                                <img loading="lazy" alt='imprend' src={EditIcon2} />
                            </Typography><Typography className='label-button'>edit Process</Typography>

                        </Card>
                        : <></>}
                </Grid>
                {processData !== undefined ? processData.processdetail.detail_skip === false ?
                    <ProcessBuilder
                        ref={processBuilderRef}
                        hiagramId={userContext.hiagramId}
                        cessId={processData.processdetail.uid}
                        questionUid={45}
                        roleId={-1}
                        isEditStepAccess={false}
                        uId={processData.steps.cess_uid}
                    ></ProcessBuilder> :
                    <></> :
                    ""}
            </Grid>

            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxDocument}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDocumentNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemDocAdd onClose={handleCloseDocumentNode}
                        QuickView={true}
                        curQuestion={curQuestionDoc}
                        ref={childIteamAddDocCompRef}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxTrg}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseTrgNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemTRGAdd onClose={handleCloseTrgNode}
                        QuickView={true}
                        curQuestion={curQuestionTrg}
                        ref={childIteamAddTrgCompRef}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteModal}
                //onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure? Your current unsaved changes will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='outlined'
                        // onClick={deleteNodeEdges}
                        onClick={() => handleCloseDeleteModal(true)}
                        className="popup-btn"
                    // loading={deleteLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={() => handleCloseDeleteModal(false)}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

