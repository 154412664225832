import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetHiagramByClientId = (id: any) =>
    GET(`${BASE_URL}/hiagram/client/` + id, null);

export const GenerateHiagram = (data: any) =>
    POST(`${BASE_URL}/hiagram`, data);

export const GetHiagramTeamProj = (id: any) =>
    GET(`${BASE_URL}/hiagram/teamproj/` + id, null);

export const GetHiagramById = (id: any) =>
    GET(`${BASE_URL}/hiagram/` + id, null);

export const UpdateHiagram = (data: any) =>
    POST(`${BASE_URL}/hiagram/update`, data);

export const UpdateHiagramName = (data: any) =>
    POST(`${BASE_URL}/hiagram/updatename`, data);

export const GetHiagramData = (id: any) =>
    GET(`${BASE_URL}/hiagram/alldata/` + id, null);

export const GetSharedHiagramData = (params: any) =>
    GET(`${BASE_URL}/hiagram/sharehiagramdata?` + params, null);

export const GetHiagramList = (params: any) =>
    GET(`${BASE_URL}/hiagram/list?` + params, null);

export const GetClientHiagramCount = (params: any) =>
    GET(`${BASE_URL}/hiagram/clienthiagramcount?` + params, null);

export const GetShareHiagramList = (params: any) =>
    GET(`${BASE_URL}/sharehiagram/list?` + params, null);

export const DeleteHiagram = (id: any, data: any) =>
    DELETE(`${BASE_URL}/hiagram/` + id, data);

export const ArchiveHiagram = (id: any, data: any) =>
    DELETE(`${BASE_URL}/hiagram/archivehiagram/` + id, data);

export const DeleteShareHiagram = (id: any, data: any) =>
    DELETE(`${BASE_URL}/sharehiagram/deletesharehiagram/` + id, data);

export const ResetHiagram = (data: any) =>
    POST(`${BASE_URL}/hiagram/resethiagram`, data);

export const UpdateHiagramProgress = (data: any) =>
    POST(`${BASE_URL}/hiagram/updateprogress`, data);

export const GetArchivedHiagramByClientID = (params: any) =>
    GET(`${BASE_URL}/hiagram/deletedhiagram?` + params, null);

export const ReinstateHiagram = (id: any, data: any) =>
    GET(`${BASE_URL}/hiagram/reinstatehiagram/` + id, data);

export const GetFindUserForShare = (params: any) =>
    GET(`${BASE_URL}/users/finduser?` + params, null);

export const HiagramGetAllDetail = (params: any) =>
    GET(`${BASE_URL}/hiagram/hiagramgetalldetail?` + params, null);

export const GetHiagramListByFreeUserId = (id: any) =>
    GET(`${BASE_URL}/hiagram/freeuserhiagram/` + id, null);

export const UpdateHiagramData = (data: any) =>
    POST(`${BASE_URL}/hiagram/updatecard`, data);

export const GetHiagramDetailsForPdf = (data: any) =>
    POST(`${BASE_URL}/hiagram/getalldetailsforpdf`, data);