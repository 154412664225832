import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetRaciList = () =>
    GET(`${BASE_URL}/stake/getAllRaci?`, null);

export const CreateStake = (data: any) =>
    POST(`${BASE_URL}/stake/create`, data);

export const CreateStakeMapping = (data: any) =>
    POST(`${BASE_URL}/stake/createmapping`, data);

export const UpdateStake = (data: any) =>
    POST(`${BASE_URL}/stake/update`, data);

export const GetStakeList = (params: any) =>
    GET(`${BASE_URL}/stake/list?` + params, null);

export const GetStakeDetail = (params: any) =>
    GET(`${BASE_URL}/stake/getdetail?` + params, null);

export const DeleteStake = (id: any, data: any) =>
    DELETE(`${BASE_URL}/stake/` + id, data);

export const GetQuestionAnswer = (params: any) =>
    GET(`${BASE_URL}/stake/getquestionanswer?` + params, null);

export const CreateQusAnswer = (data: any) =>
    POST(`${BASE_URL}/stake/createqusanswer`, data);

export const GetAllStakeList = (params: any) =>
    GET(`${BASE_URL}/stake/allstakelist?` + params, null);

export const GetHiagramStakeList = (params: any) =>
    GET(`${BASE_URL}/stake/hiagramallstakelist?` + params, null);

export const GetAutoCompleteData = (params: any) =>
    GET(`${BASE_URL}/stake/getautocompletedata?` + params, null);

export const CreateStakeForTeamProjActivity = (data: any) =>
    POST(`${BASE_URL}/stake/createStakeForTeamProjActivity`, data);

export const CreateStakeMappingForTeamProjActivity = (data: any) =>
    POST(`${BASE_URL}/stake/createStakeMappingForTeamProjActivity`, data);

export const UpdateStakeForTeamProjActivity = (data: any) =>
    POST(`${BASE_URL}/stake/updateStakeForTeamProjActivity`, data);