import { Button, Grid, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Skeleton, useTheme, IconButton, Popover, Box } from "@mui/material";
import * as React from "react";
// import Action from '../../../assets/icons/action.svg'
import Action from '../../../assets/icons/action-wb.svg'
import ClearIcon from '@mui/icons-material/Clear';
import "./ItemActionAddModel.scss";
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import * as Yup from "yup";
import { PreferencesContext } from "../../../PreferenceContext";
import { AlertType } from "../../../shared/AlertType";
import { Form, Formik } from "formik";
import { CreateAction, CreateActionMapping, GetActionDetail, GetAllActionList, GetStatusList, GetTypeList, UpdateAction } from "../../../services/ActionService";
import { getNameSelected, getSingleSelected } from "../../../services/common/commonFunction";
import _ from "lodash";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import dayjs, { Dayjs } from 'dayjs';
import { LoadingButton } from "@mui/lab";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: "#F58A80",
        backgroundColor: "#f37368",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#FEF3F2",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const formInitialValues = {
    id: 0,
    type: "",
    status: "",
    name: "",
    dueDate: "",
    description: "",
    mitigation: "",
    mappingId: 0,
    appendToType: "",
    appendUid: 0
};


export default function ItemActionAddModel(param: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [typeData, setTypeData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [formInitial, setFormInitial] = useState(formInitialValues);
    const [allActionData, setAllActionData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [isMitigationStatus, setMitigationStatus] = useState(true);
    const [openGuidanceDialog, setOpenGuidanceDialog] = useState<HTMLElement | null>(null);
    const [guidance, setGuidance] = useState("");
    const mainTheme = useTheme();
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [isFocusedName, setFocusedName] = useState(false);
    const [isFocusedMitigation, setFocusedMitigation] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);

    const handlePopoverOpenForAction = (event: React.MouseEvent<HTMLElement>) => {
        setOpenGuidanceDialog(event.currentTarget);
        setGuidance(param.curQuestion.guidance);
    };

    const handlePopoverCloseForAction = () => {
        setOpenGuidanceDialog(null);
    };

    const open = Boolean(openGuidanceDialog);

    async function fetchType() {
        try {
            const typeGetData: any = await GetTypeList();
            if (typeGetData !== undefined && typeGetData !== null && typeGetData.data !== null && typeGetData.data.data !== null && typeGetData.data.data.data !== null) {
                setTypeData(typeGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchStatus() {
        try {
            const statusGetData: any = await GetStatusList();
            if (statusGetData !== undefined && statusGetData !== null && statusGetData.data !== null && statusGetData.data.data !== null && statusGetData.data.data.data !== null) {
                setStatusData(statusGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchAction(id: any, aId: any) {
        setDataLoading(true);
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("amuid", aId.toString());
        params.append("amid", id.toString());
        const actionres: any = await GetActionDetail(params);
        if (actionres !== null && actionres.data !== null && actionres.data.data !== null) {
            let data = actionres.data.data;
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            if (+param.curQuestion.number === 19) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            const formValues = {
                id: +data.uid,
                type: data.type,
                status: data.status,
                name: data.name,
                dueDate: data.due_date,
                description: data.description,
                mitigation: data.mitigation,
                mappingId: +data.actionMapping[0].uid,
                appendToType: appendType,
                appendUid: appendUID
            };
            if (data.status === 'SEVERE_DELAY' || data.status === 'DELAY' || data.status === 'PROGRESS_AT_RISK') {
                setMitigationStatus(false);
            } else {
                setMitigationStatus(true);
            }
            setFormInitial(formValues);
        }
        setDataLoading(false);
    }

    async function fetchActionList() {
        setLoading(true);
        let appendType = "";
        let appendUID = 0;
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        if (+param.curQuestion.number === 4) {
            appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            appendUID = userContext.teamProjId;
        } else if (+param.curQuestion.number === 19) {
            appendType = "ACTIVITY";
            appendUID = userContext.activityId;
        }
        if (param.curQuestion.hiagram !== undefined) {
            appendType = param.curQuestion.hiagram.type;
            appendUID = param.curQuestion.hiagram.typeId;
        }
        params.append("appendType", appendType.toString());
        params.append("appendUid", appendUID.toString());
        const actions: any = await GetAllActionList(params);
        if (actions !== null && actions.data !== null && actions.data.data !== null) {
            let data = actions.data.data;
            setAllActionData(data);
        }
        setLoading(false)
    }

    async function submitForm(values: any, resetForm: any) {
        setBtnLoading(true);
        try {
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            let isFromExisting = false;
            if (+param.curQuestion.number === 19) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }

            if (values.id > 0 && values.mappingId === 0) {
                let curAction: any = allActionData.find((x: any) => x.uid === values.id);
                if (values.type === curAction.type && values.status === curAction.status
                    && values.name === curAction.name && values.dueDate === curAction.due_date && values.description === curAction.description
                    && values.mitigation === curAction.mitigation) {
                    isFromExisting = true;
                } else {
                    values.id = 0;
                }
            }

            let requestData = {
                actionUid: values.id,
                actionMappingUid: values.mappingId,
                hiagramUid: userContext.hiagramId,
                type: values.type,
                status: values.status,
                name: values.name,
                dueDate: (values.dueDate === "" || values.dueDate === null) ? null : dayjs(values.dueDate).format('YYYY-MM-DD'),
                description: values.description,
                mitigation: values.mitigation,
                appendToType: appendType,
                appendUid: appendUID,
                isDeleted: false,
                dataManagedBy: userContext.userId
            }
            let data: any;
            if (isFromExisting) {
                data = await CreateActionMapping(requestData);
            } else if (values.id !== null && values.id > 0) {
                data = await UpdateAction(requestData);
            } else {
                data = await CreateAction(requestData);
            }
            setFormInitial(formInitialValues);
            resetForm();
            param.onSubmitClose();
            updateUserContext({
                ...userContext,
                isItemCountUpdated: true
            });
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    const handleClickAdd = (id: any) => {
        let curAction: any = allActionData.find((x: any) => x.uid === id);
        const formInitial = {
            id: curAction.uid,
            type: curAction.type,
            status: curAction.status,
            name: curAction.name,
            dueDate: curAction.due_date,
            description: curAction.description,
            mitigation: curAction.mitigation,
            mappingId: 0,
            appendToType: "",
            appendUid: 0
        };
        if (curAction.status === 'SEVERE_DELAY' || curAction.status === 'DELAY' || curAction.status === 'PROGRESS_AT_RISK') {
            setMitigationStatus(false);
        } else {
            setMitigationStatus(true);
        }
        setFormInitial(formInitial);
    }

    const formValidation = Yup.object().shape({
        type: Yup.string().required("Type is required."),
        status: Yup.string().required("Status is required."),
        name: Yup.string().required("Name is required."),
    });

    useEffect(() => {
        if (param.isOpen) {
            setFormInitial(formInitialValues);
            setMitigationStatus(true);
            fetchStatus();
            fetchType();
            if (param.id > 0) {
                fetchAction(param.id, param.mappingId);
            } else {
                fetchActionList();
            }
        }
    }, [param.isOpen]);
    return (

        <Formik
            enableReinitialize
            initialValues={formInitial}
            validationSchema={formValidation}
            onSubmit={(values: any, { resetForm }) => {
                submitForm(values, resetForm);
            }}
        >{(props: any) => {
            return (
                <>
                    <Grid container className="main-grid-model-action-add">
                        <Grid className="border-grid-left" item xs={param.isUpdate === true ? 12 : 6} paddingRight={param.isUpdate === true ? "30px" : "50px"}>
                            <Form onSubmit={props.handleSubmit} noValidate>
                                <Grid container>
                                    <Grid className="header-left" sx={{ display: "flex", padding: 1 }} item xs={12}>
                                        <img loading="lazy" alt="imprend" src={Action} />
                                        {param.isUpdate === true ?
                                            <Typography className="icon-text">{+param.curQuestion.number === 4 ? 'Edit Milestone' : 'Edit Action'} </Typography>
                                            :
                                            <Typography className="icon-text">{+param.curQuestion.number === 4 ? 'Add new Milestone' : 'Add new Action'} </Typography>
                                        }
                                        {param.QuickView === false ?
                                            <Tooltip title="Guidance">
                                                <IconButton
                                                    className="action-guidance-icon"
                                                    aria-label="Guidance"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    disableRipple
                                                    onClick={handlePopoverOpenForAction}
                                                >
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip> : <></>}
                                    </Grid>
                                    {/* {param.isUpdate === true ?
                                        <Grid item xs={2} className="clear-icon-edit">
                                            <Button
                                                disableRipple
                                                className="btn-cancel"
                                                onClick={() => {
                                                    setFormInitial(formInitialValues);
                                                    props.resetForm();
                                                    param.onClose();
                                                }}
                                            >
                                                <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                            </Button>
                                        </Grid> :
                                        <></>} */}
                                </Grid>
                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 5 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Type</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Select
                                                classNamePrefix="select"
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                styles={
                                                    props.values.type === "" &&
                                                        props.errors.type &&
                                                        props.touched.type
                                                        ? selectStyles
                                                        : selectFontFamily
                                                }
                                                // placeholder={'Contrary to popular belief'}
                                                placeholder={''}

                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                value={getSingleSelected(
                                                    props.values.type,
                                                    typeData
                                                )}
                                                onChange={(e, value) => {
                                                    props.setFieldValue(
                                                        "type",
                                                        e !== null ? e.value : "",
                                                        false
                                                    );
                                                }}
                                                options={typeData}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: mainTheme.palette.primary.main,
                                                        primary25: mainTheme.palette.primary.light,
                                                        primary50: mainTheme.palette.primary.light,
                                                    },
                                                })}
                                            />}
                                        {props.values.type === "" &&
                                            props.errors.type &&
                                            props.touched.type && (
                                                <Typography
                                                    className={
                                                        props.values.type === "" &&
                                                            props.errors.type &&
                                                            props.touched.type
                                                            ? "validation-message text-input error"
                                                            : "validation-message text-input"
                                                    }
                                                >
                                                    {props.errors.type}
                                                </Typography>
                                            )}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Name</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="name"
                                                    fullWidth
                                                    // placeholder='Belief'
                                                    placeholder='Name'
                                                    autoComplete="off"
                                                    className="item-text-fields"
                                                    onChange={props.handleChange.bind("name")}
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedName(false)}
                                                    onFocus={() => setFocusedName(true)}
                                                    value={props.values.name}
                                                    error={
                                                        props.errors.name && props.touched.name
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                </TextField>
                                                {isFocusedName && <Typography className="textfield-counter">{(props.values.name !== null ? props.values.name.length : 0) + "/" + 90}</Typography>}
                                            </Box>}
                                        {props.errors.name && props.touched.name ?
                                            <Typography className="validation-message" >
                                                {props.errors.name}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography-decription">Description</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="description"
                                                    multiline={true}
                                                    rows={3}
                                                    className="description-textfield item-text-fields"
                                                    inputProps={{ maxLength: 500 }}
                                                    onBlur={() => setFocusedDescription(false)}
                                                    onFocus={() => setFocusedDescription(true)}
                                                    fullWidth
                                                    placeholder='Description'
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("description")}
                                                    value={props.values.description}
                                                >
                                                </TextField>
                                                {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 500}</Typography>}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Due Date</Typography>
                                    </Box>
                                    <Box className="textfield-grid" >
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            // <TextField
                                            //     id="dueDate"
                                            //     type="date"
                                            //     fullWidth
                                            //     placeholder='Contrary to popular belief'
                                            //     autoComplete="off"
                                            //     onChange={props.handleChange.bind("dueDate")}
                                            //     value={props.values.dueDate}
                                            // >
                                            // </TextField>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        // id="dueDate"
                                                        // label="Basic date field"
                                                        className="datepicker item-text-fields"
                                                        sx={{ width: '100%' }}
                                                        format="DD-MM-YYYY"
                                                        value={dayjs(props.values.dueDate)}
                                                        onChange={(e) =>
                                                            props.setFieldValue(
                                                                "dueDate",
                                                                e !== null ? e.toDate() :
                                                                    false
                                                            )}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Status</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Select
                                                classNamePrefix="select"
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                styles={
                                                    props.values.status === "" &&
                                                        props.errors.status &&
                                                        props.touched.status
                                                        ? selectStyles
                                                        : selectFontFamily
                                                }
                                                // placeholder={'Contrary to popular belief'}
                                                placeholder={''}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                value={getSingleSelected(
                                                    props.values.status,
                                                    statusData
                                                )}
                                                onChange={(e, value) => {
                                                    props.setFieldValue(
                                                        "status",
                                                        e !== null ? e.value : "",
                                                        false
                                                    );
                                                    if (e.value === 'SEVERE_DELAY' || e.value === 'DELAY' || e.value === 'PROGRESS_AT_RISK') {
                                                        setMitigationStatus(false);
                                                    } else {
                                                        setMitigationStatus(true);
                                                        props.setFieldValue(
                                                            "mitigation",
                                                            "",
                                                            true
                                                        );
                                                    }
                                                }}
                                                options={statusData}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: mainTheme.palette.primary.main,
                                                        primary25: '#FEF4F4',
                                                        primary50: '#FEF4F4',
                                                    },
                                                })}
                                            />}
                                        {props.values.status === "" &&
                                            props.errors.status &&
                                            props.touched.status && (
                                                <Typography
                                                    className={
                                                        props.values.status === "" &&
                                                            props.errors.status &&
                                                            props.touched.status
                                                            ? "validation-message text-input error"
                                                            : "validation-message text-input"
                                                    }
                                                >
                                                    {props.errors.status}
                                                </Typography>
                                            )}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography-decription">Mitigation</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="mitigation"
                                                    multiline={true}
                                                    disabled={isMitigationStatus}
                                                    rows={3}
                                                    className="description-textfield item-text-fields"
                                                    fullWidth
                                                    placeholder='Mitigation'
                                                    inputProps={{ maxLength: 500 }}
                                                    onBlur={() => setFocusedMitigation(false)}
                                                    onFocus={() => setFocusedMitigation(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("mitigation")}
                                                    value={props.values.mitigation}
                                                >
                                                </TextField>
                                                {isFocusedMitigation && <Typography className="textfield-counter">{(props.values.mitigation !== null ? props.values.mitigation.length : 0) + "/" + 500}</Typography>}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="button-main-grid">
                                    <Grid>
                                        <Button className='button-cancel'
                                            onClick={() => {
                                                setFormInitial(formInitialValues);
                                                props.resetForm();
                                                param.onClose();
                                            }}>Cancel</Button>
                                    </Grid>
                                    <Grid sx={{ paddingLeft: 4 }}>
                                        {/* <Button variant="contained" className='button-submit' type="submit">Submit</Button> */}
                                        <LoadingButton
                                            loading={isBtnLoading}
                                            variant="contained"
                                            type="submit"
                                            className='button-submit'
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Form>

                        </Grid>
                        {
                            param.isUpdate !== true ?
                                <Grid container className="border-grid-right" spacing={1} item xs={6}>
                                    <Grid item sx={{ padding: 5 }} className="table-main" xs={12}>
                                        <Grid container className="header-right" sx={{ display: "flex", padding: 1 }} >
                                            <Grid item xs={10}>
                                                <Typography className="clear-text"><b>{+param.curQuestion.number === 4 ? 'Milestone already in hiagram' : 'Action already in hiagram'}  </b></Typography>
                                            </Grid>
                                            {/* <Grid item xs={2}>
                                                <Button
                                                    disableRipple
                                                    className="btn-cancel"
                                                    onClick={() => {
                                                        setFormInitial(formInitialValues);
                                                        props.resetForm();
                                                        param.onClose();
                                                    }}
                                                >
                                                    <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                        <Grid container paddingTop={5}>
                                            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                                <Table className="table-css" aria-label="customized table">
                                                    <TableHead >
                                                        <TableRow >
                                                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Type</StyledTableCell>
                                                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Name</StyledTableCell>
                                                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Due Date</StyledTableCell>
                                                            {/* <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}>Add</StyledTableCell> */}
                                                            <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}></StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <>

                                                            {isLoading === true ? (
                                                                _.times(4, (i) => (
                                                                    <TableRow key={i}>
                                                                        <TableCell colSpan={4}>
                                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={25} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))) : allActionData.length > 0 ? allActionData.map((row: any, index: any) => {
                                                                    return (<>
                                                                        <StyledTableRow sx={{ fontSize: 12 }} key={index}>
                                                                            <StyledTableCell align="left" className="text-show-less">{getNameSelected(
                                                                                row.type,
                                                                                typeData
                                                                            )}</StyledTableCell>
                                                                            <StyledTableCell align="left" className="text-show-less">{row.name}</StyledTableCell>
                                                                            <StyledTableCell align="left" className="text-show-less">{row.due_date !== null && row.due_date.split("-").reverse().join("-")}</StyledTableCell>
                                                                            <StyledTableCell className="btn-add">
                                                                                <Tooltip title='Add'>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        className='edit-btn'
                                                                                        onClick={() => handleClickAdd(row.uid)}
                                                                                    >
                                                                                        <AddIcon ></AddIcon>
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    </>
                                                                    );
                                                                }) : <>
                                                                <TableRow key={1}>
                                                                    <TableCell colSpan={5} align='center'>
                                                                        None added yet!
                                                                    </TableCell>
                                                                </TableRow></>}
                                                        </>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                : <></>
                        }
                        <Box className="close-btn-box">
                            <Button
                                disableRipple
                                className="btn-cancel"
                                onClick={() => {
                                    setFormInitial(formInitialValues);
                                    props.resetForm();
                                    param.onClose();
                                }}
                            >
                                <ClearIcon className="close-btn-icon" />
                            </Button>
                        </Box>
                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={openGuidanceDialog}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: { width: '400px', padding: '15px', borderRadius: '10px' },
                            }}
                            onClose={handlePopoverCloseForAction}
                            disableRestoreFocus
                        >
                            <Typography className="popover-guidance-body" dangerouslySetInnerHTML={{ __html: guidance }}></Typography>
                        </Popover>
                    </Grid >
                </>
            );
        }}
        </Formik >
    );
}


const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0b0b45" : "1px solid #d32f2f",
    }),
};

const selectFontFamily = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        fontFamily: 'Roboto'
    }),
    menu: (provided: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    }),
    singleValue: (provided: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    })
};