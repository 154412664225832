import React from 'react';
import { getStorage } from './services/common/storage';
import { Context } from './services/models/Context';


function getUserContext()
{
  return getStorage();
}

const defaultPreferenceState = {
  userContext: getUserContext(),
  updateUserContext: (ctx: Context) =>  {},
};

export const PreferencesContext = React.createContext(defaultPreferenceState);

