import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetTypeList = () =>
    GET(`${BASE_URL}/doc/types?`, null);

export const CreateDoc = (data: any) =>
    POST(`${BASE_URL}/doc/create`, data);

export const UpdateDoc = (data: any) =>
    POST(`${BASE_URL}/doc/update`, data);

export const GetDocList = (params: any) =>
    GET(`${BASE_URL}/doc/list?` + params, null);

export const GetDocDetail = (params: any) =>
    GET(`${BASE_URL}/doc/getdetail?` + params, null);

export const DeleteDoc = (id: any, data: any) =>
    DELETE(`${BASE_URL}/doc/` + id, data);

export const GetQuestionAnswer = (params: any) =>
    GET(`${BASE_URL}/doc/getquestionanswer?` + params, null);

export const CreateQusAnswer = (data: any) =>
    POST(`${BASE_URL}/doc/createqusanswer`, data);

export const CreateDocMapping = (data: any) =>
    POST(`${BASE_URL}/doc/createmapping`, data);

export const GetAllDocList = (params: any) =>
    GET(`${BASE_URL}/doc/alldoclist?` + params, null);

export const GetHiagramDocList = (params: any) =>
    GET(`${BASE_URL}/doc/hiagramalldoclist?` + params, null);