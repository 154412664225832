import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";

export const CreateInvite = (data: any) =>
    POST(`${BASE_URL}/invite/create`, data);

export const getInviteDetail = (params: any) =>
    GET(`${BASE_URL}/invite/getdetail?` + params, null);

export const GetInvitedUserList = (id: any, params: any) =>
    GET(`${BASE_URL}/invite/` + id + "?" + params, null);

export const DeleteInviteUser = (id: any, data: any) =>
    DELETE(`${BASE_URL}/invite/` + id, data);

export const ReinviteUser = (id: any) =>
    GET(`${BASE_URL}/invite/reinvite/` + id, null);