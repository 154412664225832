import { Grid, DialogContentText, DialogActions, Skeleton, Button, TableContainer, Paper, Table, TableHead, TableRow, Tooltip, TableCell, TableBody, tableCellClasses, styled, Typography, Box, TooltipProps, tooltipClasses } from "@mui/material";
import * as React from "react";
import editIcon from '../../../assets/icons/edit-2.svg'
import trashIcon from '../../../assets/icons/trash.svg'
import Asset from '../../../assets/icons/assets.svg'
import Autonio from '../../../assets/icons/autonio.svg'
import { AlertType } from "../../../shared/AlertType";
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import ItemAssetAddModel from './ItemAssetAddModel'
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { TransitionProps } from '@mui/material/transitions';
import { PreferencesContext } from "../../../PreferenceContext";
import _ from "lodash";
import "./ItemAssetAdd.scss";
import { LoadingButton } from "@mui/lab";
import { CreateQusAnswer, DeleteAsset, GetAssetList, GetQuestionAnswer, GetTypeList } from "../../../services/AssetService";
import { getNameSelected } from "../../../services/common/commonFunction";
import { GetNeedRatingList } from "../../../services/NeedRatingService";

interface IAssetDetail {
    id: any;
    assetMappingId: any;
    type: any;
    name: any;
    needRating: any;
    description: any;
}

const initAssetDetail: IAssetDetail = {
    id: 0,
    assetMappingId: 0,
    type: "",
    name: "",
    needRating: "",
    description: ""
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: "#091C69",
        backgroundColor: "#8e96ba",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E6E8F0",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        describeChild={true}
        {...props}
        classes={{ popper: className }}
        slotProps={{ popper: { disablePortal: true } }}
        PopperProps={{
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['right', 'left', 'top', 'bottom'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundariesElement: 'window',
                    },
                },
            ],
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        minWidth: 500,
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: '10px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    [`& .${tooltipClasses.popper}`]: {
        marginTop: theme.spacing(1),
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// export default function ItemAssetAdd() {
const ItemAssetAdd = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editMappingId, setEditMappingId] = useState(0);
    const [questionAnswerId, setQuestionAnswerId] = useState(0);
    const [assetList, setAssetList] = useState([initAssetDetail]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAssetDeleteModal, setAssetDeleteModal] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [typeData, setTypeData] = useState([]);
    const [needRatingData, setNeedRatingData] = useState([]);

    useImperativeHandle(ref, () => ({
        submitClick() {
            saveAnswer();
        },
        getData() {
            fetchType();
            fetchNeedRating()
            fetchAssetList();
            fetchAssetQuestionAnswer();
        }
    }),);


    const handleClickOpen = () => {
        setEditId(0);
        setEditMappingId(0);
        setIsUpdate(false);
        setOpenDialog(true);
    };
    const handleClickOpenForEdit = (id: any, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setIsUpdate(true);
        setOpenDialog(true);
    };

    const handleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialog(false);
    };

    const handleSubmitClose = () => {
        setOpenDialog(false);
        fetchAssetList();
        fetchAssetQuestionAnswer();
    };

    const handleOpenAssetDeleteModal = (id: number, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setAssetDeleteModal(true);
    };

    const handleCloseAssetDeleteModal = () => {
        setAssetDeleteModal(false);
    };

    async function fetchType() {
        try {
            const typeGetData: any = await GetTypeList();
            if (typeGetData !== undefined && typeGetData !== null && typeGetData.data !== null && typeGetData.data.data !== null && typeGetData.data.data.data !== null) {
                setTypeData(typeGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchNeedRating() {
        try {
            const needRatingGetData: any = await GetNeedRatingList();
            if (needRatingGetData !== undefined && needRatingGetData !== null && needRatingGetData.data !== null && needRatingGetData.data.data !== null && needRatingGetData.data.data.data !== null) {
                setNeedRatingData(needRatingGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchAssetList() {
        try {
            setLoading(true);
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            if (+param.curQuestion.number === 5) {
                let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
                let appendUID = userContext.teamProjId;
                if (param.curQuestion.hiagram !== undefined) {
                    appendType = param.curQuestion.hiagram.type;
                    appendUID = param.curQuestion.hiagram.typeId;
                }
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            } else if (+param.curQuestion.number === 17) {
                let appendType = "ACTIVITY";
                let appendUID = userContext.activityId;
                if (param.curQuestion.hiagram !== undefined) {
                    appendType = param.curQuestion.hiagram.type;
                    appendUID = param.curQuestion.hiagram.typeId;
                }
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            } else if (+param.curQuestion.number === 30) {
                let appendType = "CESS";
                let appendUID = userContext.cessId;
                if (param.curQuestion.hiagram !== undefined) {
                    appendType = param.curQuestion.hiagram.type;
                    appendUID = param.curQuestion.hiagram.typeId;
                }
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            } else if (+param.curQuestion.number === 34) {
                let appendType = "STEP";
                let appendUID = userContext.stepId;
                if (param.curQuestion.hiagram !== undefined) {
                    appendType = param.curQuestion.hiagram.type;
                    appendUID = param.curQuestion.hiagram.typeId;
                }
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            } else if (+param.curQuestion.number === 0) {
                let appendType = "NONE";
                let appendUID = 0;
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            }

            const assetres: any = await GetAssetList(params);
            if (assetres !== null && assetres.data !== null && assetres.data.data !== null) {
                let data = assetres.data.data;
                let list: any[] = [];
                data.forEach((element: any) => {
                    let item: IAssetDetail = {
                        id: element.uid,
                        type: element.app_type,
                        name: element.name,
                        needRating: element.assetMapping === undefined ? 0 : element.assetMapping[0].need_rating,
                        description: element.description,
                        assetMappingId: element.assetMapping === undefined ? 0 : element.assetMapping[0].uid,
                    }
                    list.push(item);
                });
                setAssetList(list);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchAssetQuestionAnswer() {
        try {
            setLoading(true);
            let projUID = 0;
            let teamUID = 0;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            params.append("tuid", teamUID.toString());
            params.append("puid", projUID.toString());
            params.append("ruid", userContext.roleId.toString());
            params.append("auid", userContext.activityId.toString());
            params.append("cuid", userContext.cessId.toString());
            const questionAnswer: any = await GetQuestionAnswer(params);
            if (questionAnswer !== null && questionAnswer.data !== null && questionAnswer.data.data !== null) {
                let data = questionAnswer.data.data;
                setQuestionAnswerId(data.uid);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function deleteAsset() {
        setBtnLoading(true);
        try {
            const asset: any = await DeleteAsset(editId, {
                huid: userContext.hiagramId,
                mappingUid: editMappingId,
                is_deleted: true,
                row_managed_by: userContext.userId
            });
            if (asset.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: asset.message,
                    alertType: AlertType.Success,
                });
                setAssetDeleteModal(false);
                fetchAssetList();
                fetchAssetQuestionAnswer();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function saveAnswer() {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            let projUID = 0;
            let teamUID = 0;
            let roleUID = userContext.roleId;
            let activityUID = userContext.activityId;
            let cessUID = userContext.cessId;
            let stepUID = userContext.stepId;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            try {
                let requestData = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: questionAnswerId,
                    teamUid: teamUID,
                    projUid: projUID,
                    roleUid: roleUID,
                    activityUid: activityUID,
                    cessUid: cessUID,
                    stepUid: stepUID,
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }

                let data: any;
                data = await CreateQusAnswer(requestData);
                if (data !== null && data.data === true) {
                    param.changeNextQuestion();
                }
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            param.changeNextQuestion();
        }
    }

    // useEffect(() => {
    //     fetchAssetList();
    // }, []);

    useEffect(() => {
    }, [assetList]);

    return (
        <Grid container className="asset-list-grid" >
            {param.QuickView === false ?
                <Grid item xs={12} className="asset-icon-group" ><img loading="lazy" alt="imprend" className='icon-Hint' src={Asset} />
                    <Typography className="asset-icon-name">Asset</Typography>
                </Grid>
                : <Grid item xs={12} className="heading-container-grid-asset">
                    <Grid className="header-css" item xs={12}>
                        <img loading="lazy" alt="imprend" className='header-icon' src={Asset} />
                        <Typography className="icon-text">Asset</Typography>
                    </Grid>
                    {/* <Grid item xs={1} className="clear-icon-grid">
                        <Button
                            disableRipple
                            className="btn-cancel"
                            onClick={param.onClose}
                        >
                            <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                        </Button>
                    </Grid> */}
                </Grid>}
            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Type</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Name</StyledTableCell>
                            {+param.curQuestion.number === 0 ? <></> : <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Must have asset?</StyledTableCell>}
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Description</StyledTableCell>
                            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell align="center" sx={{ width: '20%', fontSize: 13 }}>Action</StyledTableCell> : <></>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {isLoading === true ? (
                                _.times(4, (i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={6}>
                                            <Skeleton variant="rounded" width='auto' height={25} />
                                        </TableCell>
                                    </TableRow>
                                ))) : assetList.filter((x) => x.id > 0).length > 0 ? (assetList && assetList.map((row: any, index: any) => {
                                    const placement = index < 3 ? 'bottom' : 'top';
                                    return (
                                        <HtmlTooltip
                                            key={index}
                                            placement={placement}
                                            title={
                                                <React.Fragment>
                                                    <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.type, typeData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Type:</b> {getNameSelected(row.type, typeData)}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                    {+param.curQuestion.number > 0 ? <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.needRating, needRatingData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Must have asset?:</b> {getNameSelected(row.needRating, needRatingData)}</Typography> : <></>}
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                </React.Fragment>
                                            }>
                                            <StyledTableRow
                                                sx={{ fontSize: 12, height: 50, cursor: 'pointer' }}
                                                key={row.id}>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">
                                                    {getNameSelected(
                                                        row.type,
                                                        typeData
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.name}</StyledTableCell>
                                                {+param.curQuestion.number === 0 ? <></> : <StyledTableCell align="left">
                                                    {getNameSelected(
                                                        row.needRating,
                                                        needRatingData
                                                    )}
                                                </StyledTableCell>}
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.description}</StyledTableCell>
                                                {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell
                                                    sx={{ minWidth: "115px" }}
                                                >
                                                    <Grid container spacing={2} className='btn-grid'>
                                                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                            <Tooltip title='Edit'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='edit-btn'
                                                                    onClick={() => handleClickOpenForEdit(row.id, row.assetMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='edit-btn-logo' src={editIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                            <Tooltip title='Delete'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='delete-btn'
                                                                    onClick={() => handleOpenAssetDeleteModal(row.id, row.assetMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='delete-btn-logo' src={trashIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell> : <></>}
                                            </StyledTableRow>
                                        </HtmlTooltip>
                                    )
                                })) : (
                                <TableRow key={1}>
                                    <TableCell colSpan={6} align='center'>
                                        None added yet!
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    </TableBody>
                </Table>

            </TableContainer>
            {/* {+param.curQuestion.number === 0 || param.QuickView === true ?  */}
            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ?
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Button className="asset-btn"
                        onClick={handleClickOpen}
                        // startIcon={<AddIcon sx={{ color: "#091C69" }} />}
                        startIcon={<AddIcon sx={{ color: "#8e96ba" }} />}
                        endIcon={<img loading="lazy" alt="imprend" className='icon-Hint' src={Autonio} />}
                    >
                        Add Asset
                    </Button>
                </Grid> : <></>}
            <Dialog
                maxWidth={isUpdate === true ? "md" : "xl"}
                fullWidth={true}
                open={openDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ paddingBottom: "50px" }}>
                    <ItemAssetAddModel
                        QuickView={param.QuickView}
                        isOpen={openDialog}
                        isUpdate={isUpdate}
                        id={editId}
                        mappingId={editMappingId}
                        onClose={handleClose}
                        onSubmitClose={handleSubmitClose}
                        curQuestion={param.curQuestion}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openAssetDeleteModal}
                onClose={handleCloseAssetDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this Asset?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => deleteAsset()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseAssetDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {param.QuickView === true ?
                <Box className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={param.onClose}
                    >
                        <ClearIcon className="close-btn-icon" />
                    </Button>
                </Box> : <></>}
        </Grid >
    );
});

export default ItemAssetAdd;
