import { Box } from "@mui/material";
import "./ProcessBuilder.scss";
//import * as Yup from "yup";
import React, { forwardRef, useImperativeHandle, useContext, useState } from "react";
//import { PreferencesContext } from "../../PreferenceContext";
import { ReactFlowProvider, } from "reactflow";
import 'reactflow/dist/style.css';
import ProcessFlowComponent from "../ReactFlow/ProcessFlow";
import { PreferencesContext } from "../../PreferenceContext";
// import { UpdateCess } from "../../services/CessService";
import { AlertType } from "../../shared/AlertType";
import { StepList } from "../../services/StepService";
import { GetQuestionAnswer, UpdateQuestionAnswer } from "../../services/QuestionService";


const formInitialValues = {
    answerId: 0,
    description: "",
    title: "",
    stepId: 0
};

const ProcessBuilder = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [curAnswerId, setCurAnswerId] = useState(0);
    // const [forminitial, setFormInitial] = React.useState(formInitialValues);
    // const [isFocusedDescription, setFocusedDescription] = React.useState(false);
    // const [isFocusedTitle, setFocusedTitle] = React.useState(false);
    // const [setData, setSetData] = React.useState(false);
    // const [steps, setSteps] = React.useState([]);
    // const [stepEdges, setStepEdges] = React.useState([]);
    // const formValidation = Yup.object().shape({
    //     title: Yup.string().required("Step title is required."),
    // });

    //const addSteps = useNodeAdd(steps);

    useImperativeHandle(ref, () => ({
        submitClick() {
            submitForm();
        },
        getData() {
            fetchData();
        }
    }),);

    async function getAllSteps() {
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("cuid", param.cessId > 0 ? param.cessId : userContext.cessId.toString());
        let allSteps: any = [];
        //let allNodes = getNodes();  
        let listData: any = await StepList(params);
        if (listData !== null && listData.data !== null && listData.data.data !== null) {
            allSteps = listData.data.data as any[];
        }
        return allSteps;
    }

    async function fetchData() {
        try {
            let data: any;
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            params.append("cuid", userContext.cessId.toString());
            data = await GetQuestionAnswer(params);
            if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
                setCurAnswerId(+data.data.data.uid);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function submitForm() {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            let steps: any = await getAllSteps();
            if (steps.length > 0) {
                try {
                    // let requestData = {
                    //     hiagramUid: userContext.hiagramId,
                    //     questionUid: param.questionUid > 0 ? param.questionUid : param.curQuestion.uid,
                    //     questionAnswerUid: -1,
                    //     roleUid: userContext.roleId,
                    //     itemId: param.cessId > 0 ? param.cessId : userContext.cessId,
                    //     itemStatus: "NONE",
                    //     itemValue: "NONE",
                    //     isDeleted: false,
                    //     dataManagedBy: userContext.userId
                    // }
                    let data: any;
                    //data = await UpdateCess(requestData);
                    //param.changeNextQuestion();

                    let requestData: any = {
                        hiagramUid: userContext.hiagramId,
                        questionUid: param.questionUid > 0 ? param.questionUid : param.curQuestion.uid,
                        questionAnswerUid: curAnswerId,
                        appendType: "PROCESS",
                        teamUid: null,
                        projUid: null,
                        roleUid: null,
                        activityUid: null,
                        cessUid: param.cessId > 0 ? param.cessId : userContext.cessId,
                        isDeleted: false,
                        dataManagedBy: userContext.userId
                    }
                    data = await UpdateQuestionAnswer(requestData);
                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                }
                catch (error: any) {
                    if (error) {
                        updateUserContext({
                            ...userContext,
                            isAlert: true,
                            alertMessage: error.message,
                            alertType: AlertType.Error,
                        });
                    }
                }
            } else {
                param.stopLoading()
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Please add steps.",
                    alertType: AlertType.Error,
                });
            }
        } else {
            param.changeNextQuestion();
        }
    }


    //React Flow

    return (
        <>
            {/* <Formik
                enableReinitialize
                initialValues={forminitial}
                validationSchema={formValidation}
                onSubmit={(values: any, { resetForm }) => {
                    //SubmitForm(values, resetForm);
                }}
            >{(props: any) => {
                return (
                    <>
                        <Box className="process-builder">
                            <Box className="left-box">
                                <Grid container>

                                    <Grid item xs={12} style={{ marginBottom: '40px' }}>
                                        <Typography className="textfield-label">
                                            Step Title
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className="textfield-label">
                                            Description
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="right-box">
                                <Form onSubmit={props.handleSubmit} noValidate style={{ position: 'relative' }}>
                                    <Grid container>
                                        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                                            <TextField
                                                id="title"
                                                label="Step Title"
                                                inputProps={{ maxLength: param.curQuestion.max_length !== null ? param.curQuestion.max_length : 100 }}
                                                fullWidth
                                                placeholder="Contrary to popular belief"
                                                onChange={props.handleChange.bind("title")}
                                                onBlur={() => setFocusedTitle(false)}
                                                value={props.values.title}
                                                onFocus={() => setFocusedTitle(true)}
                                                error={
                                                    props.values.title === "" && props.errors.title && props.touched.title
                                                        ? true
                                                        : false
                                                }
                                            >
                                            </TextField>
                                            {isFocusedTitle && <Typography className="length-counter">{props.values.description.length + "/" + (param.curQuestion.max_length !== null ? param.curQuestion.max_length : 100)}</Typography>}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="description"
                                                label="Description"
                                                inputProps={{ maxLength: param.curQuestion.max_length !== null ? param.curQuestion.max_length : 100 }}
                                                fullWidth
                                                multiline={true}
                                                rows={3}
                                                placeholder="Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia"
                                                onChange={props.handleChange.bind("description")}
                                                onBlur={() => setFocusedDescription(false)}
                                                value={props.values.description}
                                                onFocus={() => setFocusedDescription(true)}
                                                error={
                                                    props.values.description === "" && props.errors.description && props.touched.description
                                                        ? true
                                                        : false
                                                }
                                            >
                                            </TextField>
                                            {isFocusedDescription && <Typography className="length-counter">{props.values.description.length + "/" + (param.curQuestion.max_length !== null ? param.curQuestion.max_length : 100)}</Typography>}
                                        </Grid>
                                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                                            <Button className="delete-btn"
                                                variant="outlined"
                                                // onClick={handleClickOpen}
                                                startIcon={<img src={trashIcon} />}
                                            //endIcon={<img className='icon-trg' src={Bookmark} />}
                                            >
                                                Delete step
                                            </Button>
                                            <Button className="add-btn"
                                                variant="contained"
                                            //color={"#2C2449"}
                                            // onClick={handleClickOpen}
                                            //startIcon={<AddIcon sx={{ color: "#EF4646" }} />}
                                            //endIcon={<img className='icon-trg' src={Bookmark} />}
                                            >
                                                Add step
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Button className="inner-button" variant="contained" type="submit">
                                    </Button>
                                </Form>
                            </Box>
                        </Box>
                    </>
                );
            }}
            </Formik> */}
            <Box className="flow-builder">
                <ReactFlowProvider>
                    <ProcessFlowComponent
                        isdataUpdate={true}
                        cessId={param.cessId > 0 ? param.cessId : userContext.cessId}
                        isEditStepAccess={param.isEditStepAccess}
                    />
                </ReactFlowProvider>
            </Box>

        </>
    );



});
export default ProcessBuilder;
