import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetRoleList = (params: any) =>
    GET(`${BASE_URL}/role/list?` + params, null);

export const CreateRole = (data: any) =>
    POST(`${BASE_URL}/role/create`, data);

export const GetRoledetail = (params: any) =>
    GET(`${BASE_URL}/role/getdetail?` + params, null);

export const UpdateRole = (data: any) =>
    POST(`${BASE_URL}/role/update`, data);

export const GetRoledetailById = (params: any) =>
    GET(`${BASE_URL}/role/getdetailbyuid?` + params, null);

export const RoleGetAllDetail = (params: any) =>
    GET(`${BASE_URL}/role/hiagramgetalldetail?` + params, null);

export const GetRoleDetailForEdit = (params: any) =>
    GET(`${BASE_URL}/role/getdetailforedit?` + params, null);

export const UpdateRoleDataOfRoleCard = (data: any) =>
    POST(`${BASE_URL}/role/updatecard`, data);

export const UpdateCurRoleDetail = (data: any) =>
    POST(`${BASE_URL}/role/updatecurroledetail`, data);

export const DeleteRole = (id: any, data: any) =>
    DELETE(`${BASE_URL}/role/` + id, data);