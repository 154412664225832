import React, { useRef } from 'react';
import "./ActivityDetail.scss";
import { Grid, Slide, Box, Skeleton, TextField, Button, Typography, Card, useTheme, CircularProgress, DialogContentText, DialogActions } from "@mui/material";
import TRG from '../../../assets/icons/training.svg'
import Asset from '../../../assets/icons/assets.svg'
import TimeIcon from '../../../assets/icons/time-icon.svg'
import Document from '../../../assets/icons/doc.svg'
import Event from '../../../assets/icons/events.svg'
import Hint from '../../../assets/icons/hint.svg'
import Activity from '../../../assets/icons/card-activity-icon.svg';
import People from '../../../assets/icons/person-icon.svg'
// import process from '../../../assets/icons/process.svg'
// import process from '../../../assets/icons/card-process-icon.svg';
import StepCount from '../../../assets/icons/step-bg.svg';
import eye from '../../../assets/icons/eye.svg';
import editIcon from '../../../assets/icons/edit-2.svg'
// import trashIcon from '../../../assets/icons/trash.svg'
import tickicon from "../../../assets/icons/tick.svg";
import * as Yup from "yup";
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import NodeComponent from '../../AnswerType/NodeComponent/NodeComponent';
import { getNameSelected, getSingleSelected } from '../../../services/common/commonFunction';
import ItemTRGAdd from '../../AnswerType/ItemTrgAdd/ItemTrgAdd';
import ItemStakeAdd from '../../AnswerType/ItemStakeAdd/ItemStakeAdd';
import ItemHintAdd from '../../AnswerType/ItemHintAdd/ItemHintAdd';
import ItemDocAdd from '../../AnswerType/ItemDocAdd/ItemDocAdd';
import ItemAssetAdd from '../../AnswerType/ItemAssetAdd/ItemAssetAdd';
import ItemEventAdd from '../../AnswerType/ItemEventAdd/ItemEventAdd';
import ItemActionAdd from '../../AnswerType/ItemActionAdd/ItemActionAdd';
import { PreferencesContext } from '../../../PreferenceContext';
import { AlertType } from '../../../shared/AlertType';
import { ActivityGetAllDetail, UpdateActivityDataOfActivityCard } from '../../../services/ActivityService';
import { GetRaciList } from '../../../services/StakeService';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import BreifIcon from '../../../assets/icons/card-role-icon.svg'
// import Process from '../../Update/Process/Process';
// import { DeleteCess } from '../../../services/CessService';


const formInitialValues = {
    id: 0,
    raci: "",
    name: "",
    description: "",
};

const roleInitial = {
    id: 0,
    name: "",
};

const teamProjInitial = {
    id: 0,
    name: "",
    isProj: 0,
    purpose: "",
};



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initMenuItems = [
    { id: 1, label: 'Snow', iconSrc: eye },
    { id: 2, label: 'Lannister', iconSrc: eye },
    { id: 3, label: 'Lannister', iconSrc: eye },
    { id: 4, label: 'Stark', iconSrc: eye },
];
const rowsSatatic = [
    { id: 2, number: 4, iconSrc: Document },
    { id: 3, number: 5, iconSrc: TRG },
];

// const hiagramNodeComponentConfig = {
//     questionName: "processes",
//     questionTypeCount: "3",
//     isAddButton: false,
//     upperIcon: StepCount,
//     isUpperLabelVisible: true,
//     isUpperLabelGridVisible: false,
//     isBottomLabelVisible: false,
//     isHoverable: false,
//     isPopoverHover: false,
//     isMenuButtonVisible: true,
//     menuItems: initMenuItems,
//     questionsData: rowsSatatic,
// };


let initdata: any;

export default function ActivityDetail(params: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    // const [isAuthorize, setAuthorize] = useState(false);
    // const [isFieldEditCommon, setFieldEditCommon] = useState(false);

    const [isFieldRaciEdit, setFieldRaciEdit] = useState(false);
    const [isFieldRaciSave, setFieldRaciSave] = useState(false);

    const [isFieldNameEdit, setFieldNameEdit] = useState(false);
    const [isFieldNameSave, setFieldNameSave] = useState(false);

    const [isFieldDescriptionEdit, setFieldDescriptionEdit] = useState(false);
    const [isFieldDescriptionSave, setFieldDescriptionSave] = useState(false);

    const [isFocusedName, setFocusedName] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [isButtonLoading, setButtonLoading] = useState(false);
    const [isDeleteButtonLoading, setDeleteButtonLoading] = useState(false);

    const [isDataUpdated, setDataUpdated] = useState(false);
    const [roleDetail, setRoleDetail] = useState(roleInitial);
    const [teamProjDetail, setTeamProjDetail] = useState(teamProjInitial);
    const [activityDetailData, setActivityDetailData] = useState(formInitialValues);
    const [openDialogBoxPeople, setOpenDialogBoxPeople] = useState(false);
    const [openDialogBoxAsset, setopenDialogBoxAsset] = useState(false);
    const [openDialogBoxHint, setopenDialogBoxHint] = useState(false);
    const [openDialogBoxTrg, setopenDialogBoxTrg] = useState(false);
    const [openDialogBoxEvent, setopenDialogBoxEvent] = useState(false);
    const [openDialogBoxAction, setopenDialogBoxAction] = useState(false);
    const [openDialogBoxDocument, setopenDialogBoxDocument] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isFreeVersion, setFreeVersion] = useState(false);
    const [raciData, setRaciData] = useState([]);
    const [activityData, setActivityData] = useState(initdata);
    // const [processData, setProcessData] = useState([initdata]);
    // const [isBtnLoading, setBtnLoading] = useState(false);
    // const [openCessEditDialog, setOpenCessEditDialog] = useState(false);
    // const [cessEditId, setCessEditId] = useState(0);

    // const [openCessDeleteDialog, setOpenCessDeleteDialog] = useState(false);
    // const [cessDeleteId, setCessDeleteId] = useState(0);

    const childIteamAddActionCompRef: any = useRef();
    const childIteamAddStakeCompRef: any = useRef();
    const childIteamAddDocCompRef: any = useRef();
    const childIteamAddHintCompRef: any = useRef();
    const childIteamAddTrgCompRef: any = useRef();
    const childIteamAddAssetCompRef: any = useRef();
    const childIteamAddEventCompRef: any = useRef();
    const mainTheme = useTheme();
    let navigate = useNavigate();


    const formValidation = Yup.object().shape({
        raci: Yup
            .string()
            .when("isFreeVersion", {
                is: false,
                then: Yup.string().required("RACI is required.")
            }),
        // raci: Yup.string().required("RACI is required."),
        name: Yup.string().required("Name is required."),
        description: Yup.string().required("Description is required."),

    });

    async function editName() {
        setFieldNameEdit(true);
    }

    async function cancelName(props: any) {
        props.setFieldValue(
            `name`,
            activityDetailData.name,
            true
        );
        setFieldNameEdit(false);
        setFieldNameSave(false);
    }

    async function editDescription() {
        setFieldDescriptionEdit(true);
    }

    async function cancelDescription(props: any) {
        props.setFieldValue(
            `description`,
            activityDetailData.description,
            true
        );
        setFieldDescriptionEdit(false);
        setFieldDescriptionSave(false);
    }

    const editValueRaci = () => {
        setFieldRaciEdit(true);
    }

    async function cancelValueRaci(props: any) {
        props.setFieldValue(
            `raci`,
            activityDetailData.raci,
            true
        );
        setFieldRaciEdit(false);
        setFieldRaciSave(false);
    }

    async function addRow(values: any) {
        //setLoading(true);
        setButtonLoading(true);
        try {
            let requestData = {
                // userUid: params.dataId.split('-')[1].toString(),
                userUid: activityDetailData.id.toString(),
                hiagramUid: userContext.hiagramId,
                dataManagedBy: userContext.userId,
                raci: values.raci,
                name: values.name,
                description: values.description
            }
            let data: any;
            data = await UpdateActivityDataOfActivityCard(requestData);

            if (data) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Activity updated!",
                    alertType: AlertType.Success,
                });
                setFieldRaciEdit(false);
                setFieldRaciSave(false);
                setFieldNameEdit(false);
                setFieldNameSave(false);
                setFieldDescriptionEdit(false);
                setFieldDescriptionSave(false);
                setDataUpdated(true);
                fetchData();
            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Something went wrong!",
                    alertType: AlertType.Error,
                });
            }
            //setLoading(false);
            setButtonLoading(false);
        } catch (error) {
            //setLoading(false);
            setButtonLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Something went wrong!",
                    alertType: AlertType.Error,
                });
            }
        }
        setTimeout(() => {
            updateUserContext({
                ...userContext,
                isAlert: false,
                alertMessage: "",
                alertType: '',
            });
        }, 7000);
        // resetForm(formInitialValues);

    }

    const handleClickPeopleNode = () => {
        setOpenDialogBoxPeople(true);
    };
    const handleClickAssetNode = () => {
        setopenDialogBoxAsset(true);
    };
    const handleClickDocumentNode = () => {
        setopenDialogBoxDocument(true);
    };
    const handleClickHintNode = () => {
        setopenDialogBoxHint(true);
    };
    const handleClickTrainingNode = () => {
        setopenDialogBoxTrg(true);
    };
    const handleClickEventNode = () => {
        setopenDialogBoxEvent(true);
    };
    const handleClickActionNode = () => {
        setopenDialogBoxAction(true);
    };
    const handleClosePeopleNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialogBoxPeople(false);
        fetchData();
    };
    const handleCloseAssetNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxAsset(false);
        fetchData();
    };
    const handleCloseHintNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxHint(false);
        fetchData();
    };
    const handleCloseDocumentNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxDocument(false);
        fetchData();
    };
    const handleCloseTrgNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxTrg(false);
        fetchData();
    };
    const handleCloseEventgNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxEvent(false);
        fetchData();
    };
    const handleCloseActionNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxAction(false);
        fetchData();
    };

    const handleCloseDeleteModal = (isCheck: any) => {
        if (isCheck) {
            updateUserContext({
                ...userContext,
                isCardCountUpdate: true
            })
            params.onClose(isDataUpdated);
        }
        setOpenDeleteModal(false);
    };

    // const handleCloseCessDeleteModal = () => {
    //     setOpenCessDeleteDialog(false)
    // }

    // async function deleteCess() {
    //     setBtnLoading(true);
    //     try {
    //         const cess: any = await DeleteCess(cessDeleteId, {
    //             huid: userContext.hiagramId,
    //             is_deleted: true,
    //             row_managed_by: userContext.userId
    //         });
    //         if (cess.data === true) {
    //             updateUserContext({
    //                 ...userContext,
    //                 isAlert: true,
    //                 alertMessage: cess.message,
    //                 alertType: AlertType.Success,
    //             });
    //             setBtnLoading(false);
    //             handleCloseCessDeleteModal();
    //             setDataUpdated(true);
    //             fetchData();
    //         }
    //     }
    //     catch (error: any) {
    //         if (error) {
    //             setBtnLoading(false);
    //             updateUserContext({
    //                 ...userContext,
    //                 isAlert: true,
    //                 alertMessage: error.response.message,
    //                 alertType: AlertType.Error,
    //             });
    //         }
    //     }
    //     setBtnLoading(false);
    // }

    const handleCloseModal = () => {
        if (isFieldDescriptionSave || isFieldNameSave || isFieldRaciSave) {
            setOpenDeleteModal(true);
        } else {
            updateUserContext({
                ...userContext,
                isCardCountUpdate: true
            })
            params.onClose(isDataUpdated);
        }
    };

    const curQuestionStack = {
        number: 16,
        uid: 23,
        hiagram: {
            type: "ACTIVITY",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionAsset = {
        number: 17,
        uid: 24,
        hiagram: {
            type: "ACTIVITY",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionEvent = {
        number: 20,
        uid: 28,
        hiagram: {
            type: "ACTIVITY",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionHint = {
        number: 22,
        uid: 31,
        hiagram: {
            type: "ACTIVITY",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionAction = {
        number: 19,
        uid: 27,
        hiagram: {
            type: "ACTIVITY",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionDoc = {
        number: 18,
        uid: 25,
        hiagram: {
            type: "ACTIVITY",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionTrg = {
        number: 21,
        uid: 29,
        hiagram: {
            type: "ACTIVITY",
            typeId: params.dataId.split('-')[1]
        }
    }

    async function fetchRaci() {
        try {
            const raciGetData: any = await GetRaciList();
            if (raciGetData !== undefined && raciGetData !== null && raciGetData.data !== null && raciGetData.data.data !== null && raciGetData.data.data.data !== null) {
                let RaciData = raciGetData.data.data.data
                const filterRaciOptions = RaciData.filter((raciO: any) => raciO.value === 'RESPONSIBLE' || raciO.value === 'ACCOUNTABLE');
                setRaciData(filterRaciOptions);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }
    async function fetchData() {
        setLoading(true);
        try {
            let urlparams = new URLSearchParams();
            urlparams.append("huid", userContext.hiagramId.toString());
            urlparams.append("auid", params.dataId.split('-')[1].toString());
            const data: any = await ActivityGetAllDetail(urlparams);
            if (data !== null && data.data !== null && data.data.data !== null) {
                let activity: any = data.data.data
                setActivityData(activity);
                if (userContext.isItemCountUpdated === true) {
                    setDataUpdated(true);
                }
                const initialValues = {
                    id: activity.activitydetail.uid,
                    raci: activity.activitydetail.raci,
                    name: activity.activitydetail.name,
                    description: activity.activitydetail.description,
                };
                const roleValues = {
                    id: activity.roledetail.uid,
                    name: activity.roledetail.name,
                    description: activity.roledetail.description,
                };
                const teamProj = {
                    id: activity.teamProjDetail.uid,
                    name: activity.teamProjDetail.name,
                    isProj: activity.teamProjDetail.is_proj,
                    purpose: activity.teamProjDetail.purpose,
                };
                setRoleDetail(roleValues);
                setTeamProjDetail(teamProj);
                setActivityDetailData(initialValues);

                // let processes: any = activity.processes;
                // let processCounts: any = activity.processdata;
                // let processesData: any[] = [];
                // processes.forEach((process: any) => {
                //     let processcount: any = processCounts.find((x: any) => x.processid === process.uid);
                //     const initSatatic = [
                //         { id: 1, number: processcount.doccount, iconSrc: Document },
                //         { id: 2, number: processcount.trgcount, iconSrc: TRG },
                //     ];
                //     const processConfig = {
                //         questionName: "step",
                //         questionTypeCount: processcount.stepcount,
                //         isAddButton: false,
                //         upperIcon: StepCount,
                //         isUpperLabelVisible: true,
                //         isUpperLabelGridVisible: true,
                //         isBottomLabelVisible: false,
                //         isHoverable: false,
                //         isPopoverHover: false,
                //         isMenuButtonVisible: false,
                //         menuItems: initMenuItems,
                //         questionsData: initSatatic,
                //     };
                //     let newData: any = {
                //         process: process,
                //         config: processConfig
                //     }
                //     processesData.push(newData);
                // });
                // setProcessData(processesData);
            }
            setLoading(false);
            updateUserContext({
                ...userContext,
                isItemCountUpdated: false
            });
        }
        catch (error: any) {
            if (error) {
                setLoading(false);
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    function addActivity() {
        let lastQuestionData: any = {
            questionUid: +userContext.tempUid === 3 ? 68 : 21,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjName: teamProjDetail.name,
            teamProjPurpose: teamProjDetail.purpose,
            teamProjId: teamProjDetail.id,
            isProj: teamProjDetail.isProj === 1 ? "true" : "false",
            roleId: roleDetail.id,
            roleName: roleDetail.name,
            activityId: 0,
            activityName: "",
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: ""
        };
        updateUserContext({
            ...userContext,
            lastQuestionInfo: lastQuestionData
        });
        navigate("/question/" + userContext.hiagramId);
    }

    // function addProcess() {
    //     let lastQuestionData: any = {
    //         questionUid: 33,
    //         totalTeamProj: 0,
    //         totalRole: 0,
    //         teamProjName: teamProjDetail.name,
    //         teamProjPurpose: teamProjDetail.purpose,
    //         teamProjId: teamProjDetail.id,
    //         isProj: teamProjDetail.isProj === 1 ? "true" : "false",
    //         roleId: roleDetail.id,
    //         roleName: roleDetail.name,
    //         activityId: activityDetailData.id,
    //         activityName: activityDetailData.name,
    //         cessId: 0,
    //         cessName: "",
    //         stepId: 0,
    //         stepName: ""
    //     };
    //     updateUserContext({
    //         ...userContext,
    //         lastQuestionInfo: lastQuestionData
    //     });
    //     navigate("/question/" + userContext.hiagramId);
    // }

    // const handleClickOpenForCessEdit = (uId: any) => {
    //     setCessEditId(uId);
    //     setOpenCessEditDialog(true);
    // }

    // const handleClickOpenForCessDelete = (uId: any) => {
    //     setCessDeleteId(uId);
    //     setOpenCessDeleteDialog(true);
    // }

    // const handleCloseCessEditPopup = (event: any, reason: any) => {
    //     if (reason && reason === "backdropClick")
    //         return;
    //     setOpenCessEditDialog(false);
    // };

    // const handleSubmitClose = () => {
    //     setOpenCessEditDialog(false);
    //     setDataUpdated(true);
    //     fetchData();
    // };

    useEffect(() => {
        fetchRaci();
        fetchData();

        if (+userContext.tempUid === 3) {
            setFreeVersion(true);
        }
    }, []);

    useEffect(() => {
    }, [activityData]);

    useEffect(() => {
        if (openDialogBoxAction === true && childIteamAddActionCompRef.current !== undefined && childIteamAddActionCompRef.current !== null) {
            childIteamAddActionCompRef.current.getData();
        }
        else if (openDialogBoxPeople === true && childIteamAddStakeCompRef.current !== undefined && childIteamAddStakeCompRef.current !== null) {
            childIteamAddStakeCompRef.current.getData();
        }
        else if (openDialogBoxDocument === true && childIteamAddDocCompRef.current !== undefined && childIteamAddDocCompRef.current !== null) {
            childIteamAddDocCompRef.current.getData();
        }
        else if (openDialogBoxHint === true && childIteamAddHintCompRef.current !== undefined && childIteamAddHintCompRef.current !== null) {
            childIteamAddHintCompRef.current.getData();
        }
        else if (openDialogBoxTrg === true && childIteamAddTrgCompRef.current !== undefined && childIteamAddTrgCompRef.current !== null) {
            childIteamAddTrgCompRef.current.getData();
        }
        else if (openDialogBoxAsset === true && childIteamAddAssetCompRef.current !== undefined && childIteamAddAssetCompRef.current !== null) {
            childIteamAddAssetCompRef.current.getData();
        }
        else if (openDialogBoxEvent === true && childIteamAddEventCompRef.current !== undefined && childIteamAddEventCompRef.current !== null) {
            childIteamAddEventCompRef.current.getData();
        }
    })
    return (
        <>
            <Grid container className="heading-container-grid-activity">
                <Grid className="header-css" item xs={11}>
                    <Box className='header-icon-grid'>
                        <img loading="lazy" alt='imprend' className='header-icon' src={BreifIcon} />
                    </Box>
                    <Typography className="icon-text">Role: {activityDetailData.name}</Typography>

                    {userContext.isEditAccess === true ?
                        <Box className="header-add-button">
                            <Button className="add-btn"
                                onClick={addActivity}
                                startIcon={<AddIcon sx={{ color: "#2C2449" }} />}>
                                add Role
                            </Button>
                        </Box>
                        : <></>}
                </Grid>
                <Grid item xs={1} className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={handleCloseModal}
                    >
                        <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                    </Button>
                </Grid>
            </Grid>
            <Box className='box-node-activity'>
                <Grid container className='node-component-container'>
                    <Card
                        className='card-component'
                        onClick={handleClickPeopleNode}>
                        <NodeComponent
                            iconSrc={People}
                            isLoading={isLoading}
                            text="People"
                            number={activityData !== undefined ? activityData.stakecount : 0}
                            border='#807c92'
                        />
                    </Card>
                    <Card
                        className='card-component'
                        onClick={handleClickDocumentNode}
                    >
                        <NodeComponent
                            iconSrc={Document}
                            isLoading={isLoading}
                            text="Documents"
                            number={activityData !== undefined ? activityData.doccount : 0}
                            border='#94b3d2'
                        />
                    </Card>
                    <Card
                        className='card-component'
                        onClick={handleClickHintNode}
                    >
                        <NodeComponent
                            iconSrc={Hint}
                            text="Hints & Risks"
                            isLoading={isLoading}
                            number={activityData !== undefined ? activityData.hintcount : 0}
                            border='#f59090'
                        />
                    </Card>
                    {/*  <Card
                        className='card-component'
                        onClick={handleClickTrainingNode}
                    >
                        <NodeComponent
                            iconSrc={TRG}
                            isLoading={isLoading}
                            text="Skills"
                            number={activityData !== undefined ? activityData.trgcount : 0}
                            border='#ba6668	'
                        />
                    </Card>
                     <Card
                        className='card-component'
                        onClick={handleClickAssetNode}
                    >
                        <NodeComponent
                            iconSrc={Asset}
                            isLoading={isLoading}
                            text="Assets"
                            number={activityData !== undefined ? activityData.assetcount : 0}
                            border='#091C69'
                        />
                    </Card> */}
                    {/* hp code start */}
                    {+userContext.tempUid === 3 ? <></> : <Card
                        className='card-component'
                        onClick={handleClickTrainingNode}
                    >
                        <NodeComponent
                            iconSrc={TRG}
                            isLoading={isLoading}
                            text="Skills"
                            number={activityData !== undefined ? activityData.trgcount : 0}
                            border='#ba6668	'
                        />
                    </Card>}
                    {+userContext.tempUid === 3 ? <></> :
                        <Card
                            className='card-component'
                            onClick={handleClickAssetNode}>
                            <NodeComponent
                                iconSrc={Asset}
                                isLoading={isLoading}
                                text="Assets"
                                number={activityData !== undefined ? activityData.assetcount : 0}
                                border='#091C69'
                            />
                        </Card>}
                    {/* hp code end */}
                    <Card
                        className='card-component'
                        onClick={handleClickActionNode}
                    >
                        <NodeComponent
                            iconSrc={TimeIcon}
                            isLoading={isLoading}
                            text="Actions"
                            number={activityData !== undefined ? activityData.actioncount : 0}
                            border='#F58A80'
                        />
                    </Card>
                    <Card
                        className='card-component'
                        onClick={handleClickEventNode}
                    >
                        <NodeComponent
                            iconSrc={Event}
                            isLoading={isLoading}
                            text="Events"
                            number={activityData !== undefined ? activityData.eventcount : 0}
                            border='#95cac3'
                        />
                    </Card>
                </Grid>
            </Box>
            <Box className='box-form-container-activity'>
                <Formik
                    enableReinitialize
                    initialValues={activityDetailData}
                    onSubmit={(values: any) => {
                        addRow(values);
                    }}
                    validationSchema={formValidation}>
                    {(props) => {
                        return (
                            <>
                                <Form onSubmit={props.handleSubmit}>
                                    {+userContext.tempUid === 3 ? <></> :
                                        <Grid container className='first-container'>
                                            <Grid item xs={2} className="text-grid-textfield">
                                                <Typography className="text-typography">RACI</Typography>
                                            </Grid>
                                            <Grid className="textfield-grid" item xs={8}>
                                                {isLoading === true ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={30} />
                                                    : <>
                                                        {isFieldRaciEdit === true ?
                                                            <Select
                                                                classNamePrefix="select"
                                                                isDisabled={false}
                                                                isClearable={false}
                                                                isSearchable={true}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                value={getSingleSelected(
                                                                    props.values.raci,
                                                                    raciData
                                                                )}
                                                                onChange={(e, value) => {
                                                                    props.setFieldValue(
                                                                        "raci",
                                                                        e !== null ? e.value : "",
                                                                        true
                                                                    );
                                                                    if (e.value !== null && e.value !== undefined) {
                                                                        if (e.value !== activityDetailData.raci) {
                                                                            setFieldRaciSave(true);
                                                                        } else {
                                                                            setFieldRaciSave(false);
                                                                        }
                                                                    }
                                                                }}

                                                                options={raciData}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary: mainTheme.palette.primary.main,
                                                                        primary25: '#FEF4F4',
                                                                        primary50: '#FEF4F4',
                                                                    },
                                                                })}
                                                            />
                                                            : <Typography className="text-typography-description">
                                                                {getNameSelected(
                                                                    props.values.raci,
                                                                    raciData
                                                                )}
                                                            </Typography>}
                                                    </>}
                                            </Grid>
                                            <Grid item xs={2} className='edit-btn-grid'>
                                                {isLoading === true ? <></> :
                                                    userContext.isEditAccess === true ?
                                                        <>
                                                            {isFieldRaciEdit === false ?
                                                                <Button
                                                                    variant='contained'
                                                                    className='edit-btn'
                                                                    onClick={editValueRaci}
                                                                >
                                                                    <img loading="lazy" alt='imprend' src={editIcon} />
                                                                </Button> :
                                                                isFieldRaciSave === true ?
                                                                    <Box className='activity-card-button'>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='tick-btn'
                                                                            type='submit'
                                                                        >
                                                                            {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                        </Button>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='button-delete'
                                                                            onClick={() => cancelValueRaci(props)}
                                                                        >
                                                                            {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                        </Button>
                                                                    </Box>
                                                                    :
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete'
                                                                        onClick={() => cancelValueRaci(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                            }</>
                                                        : <></>}
                                            </Grid>
                                        </Grid>}
                                    <Grid container className='first-container'>
                                        <Grid item xs={2} className="text-grid-textfield">
                                            <Typography className="text-typography">Name</Typography>
                                        </Grid>
                                        <Grid className="textfield-grid" item xs={8}>
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={60} />
                                                : <>
                                                    {isFieldNameEdit === true ?
                                                        <Box className="description-textfield-box">
                                                            <TextField
                                                                id="name"
                                                                fullWidth
                                                                value={props.values.name}
                                                                inputProps={{ maxLength: 90 }}
                                                                // placeholder='Contrary to popular belief'
                                                                placeholder='Name'
                                                                autoComplete="off"
                                                                onBlur={() => setFocusedName(false)}
                                                                onFocus={() => setFocusedName(true)}
                                                                // onChange={props.handleChange("name")}
                                                                onChange={(e: any) => {
                                                                    props.setFieldValue(
                                                                        // "raci",
                                                                        `name`,
                                                                        e.target.value !== null ? e.target.value : "",
                                                                        true
                                                                    );
                                                                    if (e.target.value !== null && e.target.value !== undefined) {
                                                                        if (e.target.value !== activityDetailData.name) {
                                                                            setFieldNameSave(true);
                                                                        } else {
                                                                            setFieldNameSave(false);
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                            </TextField>
                                                            {isFocusedName && <Typography className="textfield-counter">{(props.values.name !== null ? props.values.name.length : 0) + "/" + 90}</Typography>}
                                                        </Box>
                                                        : <Typography className="text-typography-description">{props.values.name}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid'>
                                            {isLoading === true ? <></> :
                                                userContext.isEditAccess === true ?
                                                    <>
                                                        {isFieldNameEdit === false ?
                                                            <Button
                                                                variant='contained'
                                                                className='edit-btn'
                                                                onClick={editName}
                                                            >
                                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                                            </Button> :
                                                            isFieldNameSave === true ?
                                                                <Box className='activity-card-button'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='tick-btn'
                                                                        type='submit'>
                                                                        {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                    </Button>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete'
                                                                        onClick={() => cancelName(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                                </Box>
                                                                :
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete'
                                                                    onClick={() => cancelName(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                        }</>
                                                    : <></>}
                                        </Grid>
                                    </Grid>
                                    <Grid container className='second-container' sx={{ marginTop: 2 }}>
                                        <Grid item xs={2} className="text-grid-textfield-description">
                                            <Typography className="text-typography">Description</Typography>
                                        </Grid>
                                        <Grid item xs={8} className="textfield-grid">
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={60} />
                                                : <>
                                                    {isFieldDescriptionEdit === true ?
                                                        <Box className="description-textfield-box">
                                                            <TextField
                                                                id="description"
                                                                multiline={true}
                                                                rows={6}
                                                                className="description-textfield"
                                                                fullWidth
                                                                inputProps={{ maxLength: 5000 }}
                                                                // onChange={props.handleChange("description")}
                                                                onBlur={() => setFocusedDescription(false)}
                                                                onFocus={() => setFocusedDescription(true)}
                                                                onChange={(e: any) => {
                                                                    props.setFieldValue(
                                                                        // "raci",
                                                                        `description`,
                                                                        e.target.value !== null ? e.target.value : "",
                                                                        true
                                                                    );
                                                                    if (e.target.value !== null && e.target.value !== undefined) {
                                                                        if (e.target.value !== activityDetailData.description) {
                                                                            setFieldDescriptionSave(true);
                                                                        } else {
                                                                            setFieldDescriptionSave(false);
                                                                        }
                                                                    }
                                                                }}
                                                                value={props.values.description}
                                                                placeholder='Description'
                                                                autoComplete="off"
                                                            >
                                                            </TextField>
                                                            {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 5000}</Typography>}
                                                        </Box>
                                                        : <Typography className="text-typography-description">{props.values.description}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid'>
                                            {isLoading === true ? <></> :
                                                userContext.isEditAccess === true ?
                                                    <>
                                                        {isFieldDescriptionEdit === false ?
                                                            <Button
                                                                variant='contained'
                                                                className='edit-btn'
                                                                onClick={editDescription}
                                                            >
                                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                                            </Button> :
                                                            isFieldDescriptionSave === true ?
                                                                <Box className='activity-card-button'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='tick-btn'
                                                                        //onClick={addRow}
                                                                        type='submit'
                                                                    >
                                                                        {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                    </Button>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete'
                                                                        onClick={() => cancelDescription(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                                </Box> :
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete'
                                                                    onClick={() => cancelDescription(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                        }</>
                                                    : <></>
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* {isFieldEditCommon === true && isAuthorize === true ?
                                        <Grid container className='last-container'>
                                            <Grid item xs={2} sx={{ maxWidth: "94px !important" }}></Grid>
                                            <Grid item xs={9} className="textfield-grid">
                                                <Button
                                                    className='add-btn'
                                                    type='submit'
                                                >
                                                    Save</Button>
                                            </Grid>
                                        </Grid>
                                        : <></>} */}
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </Box>
            {/* <Grid container className={isFieldEditCommon === true ? "box-container-add-process" : "box-container-add-process-visible"}>
                <Grid className="activity-bottom-process-header" item xs={11}>
                    <Typography className='header-icon-grid'>
                        <img loading="lazy" alt='imprend' className='header-icon' src={process} />
                    </Typography>
                    <Typography className="icon-text">Processes</Typography>
                    {userContext.isEditAccess === true ?
                        <Button className="add-btn"
                            onClick={addProcess}
                            startIcon={<AddIcon sx={{ color: "#2C2449" }} />}
                        >
                            add Process
                        </Button>
                        : <></>}
                </Grid>
            </Grid> */}
            {/* <Box sx={{ marginTop: 2 }}>
                <Grid container className='process-grid-container'>
                    {isLoading === true ? <>
                        <Grid item xs={3}>
                            <Skeleton animation="wave" variant="rounded" width={80} height={30} />
                            <br /><Skeleton animation="wave" variant="rounded" width={170} height={20} />
                        </Grid>
                        <Grid item xs={3}>
                            <Skeleton animation="wave" variant="rounded" width={100} height={30} />
                            <br /><Skeleton animation="wave" variant="rounded" width={300} height={20} />
                            <br /><Skeleton animation="wave" variant="rounded" width={200} height={20} />
                        </Grid>
                        <Grid item xs={isAuthorize === true ? 3 : 4}>
                            <Skeleton animation="wave" variant="rounded" width={80} height={30} />
                            <br /><Skeleton animation="wave" variant="rounded" width={170} height={20} />
                        </Grid>
                        {isAuthorize === true ?
                            <Grid item xs={1} className='icon-button-container '>
                                <Button
                                    variant='contained'
                                    className='edit-btn'
                                >
                                    <img loading="lazy" alt='imprend' src={editIcon} />
                                </Button>
                                <Button
                                    variant='contained'
                                    className='delete-btn'
                                >
                                    <img loading="lazy" alt='imprend' src={trashIcon} />
                                </Button>
                            </Grid>
                            : <></>}
                    </> :
                        <>
                            {processData !== undefined && processData.length > 0 && processData.map((row, index) => (
                                <Grid container key={index} className='process-grid-item-container'>
                                    <Grid item xs={3}>
                                        <Typography className='typography-heading'>Name</Typography>
                                        <Typography className='typography-description'>{row !== undefined ? row.process.name : ""}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={{ paddingRight: "29px" }}>
                                        <Typography className='typography-heading'> Description</Typography>
                                        <Typography className='typography-description'>{row !== undefined ? row.process.description : ""}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className='typography-heading'> Widely understood</Typography>
                                        <Typography className='typography-description'>{row !== undefined ? row.process.detail_skip === true ? "Yes" : "No" : ""}</Typography>
                                    </Grid>
                                    <Grid item xs={1} className='icon-button-container '>
                                        {userContext.isEditAccess === true ? <>
                                            <Button
                                                variant='contained'
                                                className='edit-btn'
                                                onClick={() => handleClickOpenForCessEdit(row.process.uid)}
                                            >
                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                            </Button>
                                            <Button
                                                variant='contained'
                                                className='delete-btn'
                                                onClick={() => handleClickOpenForCessDelete(row.process.uid)}
                                            >
                                                <img loading="lazy" alt='imprend' src={trashIcon} />
                                            </Button></> : <></>}
                                    </Grid>
                                </Grid>
                            ))}
                        </>}
                    {processData !== undefined && processData.length === 0 ?
                        <Box className='process-no-data'>
                            <Typography className='process-no-data-text'>None added yet!</Typography>
                        </Box> : <></>
                    }
                </Grid>
            </Box> */}

            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxAsset}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAssetNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemAssetAdd onClose={handleCloseAssetNode}
                        QuickView={true}
                        curQuestion={curQuestionAsset}
                        ref={childIteamAddAssetCompRef}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxDocument}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDocumentNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemDocAdd onClose={handleCloseDocumentNode}
                        QuickView={true}
                        curQuestion={curQuestionDoc}
                        ref={childIteamAddDocCompRef}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxHint}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseHintNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemHintAdd onClose={handleCloseHintNode}
                        QuickView={true}
                        curQuestion={curQuestionHint}
                        ref={childIteamAddHintCompRef}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxPeople}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClosePeopleNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemStakeAdd onClose={handleClosePeopleNode}
                        QuickView={true}
                        curQuestion={curQuestionStack}
                        directlyOpenFromTeamProj={false}
                        parentNodeName={activityData !== undefined && activityData !== null && +activityData.teamProjDetail.is_proj === 1 ? "PROJ" : "TEAM"}
                        parentNodeId={activityData !== undefined && activityData !== null && activityData.teamProjDetail.uid}
                        ref={childIteamAddStakeCompRef}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxTrg}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseTrgNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemTRGAdd onClose={handleCloseTrgNode}
                        QuickView={true}
                        curQuestion={curQuestionTrg}
                        ref={childIteamAddTrgCompRef}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxEvent}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseEventgNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemEventAdd onClose={handleCloseEventgNode}
                        QuickView={true}
                        curQuestion={curQuestionEvent}
                        ref={childIteamAddEventCompRef}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxAction}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseActionNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemActionAdd onClose={handleCloseActionNode}
                        QuickView={true}
                        curQuestion={curQuestionAction}
                        ref={childIteamAddActionCompRef}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteModal}
                //onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure? Your current unsaved changes will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='outlined'
                        // onClick={deleteNodeEdges}
                        onClick={() => handleCloseDeleteModal(true)}
                        className="popup-btn"
                    // loading={deleteLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={() => handleCloseDeleteModal(false)}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={openCessEditDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseCessEditPopup}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Process
                        id={cessEditId}
                        onClose={handleCloseCessEditPopup}
                        onSubmitClose={handleSubmitClose}
                    //ref={childRoleEditCompRef}
                    />
                </DialogContent>
            </Dialog> */}

            {/* <Dialog
                open={openCessDeleteDialog}
                onClose={handleCloseCessDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        WARNING! Deleting this Process will also delete any Steps beneath it
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => deleteCess()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseCessDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog> */}
        </>
    );
}

