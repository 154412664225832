import React, { useContext, useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import Logo from '../../../assets/images/logo_white.png';
import ActivityIcon from '../../../assets/icons/pdfPng/Role.png';
import TeamIcon from '../../../assets/icons/pdfPng/Team.png';
import PeopleIcon from '../../../assets/icons/pdfPng/People.png';
import HintIcon from '../../../assets/icons/pdfPng/Hint.png';
import EventIcon from '../../../assets/icons/pdfPng/Event.png';
import ProjIcon from '../../../assets/icons/pdfPng/Project.png';
import ActionIcon from '../../../assets/icons/pdfPng/Action.png';
import ProcessIcon from '../../../assets/icons/pdfPng/Process.png';
import DocIcon from '../../../assets/icons/pdfPng/Document.png';
import AssetIcon from '../../../assets/icons/pdfIcon/assets.png';
import SkillIcon from '../../../assets/icons/pdfIcon/training.png';
import dayjs from 'dayjs';
import { PreferencesContext } from '../../../PreferenceContext';

let sortedEventMappingsForTeam: any = [];
let sortedEventMappingsForProj: any = [];

const ExportToPdf = (props: any) => {
  const { hiagramAllDetailsForPdf, hiagramLink } = props
  const { userContext } = useContext(PreferencesContext);

  Font.register({ family: 'Roboto', src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf' });

  Font.registerHyphenationCallback(word => {
    if (word.length <= 1) {
      return [word];
    }

    const partSize = Math.ceil(word.length / 10);
    const parts = [];

    for (let i = 0; i < word.length; i += partSize) {
      parts.push(word.substr(i, partSize));
    }

    return parts;
  });

  const styles = StyleSheet.create({

    mainHeader: {
      backgroundColor: "#680001",
      color: "white",
      padding: 10,
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
    },

    fontFamily: {
      fontFamily: 'Roboto'
    },

    linkFont: {
      fontSize: 10
    },
    pageContainer: {
      paddingHorizontal: 15,
      paddingBottom: 10
    },

    summaryHeading: {
      color: "#680001",
      fontSize: 14,
    },

    summaryDes: {
      color: "#606060",
      fontSize: 12,
    },

    paddingVertical: {
      paddingVertical: 8,
      fontFamily: 'Roboto'
    },

    danger: {
      fontSize: 13,
      color: '#680001',
      backgroundColor: '#FFEFEF',
    },

    paddingHorizontal: {
      paddingHorizontal: 10,
      fontFamily: 'Roboto'
    },

    marginVertical: {
      marginVertical: 15,
    },

    borderElement: {
      paddingHorizontal: 10,
      border: "1px solid #A0A0A0",
      borderRadius: 10,
      paddingBottom: 10,
      marginBottom: 10
    },

    innerSection: {
      flexDirection: "row",
    },

    section: {
      paddingTop: 15,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 15,
    },

    valueText: {
      color: "#606060",
      paddingTop: 2,
      fontSize: 12,
      fontFamily: 'Roboto',
    },

    fontsize: {
      fontSize: 14,
    },

    IconAlignment: {
      width: "25px",
      height: "25px",
      display: 'flex',
      alignSelf: 'center',
      fontFamily: 'Roboto',
    },

    TableIconAlignment: {
      width: "20px",
      height: "20px",
      display: 'flex',
      alignSelf: 'center',
      fontFamily: 'Roboto',
    },

    alignItemCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    border: {
      border: "1px solid #A0A0A0",
      borderRadius: 10,
      antialiasing: false
    },

    header: {
      borderTop: 'none',
      textAlign: 'left',

    },

    bold: {
      fontWeight: 'bold'
    },

    table: {
      width: '100%',

    },

    peopleTableBg: {
      color: 'white',
      backgroundColor: '#2C2449',
    },

    docTableBg: {
      color: 'white',
      backgroundColor: '#4D81B4',
    },

    stepTableBg: {
      color: 'white',
      backgroundColor: '#2C2449',
    },

    hintTableBg: {
      color: 'white',
      backgroundColor: '#8c3939',
    },

    assetTableBg: {
      color: 'white',
      backgroundColor: '#8e96ba',
    },

    actionTableBg: {
      color: 'white',
      backgroundColor: '#f37368',
    },

    eventTableBg: {
      color: 'white',
      backgroundColor: '#4FA79B',
    },

    skillTableBg: {
      color: 'white',
      backgroundColor: '#EF4646',
    },

    row: {
      flexDirection: 'row',
      justifyContent: "space-between",
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 5,
      textAlign: 'left',
    },

    colls: {
      width: 600,
      fontSize: 10,
      paddingHorizontal: 1,
      marginHorizontal: 1,
      textAlign: 'left',
      fontFamily: 'Roboto',
      // backgroundColor: 'red',
      // maxWidth: '600px',
      // flexWrap: 'wrap',
    },

    displayNumcolls: {
      width: 300,
      fontSize: 10,
      paddingHorizontal: 1,
      marginHorizontal: 1,
      textAlign: 'left',
      fontFamily: 'Roboto',
    },

    textLetterChanges: {
      textTransform: 'lowercase'
    },

    sizing: {
      minWidth: '35%',
      // maxWidth: '35%'
    },

    flowDiagram: {
      marginTop: 20,
      alignItems: 'center',
    }
  });

  // Utility function to break long words
  function breakLongWord(word: string, maxLength: number) {
    if (word.length > maxLength) {
      let brokenWord = '';
      let approxCharWidth = 8;
      const maxCharsPerLine = Math.floor(maxLength / approxCharWidth);
      let result = '';
      let currentLineLength = 0;

      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        currentLineLength++;

        if (char === ' ' || currentLineLength > maxCharsPerLine) {
          if (currentLineLength > maxCharsPerLine) {
            result += '\n';
            currentLineLength = 1;
          }
        }

        result += char;
      }

      return brokenWord;
    } else {
      return word;
    }
  }

  function formatText(word: any) {
    if (word.length <= 1) {
      return [word];
    }

    const partSize = Math.ceil(word.length / 10);
    const parts = [];

    for (let i = 0; i < word.length; i += partSize) {
      parts.push(word.substr(i, partSize));
    }
    return parts;
  }

  const goToSteps = (stepsData: any, edgesData: any) => {
    const sortedStepsArray: any = [];
    let dependencyMap = new Map();

    edgesData.forEach((item: any) => {
      const [from, to] = item.name.split('=>');
      if (!dependencyMap.has(from)) {
        dependencyMap.set(from, []);
      }
      dependencyMap.get(from).push(to);
    });

    // Create a visited set to track already added items
    let visited = new Set();

    // Create a result array
    let result: any = [];

    // Helper function to perform a DFS and sort
    function dfs(uid: any) {
      if (visited.has(uid)) return;
      visited.add(uid);

      if (dependencyMap.has(uid)) {
        dependencyMap.get(uid).forEach((dep: any) => dfs(dep));
      }

      const item = stepsData.find((a: any) => a.uid === uid);
      if (item) result.push(item);
    }

    // Start the DFS from each item in arrayA
    stepsData.forEach((item: any) => {
      dfs(item.uid);
    });
    sortedStepsArray.push(result);
    return result.reverse();
  };

  // useEffect(() => {
  //   console.log('hiagramAllDetailsForPdf', hiagramAllDetailsForPdf)
  // })


  return (
    <Document>
      <Page size={'A4'}>
        {/* -------------------------------------------- */}
        <View style={styles.mainHeader} fixed>
          <View>
            <Text style={[styles.fontFamily, { maxWidth: '450px' }]}>{hiagramAllDetailsForPdf !== undefined && hiagramAllDetailsForPdf !== null && hiagramAllDetailsForPdf.hiagram !== undefined && hiagramAllDetailsForPdf.hiagram !== null && hiagramAllDetailsForPdf.hiagram.hiagram_name}</Text>
          </View>
          <View>
            <Image style={{ width: 90, height: 30 }} src={Logo} cache={false} />
          </View>
        </View>
        {/* ---------------------TEAM----------------------- */}
        <View style={[styles.pageContainer]}>
          <View>
            <View style={[styles.summaryHeading, styles.paddingVertical]}>
              <Text style={styles.fontFamily}>Summary </Text>
            </View>
            <View style={[styles.summaryDes, styles.paddingVertical]}>
              <Text style={styles.fontFamily}>{hiagramAllDetailsForPdf !== undefined && hiagramAllDetailsForPdf !== null && hiagramAllDetailsForPdf.hiagram !== undefined && hiagramAllDetailsForPdf.hiagram !== null && hiagramAllDetailsForPdf.hiagram.free_text !== undefined && hiagramAllDetailsForPdf.hiagram.free_text !== null ? hiagramAllDetailsForPdf.hiagram.free_text : ''}</Text>
            </View>
          </View>

          <View style={[styles.danger, styles.paddingHorizontal, styles.marginVertical, styles.paddingVertical]}>
            <Text style={[styles.fontFamily, styles.linkFont]}><Text style={[styles.fontFamily, styles.linkFont]}>Note:</Text> this handover document is best explored through the interactive diagram {' '}
              <Link style={styles.linkFont} src={hiagramLink !== undefined && hiagramLink !== null && hiagramLink}>{hiagramLink !== undefined && hiagramLink !== null && hiagramLink}</Link>
            </Text>
          </View>

          {hiagramAllDetailsForPdf !== undefined && hiagramAllDetailsForPdf !== null && hiagramAllDetailsForPdf.team[0] !== undefined && hiagramAllDetailsForPdf.team[0] !== null && hiagramAllDetailsForPdf.team[0] && <View>
            {hiagramAllDetailsForPdf !== undefined && hiagramAllDetailsForPdf !== null && hiagramAllDetailsForPdf.team !== undefined && hiagramAllDetailsForPdf.team !== null && hiagramAllDetailsForPdf.team.map((item: any, index: number) => (
              <View key={index} style={styles.borderElement}>
                <View>
                  <View style={{
                    paddingHorizontal: 10,
                  }}>
                    {/* Team heading set start */}
                    <View style={[styles.innerSection, { paddingTop: 10 }]}>
                      <Image style={[styles.IconAlignment]} src={TeamIcon} cache={false} />
                      <Text style={[styles.fontFamily, styles.paddingVertical, styles.fontsize, { marginLeft: 5, fontFamily: 'Roboto', maxWidth: '470px' }]}
                      >{item.name}</Text>
                    </View>
                    {/* Team heading set end */}

                    {/* Team data set start */}

                    {+userContext.tempUid === 2 &&
                      <View style={[styles.innerSection, styles.paddingVertical]}>
                        <Text style={[styles.fontFamily, { fontSize: 12 }]}>Purpose  </Text>
                        <Text style={[styles.valueText]}>{item.purpose}</Text>
                      </View>}
                    <View style={[styles.innerSection, styles.paddingVertical]}>
                      <Text style={[styles.fontFamily, { fontSize: 12 }]}>Description  </Text>
                      <Text style={[styles.valueText, { width: '100%', paddingHorizontal: 10 }]} >{item.description}</Text>
                    </View>
                    {/* Team data set end */}
                  </View>

                  {/* Team section data end */}


                  {/* rollup temp close start ----------------- IMP ---------*/}
                  {/* {item?.stakeMapping[0]?.stake && <View style={[styles.table]}>
              <View style={[styles.innerSection, styles.paddingVertical,]}>
                <Image style={[styles.TableIconAlignment, styles.fontFamily]} src={PeopleIcon} cache={false} />
                <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>People</Text>
              </View>
              <View style={[styles.row, styles.bold, styles.header, styles.peopleTableBg]}>
                <Text style={styles.colls}>First Name</Text>
                <Text style={styles.colls}>Last Name</Text>
                <Text style={styles.colls}>Organization</Text>
                <Text style={[styles.colls]}>Department</Text>
              </View>

              {item?.stakeMapping?.map((row: any, i: number) => (
                <View key={i} >
                  {row?.stake?.map((stakes: any, stakeI: number) => (
                    <View key={stakeI} style={[styles.row, styles.bold, styles.header, { backgroundColor: stakeI % 2 === 1 ? '#2C2449' : "white", }]} wrap={false}>
                      <Text style={[styles.colls]}>{stakes.first_name}</Text>
                      <Text style={styles.colls}>{stakes.last_name}</Text>
                      <Text style={styles.colls}>{stakes.org_name}</Text>
                      <Text style={[styles.colls]}>{stakes.department_name}</Text>
                    </View>
                  ))}
                </View>
              ))}

              {item.role.map((roles: any, i: number) => (
                <View key={i}>
                  {roles.activity.map((activities: any, i: number) => {
                    <View key={i}>{
                      activities?.stakeMapping?.map((row: any, i: number) => (
                        <View key={i}>{
                          row?.stake?.map((stakes: any, i: number) => (
                            <View key={i}>
                              <Text style={styles.colls}>{stakes.first_name}</Text>
                              <Text style={styles.colls}>{stakes.last_name}</Text>
                              <Text style={styles.colls}>{stakes.org_name}</Text>
                              <Text style={styles.colls}>{stakes.department_name}</Text>
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  })}
                </View>
              ))}


            </View>
            } */}
                  {/* rollup temp close end ----------------- imp ------------------*/}


                  {item?.hintMapping[0]?.hint && <View style={[styles.paddingVertical, styles.table]}>
                    <View style={[styles.innerSection, styles.fontsize]}>
                      <Image style={[styles.TableIconAlignment]} src={HintIcon} cache={false} />
                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Hint</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.header, styles.hintTableBg]}>
                      <Text style={styles.colls}>Type</Text>
                      <Text style={styles.colls}>Title</Text>
                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                    </View>
                    {item?.hintMapping?.map((row: any, i: number) => (
                      <View key={i} >
                        {row?.hint?.map((hints: any, i: number) => (
                          // <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                          //   <Text style={styles.colls}>{breakLongWord(hints.name, 600)}</Text>
                          //   <Text style={styles.colls}>{breakLongWord(hints.title, 600)}</Text>
                          //   <Text style={[styles.colls, styles.sizing]}>{breakLongWord(hints.description, 600)}</Text>
                          // </View>

                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                            <Text style={styles.colls}>{hints.name}</Text>
                            <Text style={styles.colls}>{hints.title}</Text>
                            <Text style={[styles.colls, styles.sizing]}>{hints.description}</Text>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>}
                  {item?.docMapping[0]?.doc && <View style={[styles.paddingVertical, styles.table]}>
                    <View style={[styles.innerSection, styles.fontsize]}>
                      <Image style={[styles.TableIconAlignment]} src={DocIcon} cache={false} />
                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Documents</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.header, styles.docTableBg]}>
                      <Text style={styles.colls}>Name</Text>
                      <Text style={[styles.colls, styles.sizing]}>Location</Text>
                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                    </View>
                    {item?.docMapping?.map((row: any, i: number) => (
                      <View key={i} >
                        {row?.doc?.map((docs: any, i: number) => (
                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                            {/* <Text style={[styles.colls]}>
                              {breakLongWord(docs.name, 600)}
                            </Text>

                            {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                              <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.location, 600)}</Text>}

                            <Text style={[styles.colls, styles.sizing,]}>{breakLongWord(docs.description, 600)}</Text> */}

                            <Text style={[styles.colls]}>{docs.name}</Text>
                            {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                              <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{docs.location}</Text>}
                            <Text style={[styles.colls, styles.sizing,]}>{docs.description}</Text>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>}


                  {/* team-role start */}
                  {item?.role[0]?.activity[0] && <View>
                    {item.role.map((roles: any, i: number) => (
                      <View key={i}>
                        {roles.activity.map((activities: any, i: number) => {
                          {
                            activities?.eventMapping[0]?.event && activities?.eventMapping?.map((eventsMapping: any, i: number) => {
                              sortedEventMappingsForTeam = activities.eventMapping.sort((a: any, b: any) => {
                                const eventA: any = a.event[0];
                                const eventB: any = b.event[0];
                                const dateTimeA: any = new Date(`${eventA.first_date}T${new Date(eventA.time).toISOString().split('T')[1]}`)
                                const dateTimeB: any = new Date(`${eventB.first_date}T${new Date(eventB.time).toISOString().split('T')[1]}`)
                                return dateTimeA - dateTimeB;
                              })
                            })
                          }
                          return (
                            <View key={i} style={{
                              paddingHorizontal: 10,
                              paddingTop: 10,
                              border: "1px solid #A0A0A0",
                              borderRadius: 10,
                              paddingBottom: 10,
                              marginTop: 10
                            }}>
                              <View key={index} style={{
                                paddingHorizontal: 10,
                              }}>
                                <View style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end', alignItems: 'center', marginBottom: 7 }}>
                                  <Image style={{ width: 15, height: 15 }} src={TeamIcon} cache={false} />
                                  <Text style={{
                                    fontSize: 9, marginLeft: 5
                                    , fontFamily: 'Roboto'
                                  }}>{item.name}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center', marginTop: 7 }}>
                                  <Image style={[styles.IconAlignment]} src={ActivityIcon} cache={false} />
                                  <Text style={[styles.fontFamily, styles.paddingVertical, styles.fontsize, { marginLeft: 5, maxWidth: '470px', }]}>{activities.name}</Text>
                                </View>
                              </View>
                              <View style={[styles.innerSection, styles.paddingVertical]}>
                                <Text style={[styles.fontFamily, { fontSize: 12 }]}>Description  </Text>
                                <Text style={[styles.valueText, { width: '100%', paddingHorizontal: 10 }]} >{activities.description}</Text>
                              </View>
                              <View style={{ paddingBottom: 20 }}>
                                {activities?.stakeMapping[0]?.stake && <View style={[styles.paddingVertical, styles.table]}>
                                  <View style={[styles.innerSection, styles.fontsize]}>
                                    <Image style={[styles.TableIconAlignment]} src={PeopleIcon} cache={false} />
                                    <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>People</Text>
                                  </View>
                                  <View style={[styles.row, styles.bold, styles.header, styles.peopleTableBg]}>
                                    <Text style={styles.colls}>First Name</Text>
                                    <Text style={styles.colls}>Last Name</Text>
                                    <Text style={styles.colls}>Organization</Text>
                                    <Text style={styles.colls}>Department</Text>
                                  </View>
                                  {activities?.stakeMapping?.slice().sort((a: any, b: any) => {
                                    // Compare org_name
                                    const orgNameA = a.stake[0];
                                    const orgNameB = b.stake[0];
                                    const firstNameA = a.stake[0];
                                    const firstNameB = b.stake[0];
                                    const lastNameA = a.stake[0];
                                    const lastNameB = b.stake[0];

                                    if (orgNameA.org_name < orgNameB.org_name) return -1;
                                    if (orgNameA.org_name > orgNameB.org_name) return 1;

                                    if (firstNameA.first_name < firstNameB.first_name) return -1;
                                    if (firstNameA.first_name > firstNameB.first_name) return 1;

                                    if (lastNameA.last_name < lastNameB.last_name) return -1;
                                    if (lastNameA.last_name > lastNameB.last_name) return 1;

                                    return 0;
                                  }).map((row: any, i: number) => (
                                    <View key={i} >
                                      {row?.stake?.map((stakes: any, i: number) => (
                                        <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                          <Text style={styles.colls}>{stakes.first_name}</Text>
                                          <Text style={styles.colls}>{stakes.last_name}</Text>
                                          <Text style={styles.colls}>{stakes.org_name}</Text>
                                          <Text style={styles.colls}>{stakes.department_name}</Text>
                                        </View>
                                      ))}
                                    </View>
                                  ))}
                                </View>}
                                {activities?.hintMapping[0]?.hint && <View style={[styles.paddingVertical, styles.table]}>
                                  <View style={[styles.innerSection, styles.fontsize]}>
                                    <Image style={[styles.TableIconAlignment]} src={HintIcon} cache={false} />
                                    <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Hint</Text>
                                  </View>
                                  <View style={[styles.row, styles.bold, styles.header, styles.hintTableBg]}>
                                    <Text style={styles.colls}>Type</Text>
                                    <Text style={styles.colls}>Title</Text>
                                    <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                  </View>
                                  {activities?.hintMapping?.map((row: any, i: number) => (
                                    <View key={i} >
                                      {row?.hint?.map((hints: any, i: number) => (
                                        <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                          {/* <Text style={styles.colls}>{breakLongWord(hints.name, 600)}</Text>
                                          <Text style={styles.colls}>{breakLongWord(hints.title, 600)}</Text>
                                          <Text style={[styles.colls, styles.sizing]}>{breakLongWord(hints.description, 600)}</Text> */}
                                          <Text style={styles.colls}>{hints.name}</Text>
                                          <Text style={styles.colls}>{hints.title}</Text>
                                          <Text style={[styles.colls, styles.sizing]}>{hints.description}</Text>
                                        </View>
                                      ))}
                                    </View>
                                  ))}
                                </View>}
                                {activities?.docMapping[0]?.doc && <View style={[styles.paddingVertical, styles.table]}>
                                  <View style={[styles.innerSection, styles.fontsize]}>
                                    <Image style={[styles.TableIconAlignment]} src={DocIcon} cache={false} />
                                    <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]} >Documents</Text>
                                  </View>
                                  <View style={[styles.row, styles.bold, styles.header, styles.docTableBg]}>
                                    <Text style={styles.colls}>Name</Text>
                                    <Text style={styles.colls}>Location</Text>
                                    <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                  </View>
                                  {activities?.docMapping?.map((row: any, i: number) => (
                                    <View key={i} >
                                      {row?.doc?.map((docs: any, i: number) => (
                                        <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                          {/* <Text style={styles.colls}>{breakLongWord(docs.name, 600)}</Text>
                                          {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                                            <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.location, 600)}</Text>}
                                          <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.description, 600)}</Text> */}

                                          <Text style={styles.colls}>{docs.name}</Text>
                                          {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                                            <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{docs.location}</Text>}
                                          <Text style={[styles.colls, styles.sizing]}>{docs.description}</Text>

                                        </View>
                                      ))}
                                    </View>
                                  ))}
                                </View>}
                                {activities?.actionMapping[0]?.action && <View style={[styles.paddingVertical, styles.table]}>
                                  <View style={[styles.innerSection, styles.fontsize]}>
                                    <Image style={[styles.TableIconAlignment]} src={ActionIcon} cache={false} />
                                    <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]} >Action</Text>
                                  </View>
                                  <View style={[styles.row, styles.bold, styles.header, styles.actionTableBg]}>
                                    <Text style={styles.colls}>Name</Text>
                                    <Text style={styles.colls}>Type</Text>
                                    <Text style={styles.colls}>Status</Text>
                                    <Text style={styles.colls}>Due Date</Text>
                                    <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                  </View>
                                  {/* {activities?.actionMapping?.map((actionsMapping: any, i: number) => (
                                    <View key={i} >
                                      {actionsMapping?.action?.map((actions: any, i: number) => (
                                        <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                          <Text style={styles.colls}>{breakLongWord(actions.name, 600)}</Text>
                                          <Text style={styles.colls}>{actions.type.charAt(0).toUpperCase() + actions.type.slice(1).toLowerCase()}</Text>
                                          <Text style={styles.colls}>{actions.status.split('_').map((part: any, index: number) => index === 0 ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : part.toLowerCase()).join(' ')}</Text>
                                          <Text style={styles.colls}>{actions.due_date}</Text>
                                          <Text style={[styles.colls, styles.sizing]}>{breakLongWord(actions.description, 600)}</Text>
                                        </View>
                                      ))}
                                    </View>
                                  ))} */}

                                  {activities?.actionMapping[0]?.action && activities?.actionMapping
                                    ?.slice()
                                    .sort((a: any, b: any) => {
                                      const eventA = a.action[0];
                                      const eventB = b.action[0];
                                      const dateTimeA: any = new Date(eventA.due_date);
                                      const dateTimeB: any = new Date(eventB.due_date);
                                      return dateTimeA - dateTimeB;
                                    })
                                    .reduce((acc: any[], item: any) => {
                                      return acc.concat(item.action);
                                    }, [])
                                    .map((actions: any, i: number) => (
                                      <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                        <Text style={styles.colls}>{actions.name}</Text>
                                        <Text style={styles.colls}>{actions.type.charAt(0).toUpperCase() + actions.type.slice(1).toLowerCase()}</Text>
                                        <Text style={styles.colls}>
                                          {actions.status
                                            .split('_')
                                            .map((part: any, index: number) =>
                                              index === 0 ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : part.toLowerCase()
                                            )
                                            .join(' ')}
                                        </Text>
                                        <Text style={styles.colls}>{actions.due_date}</Text>
                                        <Text style={[styles.colls, styles.sizing]}>{actions.description}</Text>
                                      </View>
                                    ))
                                  }
                                </View>}


                                {activities?.eventMapping[0]?.event && <View style={[styles.paddingVertical, styles.table]}>
                                  <View style={[styles.innerSection, styles.fontsize, { paddingBottom: 5 }]}>
                                    <Image style={[styles.TableIconAlignment]} src={EventIcon} cache={false} />
                                    <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Event</Text>
                                  </View>
                                  <View style={[styles.row, styles.bold, styles.header, styles.eventTableBg]}>
                                    <Text style={[styles.colls, { minWidth: '15%' }]}>Name</Text>
                                    <Text style={styles.colls}>Location</Text>
                                    <Text style={styles.colls}>First date</Text>
                                    <Text style={styles.colls}>Time</Text>
                                    <Text style={styles.colls}>Duration</Text>
                                    <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                  </View>
                                  {sortedEventMappingsForTeam.map((row: any, i: number) => (
                                    <View key={i}>
                                      {row?.event?.map((events: any, i: any) => (
                                        <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                          {/* <Text style={[styles.colls, { minWidth: '15%' }]}>{breakLongWord(events.name, 600)}</Text>
                                          <Text style={styles.colls}>{breakLongWord(events.location, 600)}</Text>
                                          <Text style={styles.colls}>{events.first_date}</Text>
                                          <Text style={styles.colls}>{dayjs(events.time).format("hh:mm a")}</Text>
                                          <Text style={styles.colls}>{breakLongWord(events.duration, 600)}</Text>
                                          <Text style={[styles.colls, styles.sizing, { marginRight: '15px' }]}>{breakLongWord(events.description, 600)}</Text> */}

                                          <Text style={[styles.colls, { minWidth: '15%' }]}>{events.name}</Text>
                                          <Text style={styles.colls}>{events.location}</Text>
                                          <Text style={styles.colls}>{events.first_date}</Text>
                                          <Text style={styles.colls}>{dayjs(events.time).utc().format('h:mm a')}</Text>
                                          <Text style={styles.colls}>{events.duration}</Text>
                                          <Text style={[styles.colls, styles.sizing, { marginRight: '15px' }]}>{events.description}</Text>
                                        </View>
                                      ))}
                                    </View>
                                  ))}
                                </View>}
                                {activities?.assetMapping[0]?.asset && <View style={[styles.paddingVertical, styles.table]}>
                                  <View style={[styles.innerSection, styles.fontsize]}>
                                    <Image style={[styles.TableIconAlignment]} src={AssetIcon} cache={false} />
                                    <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Asset</Text>
                                  </View>
                                  <View style={[styles.row, styles.bold, styles.header, styles.assetTableBg]}>
                                    <Text style={styles.colls}>Name</Text>
                                    <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                  </View>
                                  {activities?.assetMapping?.map((assetsMapping: any, i: number) => (
                                    <View key={i} >
                                      {assetsMapping?.asset?.map((assets: any, i: number) => (
                                        <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                          {/* <Text style={styles.colls}>{breakLongWord(assets.name, 600)}</Text>
                                          <Text style={[styles.colls, styles.sizing]}>{breakLongWord(assets.description, 600)}</Text> */}

                                          <Text style={styles.colls}>{assets.name}</Text>
                                          <Text style={[styles.colls, styles.sizing]}>{assets.description}</Text>
                                        </View>
                                      ))}
                                    </View>
                                  ))}
                                </View>}
                                {activities?.trgMapping[0]?.trg && <View style={[styles.paddingVertical, styles.table]}>
                                  <View style={[styles.innerSection, styles.fontsize]}>
                                    <Image style={[styles.TableIconAlignment]} src={SkillIcon} cache={false} />
                                    <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Skill</Text>
                                  </View>
                                  <View style={[styles.row, styles.bold, styles.header, styles.skillTableBg]}>
                                    <Text style={styles.colls}>Name</Text>
                                    <Text style={styles.colls}>Estimate Days</Text>
                                    <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                  </View>
                                  {activities?.trgMapping?.map((trgsMapping: any, i: number) => (
                                    <View key={i} >
                                      {trgsMapping?.trg?.map((trgs: any, i: number) => (
                                        <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                          {/* <Text style={styles.colls}>{breakLongWord(trgs.name, 600)}</Text>
                                          <Text style={styles.colls}>{trgs.estimate_days}</Text>
                                          <Text style={[styles.colls, styles.sizing]}>{breakLongWord(trgs.description, 600)}</Text> */}

                                          <Text style={styles.colls}>{trgs.name}</Text>
                                          <Text style={styles.colls}>{trgs.estimate_days}</Text>
                                          <Text style={[styles.colls, styles.sizing]}>{trgs.description}</Text>
                                        </View>
                                      ))}
                                    </View>
                                  ))}
                                </View>}
                              </View>
                              {
                                activities.cess[0] &&
                                <View >
                                  {activities.cess?.map((process: any, i: number) => {
                                    const sortedSteps = process.step.slice().sort((a: any, b: any) => a.step_order - b.step_order);
                                    return (
                                      <View key={i} style={[styles.border, styles.paddingHorizontal, styles.paddingVertical, { marginTop: 10 }]}>
                                        <View style={{ fontSize: 11 }}>
                                          {/* team-process heading start */}
                                          <View style={[
                                            styles.innerSection,
                                            {
                                              // justifyContent: 'flex-end',
                                              // alignItems: 'center',
                                              // marginBottom: 10,
                                              // width: '100%',
                                              // alignSelf: "flex-end",
                                              // backgroundColor:'blue'
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                              alignItems: 'center',
                                              marginBottom: '15',
                                              width: '100%',
                                            }]}>
                                            <View style={[styles.innerSection, styles.alignItemCenter, { maxWidth: '45%' }]}>
                                              <Image style={{ width: 15, height: 15, alignSelf: 'baseline' }} src={TeamIcon} cache={false} />
                                              <Text style={{
                                                fontSize: 9, marginLeft: 5
                                                , fontFamily: 'Roboto'
                                              }}>{item.name}</Text>
                                            </View>
                                            <View style={[styles.innerSection, styles.alignItemCenter, { width: '3%', marginHorizontal: '2px' }]}>
                                              <Text>{'>'}</Text>
                                            </View>
                                            <View style={[styles.innerSection, styles.alignItemCenter, { maxWidth: '45%', alignSelf: 'baseline' }]}>
                                              <Image style={{ width: 15, height: 15 }} src={ActivityIcon} cache={false} />
                                              <Text style={{
                                                fontSize: 9, marginLeft: 5
                                                , fontFamily: 'Roboto'
                                              }}>{activities.name}
                                              </Text>
                                            </View>
                                          </View>

                                          <View style={[styles.innerSection, { alignItems: 'center' }]} >
                                            <Image style={[styles.IconAlignment]} src={ProcessIcon} cache={false} />
                                            <Text style={[styles.fontFamily, styles.paddingVertical, styles.fontsize, { marginLeft: 5, maxWidth: '470px' }]}>{process.name}</Text>
                                          </View>


                                        </View>

                                        {/* team-process heading end */}
                                        {/* <View style={[styles.innerSection, styles.paddingVertical]}>
                                    <Text style={[styles.fontFamily, { fontSize: 12 }]}>Name  </Text>
                                    <Text style={[styles.valueText]}>{process.name}</Text>
                                  </View> */}
                                        <View style={[styles.innerSection, styles.paddingVertical]}>
                                          <Text style={[styles.fontFamily, { fontSize: 12 }]}>Description  </Text>
                                          <Text style={[styles.valueText, { width: '100%', paddingHorizontal: 10 }]}>{process.description}</Text>
                                        </View>
                                        <View style={[styles.innerSection, styles.paddingVertical]}>
                                          <Text style={[styles.fontFamily, { fontSize: 12 }]}>Widely understood?  </Text>
                                          <Text style={[styles.valueText]}>{process.detail_skip === true ? "Yes" : "No"}</Text>
                                        </View>
                                        <View style={[styles.danger, styles.paddingHorizontal, styles.marginVertical, styles.paddingVertical]}>
                                          <Text style={[styles.fontFamily, styles.linkFont]}><Text style={[styles.fontFamily, styles.linkFont]}>Note:</Text> processes in particular are best viewed within the interactive diagram here {' '}<Link src={hiagramLink} style={styles.linkFont}>{hiagramLink}
                                          </Link>
                                          </Text>
                                        </View>
                                        {process?.docMapping[0]?.doc && <View style={[styles.paddingVertical, styles.table]}>
                                          <View style={[styles.innerSection, styles.fontsize]}>
                                            <Image style={[styles.TableIconAlignment]} src={DocIcon} cache={false} />
                                            <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Documents</Text>
                                          </View>
                                          <View style={[styles.row, styles.bold, styles.header, styles.docTableBg]}>
                                            <Text style={styles.colls}>Name</Text>
                                            <Text style={styles.colls}>Location</Text>
                                            <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                          </View>
                                          {process.docMapping.map((docsMapping: any, i: number) => {
                                            return (
                                              <View key={i} >
                                                {docsMapping.doc.map((docs: any, i: number) => {
                                                  return (
                                                    <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                                      {/* <Text style={styles.colls}>{breakLongWord(docs.name, 600)}</Text>
                                                      {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                                                        <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.location, 600)}</Text>}
                                                      <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.description, 600)}</Text> */}


                                                      <Text style={styles.colls}>{docs.name}</Text>
                                                      {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                                                        <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{docs.location}</Text>}
                                                      <Text style={[styles.colls, styles.sizing]}>{docs.description}</Text>
                                                    </View>
                                                  )
                                                })}
                                              </View>
                                            )
                                          })}
                                        </View>}
                                        {process?.step[0] &&
                                          <View style={[styles.paddingVertical, styles.table]}>
                                            <View style={[styles.innerSection, styles.fontsize]}>
                                              <Text style={[styles.paddingHorizontal, styles.paddingVertical]} >Steps </Text>
                                            </View>
                                            <View style={[styles.row, styles.bold, styles.header, styles.stepTableBg]}>
                                              <Text style={styles.displayNumcolls}>Step Number</Text>
                                              <Text style={styles.colls}>Step Name</Text>
                                              <Text style={[styles.colls, styles.sizing]}>Step Description</Text>
                                            </View>

                                            {/* {process?.step.map((steps: any, i: number) => {
                                              return (
                                                <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                                  <Text style={styles.colls}>{steps.name}</Text>
                                                  <Text style={[styles.colls, styles.sizing]}>{steps.description}</Text>
                                                </View>
                                              )
                                            })} */}
                                            {
                                              sortedSteps.map((steps: any, i: number) => {
                                                return (
                                                  <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                                    <Text style={styles.displayNumcolls}>{steps.step_order}</Text>
                                                    <Text style={styles.colls}>{steps.name}</Text>
                                                    <Text style={[styles.colls, styles.sizing]}>{steps.description}</Text>
                                                  </View>
                                                )
                                              })
                                            }
                                          </View>}
                                      </View>
                                    )
                                  })}
                                </View>
                              }
                            </View>
                          )
                        })
                        }
                      </View>
                    ))}
                  </View>}
                  {/* team-role end */}

                </View>
              </View>))}
          </View>}

          {/* -----------------------PROJ--------------------- */}

          {hiagramAllDetailsForPdf?.proj[0] && <View>
            {hiagramAllDetailsForPdf?.proj?.map((item: any, index: number) => (
              <View key={index} style={{
                paddingHorizontal: 10,
                paddingTop: 10,
                border: "1px solid #A0A0A0",
                borderRadius: 10,
                paddingBottom: 10,
                marginBottom: 10,
              }}>
                <View>
                  <View style={{
                    paddingHorizontal: 10,
                  }}>
                    <View style={[styles.innerSection]}>
                      <Image style={[styles.IconAlignment]} src={ProjIcon} cache={false} />
                      <Text style={[styles.fontFamily, styles.paddingVertical, styles.fontsize, { marginLeft: 5, maxWidth: '470px' }]}>{item.name}</Text>
                    </View>
                    {/* <View style={[styles.innerSection, styles.paddingVertical]}>
                <Text style={[styles.fontFamily, { fontSize: 12 }]}>Name  </Text>
                <Text style={[styles.valueText]}>{item.name}</Text>
              </View> */}
                    {+userContext.tempUid === 2 && <View style={[styles.innerSection, styles.paddingVertical]}>
                      <Text style={[styles.fontFamily, { fontSize: 12 }]}>Purpose  </Text>
                      <Text style={[styles.valueText]}>{item.purpose}</Text>
                    </View>}
                    <View style={[styles.innerSection, styles.paddingVertical]}>
                      <Text style={[styles.fontFamily, { fontSize: 12 }]}>Description  </Text>
                      <Text style={[styles.valueText, { width: '100%', paddingHorizontal: 10 }]} >{item.description}</Text>
                    </View>
                  </View>

                  {/* rollup temp close start*/}
                  {/* {item?.stakeMapping[0]?.stake &&
              <View style={[styles.table]}>
                <View style={[styles.innerSection, styles.paddingVertical,]}>
                  <Image style={[styles.TableIconAlignment]} src={PeopleIcon} cache={false} />
                  <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>People</Text>
                </View>
                <View style={[styles.row, styles.bold, styles.header, styles.peopleTableBg]}>
                  <Text style={styles.colls}>First Name</Text>
                  <Text style={styles.colls}>Last Name</Text>
                  <Text style={styles.colls}>Organization</Text>
                  <Text style={styles.colls}>Department</Text>
                </View>
                {item?.stakeMapping?.map((row: any, i: number) => (
                  <View key={i} >
                    {row?.stake?.map((stakes: any, stakeI: number) => (
                      <View key={stakeI} style={[styles.row, styles.bold, styles.header, { backgroundColor: stakeI % 2 === 1 ? '#2C2449' : "white", }]} wrap={false}>
                        <Text style={[styles.colls]}>{stakes.first_name}</Text>
                        <Text style={styles.colls}>{stakes.last_name}</Text>
                        <Text style={styles.colls}>{stakes.org_name}</Text>
                        <Text style={styles.colls}>{stakes.department_name}</Text>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            } */}
                  {/* rollup temp close end*/}

                  {item?.actionMapping[0]?.action && <View style={[styles.paddingVertical, styles.table]}>
                    <View style={[styles.innerSection, styles.fontsize]}>
                      <Image style={[styles.TableIconAlignment]} src={ActionIcon} cache={false} />
                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]} >Milestone</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.header, styles.actionTableBg]}>
                      <Text style={styles.colls}>Name</Text>
                      <Text style={styles.colls}>Type</Text>
                      <Text style={styles.colls}>Status</Text>
                      <Text style={styles.colls}>Due Date</Text>
                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                    </View>
                    {/* {item?.actionMapping?.map((actionsMapping: any, i: number) => (
                      <View key={i} >
                        {actionsMapping?.action?.map((actions: any, i: number) => (
                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                            <Text style={styles.colls}>{breakLongWord(actions.name, 600)}</Text>
                            <Text style={styles.colls}>{actions.type.charAt(0).toUpperCase() + actions.type.slice(1).toLowerCase()}</Text>
                            <Text style={styles.colls}>{actions.status.split('_').map((part: any, index: number) => index === 0 ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : part.toLowerCase()).join(' ')}</Text>
                            <Text style={styles.colls}>{actions.due_date}</Text>
                            <Text style={[styles.colls, styles.sizing]}>{breakLongWord(actions.description, 600)}</Text>
                          </View>
                        ))}
                      </View>
                    ))} */}

                    {item?.actionMapping[0]?.action && item?.actionMapping
                      ?.slice()
                      .sort((a: any, b: any) => {
                        const eventA = a.action[0];
                        const eventB = b.action[0];
                        const dateTimeA: any = new Date(eventA.due_date);
                        const dateTimeB: any = new Date(eventB.due_date);
                        return dateTimeA - dateTimeB;
                      })
                      .reduce((acc: any[], item: any) => {
                        return acc.concat(item.action);
                      }, [])
                      .map((actions: any, i: number) => (
                        <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                          <Text style={styles.colls}>{actions.name}</Text>
                          <Text style={styles.colls}>{actions.type.charAt(0).toUpperCase() + actions.type.slice(1).toLowerCase()}</Text>
                          <Text style={styles.colls}>
                            {actions.status
                              .split('_')
                              .map((part: any, index: number) =>
                                index === 0 ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : part.toLowerCase()
                              )
                              .join(' ')}
                          </Text>
                          <Text style={styles.colls}>{actions.due_date}</Text>
                          <Text style={[styles.colls, styles.sizing]}>{actions.description}</Text>
                        </View>
                      ))
                    }


                  </View>}

                  {item?.hintMapping[0]?.hint && <View style={[styles.paddingVertical, styles.table]}>
                    <View style={[styles.innerSection, styles.fontsize]}>
                      <Image style={[styles.TableIconAlignment]} src={HintIcon} cache={false} />
                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Hint</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.header, styles.hintTableBg]}>
                      <Text style={styles.colls}>Type</Text>
                      <Text style={styles.colls}>Title</Text>
                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                    </View>
                    {item?.hintMapping?.map((row: any, i: number) => (
                      <View key={i} >
                        {row?.hint?.map((hints: any, i: number) => (
                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                            {/* <Text style={styles.colls}>{breakLongWord(hints.name, 600)}</Text>
                            <Text style={styles.colls}>{breakLongWord(hints.title, 600)}</Text>
                            <Text style={[styles.colls, styles.sizing]}>{breakLongWord(hints.description, 600)}</Text> */}

                            <Text style={styles.colls}>{hints.name}</Text>
                            <Text style={styles.colls}>{hints.title}</Text>
                            <Text style={[styles.colls, styles.sizing]}>{hints.description}</Text>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>}
                  {item?.docMapping[0]?.doc && <View style={[styles.paddingVertical, styles.table]}>
                    <View style={[styles.innerSection, styles.fontsize]}>
                      <Image style={[styles.TableIconAlignment]} src={DocIcon} cache={false} />
                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Documents</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.header, styles.docTableBg]}>
                      <Text style={styles.colls}>Name</Text>
                      <Text style={styles.colls}>Location</Text>
                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                    </View>
                    {item?.docMapping?.map((row: any, i: number) => (
                      <View key={i} >
                        {row?.doc?.map((docs: any, i: number) => (
                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                            {/* <Text style={styles.colls}>{breakLongWord(docs.name, 600)}</Text>
                            {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                              <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.location, 600)}</Text>}
                            <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.description, 600)}</Text> */}

                            <Text style={styles.colls}>{docs.name}</Text>
                            {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                              <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{docs.location}</Text>}
                            <Text style={[styles.colls, styles.sizing]}>{docs.description}</Text>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>}
                  {item?.role[0].activity[0] &&
                    <View>
                      {item.role.map((roles: any, i: number) => (
                        <View key={i} >
                          {roles.activity.map((activities: any, i: number) => {
                            {
                              activities?.eventMapping[0]?.event && activities?.eventMapping?.map((eventsMapping: any, i: number) => {
                                sortedEventMappingsForProj = activities.eventMapping.sort((a: any, b: any) => {
                                  const eventA: any = a.event[0];
                                  const eventB: any = b.event[0];
                                  const dateTimeA: any = new Date(`${eventA.first_date}T${new Date(eventA.time).toISOString().split('T')[1]}`)
                                  const dateTimeB: any = new Date(`${eventB.first_date}T${new Date(eventB.time).toISOString().split('T')[1]}`)
                                  return dateTimeA - dateTimeB;
                                })
                              })
                            }
                            return (
                              <View key={i} style={{
                                paddingHorizontal: 10,
                                paddingTop: 10,
                                border: "1px solid #A0A0A0",
                                borderRadius: 10,
                                paddingBottom: 10,
                                marginTop: 10,
                              }}  >
                                {/* <View key={index} style={{ fontSize: 11 }}  >
                            <View style={[styles.innerSection, { justifyContent: 'flex-end', alignItems: 'center' }]} >
                              <Image style={{ width: 20, height: 20 }} src={ProjIcon} cache={false} />
                              <Text style={[styles.paddingHorizontal, { fontSize: 9 }]}>{item.name}</Text>
                            </View>
                            <View style={[styles.innerSection, { alignItems: 'center' }]}>
                              <Image style={[styles.IconAlignment]} src={ActivityIcon} cache={false} />
                              <Text style={[styles.paddingHorizontal, styles.fontsize]}>{activities.name}</Text>
                            </View>
                          </View> */}
                                <View key={index}>
                                  <View style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-end', alignItems: 'center', marginBottom: 7 }}>
                                    <Image style={{ width: 15, height: 15 }} src={ProjIcon} cache={false} />
                                    <Text style={{
                                      fontSize: 9, marginLeft: 5
                                      , fontFamily: 'Roboto',
                                    }}>{item.name}</Text>
                                  </View>
                                  <View style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center', marginTop: 7 }}>
                                    <Image style={[styles.IconAlignment]} src={ActivityIcon} cache={false} />
                                    <Text style={[styles.fontFamily, styles.paddingVertical, styles.fontsize, { marginLeft: 5, maxWidth: '470px' }]}>{activities.name}</Text>
                                  </View>
                                </View>
                                {/* <View style={[styles.innerSection, styles.fontsize, styles.paddingVertical]}>
                            <Text style={[styles.fontFamily, { fontSize: 12 }]}>Name  </Text>
                            <Text style={[styles.valueText]}>{activities.name}</Text>
                          </View> */}
                                <View style={[styles.innerSection, styles.paddingVertical]}>
                                  <Text style={[styles.fontFamily, { fontSize: 12 }]}>Description  </Text>
                                  <Text style={[styles.valueText, { width: '100%', paddingHorizontal: 10 }]} >{activities.description}</Text>
                                </View>
                                <View style={{ paddingBottom: 20 }}>
                                  {activities?.stakeMapping[0]?.stake &&
                                    <View style={[styles.paddingVertical, styles.table]}>
                                      <View style={[styles.innerSection, styles.fontsize]}>
                                        <Image style={[styles.TableIconAlignment]} src={PeopleIcon} cache={false} />
                                        <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>People</Text>
                                      </View>
                                      <View style={[styles.row, styles.bold, styles.header, styles.peopleTableBg]}>
                                        <Text style={styles.colls}>First Name</Text>
                                        <Text style={styles.colls}>Last Name</Text>
                                        <Text style={styles.colls}>Organization</Text>
                                        <Text style={styles.colls}>Department</Text>
                                      </View>
                                      {activities?.stakeMapping?.slice().sort((a: any, b: any) => {
                                        // Compare org_name
                                        const orgNameA = a.stake[0];
                                        const orgNameB = b.stake[0];
                                        const firstNameA = a.stake[0];
                                        const firstNameB = b.stake[0];
                                        const lastNameA = a.stake[0];
                                        const lastNameB = b.stake[0];

                                        if (orgNameA.org_name < orgNameB.org_name) return -1;
                                        if (orgNameA.org_name > orgNameB.org_name) return 1;

                                        if (firstNameA.first_name < firstNameB.first_name) return -1;
                                        if (firstNameA.first_name > firstNameB.first_name) return 1;

                                        if (lastNameA.last_name < lastNameB.last_name) return -1;
                                        if (lastNameA.last_name > lastNameB.last_name) return 1;

                                        return 0;
                                      }).map((row: any, i: number) => (
                                        <View key={i} >
                                          {row?.stake?.map((stakes: any, i: number) => (
                                            <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                              {/* <Text style={styles.colls}>{breakLongWord(stakes.first_name, 600)}</Text>
                                              <Text style={styles.colls}>{breakLongWord(stakes.last_name, 600)}</Text>
                                              <Text style={styles.colls}>{breakLongWord(stakes.org_name, 600)}</Text>
                                              <Text style={styles.colls}>{breakLongWord(stakes.department_name, 600)}</Text> */}

                                              <Text style={styles.colls}>{stakes.first_name}</Text>
                                              <Text style={styles.colls}>{stakes.last_name}</Text>
                                              <Text style={styles.colls}>{stakes.org_name}</Text>
                                              <Text style={styles.colls}>{stakes.department_name}</Text>
                                            </View>
                                          ))}
                                        </View>
                                      ))}
                                    </View>}
                                  {activities?.hintMapping[0]?.hint &&
                                    <View style={[styles.paddingVertical, styles.table]}>
                                      <View style={[styles.innerSection, styles.fontsize]}>
                                        <Image style={[styles.TableIconAlignment]} src={HintIcon} cache={false} />
                                        <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Hint</Text>
                                      </View>
                                      <View style={[styles.row, styles.bold, styles.header, styles.hintTableBg]}>
                                        <Text style={styles.colls}>Type</Text>
                                        <Text style={styles.colls}>Title</Text>
                                        <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                      </View>
                                      {activities?.hintMapping?.map((row: any, i: number) => (
                                        <View key={i} >
                                          {row?.hint?.map((hints: any, i: number) => (
                                            <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                              {/* <Text style={styles.colls}>{breakLongWord(hints.name, 600)}</Text>
                                              <Text style={styles.colls}>{breakLongWord(hints.title, 600)}</Text>
                                              <Text style={[styles.colls, styles.sizing]}>{breakLongWord(hints.description, 600)}</Text> */}

                                              <Text style={styles.colls}>{hints.name}</Text>
                                              <Text style={styles.colls}>{hints.title}</Text>
                                              <Text style={[styles.colls, styles.sizing]}>{hints.description}</Text>
                                            </View>
                                          ))}
                                        </View>
                                      ))}
                                    </View>}
                                  {activities?.docMapping[0]?.doc && <View style={[styles.paddingVertical, styles.table]}>
                                    <View style={[styles.innerSection, styles.fontsize]}>
                                      <Image style={[styles.TableIconAlignment]} src={DocIcon} cache={false} />
                                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Documents</Text>
                                    </View>
                                    <View style={[styles.row, styles.bold, styles.header, styles.docTableBg]}>
                                      <Text style={styles.colls}>Name</Text>
                                      <Text style={styles.colls}>Location</Text>
                                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                    </View>
                                    {activities?.docMapping?.map((row: any, i: number) => (
                                      <View key={i} >
                                        {row?.doc?.map((docs: any, i: number) => (
                                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                            {/* <Text style={styles.colls}>{breakLongWord(docs.name, 600)}</Text>
                                            {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                                              <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.location, 600)}</Text>}
                                            <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.description, 600)}</Text> */}

                                            <Text style={styles.colls}>{docs.name}</Text>
                                            {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                                              <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{docs.location}</Text>}
                                            <Text style={[styles.colls, styles.sizing]}>{docs.description}</Text>
                                          </View>
                                        ))}
                                      </View>
                                    ))}
                                  </View>}
                                  {activities?.actionMapping[0]?.action &&
                                    <View style={[styles.paddingVertical, styles.table]}>
                                      <View style={[styles.innerSection, styles.fontsize]}>
                                        <Image style={[styles.TableIconAlignment]} src={ActionIcon} cache={false} />
                                        <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Action</Text>
                                      </View>
                                      <View style={[styles.row, styles.bold, styles.header, styles.actionTableBg]}>
                                        <Text style={styles.colls}>Name</Text>
                                        <Text style={styles.colls}>Type</Text>
                                        <Text style={styles.colls}>Status</Text>
                                        <Text style={styles.colls}>Due Date</Text>
                                        <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                      </View>

                                      {activities?.actionMapping[0]?.action && activities?.actionMapping
                                        ?.slice()
                                        .sort((a: any, b: any) => {
                                          const eventA = a.action[0];
                                          const eventB = b.action[0];
                                          const dateTimeA: any = new Date(eventA.due_date);
                                          const dateTimeB: any = new Date(eventB.due_date);
                                          return dateTimeA - dateTimeB;
                                        })
                                        .reduce((acc: any[], item: any) => {
                                          return acc.concat(item.action);
                                        }, [])
                                        .map((actions: any, i: number) => (
                                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                            <Text style={styles.colls}>{actions.name}</Text>
                                            <Text style={styles.colls}>{actions.type.charAt(0).toUpperCase() + actions.type.slice(1).toLowerCase()}</Text>
                                            <Text style={styles.colls}>
                                              {actions.status
                                                .split('_')
                                                .map((part: any, index: number) =>
                                                  index === 0 ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : part.toLowerCase()
                                                )
                                                .join(' ')}
                                            </Text>
                                            <Text style={styles.colls}>{actions.due_date}</Text>
                                            <Text style={[styles.colls, styles.sizing]}>{actions.description}</Text>
                                          </View>
                                        ))
                                      }

                                    </View>}
                                  {activities?.eventMapping[0]?.event && <View style={[styles.paddingVertical, styles.table]}>
                                    <View style={[styles.innerSection, styles.fontsize]}>
                                      <Image style={[styles.TableIconAlignment]} src={EventIcon} cache={false} />
                                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Event</Text>
                                    </View>
                                    <View style={[styles.row, styles.bold, styles.header, styles.eventTableBg]}>
                                      <Text style={[styles.colls, { minWidth: '15%' }]}>Name</Text>
                                      <Text style={styles.colls}>Location</Text>
                                      <Text style={styles.colls}>First date</Text>
                                      <Text style={styles.colls}>Time</Text>
                                      <Text style={styles.colls}>Duration</Text>
                                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                    </View>
                                    {/* {activities?.eventMapping?.map((row: any, i: number) => (
                                      <View key={i} >
                                        {row?.event?.sort((a: any, b: any) => {
                                          const dateA: any = new Date(`${a.first_date}T${new Date(a.time).toISOString().split('T')[1]}`);
                                          const dateB: any = new Date(`${b.first_date}T${new Date(b.time).toISOString().split('T')[1]}`);
                                          return dateA - dateB;
                                        }).map((event: any, i: number) => (
                                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                            <Text style={[styles.colls, { minWidth: '15%' }]}>{breakLongWord(event.name, 600)}</Text>
                                            <Text style={styles.colls}>{breakLongWord(event.location, 600)}</Text>
                                            <Text style={styles.colls}>{event.first_date}</Text>
                                            <Text style={styles.colls}>{dayjs(event.time).format("h:m a")}</Text>
                                            <Text style={styles.colls}>{breakLongWord(event.duration, 600)}</Text>
                                            <Text style={[styles.colls, styles.sizing, { marginRight: '15px' }]}>{breakLongWord(event.description, 600)}</Text>
                                          </View>
                                        ))}
                                      </View>
                                    ))} */}

                                    {sortedEventMappingsForProj.map((row: any, i: number) => (
                                      <View key={i}>
                                        {row?.event?.map((events: any, i: any) => (
                                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                            {/* <Text style={[styles.colls, { minWidth: '15%' }]}>{breakLongWord(events.name, 600)}</Text>
                                            <Text style={styles.colls}>{breakLongWord(events.location, 600)}</Text>
                                            <Text style={styles.colls}>{events.first_date}</Text>
                                            <Text style={styles.colls}>{dayjs(events.time).format("hh:mm a")}</Text>
                                            <Text style={styles.colls}>{breakLongWord(events.duration, 600)}</Text>
                                            <Text style={[styles.colls, styles.sizing, { marginRight: '15px' }]}>{breakLongWord(events.description, 600)}</Text> */}

                                            <Text style={[styles.colls, { minWidth: '15%' }]}>{events.name}</Text>
                                            <Text style={styles.colls}>{events.location}</Text>
                                            <Text style={styles.colls}>{events.first_date}</Text>
                                            <Text style={styles.colls}>{dayjs(events.time).utc().format('h:mm a')}</Text>
                                            <Text style={styles.colls}>{events.duration}</Text>
                                            <Text style={[styles.colls, styles.sizing, { marginRight: '15px' }]}>{events.description}</Text>
                                          </View>
                                        ))}
                                      </View>
                                    ))}

                                  </View>}
                                  {activities?.assetMapping[0]?.asset && <View style={[styles.paddingVertical, styles.table]}>
                                    <View style={[styles.innerSection, styles.fontsize]}>
                                      <Image style={[styles.TableIconAlignment]} src={AssetIcon} cache={false} />
                                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Asset</Text>
                                    </View>
                                    <View style={[styles.row, styles.bold, styles.header, styles.assetTableBg]}>
                                      <Text style={styles.colls}>Name</Text>
                                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                    </View>
                                    {activities?.assetMapping?.map((assetsMapping: any, i: number) => (
                                      <View key={i} >
                                        {assetsMapping?.asset?.map((assets: any, i: number) => (
                                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                            {/* <Text style={styles.colls}>{breakLongWord(assets.name, 600)}</Text>
                                            <Text style={[styles.colls, styles.sizing]}>{breakLongWord(assets.description, 600)}</Text> */}

                                            <Text style={styles.colls}>{assets.name}</Text>
                                            <Text style={[styles.colls, styles.sizing]}>{assets.description}</Text>
                                          </View>
                                        ))}
                                      </View>
                                    ))}
                                  </View>}
                                  {activities?.trgMapping[0]?.trg && <View style={[styles.paddingVertical, styles.table]}>
                                    <View style={[styles.innerSection, styles.fontsize]}>
                                      <Image style={[styles.TableIconAlignment]} src={SkillIcon} cache={false} />
                                      <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Skill</Text>
                                    </View>
                                    <View style={[styles.row, styles.bold, styles.header, styles.skillTableBg]}>
                                      <Text style={styles.colls}>Name</Text>
                                      <Text style={styles.colls}>Estimate Days</Text>
                                      <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                    </View>
                                    {activities?.trgMapping?.map((trgsMapping: any, i: number) => (
                                      <View key={i} >
                                        {trgsMapping?.trg?.map((trgs: any, i: number) => (
                                          <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                            {/* <Text style={styles.colls}>{breakLongWord(trgs.name, 600)}</Text>
                                            <Text style={styles.colls}>{breakLongWord(trgs.estimate_days, 600)}</Text>
                                            <Text style={[styles.colls, styles.sizing]}>{breakLongWord(trgs.description, 600)}</Text> */}

                                            <Text style={styles.colls}>{trgs.name}</Text>
                                            <Text style={styles.colls}>{trgs.estimate_days}</Text>
                                            <Text style={[styles.colls, styles.sizing]}>{trgs.description}</Text>
                                          </View>
                                        ))}
                                      </View>
                                    ))}
                                  </View>}
                                </View>
                                <View>
                                  {activities.cess?.map((process: any, i: number) => {
                                    const sortedSteps = process.step.slice().sort((a: any, b: any) => a.step_order - b.step_order);
                                    return (
                                      <View key={i} style={[styles.border, styles.paddingHorizontal, styles.paddingVertical, { marginTop: 10 }]}>
                                        <View key={index} style={{ fontSize: 11 }}  >
                                          <View style={[
                                            styles.innerSection,
                                            {
                                              // justifyContent: 'flex-end',
                                              // alignItems: 'center',
                                              // marginBottom: 10,
                                              // width: '80%',
                                              // alignSelf: "flex-end"
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                              alignItems: 'center',
                                              marginBottom: '15',
                                              width: '100%',
                                            }
                                          ]}>
                                            <View style={[styles.innerSection, styles.alignItemCenter, { maxWidth: '45%' }]}>
                                              <Image style={{ width: 15, height: 15 }} src={ProjIcon} cache={false} />
                                              <Text style={{
                                                fontSize: 9, marginLeft: 5
                                                , fontFamily: 'Roboto'
                                              }}>{item.name}</Text>
                                            </View>
                                            <View style={[styles.innerSection, styles.alignItemCenter, { width: '3%', marginHorizontal: '2px' }]}>
                                              <Text >{'>'}</Text>
                                            </View>
                                            <View style={[styles.innerSection, styles.alignItemCenter, { maxWidth: '45%' }]}>
                                              <Image style={{ width: 15, height: 15 }} src={ActivityIcon} cache={false} />
                                              <Text style={{
                                                fontSize: 9, marginLeft: 5
                                                , fontFamily: 'Roboto'
                                              }}>{activities.name}</Text>
                                            </View>
                                          </View>
                                          <View style={[styles.innerSection, { alignItems: 'center' }]} >
                                            <Image style={[styles.IconAlignment]} src={ProcessIcon} cache={false} />
                                            <Text style={[styles.fontFamily, styles.paddingVertical, styles.fontsize, { marginLeft: 5, maxWidth: '470px' }]}>{process.name}</Text>
                                          </View>
                                        </View>
                                        {/* <View style={[styles.innerSection, styles.paddingVertical]}>
                                    <Text style={[styles.fontFamily, { fontSize: 12 }]}>Name  </Text>
                                    <Text style={[styles.valueText]}>{process.name}</Text>
                                  </View> */}
                                        <View style={[styles.innerSection, styles.paddingVertical]}>
                                          <Text style={[styles.fontFamily, { fontSize: 12 }]}>Description  </Text>
                                          <Text style={[styles.valueText, { width: "100%", paddingLeft: 10 }]}>{process.description}</Text>
                                        </View>
                                        <View style={[styles.innerSection, styles.paddingVertical]}>
                                          <Text style={[styles.fontFamily, { fontSize: 12 }]}>Widely understood?  </Text>
                                          <Text style={[styles.valueText]}>{process.detail_skip === true ? "Yes" : "No"}</Text>
                                        </View>
                                        <View style={[styles.danger, styles.paddingHorizontal, styles.marginVertical, styles.paddingVertical]}>
                                          <Text style={[styles.fontFamily, styles.linkFont]}><Text style={[styles.fontFamily, styles.linkFont]}>Note:</Text> processes in particular are best viewed within the interactive diagram here {' '}  <Link src={hiagramLink}>{hiagramLink}
                                          </Link>
                                          </Text>
                                        </View>
                                        {process?.docMapping[0]?.doc && <View style={[styles.paddingVertical, styles.table]}>
                                          <View style={[styles.innerSection, styles.fontsize]}>
                                            <Image style={[styles.TableIconAlignment]} src={DocIcon} cache={false} />
                                            <Text style={[styles.paddingVertical, styles.innerSection, { fontSize: 13, marginLeft: 5 }]}>Documents</Text>
                                          </View>
                                          <View style={[styles.row, styles.bold, styles.header, styles.docTableBg]}>
                                            <Text style={styles.colls}>Name</Text>
                                            <Text style={styles.colls}>Location</Text>
                                            <Text style={[styles.colls, styles.sizing]}>Description</Text>
                                          </View>
                                          {process.docMapping.map((docsMapping: any, i: number) => {
                                            return (
                                              <View key={i} >
                                                {docsMapping.doc.map((docs: any, i: number) => {
                                                  return (
                                                    <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                                      {/* <Text style={[styles.colls]}>{breakLongWord(docs.name, 600)}</Text>
                                                      {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                                                        <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.location, 600)}</Text>}
                                                      <Text style={[styles.colls, styles.sizing]}>{breakLongWord(docs.description, 600)}</Text> */}

                                                      <Text style={[styles.colls]}>{docs.name}</Text>
                                                      {(docs.location !== undefined && docs.location !== null && docs.location.includes("http")) || (docs.location !== undefined && docs.location !== null && docs.location.includes("https")) ?
                                                        <Link src={docs.location !== undefined && docs.location !== null && docs.location} style={[styles.colls, styles.sizing]}>{formatText(docs.location !== undefined && docs.location !== null && docs.location)}</Link> : <Text style={[styles.colls, styles.sizing]}>{docs.location}</Text>}
                                                      <Text style={[styles.colls, styles.sizing]}>{docs.description}</Text>
                                                    </View>
                                                  )
                                                })}
                                              </View>
                                            )
                                          })}
                                        </View>}
                                        {process?.step[0] && <View style={[styles.paddingVertical, styles.table]}>
                                          <View style={[styles.innerSection, styles.fontsize]}>
                                            <Text style={[styles.paddingHorizontal, styles.paddingVertical]} >Steps </Text>
                                          </View>
                                          <View style={[styles.row, styles.bold, styles.header, styles.stepTableBg]}>
                                            <Text style={styles.displayNumcolls}>Step Number</Text>
                                            <Text style={styles.colls}>Step Name</Text>
                                            <Text style={[styles.colls, styles.sizing]}>Step Description</Text>
                                          </View>
                                          {
                                            sortedSteps.map((steps: any, i: number) => {
                                              return (
                                                <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                                  <Text style={styles.displayNumcolls}>{steps.step_order}</Text>
                                                  <Text style={styles.colls}>{steps.name}</Text>
                                                  <Text style={[styles.colls, styles.sizing]}>{steps.description}</Text>
                                                </View>
                                              )
                                            })
                                          }
                                          {/* {process?.step.map((steps: any, i: number) => {
                                            return (
                                              <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                                <Text style={styles.colls}>{steps.name}</Text>
                                                <Text style={[styles.colls, styles.sizing]}>{steps.description}</Text>
                                              </View>
                                            )
                                          })} */}
                                          {/* {process.step.map((steps: any, i: number) => {
                                            return (
                                              <View key={i} style={[styles.row, styles.bold, styles.header]} wrap={false}>
                                                <Text style={styles.colls}>{steps.name}</Text>
                                                <Text style={[styles.colls, styles.sizing]}>{steps.description}</Text>
                                              </View>
                                            )
                                          })} */}
                                        </View>}
                                      </View>
                                    )
                                  })}
                                </View>
                              </View>
                            )
                          })
                          }
                        </View>
                      ))}
                    </View>}
                </View>
              </View>
            ))}
          </View>}
          {/* Project section end */}

          {/* Fixed Footer start */}
          <View style={{ paddingVertical: 10 }} fixed ></View>
          {/* Fixed Footer end */}
        </View>
      </Page >
    </Document >
  )
}

export default ExportToPdf





