import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetCessList = (params: any) =>
    GET(`${BASE_URL}/cess/list?` + params, null);

export const CreateCess = (data: any) =>
    POST(`${BASE_URL}/cess/create`, data);

export const GetCessdetail = (params: any) =>
    GET(`${BASE_URL}/cess/getdetail?` + params, null);

export const UpdateCess = (data: any) =>
    POST(`${BASE_URL}/cess/update`, data);

export const UpdateCessDetail = (data: any) =>
    POST(`${BASE_URL}/cess/updatedetail`, data);

export const GetAllCessList = (params: any) =>
    GET(`${BASE_URL}/cess/cesslist?` + params, null);

export const GetCessdetailById = (params: any) =>
    GET(`${BASE_URL}/cess/getdetailbyuid?` + params, null);

export const ProcessGetAllDetail = (params: any) =>
    GET(`${BASE_URL}/cess/hiagramgetalldetail?` + params, null);

export const GetCessDetailForEdit = (params: any) =>
    GET(`${BASE_URL}/cess/getdetailforedit?` + params, null);

export const UpdateCessDataOfProcessCard = (data: any) =>
    POST(`${BASE_URL}/cess/updatecard`, data);

export const UpdateCurCessDetail = (data: any) =>
    POST(`${BASE_URL}/cess/updatecurcessdetail`, data);

export const DeleteCess = (id: any, data: any) =>
    DELETE(`${BASE_URL}/cess/` + id, data);
