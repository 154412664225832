import { NodeProps, useReactFlow, MarkerType, getOutgoers, getIncomers } from 'reactflow';
import { useCallback } from 'react';
import { uuid, randomLabel } from '../utils';

// this hook implements the logic for clicking the button on a workflow edge
// on edge click: create a node in between the two nodes that are connected by the edge
function useNodeDelete(id: NodeProps['id']) {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();
  let removeNodes: any[] = [];
  let removeEdges: string[] = [];
  const onClick = () => {
    const currentNode = getNode(id);
    if (!currentNode) {
      return;
    }

    const parentNodes = getIncomers(currentNode, getNodes(), getEdges());
    let currentNodeEdge = parentNodes[0].id + "=>" + id;

    const childs = getOutgoers(currentNode, getNodes(), getEdges());
    removeNodes = [];
    removeEdges = [];

    if (childs.length > 0) {
      removeNodeandEdges(childs, id);
    }

    //Remove Child Nodes
    if (removeNodes.length > 0) {
      removeNodes.forEach(rNodes => {
        if (rNodes.id !== '2') {
          setNodes((nodes) =>
            nodes.filter((node) => node.id !== rNodes.id));
        }
      });
    }

    //Remove Child Edges
    if (removeEdges.length > 0) {
      removeEdges.forEach(rEdges => {
        setEdges((edges) =>
          edges.filter((edge) => edge.id !== rEdges));
      });
    }

    //Remove Current Node
    setNodes((nodes) =>
      nodes.filter((node) => node.id !== id));

    //Remove Current Edge
    setEdges((edges) =>
      edges.filter((edge) => edge.id !== currentNodeEdge));
    
    //Remove all end edges
    const endNode = getNode('2');
    if (endNode) {
      const allParentIds = getIncomers(endNode, getNodes(), getEdges()).map((node) => node.id);
      if (allParentIds.length > 0) {
        allParentIds.forEach(node => {
          setEdges((edges) =>
            edges.filter((edge) => edge.id !== `${node}=>2`));
        });
      }
    }

    //Connect to end node
    const allNodes = getNodes();
    if (allNodes.length > 0) {
      allNodes.forEach(node => {
        const allChilds = getOutgoers(node, getNodes(), getEdges());
        if (allChilds.length === 0 && node.id != '2') {
          const endEdge = {
            id: `${node.id}=>2`,
            source: node.id,
            target: '2',
            type: 'step',
            style: { stroke: '#680001', strokeDasharray: 3 },
            markerEnd: {
              type: MarkerType.Arrow,
              color: '#680001',
              height: 20,
              width: 20,
            },
          };
          setEdges((edges) =>
            edges.concat([endEdge])
          );
        }
      });
    }

  };

  function removeNodeandEdges(childs: any[], currentNodeId: any) {
    childs.forEach(child => {
      let currentEdge: any = currentNodeId + "=>" + child.id;
      removeEdges.push(...removeEdges, currentEdge);
      const childParents = getIncomers(child, getNodes(), getEdges()).filter((node) => node.id !== currentNodeId);
      if (childParents.length === 0) {
        removeNodes.push({ ...child });
        const innerChilds = getOutgoers(child, getNodes(), getEdges());
        if (innerChilds.length > 0) {
          removeNodeandEdges(innerChilds, child.id);
        }
      }
    });

  }
  return onClick;
}

export default useNodeDelete;
