
// import { doAuthenticate } from "../AuthenticationService";
// import { AuthenticationModelResponse } from "../Model/AuthenticationModel";
import { Context } from "../models/Context";
import { EncryptDecryptService } from "./EncryptionService";
//import { EncryptDecryptService } from "./encryptionSercice";

const STORAGE_KEY = "ip_1";



// function isTokenExpired(token: string, tokenExpiryTime: string)
// {
//   if(token == "" && tokenExpiryTime == "")
//   {
//     return true;
//   }
//   else 
//   {
//     let expiryTime: Date = new Date(tokenExpiryTime);
//     let nowDate: Date = new Date(new Date().toISOString());
//     let pendingSeconds: number = (+expiryTime - +nowDate) / 1000;

//     if(pendingSeconds < 300)
//     {
//       return true;
//     }
//     else
//     {
//       return false;
//     }
//   }

//}


// async function refreshAuthToken(context: Context) {
//   try {

//       var resultPromise = await doAuthenticate({
//           clientId: CLIENT_ID,
//           clientSecret: CLIENT_SECRET,
//       });

//       let response: AuthenticationModelResponse = JSON.parse(JSON.stringify(resultPromise));

//       // prepare expiry time
//       let nowDate = new Date().toISOString();
//       let expiryTime = new Date();
//       expiryTime.setSeconds(new Date(nowDate).getSeconds() + response.expires_In);
//       //expiryTime.setSeconds(new Date(nowDate).getSeconds() + 300);

//       context.token = response.access_Token;
//       context.tokenExpiryTime = expiryTime.toISOString();

//       return context;

//   } catch (error) {
//       if (error) {
//           console.log(error);
//       }
//       return null;
//   }
// }

// async function getVerifiedStorageInfo (context: Context) {
//   if (!context) {
//     context = new Context();
//     const response = await refreshAuthToken(context);

//     await setStorage(response);

//     return response;
//   }
//   else if (context) {
//     if (!isTokenExpired(context.token, context.tokenExpiryTime)) {
//       return context;
//     } else {

//       const response = await refreshAuthToken(context);

//        await setStorage(response);

//        return response;

//     }
//   } else {
//     return null;
//   }
// }



function storeDataToStorage(credentials: Context) {

  try {
    if (!(credentials && credentials.token)) {
      credentials = new Context();
    }

    //remove existing
    localStorage.removeItem(STORAGE_KEY);

    const edService = new EncryptDecryptService();

    //  // Save to local storage enc
    const A = edService.encrypt(JSON.stringify(credentials))!!.toString()

    //not enc
    // const A = JSON.stringify(credentials)!!.toString();

    //add new
    localStorage.setItem(STORAGE_KEY, A);


  } catch (error) {
    // Error saving data
    console.log(error);
  }
}

const readDataFromStorage = () => {
  try {
    let credentials = localStorage.getItem(STORAGE_KEY);

    let storage_value: any = null;
    storage_value = credentials;

    const edService = new EncryptDecryptService();

    const d = edService.decrypt(credentials)

    // let storage_value;
    if (d != null) {
      storage_value = d;
    }

    let context: Context = (storage_value) ? JSON.parse(storage_value) : null;

    //let cred = await getVerifiedStorageInfo(context)

    //if (credentials != null && cred != null) {
    if (context != null) {
      return context
      //return cred
    } else {
      return new Context();
    }
  } catch (error) {
    console.log(error)
  }

  return new Context();
}


export const getStorage = () => {
  return readDataFromStorage();
}

export function setStorage(credentials: Context) {
  return storeDataToStorage(credentials);
}



