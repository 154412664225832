import { Autocomplete, Box, Button, createFilterOptions, Grid, IconButton, Paper, Popover, Skeleton, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import * as React from "react";
import Stakeholders from '../../../assets/icons/Group 39982.svg';
import ClearIcon from '@mui/icons-material/Clear';
import "./ItemStakeAddModel.scss";
import Select from "react-select";
import AddIcon from '@mui/icons-material/Add';
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { CreateStake, GetAllStakeList, GetRaciList, GetStakeDetail, UpdateStake, CreateStakeMapping, GetAutoCompleteData, CreateStakeMappingForTeamProjActivity, UpdateStakeForTeamProjActivity, CreateStakeForTeamProjActivity } from "../../../services/StakeService";
import { PreferencesContext } from "../../../PreferenceContext";
import { AlertType } from "../../../shared/AlertType";
import { Form, Formik } from "formik";
import _ from "lodash";
import { getSingleSelected } from "../../../services/common/commonFunction";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LoadingButton } from "@mui/lab";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#56506D",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E6E8F0",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function ItemStakeAddModel(param: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [raciData, setRaciData] = useState([]);
    const [organizationData, setorganizationData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [isRaciDisabled, setRaciDisabled] = useState(false);
    const [allStakeData, setAllStakeData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [openGuidanceDialog, setOpenGuidanceDialog] = useState<HTMLElement | null>(null);
    const [guidance, setGuidance] = useState("");
    const [openorganization, setOpenorganization] = useState(false);
    const [openDepartment, setOpenDepartment] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [isFocusedFname, setFocusedFname] = useState(false);
    const [isFocusedLname, setFocusedLname] = useState(false);
    const [isFocusedDepartment, setFocusedDepartment] = useState(false);
    const [isFocusedOrganize, setFocusedOrganize] = useState(false);
    const [isFocusedContact, setFocusedContact] = useState(false);
    const [isFocusedRole, setFocusedRole] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);


    const formInitialValues = {
        id: 0,
        raci: "",
        firstName: "",
        lastName: "",
        organization: "",
        department: "",
        role: "",
        contact: "",
        description: "",
        mappingId: 0,
        appendToType: "",
        appendUid: 0,
        isRaciDisabled: false,
    };

    const [formInitial, setFormInitial] = useState(formInitialValues);

    const filterOptions = createFilterOptions({
        matchFrom: "start"
    });
    const mainTheme = useTheme();

    const handlePopoverOpenForStake = (event: React.MouseEvent<HTMLElement>) => {
        setOpenGuidanceDialog(event.currentTarget);
        setGuidance(param.curQuestion.guidance);
    };

    const handlePopoverCloseForStake = () => {
        setOpenGuidanceDialog(null);
    };

    const open = Boolean(openGuidanceDialog);

    async function fetchRACI() {
        try {
            const raciGetData: any = await GetRaciList();
            if (raciGetData !== undefined && raciGetData !== null && raciGetData.data !== null && raciGetData.data.data !== null && raciGetData.data.data.data !== null) {
                setRaciData(raciGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function getAutoCompleteData() {
        try {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            const autoCompleteGetData: any = await GetAutoCompleteData(params);
            if (autoCompleteGetData !== undefined && autoCompleteGetData !== null && autoCompleteGetData.data !== null && autoCompleteGetData.data.data !== null) {
                setorganizationData(autoCompleteGetData.data.data.organization);
                setDepartmentData(autoCompleteGetData.data.data.department);

            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchStake(id: any, sId: any, isDisabled: boolean) {
        setDataLoading(true);
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("smuid", sId.toString());
        params.append("suid", id.toString());
        const stakeres: any = await GetStakeDetail(params);
        if (stakeres !== null && stakeres.data !== null && stakeres.data.data !== null) {
            let data = stakeres.data.data;
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            if (+param.curQuestion.number === 10) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 16) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 29) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            } else if (+param.curQuestion.number === 33) {
                appendType = "STEP";
                appendUID = userContext.stepId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            const formValues = {
                id: +data.uid,
                raci: isDisabled === true ? "" : data.stakeMapping[0].raci,
                firstName: data.first_name,
                lastName: data.last_name,
                organization: data.org_name,
                department: data.department_name,
                // role: data.role,
                contact: data.contact,
                description: data.stakeMapping[0].description,
                role: data.stakeMapping[0].role,
                mappingId: +data.stakeMapping[0].uid,
                appendToType: appendType,
                appendUid: appendUID,
                isRaciDisabled: isDisabled
            };
            setFormInitial(formValues);
        }
        setDataLoading(false);
    }

    async function fetchStakeList() {
        setLoading(true);
        let appendType = "";
        let appendUID = 0;
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        if (+param.curQuestion.number === 5) {
            appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            appendUID = userContext.teamProjId;
        } else if (+param.curQuestion.number === 10) {
            appendType = "ROLE";
            appendUID = userContext.roleId;
        } else if (+param.curQuestion.number === 16) {
            appendType = "ACTIVITY";
            appendUID = userContext.activityId;
        } else if (+param.curQuestion.number === 29) {
            appendType = "CESS";
            appendUID = userContext.cessId;
        } else if (+param.curQuestion.number === 33) {
            appendType = "STEP";
            appendUID = userContext.stepId;
        }

        if (param.curQuestion.hiagram !== undefined) {
            appendType = param.curQuestion.hiagram.type;
            appendUID = param.curQuestion.hiagram.typeId;
        }
        params.append("appendType", appendType.toString());
        params.append("appendUid", appendUID.toString());

        const stakeres: any = await GetAllStakeList(params);
        if (stakeres !== null && stakeres.data !== null && stakeres.data.data !== null) {
            let data = stakeres.data.data;
            setAllStakeData(data);
        }
        setLoading(false);
    }

    async function submitForm(values: any, resetForm: any) {
        setBtnLoading(true);
        try {
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            let isFromExisting = false;
            if (+param.curQuestion.number === 10) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 16) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 29) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            } else if (+param.curQuestion.number === 33) {
                appendType = "STEP";
                appendUID = userContext.stepId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }

            if (values.id > 0 && values.mappingId === 0) {
                let curStake: any = allStakeData.find((x: any) => x.uid === values.id);
                if (curStake !== undefined && curStake !== null) {
                    if (values.firstName === curStake.first_name && values.lastName === curStake.last_name
                        && values.organization === curStake.org_name && values.department === curStake.department_name
                        && values.contact === curStake.contact) {
                        isFromExisting = true;
                    } else {
                        values.id = 0;
                    }
                }
            }

            let requestData = {
                stakeUid: values.id,
                stakeMappingUid: values.mappingId,
                hiagramUid: userContext.hiagramId,
                raci: values.raci,
                firstName: values.firstName,
                lastName: values.lastName,
                organization: values.organization,
                department: values.department,
                role: values.role,
                contact: values.contact,
                description: values.description,
                appendToType: appendType,
                appendUid: appendUID,
                isDeleted: false,
                dataManagedBy: userContext.userId
            }


            let requestDataForTeamActivity = {
                stakeUid: values.id,
                stakeMappingUid: values.mappingId,
                hiagramUid: userContext.hiagramId,
                raci: values.raci,
                firstName: values.firstName,
                lastName: values.lastName,
                organization: values.organization,
                department: values.department,
                role: values.role,
                contact: values.contact,
                description: values.description,
                appendToType: appendType,
                appendUid: appendUID,
                isDeleted: false,
                dataManagedBy: userContext.userId,
                appendToTypeTeamProj: param.parentNodeName !== undefined ? param.parentNodeName : (userContext.isProj.toString() === "true" ? "PROJ" : "TEAM"),
                appendUidTeamProj: param.parentNodeId !== undefined ? param.parentNodeId : userContext.teamProjId,
            }
            let data: any;

            if (+userContext.tempUid === 3) {
                if (appendType === "PROJ" || appendType === "TEAM") {
                    if (isFromExisting) {
                        data = await CreateStakeMapping(requestData);
                    } else if (values.id !== null && values.id > 0) {
                        data = await UpdateStake(requestData);
                    } else {
                        data = await CreateStake(requestData);

                    }
                } else {

                    if (isFromExisting) {
                        data = await CreateStakeMapping(requestData);
                    } else if (values.id !== null && values.id > 0) {
                        data = await UpdateStake(requestData);
                    } else {
                        data = await CreateStake(requestData);

                    }

                    // hp start coding also adding team/proj when activity stake add
                    // if (isFromExisting) {
                    //     data = await CreateStakeMappingForTeamProjActivity(requestDataForTeamActivity);
                    // } else if (values.id !== null && values.id > 0) {
                    //     data = await UpdateStakeForTeamProjActivity(requestDataForTeamActivity);
                    // } else {
                    //     data = await CreateStakeForTeamProjActivity(requestDataForTeamActivity);
                    // }
                    // hp end
                }
            }
            else {
                if (isFromExisting) {
                    data = await CreateStakeMapping(requestData);
                } else if (values.id !== null && values.id > 0) {
                    data = await UpdateStake(requestData);
                } else {
                    data = await CreateStake(requestData);
                }
            }
            setFormInitial(formInitialValues);
            resetForm();
            param.onSubmitClose();

            updateUserContext({
                ...userContext,
                isItemCountUpdated: true
            });

        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    const handleClickAdd = (id: any) => {
        let curStake: any = allStakeData.find((x: any) => x.uid === id);
        const formInitial = {
            id: curStake.uid,
            raci: "",
            firstName: curStake.first_name,
            lastName: curStake.last_name,
            organization: curStake.org_name,
            department: curStake.department_name,
            role: "",
            contact: curStake.contact,
            description: "",
            mappingId: 0,
            appendToType: "",
            appendUid: 0,
            isRaciDisabled: isRaciDisabled
        };
        setFormInitial(formInitial);
    }

    //validation
    const formValidation = Yup.object().shape({
        firstName: Yup.string().required("First name is required."),
        organization: Yup.string().required("Organization is required."),
        // department: Yup.string().required("Department is required."),
        // description: Yup.string().required("Description is required."),

        description: Yup.string()
            .when("param.isOpenFromTeamProj", {
                is: false,
                then: Yup.string(),
                otherwise: Yup.string().required("Description is required."),
            }),
        isRaciDisabled: Yup.boolean(),
        role: Yup
            .string()
            .when("isRaciDisabled", {
                is: false,
                then: Yup.string().required("Role is required.")
            }),
        // if raciCondition true it will goes then { need to clarify condition }
        // raci: Yup
        //     .string()
        //     .when('isRaciDisabled', {
        //         is:  isRaciDisabled ,
        //         then: Yup.string().required("Raci is required."), 
        //         otherwise: Yup.string(),
        //     })


        //   raci: Yup
        //     .string()
        //     .when('isRaciDisabled', {
        //         is: true,
        //         then: Yup.boolean().required("Raci is required."),
        //         // otherwise: Yup.string()
        //       })
        raci: Yup
            .string()
            .when("isRaciDisabled", {
                is: false,
                then: Yup.string().required("Raci is required."),
                otherwise: Yup.string(),
            }),

    });


    useEffect(() => {
        if (param.isOpen) {
            let initialValues = formInitialValues;
            if (+param.curQuestion.number === 16 || +param.curQuestion.number === 29 || +param.curQuestion.number === 33) {
                setRaciDisabled(false);
                initialValues.isRaciDisabled = false;
                if (+userContext.tempUid === 3) {
                    setRaciDisabled(true);
                    initialValues.isRaciDisabled = true;
                }
            } else {
                setRaciDisabled(true);
                initialValues.isRaciDisabled = true;
            }

            setFormInitial(initialValues);
            fetchRACI();
            getAutoCompleteData();
            if (param.id > 0) {
                fetchStake(param.id, param.mappingId, initialValues.isRaciDisabled);
            } else {
                fetchStakeList();
            }
        }

    }, [param.isOpen]);

    return (
        <Formik
            enableReinitialize
            initialValues={formInitial}
            validationSchema={formValidation}
            onSubmit={(values: any, { resetForm }) => {
                submitForm(values, resetForm);
            }}
        >{(props: any) => {
            return (
                <>
                    <Grid container className="main-grid-model-stake-add">
                        <Grid className="border-grid-one" item xs={param.isUpdate === true ? 12 : 6} paddingRight={param.isUpdate === true ? "30px" : "50px"}>
                            <Form onSubmit={props.handleSubmit} noValidate>
                                <Grid container>
                                    <Grid className="header-left" sx={{ display: "flex", padding: 1 }} item xs={12}>
                                        <img loading="lazy" alt="imprend" className='icon-stakeholders' src={Stakeholders} />
                                        {param.isUpdate === true ?
                                            <Typography className="icon-text">Edit people</Typography>
                                            : <Typography className="icon-text">Add new people</Typography>
                                        }
                                        {param.QuickView === false ?
                                            <Tooltip title="Guidance">
                                                <IconButton
                                                    aria-label="Guidance"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    onClick={handlePopoverOpenForStake}
                                                    disableRipple
                                                >
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip> : <></>}
                                    </Grid>
                                    {/* {param.isUpdate === true ?
                                        <Grid item xs={2} className="clear-icon-edit">
                                            <Button
                                                disableRipple
                                                className="btn-cancel"
                                                onClick={() => {
                                                    setFormInitial(formInitialValues);
                                                    props.resetForm();
                                                    param.onClose();
                                                }}>
                                                <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                            </Button>
                                        </Grid> :
                                        <></>} */}
                                </Grid>
                                {/* {isRaciDisabled || +userContext.tempUid === 3 ||  && +param.curQuestion.number === 16 ? <></> : <Typography className="text-typography-required">(required)</Typography>} */}

                                {+userContext.tempUid === 3 && (+param.curQuestion.number === 16 || param.isOpenFromTeamProj === true) ? <></> :
                                    <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 5 }}>
                                        <Box className="text-grid-textfield">
                                            <Typography className="text-typography">RACI</Typography>

                                            {isRaciDisabled === false ? <Typography className="text-typography-required">(required)</Typography> : <></>}


                                        </Box>
                                        <Box className="textfield-grid" >
                                            {isDataLoading ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                                <Select
                                                    //className="basic-single"
                                                    classNamePrefix="select"
                                                    styles={
                                                        props.values.raci === "" &&
                                                            props.errors.raci &&
                                                            props.touched.raci
                                                            ? selectStyles
                                                            : selectFontFamily
                                                    }
                                                    isDisabled={isRaciDisabled}
                                                    //isLoading={isLoading}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    // placeholder={'Contrary to popular belief'}
                                                    placeholder={''}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    value={getSingleSelected(
                                                        props.values.raci,
                                                        raciData
                                                    )}
                                                    onChange={(e, value) => {
                                                        props.setFieldValue(
                                                            "raci",
                                                            e !== null ? e.value : "",
                                                            false
                                                        );
                                                    }}
                                                    options={raciData}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: mainTheme.palette.primary.main,
                                                            primary25: '#FEF4F4',
                                                            primary50: '#FEF4F4',
                                                        },
                                                    })}
                                                />}
                                            {isRaciDisabled === false && props.values.raci === "" &&
                                                props.errors.raci &&
                                                props.touched.raci && (
                                                    <Typography
                                                        className={
                                                            props.values.raci === "" &&
                                                                props.errors.raci &&
                                                                props.touched.raci
                                                                ? "validation-message text-input error"
                                                                : "validation-message text-input"
                                                        }
                                                    >
                                                        {props.errors.raci}
                                                    </Typography>
                                                )}
                                        </Box>
                                    </Grid>

                                }

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">First name</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="firstName"
                                                    fullWidth
                                                    // placeholder='Contrary to popular belief'
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedFname(false)}
                                                    onFocus={() => setFocusedFname(true)}
                                                    placeholder='First name'
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("firstName")}
                                                    value={props.values.firstName}
                                                    error={
                                                        props.errors.firstName && props.touched.firstName
                                                            ? true
                                                            : false
                                                    }
                                                    className="item-text-fields"
                                                >
                                                </TextField>
                                                {isFocusedFname && <Typography className="textfield-counter">{(props.values.firstName !== null ? props.values.firstName.length : 0) + "/" + 90}</Typography>}
                                            </Box>
                                        }
                                        {props.errors.firstName && props.touched.firstName ?
                                            <Typography className="validation-message" >
                                                {props.errors.firstName}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Last name</Typography>
                                    </Box>
                                    <Box className="textfield-grid" >
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="lastName"
                                                    fullWidth
                                                    // placeholder='Belief'
                                                    placeholder='Last name'
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedLname(false)}
                                                    onFocus={() => setFocusedLname(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("lastName")}
                                                    value={props.values.lastName}
                                                    className="item-text-fields"
                                                >
                                                </TextField>
                                                {isFocusedLname && <Typography className="textfield-counter">{(props.values.lastName !== null ? props.values.lastName.length : 0) + "/" + 90}</Typography>}
                                            </Box>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Contact details</Typography>
                                    </Box>
                                    <Box className="textfield-grid" >
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="contact"
                                                    fullWidth
                                                    // placeholder='Contrary to popular belief'
                                                    placeholder='Contact details'
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedContact(false)}
                                                    onFocus={() => setFocusedContact(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("contact")}
                                                    value={props.values.contact}
                                                    className="item-text-fields"
                                                >
                                                </TextField>
                                                {isFocusedContact && <Typography className="textfield-counter">{(props.values.contact !== null ? props.values.contact.length : 0) + "/" + 90}</Typography>}
                                            </Box>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Organization</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid" >
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            // <TextField
                                            //     id="organization"
                                            //     fullWidth
                                            //     // placeholder='Contrary to popular belief'
                                            //     placeholder='Organization'
                                            //     autoComplete="off"
                                            //     onChange={props.handleChange.bind("organization")}
                                            //     value={props.values.organization}
                                            // >
                                            // </TextField>
                                            <Autocomplete
                                                id="organization"
                                                //autoHighlight={true}
                                                open={openorganization}
                                                onOpen={() => {
                                                    if (props.values.organization.length > 0) {
                                                        setOpenorganization(true);
                                                    }
                                                }}
                                                onClose={() => setOpenorganization(false)}
                                                inputValue={props.values.organization}
                                                filterOptions={filterOptions}
                                                onInputChange={(e, value, reason) => {
                                                    if (value.length <= 90) {
                                                        props.setFieldValue(
                                                            "organization",
                                                            value,
                                                            true
                                                        );
                                                        if (value.length < 2) {
                                                            setOpenorganization(false);
                                                        }
                                                    }
                                                }}
                                                freeSolo
                                                disableClearable
                                                options={organizationData.map((option: any) => option.organization)}
                                                renderInput={(params) =>
                                                    <Box className="description-textfield-box">
                                                        <TextField
                                                            {...params}
                                                            placeholder='Organization'
                                                            className="organization-input item-text-fields"
                                                            // inputProps={{ maxLength: 90 }}
                                                            onBlur={() => setFocusedOrganize(false)}
                                                            onFocus={() => setFocusedOrganize(true)}
                                                            error={
                                                                props.errors.organization && props.touched.organization
                                                                    ? true
                                                                    : false
                                                            }
                                                        //onChange={props.handleChange.bind("organization")}
                                                        />
                                                        {isFocusedOrganize && <Typography className="textfield-counter">{(props.values.organization !== null ? props.values.organization.length : 0) + "/" + 90}</Typography>}
                                                    </Box>
                                                }
                                            />

                                        }
                                        {props.errors.organization && props.touched.organization ?
                                            <Typography className="validation-message" >
                                                {props.errors.organization}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Department</Typography>
                                        {/* <Typography className="text-typography-required">(required)</Typography> */}
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            // <TextField
                                            //     id="department"
                                            //     fullWidth
                                            //     // placeholder='Contrary to popular belief'
                                            //     placeholder='Department'
                                            //     autoComplete="off"
                                            //     onChange={props.handleChange.bind("department")}
                                            //     value={props.values.department}
                                            //     error={
                                            //         props.errors.department && props.touched.department
                                            //             ? true
                                            //             : false
                                            //     }
                                            // >
                                            // </TextField>
                                            <Autocomplete
                                                id="department"
                                                open={openDepartment}
                                                onOpen={() => {
                                                    if (props.values.department.length > 0) {
                                                        setOpenDepartment(true);
                                                    }
                                                }}
                                                onClose={() => setOpenDepartment(false)}
                                                inputValue={props.values.department}
                                                filterOptions={filterOptions}
                                                onInputChange={(e, value, reason) => {
                                                    if (value.length <= 90) {
                                                        props.setFieldValue(
                                                            "department",
                                                            value,
                                                            true
                                                        );
                                                        if (value.length < 2) {
                                                            setOpenDepartment(false);
                                                        }
                                                    }
                                                }}
                                                freeSolo
                                                disableClearable
                                                options={departmentData.map((option: any) => option.department)}
                                                renderInput={(params) =>
                                                    <Box className="description-textfield-box">
                                                        <TextField
                                                            {...params}
                                                            placeholder='Department'
                                                            className="department-input item-text-fields"
                                                            //inputProps={{ maxLength: 90 }}
                                                            onBlur={() => setFocusedDepartment(false)}
                                                            onFocus={() => setFocusedDepartment(true)}
                                                            error={
                                                                props.errors.department && props.touched.department
                                                                    ? true
                                                                    : false
                                                            } />
                                                        {isFocusedDepartment && <Typography className="textfield-counter">{(props.values.department !== null ? props.values.department.length : 0) + "/" + 90}</Typography>}
                                                    </Box>
                                                }
                                            />
                                        }
                                        {/* {props.errors.department && props.touched.department ?
                                            <Typography className="validation-message" >
                                                {props.errors.department}
                                            </Typography> : <></>} */}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Role</Typography>
                                        {!isRaciDisabled ? <Typography className="text-typography-required">(required)</Typography> : <></>}
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="role"
                                                    fullWidth
                                                    // placeholder='Contrary to popular belief'
                                                    placeholder='Role'
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedRole(false)}
                                                    onFocus={() => setFocusedRole(true)}
                                                    onChange={props.handleChange.bind("role")}
                                                    value={props.values.role}
                                                    error={
                                                        props.errors.role && props.touched.role
                                                            ? true
                                                            : false
                                                    }
                                                    className="item-text-fields">
                                                </TextField>
                                                {isFocusedRole && <Typography className="textfield-counter">{(props.values.role !== null ? props.values.role.length : 0) + "/" + 90}</Typography>}
                                            </Box>}
                                        {props.errors.role && props.touched.role ?
                                            <Typography className="validation-message" >
                                                {props.errors.role}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography-decription">How is this person involved here?</Typography>
                                        {param.isOpenFromTeamProj === true ? <></> : <Typography className="text-typography-required">(required)</Typography>}
                                    </Box>
                                    <Box className="textfield-grid" >
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="description"
                                                    multiline={true}
                                                    rows={3}
                                                    className="description-textfield item-text-fields"
                                                    fullWidth
                                                    placeholder='How is this person involved here?'
                                                    inputProps={{ maxLength: 500 }}
                                                    onBlur={() => setFocusedDescription(false)}
                                                    onFocus={() => setFocusedDescription(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("description")}
                                                    value={props.values.description}
                                                    error={
                                                        props.errors.description && props.touched.description
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                </TextField>
                                                {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 500}</Typography>}
                                            </Box>
                                        }
                                        {param.isOpenFromTeamProj === true ? <Typography>hi</Typography> : ((props.errors.description && props.touched.description) ?
                                            <Typography className="validation-message" >
                                                {props.errors.description}
                                            </Typography> : <></>)}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="button-main-grid">
                                    <Grid>
                                        <Button className='button-cancel'
                                            onClick={() => {
                                                setFormInitial(formInitialValues);
                                                props.resetForm();
                                                param.onClose();
                                            }}>Cancel</Button>
                                    </Grid>
                                    <Grid sx={{ paddingLeft: 4 }}>
                                        {/* <Button variant="contained" className='button-submit' type="submit">Submit</Button> */}
                                        <LoadingButton
                                            loading={isBtnLoading}
                                            variant="contained"
                                            type="submit"
                                            className='button-submit'
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Grid>
                                </Grid>

                            </Form>
                        </Grid>
                        {param.isUpdate !== true ?
                            <Grid container className="border-grid-two" spacing={1} item xs={6}>
                                <Grid item sx={{ padding: 5 }} className="table-main" xs={12}>
                                    <Grid container className="header-two" sx={{ display: "flex", padding: 1 }} >
                                        <Grid item xs={12}>
                                            <Typography className="clear-text"><b>People already in hiagram</b></Typography>
                                        </Grid>
                                        {/* <Grid item xs={2}>
                                            <Button
                                                disableRipple
                                                className="btn-cancel"
                                                onClick={() => {
                                                    setFormInitial(formInitialValues);
                                                    props.resetForm();
                                                    param.onClose();
                                                }}>
                                                <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                    <Grid container paddingTop={5}>
                                        <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                            <Table className="table-css" aria-label="customized table">
                                                <TableHead sx={{ backgroundColor: "#56506D" }}>
                                                    <TableRow>
                                                        <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Organization</StyledTableCell>
                                                        <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>First name</StyledTableCell>
                                                        <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Last name</StyledTableCell>
                                                        <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Department</StyledTableCell>
                                                        {/* <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}>Add</StyledTableCell> */}
                                                        <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}></StyledTableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={{ minWidth: "115px" }}>
                                                    <>
                                                        {isLoading === true ? (
                                                            _.times(4, (i) => (
                                                                <TableRow key={i}>
                                                                    <TableCell colSpan={5}>
                                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={25} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))) : allStakeData.length > 0 ? allStakeData.map((row: any, index: any) => {
                                                                return (<>
                                                                    <StyledTableRow sx={{ fontSize: 12 }} key={index}>
                                                                        <StyledTableCell align="left" className="text-show-less">{row.org_name}</StyledTableCell>
                                                                        <StyledTableCell align="left" className="text-show-less">{row.first_name}</StyledTableCell>
                                                                        <StyledTableCell align="left" className="text-show-less">{row.last_name}</StyledTableCell>
                                                                        <StyledTableCell align="left" className="text-show-less">{row.department_name}</StyledTableCell>
                                                                        <StyledTableCell className="btn-add">
                                                                            <Tooltip title='Add'>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    className='edit-btn'
                                                                                    onClick={() => handleClickAdd(row.uid)}
                                                                                >
                                                                                    <AddIcon ></AddIcon>
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                </>
                                                                );
                                                            }) : <>
                                                            <TableRow key={1}>
                                                                <TableCell colSpan={5} align='center'>
                                                                    None added yet!
                                                                </TableCell>
                                                            </TableRow></>}
                                                    </>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>

                            </Grid>
                            : <></>}

                        <Box className="close-btn-box">
                            <Button
                                disableRipple
                                className="btn-cancel"
                                onClick={() => {
                                    setFormInitial(formInitialValues);
                                    props.resetForm();
                                    param.onClose();
                                }}>
                                <ClearIcon className="close-btn-icon" />
                            </Button>
                        </Box>
                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={openGuidanceDialog}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: { width: '400px', padding: '15px', borderRadius: '10px' },
                            }}
                            onClose={handlePopoverCloseForStake}
                            disableRestoreFocus
                        >
                            <Typography className="popover-guidance-body" dangerouslySetInnerHTML={{ __html: guidance }}></Typography>
                        </Popover>
                    </Grid>
                </>
            );
        }}
        </Formik>
    );
}
const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0b0b45" : "1px solid #d32f2f",
    }),
};

const selectFontFamily = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        fontFamily: 'Roboto'
    })
};