import { Grid, TextField, Button, Fab, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import trashIcon from '../../assets/icons/trash-icon-for-acronym.svg'
import "./AcronymAdd.scss";

export default function AcronymAdd() {
    return (
        <>
            <Grid container className="main-acronymadd">


                <Grid container >
                    <Grid sx={{ paddingRight: 2 }} item xs={6}>
                        <Typography className="lable-textfield"><b>Expanded</b></Typography>
                    </Grid>
                    <Grid sx={{ paddingLeft: 2 }} item xs={6}>
                        <Typography className="lable-textfield"><b>Description</b></Typography>
                    </Grid>
                </Grid>
                {/* <Grid item xs={2} className="name-textfield" >
            </Grid>
            <Grid sx={{ paddingRight: 2 }} item xs={5}>
                <Typography className="lable-textfield"><b>Expanded</b></Typography>
            </Grid>
            <Grid sx={{ paddingLeft: 2 }} item xs={5}>
                <Typography className="lable-textfield"><b>Description</b></Typography>
            </Grid> */}
                <Grid container>
                    <Grid item xs={1} className="name-textfield" >
                        <Typography><b>XYZ =</b></Typography>
                    </Grid>
                    <Grid sx={{ paddingRight: 2 }} item xs={5} >
                        <TextField
                            id='organization'
                            sx={{ marginTop: 2 }}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid sx={{ paddingLeft: 2 }} item xs={5} className="textfield-grid">
                        <TextField
                            id='organization'
                            sx={{ marginTop: 2 }}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "start" }} className="delete-icon">
                        <Typography
                       // variant='contained'
                         //className='delete-btn'
                        >
                            <img src={trashIcon} />
                        </Typography>
                    </Grid>

                    <Grid item xs={1} className="name-textfield" >
                        <Typography><b>CDA =</b></Typography>
                    </Grid>
                    <Grid sx={{ paddingRight: 2 }} item xs={5}>
                        <TextField
                            id='organization'
                            sx={{ marginTop: 2 }}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid sx={{ paddingLeft: 2 }} item xs={5} className="textfield-grid">
                        <TextField
                            id='organization'
                            sx={{ marginTop: 2 }}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "start" }} className="delete-icon">
                        <Typography
                            // variant='contained'
                         //className='delete-btn'
                        >
                            <img src={trashIcon} />
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className="name-textfield" >
                        <Typography><b>ABC =</b></Typography>
                    </Grid>
                    <Grid sx={{ paddingRight: 2 }} item xs={5}>
                        <TextField
                            id='organization'
                            sx={{ marginTop: 2 }}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid sx={{ paddingLeft: 2 }} item xs={5} className="textfield-grid">
                        <TextField
                            id='organization'
                            sx={{ marginTop: 2 }}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "start" }} className="delete-icon">
                        <Typography
                            // variant='contained'
                         //className='delete-btn'
                        >
                            <img src={trashIcon} />
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className="name-textfield" >
                        <Typography><b>KKZ =</b></Typography>
                    </Grid>
                    <Grid sx={{ paddingRight: 2 }} item xs={5}>
                        <TextField
                            id='organization'
                            sx={{ marginTop: 2 }}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid sx={{ paddingLeft: 2 }} item xs={5} className="textfield-grid">
                        <TextField
                            id='organization'
                            sx={{ marginTop: 2 }}
                            fullWidth
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "start" }} className="delete-icon">
                        <Typography
                            // variant='contained'
                         //className='delete-btn'
                        >
                            <img src={trashIcon} />
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
        </>
    );
}