import { Grid, DialogContentText, DialogActions, Button, TableContainer, Paper, Table, TableHead, TableRow, Tooltip, TableCell, TableBody, tableCellClasses, styled, Typography, Skeleton, Link, Box, TooltipProps, tooltipClasses } from "@mui/material";
import * as React from "react";
import editIcon from '../../../assets/icons/edit-2.svg'
import trashIcon from '../../../assets/icons/trash.svg'
import ClearIcon from '@mui/icons-material/Clear';
import Doc from '../../../assets/icons/doc.svg'
import Clipboard from '../../../assets/icons/clipboard-text.svg'
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import { AlertType } from "../../../shared/AlertType";
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import ItemDocAddModel from './ItemDocAddModel'
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { TransitionProps } from '@mui/material/transitions';
import "./ItemDocAdd.scss";
import { PreferencesContext } from "../../../PreferenceContext";
import { CreateQusAnswer, DeleteDoc, GetDocList, GetQuestionAnswer } from "../../../services/DocService";

interface IDocDetail {
    id: any;
    docMappingId: any;
    // type: any;
    name: any;
    fileName: any;
    location: any;
    // link: any;
    description: any;
}

const initDocDetail: IDocDetail = {
    id: 0,
    docMappingId: 0,
    // type: "",
    name: "",
    fileName: "",
    location: "",
    // link: "",
    description: ""
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4D81B4",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#F7F9FB",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        describeChild={true}
        {...props}
        classes={{ popper: className }}
        slotProps={{ popper: { disablePortal: true } }}
        PopperProps={{
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['right', 'left', 'top', 'bottom'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundariesElement: 'window',
                    },
                },
            ],
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        minWidth: 500,
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: '10px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    [`& .${tooltipClasses.popper}`]: {
        marginTop: theme.spacing(1),
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//export default function ItemDocAdd() {
const ItemDocAdd = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editMappingId, setEditMappingId] = useState(0);
    const [questionAnswerId, setQuestionAnswerId] = useState(0);
    // const [typeData, setTypeData] = useState([]);
    const [docList, setDocList] = useState([initDocDetail]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openActionDeleteModal, setActionDeleteModal] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        submitClick() {
            saveAnswer();
        },
        getData() {
            // fetchType();
            fetchDocList();
            fetchDocQuestionAnswer();
        }
    }),);

    const handleClickOpen = () => {
        setEditId(0);
        setEditMappingId(0);
        setIsUpdate(false);
        setOpenDialog(true);
    };
    const handleClickOpenForEdit = (id: any, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setIsUpdate(true);
        setOpenDialog(true);
    };

    const handleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialog(false);
    };

    const handleSubmitClose = () => {
        setOpenDialog(false);
        fetchDocList();
        fetchDocQuestionAnswer();
    };

    const handleOpenActionDeleteModal = (id: number, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setActionDeleteModal(true);
    };
    const handleCloseActionDeleteModal = () => {
        setActionDeleteModal(false);
    };

    // async function fetchType() {
    //     try {
    //         const typeGetData: any = await GetTypeList();
    //         if (typeGetData !== undefined && typeGetData !== null && typeGetData.data !== null && typeGetData.data.data !== null && typeGetData.data.data.data !== null) {
    //             setTypeData(typeGetData.data.data.data);
    //         }
    //     }
    //     catch (error: any) {
    //         if (error) {
    //             updateUserContext({
    //                 ...userContext,
    //                 isAlert: true,
    //                 alertMessage: error.response.message,
    //                 alertType: AlertType.Error,
    //             });
    //         }
    //     }
    // }

    async function fetchDocList() {
        try {
            setLoading(true);
            let appendType = "";
            let appendUID = 0;
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            if (+param.curQuestion.number === 6) {
                appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
                appendUID = userContext.teamProjId;
            } else if (+param.curQuestion.number === 11) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 18) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 26) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            } else if (+param.curQuestion.number === 35) {
                appendType = "STEP";
                appendUID = userContext.stepId;
            } else if (+param.curQuestion.number === 0) {
                appendType = "NONE";
                appendUID = 0;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            params.append("appendType", appendType.toString());
            params.append("appendUid", appendUID.toString());
            const docres: any = await GetDocList(params);
            if (docres !== null && docres.data !== null && docres.data.data !== null) {
                let data = docres.data.data;
                let list: any[] = [];
                data.forEach((element: any) => {
                    let item: IDocDetail = {
                        id: element.uid,
                        // type: element.type,
                        name: element.name,
                        fileName: element.file_name,
                        location: element.location,
                        // link: element.link,
                        description: element.description,
                        docMappingId: element.docMapping === undefined ? 0 : element.docMapping[0].uid,
                    }
                    list.push(item);
                });
                setDocList(list);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function deleteDoc() {
        setBtnLoading(true);
        try {
            const doc: any = await DeleteDoc(editId, {
                huid: userContext.hiagramId,
                mappingUid: editMappingId,
                is_deleted: true,
                row_managed_by: userContext.userId
            });
            if (doc.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: doc.message,
                    alertType: AlertType.Success,
                    isItemCountUpdated: true
                });
                // handleCloseActionDeleteModal();
                setActionDeleteModal(false);
                fetchDocList();
                fetchDocQuestionAnswer();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function fetchDocQuestionAnswer() {
        try {
            setLoading(true);
            let projUID = 0;
            let teamUID = 0;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            params.append("tuid", teamUID.toString());
            params.append("puid", projUID.toString());
            params.append("ruid", userContext.roleId.toString());
            params.append("auid", userContext.activityId.toString());
            params.append("cuid", userContext.cessId.toString());
            const questionAnswer: any = await GetQuestionAnswer(params);
            if (questionAnswer !== null && questionAnswer.data !== null && questionAnswer.data.data !== null) {
                let data = questionAnswer.data.data;
                setQuestionAnswerId(data.uid);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function saveAnswer() {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            let projUID = 0;
            let teamUID = 0;
            let roleUID = userContext.roleId;
            let cessUID = userContext.cessId;
            let stepUID = userContext.stepId;
            let activityUID = userContext.activityId;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            try {
                let requestData = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: questionAnswerId,
                    teamUid: teamUID,
                    projUid: projUID,
                    roleUid: roleUID,
                    cessUid: cessUID,
                    stepUid: stepUID,
                    activityUid: activityUID,
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }

                let data: any;
                data = await CreateQusAnswer(requestData);
                if (data !== null && data.data === true) {
                    param.changeNextQuestion();
                }
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            param.changeNextQuestion();
        }
    }
    // useEffect(() => {
    //     fetchDocList();
    // }, []);

    useEffect(() => {
    }, [docList]);

    return (
        <Grid container className="doc-list-grid" >
            {param.QuickView === false ?
                <Grid item xs={12} className="doc-icon-group" ><img loading="lazy" alt="imprend" className='icon-docholders' src={Doc} />
                    <Typography className="doc-icon-name">Documents</Typography>
                </Grid>
                : <Grid item xs={12} className="heading-container-grid-documnet">
                    <Grid className="header-css" item xs={12}>
                        <img loading="lazy" alt="imprend" className='header-icon' src={Doc} />
                        <Typography className="icon-text">Documents</Typography>
                    </Grid>
                    {/* <Grid item xs={1} className="clear-icon-grid">
                        <Button
                            disableRipple
                            className="btn-cancel"
                            onClick={param.onClose}
                        >
                            <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                        </Button>
                    </Grid> */}
                </Grid>}
            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {/* <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Type</StyledTableCell> */}
                            {/* <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Name</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Filename</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Location</StyledTableCell> */}
                            {/* <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Link</StyledTableCell> */}
                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 12 }}>Name</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 12 }}>Filename</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '25%', fontSize: 12 }}>Location/Link</StyledTableCell>
                            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell align="center" sx={{ width: '25%', fontSize: 12 }}>Action</StyledTableCell> : <></>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {isLoading === true ? (
                                _.times(4, (i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={6}>
                                            <Skeleton variant="rounded" width='auto' height={25} />
                                        </TableCell>
                                    </TableRow>
                                ))) : docList.filter((x) => x.id > 0).length > 0 ? (docList && docList.map((row: any, index: any) => {
                                    const placement = index < 3 ? 'bottom' : 'top';
                                    return (
                                        <HtmlTooltip
                                            key={index}
                                            placement={placement}
                                            title={
                                                <React.Fragment>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.fileName)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Filename:</b> {row.fileName}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.location)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Location:</b> {row.location.includes("http") ?
                                                        <Link href={row.location} underline="hover" target="_blank" rel="noopener">
                                                            {row.location}
                                                        </Link> : row.location}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                </React.Fragment>
                                            }>
                                            <StyledTableRow
                                                sx={{ fontSize: 12, height: 50, cursor: 'pointer' }}
                                                key={row.id}>
                                                {/* <StyledTableCell align="left">{getNameSelected(
                                                row.type,
                                                typeData
                                            )}</StyledTableCell> */}
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.name}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.fileName}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">
                                                    {row.location.includes("http") ?
                                                        <Link href={row.location} underline="hover" target="_blank" rel="noopener">
                                                            {row.location}
                                                        </Link> : row.location}

                                                </StyledTableCell>
                                                {/* <StyledTableCell align="left">{row.link}</StyledTableCell> */}
                                                {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell
                                                    sx={{ minWidth: "115px" }}
                                                >
                                                    <Grid container spacing={2} className='btn-grid'>
                                                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                            <Tooltip title='Edit'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='edit-btn'
                                                                    onClick={() => handleClickOpenForEdit(row.id, row.docMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='edit-btn-logo' src={editIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                            <Tooltip title='Delete'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='delete-btn'
                                                                    onClick={() => handleOpenActionDeleteModal(row.id, row.docMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='delete-btn-logo' src={trashIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell> : <></>}
                                            </StyledTableRow>
                                        </HtmlTooltip>
                                    )
                                })) : (
                                <TableRow key={1}>
                                    <TableCell colSpan={6} align='center'>
                                        None added yet!
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    </TableBody>
                </Table>

            </TableContainer>
            {/* {+param.curQuestion.number === 0 || param.QuickView === true ?   */}
            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ?
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Button className="doc-btn"
                        onClick={handleClickOpen}
                        startIcon={<AddIcon sx={{ color: "#4D81B4" }} />}
                        endIcon={<img loading="lazy" alt="imprend" className='icon-stakeholders' src={Clipboard} />}
                    >
                        Add doc
                    </Button>
                </Grid> : <></>}
            <Dialog
                maxWidth={isUpdate === true ? "md" : "xl"}
                fullWidth={true}
                open={openDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ paddingBottom: "50px" }}>
                    <ItemDocAddModel
                        QuickView={param.QuickView}
                        isOpen={openDialog}
                        isUpdate={isUpdate}
                        id={editId}
                        mappingId={editMappingId}
                        onClose={handleClose}
                        onSubmitClose={handleSubmitClose}
                        curQuestion={param.curQuestion}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openActionDeleteModal}
                onClose={handleCloseActionDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this Document?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => deleteDoc()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseActionDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {param.QuickView === true ?
                <Box className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={param.onClose}
                    >
                        <ClearIcon className="close-btn-icon" />
                    </Button>
                </Box> : <></>}
        </Grid >
    );
});
export default ItemDocAdd;
