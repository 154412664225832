import React, { useContext, useEffect, useState } from 'react';
import './HiagramDetail.scss';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Skeleton, TextField, Typography } from '@mui/material';
import profile from '../../../assets/icons/profile.svg';
import editIcon from '../../../assets/icons/edit-2.svg';
import tickicon from "../../../assets/icons/tick.svg";
import ClearIcon from '@mui/icons-material/Clear';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { PreferencesContext } from '../../../PreferenceContext';
import { AlertType } from '../../../shared/AlertType';
import { HiagramGetAllDetail, UpdateHiagramData } from '../../../services/HiagramService';
import { LoadingButton } from '@mui/lab';

const formInitialValues = {
    // id: 0,
    name: "",
    summary: "",
};

const formValidation = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    summary: Yup.string().required("Summary is required."),

});

let initdata: any;

export default function HiagramDetail(params: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [hiagramDetailData, setHiagramDetailData] = useState(formInitialValues);
    const [hiagramData, setHiagramData] = useState(initdata);

    const [isLoading, setLoading] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [isDeleteButtonLoading, setDeleteButtonLoading] = useState(false);

    const [isDataUpdated, setDataUpdated] = useState(false);

    const [isFieldNameEdit, setFieldNameEdit] = useState(false);
    const [isFieldNameSave, setFieldNameSave] = useState(false);

    const [isFieldSummaryEdit, setFieldSummaryEdit] = useState(false);
    const [isFieldSummarySave, setFieldSummarySave] = useState(false);

    const [isFocusedName, setFocusedName] = useState(false);
    const [isFocusedSummary, setFocusedSummary] = useState(false);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleCloseModal = () => {
        if (isFieldSummarySave || isFieldNameSave) {
            setOpenDeleteModal(true);
        } else {
            params.onClose(isDataUpdated);
        }
    };

    const handleCloseDeleteModal = (isCheck: any) => {
        if (isCheck) {
            params.onClose(isDataUpdated);
        }
        setOpenDeleteModal(false);
    };

    async function editName() {
        setFieldNameEdit(true);
    }

    async function cancelName(props: any) {
        setDeleteButtonLoading(true);
        props.setFieldValue(
            `name`,
            hiagramDetailData.name,
            true
        );
        setFieldNameEdit(false);
        setFieldNameSave(false);
        setDeleteButtonLoading(false);
    }

    async function editSummary() {
        setFieldSummaryEdit(true);
    }

    async function cancelDescription(props: any) {
        setDeleteButtonLoading(true);
        props.setFieldValue(
            `summary`,
            hiagramDetailData.summary,
            true
        );
        setFieldSummaryEdit(false);
        setFieldSummarySave(false);
        setDeleteButtonLoading(false);
    }

    async function fetchData() {
        setLoading(true);
        try {
            let urlparams = new URLSearchParams();
            urlparams.append("huid", userContext.hiagramId.toString());
            // urlparams.append("tuid", params.dataId);
            const data: any = await HiagramGetAllDetail(urlparams);
            if (data !== null && data.data !== null && data.data.data !== null) {
                let hiagram: any = data.data.data
                setHiagramData(hiagram);
                if (userContext.isItemCountUpdated === true) {
                    setDataUpdated(true);
                }
                const initialValues = {
                    name: hiagram.hiagramdetail.hiagram_name,
                    summary: hiagram.hiagramdetail.free_text,
                };
                setHiagramDetailData(initialValues);
            }
            setLoading(false);
            updateUserContext({
                ...userContext,
                isItemCountUpdated: false
            });
        }
        catch (error: any) {
            if (error) {
                setLoading(false);
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }


    async function submitForm(values: any) {
        setButtonLoading(true);
        try {
            let requestData = {
                hiagramUid: userContext.hiagramId,
                dataManagedBy: userContext.userId,
                name: values.name,
                summary: values.summary
            }
            let data: any;
            data = await UpdateHiagramData(requestData);

            if (data) {
                updateUserContext({
                    ...userContext,
                    hiagramName: values.name,
                    isAlert: true,
                    alertMessage: "Hiagram updated!",
                    alertType: AlertType.Success,
                });
                setFieldNameEdit(false);
                setFieldNameSave(false);
                setFieldSummaryEdit(false);
                setFieldSummarySave(false);
                setDataUpdated(true);
                // fetchData();
            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Something went wrong!",
                    alertType: AlertType.Error,
                });
            }
            //setLoading(false);
            setButtonLoading(false);
        } catch (error) {
            //setLoading(false);
            setButtonLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Something went wrong!",
                    alertType: AlertType.Error,
                });
            }
        }
        // setTimeout(() => {
        //     updateUserContext({
        //         ...userContext,
        //         isAlert: false,
        //         alertMessage: "",
        //         alertType: '',
        //     });
        // }, 7000);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
    }, [hiagramData]);

    return (
        <>
            <Grid container className="heading-container-grid-hiagram">
                <Grid className="header-css" item xs={11}>
                    <Box className='header-icon-grid'>
                        <img loading="lazy" alt='imprend' className='header-icon' src={profile} />
                    </Box>
                    <Typography className="icon-text">Hiagram summary and final thoughts </Typography>
                </Grid>
                <Grid item xs={1} className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={handleCloseModal}
                    >
                        <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                    </Button>
                </Grid>
            </Grid>

            <Box className='box-form-container-hiagram'>
                <Formik
                    enableReinitialize
                    initialValues={hiagramDetailData}
                    onSubmit={(values: any) => {
                        submitForm(values);
                    }}
                    validationSchema={formValidation}
                >
                    {(props) => {
                        return (
                            <>
                                <Form onSubmit={props.handleSubmit}>
                                    <Grid container className='grid-container'>
                                        <Grid item xs={2} className="text-grid-label">
                                            <Typography className="text-typography">Name</Typography>
                                        </Grid>
                                        <Grid className="textfield-grid" item xs={8}>
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={30} />
                                                : <> {isFieldNameEdit === true ?
                                                    <Box className="description-textfield-box">
                                                        <TextField
                                                            id="name"
                                                            className="text-typography-description"
                                                            fullWidth
                                                            value={props.values.name}
                                                            inputProps={{ maxLength: 100 }}
                                                            placeholder='Name'
                                                            autoComplete="off"
                                                            onBlur={() => setFocusedName(false)}
                                                            onFocus={() => setFocusedName(true)}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `name`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                                if (e.target.value !== null && e.target.value !== undefined) {
                                                                    if (e.target.value !== hiagramDetailData.name) {
                                                                        setFieldNameSave(true);
                                                                    } else {
                                                                        setFieldNameSave(false);
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                        </TextField>
                                                        {isFocusedName && <Typography className="textfield-counter">{(props.values.name !== null ? props.values.name.length : 0) + "/" + 100}</Typography>}
                                                    </Box> : <Typography className="text-typography-description">{props.values.name}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid'>
                                            {userContext.isEditAccess === true ?
                                                <>
                                                    {isFieldNameEdit === false ?
                                                        <Button
                                                            variant='contained'
                                                            className='edit-btn'
                                                            onClick={editName}
                                                        >
                                                            <img loading="lazy" alt='imprend' src={editIcon} />
                                                        </Button> :
                                                        isFieldNameSave === true ?
                                                            <Box className='team-card-button'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='tick-btn'
                                                                    type='submit'
                                                                >
                                                                    {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                </Button>
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete'
                                                                    onClick={() => cancelName(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                            </Box> :
                                                            <Button
                                                                variant='contained'
                                                                className='button-delete'
                                                                onClick={() => cancelName(props)}
                                                            >
                                                                {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                            </Button>
                                                    }</>
                                                : <></>}
                                        </Grid>
                                    </Grid>
                                    <Grid container className='summary-grid grid-container' sx={{ marginTop: 2 }}>
                                        <Grid item xs={2} className="text-grid-label ">
                                            <Typography className="text-typography">Summary</Typography>
                                        </Grid>
                                        <Grid item xs={8} className="textfield-grid">
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={70} />
                                                : <> {isFieldSummaryEdit === true ?
                                                    <Box className="description-textfield-box">
                                                        <TextField
                                                            id="summary"
                                                            multiline={true}
                                                            rows={6}
                                                            className="description-textfield"
                                                            fullWidth
                                                            inputProps={{ maxLength: 5000 }}
                                                            onBlur={() => setFocusedSummary(false)}
                                                            onFocus={() => setFocusedSummary(true)}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `summary`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                                if (e.target.value !== null && e.target.value !== undefined) {
                                                                    if (e.target.value !== hiagramDetailData.summary) {
                                                                        setFieldSummarySave(true);
                                                                    } else {
                                                                        setFieldSummarySave(false);
                                                                    }
                                                                }
                                                            }}
                                                            value={props.values.summary}
                                                            placeholder='Description'
                                                            autoComplete="off"
                                                        >
                                                        </TextField>
                                                        {isFocusedSummary && <Typography className="textfield-counter">{(props.values.summary !== null ? props.values.summary.length : 0) + "/" + 5000}</Typography>}
                                                    </Box>
                                                    : <Typography className="text-typography-description">{props.values.summary}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid'>
                                            {/* {isAuthorize === true && isVisibleEditButtonDescription === true ? */}
                                            {userContext.isEditAccess === true ?
                                                <>
                                                    {isFieldSummaryEdit === false ?
                                                        <Button
                                                            variant='contained'
                                                            className='edit-btn'
                                                            onClick={editSummary}
                                                        >
                                                            <img loading="lazy" alt='imprend' src={editIcon} />
                                                        </Button> :
                                                        isFieldSummarySave === true ?
                                                            <Box className='team-card-button'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='tick-btn'
                                                                    type='submit'>
                                                                    {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                </Button>
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete'
                                                                    onClick={() => cancelDescription(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                            </Box> :
                                                            <Button
                                                                variant='contained'
                                                                className='button-delete'
                                                                onClick={() => cancelDescription(props)}
                                                            >
                                                                {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                            </Button>
                                                    }</>
                                                : <></>
                                            }

                                        </Grid>
                                    </Grid>

                                    {/* {isFieldEditCommon === true && isAuthorize === true ? */}
                                    {/* {userContext.isEditAccess === true ?
                                        <>
                                            {isFieldNameEdit === true || isFieldDescriptionEdit === true ?
                                                <Grid container className='last-container'>
                                                    <Grid item xs={2} sx={{ maxWidth: "95px !important" }}></Grid>
                                                    <Grid item xs={9} className="textfield-grid">
                                                        <Button
                                                            className='add-btn'
                                                            type='submit'>
                                                            Save</Button>
                                                    </Grid>
                                                </Grid>
                                                : <></>} </>
                                        : <></>} */}
                                </Form >
                            </>
                        )
                    }}
                </Formik>
            </Box>

            <Dialog
                open={openDeleteModal}
                //onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure? Your current unsaved changes will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='outlined'
                        // onClick={deleteNodeEdges}
                        onClick={() => handleCloseDeleteModal(true)}
                        className="popup-btn"
                    // loading={deleteLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={() => handleCloseDeleteModal(false)}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}
