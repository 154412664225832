import { NodeProps, useReactFlow, MarkerType, getOutgoers, getIncomers } from 'reactflow';
import { useCallback } from 'react';
import { uuid, randomLabel } from '../utils';

function useNodeAddConcurrent(id: NodeProps['id']) {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();

  const onClick = useCallback(() => {
    const currentNode = getNode(id);
    if (!currentNode) {
      return;
    }

    const parentIds = getIncomers(currentNode, getNodes(), getEdges()).map((node) => node.id);

    const parentNode = getNode(parentIds[0]);

    if (!parentNode) {
      return;
    }

    const concurrentNodeId = uuid();

    const concurrentNode = {
      id: concurrentNodeId,
      position: { x: parentNode.position.x, y: parentNode.position.y + 200 },
      type: 'step',
      data: { title: randomLabel(), isMenuOpen: false },
    };

    const concurrentEdge = {
      id: `${parentNode.id}=>${concurrentNodeId}`,
      source: parentNode.id,
      target: concurrentNodeId,
      type: 'step',
      style: { stroke: '#680001', strokeDasharray: 3 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: '#680001',
        height: 20,
        width: 20,
      },
    };

    setNodes((nodes) =>
      nodes.concat([concurrentNode])
    );

    setEdges((edges) =>
      edges.concat([concurrentEdge])
    );


    //Add edges to child    
    const childs = getOutgoers(currentNode, getNodes(), getEdges());
    if (childs.length > 0) {
      childs.forEach(child => {
        let childEdge = {
          id: `${concurrentNodeId}=>${child.id}`,
          source: concurrentNodeId,
          target: child.id,
          type: 'step',
          style: { stroke: '#680001', strokeDasharray: 3 },
          markerEnd: {
            type: MarkerType.Arrow,
            color: '#680001',
            height: 20,
            width: 20,
          },
        }
        setEdges((edges) =>
          edges.concat([childEdge])
        );
      });
    }

    //Add edges to parent
    if (parentIds.length > 0) {
      parentIds.forEach(parentId => {
        if (parentId !== parentNode.id) {
          let parentEdge = {
            id: `${parentId}=>${concurrentNodeId}`,
            source: parentId,
            target: concurrentNodeId,
            type: 'step',
            style: { stroke: '#680001', strokeDasharray: 3 },
            markerEnd: {
              type: MarkerType.Arrow,
              color: '#680001',
              height: 20,
              width: 20,
            },
          }
          setEdges((edges) =>
            edges.concat([parentEdge])
          );
        }
        
      });
    }
    
    //Remove all end edges
    const endNode = getNode('2');
    if (endNode) {
      const allParentIds = getIncomers(endNode, getNodes(), getEdges()).map((node) => node.id);
      if (allParentIds.length > 0) {
        allParentIds.forEach(node => {
          setEdges((edges) =>
            edges.filter((edge) => edge.id !== `${node}=>2`));
        });
      }
    }

    //Connect to end node
    const allNodes = getNodes();
    if (allNodes.length > 0) {
      allNodes.forEach(node => {
        const allChilds = getOutgoers(node, getNodes(), getEdges());
        if (allChilds.length === 0 && node.id != '2') {
          const endEdge = {
            id: `${node.id}=>2`,
            source: node.id,
            target: '2',
            type: 'step',
            style: { stroke: '#680001', strokeDasharray: 3 },
            markerEnd: {
              type: MarkerType.Arrow,
              color: '#680001',
              height: 20,
              width: 20,
            },
          };
          setEdges((edges) =>
            edges.concat([endEdge])
          );
        }
      });
    }


  }, [getEdges, getNode, getNodes, id, setEdges, setNodes]);

  return onClick;
}

export default useNodeAddConcurrent;
