import * as React from "react";
import { Grid, Paper, Typography, TextField, Button, Backdrop, Slide, Fade, Collapse, Tooltip, createTheme, ThemeProvider, IconButton, Popover } from "@mui/material";
import { Box } from "@mui/system";
// import Questionimg from "../../assets/icons/QuetionGraph.svg";
// import Questionimg from "../../assets/icons/hiagramGraph.svg";
import Questionimg from "../../assets/icons/hiagram.svg";
import searchout from "../../assets/icons/search-zoom-in.svg";
import search from "../../assets/icons/zoom-to-next.svg";
//import searchout from "../../assets/icons/zoom-first.svg";
import logouticon from "../../assets/icons/logout.svg";
//import logouticon from "../../assets/icons/signout-icon.svg";
import { useNavigate } from "react-router-dom";
import "./Question.scss";
import StandardMany from "../../Components/AnswerType/StandardMany/StandardMany";
import Ranking from "../../Components/AnswerType/Ranking/Ranking";
import AcronymAdd from "../../Components/AnswerType/AcronymAdd";
import StandardOne from "../../Components/AnswerType/StandardOne/StandardOne";
import ItemStakeAdd from "../../Components/AnswerType/ItemStakeAdd/ItemStakeAdd";
import ItemActionAdd from "../../Components/AnswerType/ItemActionAdd/ItemActionAdd";
import ItemTrgAdd from "../../Components/AnswerType/ItemTrgAdd/ItemTrgAdd";
import ItemHintAdd from "../../Components/AnswerType/ItemHintAdd/ItemHintAdd";
import ItemDocAdd from "../../Components/AnswerType/ItemDocAdd/ItemDocAdd";
import ItemEventAdd from "../../Components/AnswerType/ItemEventAdd/ItemEventAdd";
import ItemAssetAdd from "../../Components/AnswerType/ItemAssetAdd/ItemAssetAdd";
import Decision from "../../Components/AnswerType/Decision/Decision";
import SharepartyAdd from "../../Components/AnswerType/SharepartyAdd/SharepartyAdd";
import { useContext, useEffect, useRef, useState } from "react";
import { GetLastAttemptedQuestion, GetQuestionById } from "../../services/QuestionService";
import { PreferencesContext } from "../../PreferenceContext";
import { AlertType } from "../../shared/AlertType";
import { LoadingButton } from "@mui/lab";
import { GetHiagramTeamProj, UpdateHiagramName, UpdateHiagramProgress } from "../../services/HiagramService";
import { GetRoledetailById, GetRoleList } from "../../services/RoleService";
import { GetActivitydetailById, GetActivityList } from "../../services/ActivityService";
import { GetAllCessList, GetCessdetail, GetCessdetailById, GetCessList } from "../../services/CessService";
import ProcessBuilder from "../../Components/ProcessBuilder/ProcessBuilder";
import { GetStepdetailById, StepList } from "../../services/StepService";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { GetTeamdetailById } from "../../services/TeamService";
import { GetProjdetailById } from "../../services/ProjectService";
import Typewriter from 'typewriter-effect';
import peopleTeam from '../../assets/icons/people-team.svg';
import Project from '../../assets/icons/hiagramnode-project.svg';
import Activity from '../../assets/icons/hiagramnode-role.svg';
import Role from '../../assets/icons/hiagramnode-activity.svg';
import Process from '../../assets/icons/hiagramnode-process.svg';
// import Step from '../../assets/icons/step-bg.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Checkpoint from "../../Components/AnswerType/Checkpoint/Checkpoint";

const theme = createTheme({
  typography: {
    fontFamily: [
      'RoseauSlab-Light',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#2c2449', light: '#d9d2f1' }
    , secondary: { main: '#091C69', light: '#E6E8F0' }
    , action: { hover: '#8c00040d' },
  },
  components: {
    // MuiPaper:{
    //   styleOverrides: { 
    //     root: { 
    //     borderRadius:'10px'
    //   } 
    // }
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '14px', fontWeight: 600,
          padding: '16px 40px',
          borderRadius: '8px'
        }
      }
    }
  }
});

const templateQuestion = {
  uid: 0,
  template_uid: 0,
  question_uid: 0,
  previous_question_uid: null,
  next_question_uid: null,
  logic_no_previous_question_uid: null,
  logic_no_next_question_uid: null
}
const questionModel = {
  uid: 0,
  type: "",
  text: "",
  text_free: "",
  number: 0,
  max_length: "",
  guidance_free: '',
  guidance: "",
  data_type: "",
  append_to: "",
  append_field: "",
  append_type: "",
  is_use_logic: "",
  templateQuestionMapping: [] = [templateQuestion]
};

const standardOneConfigInit = {
  secUid: 0,
  label: "",
  placeholder: "",
  numberOfLines: 4,
  isDropdownVisible: false,
  isAIVisible: true,
  dropdownLabel: "",
  isWordCount: false
};
export interface IInputWrapperProps {
  label?: string;
  required?: boolean;
  minimizedLabel?: boolean;
  description?: string;
  error?: string;
  wrapperStyle?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[];
}
type DirectionType = "right" | "left" | "up" | "down";

export default function Question() {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isShowLoder, setShowLoder] = React.useState(false);
  const [isNextButtonLoading, setNextButtonLoading] = React.useState(false);
  const [isPreviousButtonLoading, setPreviousButtonLoading] = React.useState(false);
  const [isZoomButtonLoading, setZoomButtonLoading] = React.useState(false);
  const [isZoomFirstButtonLoading, setZoomFirstButtonLoading] = React.useState(false);
  const [isSubmitButtonLoading, setSubmitButtonLoading] = React.useState(false);
  const [curQuestion, setCurQuestion] = React.useState(questionModel);
  const [standardOneConfig, setStandardOneConfig] = React.useState(standardOneConfigInit);
  const [curQuestionId, setCurQuestionId] = React.useState(0);
  // const params = useParams();
  const childStandardOneCompRef: any = useRef();
  const childStandardManyCompRef: any = useRef();
  const childIteamAddActionCompRef: any = useRef();
  const childIteamAddStakeCompRef: any = useRef();
  const childIteamAddDocCompRef: any = useRef();
  const childIteamAddHintCompRef: any = useRef();
  const childIteamAddTrgCompRef: any = useRef();
  const childIteamAddAssetCompRef: any = useRef();
  const childIteamAddEventCompRef: any = useRef();
  const childRankingCompRef: any = useRef();
  const childDecisionCompRef: any = useRef();
  const containerRef: any = React.useRef();
  const processBuilderRef: any = React.useRef();
  const checkpointRef: any = React.useRef();
  // const [forSlide, setForSlide] = useState(0);
  const [checked, setChecked] = React.useState(false);
  const [direction, setDirection] = React.useState<DirectionType>("up");
  const [isCollapse, setCollapse] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [showTypewriter, setShowTypewriter] = React.useState(false);
  const [hiagramName, setHiagramName] = React.useState(userContext.hiagramName);
  const [hiagramProgress, setHiagramProgress] = React.useState(userContext.hiagramProgress);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // const mainTheme = useTheme();
  const open = Boolean(anchorEl);
  // const bodyElt = document.querySelector("body");
  //   if (bodyElt) {
  //       bodyElt.style.backgroundColor = "#fff";
  //   }
  const handleGuidanceForStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGuidanceForStatusClose = () => {
    setAnchorEl(null);
  };

  function Init() {
    // fetchQuestion(true);
    //setChecked(true);
    let curQuestionData: any = userContext.lastQuestionInfo;
    if (curQuestionData !== null && curQuestionData !== undefined) {
      updateUserContext({
        ...userContext,
        totalTeamProj: curQuestionData.totalTeamProj,
        totalRole: curQuestionData.totalRole,
        teamProjId: curQuestionData.teamProjId,
        teamProjName: curQuestionData.teamProjName,
        teamProjPurpose: curQuestionData.teamProjPurpose,
        isProj: curQuestionData.isProj,
        roleId: curQuestionData.roleId,
        roleName: curQuestionData.roleName,
        activityId: curQuestionData.activityId,
        activityName: curQuestionData.activityName,
        cessId: curQuestionData.cessId,
        cessName: curQuestionData.cessName,
        stepId: curQuestionData.stepId,
        stepName: curQuestionData.stepName,
      });
      if (curQuestionData.questionUid === 6) {
        setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: true, isAIVisible: false, dropdownLabel: "Status", secUid: 4, isWordCount: false });
      }
      else if (curQuestionData.questionUid === 61) {
        setStandardOneConfig({ ...standardOneConfig, label: "Purpose", placeholder: "Purpose", numberOfLines: 4, isDropdownVisible: false, isAIVisible: false, dropdownLabel: "", secUid: 0, isWordCount: true });
      }
      else if (curQuestionData.questionUid === 53) {
        setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: false, isAIVisible: false, dropdownLabel: "", secUid: 0, isWordCount: false });
      } else {
        setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: false, isAIVisible: true, dropdownLabel: "", secUid: 0, isWordCount: false });
      }
      setCurQuestionId(curQuestionData.questionUid);
    } else {
      setCurQuestionId(1);
    }
  }
  let navigate = useNavigate();

  // const gotoSharepartyAdd = () => {
  //   // navigate("/sharepartyAdd");
  //   navigate("/hiagram");
  // };

  const gotoMyprofile = () => {
    // navigate("/sharepartyAdd");
    //navigate("/myprofile");
    // navigate("/all-hiagram");
    navigate("/dashboard");
  };

  const gotoHiagram = () => {
    // navigate("/sharepartyAdd");
    updateProgress(0, 0, 0, 0, 0, true);
    navigate("/hiagram/" + userContext.hiagramId);
  };

  const handleGuidanceShowHideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setCollapse(!isCollapse)
  }

  const handlelabelClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setEdit(true);
  }

  async function getAllActivity(newContextData?: any) {
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("quid", "0");
      params.append("ruid", newContextData !== undefined ? newContextData.roleId.toString() : userContext.roleId.toString());
      const activities: any = await GetActivityList(params);
      return activities;
    } catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.message,
          alertType: AlertType.Error,
        });
      }
      return null;
    }
  }

  async function getAllCess(newContextData?: any) {
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("quid", "0");
      params.append("auid", newContextData !== undefined ? newContextData.activityId.toString() : userContext.activityId.toString());
      const cesses: any = await GetCessList(params);
      return cesses;
    } catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.message,
          alertType: AlertType.Error,
        });
      }
      return null;
    }
  }

  async function getAllRole(newContextData?: any) {
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("quid", "0");
      let projUID = 0;
      let teamUID = 0;
      if ((newContextData !== undefined ? newContextData.isProj.toString() : userContext.isProj.toString()) === "false") {
        teamUID = newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId;
      } else if ((newContextData !== undefined ? newContextData.isProj.toString() : userContext.isProj.toString()) === "true") {
        projUID = newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId;
      }
      params.append("tuid", teamUID.toString());
      params.append("puid", projUID.toString());
      const roles: any = await GetRoleList(params);
      return roles;
    } catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.message,
          alertType: AlertType.Error,
        });
      }
      return null;
    }
  }

  async function fetchQuestion(isPageloading: boolean) {
    setChecked(true);
    setShowLoder(isPageloading);
    setShowTypewriter(false);
    try {
      let curProgress = userContext.hiagramProgress;
      if (userContext.isEditAccess === true) {
        curProgress = await updateProgress(0, 0, 0, 0, 0, false);
      }
      const question: any = await GetQuestionById(curQuestionId, userContext.tempUid);
      if (question !== undefined && question !== null && question.data !== null && question.data.data !== null) {
        let questionText = +userContext.tempUid === 3 ? question.data.data.text_free : question.data.data.text;
        let replacvalue = questionText.substring(questionText.indexOf("{") + 1);
        if (curQuestionId === 33) {
          replacvalue = replacvalue.substring(0, replacvalue.indexOf("}"));
        } else {
          replacvalue = replacvalue.substring(0, replacvalue.lastIndexOf("}"));
        }
        if (replacvalue === "CLIENT.ORGANIZATION_NAME") {
          questionText = questionText.replaceAll("{" + replacvalue + "}", "<span class='color-text'>" + userContext.clientOrgName + "</span>");
        } else if (replacvalue === "TEAM.NAME}/{PROJ.NAME" || replacvalue === "PROJ.NAME" || replacvalue === "TEAM.NAME}/{PROJECT.NAME") {
          questionText = questionText.replaceAll("{" + replacvalue + "}", "<span class='color-text'>" + userContext.teamProjName + "</span>");
        } else if (replacvalue === "ROLE.NAME") {
          questionText = questionText.replaceAll("{" + replacvalue + "}", "<span class='color-text'>" + userContext.roleName + "</span>");
        } else if (replacvalue === "ACTIVITY.NAME") {
          questionText = questionText.replaceAll("{" + replacvalue + "}", "<span class='color-text'>" + userContext.activityName + "</span>");
        } else if (replacvalue === "CESS.NAME") {
          questionText = questionText.replaceAll("{" + replacvalue + "}", "<span class='color-text'>" + userContext.cessName + "</span>");
        } else if (replacvalue === "STEP.NAME") {
          questionText = questionText.replaceAll("{" + replacvalue + "}", "<span class='color-text'>" + userContext.stepName + "</span>");
        }
        if (+userContext.tempUid === 3) {
          question.data.data.text_free = questionText;
        } else {
          question.data.data.text = questionText;
        }
        setCurQuestion(question.data.data);
        let lastQuestionData: any = {
          questionUid: curQuestionId,
          totalTeamProj: userContext.totalTeamProj,
          totalRole: userContext.totalRole,
          teamProjName: userContext.teamProjName,
          teamProjPurpose: userContext.teamProjPurpose,
          teamProjId: userContext.teamProjId,
          isProj: userContext.isProj,
          roleId: userContext.roleId,
          roleName: userContext.roleName,
          activityId: userContext.activityId,
          activityName: userContext.activityName,
          cessId: userContext.cessId,
          cessName: userContext.cessName,
          stepId: userContext.stepId,
          stepName: userContext.stepName
        };
        if (curQuestionId === 45) {
          lastQuestionData.stepId = 0;
          lastQuestionData.stepName = "";
          updateUserContext({
            ...userContext,
            stepId: 0,
            stepName: "",
            lastQuestionInfo: lastQuestionData,
            hiagramProgress: +curProgress
          });
        } else {
          updateUserContext({
            ...userContext,
            lastQuestionInfo: lastQuestionData,
            hiagramProgress: +curProgress
          });
        }
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
    setShowLoder(false);
    setNextButtonLoading(false);
    setZoomButtonLoading(false);
    setZoomFirstButtonLoading(false);
    setPreviousButtonLoading(false);
    setCollapse(false);
  }

  async function fetchTeamProj(newContextData?: any, isFromZoom: boolean = false) {
    try {
      const hiagram: any = await GetHiagramTeamProj(userContext.hiagramId);
      if (hiagram !== null && hiagram.data !== null && hiagram.data.data !== null) {
        let hiagramData = hiagram.data.data;
        if (hiagramData.length > 0 && (newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId) === 0) {
          // let newTeamProjId = userContext.teamProjId;
          // let newTeamProjName = userContext.teamProjName;
          let newTeamProjId = 0;
          let newTeamProjName = "";
          let newTeamProjPurpose = "";
          let newisProj = "false";
          let newRoleId = 0;
          let remainingDataIndex = hiagramData.findIndex((teamProj: any) => teamProj.is_completed === false);
          if (remainingDataIndex >= 0 && isFromZoom) {
            newTeamProjId = hiagramData[remainingDataIndex].uid;
            newTeamProjName = hiagramData[remainingDataIndex].name;
            newTeamProjPurpose = hiagramData[remainingDataIndex].purpose;
            newisProj = hiagramData[remainingDataIndex].is_proj.toString() === "0" ? "false" : "true";
            if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
              newRoleId = +hiagramData[remainingDataIndex].role[0].uid;
            }
          } else {
            if (newTeamProjId === undefined || newTeamProjId === 0) {
              newTeamProjId = hiagramData[0].uid;
              newTeamProjName = hiagramData[0].name;
              newTeamProjPurpose = hiagramData[0].purpose;
              newisProj = hiagramData[0].is_proj.toString() === "0" ? "false" : "true";
              if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                newRoleId = +hiagramData[0].role[0].uid;
              }
            }
          }
          updateUserContext({
            ...userContext,
            teamProjId: +newTeamProjId,
            teamProjName: newTeamProjName,
            totalTeamProj: hiagramData.length,
            teamProjPurpose: newTeamProjPurpose,
            roleId: newRoleId,
            roleName: "",
            activityId: 0,
            activityName: "",
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: "",
            isProj: newisProj,
          });
        } else {
          setCurQuestionId(53);
        }
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function fetchRoles(newContextData?: any, isFromZoom: boolean = false) {
    let roleData: any = []
    try {
      const Roles: any = await getAllRole(newContextData);
      if (Roles !== null && Roles.data !== null && Roles.data.data !== null) {
        roleData = Roles.data.data;
        if (roleData.length > 0 && userContext.roleId === 0) {
          let newUid = +roleData[0].uid;
          let newName = roleData[0].name;
          let remainingDataIndex = roleData.findIndex((role: any) => role.is_completed === false);
          if (remainingDataIndex >= 0 && isFromZoom) {
            newUid = +roleData[remainingDataIndex].uid;
            newName = roleData[remainingDataIndex].name;
          }
          if (newContextData !== undefined) {
            updateUserContext({
              ...userContext,
              teamProjId: newContextData.teamProjId,
              teamProjName: newContextData.teamProjName,
              teamProjPurpose: newContextData.teamProjPurpose,
              isProj: newContextData.isProj,
              totalRole: roleData.length,
              roleId: newUid,
              roleName: newName,
              activityId: 0,
              activityName: "",
              cessId: 0,
              cessName: "",
              stepId: 0,
              stepName: "",
            });
          } else {

            updateUserContext({
              ...userContext,
              roleId: newUid,
              roleName: newName,
              activityId: 0,
              activityName: "",
              cessId: 0,
              cessName: "",
              stepId: 0,
              stepName: "",
            });
          }
        } else {
          if (newContextData !== undefined && newContextData.roleId > 0) {
            updateUserContext({
              ...userContext,
              teamProjId: newContextData.teamProjId,
              teamProjName: newContextData.teamProjName,
              teamProjPurpose: newContextData.teamProjPurpose,
              isProj: newContextData.isProj,
              roleId: newContextData.uid,
              roleName: newContextData.name,
              activityId: 0,
              activityName: "",
              cessId: 0,
              cessName: "",
              stepId: 0,
              stepName: "",
            });
          } else {
            const data: any = await GetHiagramTeamProj(userContext.hiagramId);
            if (data !== null && data.data !== null && data.data.data !== null) {
              let allTeamProj = data.data.data as any[];
              if (allTeamProj.length > 0) {
                let curIndex = allTeamProj.findIndex((teamProj: any) => +teamProj.uid === (newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId)
                  && (teamProj.is_proj.toString() === "0" ? "false" : "true") === (newContextData !== undefined ? newContextData.isProj : userContext.isProj));
                if ((curIndex + 1) <= (allTeamProj.length - 1)) {
                  let curTeamProj = allTeamProj[curIndex + 1];
                  // if(+userContext.tempUid === 3){
                  //   updateUserContext({
                  //     ...userContext,
                  //     teamProjId: +curTeamProj.uid,
                  //     teamProjName: curTeamProj.name,
                  //     teamProjPurpose: curTeamProj.purpose,
                  //     isProj: curTeamProj.is_proj.toString() === "0" ? "false" : "true",
                  //     roleId: +curTeamProj.role[0].uid,
                  //     roleName: curTeamProj.role[0].name,
                  //     activityId: 0,
                  //     activityName: "",
                  //     cessId: 0,
                  //     cessName: "",
                  //     stepId: 0,
                  //     stepName: ""
                  //   });
                  // }else{
                  updateUserContext({
                    ...userContext,
                    teamProjId: +curTeamProj.uid,
                    teamProjName: curTeamProj.name,
                    teamProjPurpose: curTeamProj.purpose,
                    isProj: curTeamProj.is_proj.toString() === "0" ? "false" : "true",
                    roleId: 0,
                    roleName: "",
                    activityId: 0,
                    activityName: "",
                    cessId: 0,
                    cessName: "",
                    stepId: 0,
                    stepName: ""
                  });
                  // }
                  //setCurQuestionId(4);
                  setCurQuestionId(61);
                } else {
                  setCurQuestionId(53);
                }
              }
            }
          }
        }
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
    return roleData;
  }

  async function fetchActivity(newContextData?: any, isFromZoom: boolean = false) {
    let activityData: any = [];
    try {
      const Activities: any = await getAllActivity(newContextData);
      if (Activities !== null && Activities.data !== null && Activities.data.data !== null) {
        activityData = Activities.data.data;
        if (activityData.length > 0 && (newContextData !== undefined ? newContextData.activityId : userContext.activityId) === 0) {
          let newUid = +activityData[0].uid;
          let newName = activityData[0].name;
          let newRoleName = activityData[0].role.name;
          let remainingDataIndex = activityData.findIndex((activity: any) => activity.is_completed === false);
          if (remainingDataIndex >= 0 && isFromZoom) {
            newUid = +activityData[remainingDataIndex].uid;
            newName = activityData[remainingDataIndex].name;
          }
          if (newContextData !== undefined) {
            updateUserContext({
              ...userContext,
              teamProjId: newContextData.teamProjId,
              teamProjName: newContextData.teamProjName,
              teamProjPurpose: newContextData.teamProjPurpose,
              isProj: newContextData.isProj,
              roleId: newContextData.roleId,
              roleName: activityData[0].role.name,
              activityId: newUid,
              activityName: newName,
              cessId: 0,
              cessName: "",
              stepId: 0,
              stepName: "",
            });
          } else {
            updateUserContext({
              ...userContext,
              activityId: newUid,
              activityName: newName,
              roleName: activityData[0].role.name,
              cessId: 0,
              cessName: "",
              stepId: 0,
              stepName: "",
            });
          }
        } else {
          if (newContextData !== undefined) {
            updateUserContext({
              ...userContext,
              teamProjId: newContextData.teamProjId,
              teamProjName: newContextData.teamProjName,
              teamProjPurpose: newContextData.teamProjPurpose,
              isProj: newContextData.isProj,
              roleId: newContextData.roleId,
              roleName: newContextData.roleName,
              activityId: newContextData.activityId,
              activityName: newContextData.activityName,
              cessId: newContextData.cessId,
              cessName: newContextData.cessName,
              stepId: newContextData.stepId,
              stepName: newContextData.stepName
            });
          } else {
            fetchRoles(newContextData);
          }
        }
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
    return activityData;
  }

  async function fetchCess(newContextData?: any, isFromZoom: boolean = false) {
    let cessData: any = [];
    try {
      const Cess: any = await getAllCess(newContextData);
      if (Cess !== null && Cess.data !== null && Cess.data.data !== null) {
        cessData = Cess.data.data;
        if (cessData.length > 0 && (newContextData !== undefined ? newContextData.cessId : userContext.cessId) === 0) {
          let newUid = +cessData[0].uid;
          let newName = cessData[0].name;
          let remainingDataIndex = cessData.findIndex((cess: any) => cess.is_completed === false);
          if (remainingDataIndex >= 0 && isFromZoom) {
            newUid = +cessData[remainingDataIndex].uid;
            newName = cessData[remainingDataIndex].name;
          }
          if (newContextData !== undefined) {
            updateUserContext({
              ...userContext,
              teamProjId: newContextData.teamProjId,
              teamProjName: newContextData.teamProjName,
              teamProjPurpose: newContextData.teamProjPurpose,
              isProj: newContextData.isProj,
              roleId: newContextData.roleId,
              roleName: newContextData.roleName,
              activityId: newContextData.activityId,
              activityName: newContextData.activityName,
              cessId: newUid,
              cessName: newName,
              stepId: 0,
              stepName: ""
            });
          } else {
            updateUserContext({
              ...userContext,
              cessId: newUid,
              cessName: newName,
              stepId: 0,
              stepName: "",
            });
          }
        } else {
          //fetchActivity(newContextData);
          if (newContextData !== undefined) {
            updateUserContext({
              ...userContext,
              teamProjId: newContextData.teamProjId,
              teamProjName: newContextData.teamProjName,
              teamProjPurpose: newContextData.teamProjPurpose,
              isProj: newContextData.isProj,
              roleId: newContextData.roleId,
              roleName: newContextData.roleName,
              activityId: newContextData.activityId,
              activityName: newContextData.activityName,
              cessId: newContextData.cessId,
              cessName: newContextData.cessName,
              stepId: newContextData.stepId,
              stepName: newContextData.stepName
            });
          } else {
            fetchActivity(newContextData);
          }
        }
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
    return cessData;
  }

  // async function fetchStep(newContextData?: any) {
  //   let stepData: any = [];
  //   try {
  //     let params = new URLSearchParams();
  //     params.append("huid", userContext.hiagramId.toString());
  //     params.append("cuid", newContextData !== undefined ? newContextData.cessId.toString() : userContext.cessId.toString());
  //     const Step: any = await StepList(params);
  //     if (Step !== null && Step.data !== null && Step.data.data !== null) {
  //       stepData = Step.data.data;
  //       if (stepData.length > 0 && (newContextData !== undefined ? newContextData.stepId : userContext.stepId) === 0) {
  //         if (newContextData !== undefined) {
  //           updateUserContext({
  //             ...userContext,
  //             teamProjId: newContextData.teamProjId,
  //             teamProjName: newContextData.teamProjName,
  //             teamProjPurpose: newContextData.teamProjPurpose,
  //             isProj: newContextData.isProj,
  //             roleId: newContextData.roleId,
  //             roleName: newContextData.roleName,
  //             activityId: newContextData.activityId,
  //             activityName: newContextData.activityName,
  //             cessId: newContextData.cessId,
  //             cessName: newContextData.cessName,
  //             stepId: +stepData[0].uid,
  //             stepName: stepData[0].name
  //           });
  //         } else {
  //           updateUserContext({
  //             ...userContext,
  //             stepId: +stepData[0].uid,
  //             stepName: stepData[0].name
  //           });
  //         }
  //       } else {
  //         if (newContextData !== undefined) {
  //           updateUserContext({
  //             ...userContext,
  //             teamProjId: newContextData.teamProjId,
  //             teamProjName: newContextData.teamProjName,
  //             teamProjPurpose: newContextData.teamProjPurpose,
  //             isProj: newContextData.isProj,
  //             roleId: newContextData.roleId,
  //             roleName: newContextData.roleName,
  //             activityId: newContextData.activityId,
  //             activityName: newContextData.activityName,
  //             cessId: newContextData.cessId,
  //             cessName: newContextData.cessName,
  //             stepId: newContextData.stepId,
  //             stepName: newContextData.stepName
  //           });
  //         } else {
  //           fetchCess(newContextData);
  //         }
  //       }
  //     }
  //   }
  //   catch (error: any) {
  //     if (error) {
  //       updateUserContext({
  //         ...userContext,
  //         isAlert: true,
  //         alertMessage: error.response.message,
  //         alertType: AlertType.Error,
  //       });
  //     }
  //   }
  //   return stepData;
  // }

  function NextQuestion() {
    if (childStandardOneCompRef.current !== undefined && childStandardOneCompRef.current !== null) {
      childStandardOneCompRef.current.submitClick();
    } else if (childStandardManyCompRef.current !== undefined && childStandardManyCompRef.current !== null) {
      childStandardManyCompRef.current.submitClick();
    } else if (childIteamAddActionCompRef.current !== undefined && childIteamAddActionCompRef.current !== null &&
      (curQuestion.type === "ITEM_ADD" && curQuestion.append_to === "ACTION")) {
      childIteamAddActionCompRef.current.submitClick();
    } else if (childIteamAddStakeCompRef.current !== undefined && childIteamAddStakeCompRef.current !== null &&
      (curQuestion.type === "ITEM_ADD" && curQuestion.append_to === "STAKE")) {
      childIteamAddStakeCompRef.current.submitClick();
    } else if (childIteamAddDocCompRef.current !== undefined && childIteamAddDocCompRef.current !== null &&
      (curQuestion.type === "ITEM_ADD" && curQuestion.append_to === "DOC")) {
      childIteamAddDocCompRef.current.submitClick();
    } else if (childIteamAddHintCompRef.current !== undefined && childIteamAddHintCompRef.current !== null &&
      (curQuestion.type === "ITEM_ADD" && curQuestion.append_to === "HINT")) {
      childIteamAddHintCompRef.current.submitClick();
    } else if (childIteamAddTrgCompRef.current !== undefined && childIteamAddTrgCompRef.current !== null &&
      (curQuestion.type === "ITEM_ADD" && curQuestion.append_to === "TRG")) {
      childIteamAddTrgCompRef.current.submitClick();
    } else if (childIteamAddAssetCompRef.current !== undefined && childIteamAddAssetCompRef.current !== null &&
      (curQuestion.type === "ITEM_ADD" && curQuestion.append_to === "ASSET")) {
      childIteamAddAssetCompRef.current.submitClick();
    } else if (childIteamAddEventCompRef.current !== undefined && childIteamAddEventCompRef.current !== null &&
      (curQuestion.type === "ITEM_ADD" && curQuestion.append_to === "EVENT")) {
      childIteamAddEventCompRef.current.submitClick();
    } else if (childRankingCompRef.current !== undefined && childRankingCompRef.current !== null &&
      curQuestion.type === "RANKING") {
      childRankingCompRef.current.submitClick();
    } else if (childDecisionCompRef.current !== undefined && childDecisionCompRef.current !== null &&
      curQuestion.type === "DECISION") {
      childDecisionCompRef.current.submitClick();
    } else if (processBuilderRef.current !== undefined && processBuilderRef.current !== null &&
      curQuestion.type === "PROCESS_BUILDER") {
      processBuilderRef.current.submitClick();
    } else if (checkpointRef.current !== undefined && checkpointRef.current !== null &&
      curQuestion.type === "CHECKPOINT") {
      checkpointRef.current.submitClick();
    }
    else {
      ChangeNextQuestion();
    }
  }

  async function ChangeNextQuestion(question?: any, newContextData?: any, isFromZoom: boolean = false) {
    let currentQuestion = curQuestion;
    let isSkipCess = false;
    if (question !== undefined && question !== null) {
      currentQuestion = question;
    } else {
      setNextButtonLoading(true);
    }
    let nextQus = 0;
    try {
      if (+currentQuestion.number === 38) {
        gotoHiagram();
      } else {
        if (currentQuestion.is_use_logic !== null && currentQuestion.is_use_logic.toString() === "true") {
          if (+currentQuestion.number === 2 && (newContextData !== undefined ? newContextData.isProj.toString() === "false" : userContext.isProj.toString() === "false")) {
            nextQus = currentQuestion.templateQuestionMapping[0].logic_no_next_question_uid === null ? 0 : +currentQuestion.templateQuestionMapping[0].logic_no_next_question_uid!!;
          } else if (+currentQuestion.number === 28) {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", currentQuestion.uid.toString());
            let data: any;
            params.append("uid", newContextData !== undefined ? newContextData.cessId.toString() : userContext.cessId.toString());
            data = await GetCessdetail(params);

            if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
              if (data.data.data.detail_skip === false) {
                nextQus = currentQuestion.templateQuestionMapping[0].logic_no_next_question_uid === null ? 0 : +currentQuestion.templateQuestionMapping[0].logic_no_next_question_uid;
              } else {
                //nextQus = currentQuestion.templateQuestionMapping[0].next_question_uid === null ? 0 : +currentQuestion.templateQuestionMapping[0].next_question_uid;
                //nextQus = 62;
                let params = new URLSearchParams();
                params.append("huid", userContext.hiagramId.toString());
                params.append("auid", newContextData !== undefined ? newContextData.activityId.toString() : userContext.activityId.toString());
                let data: any;

                data = await GetAllCessList(params);
                if (data != null && data.data != null && data.data.data != null) {
                  let allCess = data.data.data as any[];
                  if (allCess.length > 0) {
                    let curIndex = allCess.findIndex((cess: any) => +cess.uid === (newContextData !== undefined ? newContextData.cessId : userContext.cessId));
                    let newProgress = await updateProgress(0, 0, 0, 0, (newContextData !== undefined ? newContextData.cessId : userContext.cessId), false);
                    if ((curIndex + 1) <= (allCess.length - 1)) {
                      let curCess = allCess[curIndex + 1];
                      updateUserContext({
                        ...userContext,
                        teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                        teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                        teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                        isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                        roleId: newContextData !== undefined ? newContextData.roleId : userContext.roleId,
                        roleName: newContextData !== undefined ? newContextData.roleName : userContext.roleName,
                        activityId: newContextData !== undefined ? newContextData.activityId : userContext.activityId,
                        activityName: newContextData !== undefined ? newContextData.activityName : userContext.activityName,
                        cessId: +curCess.uid,
                        cessName: curCess.name,
                        stepId: 0,
                        stepName: ""
                      });
                      nextQus = 34;
                    } else {
                      updateUserContext({
                        ...userContext,
                        teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                        teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                        teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                        isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                        roleId: newContextData !== undefined ? newContextData.roleId : userContext.roleId,
                        roleName: newContextData !== undefined ? newContextData.roleName : userContext.roleName,
                        activityId: newContextData !== undefined ? newContextData.activityId : userContext.activityId,
                        activityName: newContextData !== undefined ? newContextData.activityName : userContext.activityName,
                        cessId: 0,
                        cessName: ""
                      });
                      nextQus = 62;
                    }
                  }
                }
              }
            }
          } else {
            nextQus = currentQuestion.templateQuestionMapping[0].next_question_uid === null ? 0 : +currentQuestion.templateQuestionMapping[0].next_question_uid;
          }
        } else {
          let isAllStepCompleted = false;
          let isAllCessCompleted = false;
          let isAllActivityCompleted = false;
          let isAllRoleCompleted = false;
          let isAllTeamProjectCompleted = false;
          nextQus = currentQuestion.templateQuestionMapping[0].next_question_uid === null ? 0 : +currentQuestion.templateQuestionMapping[0].next_question_uid;
          if (nextQus === 0 || curQuestionId !== nextQus) {
            if (+currentQuestion.number === 37) {
              let params = new URLSearchParams();
              params.append("huid", userContext.hiagramId.toString());
              params.append("cuid", newContextData !== undefined ? newContextData.cessId.toString() : userContext.cessId.toString());
              let data: any;
              data = await StepList(params);

              if (data != null && data.data != null && data.data.data != null) {
                let allStep = data.data.data as any[];
                if (allStep.length > 0) {
                  let curIndex = allStep.findIndex((step: any) => +step.uid === (newContextData !== undefined ? newContextData.stepId : userContext.stepId));
                  if ((curIndex + 1) <= (allStep.length - 1)) {
                    let curStep = allStep[curIndex + 1];
                    updateUserContext({
                      ...userContext,
                      teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                      teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                      teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                      isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                      roleId: newContextData !== undefined ? newContextData.roleId : userContext.roleId,
                      roleName: newContextData !== undefined ? newContextData.roleName : userContext.roleName,
                      activityId: newContextData !== undefined ? newContextData.activityId : userContext.activityId,
                      activityName: newContextData !== undefined ? newContextData.activityName : userContext.activityName,
                      cessId: newContextData !== undefined ? newContextData.cessId : userContext.cessId,
                      cessName: newContextData !== undefined ? newContextData.cessName : userContext.cessName,
                      stepId: +curStep.uid,
                      stepName: curStep.name
                    });
                    nextQus = 48;
                  } else {
                    isAllStepCompleted = true;
                  }
                }
              }
            }

            // if (+currentQuestion.number === 31 || isAllStepCompleted === true) {
            if (+currentQuestion.number === 32) {
              let params = new URLSearchParams();
              params.append("huid", userContext.hiagramId.toString());
              params.append("auid", newContextData !== undefined ? newContextData.activityId.toString() : userContext.activityId.toString());
              let data: any;
              data = await GetAllCessList(params);
              if (data != null && data.data != null && data.data.data != null) {
                let allCess = data.data.data as any[];
                if (allCess.length > 0) {
                  let curIndex = allCess.findIndex((cess: any) => +cess.uid === (newContextData !== undefined ? +newContextData.cessId : +userContext.cessId));
                  let newProgress = await updateProgress(0, 0, 0, 0, (newContextData !== undefined ? newContextData.cessId : userContext.cessId), false);
                  if ((curIndex + 1) <= (allCess.length - 1)) {
                    let curCess = allCess[curIndex + 1];
                    updateUserContext({
                      ...userContext,
                      teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                      teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                      teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                      isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                      roleId: newContextData !== undefined ? newContextData.roleId : userContext.roleId,
                      roleName: newContextData !== undefined ? newContextData.roleName : userContext.roleName,
                      activityId: newContextData !== undefined ? newContextData.activityId : userContext.activityId,
                      activityName: newContextData !== undefined ? newContextData.activityName : userContext.activityName,
                      cessId: +curCess.uid,
                      cessName: curCess.name,
                      stepId: 0,
                      stepName: ""
                    });
                    nextQus = 34;
                  } else {
                    isAllCessCompleted = true;
                  }
                }
              }
            }
            if (+currentQuestion.number === 24) {
              let params = new URLSearchParams();
              params.append("huid", userContext.hiagramId.toString());
              params.append("auid", newContextData !== undefined ? newContextData.activityId.toString() : userContext.activityId.toString());
              let data: any;
              data = await GetAllCessList(params);
              if (data != null && data.data != null && data.data.data != null) {
                let allCess = data.data.data as any[];
                if (allCess.length === 0) {
                  isAllCessCompleted = true;
                  isSkipCess = true;
                }
              }
            }
            //New 
            if (isAllCessCompleted === true) {
              updateUserContext({
                ...userContext,
                teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                roleId: newContextData !== undefined ? newContextData.roleId : userContext.roleId,
                roleName: newContextData !== undefined ? newContextData.roleName : userContext.roleName,
                activityId: newContextData !== undefined ? newContextData.activityId : userContext.activityId,
                activityName: newContextData !== undefined ? newContextData.activityName : userContext.activityName,
                cessId: 0,
                cessName: "",
                stepId: 0,
                stepName: ""
              });
              nextQus = 62;
            }
            // if (isAllCessCompleted === true) {
            if (+currentQuestion.number === 44) {
              let data: any;
              data = await getAllActivity(newContextData);
              if (data != null && data.data != null && data.data.data != null) {
                let allActivity = data.data.data as any[];
                if (allActivity.length > 0) {
                  let curIndex = allActivity.findIndex((activity: any) => +activity.uid === (newContextData !== undefined ? +newContextData.activityId : +userContext.activityId));
                  let newProgress = await updateProgress(0, 0, 0, (newContextData !== undefined ? newContextData.activityId : userContext.activityId), 0, false);
                  if ((curIndex + 1) <= (allActivity.length - 1)) {
                    let curActivity = allActivity[curIndex + 1];
                    updateUserContext({
                      ...userContext,
                      teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                      teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                      teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                      isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                      roleId: newContextData !== undefined ? newContextData.roleId : userContext.roleId,
                      roleName: newContextData !== undefined ? newContextData.roleName : userContext.roleName,
                      activityId: +curActivity.uid,
                      activityName: curActivity.name,
                      cessId: 0,
                      cessName: "",
                      stepId: 0,
                      stepName: "",
                    });
                    nextQus = 22;
                  } else {

                    // hp code start
                    if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                      let data: any;
                      data = await getAllRole(newContextData);
                      if (data != null && data.data != null && data.data.data != null) {
                        let allRoles = data.data.data as any[];
                        if (allRoles.length > 0) {
                          let curIndex = allRoles.findIndex((role: any) => +role.uid === (newContextData !== undefined ? +newContextData.roleId : +userContext.roleId));
                          //if (curIndex > -1) {
                          //progress = Math.round(progress + ((100 / userContext.totalTeamProj) / allRoles.length));
                          //setHiagramProgress(progress);
                          let newProgress = await updateProgress((newContextData !== undefined ? newContextData.roleId : userContext.roleId), 0, 0, 0, 0, false);
                          //}
                          if ((curIndex + 1) <= (allRoles.length - 1)) {
                            let curRole = allRoles[curIndex + 1];
                            updateUserContext({
                              ...userContext,
                              teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                              teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                              teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                              isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                              roleId: +curRole.uid,
                              roleName: curRole.name,
                              activityId: 0,
                              activityName: "",
                              cessId: 0,
                              cessName: "",
                              stepId: 0,
                              stepName: "",
                              hiagramProgress: +newProgress
                            });
                            nextQus = 21;
                            // nextQus = 14;
                          } else {
                            //isAllRoleCompleted = true;
                            const data: any = await GetHiagramTeamProj(userContext.hiagramId);

                            if (data !== null && data.data !== null && data.data.data !== null) {
                              let allTeamProj = data.data.data as any[];
                              if (allTeamProj.length > 0) {
                                let curIndex = allTeamProj.findIndex((teamProj: any) => +teamProj.uid === (newContextData !== undefined ? +newContextData.teamProjId : +userContext.teamProjId)
                                  && (teamProj.is_proj.toString() === "0" ? "false" : "true") === (newContextData !== undefined ? newContextData.isProj : userContext.isProj));
                                let newProgress = 0;
                                if ((newContextData !== undefined ? newContextData.isProj : userContext.isProj) === "true") {
                                  newProgress = await updateProgress(0, 0, (newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId), 0, 0, false);
                                } else {
                                  newProgress = await updateProgress(0, (newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId), 0, 0, 0, false);
                                }
                                let remainingDataIndex = allTeamProj.findIndex((teamProj: any) => teamProj.is_completed === false);
                                if (isFromZoom && remainingDataIndex === -1) {
                                  isAllTeamProjectCompleted = true;
                                }
                                //else {
                                if ((curIndex + 1) <= (allTeamProj.length - 1) && !isAllTeamProjectCompleted) {
                                  let curTeamProj = allTeamProj[curIndex + 1];
                                  updateUserContext({
                                    ...userContext,
                                    teamProjId: +curTeamProj.uid,
                                    teamProjName: curTeamProj.name,
                                    teamProjPurpose: curTeamProj.purpose,
                                    isProj: curTeamProj.is_proj.toString() === "0" ? "false" : "true",
                                    roleId: +curTeamProj.role[0].uid,
                                    roleName: curTeamProj.role[0].name,
                                    activityId: 0,
                                    activityName: "",
                                    cessId: 0,
                                    cessName: "",
                                    stepId: 0,
                                    stepName: "",
                                    hiagramProgress: +newProgress
                                  });
                                  nextQus = 4;
                                  //nextQus = 61;
                                } else {
                                  isAllTeamProjectCompleted = true;
                                }
                                //}
                              }
                            }
                          }
                        }
                      }
                    } else {
                      isAllActivityCompleted = true;
                    }
                    // hp code end

                  }
                }
              }
            }
            //New 
            if (isAllActivityCompleted === true) {
              // updateUserContext({
              //   ...userContext,
              //   teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
              //   teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
              //   teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
              //   isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
              //   roleId: newContextData !== undefined ? newContextData.roleId : userContext.roleId,
              //   roleName: newContextData !== undefined ? newContextData.roleName : userContext.roleName,
              //   activityId: 0,
              //   activityName: "",
              //   cessId: 0,
              //   cessName: "",
              //   stepId: 0,
              //   stepName: ""
              // });

              // hp code start
              updateUserContext({
                ...userContext,
                teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                roleId: newContextData !== undefined ? newContextData.roleId : userContext.roleId,
                roleName: newContextData !== undefined ? newContextData.roleName : userContext.roleName,
                activityId: 0,
                activityName: "",
                cessId: 0,
                cessName: "",
                stepId: 0,
                stepName: ""
              });
              // hp code end
              // nextQus = 65;
              nextQus = 67;
            }

            // if (isAllActivityCompleted === true) {
            if (+currentQuestion.number === 46) {
              let data: any;
              data = await getAllRole(newContextData);
              if (data != null && data.data != null && data.data.data != null) {
                let allRoles = data.data.data as any[];
                if (allRoles.length > 0) {
                  let curIndex = allRoles.findIndex((role: any) => +role.uid === (newContextData !== undefined ? +newContextData.roleId : +userContext.roleId));
                  //if (curIndex > -1) {
                  //progress = Math.round(progress + ((100 / userContext.totalTeamProj) / allRoles.length));
                  //setHiagramProgress(progress);
                  let newProgress = await updateProgress((newContextData !== undefined ? newContextData.roleId : userContext.roleId), 0, 0, 0, 0, false);
                  //}
                  if ((curIndex + 1) <= (allRoles.length - 1)) {
                    let curRole = allRoles[curIndex + 1];
                    updateUserContext({
                      ...userContext,
                      teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                      teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                      teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                      isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                      roleId: +curRole.uid,
                      roleName: curRole.name,
                      activityId: 0,
                      activityName: "",
                      cessId: 0,
                      cessName: "",
                      stepId: 0,
                      stepName: "",
                      hiagramProgress: +newProgress
                    });
                    nextQus = 21;
                    // nextQus = 14;
                  } else {
                    isAllRoleCompleted = true;
                  }
                }
              }
            }

            //New 
            if (isAllRoleCompleted === true) {
              updateUserContext({
                ...userContext,
                teamProjId: newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId,
                teamProjName: newContextData !== undefined ? newContextData.teamProjName : userContext.teamProjName,
                teamProjPurpose: newContextData !== undefined ? newContextData.teamProjPurpose : userContext.teamProjPurpose,
                isProj: newContextData !== undefined ? newContextData.isProj : userContext.isProj,
                roleId: 0,
                roleName: "",
                activityId: 0,
                activityName: "",
                cessId: 0,
                cessName: "",
                stepId: 0,
                stepName: "",
              });
              nextQus = 67;
            }

            // if (isAllRoleCompleted === true) {
            if (+currentQuestion.number === 47) {
              const data: any = await GetHiagramTeamProj(userContext.hiagramId);
              if (data !== null && data.data !== null && data.data.data !== null) {
                let allTeamProj = data.data.data as any[];
                if (allTeamProj.length > 0) {
                  let curIndex = allTeamProj.findIndex((teamProj: any) => +teamProj.uid === (newContextData !== undefined ? +newContextData.teamProjId : +userContext.teamProjId)
                    && (teamProj.is_proj.toString() === "0" ? "false" : "true") === (newContextData !== undefined ? newContextData.isProj : userContext.isProj));
                  let newProgress = 0;
                  if ((newContextData !== undefined ? newContextData.isProj : userContext.isProj) === "true") {
                    newProgress = await updateProgress(0, 0, (newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId), 0, 0, false);
                  } else {
                    newProgress = await updateProgress(0, (newContextData !== undefined ? newContextData.teamProjId : userContext.teamProjId), 0, 0, 0, false);
                  }
                  let remainingDataIndex = allTeamProj.findIndex((teamProj: any) => teamProj.is_completed === false);
                  if (isFromZoom && remainingDataIndex === -1) {
                    isAllTeamProjectCompleted = true;
                  }
                  //else {
                  if ((curIndex + 1) <= (allTeamProj.length - 1) && !isAllTeamProjectCompleted) {
                    let curTeamProj = allTeamProj[curIndex + 1];
                    updateUserContext({
                      ...userContext,
                      teamProjId: +curTeamProj.uid,
                      teamProjName: curTeamProj.name,
                      teamProjPurpose: curTeamProj.purpose,
                      isProj: curTeamProj.is_proj.toString() === "0" ? "false" : "true",
                      roleId: 0,
                      roleName: "",
                      activityId: 0,
                      activityName: "",
                      cessId: 0,
                      cessName: "",
                      stepId: 0,
                      stepName: "",
                      hiagramProgress: +newProgress
                    });
                    //nextQus = 4;
                    nextQus = 61;
                  } else {
                    isAllTeamProjectCompleted = true;
                  }
                  //}
                }
              }
            }

            if (isAllTeamProjectCompleted === true) {
              nextQus = 53;
            }
          }
        }

        if (nextQus === 6) {
          setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: true, isAIVisible: false, dropdownLabel: "Status", secUid: 4, isWordCount: false });
        } else if (nextQus === 61) {
          setStandardOneConfig({ ...standardOneConfig, label: "Purpose", placeholder: "Purpose", numberOfLines: 4, isDropdownVisible: false, isAIVisible: false, dropdownLabel: "", secUid: 0, isWordCount: true });
        } else if (nextQus === 53) {
          setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: false, isAIVisible: false, dropdownLabel: "", secUid: 0, isWordCount: false });
        } else {
          setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: false, isAIVisible: true, dropdownLabel: "", secUid: 0, isWordCount: false });
        }
      }
    } catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.message,
          alertType: AlertType.Error,
        });
      }
    }
    //For Team/Project Loop
    if (curQuestionId !== nextQus) {

      // if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "PROJ") {
      //   fetchTeamProj(undefined, isFromZoom).then(() => setCurQuestionId(nextQus));
      // }
      // else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "ROLE") {
      //   fetchRoles(newContextData, isFromZoom).then((e) => {
      //     if (e.length > 0) {
      //       setCurQuestionId(nextQus);
      //     }
      //   });
      // } else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "ACTIVITY") {
      //   fetchActivity(newContextData, isFromZoom).then((e) => {
      //     if (e.length > 0) {
      //       setCurQuestionId(nextQus);
      //     }
      //   });
      // } else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "CESS") {
      //   if (isSkipCess === true) {
      //     setCurQuestionId(nextQus);
      //   } else {
      //     fetchCess(newContextData, isFromZoom).then((e) => {
      //       if (e.length > 0) {
      //         setCurQuestionId(nextQus);
      //       }
      //     });
      //   }
      // }
      // // else if (+currentQuestion.number === 32) {
      // //   // fetchStep(newContextData).then((e) => {
      // //   //   if (e.length > 0) {
      // //   //     setCurQuestionId(nextQus);
      // //   //   }
      // //   // });
      // //   setCurQuestionId(nextQus);
      // // }
      // else {
      //   setCurQuestionId(nextQus);
      // }

      // hp code start
      if (+userContext.tempUid !== 3) {
        if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "PROJ") {
          fetchTeamProj(undefined, isFromZoom).then(() => setCurQuestionId(nextQus));
        }
        else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "ROLE") {
          fetchRoles(newContextData, isFromZoom).then((e) => {
            if (e.length > 0) {
              setCurQuestionId(nextQus);
            }
          });
        } else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "ACTIVITY") {
          fetchActivity(newContextData, isFromZoom).then((e) => {
            if (e.length > 0) {
              setCurQuestionId(nextQus);
            }
          });
        } else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "CESS") {
          if (isSkipCess === true) {
            setCurQuestionId(nextQus);
          } else {
            fetchCess(newContextData, isFromZoom).then((e) => {
              if (e.length > 0) {
                setCurQuestionId(nextQus);
              }
            });
          }
        }
        else {

          setCurQuestionId(nextQus);
        }
      } else {
        if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "PROJ") {
          fetchTeamProj(undefined, isFromZoom).then(() => setCurQuestionId(nextQus));
        }
        else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "ROLE") {
          fetchRoles(newContextData, isFromZoom).then((e) => {
            if (e.length > 0) {
              setCurQuestionId(nextQus);
            }
          });
        } else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "ACTIVITY") {
          fetchActivity(newContextData, isFromZoom).then((e) => {
            if (e.length > 0) {
              setCurQuestionId(nextQus);
            }
          });
        } else if (currentQuestion.type.toUpperCase() === "STANDARD_MANY" && currentQuestion.append_to.toUpperCase() === "CESS") {
          if (isSkipCess === true) {
            setCurQuestionId(nextQus);
          } else {
            fetchCess(newContextData, isFromZoom).then((e) => {
              if (e.length > 0) {
                setCurQuestionId(nextQus);
              }
            });
          }
        }
        // else if (currentQuestion.type.toUpperCase() === "STANDARD_ONE" && currentQuestion.append_field !== null && currentQuestion.append_field === "DESCRIPTION" && currentQuestion.append_to !== null && currentQuestion.append_to.toUpperCase() === "TEAM/PROJ") {
        //   fetchRoles(newContextData, isFromZoom).then((e) => {
        //     if (e.length > 0) {
        //       setCurQuestionId(nextQus);
        //     }
        //   });
        // }

        else {
          setCurQuestionId(nextQus);
        }
      }

      // hp code end
    } else {
      setZoomButtonLoading(false);
    }
    //setChecked(true);
  }

  async function PreviousQuestion() {
    setPreviousButtonLoading(true);
    try {
      let previousQus = 0;
      if (curQuestion.is_use_logic !== null && curQuestion.is_use_logic.toString() === "true") {
        if (+curQuestion.number === 5 && userContext.isProj.toString() === "false") {
          previousQus = curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid;
        }
        else if (curQuestion.append_to !== null && curQuestion.append_type !== null && curQuestion.append_to.toUpperCase() === "ACTIVITY" && curQuestion.append_type.toUpperCase() === "FINAL") {
          const Cess: any = await getAllCess();
          if (Cess !== null && Cess.data !== null && Cess.data.data !== null) {
            let cessData = Cess.data.data;
            let curCess = cessData[cessData.length - 1];
            if (curCess !== undefined && curCess !== null) {
              updateUserContext({
                ...userContext,
                cessId: +curCess.uid,
                cessName: curCess.name,
                stepId: 0,
                stepName: "",
              });
              if (curCess.detail_skip === false) {
                previousQus = curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid;
              } else {
                previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
              }
            } else {
              previousQus = curQuestion.templateQuestionMapping[0].logic_no_next_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].logic_no_next_question_uid;
            }
          } else {
            previousQus = curQuestion.templateQuestionMapping[0].logic_no_next_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].logic_no_next_question_uid;
          }
        } else if (curQuestion.append_to !== null && curQuestion.append_type !== null && curQuestion.append_to.toUpperCase() === "ROLE" && curQuestion.append_type.toUpperCase() === "FINAL") {
          const activities: any = await getAllActivity();
          if (activities !== undefined && activities !== null && activities.data !== null && activities.data.data !== null) {
            let activityData = activities.data.data;
            let curActivity = activityData[activityData.length - 1];
            if (curActivity !== undefined && curActivity !== null) {
              updateUserContext({
                ...userContext,
                activityId: +curActivity.uid,
                activityName: curActivity.name,
                cessId: 0,
                cessName: "",
                stepId: 0,
                stepName: "",
              });
            }
          }
          previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
        } else if (curQuestion.append_to !== null && curQuestion.append_type !== null && curQuestion.append_to.toUpperCase() === "HIAGRAM" && curQuestion.append_type.toUpperCase() === "SINGLE") {
          // new 16/04
          if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
            const activities: any = await getAllActivity();
            if (activities !== undefined && activities !== null && activities.data !== null && activities.data.data !== null) {
              let activityData = activities.data.data;
              let curActivity = activityData[activityData.length - 1];
              if (curActivity !== undefined && curActivity !== null) {
                updateUserContext({
                  ...userContext,
                  teamProjId: curActivity.role.proj === null ? +curActivity.role.team.uid : +curActivity.role.proj.uid,
                  teamProjName: curActivity.role.proj === null ? curActivity.role.team.name : curActivity.role.proj.name,
                  teamProjPurpose: curActivity.role.proj === null ? curActivity.role.team.purpose : curActivity.role.proj.purpose,
                  isProj: curActivity.role.proj === null ? "false" : "true",
                  roleId: +curActivity.role.uid,
                  roleName: curActivity.role.name,
                  activityId: +curActivity.uid,
                  activityName: curActivity.name,
                  cessId: 0,
                  cessName: "",
                  stepId: 0,
                  stepName: "",
                });
              }
            }
            previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
          } else {
            const teamProjs: any = await GetHiagramTeamProj(userContext.hiagramId);
            if (teamProjs !== undefined && teamProjs !== null && teamProjs.data !== null && teamProjs.data.data !== null) {
              let teamProjData = teamProjs.data.data;
              let curteamProj = teamProjData[teamProjData.length - 1];
              if (curteamProj !== undefined && curteamProj !== null) {
                updateUserContext({
                  ...userContext,
                  teamProjId: +curteamProj.uid,
                  teamProjName: curteamProj.name,
                  teamProjPurpose: curteamProj.purpose,
                  isProj: curteamProj.is_proj.toString() === "0" ? "false" : "true",
                  roleId: 0,
                  roleName: "",
                  activityId: 0,
                  activityName: "",
                  cessId: 0,
                  cessName: "",
                  stepId: 0,
                  stepName: "",
                });
              }
            }
            previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
          }
        } else if (curQuestion.append_to !== null && curQuestion.append_type !== null && curQuestion.append_to.toUpperCase() === "CESS" && curQuestion.append_type.toUpperCase() === "SINGLE") {
          const cesses: any = await getAllCess();
          if (cesses !== undefined && cesses !== null && cesses.data !== null && cesses.data.data !== null) {
            let cessData = cesses.data.data;
            let curDataIndex = cessData.findIndex((cess: any) => +cess.uid === userContext.cessId);
            if (curDataIndex > 0) {
              let curCess = cessData[curDataIndex - 1];
              updateUserContext({
                ...userContext,
                cessId: +curCess.uid,
                cessName: curCess.name,
                stepId: 0,
                stepName: "",
              });
              if (curCess.detail_skip === false) {
                previousQus = curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid;
              } else {
                previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
              }
            } else {
              previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
            }
          } else {
            previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
          }
        } else if (curQuestion.append_to !== null && curQuestion.append_type !== null && curQuestion.append_to.toUpperCase() === "ACTIVITY" && curQuestion.append_type.toUpperCase() === "SINGLE") {
          const activities: any = await getAllActivity();
          if (activities !== undefined && activities !== null && activities.data !== null && activities.data.data !== null) {
            let activityData = activities.data.data;
            let curDataIndex = activityData.findIndex((activity: any) => +activity.uid === userContext.activityId);
            if (curDataIndex > 0) {
              let newUid = +activityData[curDataIndex - 1].uid;
              let newName = activityData[curDataIndex - 1].name;
              updateUserContext({
                ...userContext,
                activityId: newUid,
                activityName: newName,
                cessId: 0,
                cessName: "",
                stepId: 0,
                stepName: "",
              });
              previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
            } else {
              previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
            }
          } else {
            previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
          }
        } else if (curQuestion.append_to !== null && curQuestion.append_type !== null && curQuestion.append_to.toUpperCase() === "ROLE" && curQuestion.append_type.toUpperCase() === "SINGLE") {
          const roles: any = await getAllRole();
          if (roles !== undefined && roles !== null && roles.data !== null && roles.data.data !== null) {
            let roleData = roles.data.data;
            let curDataIndex = roleData.findIndex((role: any) => +role.uid === userContext.roleId);
            if (curDataIndex > 0) {
              let newUid = +roleData[curDataIndex - 1].uid;
              let newName = roleData[curDataIndex - 1].name;
              updateUserContext({
                ...userContext,
                roleId: newUid,
                roleName: newName,
                activityId: 0,
                activityName: "",
                cessId: 0,
                cessName: "",
                stepId: 0,
                stepName: "",
              });
              previousQus = curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid;
            } else {
              previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
            }
          } else {
            previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
          }
        } else if (curQuestion.append_to !== null && curQuestion.append_type !== null && curQuestion.append_to.toUpperCase() === "TEAM/PROJ" && curQuestion.append_type.toUpperCase() === "SINGLE" && curQuestion.append_field.toUpperCase() === "PURPOSE") {
          const teamProjs: any = await GetHiagramTeamProj(userContext.hiagramId);
          if (teamProjs !== undefined && teamProjs !== null && teamProjs.data !== null && teamProjs.data.data !== null) {
            let teamProjData = teamProjs.data.data;
            let curDataIndex = teamProjData.findIndex((teamProj: any) => +teamProj.uid === userContext.teamProjId);
            if (curDataIndex > 0) {
              let newUid = +teamProjData[curDataIndex - 1].uid;
              let newName = teamProjData[curDataIndex - 1].name;
              let newPurpose = teamProjData[curDataIndex - 1].purpose;
              let newisProj = teamProjData[curDataIndex - 1].is_proj.toString() === "0" ? "false" : "true";
              updateUserContext({
                ...userContext,
                teamProjId: newUid,
                teamProjName: newName,
                teamProjPurpose: newPurpose,
                isProj: newisProj,
                roleId: 0,
                roleName: "",
                activityId: 0,
                activityName: "",
                cessId: 0,
                cessName: "",
                stepId: 0,
                stepName: "",
              });
              previousQus = curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid;
            } else {
              previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
            }
          } else {
            previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
          }
        } else {
          previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
        }
      } else if (curQuestion.append_to !== null && curQuestion.append_type !== null && curQuestion.append_to.toUpperCase() === "TEAM/PROJ" && curQuestion.append_type.toUpperCase() === "NONE") {

        // hp code
        /// new 16/4
        // const roles: any = await getAllRole();
        // if (roles !== undefined && roles !== null && roles.data !== null && roles.data.data !== null) {
        //   let roleData = roles.data.data;
        //   let curRole = roleData[roleData.length - 1];
        //   if (curRole !== undefined && curRole !== null) {
        //     updateUserContext({
        //       ...userContext,
        //       roleId: +curRole.uid,
        //       roleName: curRole.name,
        //       activityId: 0,
        //       activityName: "",
        //       cessId: 0,
        //       cessName: "",
        //       stepId: 0,
        //       stepName: "",
        //     });
        //   }
        // }
        // previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;

        const activities: any = await getAllActivity();
        if (activities !== undefined && activities !== null && activities.data !== null && activities.data.data !== null) {
          previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
        }

      } else {

        // previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;

        //hp code start
        if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
          if (userContext.isProj.toString() === "false" && curQuestion.templateQuestionMapping[0].previous_question_uid !== null && +curQuestion.templateQuestionMapping[0].previous_question_uid === 7) {
            previousQus = curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].logic_no_previous_question_uid;
          } else {
            previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid === null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
          }
        }
        else {
          previousQus = curQuestion.templateQuestionMapping[0].previous_question_uid == null ? 0 : +curQuestion.templateQuestionMapping[0].previous_question_uid;
        }
        //hp code end

      }

      if (previousQus === 6) {
        setStandardOneConfig({ ...standardOneConfig, label: "Description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: true, isAIVisible: false, dropdownLabel: "Status", secUid: 4, isWordCount: false });
      } else if (previousQus === 61) {
        setStandardOneConfig({ ...standardOneConfig, label: "Purpose", placeholder: "Purpose", numberOfLines: 4, isDropdownVisible: false, isAIVisible: false, dropdownLabel: "", secUid: 0, isWordCount: true });
      } else if (previousQus === 53) {
        setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: false, isAIVisible: false, dropdownLabel: "", secUid: 0, isWordCount: false });
      } else {
        setStandardOneConfig({ ...standardOneConfig, label: "Description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: false, isAIVisible: true, dropdownLabel: "", secUid: 0, isWordCount: false });
      }
      setCurQuestionId(previousQus);
      //setChecked(true);
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function stepdetail(uid: any) {
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("uid", uid.toString());
      const data: any = await GetStepdetailById(params);
      if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
        return data.data.data;
      } else {
        return null;
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function cessdetail(uid: any) {
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("uid", uid.toString());
      const data: any = await GetCessdetailById(params);
      if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
        return data.data.data;
      } else {
        return null;
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function activitydetail(uid: any) {
    try {

      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("uid", uid.toString());
      const data: any = await GetActivitydetailById(params);
      if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
        return data.data.data;
      } else {
        return null;
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function roledetail(uid: any) {
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("uid", uid.toString());
      const data: any = await GetRoledetailById(params);
      if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
        return data.data.data;
      } else {
        return null;
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function teamdetail(uid: any) {
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("uid", uid.toString());
      const data: any = await GetTeamdetailById(params);
      if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
        return data.data.data;
      } else {
        return null;
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function projectdetail(uid: any) {
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("uid", uid.toString());
      const data: any = await GetProjdetailById(params);
      if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
        return data.data.data;
      } else {
        return null;
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  function ChangeUserContext(teamProjId: number, teamProjName: any, teamProjPurpose: any, roleId: number, roleName: any,
    activityId: number, activityName: any, cessId: number, cessName: any, stepId: number, stepName: any, isProj: string) {
    updateUserContext({
      ...userContext,
      teamProjId: teamProjId,
      teamProjName: teamProjName,
      teamProjPurpose: teamProjPurpose,
      roleId: roleId,
      roleName: roleName,
      activityId: activityId,
      activityName: activityName,
      cessId: cessId,
      cessName: cessName,
      stepId: stepId,
      stepName: stepName,
      isProj: isProj
    });
    return ({
      teamProjId: teamProjId,
      teamProjName: teamProjName,
      teamProjPurpose: teamProjPurpose,
      roleId: roleId,
      roleName: roleName,
      activityId: activityId,
      activityName: activityName,
      cessId: cessId,
      cessName: cessName,
      stepId: stepId,
      stepName: stepName,
      isProj: isProj
    });
  }

  async function ZoomToNextQuestion() {
    setZoomButtonLoading(true);
    try {
      if (hiagramProgress === 100) {
        setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: false, isAIVisible: false, dropdownLabel: "", secUid: 0, isWordCount: false });
        setCurQuestionId(53)
      } else {
        let data: any;
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        data = await GetLastAttemptedQuestion(params);
        if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
          let lastQuestionData = data.data.data;
          let curQuestionData: any = undefined;
          if (lastQuestionData.question_uid !== "1") {

            const questionData: any = await GetQuestionById(lastQuestionData.question_uid, userContext.tempUid);
            if (questionData !== undefined && questionData !== null && questionData.data !== null && questionData.data.data !== null) {
              curQuestionData = questionData.data.data;
            }
            if (lastQuestionData.step_uid !== null && lastQuestionData.step_uid !== "0") {
              let stepUid = +lastQuestionData.step_uid;
              stepdetail(stepUid).then((step) => {
                if (step !== null) {
                  let curStep = step;
                  if (curStep.cess_uid !== null && curStep.cess_uid !== "0") {
                    let cessUid = +curStep.cess_uid;
                    cessdetail(cessUid).then((cess) => {
                      if (cess !== null) {
                        let curCess = cess;
                        if (curCess.activity_uid !== null && curCess.activity_uid !== "0") {
                          let activityUid = +curCess.activity_uid;
                          activitydetail(activityUid).then((activity) => {
                            if (activity !== null) {
                              let curActivity = activity;
                              if (curActivity.role_uid !== null && curActivity.role_uid !== "0") {
                                let roleUid = +curActivity.role_uid;
                                roledetail(roleUid).then((role) => {
                                  if (role !== null) {
                                    let curRole = role;
                                    if (role.team_uid !== null && role.team_uid !== "0") {
                                      let teamUid = +role.team_uid;
                                      teamdetail(teamUid).then((team) => {
                                        let curTeam = team;
                                        if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                                          curRole.uid = curTeam.role[0].uid;
                                        }
                                        let newContextData: any = ChangeUserContext(+curTeam.uid, curTeam.name, curTeam.purpose, +curRole.uid, curRole.name, +curActivity.uid, curActivity.name, +curCess.uid, curCess.name, +curStep.uid, curStep.name, "false");
                                        // setTimeout(() => {
                                        //   ChangeNextQuestion(curQuestionData, newContextData);
                                        // }, 2000);
                                        ChangeNextQuestion(curQuestionData, newContextData, true);
                                      });
                                    } else if (role.proj_uid !== null && role.proj_uid !== "0") {
                                      let projUid = +role.proj_uid;
                                      projectdetail(projUid).then((proj) => {
                                        let curProj = proj;
                                        if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                                          curRole.uid = curProj.role[0].uid;
                                        }
                                        let newContextData: any = ChangeUserContext(+curProj.uid, curProj.name, curProj.purpose, +curRole.uid, curRole.name, +curActivity.uid, curActivity.name, +curCess.uid, curCess.name, +curStep.uid, curStep.name, "true");
                                        // setTimeout(() => {
                                        //   ChangeNextQuestion(curQuestionData, newContextData);
                                        // }, 2000);
                                        ChangeNextQuestion(curQuestionData, newContextData, true);
                                      });
                                    }
                                  }
                                });
                              }
                            }
                          });
                        }
                      }
                    });
                  }
                }
              });
            } else if (lastQuestionData.cess_uid !== null && lastQuestionData.cess_uid !== "0") {
              let cessUid = +lastQuestionData.cess_uid;
              cessdetail(cessUid).then((cess) => {
                if (cess !== null) {
                  let curCess = cess;
                  if (curCess.activity_uid !== null && curCess.activity_uid !== "0") {
                    let activityUid = +curCess.activity_uid;
                    activitydetail(activityUid).then((activity) => {
                      if (activity !== null) {
                        let curActivity = activity;
                        if (curActivity.role_uid !== null && curActivity.role_uid !== "0") {
                          let roleUid = +curActivity.role_uid;
                          roledetail(roleUid).then((role) => {
                            if (role !== null) {
                              let curRole = role;
                              if (curRole.team_uid !== null && curRole.team_uid !== "0") {
                                let teamUid = +curRole.team_uid;
                                teamdetail(teamUid).then((team) => {
                                  let curTeam = team;
                                  if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                                    curRole.uid = curTeam.role[0].uid;
                                  }
                                  let newContextData: any = ChangeUserContext(+curTeam.uid, curTeam.name, curTeam.purpose, +curRole.uid, curRole.name, +curActivity.uid, curActivity.name, +curCess.uid, curCess.name, 0, "", "false");
                                  // setTimeout(() => {
                                  //   ChangeNextQuestion(curQuestionData, newContextData);
                                  // }, 2000);
                                  ChangeNextQuestion(curQuestionData, newContextData, true);
                                });
                              } else if (role.proj_uid !== null && role.proj_uid !== "0") {
                                let projUid = +role.proj_uid;
                                projectdetail(projUid).then((proj) => {
                                  let curProj = proj;
                                  if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                                    curRole.uid = curProj.role[0].uid;
                                  }
                                  let newContextData: any = ChangeUserContext(+curProj.uid, curProj.name, curProj.purpose, +curRole.uid, curRole.name, +curActivity.uid, curActivity.name, +curCess.uid, curCess.name, 0, "", "true");
                                  // setTimeout(() => {
                                  //   ChangeNextQuestion(curQuestionData, newContextData);
                                  // }, 2000);
                                  ChangeNextQuestion(curQuestionData, newContextData, true);
                                });
                              }
                            }
                          });
                        }
                      }
                    });
                  }
                }
              });
            } else if (lastQuestionData.activity_uid !== null && lastQuestionData.activity_uid !== "0") {
              let activityUid = +lastQuestionData.activity_uid;
              activitydetail(activityUid).then((activity) => {
                if (activity !== null) {
                  let curActivity = activity;
                  if (curActivity.role_uid !== null && curActivity.role_uid !== "0") {
                    let roleUid = +curActivity.role_uid;
                    roledetail(roleUid).then((role) => {
                      if (role !== null) {
                        let curRole = role;
                        if (role.team_uid !== null && role.team_uid !== "0") {
                          let teamUid = +role.team_uid;
                          teamdetail(teamUid).then((team) => {
                            let curTeam = team;
                            if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                              curRole.uid = curTeam.role[0].uid;
                            }
                            let newContextData: any = ChangeUserContext(+curTeam.uid, curTeam.name, curTeam.purpose, +curRole.uid, curRole.name, +curActivity.uid, curActivity.name, 0, "", 0, "", "false");
                            // setTimeout(() => {
                            //   ChangeNextQuestion(curQuestionData, newContextData);
                            // }, 2000);
                            ChangeNextQuestion(curQuestionData, newContextData, true);
                          });
                        } else if (role.proj_uid !== null && role.proj_uid !== "0") {
                          let projUid = +role.proj_uid;
                          projectdetail(projUid).then((proj) => {
                            let curProj = proj;
                            if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                              curRole.uid = curProj.role[0].uid;
                            }
                            let newContextData: any = ChangeUserContext(+curProj.uid, curProj.name, curProj.purpose, +curRole.uid, curRole.name, +curActivity.uid, curActivity.name, 0, "", 0, "", "true");
                            // setTimeout(() => {
                            //   ChangeNextQuestion(curQuestionData, newContextData);
                            // }, 2000);
                            ChangeNextQuestion(curQuestionData, newContextData, true);
                          });
                        }
                      }
                    });
                  }
                }
              });
            } else if (lastQuestionData.role_uid !== null && lastQuestionData.role_uid !== "0") {
              let roleUid = +lastQuestionData.role_uid;
              roledetail(roleUid).then((role) => {

                if (role !== null) {
                  let curRole = role;
                  if (role.team_uid !== null && role.team_uid !== "0") {
                    let teamUid = +role.team_uid;
                    teamdetail(teamUid).then((team) => {
                      let curTeam = team;
                      if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                        curRole.uid = curTeam.role[0].uid;
                      }
                      let newContextData: any = ChangeUserContext(+curTeam.uid, curTeam.name, curTeam.purpose, +curRole.uid, curRole.name, 0, "", 0, "", 0, "", "false");
                      // setTimeout(() => {
                      //   ChangeNextQuestion(curQuestionData, newContextData);
                      // }, 2000);
                      ChangeNextQuestion(curQuestionData, newContextData, true);
                    });
                  } else if (role.proj_uid !== null && role.proj_uid !== "0") {
                    let projUid = +role.proj_uid;
                    projectdetail(projUid).then((proj) => {
                      let curProj = proj;
                      if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                        curRole.uid = curProj.role[0].uid;
                      }
                      let newContextData: any = ChangeUserContext(+curProj.uid, curProj.name, curProj.purpose, +curRole.uid, curRole.name, 0, "", 0, "", 0, "", "true");
                      // setTimeout(() => {
                      //   ChangeNextQuestion(curQuestionData, newContextData);
                      // }, 2000);
                      ChangeNextQuestion(curQuestionData, newContextData, true);
                    });
                  }
                }
              });
            } else if (lastQuestionData.team_uid !== null && lastQuestionData.team_uid !== "0") {
              let teamUid = +lastQuestionData.team_uid;
              teamdetail(teamUid).then((team) => {
                let curTeam = team;
                let newRoleId = 0;
                if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                  newRoleId = +curTeam.role[0].uid;
                }
                let newContextData: any = ChangeUserContext(+curTeam.uid, curTeam.name, curTeam.purpose, newRoleId, "", 0, "", 0, "", 0, "", "false");
                // setTimeout(() => {
                //   ChangeNextQuestion(curQuestionData, newContextData);
                // }, 2000);
                ChangeNextQuestion(curQuestionData, newContextData, true);
              });

            } else if (lastQuestionData.proj_uid !== null && lastQuestionData.proj_uid !== "0") {
              let projUid = +lastQuestionData.proj_uid;
              projectdetail(projUid).then((proj) => {
                let newRoleId = 0;
                let curProj = proj;
                if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                  newRoleId = +curProj.role[0].uid;
                }
                let newContextData: any = ChangeUserContext(+curProj.uid, curProj.name, curProj.purpose, newRoleId, "", 0, "", 0, "", 0, "", "true");
                // setTimeout(() => {
                //   ChangeNextQuestion(curQuestionData, newContextData);
                // }, 2000);
                ChangeNextQuestion(curQuestionData, newContextData, true);
              });
            } else if (lastQuestionData.proj_uid === "0") {
              ChangeNextQuestion(curQuestionData);
              // let projUid = +lastQuestionData.proj_uid;
              // Projectdetail(projUid).then((proj) => {
              //   let curProj = proj;
              //   ChangeUserContext(+curProj.uid, curProj.name, 0, "", 0, "", 0, "", 0, "", "1");
              //   ChangeNextQuestion(curQuestionData);
              // });
            } else {
              if (hiagramProgress === 100) {
                setStandardOneConfig({ ...standardOneConfig, label: "Your description", placeholder: "Description", numberOfLines: 10, isDropdownVisible: false, isAIVisible: false, dropdownLabel: "", secUid: 0, isWordCount: false });
                setCurQuestionId(53)
              } else {
                ChangeNextQuestion(curQuestionData);
              }

            }
          } else {
            setZoomButtonLoading(false);
          }
        } else {
          let curQuestionData: any = undefined;
          if (+curQuestion.number === 1) {
            ChangeNextQuestion(curQuestion);
          } else if (+curQuestion.number === 41) {
            const questionData: any = await GetQuestionById(3, userContext.tempUid);
            if (questionData !== undefined && questionData !== null && questionData.data !== null && questionData.data.data !== null) {
              curQuestionData = questionData.data.data;
            }
            const hiagram: any = await GetHiagramTeamProj(userContext.hiagramId);
            if (hiagram !== null && hiagram.data !== null && hiagram.data.data !== null) {
              let hiagramData = hiagram.data.data;
              if (hiagramData.length > 0) {
                let newTeamProjId = 0;
                let newTeamProjName = "";
                let newTeamProjPurpose = "";
                let newisProj = "false";
                let newRole = 0;
                let newRoleName = "";
                let remainingDataIndex = hiagramData.findIndex((teamProj: any) => teamProj.is_completed === false);
                if (remainingDataIndex >= 0) {
                  newTeamProjId = hiagramData[remainingDataIndex].uid;
                  newTeamProjName = hiagramData[remainingDataIndex].name;
                  newTeamProjPurpose = hiagramData[remainingDataIndex].purpose;
                  newisProj = hiagramData[remainingDataIndex].is_proj.toString() === "0" ? "false" : "true";
                  if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                    newRole = +hiagramData[remainingDataIndex].role[0].uid;
                  }
                } else {
                  if (newTeamProjId === undefined || newTeamProjId === 0) {
                    newTeamProjId = hiagramData[0].uid;
                    newTeamProjName = hiagramData[0].name;
                    newTeamProjPurpose = hiagramData[0].purpose;
                    newisProj = hiagramData[0].is_proj.toString() === "0" ? "false" : "true";
                    if (+userContext.tempUid === 3 || +userContext.tempUid === 2) {
                      newRole = +hiagramData[0].role[0].uid;
                      newRoleName = hiagramData[0].role[0].name;
                      //
                    }
                  }
                }
                let newContextData: any = ChangeUserContext(+newTeamProjId, newTeamProjName, newTeamProjPurpose, newRole, "", 0, "", 0, "", 0, "", newisProj);

                ChangeNextQuestion(curQuestionData, newContextData, true);
              }
            }
          }
          setZoomButtonLoading(false);
        }
      }

    }
    catch (error: any) {
      setZoomButtonLoading(false);
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }

  }

  const handleTextFieldKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      updateName(hiagramName);
    }
  };

  async function ZoomToFirstQuestion() {
    setZoomFirstButtonLoading(true);
    setCurQuestionId(1);
  }

  async function updateName(name: any) {
    setEdit(false);
    try {
      let requestData = {
        hiagramUid: userContext.hiagramId,
        clientUid: userContext.clientId,
        userUid: userContext.userId,
        hiagramName: name,
        dataManagedBy: userContext.userId
      }
      let data: any;
      data = await UpdateHiagramName(requestData);
      if (data !== null && data.data === true) {
        updateUserContext({
          ...userContext,
          hiagramName: name
        });
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function updateProgress(roleUid: any, teamUid: any, projUid: any, activityUid: any, cessUid: any, isUpdate: any) {
    try {
      let curProgress = 0;
      let requestData = {
        huid: userContext.hiagramId,
        clientUid: userContext.clientId,
        userUid: userContext.userId,
        tuid: teamUid,
        puid: projUid,
        ruid: roleUid,
        auid: activityUid,
        cuid: cessUid,
        dataManagedBy: userContext.userId
      }
      let data: any;
      data = await UpdateHiagramProgress(requestData);
      if (data !== null && data.data !== null) {
        curProgress = +data.data;
        setHiagramProgress(curProgress);
      }
      if (isUpdate === true) {
        updateUserContext({
          ...userContext,
          hiagramProgress: +curProgress
        });
      }
      return curProgress;
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.message,
          alertType: AlertType.Error,
        });
      }
      return 0;
    }
  }

  useEffect(() => {
    Init();
  }, []);

  useEffect(() => {
  }, [showTypewriter]);

  useEffect(() => {
    fetchQuestion(false);
    //setChecked(true);
  }, [curQuestionId]);

  useEffect(() => {
    if (childStandardOneCompRef.current !== undefined && childStandardOneCompRef.current !== null) {
      childStandardOneCompRef.current.getData();
    } else if (childStandardManyCompRef.current !== undefined && childStandardManyCompRef.current !== null) {
      childStandardManyCompRef.current.getData();
    } else if (childIteamAddActionCompRef.current !== undefined && childIteamAddActionCompRef.current !== null && (+curQuestion.number === 4 || +curQuestion.number === 19)) {
      childIteamAddActionCompRef.current.getData();
    } else if (childIteamAddStakeCompRef.current !== undefined && childIteamAddStakeCompRef.current !== null && (+curQuestion.number === 5 || +curQuestion.number === 10 || +curQuestion.number === 16 || +curQuestion.number === 29 || +curQuestion.number === 33)) {
      childIteamAddStakeCompRef.current.getData();
    } else if (childIteamAddDocCompRef.current !== undefined && childIteamAddDocCompRef.current !== null && (+curQuestion.number === 6 || +curQuestion.number === 11 || +curQuestion.number === 18 || +curQuestion.number === 26 || +curQuestion.number === 35)) {
      childIteamAddDocCompRef.current.getData();
    } else if (childIteamAddHintCompRef.current !== undefined && childIteamAddHintCompRef.current !== null && (+curQuestion.number === 7 || +curQuestion.number === 13 || +curQuestion.number === 22 || +curQuestion.number === 31 || +curQuestion.number === 36)) {
      childIteamAddHintCompRef.current.getData();
    } else if (childIteamAddTrgCompRef.current !== undefined && childIteamAddTrgCompRef.current !== null && (+curQuestion.number === 12 || +curQuestion.number === 21 || +curQuestion.number === 27)) {
      childIteamAddTrgCompRef.current.getData();
    } else if (childIteamAddAssetCompRef.current !== undefined && childIteamAddAssetCompRef.current !== null && (+curQuestion.number === 17 || +curQuestion.number === 30 || +curQuestion.number === 34)) {
      childIteamAddAssetCompRef.current.getData();
    } else if (childIteamAddEventCompRef.current !== undefined && childIteamAddEventCompRef.current !== null && +curQuestion.number === 20) {
      childIteamAddEventCompRef.current.getData();
    } else if (childRankingCompRef.current !== undefined && childRankingCompRef.current !== null && (+curQuestion.number === 23 || +curQuestion.number === 37)) {
      childRankingCompRef.current.getData();
    } else if (childDecisionCompRef.current !== undefined && childDecisionCompRef.current !== null && +curQuestion.number === 28) {
      childDecisionCompRef.current.getData();
    } else if (processBuilderRef.current !== undefined && processBuilderRef.current !== null) {
      processBuilderRef.current.getData();
    } else if (checkpointRef.current !== undefined && checkpointRef.current !== null && (+curQuestion.number === 44 || +curQuestion.number === 46 || +curQuestion.number === 47 || +curQuestion.number === 48)) {
      checkpointRef.current.getData();
    } else {
      //ChangeNextQuestion();
      //processBuilderRef.current.getData();
    }
    setTimeout(() => {
      setChecked(false);
    }, 300);
    setShowTypewriter(true);
  }, [curQuestion]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoder}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img loading="lazy" src='/loader.gif' style={{ height: '250px', width: 'auto' }} alt='loading...'></img>
      </Backdrop>

      <Box className="container-question">
        <Grid container className="save-grid">
          <Grid item xs={4} sx={{ textAlign: "start", paddingTop: "5px", height: "45px" }}>
            {/* <Link className="backspace-Button-Back" to={"/dashboard"}> 
               <KeyboardBackspaceIcon />
              Back
             </Link> */}

            {isEdit === false ?
              <Tooltip title={hiagramName}>
                {/* <Box sx={{display:'inline'}}>
                 <Box className="hiagramname-container">
                   <Typography className="hiagramname-text" onClick={handlelabelClick}>{hiagramName}</Typography>
                 </Box>
                 <Typography className="hiagramname-text-click" onClick={handlelabelClick}>- <span>click here to change</span></Typography>
               </Box> */}
                <Box className='hiagram-name-container' onClick={handlelabelClick}>
                  <Box className={hiagramName.length < 25 ? 'default-box' : 'name-box'}>
                    <Typography className="name-text">{hiagramName} </Typography></Box>
                  <Box className="click-here-box"><Typography className="click-here-text">- click here to change</Typography></Box>
                </Box>
              </Tooltip>
              :
              <TextField
                id="hiagramName"
                className="hiagramname-change"
                autoFocus
                value={hiagramName}
                inputProps={{ maxLength: 100 }}
                // variant="outlined"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                onBlur={(hiagramName) => updateName(hiagramName.target.value)}
                onChange={(hiagramName) => setHiagramName(hiagramName.target.value)}
                onKeyDown={(event) => handleTextFieldKeyDown(event)}
              />
            }

          </Grid>
          <Grid item xs={4} sx={{ textAlign: "center", paddingTop: "10px" }}>
            {hiagramProgress > 0 ?
              <Box className="status-box">
                <Typography className="status-complete">{hiagramProgress}% complete</Typography>
                <Tooltip title="Guidance">
                  <IconButton
                    className="status-guidance-icon"
                    aria-label="Guidance"
                    aria-haspopup="true"
                    color="inherit"
                    disableRipple
                    onClick={handleGuidanceForStatus}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              : <></>}
          </Grid>
          <Fade
            in={!checked}
            timeout={{ enter: 1000, exit: !checked ? 3 : 3 }}
          >
            <Grid item xs={4} sx={{ textAlign: "end" }}>
              {/* <Button className="save-button">
                Save & Quit
                <img className="quit-logo" src={logouticon} alt="logo" />
              </Button> */}

              <LoadingButton
                className="button save-quit-button"
                loading={isSubmitButtonLoading}
                variant="contained"
                onClick={gotoMyprofile}
                endIcon={
                  <img loading="lazy" src={logouticon} alt="logo" />
                }
              >
                <Typography className="text-next">Save & Quit</Typography>
              </LoadingButton>
            </Grid>
          </Fade>
        </Grid>
        <Grid className="line-up">  </Grid>
        <Box ref={containerRef} className="main-box">
          <Box className="first-box">
            <Grid container rowSpacing={3} className="first-box-grid">
              {/* <Slide
                  direction='right'
                  in={!checked}
                  container={containerRef.current}
                  mountOnEnter
                  timeout={{ enter: 1000, exit: !checked ? 1 : 2 }}
                >
                  <Grid item xs={12} className="question-heading">
                    <h2 className="text-color" dangerouslySetInnerHTML={{ __html: curQuestion.text }}>
                    </h2>
                  </Grid>
                </Slide> */}
              <Grid item xs={12} className="question-heading">
                <Box className="upper-all-name-box">
                  {userContext.teamProjName !== '' ?
                    <>
                      <Box className="current-name-box">
                        {userContext.isProj.toString() === "false" ?
                          <img loading="lazy" alt='imprend' src={peopleTeam} />
                          :
                          <img loading="lazy" alt='imprend' src={Project} />
                        }
                        <Tooltip title={userContext.teamProjName}>
                          <Typography className="current-name-text">{userContext.teamProjName}</Typography>
                        </Tooltip>
                      </Box>
                    </>
                    : <></>
                  }

                  {/* {userContext.roleName !== '' ?
                    <>
                      <ChevronRightIcon />
                      <Box className="current-name-box">
                        <img loading="lazy" alt='imprend' src={Role} />
                        <Tooltip title={userContext.roleName}>
                          <Typography className="current-name-text">{userContext.roleName}</Typography>
                        </Tooltip>
                      </Box>
                    </>
                    : <></>
                  } */}

                  {userContext.activityName !== '' ?
                    <>
                      <ChevronRightIcon />
                      <Box className="current-name-box">
                        <img loading="lazy" alt='imprend' src={Activity} />
                        <Tooltip title={userContext.activityName}>
                          <Typography className="current-name-text">{userContext.activityName}</Typography>
                        </Tooltip>
                      </Box>
                    </>
                    : <></>
                  }

                  {userContext.cessName !== '' ?
                    <>
                      <ChevronRightIcon />
                      <Box className="current-name-box">
                        <img loading="lazy" alt='imprend' src={Process} />
                        <Tooltip title={userContext.cessName}>
                          <Typography className="current-name-text">{userContext.cessName}</Typography>
                        </Tooltip>
                      </Box>
                    </>
                    : <></>
                  }

                  {/* {userContext.stepName !== '' ?
                    <>
                      <ChevronRightIcon />
                      <Box className="current-name-box">
                        <img alt='' src={Step} />
                        <Typography className="current-name-text">{userContext.stepName}</Typography>
                      </Box>
                    </>
                    : <></>
                  } */}

                </Box>
                {showTypewriter === true ?
                  <Typewriter
                    // options={{
                    //   cursor:"|"
                    // }}
                    onInit={(typewriter) => {
                      typewriter
                        .changeDelay(16)
                        .typeString(+userContext.tempUid === 3 ? curQuestion.text_free : curQuestion.text)
                        .start();
                    }}
                  /> : <></>}



              </Grid>
              <Slide
                direction={direction}
                in={!checked}
                container={containerRef.current}
                mountOnEnter
                timeout={{ enter: 1000, exit: !checked ? 1 : 2 }}
              >
                <Grid item xs={12} className="question-dec-div">
                  <Box className="guidance-main-box">
                    <Tooltip title="Guidance">
                      <Box className="button help-icon">
                        <HelpOutlineIcon
                          // sx={{ color: mainTheme.palette.primary.main }} 
                          sx={{ color: '#4fa79b' }}
                        />
                      </Box>
                    </Tooltip>
                    {/* <Tooltip title={isCollapse === true ? "Hide Guidance" : "Show Guidance"}> */}
                    <Box className="guidance-second-box" onClick={handleGuidanceShowHideClick}>
                      <Box className="guidance-box" >
                        <Typography className="guidance-text">{isCollapse === true ? "Hide guidance" : "Show guidance"}</Typography>
                      </Box>
                      <Box>
                        {isCollapse === true ?
                          <KeyboardArrowUpIcon
                            // sx={{ color: mainTheme.palette.primary.main }} 
                            sx={{ color: '#4fa79b' }}
                          /> :
                          <KeyboardArrowDownIcon
                            // sx={{ color: mainTheme.palette.primary.main }} 
                            sx={{ color: '#4fa79b' }}
                          />}
                      </Box>
                    </Box>
                    {/* </Tooltip> */}
                  </Box>

                  {/* </Tooltip> */}
                  <Collapse in={isCollapse}>
                    <Typography className="question-dec-text" dangerouslySetInnerHTML={{ __html: +userContext.tempUid === 3 ? curQuestion.guidance_free : curQuestion.guidance }}></Typography>
                    {/* <Typography className="question-dec-text">
                        {curQuestion.guidance}
                      </Typography> */}
                  </Collapse>
                </Grid>
              </Slide>
              {curQuestion.type.toUpperCase() === 'PROCESS_BUILDER' ?
                <Slide
                  direction={direction}
                  in={!checked}
                  container={containerRef.current}
                  mountOnEnter
                  timeout={{ enter: 1000, exit: !checked ? 1 : 2 }}
                >
                  <Grid item xs={12} className="question-footer">
                    <ThemeProvider theme={theme}>
                      <Grid container>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'left' }}>
                          <Fade
                            in={!checked}
                            timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}>
                            <LoadingButton
                              className="button previous-btn"
                              //color={{ main: '#680001' ,light:'#FAF9F6'}}
                              loading={isPreviousButtonLoading}
                              variant="contained"
                              onClick={PreviousQuestion}
                              disabled={curQuestionId === 1}
                            >
                              <Typography className="btn-text">Previous</Typography>
                            </LoadingButton>
                          </Fade>

                          {curQuestionId !== 1 ? <Fade
                            in={!checked}
                            timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}
                          >
                            <Tooltip title="Your progress will remain saved.">
                              <LoadingButton
                                className="button zoom-to-first-question"
                                loading={isZoomFirstButtonLoading}
                                variant="outlined"
                                onClick={ZoomToFirstQuestion}
                                //disabled={}
                                startIcon={
                                  isZoomFirstButtonLoading === true ? <></> : <img loading="lazy" src={searchout} alt="logo" />
                                }
                              >
                                <Typography className="btn-text">Zoom to first question</Typography>
                              </LoadingButton>
                            </Tooltip>
                          </Fade> : <></>}
                        </Grid>
                        {/* <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                        </Grid> */}
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right' }}>
                          <Fade
                            in={!checked}
                            timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}
                          >
                            <LoadingButton
                              className="button next-button"
                              loading={isNextButtonLoading}
                              variant="outlined"
                              onClick={NextQuestion}
                            >
                              <Typography className="btn-text fade-in">{+curQuestion.number === 38 ? "Complete" : "Next"}</Typography>
                            </LoadingButton>
                          </Fade>

                          <Fade
                            in={!checked}
                            timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}
                          >
                            <Tooltip title="Skip past already-answered questions">
                              <LoadingButton
                                className="button zoom-to-next-button"
                                loading={isZoomButtonLoading}
                                variant="contained"
                                onClick={ZoomToNextQuestion}
                                startIcon={
                                  isZoomButtonLoading === true ? <></> : <img loading="lazy" src={search} alt="logo" />
                                }
                              >
                                <Typography className="btn-text">Zoom to next unanswered</Typography>
                              </LoadingButton>
                            </Tooltip>
                          </Fade>
                        </Grid>
                      </Grid>
                    </ThemeProvider>
                  </Grid>
                </Slide>
                : <></>}

              <Slide
                direction={direction}
                in={!checked}
                container={containerRef.current}
                mountOnEnter
                timeout={{ enter: 1000, exit: !checked ? 1 : 2 }}
              >
                <Grid item xs={12} className={curQuestionId === 45 ? "question-ans-div-process" : curQuestion.type.toUpperCase() === 'CHECKPOINT' ? "" : "question-ans-div"}>
                  {
                    curQuestion.type.toUpperCase() === 'STANDARD_MANY' ?
                      <StandardMany
                        ref={childStandardManyCompRef}
                        changeNextQuestion={ChangeNextQuestion}
                        curQuestion={curQuestion}
                        startLoading={() => setNextButtonLoading(true)}
                      ></StandardMany> :
                      curQuestion.type.toUpperCase() === 'STANDARD_ONE' ?
                        <StandardOne
                          ref={childStandardOneCompRef}
                          standardOneConfig={standardOneConfig}
                          changeNextQuestion={ChangeNextQuestion}
                          loading={isNextButtonLoading}
                          curQuestion={curQuestion}
                          startLoading={() => setNextButtonLoading(true)}
                        ></StandardOne> :
                        (curQuestion.type.toUpperCase() === 'ITEM_ADD' && (+curQuestion.number === 4 || +curQuestion.number === 19)) ?
                          <ItemActionAdd
                            QuickView={false}
                            ref={childIteamAddActionCompRef}
                            changeNextQuestion={ChangeNextQuestion}
                            curQuestion={curQuestion}
                            startLoading={() => setNextButtonLoading(true)}
                          ></ItemActionAdd> :
                          (curQuestion.type.toUpperCase() === 'ITEM_ADD' && (+curQuestion.number === 5 || +curQuestion.number === 10 || +curQuestion.number === 16 || +curQuestion.number === 29 || +curQuestion.number === 33)) ?
                            <ItemStakeAdd
                              QuickView={false}
                              ref={childIteamAddStakeCompRef}
                              changeNextQuestion={ChangeNextQuestion}
                              curQuestion={curQuestion}
                              directlyOpenFromTeamProj={false}
                              startLoading={() => setNextButtonLoading(true)}
                            ></ItemStakeAdd> :
                            (curQuestion.type.toUpperCase() === 'ITEM_ADD' && (+curQuestion.number === 6 || +curQuestion.number === 11 || +curQuestion.number === 18 || +curQuestion.number === 26 || +curQuestion.number === 35)) ?
                              <ItemDocAdd
                                QuickView={false}
                                ref={childIteamAddDocCompRef}
                                changeNextQuestion={ChangeNextQuestion}
                                curQuestion={curQuestion}
                                startLoading={() => setNextButtonLoading(true)}
                              ></ItemDocAdd> :
                              (curQuestion.type.toUpperCase() === 'ITEM_ADD' && (+curQuestion.number === 7 || +curQuestion.number === 13 || +curQuestion.number === 22 || +curQuestion.number === 31 || +curQuestion.number === 36)) ?
                                <ItemHintAdd
                                  QuickView={false}
                                  ref={childIteamAddHintCompRef}
                                  changeNextQuestion={ChangeNextQuestion}
                                  curQuestion={curQuestion}
                                  startLoading={() => setNextButtonLoading(true)}
                                ></ItemHintAdd> :
                                (curQuestion.type.toUpperCase() === 'ITEM_ADD' && (+curQuestion.number === 12 || +curQuestion.number === 21 || +curQuestion.number === 27)) ?
                                  <ItemTrgAdd
                                    QuickView={false}
                                    ref={childIteamAddTrgCompRef}
                                    changeNextQuestion={ChangeNextQuestion}
                                    curQuestion={curQuestion}
                                    startLoading={() => setNextButtonLoading(true)}
                                  ></ItemTrgAdd> :
                                  (curQuestion.type.toUpperCase() === 'ITEM_ADD' && (+curQuestion.number === 17 || +curQuestion.number === 30 || +curQuestion.number === 34)) ?
                                    <ItemAssetAdd
                                      QuickView={false}
                                      ref={childIteamAddAssetCompRef}
                                      changeNextQuestion={ChangeNextQuestion}
                                      curQuestion={curQuestion}
                                      startLoading={() => setNextButtonLoading(true)}
                                    ></ItemAssetAdd> :
                                    (curQuestion.type.toUpperCase() === 'ITEM_ADD' && +curQuestion.number === 20) ?
                                      <ItemEventAdd
                                        QuickView={false}
                                        ref={childIteamAddEventCompRef}
                                        changeNextQuestion={ChangeNextQuestion}
                                        curQuestion={curQuestion}
                                        startLoading={() => setNextButtonLoading(true)}
                                      ></ItemEventAdd> :
                                      (curQuestion.type.toUpperCase() === 'RANKING' && (+curQuestion.number === 23 || +curQuestion.number === 37)) ?
                                        <Ranking
                                          ref={childStandardOneCompRef}
                                          changeNextQuestion={ChangeNextQuestion}
                                          curQuestion={curQuestion}
                                          startLoading={() => setNextButtonLoading(true)}
                                        ></Ranking> :
                                        curQuestion.type.toUpperCase() === 'DECISION' ?
                                          <Decision
                                            ref={childDecisionCompRef}
                                            changeNextQuestion={ChangeNextQuestion}
                                            curQuestion={curQuestion}
                                            startLoading={() => setNextButtonLoading(true)}
                                          ></Decision> :
                                          curQuestion.type.toUpperCase() === 'ACRONYM_ADD' ?
                                            <AcronymAdd></AcronymAdd> :
                                            curQuestion.type.toUpperCase() === 'PROCESS_BUILDER' ?
                                              <ProcessBuilder
                                                ref={processBuilderRef}
                                                changeNextQuestion={ChangeNextQuestion}
                                                curQuestion={curQuestion}
                                                isEditStepAccess={true}
                                                cessId={-1}
                                                questionUid={-1}
                                                roleId={-1}
                                                startLoading={() => setNextButtonLoading(true)}
                                                stopLoading={() => setNextButtonLoading(false)}
                                              ></ProcessBuilder> :
                                              curQuestion.type.toUpperCase() === 'SHAREPARTY_ADD' ?
                                                <SharepartyAdd></SharepartyAdd> :
                                                curQuestion.type.toUpperCase() === 'CHECKPOINT' ?
                                                  <Checkpoint
                                                    ref={checkpointRef}
                                                    changeNextQuestion={ChangeNextQuestion}
                                                    curQuestion={curQuestion}
                                                    startLoading={() => setNextButtonLoading(true)}
                                                  ></Checkpoint> : <></>
                  }

                  {/* <ProcessBuilder
                      ref={processBuilderRef}
                      changeNextQuestion={ChangeNextQuestion}
                      curQuestion={curQuestion}
                      startLoading={() => setNextButtonLoading(true)}
                    ></ProcessBuilder> */}
                </Grid>
              </Slide>
              {curQuestion.type.toUpperCase() !== 'PROCESS_BUILDER' ?
                <Grid item xs={12} className="question-footer">
                  <ThemeProvider theme={theme}>
                    <Grid container>
                      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'left' }}>
                        <Fade
                          in={!checked}
                          timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}>
                          <LoadingButton
                            className="button previous-btn"
                            //color={{ main: '#680001' ,light:'#FAF9F6'}}
                            loading={isPreviousButtonLoading}
                            variant="contained"
                            onClick={PreviousQuestion}
                            disabled={curQuestionId === 1}
                          //   theme={(theme) => ({
                          //     ...theme,
                          //     colors: {
                          //         ...theme.colors,
                          //         primary: "#000",
                          //         primary25: '#FEF4F4',
                          //         primary50: '#FEF4F4',
                          //     },
                          // })}
                          >
                            <Typography className="btn-text">Previous</Typography>
                          </LoadingButton>
                        </Fade>

                        {curQuestionId !== 1 ? <Fade
                          in={!checked}
                          timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}
                        >
                          <Tooltip title="Your progress will remain saved.">
                            <LoadingButton
                              className="button zoom-to-first-question"
                              loading={isZoomFirstButtonLoading}
                              variant="outlined"
                              onClick={ZoomToFirstQuestion}
                              //disabled={}
                              startIcon={
                                isZoomFirstButtonLoading === true ? <></> : <img loading="lazy" src={searchout} alt="logo" />
                              }
                            >
                              <Typography className="btn-text">Zoom to first question</Typography>
                            </LoadingButton>
                          </Tooltip>
                        </Fade> : <></>}
                      </Grid>
                      {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        
                      </Grid> */}
                      {/* <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Fade
                      in={!checked}
                      timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}
                    >
                      <LoadingButton
                        className="button"
                        loading={isZoomButtonLoading}
                        variant="outlined"
                        onClick={ZoomToNextQuestion}
                        startIcon={
                          isZoomButtonLoading === true ? <></> : <img src={search} alt="logo" />
                        }
                      >
                        <Typography className="btn-text">Zoom to next unanswered</Typography>
                      </LoadingButton>
                    </Fade>
                    <Fade
                      in={!checked}
                      timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}
                    >

                      <LoadingButton
                        className="button next-button"
                        loading={isNextButtonLoading}
                        variant="contained"
                        onClick={NextQuestion}
                      >
                        <Typography className="btn-text fade-in">{+curQuestion.number === 38 ? "Complete" : "Next"}</Typography>
                      </LoadingButton>
                    </Fade>
                  </Grid> */}

                      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right' }}>

                        <Fade
                          in={!checked}
                          timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}
                        >
                          <LoadingButton
                            className="button next-button"
                            loading={isNextButtonLoading}
                            variant="outlined"
                            onClick={NextQuestion}
                          >
                            <Typography className="btn-text fade-in">{+curQuestion.number === 38 ? "Complete" : "Next"}</Typography>
                          </LoadingButton>
                        </Fade>

                        <Fade
                          in={!checked}
                          timeout={{ enter: 1000, exit: !checked ? 2 : 2 }}
                        >
                          <Tooltip title="Skip past already-answered questions">
                            <LoadingButton
                              className="button zoom-to-next-button"
                              loading={isZoomButtonLoading}
                              variant="contained"
                              onClick={ZoomToNextQuestion}
                              startIcon={
                                isZoomButtonLoading === true ? <></> : <img loading="lazy" src={search} alt="logo" />
                              }
                            >
                              <Typography className="btn-text">Zoom to next unanswered</Typography>
                            </LoadingButton>
                          </Tooltip>
                        </Fade>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </Grid>
                : <></>}
            </Grid>
          </Box>
          {(curQuestion.uid != 1 && curQuestion.uid != 3 || userContext.totalTeamProj > 0) && <Box className="second-box">
            <Paper
              elevation={3}
              sx={{ borderRadius: "10px" }}
              className="paper-card"
            >
              <Box>
                <Box className="question-img">
                  <img loading="lazy" src={Questionimg} alt="logo" />
                </Box>
                <Box className="viewhiagram-img">
                  <Button className="button view-hiagram-button" variant="contained" onClick={gotoHiagram}>
                    <Typography className="text-next">View hiagram</Typography>
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>}
        </Box>
      </Box >
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '400px', padding: '15px', borderRadius: '10px' },
        }}
        onClose={handleGuidanceForStatusClose}
        disableRestoreFocus
      >
        {userContext.clientIsFree === true ?
          <Typography className="popover-guidance-body">Wondering why this keeps changing? Each time you add a Team, Project, Activity or Process, I need to ask you some further questions. For a full-job handover, this interview may take 1-4 hours overall - it all depends on how complex your work life is.</Typography>
          :
          <Typography className="popover-guidance-body">Wondering why this keeps changing? Each time you add a Team, Project, Role, Activity or Process, I need to ask you some further questions. For a full-job handover, this interview may take 2-4 hours overall - it all depends on how complex your work life is.</Typography>}
      </Popover>

    </>
  );
}
