import { Button, Grid, Paper, styled, Table, Skeleton, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme, Popover, IconButton, Box } from "@mui/material";
import * as React from "react";
import Event from '../../../assets/icons/events.svg';
import ClearIcon from '@mui/icons-material/Clear';
import "./ItemEventAddModel.scss";
import Select from "react-select";
import * as Yup from "yup";
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { Form, Formik } from "formik";
import { AlertType } from "../../../shared/AlertType";
import { useContext, useEffect, useState } from "react";
import { PreferencesContext } from "../../../PreferenceContext";
import { CreateEvent, GetAllEventList, GetEventDetail, GetSchedulesList, GetTypeList, UpdateEvent, CreateEventMapping } from "../../../services/EventService";
import { getNameSelected, getSingleSelected } from "../../../services/common/commonFunction";
import _ from "lodash";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import dayjs, { Dayjs } from 'dayjs';
import { LoadingButton } from "@mui/lab";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4FA79B",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E2FAFF",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formInitialValues = {
    id: 0,
    type: "",
    recurring: false,
    schedule: "",
    firstDate: "",
    endDate: "",
    // dayOfWeek: "",
    // time: dayjs(null),
    time: "",
    name: "",
    location: "",
    duration: "",
    organization: "",
    description: "",
    mappingId: 0,
    appendToType: "",
    appendUid: 0
};

export default function ItemEventAddModel(param: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [typeData, setTypeData] = useState([]);
    const [scheduleData, setScheduleData] = useState([]);
    // const [dayOfWeekData, setDayOfWeekData] = useState([]);
    const [formInitial, setFormInitial] = useState(formInitialValues);
    const [allEventData, setAllEventData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [openGuidanceDialog, setOpenGuidanceDialog] = useState<HTMLElement | null>(null);
    const [guidance, setGuidance] = useState("");
    const mainTheme = useTheme();
    const [isBtnLoading, setBtnLoading] = useState(false);

    const [isFocusedName, setFocusedName] = useState(false);
    const [isFocusedLocation, setFocusedLocation] = useState(false);
    const [isFocusedDuration, setFocusedDuration] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);

    const handlePopoverOpenForEvent = (event: React.MouseEvent<HTMLElement>) => {
        setOpenGuidanceDialog(event.currentTarget);
        setGuidance(param.curQuestion.guidance);
    };

    const handlePopoverCloseForEvent = () => {
        setOpenGuidanceDialog(null);
    };

    const open = Boolean(openGuidanceDialog);

    async function fetchType() {
        try {
            const typeGetData: any = await GetTypeList();
            if (typeGetData !== undefined && typeGetData !== null && typeGetData.data !== null && typeGetData.data.data !== null && typeGetData.data.data.data !== null) {
                setTypeData(typeGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }
    async function fetchSchedule() {
        try {
            const scheduleGetData: any = await GetSchedulesList();
            if (scheduleGetData !== undefined && scheduleGetData !== null && scheduleGetData.data !== null && scheduleGetData.data.data !== null && scheduleGetData.data.data.data !== null) {
                setScheduleData(scheduleGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }
    // async function fetchdayOfWeek() {
    //     try {
    //         const dayOfWeekGetData: any = await GetDayOfWeekList();
    //         if (dayOfWeekGetData !== undefined && dayOfWeekGetData !== null && dayOfWeekGetData.data !== null && dayOfWeekGetData.data.data !== null && dayOfWeekGetData.data.data.data !== null) {
    //             setDayOfWeekData(dayOfWeekGetData.data.data.data);
    //         }
    //     }
    //     catch (error: any) {
    //         if (error) {
    //             updateUserContext({
    //                 ...userContext,
    //                 isAlert: true,
    //                 alertMessage: error.response.message,
    //                 alertType: AlertType.Error,
    //             });
    //         }
    //     }
    // }

    async function fetchEvent(id: any, aId: any) {
        setDataLoading(true);
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("emuid", aId.toString());
        params.append("euid", id.toString());
        const eventres: any = await GetEventDetail(params);
        if (eventres !== null && eventres.data !== null && eventres.data.data !== null) {
            let data = eventres.data.data;
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            if (+param.curQuestion.number === 20) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            const formValues = {
                id: +data.uid,
                type: data.type,
                recurring: data.recurring,
                schedule: data.schedule,
                firstDate: data.first_date,
                endDate: data.end_date,
                // dayOfWeek: data.day_of_week,
                // time: dayjs(data.time),
                time: new Date(data.time).toLocaleTimeString('en',
                    { timeStyle: 'short', hour12: false, timeZone: 'UTC' }),
                location: data.location,
                duration: data.duration,
                name: data.name,
                organization: data.organization,
                description: data.description,
                mappingId: +data.eventMapping[0].uid,
                appendToType: appendType,
                appendUid: appendUID
            };
            setFormInitial(formValues);
        }
        setDataLoading(false);
    }

    async function fetchEventList() {
        setLoading(true);
        let appendType = "";
        let appendUID = 0;
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        if (+param.curQuestion.number === 20) {
            appendType = "ACTIVITY";
            appendUID = userContext.activityId;
        }
        if (param.curQuestion.hiagram !== undefined) {
            appendType = param.curQuestion.hiagram.type;
            appendUID = param.curQuestion.hiagram.typeId;
        }
        params.append("appendType", appendType.toString());
        params.append("appendUid", appendUID.toString());
        const Events: any = await GetAllEventList(params);
        if (Events !== null && Events.data !== null && Events.data.data !== null) {
            let data = Events.data.data;
            setAllEventData(data);
        }
        setLoading(false);
    }

    async function submitForm(values: any, resetForm: any) {
        setBtnLoading(true);
        try {
            let appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
            let appendUID = userContext.teamProjId;
            let isFromExisting = false;
            if (+param.curQuestion.number === 20) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            }

            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }

            if (values.id > 0 && values.mappingId === 0) {
                let curEvent: any = allEventData.find((x: any) => x.uid === values.id);

                if (values.type === curEvent.type
                    && values.recurring === curEvent.recurring
                    && values.schedule === curEvent.schedule
                    && values.firstDate === curEvent.first_date
                    && values.endDate === curEvent.end_date
                    // && values.dayOfWeek === curEvent.day_of_week
                    // && (values.time === dayjs(new Date(curEvent.time)))
                    && (values.time === new Date(curEvent.time).toLocaleTimeString('en',
                        { timeStyle: 'short', hour12: false, timeZone: 'UTC' }))
                    && values.name === curEvent.name
                    && values.location === curEvent.location
                    && values.duration === curEvent.duration
                    && values.organization === curEvent.organization
                    && values.description === curEvent.description
                ) {
                    isFromExisting = true;
                } else {
                    values.id = 0;
                }
            }

            let requestData = {
                eventUid: values.id,
                eventMappingUid: values.mappingId,
                hiagramUid: userContext.hiagramId,
                type: values.type,
                recurring: values.recurring,
                schedule: values.schedule,
                firstDate: dayjs(values.firstDate).format('YYYY-MM-DD'),
                endDate: (values.endDate === "" || values.endDate === null) ? null : dayjs(values.endDate).format('YYYY-MM-DD'),
                // dayOfWeek: values.dayOfWeek,
                // time: dayjs(values.time),
                time: values.time === "24:00" ? null : values.time,
                location: values.location,
                duration: values.duration,
                name: values.name,
                organization: values.organization,
                description: values.description,
                appendToType: appendType,
                appendUid: appendUID,
                isDeleted: false,
                dataManagedBy: userContext.userId
            }
            let data: any;
            if (isFromExisting) {
                data = await CreateEventMapping(requestData);
            } else if (values.id !== null && values.id > 0) {
                data = await UpdateEvent(requestData);
            } else {
                data = await CreateEvent(requestData);
            }
            setFormInitial(formInitialValues);
            resetForm();
            param.onSubmitClose();
            updateUserContext({
                ...userContext,
                isItemCountUpdated: true
            });
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    const handleClickAdd = (id: any) => {
        let curEvent: any = allEventData.find((x: any) => x.uid === id);
        const formInitial = {
            id: curEvent.uid,
            type: curEvent.type,
            recurring: curEvent.recurring,
            schedule: curEvent.schedule,
            firstDate: curEvent.first_date,
            endDate: curEvent.end_date,
            // dayOfWeek: curEvent.day_of_week,
            // time: dayjs(new Date(curEvent.time)),
            time: new Date(curEvent.time).toLocaleTimeString('en',
                { timeStyle: 'short', hour12: false, timeZone: 'UTC' }),
            name: curEvent.name,
            location: curEvent.location,
            duration: curEvent.duration,
            organization: curEvent.organization,
            description: curEvent.description,
            mappingId: 0,
            appendToType: "",
            appendUid: 0
        };
        setFormInitial(formInitial);
    }

    const formValidation = Yup.object().shape({
        type: Yup.string().required("Type is required."),
        name: Yup.string().required("Name is required."),
        description: Yup.string().required("Description is required."),
        firstDate: Yup.date().required("Date is required."),

    });
    useEffect(() => {
        if (param.isOpen) {
            fetchType();
            fetchSchedule();
            // fetchdayOfWeek();
            if (param.id > 0) {
                fetchEvent(param.id, param.mappingId);
            } else {
                fetchEventList();
            }
        }
    }, [param.isOpen]);


    return (
        <Formik
            enableReinitialize
            initialValues={formInitial}
            validationSchema={formValidation}
            onSubmit={(values: any, { resetForm }) => {
                submitForm(values, resetForm);
            }}
        >{(props: any) => {
            return (
                <>
                    <Grid container className="main-grid-model-event-add">
                        <Grid className="border-grid-left" item xs={param.isUpdate === true ? 12 : 6} paddingRight={param.isUpdate === true ? "30px" : "50px"}>
                            <Form onSubmit={props.handleSubmit} noValidate>

                                <Grid container>
                                    <Grid className="header-left" sx={{ display: "flex", padding: 1 }} item xs={12}>
                                        <img loading="lazy" alt="imprend" className='icon-stakeholders' src={Event} />
                                        {param.isUpdate === true ? <Typography className="icon-text">Edit Event</Typography>
                                            : <Typography className="icon-text">Add new Event</Typography>
                                        }
                                        {param.QuickView === false ?
                                            <Tooltip title="Guidance">
                                                <IconButton
                                                    className="event-guidance-icon"
                                                    aria-label="Guidance"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    disableRipple
                                                    onClick={handlePopoverOpenForEvent}
                                                >
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip> : <></>}

                                    </Grid>
                                    {/* {param.isUpdate === true ?
                                        <Grid item xs={2} className="clear-icon-edit">
                                            <Button
                                                disableRipple
                                                className="btn-cancel"
                                                onClick={() => {
                                                    setFormInitial(formInitialValues);
                                                    props.resetForm();
                                                    param.onClose();
                                                }}
                                            >
                                                <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                            </Button>
                                        </Grid> :
                                        <></>} */}
                                </Grid>
                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 5 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Type</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Select
                                                classNamePrefix="select"
                                                isDisabled={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                // placeholder={'Contrary to popular belief'}
                                                placeholder={''}
                                                styles={
                                                    props.values.type === "" &&
                                                        props.errors.type &&
                                                        props.touched.type
                                                        ? selectStyles
                                                        : selectFontFamily
                                                }
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                value={getSingleSelected(
                                                    props.values.type,
                                                    typeData
                                                )}
                                                onChange={(e, value) => {
                                                    props.setFieldValue(
                                                        "type",
                                                        e !== null ? e.value : "",
                                                        false
                                                    );
                                                }}
                                                options={typeData}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: mainTheme.palette.primary.main,
                                                        primary25: '#FEF4F4',
                                                        primary50: '#FEF4F4',
                                                    },
                                                })}
                                            />}
                                        {props.values.type === "" &&
                                            props.errors.type &&
                                            props.touched.type && (
                                                <Typography
                                                    className={
                                                        props.values.type === "" &&
                                                            props.errors.type &&
                                                            props.touched.type
                                                            ? "validation-message text-input error"
                                                            : "validation-message text-input"
                                                    }
                                                >
                                                    {props.errors.type}
                                                </Typography>
                                            )}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Name</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="name"
                                                    fullWidth
                                                    // placeholder='Belief'
                                                    placeholder='Name'
                                                    autoComplete="off"
                                                    className="item-text-fields"
                                                    onChange={props.handleChange.bind("name")}
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedName(false)}
                                                    onFocus={() => setFocusedName(true)}
                                                    value={props.values.name}
                                                    error={
                                                        props.errors.name && props.touched.name
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                </TextField>
                                                {isFocusedName && <Typography className="textfield-counter">{(props.values.name !== null ? props.values.name.length : 0) + "/" + 90}</Typography>}
                                            </Box>
                                        }
                                        {props.errors.name && props.touched.name ?
                                            <Typography className="validation-message" >
                                                {props.errors.name}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography-decription">Description</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="description"
                                                    multiline={true}
                                                    rows={3}
                                                    className="description-textfield item-text-fields"
                                                    fullWidth
                                                    placeholder='Description'
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("description")}
                                                    inputProps={{ maxLength: 500 }}
                                                    onBlur={() => setFocusedDescription(false)}
                                                    onFocus={() => setFocusedDescription(true)}
                                                    value={props.values.description}
                                                    error={
                                                        props.errors.description && props.touched.description
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                </TextField>
                                                {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 500}</Typography>}
                                            </Box>
                                        }
                                        {props.errors.description && props.touched.description ?
                                            <Typography className="validation-message" >
                                                {props.errors.description}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Location</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="location"
                                                    fullWidth
                                                    // placeholder='Contrary to popular belief'
                                                    placeholder='Location'
                                                    className="item-text-fields"
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedLocation(false)}
                                                    onFocus={() => setFocusedLocation(true)}
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("location")}
                                                    value={props.values.location}
                                                >
                                                </TextField>
                                                {isFocusedLocation && <Typography className="textfield-counter">{(props.values.location !== null ? props.values.location.length : 0) + "/" + 90}</Typography>}
                                            </Box>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Date</Typography>
                                        <Typography className="text-typography-required">(required)</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            // <TextField
                                            //     id="firstDate"
                                            //     type="date"
                                            //     fullWidth
                                            //     placeholder='Contrary to popular belief'
                                            //     autoComplete="off"
                                            //     onChange={props.handleChange.bind("firstDate")}
                                            //     value={props.values.firstDate}
                                            //     error={
                                            //         props.errors.firstDate && props.touched.firstDate
                                            //             ? true
                                            //             : false
                                            //     }
                                            // >
                                            // </TextField>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        //className=""
                                                        className={
                                                            props.values.firstDate === "" &&
                                                                props.errors.firstDate &&
                                                                props.touched.firstDate
                                                                ? "datepicker error"
                                                                : "datepicker item-text-fields"
                                                        }
                                                        sx={{ width: '100%' }}
                                                        format="DD-MM-YYYY"
                                                        value={dayjs(props.values.firstDate)}
                                                        onChange={(e) =>
                                                            props.setFieldValue(
                                                                "firstDate",
                                                                e !== null ? e.toDate() : "",
                                                                true
                                                            )}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        }
                                        {props.errors.firstDate && props.touched.firstDate ?
                                            <Typography className="validation-message" >
                                                {props.errors.firstDate}
                                            </Typography> : <></>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Time</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :

                                            <TextField
                                                type="time"
                                                id="time"
                                                fullWidth
                                                placeholder='Contrary to popular belief'
                                                className="item-text-fields"
                                                autoComplete="off"
                                                onChange={props.handleChange.bind("time")}
                                                value={props.values.time}
                                            >
                                            </TextField>
                                            // <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            //     <MobileTimePicker
                                            //         ampm={true}
                                            //         className="timepicker"
                                            //         value={dayjs(props.values.time)}
                                            //         onChange={(e) =>
                                            //             props.setFieldValue(
                                            //                 "time",
                                            //                 e !== null ? e.toDate() : "",
                                            //                 true
                                            //             )}
                                            //     />
                                            // </LocalizationProvider>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Duration</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Box className="description-textfield-box">
                                                <TextField
                                                    id="duration"
                                                    fullWidth
                                                    // placeholder='Contrary to popular belief'
                                                    placeholder='Duration'
                                                    inputProps={{ maxLength: 90 }}
                                                    onBlur={() => setFocusedDuration(false)}
                                                    onFocus={() => setFocusedDuration(true)}
                                                    className="item-text-fields"
                                                    autoComplete="off"
                                                    onChange={props.handleChange.bind("duration")}
                                                    value={props.values.duration}
                                                >
                                                </TextField>
                                                {isFocusedDuration && <Typography className="textfield-counter">{(props.values.duration !== null ? props.values.duration.length : 0) + "/" + 90}</Typography>}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid-for-recurring" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Recurring</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width={100} height={52} /> :
                                            <Checkbox
                                                value={props.values.recurring}
                                                checked={props.values.recurring}
                                                onChange={(e) => {
                                                    props.setFieldValue(
                                                        "recurring",
                                                        e.target.checked !== null ? e.target.checked : "",
                                                        true
                                                    );
                                                    if (e.target.checked === false) {
                                                        props.setFieldValue(
                                                            "schedule",
                                                            "",
                                                            true
                                                        );
                                                        props.setFieldValue(
                                                            "endDate",
                                                            "",
                                                            true
                                                        );
                                                        // props.setFieldValue(
                                                        //     "dayOfWeek",
                                                        //     "",
                                                        //     true
                                                        // );
                                                    }
                                                }}
                                            />
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">Schedule</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Select
                                                classNamePrefix="select"
                                                isDisabled={!props.values.recurring}
                                                isClearable={true}
                                                isSearchable={true}
                                                // placeholder={'Contrary to popular belief'}
                                                placeholder={''}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                value={getSingleSelected(
                                                    props.values.schedule,
                                                    scheduleData
                                                )}
                                                onChange={(e, value) => {
                                                    props.setFieldValue(
                                                        "schedule",
                                                        e !== null ? e.value : "",
                                                        false
                                                    );
                                                }}
                                                styles={{
                                                    control: (baseStyles: any, state: any) => ({
                                                        ...baseStyles,
                                                        fontFamily: 'Roboto'
                                                    }),
                                                    menu: (provided: any) => ({
                                                        ...provided,
                                                        fontFamily: 'Roboto'
                                                    }),
                                                    option: (provided: any, state: any) => ({
                                                        ...provided,
                                                        fontFamily: 'Roboto'
                                                    }),
                                                    singleValue: (provided: any) => ({
                                                        ...provided,
                                                        fontFamily: 'Roboto'
                                                    })
                                                }}
                                                options={scheduleData}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: mainTheme.palette.primary.main,
                                                        primary25: '#FEF4F4',
                                                        primary50: '#FEF4F4',
                                                    },
                                                })}
                                            />}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="textfield-main-grid" sx={{ marginTop: 3 }}>
                                    <Box className="text-grid-textfield">
                                        <Typography className="text-typography">End Date</Typography>
                                    </Box>
                                    <Box className="textfield-grid">
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            // <TextField
                                            //     id="endDate"
                                            //     type="date"
                                            //     disabled={!props.values.recurring}
                                            //     fullWidth
                                            //     placeholder='Contrary to popular belief'
                                            //     autoComplete="off"
                                            //     onChange={props.handleChange.bind("endDate")}
                                            //     value={props.values.endDate}
                                            // >
                                            // </TextField>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        className="datepicker"
                                                        disabled={!props.values.recurring}
                                                        sx={{ width: '100%' }}
                                                        format="DD-MM-YYYY"
                                                        value={dayjs(props.values.endDate)}
                                                        onChange={(e) =>
                                                            props.setFieldValue(
                                                                "endDate",
                                                                e !== null ? e.toDate() : "",
                                                                true
                                                            )}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        }
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12} sx={{ display: "flex", alignItems: "center", marginTop: 3 }}>
                                    <Grid item xs={2} className="text-grid-textfield">
                                        <Typography className="text-typography">Day Of Week</Typography>
                                    </Grid>
                                    <Grid className="textfield-grid" item xs={10}>
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <Select
                                                classNamePrefix="select"
                                                isDisabled={!props.values.recurring}
                                                isClearable={true}
                                                isSearchable={true}
                                                // placeholder={'Contrary to popular belief'}
                                                placeholder={''}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                value={getSingleSelected(
                                                    props.values.dayOfWeek,
                                                    dayOfWeekData
                                                )}
                                                onChange={(e, value) => {
                                                    props.setFieldValue(
                                                        "dayOfWeek",
                                                        e !== null ? e.value : "",
                                                        false
                                                    );
                                                }}
                                                options={dayOfWeekData}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: mainTheme.palette.primary.main,
                                                        primary25: '#FEF4F4',
                                                        primary50: '#FEF4F4',
                                                    },
                                                })}
                                            />}
                                    </Grid>
                                </Grid> */}


                                {/* <Grid item xs={12} sx={{ display: "flex", alignItems: "center", marginTop: 3 }}>
                                    <Grid item xs={2} className="text-grid-textfield">
                                        <Typography className="text-typography">Organization</Typography>
                                    </Grid>
                                    <Grid className="textfield-grid" item xs={10}>
                                        {isDataLoading ?
                                            <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                            <TextField
                                                id="organization"
                                                fullWidth
                                                // placeholder='Belief'
                                                placeholder='Organization'
                                                autoComplete="off"
                                                onChange={props.handleChange.bind("organization")}
                                                value={props.values.organization}
                                            >
                                            </TextField>}
                                    </Grid>
                                </Grid> */}


                                <Grid item xs={12} className="button-main-grid">
                                    <Grid>
                                        <Button className='button-cancel'
                                            onClick={() => {
                                                setFormInitial(formInitialValues);
                                                props.resetForm();
                                                param.onClose();
                                            }}>Cancel</Button>
                                    </Grid>
                                    <Grid sx={{ paddingLeft: 4 }}>
                                        {/* <Button variant="contained" className='button-submit' type="submit">Submit</Button> */}
                                        <LoadingButton
                                            loading={isBtnLoading}
                                            variant="contained"
                                            type="submit"
                                            className='button-submit'
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid>
                        {param.isUpdate !== true ?
                            <Grid container className="border-grid-right" spacing={1} item xs={6}>
                                <Grid item sx={{ padding: 5 }} className="table-main" xs={12}>
                                    <Grid container className="header-right" sx={{ display: "flex", padding: 1 }} >
                                        <Grid item xs={10}>
                                            <Typography className="clear-text"><b>Events already in hiagram</b></Typography>
                                        </Grid>
                                        {/* <Grid item xs={2}>
                                            <Button
                                                disableRipple
                                                className="btn-cancel"
                                                onClick={() => {
                                                    setFormInitial(formInitialValues);
                                                    props.resetForm();
                                                    param.onClose();
                                                }}
                                            >
                                                <ClearIcon sx={{ height: "30px", width: "30px", color: "#292D32" }} />
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                    <Grid container paddingTop={5}>
                                        <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                                            <Table className="table-css" aria-label="customized table">
                                                <TableHead>
                                                    <TableRow >
                                                        <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Name</StyledTableCell>
                                                        <StyledTableCell align="left" sx={{ width: '20%', fontSize: 13 }}>Type</StyledTableCell>
                                                        <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Date</StyledTableCell>
                                                        <StyledTableCell align="left" sx={{ width: '25%', fontSize: 13 }}>Recurring</StyledTableCell>
                                                        {/* <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}>Add</StyledTableCell> */}
                                                        <StyledTableCell align="left" sx={{ width: '10%', fontSize: 13 }}></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <>
                                                        {isLoading === true ? (
                                                            _.times(4, (i) => (
                                                                <TableRow key={i}>
                                                                    <TableCell colSpan={5}>
                                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={25} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))) : allEventData.length > 0 ? allEventData.map((row: any, index: any) => {
                                                                return (<>
                                                                    <StyledTableRow sx={{ fontSize: 12 }} key={index}>
                                                                        <StyledTableCell align="left" className="text-show-less">{row.name}</StyledTableCell>
                                                                        <StyledTableCell align="left" className="text-show-less">{getNameSelected(
                                                                            row.type,
                                                                            typeData
                                                                        )}</StyledTableCell>
                                                                        <StyledTableCell align="left" className="text-show-less">{row.first_date.split("-").reverse().join("-")}</StyledTableCell>
                                                                        <StyledTableCell align="left" className="text-show-less">{row.recurring === true ? "Yes" : "No"}</StyledTableCell>
                                                                        <StyledTableCell className="btn-add">
                                                                            <Tooltip title='Add'>
                                                                                <Button
                                                                                    variant='contained'
                                                                                    className='edit-btn'
                                                                                    onClick={() => handleClickAdd(row.uid)}
                                                                                >
                                                                                    <AddIcon ></AddIcon>
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                </>
                                                                );
                                                            }) : <>
                                                            <TableRow key={1}>
                                                                <TableCell colSpan={5} align='center'>
                                                                    None added yet!
                                                                </TableCell>
                                                            </TableRow></>}
                                                    </>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>

                            </Grid>
                            : <></>}
                        <Box className="close-btn-box">
                            <Button
                                disableRipple
                                className="btn-cancel"
                                onClick={() => {
                                    setFormInitial(formInitialValues);
                                    props.resetForm();
                                    param.onClose();
                                }}
                            >
                                <ClearIcon className="close-btn-icon" />
                            </Button>
                        </Box>
                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={openGuidanceDialog}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: { width: '400px', padding: '15px', borderRadius: '10px' },
                            }}
                            onClose={handlePopoverCloseForEvent}
                            disableRestoreFocus
                        >
                            <Typography className="popover-guidance-body" dangerouslySetInnerHTML={{ __html: guidance }}></Typography>
                        </Popover>
                    </Grid>
                </>
            );
        }}
        </Formik>
    );
}

const selectStyles = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        border: state.isFocused ? "1px solid #0b0b45" : "1px solid #d32f2f",
        fontFamily: 'Roboto'
    })
};

const selectFontFamily = {
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        fontFamily: 'Roboto'
    }),
    menu: (provided: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    }),
    singleValue: (provided: any) => ({
        ...provided,
        fontFamily: 'Roboto'
    }),
};