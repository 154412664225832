import { Grid, Button, TableContainer, Paper, Table, TableHead, TableRow, Tooltip, TableCell, TableBody, tableCellClasses, styled, Typography, Skeleton, Popover, DialogContentText, DialogActions, Box, TooltipProps, tooltipClasses } from "@mui/material";
import * as React from "react";
import editIcon from '../../../assets/icons/edit-2.svg'
import trashIcon from '../../../assets/icons/trash.svg'
import stakeholders from '../../../assets/icons/stakeholder-people.svg'
import person from '../../../assets/icons/person-2.svg'
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import ItemStakeAddModel from './ItemStakeAddModel'
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { TransitionProps } from '@mui/material/transitions';
import "./ItemStakeAdd.scss";
import { PreferencesContext } from "../../../PreferenceContext";
import { AlertType } from "../../../shared/AlertType";
import { CreateQusAnswer, DeleteStake, GetQuestionAnswer, GetRaciList, GetStakeList } from "../../../services/StakeService";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { getNameSelected } from "../../../services/common/commonFunction";
import { Background } from "reactflow";

interface IStakeDetail {
    id: any;
    stakeMappingId: any;
    raci: any;
    firstName: any;
    lastName: any;
    organization: any;
    department: any;
    contact: any;
    description: any;
    role: any;
}

const initStakeDetail: IStakeDetail = {
    id: 0,
    stakeMappingId: 0,
    raci: "",
    firstName: "",
    lastName: "",
    organization: "",
    department: "",
    contact: "",
    description: "",
    role: ""
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#56506d",
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E6E8F0",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
//     <Tooltip describeChild={true}
//         // slotProps={{ popper: { disablePortal: true } }} {...props} classes={{ popper: className }} 
//         slotProps={{ popper: { disablePortal: true, modifiers: [{ name: 'flip', options: { fallbackPlacements: ['right', 'bottom', 'left', 'top'] } }] } }}

//     />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: '#fff',
//         color: 'rgba(0, 0, 0, 0.87)',
//         minWidth: 500,
//         maxWidth: 500,
//         fontSize: theme.typography.pxToRem(14),
//         borderRadius: '10px',
//         boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
//         //border: '1px solid #dadde9',
//     },
//     [`& .${tooltipClasses.popper}`]: {
//         marginTop: theme.spacing(0), // Adjust the margin to control the space between the element and the tooltip
//     }
// }));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        describeChild={true}
        {...props}
        classes={{ popper: className }}
        slotProps={{ popper: { disablePortal: true } }}
        PopperProps={{
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['right', 'left', 'top', 'bottom'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundariesElement: 'window',
                    },
                },
            ],
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        minWidth: 500,
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: '10px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    [`& .${tooltipClasses.popper}`]: {
        marginTop: theme.spacing(1),
    }
}));

//export default function ItemStakeAdd() {
const ItemStakeAdd = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [openDialog, setOpen] = useState(false);
    const [isDirectlyOpenFromTeam, setIsDirectlyOpenFromTeam] = useState(false);
    const [isAppendType, setIsAppendType] = useState('');
    const [isAppendUid, setIsAppendUid] = useState(0);
    const [editId, setEditId] = useState(0);
    const [editMappingId, setEditMappingId] = useState(0);
    const [questionAnswerId, setQuestionAnswerId] = useState(0);
    const [stakeList, setStakeList] = useState([initStakeDetail]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [openStakeDeleteModal, setStakeDeleteModal] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [raciData, setRaciData] = useState([]);

    useImperativeHandle(ref, () => ({
        submitClick() {
            saveAnswer();
        },
        getData() {
            fetchRaci();
            fetchStakeList();
            fetchStakeQuestionAnswer();
        }
    }),);

    const open = Boolean(anchorEl);

    const handleClickOpen = () => {
        setEditId(0);
        setEditMappingId(0);
        setIsUpdate(false);
        setOpen(true);
        setIsDirectlyOpenFromTeam(param.directlyOpenFromTeamProj);
        setIsAppendType(param.curQuestion.hiagram.type);
        setIsAppendUid(param.curQuestion.hiagram.typeId);
    };

    const handleClickOpenForEdit = (id: any, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setIsUpdate(true);
        setOpen(true);
        setIsDirectlyOpenFromTeam(param.directlyOpenFromTeamProj);
    };

    const handleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpen(false);
    };

    const handleSubmitClose = () => {
        setOpen(false);
        fetchStakeList();
        fetchStakeQuestionAnswer();
    };

    const handleOpenStakeDeleteModal = (id: number, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setStakeDeleteModal(true);
    };
    const handleCloseStakeDeleteModal = () => {
        setStakeDeleteModal(false);
    };

    async function fetchRaci() {
        try {
            const raciGetData: any = await GetRaciList();
            if (raciGetData !== undefined && raciGetData !== null && raciGetData.data !== null && raciGetData.data.data !== null && raciGetData.data.data.data !== null) {
                setRaciData(raciGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchStakeList() {
        try {
            setLoading(true);
            let appendType = "";
            let appendUID = 0;
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());

            // old when team question available in flow free version start
            if (+param.curQuestion.number === 5) {
                appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
                appendUID = userContext.teamProjId;
                // old when team question available in flow free version end


                // start attched people card (with activity /team /proj data)
                // if (+param.curQuestion.number === 5) {
                //     appendType = userContext.isProj.toString() === "true" ? "PROJ" : "TEAM";
                //     appendUID = 0;
                // end attched people card (with activity /team /proj data)
            } else if (+param.curQuestion.number === 10) {
                appendType = "ROLE";
                appendUID = userContext.roleId;
            } else if (+param.curQuestion.number === 16) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;
            } else if (+param.curQuestion.number === 29) {
                appendType = "CESS";
                appendUID = userContext.cessId;
            } else if (+param.curQuestion.number === 33) {
                appendType = "STEP";
                appendUID = userContext.stepId;
            } else if (+param.curQuestion.number === 0) {
                appendType = "NONE";
                appendUID = 0;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
                // appendUID = 0;
            }
            params.append("appendType", appendType.toString());
            params.append("appendUid", appendUID.toString());

            const stakeres: any = await GetStakeList(params);
            if (stakeres !== null && stakeres.data !== null && stakeres.data.data !== null) {
                let data = stakeres.data.data;
                let list: any[] = [];

                // if (+userContext.tempUid === 3 && appendType === "TEAM") {
                //     data.forEach((element: any) => {
                //         let item: IStakeDetail = {
                //             id: element.uid,
                //             firstName: element.first_name,
                //             lastName: element.last_name,
                //             organization: element.org_name,
                //             department: element.department_name,
                //             contact: element.contact,
                //             stakeMappingId: element.stackUid,
                //             raci: element.raci,
                //             description: element.description,
                //             role: element.role
                //         }

                //         list.push(item);
                //     })
                // }
                // else if (+userContext.tempUid === 3 && appendType === "PROJ") {
                //     data.forEach((element: any) => {
                //         let item: IStakeDetail = {
                //             id: element.uid,
                //             firstName: element.first_name,
                //             lastName: element.last_name,
                //             organization: element.org_name,
                //             department: element.department_name,
                //             contact: element.contact,
                //             stakeMappingId: element.stackUid,
                //             raci: element.raci,
                //             description: element.description,
                //             role: element.role
                //         }

                //         list.push(item);
                //     })
                // }
                // else {
                data.forEach((element: any) => {
                    let item: IStakeDetail = {
                        id: element.uid,
                        firstName: element.first_name,
                        lastName: element.last_name,
                        organization: element.org_name,
                        department: element.department_name,
                        contact: element.contact,
                        stakeMappingId: element.stakeMapping === undefined ? 0 : element.stakeMapping[0].uid,
                        raci: element.stakeMapping === undefined ? 0 : element.stakeMapping[0].raci,
                        description: element.stakeMapping === undefined ? 0 : element.stakeMapping[0].description,
                        role: element.stakeMapping === undefined ? 0 : element.stakeMapping[0].role
                    }
                    list.push(item);
                });
                // }

                setStakeList(list);
                //setStakeList([initStakeDetail]);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchStakeQuestionAnswer() {
        try {
            setLoading(true);
            let projUID = 0;
            let teamUID = 0;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            params.append("tuid", teamUID.toString());
            params.append("puid", projUID.toString());
            params.append("ruid", userContext.roleId.toString());
            params.append("auid", userContext.activityId.toString());
            params.append("cuid", userContext.cessId.toString());
            const questionAnswer: any = await GetQuestionAnswer(params);
            if (questionAnswer !== null && questionAnswer.data !== null && questionAnswer.data.data !== null) {
                let data = questionAnswer.data.data;
                setQuestionAnswerId(data.uid);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function deleteStake() {
        setBtnLoading(true);
        try {
            const stake: any = await DeleteStake(editId, {
                huid: userContext.hiagramId,
                mappingUid: editMappingId,
                is_deleted: true,
                row_managed_by: userContext.userId
            });
            if (stake.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: stake.message,
                    alertType: AlertType.Success,
                    isItemCountUpdated: true
                });
                //Close popup and refresh data
                setStakeDeleteModal(false);
                fetchStakeList();
                fetchStakeQuestionAnswer();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function saveAnswer() {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            let projUID = 0;
            let teamUID = 0;
            let roleUID = userContext.roleId;
            let activityUID = userContext.activityId;
            let cessUID = userContext.cessId;
            let stepUID = userContext.stepId;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            try {
                let requestData = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: questionAnswerId,
                    teamUid: teamUID,
                    projUid: projUID,
                    roleUid: roleUID,
                    cessUid: cessUID,
                    stepUid: stepUID,
                    activityUid: activityUID,
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }

                let data: any;
                data = await CreateQusAnswer(requestData);
                if (data !== null && data.data === true) {
                    param.changeNextQuestion();
                }
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            param.changeNextQuestion();
        }
    }


    useEffect(() => {
    }, [stakeList]);

    return (
        <Grid container className="stake-list-grid" >
            {param.QuickView === false ?
                <Grid item xs={12} className="stakholder-icon-group" >
                    <img loading="lazy" alt="imprend" className='icon-stakeholders' src={stakeholders} />
                    <Typography className="stakholder-icon-name">People</Typography>
                </Grid> :

                <Grid item xs={12} className="heading-container-grid-stack">
                    <Grid className="header-css" item xs={12}>
                        <img loading="lazy" alt="imprend" className='header-icon' src={stakeholders} />
                        <Typography className="icon-text">People</Typography>
                    </Grid>
                    {/* <Grid item xs={1} className="clear-icon-grid">
                        <Button
                            disableRipple
                            className="btn-cancel"
                            onClick={param.onClose}
                        >
                            <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                        </Button>
                    </Grid> */}
                </Grid>
            }
            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead sx={{ backgroundColor: "#56506d" }}>
                        <TableRow>
                            {+param.curQuestion.number === 0 || (+param.curQuestion.number === 16 && +userContext.tempUid === 3) || (param.directlyOpenFromTeamProj === true && +userContext.tempUid === 3) ? <></> : <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>RACI</StyledTableCell>}
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Organization</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>First name</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Last name</StyledTableCell>
                            {/* {+param.curQuestion.number !== 0 ? <></> : <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Contact</StyledTableCell>} */}
                            {/* {+param.curQuestion.number == 0 ? <></> : <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Department</StyledTableCell>} */}
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Department</StyledTableCell>
                            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ?
                                <StyledTableCell align="center" sx={{ width: '16%', fontSize: 12 }}>Action</StyledTableCell>
                                : <></>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {isLoading === true ? (
                                _.times(4, (i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={6}>
                                            <Skeleton variant="rounded" width='auto' height={25} />
                                        </TableCell>
                                    </TableRow>
                                ))) : stakeList.filter((x) => x.id > 0).length > 0 ? (stakeList && stakeList.map((row: any, index: any) => {
                                    // const placement = index < 3 ? 'bottom' : 'top';
                                    const placement = index < 3 ? 'bottom' : 'top';
                                    return (
                                        <HtmlTooltip
                                            key={index}
                                            placement={placement}
                                            title={
                                                <React.Fragment>
                                                    {+param.curQuestion.number === 0 || (+param.curQuestion.number === 16 && +userContext.tempUid === 3) || (param.directlyOpenFromTeamProj === true && +userContext.tempUid === 3) ? <></> :
                                                        <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.raci, raciData))} sx={{ p: 1, cursor: 'copy' }}><b>RACI:</b> {getNameSelected(row.raci, raciData)}</Typography>
                                                    }
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.firstName)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>First name:</b> {row.firstName}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.lastName)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Last name:</b> {row.lastName}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.contact)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Contact details:</b> {row.contact}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.organization)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Organization:</b> {row.organization}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.department)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Department:</b> {row.department}</Typography>
                                                    {+param.curQuestion.number > 0 ?
                                                        <>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.role)} sx={{ p: 1, cursor: 'copy' }}><b>Role:</b> {row.role}</Typography>
                                                            {param.directlyOpenFromTeamProj === false ? <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy' }}><b>How is this person involved here?:</b> {row.description}</Typography> : <></>}
                                                        </> :
                                                        <></>}
                                                    {/* </Box> */}
                                                </React.Fragment>
                                            }
                                        >
                                            <StyledTableRow
                                                sx={{ fontSize: 12, height: 50 }}
                                                key={row.id}
                                            >
                                                {+param.curQuestion.number === 0 || (+param.curQuestion.number === 16 && +userContext.tempUid === 3) || (param.directlyOpenFromTeamProj === true && +userContext.tempUid === 3) ? <></> :
                                                    <StyledTableCell align="left">
                                                        {getNameSelected(
                                                            row.raci,
                                                            raciData
                                                        )}
                                                    </StyledTableCell>}
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less"
                                                >{row.organization}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less"
                                                >{row.firstName}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less"
                                                >{row.lastName}
                                                </StyledTableCell>
                                                {/* {+param.curQuestion.number !== 0 ? <></> : <StyledTableCell className="text-show-less" align="left">{row.contact}</StyledTableCell>} */}
                                                {/* {+param.curQuestion.number === 0 ? <></> : <StyledTableCell align="left">{row.department}</StyledTableCell>} */}
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less"
                                                >{row.department}</StyledTableCell>
                                                {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell
                                                    //onMouseEnter={handlePopoverClose}
                                                    sx={{ minWidth: "115px" }}
                                                >
                                                    <Grid container spacing={2} className='btn-grid'>
                                                        <Grid item xs={6} sx={{ textAlign: "end" }} >
                                                            <Tooltip title='Edit'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='edit-btn'
                                                                    onClick={() => handleClickOpenForEdit(row.id, row.stakeMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='edit-btn-logo' src={editIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                            <Tooltip title='Delete'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='delete-btn'
                                                                    onClick={() => handleOpenStakeDeleteModal(row.id, row.stakeMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='delete-btn-logo' src={trashIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell> : <></>}
                                            </StyledTableRow>
                                        </HtmlTooltip>
                                    )
                                })) : (
                                <TableRow key={1}>
                                    <TableCell colSpan={6} align='center'>
                                        None added yet!
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* {+param.curQuestion.number === 0 || param.QuickView === true ? <></> : */}
            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ?
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Button className="stakholder-btn"
                        onClick={handleClickOpen}
                        startIcon={<AddIcon sx={{ color: "#56506d" }} />}
                        endIcon={<img loading="lazy" alt="imprend" className='icon-stakeholders' src={person} />}
                    >
                        Add people
                    </Button>
                </Grid> : <></>}
            <Dialog
                maxWidth={isUpdate === true ? "md" : "xl"}
                fullWidth={true}
                open={openDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ paddingBottom: "50px" }}>
                    <ItemStakeAddModel
                        QuickView={param.QuickView}
                        isOpen={openDialog}
                        isOpenFromTeamProj={isDirectlyOpenFromTeam}
                        parentNodeName={param.parentNodeName}
                        parentNodeId={param.parentNodeId}
                        isAppendType={isAppendType}
                        isAppendUid={isAppendUid}
                        isUpdate={isUpdate}
                        id={editId}
                        mappingId={editMappingId}
                        onClose={handleClose}
                        onSubmitClose={handleSubmitClose}
                        curQuestion={param.curQuestion}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openStakeDeleteModal}
                onClose={handleCloseStakeDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this person?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => deleteStake()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseStakeDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {param.QuickView === true ?
                <Box className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={param.onClose}
                    >
                        <ClearIcon className="close-btn-icon" />
                    </Button>
                </Box> : <></>}
        </Grid >
    );
});
export default ItemStakeAdd;
