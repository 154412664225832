import React, { useContext } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Grid, Paper, Slide, ThemeProvider, Typography, createTheme } from '@mui/material';
import "./ResumeHiagram.scss";
import { PreferencesContext } from '../../PreferenceContext';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import trashIcon from '../../assets/icons/trash-red.svg'
import { TransitionProps } from '@mui/material/transitions';
import { AlertType } from '../../shared/AlertType';
import { ResetHiagram } from '../../services/HiagramService';


const theme = createTheme({
    typography: {
      fontFamily: [
        'RoseauSlab-Light',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: { main: '#2c2449', light: '#d9d2f1' }
      , secondary: { main: '#091C69', light: '#E6E8F0' }
      , action: { hover: '#8c00040d' },
    },
    components: {
      // MuiPaper:{
      //   styleOverrides: { 
      //     root: { 
      //     borderRadius:'10px'
      //   } 
      // }
      // },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '14px', fontWeight: 600,
            padding: '16px 40px',
            borderRadius: '8px'
          }
        }
      }
    }
  });

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResumeHiagram() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [openResetModal, setResetModal] = React.useState(false);
    const [isBtnLoading, setBtnLoading] = React.useState(false);
    const { innerHeight: windowHeight } = window;
    const [flowDivHeight, setFlowDivHeight] = React.useState((windowHeight - 144) + "px");
    let navigate = useNavigate();
    const gotoQuestions = () => {
        updateUserContext({
            ...userContext,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjId: 0,
            teamProjName: "",
            teamProjPurpose: "",
            roleId: 0,
            roleName: "",
            activityId: 0,
            activityName: "",
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: "",
            isProj: "false",
            lastQuestionInfo: null
        });
        navigate("/question/" + userContext.hiagramId);
    };

    const gotoHiagram = () => {
        // navigate("/sharepartyAdd");
        updateUserContext({
            ...userContext,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjId: 0,
            teamProjName: "",
            teamProjPurpose: "",
            roleId: 0,
            roleName: "",
            activityId: 0,
            activityName: "",
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: "",
            isProj: "false",
            lastQuestionInfo: null
        });
        navigate("/hiagram/" + userContext.hiagramId);
    };

    const handleOpenResetModal = () => {
        //setResetModal(true);
       //navigate("/myprofile");
    //    navigate("/all-hiagram");
       navigate("/dashboard");
    };

    const handleCloseResetModal = () => {
        setResetModal(false);
    };

    async function resetHiagram() {
        setBtnLoading(true);
        try {
            let requestData = {
                huid: userContext.hiagramId,
                isDeleted: true,
                dataManagedBy: userContext.userId
            }
            let data: any;
            data = await ResetHiagram(requestData);
            if (data !== null && data.data === true) {
                setBtnLoading(false);
                handleCloseResetModal();
                gotoQuestions();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }

    }

    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box
                    className='resume-container' >
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: '10px' }}
                        className='wrapper'
                        style={{ height: flowDivHeight }}
                    >
                        <Grid className='main-post-login' container>
                            <Grid item xs={12} className="text-page">
                                {/* <Typography className='title-text'>Resume your hiagram<br></br>for <span className='color-text'>{userContext.hiagramName}?</span></Typography> */}
                                <Box className='button-main-grid'>
                                    <Typography className='title-text'>Resume your <span className='color-text'>{userContext.hiagramName}</span><br></br>hiagram? </Typography>
                                    <ThemeProvider theme={theme}>
                                    <Box className='button-box'>
                                        <Button className='startagain-button' onClick={handleOpenResetModal}>Home</Button>
                                        {/* <Button variant="contained" className='yes-button' onClick={gotoQuestions}>Yes</Button> */}
                                        <Button variant="contained" className='yes-button' onClick={gotoHiagram}>Yes</Button>
                                    </Box>
                                    </ThemeProvider>
                                </Box>
                                {/* <Grid className='button-main-grid'>
                                <Grid className="startagain-grid" item xs={6}><Button className='startagain-button' onClick={handleOpenResetModal}>Start again</Button></Grid>
                                <Grid className="yes-grid" item xs={6}><Button variant="contained" className='yes-button' onClick={gotoQuestions}>Yes</Button></Grid>
                            </Grid>*/}
                                {/* <Box className='button-main-grid'>
                                
                            </Box> */}
                            </Grid>
                        </Grid>

                    </Paper>
                </Box>
            </Fade>
            {/* Reset dialog */}
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={openResetModal}
                TransitionComponent={Transition}
                onClose={handleCloseResetModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='reset-dialog-box'
            >
                <Box className='img-box'>
                    <img loading="lazy" alt='imprend' className='delete-icon' src={trashIcon} />
                </Box>
                <DialogTitle className='dialog-title'>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        All current progress will be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={() => resetHiagram()}
                        className="delete-btn"
                        loading={isBtnLoading}
                    >
                        Delete
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseResetModal}
                        className="keep-btn"
                    >
                        Keep
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
}