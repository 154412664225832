const AMAZON_URL = 'https://s3-sa-east-1.amazonaws.com/azpay-checkout/payment-methods';

export function getSingleSelected(selectedValue: any, sourceList: any) {
    let result = sourceList.find((o: { value: any; }) => o.value === selectedValue);
    return (result === undefined || result === "" ? null : result);
}

export function getNameSelected(selectedValue: any, sourceList: any) {
    let result = sourceList.find((o: { value: any; }) => o.value === selectedValue);
    return (result === undefined || result === "" ? "" : result.label);
}

export function getValueFromName(selectedName: any, sourceList: any) {
    let result = sourceList.find((o: { name: any; }) => o.name === selectedName);
    return (result === undefined || result === "" ? null : result);
}

export function getDateFromTimestamp(timestamp: any) {
    let result = "";
    if (timestamp !== undefined && timestamp !== null) {
        result = formateDate(timestamp);
    }

    return result;
}

function formateDate(date: any) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    let curDate = new Date(0);
    curDate.setSeconds(date);
    let curDay = curDate.getDate();
    let curMonth = monthNames[curDate.getMonth()];
    let curYear = curDate.getFullYear();
    let curHour = curDate.getHours();
    let curMin = curDate.getMinutes();

    return (curMonth + " " + curDay + " " + curYear + " " + String(curHour).padStart(2, '0') + ":" + String(curMin).padStart(2, '0'));
}


export function getFlagSvgUrl(key: any, transparent = false) {
    const transparentStr = transparent ? '_transparent' : '';
    const FLAGS_SVGS_URLS: any = {
        adyen: `${AMAZON_URL}/adyen${transparentStr}.svg`,
        amex: `${AMAZON_URL}/amex${transparentStr}.svg`,
        aura: `${AMAZON_URL}/aura${transparentStr}.svg`,
        bin: `${AMAZON_URL}/bin${transparentStr}.svg`,
        boleto: `${AMAZON_URL}/boleto${transparentStr}.svg`,
        cirrus: `${AMAZON_URL}/cirrus${transparentStr}.svg`,
        credit_card: `${AMAZON_URL}/credit-card${transparentStr}.svg`,
        diners: `${AMAZON_URL}/diners${transparentStr}.svg`,
        direct_debit: `${AMAZON_URL}/direct_debit${transparentStr}.svg`,
        discover: `${AMAZON_URL}/discover${transparentStr}.svg`,
        ebay: `${AMAZON_URL}/ebay${transparentStr}.svg`,
        elo: `${AMAZON_URL}/elo${transparentStr}.svg`,
        eway: `${AMAZON_URL}/eway${transparentStr}.svg`,
        hipercard: `${AMAZON_URL}/hipercard${transparentStr}.svg`,
        jcb: `${AMAZON_URL}/jcb${transparentStr}.svg`,
        maestro: `${AMAZON_URL}/maestro${transparentStr}.svg`,
        mastercard: `${AMAZON_URL}/mastercard${transparentStr}.svg`,
        sage: `${AMAZON_URL}/sage${transparentStr}.svg`,
        shopify: `${AMAZON_URL}/shopify${transparentStr}.svg`,
        skrill: `${AMAZON_URL}/skrill${transparentStr}.svg`,
        skrill_moneybookers: `${AMAZON_URL}/skrill_moneybookers${transparentStr}.svg`,
        sodexo: `${AMAZON_URL}/sodexo${transparentStr}.svg`,
        solo: `${AMAZON_URL}/solo${transparentStr}.svg`,
        ticket: `${AMAZON_URL}/ticket${transparentStr}.svg`,
        visa: `${AMAZON_URL}/visa${transparentStr}.svg`,
        visa_electron: `${AMAZON_URL}/visa_electron${transparentStr}.svg`,
        western: `${AMAZON_URL}/western${transparentStr}.svg`,
        wirecard: `${AMAZON_URL}/wirecard${transparentStr}.svg`,
    };
    return FLAGS_SVGS_URLS[key];
}