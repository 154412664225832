import { NodeProps, useReactFlow, MarkerType, getOutgoers, getIncomers } from 'reactflow';

function useFocusNode(id: NodeProps['id']) {
  const { getNode, setCenter } = useReactFlow();
  const focusNode = () => {
    const currentNode = getNode(id);
    if (!currentNode) {
      return;
    }
    const x = currentNode.position.x + 200 / 2;
    const y = currentNode.position.y + 70 / 2;
    const zoom = 1.45;
    setCenter(x, y, { zoom, duration: 1000 });
  }
  return focusNode;

}

export default useFocusNode;
