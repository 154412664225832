import { useContext, useEffect, useState } from 'react';
import './MyAccount.scss';
import myAccountIcon from '../../../assets/icons/myAccountIcon.svg';
import { Avatar, Box, Button, Fade, Grid, Skeleton, TextField, Typography } from '@mui/material';
import { PreferencesContext } from '../../../PreferenceContext';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, Formik } from 'formik';
import { GetUserDataById } from '../../../services/AuthService';
import * as Yup from "yup";
import { AlertType } from '../../../shared/AlertType';
import { UpdateUser } from '../../../services/UserService';
import { LoadingButton } from '@mui/lab';

const userFormInitialValues = {
    firstName: "",
    lastName: "",
    email: ""
};

export default function MyAccount() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [userinitial, setUserInitial] = useState(userFormInitialValues);
    const [dataLoding, setDataLoding] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [isFocusedFirstName, setFocusedFirstName] = useState(false);
    const [isFocusedLastName, setFocusedLastName] = useState(false);
    const { user } = useAuth0();

    const userValildationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        email: Yup.string().email("Enter an valid email").required("Email is required"),
    });

    async function submitUserForm(values: any, resetForm: any) {
        setBtnLoading(true);
        try {
            const user: any = await UpdateUser({
                uid: userContext.userId,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                dataManagedBy: userContext.userId
            });
            if (user.data === true) {
                fetchData();
                updateUserContext({
                    ...userContext,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    fullName: values.firstName + " " + values.lastName,
                    email: values.email,
                    isAlert: true,
                    alertMessage: user.message,
                    alertType: AlertType.Success,
                });
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
        resetForm();
    }

    async function fetchData() {
        setDataLoding(true);
        try {
            const result: any = await GetUserDataById(userContext.userId);
            let userData = result.data;
            let userForm = userFormInitialValues;
            userForm.firstName = userData.first_name;
            userForm.lastName = userData.last_name;
            userForm.email = userData.email;
            setUserInitial(userForm);
        }
        catch (e: any) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.response.message,
                alertType: AlertType.Error,
            });
        }
        setDataLoding(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className='myaccount-wrapper'>
                <Box className='myaccount-head-box'>
                    <Typography className='myaccount-title'>My account</Typography>
                </Box>

                <Box className='myaccount-body-box'>

                    <Box className='myaccount-img-box'>
                        <Avatar
                            className='avtar' sx={{ fontSize: 70 }}
                            alt={userContext.fullName}
                            //src={user?.picture}
                            //src={`https://s.gravatar.com/avatar/bd4ae38ac8635bd1422d3cdd25483d75?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F${userContext.firstName.slice(0,1).toLocaleLowerCase() + userContext.lastName.slice(0,1).toLocaleLowerCase()}.png`}
                            src={`https://i1.wp.com/cdn.auth0.com/avatars/${userContext.firstName.slice(0,1).toLocaleLowerCase() + userContext.lastName.slice(0,1).toLocaleLowerCase()}.png?ssl=1`}
                        />
                    </Box>
                    <Formik
                        enableReinitialize
                        initialValues={userinitial}
                        validationSchema={userValildationSchema}
                        onSubmit={(values, { resetForm }) => {
                            submitUserForm(values, resetForm);
                        }}
                    >
                        {(props: any) => {
                            return (
                                <Form>
                                    <Box className='myaccount-form-box'>
                                        <Box className='myaccount-form-row'>
                                            <Box className='myaccount-form-right'>
                                                <Typography className='myaccount-textfield-label'>First name</Typography>
                                                {dataLoding ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                                    <>
                                                        <TextField
                                                            id='firstName'
                                                            fullWidth
                                                            type='text'
                                                            placeholder='First name'
                                                            value={props.values.firstName}
                                                            onChange={props.handleChange("firstName")}
                                                            inputProps={{ maxLength: 100 }}
                                                            onBlur={() => setFocusedFirstName(false)}
                                                            onFocus={() => setFocusedFirstName(true)}
                                                            error={
                                                                props.errors.firstName && props.touched.firstName
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {isFocusedFirstName && <Typography className="textfield-counter">{(props.values.firstName !== null ? props.values.firstName.length : 0) + "/" + 100}</Typography>}
                                                    </>}
                                                {props.errors.firstName && props.touched.firstName ?
                                                    <Typography className="validation-message" >
                                                        {props.errors.firstName}
                                                    </Typography> : <></>}
                                            </Box>
                                            <Box className='myaccount-form-left'>
                                                <Typography className='myaccount-textfield-label'>Last name</Typography>
                                                {dataLoding ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                                    <>
                                                        <TextField
                                                            id='lastName'
                                                            fullWidth
                                                            type='text'
                                                            placeholder='Last name'
                                                            value={props.values.lastName}
                                                            onChange={props.handleChange("lastName")}
                                                            inputProps={{ maxLength: 100 }}
                                                            onBlur={() => setFocusedLastName(false)}
                                                            onFocus={() => setFocusedLastName(true)}
                                                            error={
                                                                props.errors.lastName && props.touched.lastName
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {isFocusedLastName && <Typography className="textfield-counter">{(props.values.firstName !== null ? props.values.firstName.length : 0) + "/" + 100}</Typography>}
                                                    </>}
                                                {props.errors.lastName && props.touched.lastName ?
                                                    <Typography className="validation-message" >
                                                        {props.errors.lastName}
                                                    </Typography> : <></>}
                                            </Box>
                                        </Box>
                                        <Box className='myaccount-form-row'>
                                            <Box className='myaccount-form-field'>
                                                <Typography className='myaccount-textfield-label'>Work email</Typography>
                                                {dataLoding ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={52} /> :
                                                    <TextField
                                                        id='email'
                                                        fullWidth
                                                        type='text'
                                                        disabled={true}
                                                        placeholder='Work email'
                                                        value={props.values.email}
                                                        onChange={props.handleChange("email")}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.email && props.touched.email
                                                                ? true
                                                                : false
                                                        }
                                                    />}
                                                {props.errors.email && props.touched.email ?
                                                    <Typography className="validation-message" >
                                                        {props.errors.email}
                                                    </Typography> : <></>}
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className='myaccount-buttons'>
                                        <Button className='button-canel' >Cancel</Button>
                                        <LoadingButton
                                            // color="primary"
                                            loading={isBtnLoading}
                                            // loadingPosition="start"
                                            fullWidth
                                            // startIcon={<></>}
                                            variant="contained"
                                            type="submit"
                                            className='button-submit'
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Box>
                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
            </Box>
        </Fade>
    );
}