import { Grid, DialogContentText, DialogActions, Skeleton, Button, TableContainer, Paper, Table, TableHead, TableRow, Tooltip, TableCell, TableBody, tableCellClasses, styled, Typography, Box, useTheme, Card, Fab, TooltipProps, tooltipClasses } from "@mui/material";
import * as React from "react";
import editIcon from '../../../assets/icons/edit-2.svg'
import trashIcon from '../../../assets/icons/trash.svg'
import Event from '../../../assets/icons/events.svg'
import Calendar from '../../../assets/icons/calendar.svg'
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import ClearIcon from '@mui/icons-material/Clear';
import { AlertType } from "../../../shared/AlertType";
import ItemEventAddModel from './ItemEventAddModel'
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { TransitionProps } from '@mui/material/transitions';
import { PreferencesContext } from "../../../PreferenceContext";
import _ from "lodash";
import "./ItemEventAdd.scss";
import { CreateQusAnswer, DeleteEvent, GetEventList, GetQuestionAnswer, GetSchedulesList, GetTypeList } from "../../../services/EventService";
import { getNameSelected } from "../../../services/common/commonFunction";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);


interface IEventDetail {
    id: any;
    eventMappingId: any;
    name: any;
    type: any;
    firstDate: any;
    recurring: any;
    location: any;
    dayOfWeek: any;
    time: any;
    endDate: any;
    organization: any;
    description: any;
    duration: any;
    schedule: any;
}

const initEventDetail: IEventDetail = {
    id: 0,
    eventMappingId: 0,
    name: "",
    type: "",
    firstDate: "",
    recurring: "",
    location: "",
    dayOfWeek: "",
    time: "",
    // time: dayjs(null),
    endDate: "",
    organization: "",
    description: "",
    duration: "",
    schedule: ""
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4FA79B",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E2FAFF",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        describeChild={true}
        {...props}
        classes={{ popper: className }}
        slotProps={{ popper: { disablePortal: true } }}
        PopperProps={{
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['right', 'left', 'top', 'bottom'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundariesElement: 'window',
                    },
                },
            ],
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        minWidth: 500,
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: '10px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    [`& .${tooltipClasses.popper}`]: {
        marginTop: theme.spacing(1),
    }
}));

// type DirectionType = "right" | "left" | "up" | "down";

// export default function ItemEventAdd() {

const ItemEventAdd = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editMappingId, setEditMappingId] = useState(0);
    const [questionAnswerId, setQuestionAnswerId] = useState(0);
    const [eventList, setEventList] = useState([initEventDetail]);
    const [openDialog, setOpenDialog] = useState(false);
    const [scheduleData, setScheduleData] = useState([]);
    const [openEventDeleteModal, setEventDeleteModal] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    // const [checked, setChecked] = useState(false);
    const [typeData, setTypeData] = useState([]);
    // const [direction, setDirection] = useState<DirectionType>("up");
    // const containerRef: any = React.useRef();
    const mainTheme = useTheme();

    useImperativeHandle(ref, () => ({
        submitClick() {
            saveAnswer();
        },
        getData() {
            fetchSchedule();
            fetchType();
            // fetchdayOfWeek();
            fetchEventList();
            fetchEventQuestionAnswer();
        }
    }),);

    const handleClickOpen = () => {
        setEditId(0);
        setEditMappingId(0);
        setIsUpdate(false);
        setOpenDialog(true);
    };
    const handleClickOpenForEdit = (id: any, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setIsUpdate(true);
        setOpenDialog(true);
    };

    const handleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialog(false);
    };

    const handleSubmitClose = () => {
        setOpenDialog(false);
        fetchEventList();
        fetchEventQuestionAnswer();
    };

    const handleOpenEventDeleteModal = (id: number, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setEventDeleteModal(true);
    };
    const handleCloseEventDeleteModal = () => {
        setEventDeleteModal(false);
    };

    async function fetchSchedule() {
        try {
            const scheduleGetData: any = await GetSchedulesList();
            if (scheduleGetData !== undefined && scheduleGetData !== null && scheduleGetData.data !== null && scheduleGetData.data.data !== null && scheduleGetData.data.data.data !== null) {
                setScheduleData(scheduleGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchType() {
        try {
            const typeGetData: any = await GetTypeList();
            if (typeGetData !== undefined && typeGetData !== null && typeGetData.data !== null && typeGetData.data.data !== null && typeGetData.data.data.data !== null) {
                setTypeData(typeGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    // async function fetchdayOfWeek() {
    //     try {
    //         const dayOfWeekGetData: any = await GetDayOfWeekList();
    //         if (dayOfWeekGetData !== undefined && dayOfWeekGetData !== null && dayOfWeekGetData.data !== null && dayOfWeekGetData.data.data !== null && dayOfWeekGetData.data.data.data !== null) {
    //             setDayOfWeekAllData(dayOfWeekGetData.data.data.data);
    //         }
    //     }
    //     catch (error: any) {
    //         if (error) {
    //             updateUserContext({
    //                 ...userContext,
    //                 isAlert: true,
    //                 alertMessage: error.response.message,
    //                 alertType: AlertType.Error,
    //             });
    //         }
    //     }
    // }

    async function fetchEventList() {
        try {
            setLoading(true);
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            if (+param.curQuestion.number === 20) {
                let appendType = "ACTIVITY";
                let appendUID = userContext.activityId;
                if (param.curQuestion.hiagram !== undefined) {
                    appendType = param.curQuestion.hiagram.type;
                    appendUID = param.curQuestion.hiagram.typeId;
                }
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            } else if (+param.curQuestion.number === 0) {
                let appendType = "NONE";
                let appendUID = 0;
                params.append("appendType", appendType.toString());
                params.append("appendUid", appendUID.toString());
            }

            const eventres: any = await GetEventList(params);
            if (eventres !== null && eventres.data !== null && eventres.data.data !== null) {
                let data = eventres.data.data;
                let list: any[] = [];
                data.forEach((element: any) => {
                    let item: IEventDetail = {
                        id: element.uid,
                        name: element.name,
                        type: element.type,
                        firstDate: element.first_date !== null ? element.first_date.split("-").reverse().join("-") : "",
                        recurring: element.recurring,
                        location: element.location,
                        dayOfWeek: element.day_of_week,
                        time: (element.time === "" || element.time === null) ? "" : dayjs.utc(element.time).format("h:mm A"),
                        //time: new Date(element.time).toTimeString(),
                        endDate: element.end_date !== null ? element.end_date.split("-").reverse().join("-") : "",
                        organization: element.organization,
                        description: element.description,
                        duration: element.duration,
                        schedule: element.schedule,
                        eventMappingId: element.eventMapping === undefined ? 0 : element.eventMapping[0].uid,
                    }
                    list.push(item);
                });
                setEventList(list);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }
    async function fetchEventQuestionAnswer() {
        try {
            setLoading(true);
            let projUID = 0;
            let teamUID = 0;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            params.append("tuid", teamUID.toString());
            params.append("puid", projUID.toString());
            params.append("ruid", userContext.roleId.toString());
            params.append("auid", userContext.activityId.toString());
            const questionAnswer: any = await GetQuestionAnswer(params);
            if (questionAnswer !== null && questionAnswer.data !== null && questionAnswer.data.data !== null) {
                let data = questionAnswer.data.data;
                setQuestionAnswerId(data.uid);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }
    async function deleteEvent() {
        setBtnLoading(true);
        try {
            const event: any = await DeleteEvent(editId, {
                huid: userContext.hiagramId,
                mappingUid: editMappingId,
                is_deleted: true,
                row_managed_by: userContext.userId
            });
            if (event.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: event.message,
                    alertType: AlertType.Success,
                    isItemCountUpdated: true
                });
                setEventDeleteModal(false);
                fetchEventList();
                fetchEventQuestionAnswer();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function saveAnswer() {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            let projUID = 0;
            let teamUID = 0;
            let roleUID = userContext.roleId;
            let activityUID = userContext.activityId;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            try {
                let requestData = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: questionAnswerId,
                    teamUid: teamUID,
                    projUid: projUID,
                    roleUid: roleUID,
                    activityUid: activityUID,
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }

                let data: any;
                data = await CreateQusAnswer(requestData);
                if (data !== null && data.data === true) {
                    param.changeNextQuestion();
                }
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            param.changeNextQuestion();
        }
    }

    // useEffect(() => {
    //     fetchEventList();
    // }, []);

    useEffect(() => {
    }, [eventList]);

    let evenCounter = 0;
    let oddCounter = 0;
    return (
        <Grid container className="event-list-grid" >
            {param.QuickView === false ?
                <Grid item xs={12} className="event-icon-group" ><img loading="lazy" alt="imprend" className='icon-event' src={Event} />
                    <Typography className="event-icon-name">Event</Typography>
                </Grid>
                :
                <Grid item xs={12} className="heading-container-grid-event">
                    <Grid className="header-css" item xs={12}>
                        <img loading="lazy" alt="imprend" className='header-icon' src={Event} />
                        <Typography className="icon-text">Event</Typography>
                        {+param.curQuestion.number === 0 ?
                            <Fab disabled className="fab-circule">
                                <Typography><b>{eventList.length}</b></Typography>
                            </Fab> : <></>}
                    </Grid>
                    {/* <Grid item xs={1} className="clear-icon-grid">
                        <Button
                            disableRipple
                            className="btn-cancel"
                            onClick={param.onClose}
                        >
                            <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                        </Button>
                    </Grid> */}
                </Grid>
            }
            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Name</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Type</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Date</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Recurring</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '16%', fontSize: 12 }}>Location</StyledTableCell>
                            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell align="center" sx={{ width: '20%', fontSize: 12 }}>Action</StyledTableCell> : <></>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {isLoading === true ? (
                                _.times(4, (i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={6}>
                                            <Skeleton variant="rounded" width='auto' height={25} />
                                        </TableCell>
                                    </TableRow>
                                ))) : eventList.filter((x) => x.id > 0).length > 0 ? (eventList && eventList.map((row: any, index: any) => {
                                    const placement = index < 3 ? 'bottom' : 'top';
                                    return (
                                        <HtmlTooltip
                                            key={index}
                                            placement={placement}
                                            title={
                                                <React.Fragment>
                                                    <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.type, typeData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Type:</b> {getNameSelected(row.type, typeData)}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.location)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Location:</b> {row.location}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.firstDate)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Date:</b> {row.firstDate}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.time)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Time:</b> {row.time}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.duration)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Duration:</b> {row.duration}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.recurring === true ? "Yes" : "No")} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Recurring:</b> {row.recurring === true ? "Yes" : "No"}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.schedule, scheduleData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Schedule:</b> {getNameSelected(row.schedule, scheduleData)}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.endDate)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>End Date:</b> {row.endDate}</Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <StyledTableRow
                                                sx={{ fontSize: 12, height: 50, cursor: 'pointer' }}
                                                key={row.id}>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.name}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{getNameSelected(
                                                        row.type,
                                                        typeData
                                                    )}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.firstDate}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.recurring === true ? "Yes" : "No"}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.location}</StyledTableCell>
                                                {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell
                                                    sx={{ minWidth: "115px" }}>
                                                    <Grid container spacing={2} className='btn-grid'>
                                                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                            <Tooltip title='Edit'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='edit-btn'
                                                                    onClick={() => handleClickOpenForEdit(row.id, row.eventMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='edit-btn-logo' src={editIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                            <Tooltip title='Delete'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='delete-btn'
                                                                    onClick={() => handleOpenEventDeleteModal(row.id, row.eventMappingId)}>
                                                                    <img loading="lazy" alt="imprend" className='delete-btn-logo' src={trashIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell> : <></>}
                                            </StyledTableRow>
                                        </HtmlTooltip>
                                    )
                                })) : (
                                <TableRow key={1}>
                                    <TableCell colSpan={6} align='center'>
                                        None added yet!
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    </TableBody>
                </Table>

            </TableContainer>
            {/* {+param.curQuestion.number === 0 || param.QuickView === true ?  */}
            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ?
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Button className="event-btn"
                        onClick={handleClickOpen}
                        startIcon={<AddIcon sx={{ color: "#4FA79B" }} />}
                        endIcon={<img loading="lazy" alt="imprend" className='icon-event' src={Calendar} />}
                    >
                        Add event
                    </Button>
                </Grid> : <></>}
            {(+param.curQuestion.number === 0 || param.curQuestion.hiagram !== undefined) && eventList.length > 0 ?
                <Grid className='time-line-container-grid-event'>
                    <Timeline position="alternate">
                        <Box className='timeline-start'></Box>
                        <TimelineItem className='timeline-item-upper-grid'>
                            <TimelineSeparator className='timeline-seprator' >
                                <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: mainTheme.palette.primary.main }} />
                            </TimelineSeparator>
                            <TimelineContent></TimelineContent>
                        </TimelineItem>
                        {eventList.length > 0 && eventList.map((row, index) => {
                            return (
                                <>
                                    {index % 2 === 0 ?
                                        <>
                                            {(evenCounter++) % 2 === 0 ?
                                                <HtmlTooltip
                                                    key={index}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.time)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Time:</b> {row.time}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.duration)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Duration:</b> {row.duration}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.schedule, scheduleData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Schedule:</b> {getNameSelected(row.schedule, scheduleData)}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.endDate)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>End Date:</b> {row.endDate}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TimelineItem sx={{ cursor: 'pointer' }} key={index}>
                                                        <TimelineSeparator sx={{ height: '70px' }}>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: mainTheme.palette.primary.main }}>
                                                            </TimelineDot>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingRight: "5px !important", marginLeft: "11px" }}>
                                                            <Box className='card-content-right-side' color={mainTheme.palette.primary.main}>
                                                                <Box className='card-one' sx={{ backgroundColor: mainTheme.palette.primary.main }} >
                                                                    <Typography className='typography-text'>{row.firstDate}</Typography>
                                                                    <Typography className='typography-text text-show-less'>{row.name}</Typography>
                                                                    {/* <Typography className='typography-text'>{getNameSelected(
                                                                    row.type,
                                                                    typeData
                                                                )}</Typography> */}
                                                                </Box>
                                                                <Card elevation={0} className='triangle triangle-right' sx={{ borderColor: `transparent transparent transparent ${mainTheme.palette.primary.main}` }}>
                                                                </Card>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </HtmlTooltip>
                                                :
                                                <HtmlTooltip
                                                    key={index}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.time)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Time:</b> {row.time}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.duration)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Duration:</b> {row.duration}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.schedule, scheduleData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Schedule:</b> {getNameSelected(row.schedule, scheduleData)}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.endDate)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>End Date:</b> {row.endDate}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TimelineItem sx={{ cursor: 'pointer' }}>
                                                        <TimelineSeparator sx={{ height: '70px' }}>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#4D81B4" }}>
                                                            </TimelineDot>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingRight: "5px !important", marginLeft: "11px" }}>
                                                            <Box className='card-content-right-side' color="#4D81B4">
                                                                <Box className='card-one' sx={{ backgroundColor: "#4D81B4" }} >
                                                                    <Typography className='typography-text'>{row.firstDate}</Typography>
                                                                    <Typography className='typography-text text-show-less'>{row.name}</Typography>
                                                                    {/* <Typography className='typography-text'>{getNameSelected(
                                                                    row.type,
                                                                    typeData
                                                                )}</Typography> */}
                                                                </Box>
                                                                <Card elevation={0} className='triangle triangle-right' sx={{ borderColor: 'transparent transparent transparent #4D81B4' }}>
                                                                </Card>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </HtmlTooltip>
                                            }
                                        </>
                                        :
                                        <>
                                            {(oddCounter++) % 2 === 0 ?
                                                <HtmlTooltip
                                                    key={index}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.time)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Time:</b> {row.time}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.duration)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Duration:</b> {row.duration}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.schedule, scheduleData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Schedule:</b> {getNameSelected(row.schedule, scheduleData)}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.endDate)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>End Date:</b> {row.endDate}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TimelineItem sx={{ cursor: 'pointer' }}>
                                                        <TimelineSeparator >
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: "#4FA79B" }} />
                                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#4FA79B" }}>
                                                            </TimelineDot >
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingLeft: "5px!important", marginRight: "11px" }}>
                                                            <Box className='card-content-left-side'>
                                                                <Card elevation={0} className='triangle triangle-left' sx={{ borderColor: '#ffffff  #4FA79B  #ffffff #ffffff' }}>
                                                                </Card>
                                                                <Box className='card-two' sx={{ backgroundColor: "#4FA79B" }} >
                                                                    <Typography className='typography-text'>{row.firstDate}</Typography>
                                                                    <Typography className='typography-text text-show-less'>{row.name}</Typography>
                                                                    {/* <Typography className='typography-text'>{getNameSelected(
                                                                    row.type,
                                                                    typeData
                                                                )}</Typography> */}
                                                                </Box>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </HtmlTooltip>
                                                :
                                                <HtmlTooltip
                                                    key={index}
                                                    title={
                                                        <React.Fragment>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Name:</b> {row.name}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.time)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Time:</b> {row.time}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.duration)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Duration:</b> {row.duration}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.schedule, scheduleData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Schedule:</b> {getNameSelected(row.schedule, scheduleData)}</Typography>
                                                            <Typography onClick={() => navigator.clipboard.writeText(row.endDate)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>End Date:</b> {row.endDate}</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TimelineItem sx={{ cursor: 'pointer' }}>
                                                        <TimelineSeparator>
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323', borderColor: "#A7679B" }} />
                                                            <TimelineDot className='timeline-round' sx={{ backgroundColor: "#A7679B" }}>
                                                            </TimelineDot >
                                                            <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ paddingTop: "2px !important", paddingBottom: "0px !important", paddingLeft: "5px!important", marginRight: "11px" }}>
                                                            <Box className='card-content-left-side'>
                                                                <Card elevation={0} className='triangle triangle-left' sx={{ borderColor: '#ffffff  #A7679B  #ffffff #ffffff' }}>
                                                                </Card>
                                                                <Box className='card-two' sx={{ backgroundColor: "#A7679B" }} >
                                                                    <Typography className='typography-text'>{row.firstDate}</Typography>
                                                                    <Typography className='typography-text text-show-less'>{row.name}</Typography>
                                                                    {/* <Typography className='typography-text'>{getNameSelected(
                                                                    row.type,
                                                                    typeData
                                                                )}</Typography> */}
                                                                </Box>
                                                            </Box>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </HtmlTooltip>
                                            }
                                        </>
                                    }
                                </>
                            )
                        })}
                        <TimelineItem className='timeline-item-upper-grid'>
                            <TimelineSeparator className='timeline-seprator' >
                                <TimelineConnector sx={{ width: "8px !important", backgroundColor: '#232323' }} />
                            </TimelineSeparator>
                            <TimelineContent></TimelineContent>
                        </TimelineItem>
                        <Box className='timeline-start'></Box>
                    </Timeline>
                </Grid> : <></>}

            <Dialog
                maxWidth={isUpdate === true ? "md" : "xl"}
                fullWidth={true}
                open={openDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ paddingBottom: "50px" }}>
                    <ItemEventAddModel
                        QuickView={param.QuickView}
                        isOpen={openDialog}
                        isUpdate={isUpdate}
                        id={editId}
                        mappingId={editMappingId}
                        onClose={handleClose}
                        curQuestion={param.curQuestion}
                        onSubmitClose={handleSubmitClose}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openEventDeleteModal}
                onClose={handleCloseEventDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this Event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => deleteEvent()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseEventDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {param.QuickView === true ?
                <Box className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={param.onClose}
                    >
                        <ClearIcon className="close-btn-icon" />
                    </Button>
                </Box> : <></>}
        </Grid >
    );
});
export default ItemEventAdd;
