import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import logoWhite from '../assets/images/logo_white.png';
// import avatar from '../assets/images/avatar.png';
import home from '../assets/icons/home.svg';
import homeIcon from '../assets/icons/homeIcon.svg'
import profile from '../assets/icons/profile.svg';
import profileIcon from '../assets/icons/profileIcon.svg';
import signOut from '../assets/icons/logout-icon.svg';
//import "../theme/style.scss";
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Drawer, Grid, List, Paper, ListItem, Menu, ListItemButton, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Link } from '@mui/material';
import People from '../assets/icons/people.svg';
import { PreferencesContext } from '../PreferenceContext';
// import { Context } from '../services/models/Context';
import { useAuth0 } from '@auth0/auth0-react';
// import { useEffect } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function Nav(props: any) {
  const { userContext, updateUserContext } = React.useContext(PreferencesContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl4, setAnchorEl4] = React.useState<null | HTMLElement>(null);
  const [openHelpModal, setOpenHelpModal] = React.useState(false)
  const { logout, isAuthenticated, user } = useAuth0();
  const open = Boolean(anchorEl);
  const open4 = Boolean(anchorEl4);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClient = () => {
    setAnchorEl(null);
    navigate("/client");
  };

  const handleUser = () => {
    setAnchorEl(null);
    navigate("/user");
  }

  const handleProfile = () => {
    setAnchorEl(null);
    navigate("/myprofile");
  }

  const handleHome = () => {
    setAnchorEl(null);
    // navigate("/all-hiagram");
    navigate("/dashboard");
  }

  // const handleInitedUser = () => {
  //   setAnchorEl(null);
  //   navigate("/inviteduser");
  // }
  let navigate = useNavigate();

  // const dashboardClick = () => {
  //   navigate("/dashboard");
  // };

  // const myprofileClick = () => {
  const onLogoClick = () => {
    // navigate("/myprofile");
    // navigate("/all-hiagram");
    navigate("/dashboard");
  };

  const profieClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl4(event.currentTarget);
  };

  const handalHelpClickModal = () => {
    setOpenHelpModal(true);
  }

  const handalCloseHelpClickModal = () => {
    setOpenHelpModal(false);
  }
  const profileClose = (page: string) => {
    //let page = event.target.innerText;
    switch (page) {
      case "myprofile": {
        setAnchorEl4(null);
        navigate("/myprofile");
        // navigate("/all-hiagram");
        break;
      }

      case "users": {
        setAnchorEl4(null);
        navigate("/user");
        // navigate("/all-hiagram");
        break;
      }

      case "dashboard": {
        setAnchorEl4(null);
        navigate("/dashboard");
        // navigate("/all-hiagram");
        break;
      }
      case "logOut": {
        //logout();
        logout({ logoutParams: { returnTo: userContext.clientIsFree === true ? window.location.origin + "/free-login" : window.location.origin } });
        break;
      }
      default: {
        setAnchorEl4(null);
      }
    }
    setAnchorEl4(null);
  };

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     setAnchorEl4(null);
  //     let initContext: Context = new Context();
  //     updateUserContext(initContext);
  //     navigate("/");
  //   }
  // }, [isAuthenticated]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Grid>
            {/* {userContext.userRoleId === "4" ?
              <></>
              :
              <IconButton
                color="inherit"
                aria-label="Menu"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
            } */}
            {/* <IconButton
              color="inherit"
              aria-label="Menu"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <MenuIcon />
            </IconButton> */}
            <React.Fragment key="Left">
              <Drawer
                anchor="left"
                open={open}
                onClose={handleClose}
              >
                <Box
                  sx={{ width: 250 }}
                  role="presentation"
                >
                  <List>
                    {userContext.userRoleId === "3" ?
                      <>
                        <ListItem key="home" disablePadding>
                          <ListItemButton onClick={handleHome}>
                            <ListItemIcon>
                              <img loading="lazy" src={homeIcon} alt="imprend" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography>Home</Typography>} />
                          </ListItemButton>
                        </ListItem>

                        <ListItem key="profile" disablePadding>
                          <ListItemButton onClick={handleProfile}>
                            <ListItemIcon>
                              <img loading="lazy" src={profileIcon} alt="imprend" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography>Profile</Typography>} />
                          </ListItemButton>
                        </ListItem>

                        <ListItem key="User" disablePadding>
                          <ListItemButton onClick={handleUser}>
                            <ListItemIcon>
                              <img loading="lazy" src={People} alt="imprend" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography>Manage users</Typography>} />
                          </ListItemButton>
                        </ListItem>
                        {/* <ListItem key="User" disablePadding>
                          <ListItemButton onClick={handleInitedUser}>
                            <ListItemIcon>
                              <img src={People} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography>Invited users</Typography>} />
                          </ListItemButton>
                        </ListItem> */}
                      </>
                      :
                      <></>
                    }
                    {userContext.userRoleId === "1" ?
                      <ListItem key="Client" disablePadding>
                        <ListItemButton onClick={handleClient}>
                          <ListItemIcon>
                            <img loading="lazy" src={People} alt="imprend" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography>Client</Typography>} />
                        </ListItemButton>
                      </ListItem>
                      :
                      <></>
                    }
                    {/* <ListItem key="Client" disablePadding>
                      <ListItemButton onClick={handleClient}>
                        <ListItemIcon>
                          <img src={People} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography>Client</Typography>} />
                      </ListItemButton>
                    </ListItem> */}

                    {/* <ListItem key="User" disablePadding>
                      <ListItemButton onClick={handleUser}>
                        <ListItemIcon>
                          <img src={People} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography>User</Typography>} />
                      </ListItemButton>
                    </ListItem> */}

                  </List>
                </Box>
              </Drawer>
            </React.Fragment>
          </Grid>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'flex', sm: 'flex', marginLeft: 10 } }}
            // onClick={myprofileClick}
            onClick={onLogoClick}
            style={{ cursor: 'pointer' }}
          >
            <img loading="lazy" className='nav-logo' src={logoWhite} alt="logo" />
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <IconButton
              // size="large"
              sx={{ padding: 0 }}
              color="inherit"
              onClick={handalHelpClickModal}>
              <HelpOutlineIcon />
            </IconButton>
            {props == undefined || props.isHidden == false ? <>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <NotificationsNoneIcon />
              </IconButton>
              <Typography sx={{ alignItems: "center", display: "flex", cursor: 'pointer' }} onClick={profieClick}>
                {userContext.fullName}
              </Typography>

              <IconButton onClick={profieClick}>
                {/* <Avatar sx={{ height: '25px', width: 'auto', cursor: 'pointer' }} alt={userContext.fullName}  src={avatar} /> */}
                <Avatar
                  sx={{ height: '25px', width: '25px', cursor: 'pointer' }}
                  alt={userContext.fullName}
                  //src={user?.picture}
                  //src={`https://s.gravatar.com/avatar/bd4ae38ac8635bd1422d3cdd25483d75?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F${userContext.firstName.slice(0,1).toLocaleLowerCase() + userContext.lastName.slice(0,1).toLocaleLowerCase()}.png`}
                  src={`https://i1.wp.com/cdn.auth0.com/avatars/${userContext.firstName.slice(0, 1).toLocaleLowerCase() + userContext.lastName.slice(0, 1).toLocaleLowerCase()}.png?ssl=1`}
                />
              </IconButton>
              <IconButton
                sx={{ fontSize: "500px" }}
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={profieClick}
              >
                <KeyboardArrowDownIcon />
              </IconButton></>
              : <></>}
          </Box>
          <Box>
            <Menu
              className='popover'
              anchorEl={anchorEl4}
              open={open4}
              onClose={() => profileClose("")}
              PaperProps={{
                elevation: 0,
                sx: {
                  top: '60px !important',
                  left: 'auto',
                  borderRadius: '10px !important',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    right: 14,
                    width: 100,
                    height: 10,
                    bgcolor: 'background.paper',
                  },
                },
              }}>

              <Paper elevation={0} className='paper-menu-item'>
                <MenuItem className='menu-item' onClick={() => profileClose("dashboard")}>
                  <ListItemIcon className='menu-icon-home'>
                    <img loading="lazy" src={home} alt="imprend" />
                  </ListItemIcon>
                  <Typography className='text-typography' >
                    Home
                  </Typography>
                  {/* <Box className='right-div'>
                  </Box> */}
                </MenuItem>
              </Paper>
              <Paper elevation={0} className='paper-menu-item'>
                <MenuItem className='menu-item' onClick={() => profileClose("myprofile")}>
                  <ListItemIcon className='menu-icon-home'>
                    <img loading="lazy" src={profile} alt="imprend" />
                  </ListItemIcon>
                  <Typography className='text-typography' >
                    Profile
                  </Typography>
                  {/* <Box className='right-div'>
                  </Box> */}
                </MenuItem>
              </Paper>
              {userContext.clientIsFree === true && userContext.userRoleId === "4" ? <></> :
                <Paper elevation={0} className='paper-menu-item'>
                  <MenuItem className='menu-item' onClick={() => profileClose("users")}>
                    <ListItemIcon className='menu-icon-home'>
                      <img loading="lazy" src={People} alt="imprend" />
                    </ListItemIcon>
                    <Typography className='text-typography' >
                      Users
                    </Typography>
                    {/* <Box className='right-div'>
                  </Box> */}
                  </MenuItem>
                </Paper>
              }
              <Paper elevation={0} className='paper-menu-item-last'>
                <MenuItem className='menu-item' onClick={() => profileClose("logOut")}>
                  <ListItemIcon className='menu-icon-home'>
                    <img loading="lazy" src={signOut} alt="imprend" />
                  </ListItemIcon>
                  <Typography className='text-typography' >
                    Sign out
                  </Typography>
                  {/* <Box className='right-div'>
                  </Box> */}
                </MenuItem>
              </Paper>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Dialog
        maxWidth="xs"
        fullWidth={true}
        open={openHelpModal}
        // TransitionComponent={Transition}
        //onClose={handleCloseWelcomeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='help-dialog-box'
      >
        <Box className='help-img-box'>
          {/* <img loading="lazy" alt="imprend" className='welecome-icon' src={userImage} /> */}
          <QuestionMarkIcon className='help-icon' />
        </Box>
        <DialogTitle className='help-dialog-title'>Confused?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='help-popup-text'>
            Visit our Youtube channel for helpful videos or email us at <Link href='mailto:hello@imprend.com' underline="hover" target="_blank" rel="noopener">hello@imprend.com </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="help-dialog-action">
          <Button
            variant='contained'
            onClick={handalCloseHelpClickModal}
            className="help-close-btn"
          >
            Close
          </Button>

        </DialogActions>
      </Dialog>
    </Box >
  );
}
