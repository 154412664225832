import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetProjectList = (params: any) =>
    GET(`${BASE_URL}/proj/list?` + params, null);

export const CreateProject = (data: any) =>
    POST(`${BASE_URL}/proj/create`, data);

export const GetProjdetail = (params: any) =>
    GET(`${BASE_URL}/proj/getdetail?` + params, null);

export const UpdateProj = (data: any) =>
    POST(`${BASE_URL}/proj/update`, data);

export const GetStatusList = () =>
    GET(`${BASE_URL}/proj/status`, null);

export const GetProjdetailById = (params: any) =>
    GET(`${BASE_URL}/proj/getdetailbyuid?` + params, null);

export const ProjectGetAllDetail = (params: any) =>
    GET(`${BASE_URL}/proj/hiagramgetalldetail?` + params, null);
export const UpdateProjDataOfProjectCard = (data: any) =>
    POST(`${BASE_URL}/proj/updatecard`, data);

export const UpdateProjPurpose = (data: any) =>
    POST(`${BASE_URL}/proj/updatepurpose`, data);