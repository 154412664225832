// function getEnvironment() {
//     return {
//         envName: "LOCAL",
//         apiUrl: "http://localhost:4000",
//         pageSize: 20,
//     };
// }

// let appEnv = getEnvironment();
export const CUR_ENV = process.env.REACT_APP_ENV;
export const BASE_URL = process.env.REACT_APP_API_URL;
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN === undefined ? "" : process.env.REACT_APP_AUTH_DOMAIN;
export const AUTH_CLIENTID = process.env.REACT_APP_AUTH_CLIENTID === undefined ? "" : process.env.REACT_APP_AUTH_CLIENTID;
export const AUTH_FREECLIENTID = process.env.REACT_APP_AUTH_FREE_CLIENTID === undefined ? "" : process.env.REACT_APP_AUTH_FREE_CLIENTID;


