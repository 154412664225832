import { NodeProps, useReactFlow, MarkerType, getIncomers, getOutgoers } from 'reactflow';

import { uuid, randomLabel } from '../utils';

// this hook implements the logic for clicking the button on a workflow edge
// on edge click: create a node in between the two nodes that are connected by the edge
function useNodeAddParent(id: NodeProps['id']) {
  const { setEdges, setNodes, getNode, getEdge, getNodes, getEdges } = useReactFlow();

  const handleEdgeClick = () => {
    const currentNode = getNode(id);
    if (!currentNode) {
      return;
    }

    const parentNodes = getIncomers(currentNode, getNodes(), getEdges());
    if (parentNodes.length <= 0) {
      return;
    }
    const parentNode = parentNodes[0];

    const childNodes = getOutgoers(parentNode, getNodes(), getEdges());
    if (childNodes.length <= 0) {
      return;
    }
    // create a unique id for newly added elements
    const insertNodeId = uuid();

    // this is the node object that will be added in between source and target node
    const insertNode = {
      id: insertNodeId,
      position: { x: parentNode.position.x, y: parentNode.position.y + 200 },
      data: { title: randomLabel(), isMenuOpen: false },
      type: 'step',
    };

    setNodes((nodes) =>
      nodes.concat([insertNode])
    );

    //Add Parent to new new Node edge
    parentNodes.forEach(parent => {
      const sourceEdge = {
        id: `${parent.id}=>${insertNodeId}`,
        source: parent.id,
        target: insertNodeId,
        type: 'step',
        style: { stroke: '#680001', strokeDasharray: 3 },
        markerEnd: {
          type: MarkerType.Arrow,
          color: '#680001',
          height: 20,
          width: 20,
        },
      };
      setEdges((edges) =>
        edges.concat([sourceEdge])
      );
    });

    //Add New node to child nodes edges
    childNodes.forEach(child => {
      const targetEdge = {
        id: `${insertNodeId}=>${child.id}`,
        source: insertNodeId,
        target: child.id,
        type: 'step',
        style: { stroke: '#680001', strokeDasharray: 3 },
        markerEnd: {
          type: MarkerType.Arrow,
          color: '#680001',
          height: 20,
          width: 20,
        },
      };
      setEdges((edges) =>
        edges.concat([targetEdge])
      );
    });

    //Remove edges
    parentNodes.forEach(parent => {
      childNodes.forEach(child => {
        let removeEdge = `${parent.id}=>${child.id}`;
        setEdges((edges) =>
          edges.filter((edge) => edge.id !== removeEdge));
      });
    });
  };

  return handleEdgeClick;
}

export default useNodeAddParent;
