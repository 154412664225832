import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const CreateTrg = (data: any) =>
    POST(`${BASE_URL}/trg/create`, data);

export const UpdateTrg = (data: any) =>
    POST(`${BASE_URL}/trg/update`, data);

export const GetTrgList = (params: any) =>
    GET(`${BASE_URL}/trg/list?` + params, null);

export const GetTrgDetail = (params: any) =>
    GET(`${BASE_URL}/trg/getdetail?` + params, null);

export const DeleteTrg = (id: any, data: any) =>
    DELETE(`${BASE_URL}/trg/` + id, data);

export const GetQuestionAnswer = (params: any) =>
    GET(`${BASE_URL}/trg/getquestionanswer?` + params, null);

export const CreateQusAnswer = (data: any) =>
    POST(`${BASE_URL}/trg/createqusanswer`, data);

export const CreateTrgMapping = (data: any) =>
    POST(`${BASE_URL}/trg/createmapping`, data);

export const GetAllTrgList = (params: any) =>
    GET(`${BASE_URL}/trg/alltrglist?` + params, null);

export const GetHiagramTrgList = (params: any) =>
    GET(`${BASE_URL}/trg/hiagramalltrglist?` + params, null);