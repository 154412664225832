import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import "./Dashboard.scss"
import profileImg from "../../assets/images/profile.png";
import peopleImg from "../../assets/images/people.png";
import briefcaseImg from "../../assets/images/briefcase.png";
import processImg from "../../assets/images/sound.png";
import imageOne from "../../assets/images/image-1.png";
import imageTwo from "../../assets/images/image-2.png";
import imageThree from "../../assets/images/image-3.png";
import imageFour from "../../assets/images/image-4.png";
import imageFive from "../../assets/images/image-5.png";
import moreMenu from "../../assets/images/more.png";
import editMenu from "../../assets/images/edit-2.png";
import archiveMenu from "../../assets/images/archive.png";
import trashRedIcon from '../../assets/icons/trash-red.svg';
import reinstateMenu from '../../assets/images/restore.png';
import userImage from '../../assets/icons/inviteUserImage.png'
import logouticon from "../../assets/icons/logout.svg";
import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControlLabel, FormGroup, Grid, IconButton, ListItemIcon, Menu, MenuItem, Paper, Skeleton, Slide, Switch, SwitchProps, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses, useTheme } from '@mui/material';
import { PreferencesContext } from '../../PreferenceContext';
import { ArchiveHiagram, GenerateHiagram, GetArchivedHiagramByClientID, GetHiagramList, ReinstateHiagram, UpdateHiagramName } from '../../services/HiagramService';
import { AlertType } from '../../shared/AlertType';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';
import { PAGE_SIZE } from '../../services/common/const';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

const imageTextColorMapping = [
    { image: imageOne, color: '#F58A80' },
    { image: imageTwo, color: '#4FA79B' },
    { image: imageThree, color: '#2C2449' },
    { image: imageFour, color: '#4D81B4' },
    { image: imageFive, color: '#A7679B' }
];

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        describeChild={true}
        {...props}
        classes={{ popper: className }}

        PopperProps={{
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, -30],
                    },
                }
            ],
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        minWidth: 300,
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(16),
        borderRadius: '10px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        padding: '15px'
    },
    [`& .${tooltipClasses.popper}`]: {
        marginTop: theme.spacing(1),
        transform: 'translate(80px, -290.333px)',
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const parameters: any = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
};

export default function Dashboard() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const { logout, isAuthenticated, user } = useAuth0();
    const [isShowLoder, setShowLoder] = useState(false);
    const [isShowbox, setShowbox] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [hiagramList, setHiagramList] = useState([]);
    const [anchorElHiagramMenu, setAnchorElHiagramMenu] = useState<null | HTMLElement>(null);
    const [anchorElArchivedHiagramMenu, setAnchorElArchivedHiagramMenu] = useState<null | HTMLElement>(null);
    const [currentRowData, setCurrentRowData] = useState('');
    const [currentDataIndex, setCurrentDataIndex] = useState(null);
    const [editCurrentDataIndex, setEditCurrentDataIndex] = useState(null);
    const [currentArchivedRowData, setCurrentArchivedRowData] = useState('');
    const [selectedHiagram, setSelectedHiagram] = useState(0);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [param, setParam] = useState(parameters);
    const [deletedHiagramData, setDeletedHiagramData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [toggleChecked, setToggleChecked] = useState(false);
    const [openWelcomeModal, setWelcomeModal] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [hiagramName, setHiagramName] = useState(userContext.hiagramName);
    const openHiagramMenu = Boolean(anchorElHiagramMenu);
    const openArchivedHiagramMenu = Boolean(anchorElArchivedHiagramMenu);
    const textFieldRef = useRef(null);
    const mainTheme = useTheme();


    let navigate = useNavigate();

    const gotoQuestion = (id: any) => {
        navigate("/question/" + id);
    };


    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : `${mainTheme.palette.primary.main}`,
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const signOut = () => {
        logout({ logoutParams: { returnTo: userContext.clientIsFree === true ? window.location.origin + "/free-login" : window.location.origin } });
    };

    async function generateHiagram(type: string) {
        setShowLoder(true);
        let defaultHiagraName = "Untitled hiagram";
        // hp code start
        let templateId: any;
        if (userContext.clientIsFree === true) {
            templateId = 3
        } else {
            templateId = 2
        }
        // ho code end
        try {
            const hiagram: any = await GenerateHiagram({
                // hp code start
                template_id: templateId,
                // ho code end
                client_uid: userContext.clientId,
                user_uid: userContext.userId,
                hiagram_name: defaultHiagraName,
                hiagram_type: type,
                row_managed_by: userContext.userId
            });
            if (hiagram.data != null) {
                updateUserContext({
                    ...userContext,
                    // hiagramId: hiagram.data,
                    // hp code start
                    tempUid: hiagram.data.TEMP_UID,
                    hiagramId: hiagram.data.UID,
                    // ho code end
                    hiagramName: defaultHiagraName,
                    hiagramType: type,
                    hiagramProgress: 0,
                    totalTeamProj: 0,
                    totalRole: 0,
                    teamProjId: 0,
                    teamProjName: "",
                    teamProjPurpose: "",
                    roleId: 0,
                    roleName: "",
                    activityId: 0,
                    activityName: "",
                    cessId: 0,
                    cessName: "",
                    stepId: 0,
                    stepName: "",
                    isProj: "false",
                    lastQuestionInfo: null,
                    isAlert: true,
                    alertMessage: hiagram.message,
                    alertType: AlertType.Success,
                    isEditAccess: true
                });

                if (type === "FULL") {
                    gotoQuestion(hiagram.data.UID);
                }
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setShowLoder(false);
    }

    function formateDate(date: any) {
        // const monthNames = ["January", "February", "March", "April", "May", "June",
        //     "July", "August", "September", "October", "November", "December"
        // ];

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        let curDate = new Date(date);
        let curDay = curDate.getDate();
        let curMonth = monthNames[curDate.getMonth()];
        let curYear = curDate.getFullYear();
        let curHour = curDate.getHours();
        let curMin = curDate.getMinutes();

        return (curMonth + " " + curDay + " " + curYear + " " + String(curHour).padStart(2, '0') + ":" + String(curMin).padStart(2, '0'));
    }

    function openMenu(event: React.MouseEvent<HTMLButtonElement>, data: any, index: any) {
        setAnchorElHiagramMenu(event.currentTarget);
        setCurrentRowData(data);
        setCurrentDataIndex(index);
    };

    function openArchivedMenu(event: React.MouseEvent<HTMLButtonElement>, data: any) {
        setAnchorElArchivedHiagramMenu(event.currentTarget);
        setCurrentArchivedRowData(data);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
        setSelectedHiagram(0);
    };

    const handleOpenDeleteModal = () => {
        setDeleteModal(true);
    };

    const hiagramMenuClose = (page: string, data: any, dataIndex: any) => {
        let curHiagramName: any = hiagramList[dataIndex];
        switch (page) {
            case "rename": {
                setEditCurrentDataIndex(dataIndex);
                setHiagramName(curHiagramName.hiagram_name);
                setAnchorElHiagramMenu(null);
                break;
            }

            case "edit": {
                setAnchorElHiagramMenu(null);
                updateUserContext({
                    ...userContext,
                    // hp code start
                    tempUid: +data.temp_uid,
                    // hp code end
                    hiagramId: +data.hiagram_uid,
                    hiagramName: data.hiagram_name,
                    hiagramType: data.hiagram_type,
                    hiagramProgress: data.progress === null ? 0 : data.progress,
                    isEditAccess: true
                });
                navigate("/resumehiagram");
                break;
            }

            case "archive": {
                // setAnchorElArchivedHiagramMenu(null);
                // reinstateHiagram(data.hiagram_uid, data.user_uid)
                setAnchorElHiagramMenu(null);
                setSelectedHiagram(+data.hiagram_uid);
                break;
            }
            case "reinstate": {
                setAnchorElArchivedHiagramMenu(null);
                reinstateHiagram(data.hiagram_uid, data.user_uid)
                break;
            }
            case "": {
                setAnchorElHiagramMenu(null);
                setAnchorElArchivedHiagramMenu(null);
                setEditCurrentDataIndex(null);
                setCurrentDataIndex(null);
                break;
            }
        }
        setAnchorElHiagramMenu(null);
    };

    async function archiveHiagram() {
        setBtnLoading(true);
        try {
            let requestData = {
                clientUid: userContext.clientId,
                userUid: userContext.userId,
                isArchived: true,
                dataManagedBy: userContext.userId
            }
            let data: any;
            data = await ArchiveHiagram(selectedHiagram, requestData);
            if (data !== null && data.data === true) {
                setBtnLoading(false);
                fetchHiagramList();
                handleCloseDeleteModal();
            }
        }
        catch (error: any) {
            setBtnLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchHiagramList() {
        try {
            setLoading(true);
            let params = new URLSearchParams();
            params.append("cuid", userContext.clientId.toString());
            params.append("uuid", userContext.userId.toString());
            const hiagrams: any = await GetHiagramList(params);
            if (hiagrams !== null && hiagrams.data !== null && hiagrams.data.data !== null) {
                setHiagramList(hiagrams.data.data);
                setLoading(false);
                fetchDeletedHigagramList();
            }

        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }

    }

    async function fetchDeletedHigagramList() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("uId", userContext.userId.toString());
            params.append("cId", userContext.clientId.toString())
            params.append("pageIndex", param.pageIndex);
            params.append("pageSize", param.pageSize);
            const result: any = await GetArchivedHiagramByClientID(params);

            if (result.data.data != null) {

                let deletedHiagramDataRows = result.data.data.data;
                deletedHiagramDataRows.forEach((data: any) => {
                    data.isloading = false;
                });
                setDeletedHiagramData(deletedHiagramDataRows);
                let totalRecords = result.data.data.total_data_count;
                setTotalRecords(totalRecords);
            }
        }
        catch (e: any) {
            //API error
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.message,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    async function reinstateHiagram(id: any, uId: any) {

        let data: any = deletedHiagramData;

        // data[index].isloading = true;
        setDeletedHiagramData(data);
        try {
            let requestData = {
                dataManagedBy: uId
            }
            const hiagram: any = await ReinstateHiagram(id, requestData);

            if (hiagram !== undefined && hiagram !== null && hiagram.data !== null && hiagram.data.data !== null && hiagram.data.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: hiagram.data.message,
                    alertType: AlertType.Success,
                });
                fetchDeletedHigagramList();
                fetchHiagramList();

            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Somthing went wrong!",
                    alertType: AlertType.Error,
                });
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        // data[index].isloading = false;
        setDeletedHiagramData(data);
    }

    const handleCloseWelcomeModal = () => {
        setWelcomeModal(false);
        updateUserContext({
            ...userContext,
            firstLogin: false
        });
    }

    const handleTextFieldChange = (event: any) => {
        setHiagramName(event.target.value);
    };

    const handleTextFieldKeyDown = (event: any, curHiagramId: any) => {
        if (event.key === 'Enter') {
            updateName(hiagramName, curHiagramId);
        }
    };


    const showArchiveHiagramData = (event: any) => {
        const isChecked = event.target.checked;
        setToggleChecked(isChecked);
    };

    const handleWelcomeModal = () => {
        setWelcomeModal(true);
    }

    const gotoHiagram = (data: any) => {
        updateUserContext({
            ...userContext,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjId: 0,
            teamProjName: "",
            teamProjPurpose: "",
            roleId: 0,
            roleName: "",
            activityId: 0,
            activityName: "",
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: "",
            isProj: "false",
            lastQuestionInfo: null,

            // hp code start
            tempUid: +data.temp_uid,
            hiagramId: +data.hiagram_uid,
            hiagramName: data.hiagram_name,
            hiagramType: data.hiagram_type,
            hiagramProgress: data.progress === null ? 0 : data.progress,
            isEditAccess: true
            // hp code end

        });
        navigate("/hiagram/" + data.hiagram_uid);
    };

    async function updateName(name: any, hiagramId: any) {
        try {
            let requestData = {
                hiagramUid: hiagramId,
                clientUid: userContext.clientId,
                userUid: userContext.userId,
                hiagramName: name,
                dataManagedBy: userContext.userId
            }
            let data: any;
            data = await UpdateHiagramName(requestData);
            if (data !== null && data.data === true) {
                updateUserContext({
                    ...userContext,
                    hiagramName: name
                });
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setEditCurrentDataIndex(null);
        fetchHiagramList();
    }

    const handleClickOutside = (event: any) => {
        let curEventData: any = textFieldRef.current
        if (curEventData && !curEventData.contains(event.target)) {
            setEditCurrentDataIndex(null);
        }
    };

    useEffect(() => {
        if (editCurrentDataIndex !== null) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editCurrentDataIndex]);

    useEffect(() => {
        fetchHiagramList();
        fetchDeletedHigagramList();
        if (userContext.firstLogin === true) {
            handleWelcomeModal()
        }
    }, []);

    useEffect(() => {
        if (selectedHiagram > 0) {
            handleOpenDeleteModal()
        }
    }, [selectedHiagram]);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isShowLoder}>
                <img src='/loader.gif' style={{ height: '250px', width: 'auto' }} alt='loading...'></img>
            </Backdrop>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Grid className="main-wrapper">
                    <Box className='container'>
                        <Paper
                            elevation={3}
                            sx={{ borderRadius: '10px' }}
                            className='wrapper'>
                            {isShowbox ?
                                <Grid className='main-dashbord'>

                                    {/* <Box className='all-hiagram-logout'>
                                        <LoadingButton
                                            className="button-sign-out"
                                            variant="contained"
                                            onClick={signOut}
                                            endIcon={
                                                <img loading="lazy" src={logouticon} alt="logo" />
                                            }
                                        >
                                            <Typography >Sign Out</Typography>
                                        </LoadingButton>
                                    </Box> */}

                                    <Box className="new-bar-main-box">
                                        <Box className="head-title">
                                            <Typography className='title-text'>
                                                Create New
                                            </Typography>
                                        </Box>

                                        <Box className="body-box">
                                            <HtmlTooltip placement="top-end" className='html-tool'
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit" className='tooltip-text'>Leaving your organization permanently or going on leave? Select this option.</Typography>
                                                    </React.Fragment>} >
                                                <Box className="body-option-box" onClick={() => {
                                                    generateHiagram("FULL");
                                                }}>
                                                    <Box className="img-box one"><img src={profileImg} alt='logo' /></Box>
                                                    <Box className="body-option-title"> <Typography className='option-title-text'>Job, project or <br/> team handover</Typography></Box>
                                                </Box>
                                            </HtmlTooltip>

                                            {/* <HtmlTooltip placement="top" className='html-tool'
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit" className='tooltip-text'>COMING SOON. If you are leaving ONE team or ONE project but are remaining employed within your organization, select this option. If you are leaving more than one team or project, create a
                                                            <span> Full job handover.</span></Typography>
                                                    </React.Fragment>
                                                }>

                                                <Box className="body-option-box">
                                                    <Box className="img-box two"><img src={peopleImg} alt='logo' /></Box>
                                                    <Box className="body-option-title"> <Typography className='option-title-text'>Team or Project</Typography></Box>
                                                </Box>
                                            </HtmlTooltip> */}
                                            <HtmlTooltip placement="top" className='html-tool'
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit" className='tooltip-text'>COMING SOON. A Role is a set of responsiblities for one team or project. If you are remaining within the same team/project but are handing over ONE particular role, select this option. If multiple roles are being handed over, select <span>Team/Project</span></Typography>
                                                    </React.Fragment>
                                                }>
                                                <Box className="body-option-box">
                                                    <Box className="img-box three"><img src={briefcaseImg} alt='logo' /></Box>
                                                    <Box className="body-option-title"> <Typography className='option-title-text'>One Role</Typography></Box>
                                                </Box>
                                            </HtmlTooltip>
                                            <HtmlTooltip placement="top" className='html-tool'
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit" className='tooltip-text'>COMING SOON. A process is a series of actions taken in order to achieve a particular outcome e.g. an approval flow. If you are simply teaching someone how a process at your organization works, select this option.</Typography>
                                                    </React.Fragment>
                                                }>
                                                <Box className="body-option-box">
                                                    <Box className="img-box four"><img src={processImg} alt='logo' /></Box>
                                                    <Box className="body-option-title"> <Typography className='option-title-text'>One Process</Typography></Box>
                                                </Box>
                                            </HtmlTooltip>
                                        </Box>
                                    </Box>

                                    <Box className="hiagram-main-box">
                                        <Box className="head-title">
                                            <Typography className='title-text'>
                                                Your hiagrams
                                            </Typography>
                                        </Box>

                                        <Grid container className="hiagram-body-box">

                                            {isLoading === true ? (
                                                _.times(6, (i) => (
                                                    <Skeleton variant="rounded" height={150} width={200} sx={{ mr: '15px' }} />
                                                ))) :

                                                hiagramList && hiagramList.map((row: any, index: any) => {
                                                    const { image, color } = imageTextColorMapping[index % 5];
                                                    return (
                                                        <Grid item sm={3} md={3} lg={2} className="hiagram-box" >
                                                            <Box className="img-box" onClick={() => { gotoHiagram(row) }}>
                                                                <IconButton
                                                                    disableRipple
                                                                    className="more-menu-img-box"
                                                                    aria-label="account of current user"
                                                                    aria-haspopup="true"
                                                                    color="inherit"
                                                                    // onClick={(event) => openMenu(event, row)}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        openMenu(event, row, index);
                                                                    }}
                                                                >
                                                                    <img src={moreMenu} alt='logo' />
                                                                </IconButton>
                                                                <img src={image} alt='logo' />
                                                                <Box className="hiagram-modified-data-box">
                                                                    <Typography className="modified-text" sx={{ color: color }}>Last updated</Typography>
                                                                    <Typography className="modified-text" sx={{ color: color }}>{formateDate(row.modified_date === null ? row.created_date : row.modified_date)}</Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box className="hiagram-list-title">
                                                                {editCurrentDataIndex === index ?
                                                                    <TextField
                                                                        id="hiagramName"
                                                                        className="hiagramname-change"
                                                                        value={hiagramName}
                                                                        variant="standard"
                                                                        inputProps={{ maxLength: 100 }}
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                        }}
                                                                        inputRef={textFieldRef}
                                                                        onChange={(event) => handleTextFieldChange(event)}
                                                                        onKeyDown={(event) => handleTextFieldKeyDown(event, row.hiagram_uid)}
                                                                    /> :
                                                                    <Tooltip title={row.hiagram_name} sx={{ cursor: 'pointer' }}>
                                                                        <Typography className='hiagram-title-text'>{row.hiagram_name}</Typography>
                                                                    </Tooltip>}
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })}
                                        </Grid>
                                    </Box>

                                    <Box className="toggle-switch-box">
                                        <FormGroup>
                                            <FormControlLabel
                                                className='toggle-label-text'
                                                color='primary'
                                                control={<IOSSwitch sx={{ ml: 2 }} />}
                                                label="Show archived hiagrams"
                                                labelPlacement="start"
                                                checked={toggleChecked}
                                                onChange={showArchiveHiagramData}
                                            />
                                        </FormGroup>
                                    </Box>

                                    {toggleChecked === true ?
                                        <Box className="archived-hiagram-main-box">
                                            <Box className="head-title">
                                                <Typography className='title-text'>
                                                    Archived hiagrams
                                                </Typography>
                                            </Box>

                                            <Grid container className="archived-hiagram-body-box">
                                                {isLoading === true ? (
                                                    _.times(6, (i) => (
                                                        <Skeleton variant="rounded" height={150} width={200} sx={{ mr: '15px' }} />
                                                    ))) :
                                                    deletedHiagramData && deletedHiagramData.map((row: any, index: any) => {
                                                        const { image, color } = imageTextColorMapping[index % 5];
                                                        return (
                                                            <Grid item sm={3} md={3} lg={2} className="archived-hiagram-box" >
                                                                <Box className="img-box">
                                                                    <IconButton
                                                                        disableRipple
                                                                        className="more-menu-img-box"
                                                                        aria-label="account of current user"
                                                                        aria-haspopup="true"
                                                                        color="inherit"
                                                                        onClick={(event) => openArchivedMenu(event, row)}>
                                                                        <img src={moreMenu} alt='logo' />
                                                                    </IconButton>
                                                                    <img src={image} alt='logo' />
                                                                    <Box className="archived-deleted-data-box">
                                                                        <Typography className="deleted-text" sx={{ color: color }}>Deleted date</Typography>
                                                                        <Typography className="deleted-text" sx={{ color: color }}>{formateDate(row.modified_date)}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="archived-hiagram-list-title">
                                                                    <Tooltip title={row.hiagram_name} sx={{ cursor: 'pointer' }}>
                                                                        <Typography className='archived-hiagram-title-text'>{row.hiagram_name}</Typography>
                                                                    </Tooltip>
                                                                </Box>
                                                            </Grid>
                                                        )
                                                    })}
                                            </Grid>
                                        </Box> : <></>}
                                </Grid> : <></>}
                        </Paper>
                    </Box >

                    <Box>
                        <Menu
                            className='popover'
                            anchorEl={anchorElHiagramMenu}
                            open={openHiagramMenu}
                            onClose={() => hiagramMenuClose("", "", "")}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    // top: '60px !important',
                                    right: 'auto',
                                    borderRadius: '10px !important',
                                    // overflow: 'visible',
                                    filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        right: 14,
                                        width: 100,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                    },
                                },
                            }}

                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Paper elevation={0} className='paper-menu-item-last'>
                                <MenuItem className='menu-item'
                                    // onClick={() => hiagramMenuClose("rename", currentRowData)}
                                    onClick={() => hiagramMenuClose("rename", currentRowData, currentDataIndex)}

                                >
                                    <ListItemIcon className='menu-icon-home'>
                                        <img loading="lazy" src={editMenu} alt="imprend" />
                                    </ListItemIcon>
                                    <Typography className='text-typography' >
                                        Rename
                                    </Typography>
                                </MenuItem>
                            </Paper>
                            <Paper elevation={0} className='paper-menu-item-last'>
                                <MenuItem className='menu-item'
                                    onClick={() => hiagramMenuClose("edit", currentRowData, currentDataIndex)}>
                                    <ListItemIcon className='menu-icon-home'>
                                        <img loading="lazy" src={editMenu} alt="imprend" />
                                    </ListItemIcon>
                                    <Typography className='text-typography' >
                                        Edit
                                    </Typography>
                                </MenuItem>
                            </Paper>
                            <Paper elevation={0} className='paper-menu-item-last'>
                                <MenuItem className='menu-item'
                                    onClick={() => hiagramMenuClose("archive", currentRowData, currentDataIndex)}>
                                    <ListItemIcon className='menu-icon-home'>
                                        <img loading="lazy" src={archiveMenu} alt="imprend" />
                                    </ListItemIcon>
                                    <Typography className='text-typography' >
                                        Archive
                                    </Typography>
                                </MenuItem>
                            </Paper>
                        </Menu>
                    </Box>

                    <Box>
                        <Menu
                            className='popover'
                            anchorEl={anchorElArchivedHiagramMenu}
                            open={openArchivedHiagramMenu}
                            onClose={() => hiagramMenuClose("", "", "")}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    // top: '60px !important',
                                    right: 'auto',
                                    borderRadius: '10px !important',
                                    // overflow: 'visible',
                                    filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        right: 14,
                                        width: 100,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                    },
                                },
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Paper elevation={0} className='paper-menu-item-last'>
                                <MenuItem className='menu-item'
                                    onClick={() => hiagramMenuClose("reinstate", currentArchivedRowData, currentDataIndex)}>
                                    <ListItemIcon className='menu-icon-home'>
                                        <img loading="lazy" src={reinstateMenu} alt="imprend" />
                                    </ListItemIcon>
                                    <Typography className='text-typography' >
                                        Reinstate
                                    </Typography>
                                </MenuItem>
                            </Paper>
                        </Menu>
                    </Box>

                    <Dialog
                        maxWidth="xs"
                        fullWidth={true}
                        open={openDeleteModal}
                        TransitionComponent={Transition}
                        onClose={handleCloseDeleteModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className='reset-dialog-box'
                    >
                        <Box className='img-box'>
                            <img loading="lazy" alt="imprend" className='delete-icon' src={trashRedIcon} />
                        </Box>
                        <DialogTitle className='dialog-title'>Are you sure?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" className='popup-text'>
                                Hiagram will be archived.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                onClick={() => archiveHiagram()}
                                className="delete-btn"
                                loading={isBtnLoading} >
                                Archive
                            </LoadingButton>
                            <Button
                                variant='contained'
                                onClick={handleCloseDeleteModal}
                                className="keep-btn"
                            >
                                Keep
                            </Button>

                        </DialogActions>
                    </Dialog>

                    <Dialog
                        maxWidth="xs"
                        fullWidth={true}
                        open={openWelcomeModal}
                        // TransitionComponent={Transition}
                        //onClose={handleCloseWelcomeModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className='welcome-dialog-box'
                    >
                        <Box className='welcome-img-box'>
                            <img loading="lazy" alt="imprend" className='welecome-icon' src={userImage} />
                        </Box>
                        <DialogTitle className='welcome-dialog-title'>Welcome {userContext.firstName}!</DialogTitle>
                        <DialogContent>
                            {userContext.userRoleId === '3' ?
                                <DialogContentText id="alert-dialog-description" className='welcome-popup-text'>
                                    Use the top right menu to set up your staff with access.
                                </DialogContentText>
                                :
                                (
                                    userContext.clientIsFree === true ?
                                        <DialogContentText id="alert-dialog-description" className='welcome-popup-text'>
                                            You can see all your hiagrams on your home page here.
                                        </DialogContentText>
                                        :
                                        <DialogContentText id="alert-dialog-description" className='welcome-popup-text'>
                                            You can see all the higrams you’ve made or have been shared with you on your home page.
                                        </DialogContentText>
                                )

                            }
                        </DialogContent>
                        <DialogActions className="welcome-dialog-action">
                            <Button
                                variant='contained'
                                onClick={handleCloseWelcomeModal}
                                className="welcome-close-btn"
                            >
                                Close
                            </Button>

                        </DialogActions>
                    </Dialog>

                </Grid >
            </Fade >
        </>
    )
}