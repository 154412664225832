import React from 'react';
import { Grid, Paper } from '@mui/material';
import { Link, } from 'react-router-dom';
import { Box } from '@mui/system';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./SharepartyAdd.scss";
import SherepartyMin from '../SharepartyMin';
import { Formik } from "formik";

const formInitialValues = {
    email: "",
    password: ""
};

export default function SherepartyAdd() {

    return (
        <>
            <Grid sx={{
                backgroundColor: "#E5E5E5",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                height: "100%",
                width: "100%",
            }}>

                <Paper
                    elevation={3}
                    className="paper-sharepartyadd"
                >
                    <Grid className="sharepartyadd-wrapper">
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues}
                            onSubmit={(values) => {
                            }}
                        >
                            {(props) => {
                                return (
                                    <React.Fragment>
                                        <Grid className="back-button">
                                            <Link
                                                className='backspace-Button'
                                                to={'/question/:id'}
                                            >
                                                <KeyboardBackspaceIcon className='icon-css-sharepartyadd' /><b>Back</b>
                                            </Link>
                                        </Grid>
                                        <Box
                                            className="box-container-sharepartyadd"
                                            sx={{ my: 2 }}
                                        >
                                            <Paper className="paper-container-sharepartyadd">
                                                <SherepartyMin></SherepartyMin>

                                            </Paper>
                                        </Box>

                                    </React.Fragment>
                                );
                            }}
                        </Formik>
                    </Grid>
                    <Grid className='img-wrapper-sharepartyadd' item  >
                    </Grid>

                </Paper>
            </Grid>
        </>
    );
}
