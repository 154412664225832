import { useContext, useState } from 'react';
import './MyProfile.scss';
import myAccountIcon from '../../assets/icons/myAccountIcon.svg';
import billinginfoIcon from '../../assets/icons/billingInfoIcon.svg';
import manageAccount from '../../assets/icons/manageAccountIcon.svg';
import { Box, Fade, Tab, Tabs, Typography } from '@mui/material';
import MyAccount from '../../Components/Profile/MyAccount/MyAccount';
import { TabContext } from '@mui/lab';
import BillingInformation from '../../Components/Profile/BillingInformation/BillingInformation';
import ManageAccount from '../../Components/Profile/ManageAccount/ManageAccount';
import { PreferencesContext } from '../../PreferenceContext';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <> {children}</>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


export default function MyProfile() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className='profile-wrapper'>
                {/* <Box className='container'> */}
                <Box className='main-box'>
                    <Box className='main-box-right'>
                        <Tabs
                            orientation="vertical"
                            //variant="scrollable"
                            indicatorColor="primary"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                        //TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
                        >
                            <Tab icon={<img loading="lazy" src={myAccountIcon} alt="imprend" />} sx={{ color: '#232323' }} iconPosition="start" label="My account" {...a11yProps(0)} />
                            {userContext.userRoleId === "3" && userContext.clientIsFree === false && <Tab icon={<img loading="lazy" src={billinginfoIcon} alt="imprend" />} sx={{ color: '#232323' }} iconPosition="start" label="Billing information" {...a11yProps(1)} />}
                            {userContext.userRoleId === "3" && <Tab icon={<img loading="lazy" src={manageAccount} alt="imprend" />} sx={{ color: '#232323' }} iconPosition="start" label="Manage account" {...a11yProps(2)} />}
                        </Tabs>

                    </Box>
                    <Box className='main-box-left'>
                        <TabPanel value={value} index={0}>
                            <MyAccount />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <BillingInformation />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <ManageAccount />
                        </TabPanel>
                    </Box>
                </Box>
                {/* </Box> */}
            </Box>
        </Fade>
    );
}