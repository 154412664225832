import React, { useContext } from 'react';
import logo from '../../assets/images/logo.png';
import { ReactComponent as SmsIcon } from '../../assets/icons/sms.svg';
import * as Yup from "yup";
import { Alert, Button, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { Form, Formik } from "formik";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import { Context } from '../../services/models/Context';
import { PreferencesContext } from '../../PreferenceContext';
import { ForgotPasswordEmail } from '../../services/AuthService';
import { LoadingButton } from '@mui/lab';
import { useAuth0 } from '@auth0/auth0-react';

const formInitialValues = {
    email: "",
};


export default function ForgotPassword() {
    const [loading, setLoading] = React.useState(false);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [showAlert, setAlertMessage] = React.useState({
        isVisible: false,
        type: "",
        message: "",
    });
    const { logout } = useAuth0();
    //let navigate = useNavigate();
    // const onClickReturn = () => {
    //     navigate("/");
    // };
    logout();
    const valildationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required.")
            .email("Enter a valid email."),
    });

    async function submitForgotPasswordForm(values: any, resetForm: any) {
        setLoading(true);
        let email = values.email;
        try {
            const user: any = await ForgotPasswordEmail({
                email: email
            });

            if (user) {
                setAlertMessage({
                    isVisible: true,
                    type: 'success',
                    message: "Email sent successfully, please check your inbox to find reset password link.",
                });
            }
            else {
                setAlertMessage({
                    isVisible: true,
                    type: 'error',
                    message: "User not found",

                });
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isVisible: true,
                    type: 'error',
                    message: error as string,
                });
            }
        }
        setTimeout(() => {
            setAlertMessage({
                isVisible: false,
                type: "",
                message: "",
            })
        }, 7000);
        resetForm(formInitialValues);
    }
    return (
        <>
            <Box sx={{
                backgroundColor: "#E5E5E5",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                height: "100%",
                width: "100%",
            }}>
                <Paper
                    elevation={3}
                    className="paper-singin"
                >
                    <Grid item className="signin-wrapper" xs={4}>
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues}
                            onSubmit={(values, { resetForm }) => {
                                submitForgotPasswordForm(values, resetForm);
                            }}
                            validationSchema={valildationSchema}
                        >
                            {(props) => {
                                return (
                                    <React.Fragment>

                                        <Grid className="forgot-password">
                                            <Link
                                                className='backspace-Button'
                                                to={'/sign-in'}
                                            >
                                                <KeyboardBackspaceIcon className='icon-css' /><b>Back</b>
                                            </Link>
                                        </Grid>

                                        <Box
                                            className="box-container forgot-password-wrapper"
                                        >
                                            <Paper className="paper-container-forgot paper-wrapper">
                                                {/* <Grid
                                                    marginTop={3}
                                                    justifyContent="center"
                                                > */}
                                                <a href="https://www.imprend.com"
                                                ><img loading="lazy" className='img-page'
                                                    src={logo} alt="logo" /></a>

                                                {/* </Grid> */}

                                                {showAlert.isVisible ? (
                                                    <Grid
                                                        marginBottom={1}
                                                    >
                                                        <Alert severity={showAlert.type === "success" ? 'success' : 'error'}>{showAlert.message}</Alert>
                                                    </Grid>) : <></>}
                                                <Form className='forgot-password-form'>
                                                    <Typography
                                                        sx={{ textAlign: "left" }}
                                                    >Email address</Typography>
                                                    <TextField
                                                        sx={{ marginTop: 1 }}
                                                        fullWidth
                                                        placeholder='Email address'
                                                        value={props.values.email}
                                                        onChange={props.handleChange("email")}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.email && props.touched.email
                                                                ? true
                                                                : false
                                                        }
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><SmsIcon stroke={'red'} /></InputAdornment>,
                                                        }}
                                                    >
                                                    </TextField>
                                                    {props.errors.email && props.touched.email ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.email}
                                                        </Typography> : <></>}
                                                    {/* <Button
                                                        sx={{ marginTop: 3 }}
                                                        fullWidth
                                                        variant="contained"
                                                        type="submit"
                                                    >Submit</Button> */}
                                                    <LoadingButton
                                                        color="primary"
                                                        sx={{ marginTop: 3 }}
                                                        loading={loading}
                                                        loadingPosition="start"
                                                        variant="contained"
                                                        type="submit"
                                                        startIcon={<></>}
                                                    >
                                                        Submit
                                                    </LoadingButton>
                                                </Form>
                                            </Paper>
                                        </Box>
                                    </React.Fragment>
                                );
                            }}
                        </Formik>
                    </Grid>
                    <Grid className='img-wrapper' item xs={6} >
                        {/* <img className="home-img" src={home} alt="home" /> */}
                    </Grid>

                </Paper>
            </Box>
        </>
    );
}