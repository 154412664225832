import React, { useCallback, useContext, useEffect, useState } from 'react';
import useLayout from "../../hooks/useLayout";
import ReactFlow, { ReactFlowProvider, Node, MiniMap, Background, ProOptions, Edge, Controls, useReactFlow, MarkerType, useNodesState, useEdgesState, getOutgoers, getIncomers, Panel, useViewport } from "reactflow";
import nodeTypes from '../NodeTypes';
import { PreferencesContext } from '../../PreferenceContext';
import { StepEdgeList, StepList } from '../../services/StepService';
import { useTheme } from '@mui/material';
//React Flow
const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

const fitViewOptions = {
    padding: 0.95,
    maxZoom: 1
};


const reactFlowStyle = {
    // background: 'green',
    height: "400px",
};

export default function ProcessFlowComponent(props: any) {
    const { setNodes, setEdges, getNodes, getEdges, getNode, zoomOut, setViewport, fitView, zoomIn, zoomTo } = useReactFlow();
    const mainTheme = useTheme();
    const { x, y, zoom } = useViewport();
    //useLayout();
    const defaultNodes: Node[] = [
        {
            id: '1',
            data: { label: 'Start', cessId: props.cessId },
            position: { x: 0, y: 0 },
            type: 'initial',
        },
        // {
        //     id: '2',
        //     data: { label: 'End' },
        //     position: { x: 0, y: 150 },
        //     type: 'initial',
        // },
    ];
    const defaultEdges: Edge[] = [
        // {
        //     id: '1=>2',
        //     source: '1',
        //     target: '2',
        //     type: 'step',
        //     style: { stroke: '#8C0003', strokeDasharray: 3 },
        //     markerEnd: {
        //         type: MarkerType.Arrow,
        //         color: '#8C0003',
        //         height: 20,
        //         width: 20,
        //     },
        // }
    ];

    useLayout();
    const { userContext, updateUserContext } = useContext(PreferencesContext);

    // const handleTransform = useCallback(() => {   
    //    // zoomTo(1);
    //     setViewport({ x: 600, y: 0, zoom: 1 }, { duration: 800 });
    //     //setViewport({ x: x, y: y, zoom: 1 }, { duration: 0 });
    //   }, [setViewport]);

    async function getAllSteps() {
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("cuid", props.cessId.toString());
        let allSteps: any = [];
        //let allNodes = getNodes();
        let listData: any = await StepList(params);
        if (listData !== null && listData.data !== null && listData.data.data !== null) {
            let allStepListData = listData.data.data as any[];
            if (allStepListData.length > 0) {
                let nodeCount = 0;
                allStepListData.forEach(stepData => {
                    const newStep = {
                        id: stepData.uid,
                        position: { x: stepData.position.split(",")[0], y: stepData.position.split(",")[1] },
                        type: 'step',
                        data: { title: stepData.name, description: stepData.description, isManuAccess: props.isEditStepAccess, isMenuOpen: false, cessId: props.cessId, stakeCount: stepData.stakeCount, hintCount: stepData.hintCount, assetCount: stepData.assetCount, docCount: stepData.docCount }
                    };
                    allSteps.push(newStep);
                    setNodes((nodes) =>
                        nodes.concat([newStep])
                    );
                });
                const endStep = {
                    id: '2',
                    data: { label: 'End' },
                    position: { x: 0, y: 150 },
                    type: 'initial',
                };
                setNodes((nodes) =>
                    nodes.concat([endStep])
                );
                // setTimeout(()=>{
                //     console.log("helo")
                //     handleTransform();
                // }, 2000);
            }

        }
        return allSteps;
    }

    async function getAllStepEdges() {
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("cuid", props.cessId.toString());
        let allEdges: any = [];
        //let allEdges = getEdges();
        let listData: any = await StepEdgeList(params);
        if (listData !== null && listData.data !== null && listData.data.data !== null) {
            let allStepEdgeListData = listData.data.data as any[];
            if (allStepEdgeListData.length > 0) {
                allStepEdgeListData.forEach(stepEdgeData => {
                    const newEdge = {
                        id: stepEdgeData.name,
                        source: stepEdgeData.name.split("=>")[0],
                        target: stepEdgeData.name.split("=>")[1],
                        data: { uid: stepEdgeData.uid },
                        type: 'step',
                        style: { stroke: mainTheme.palette.primary.main, strokeDasharray: 3 },
                        markerEnd: {
                            type: MarkerType.Arrow,
                            color: mainTheme.palette.primary.main,
                            height: 20,
                            width: 20,
                        },
                    };
                    allEdges.push(newEdge);
                    setEdges((edges) =>
                        edges.concat([newEdge])
                    );

                });
            }
        }
        getAllSteps();
        return allEdges;
    }

    async function fetchData() {
        let [s] = await Promise.all([
            //getAllSteps(),
            getAllStepEdges()
        ]);
    }

    useEffect(() => {
        fetchData();
    }, [props.isdataUpdate]);

    // every time our nodes change, we want to center the graph again
    // useEffect(() => {

    // }, [ setNodes,setEdges]);

    return (

        <ReactFlow
            defaultNodes={defaultNodes}
            defaultEdges={defaultEdges}
            //nodes={nodes}
            //edges={edges}
            // onNodesChange={onNodesChange}
            // onEdgesChange={onEdgesChange}
            proOptions={proOptions}
            fitView
            nodeTypes={nodeTypes}
            //panOnDrag={false}
            //fitViewOptions={fitViewOptions}
            //minZoom={0.2}
            nodesDraggable={false}
            nodesConnectable={false}
            zoomOnDoubleClick={false}
            style={reactFlowStyle}
        >
            {/* <MiniMap style={minimapStyle} zoomable pannable /> */}
            {/* <Background color='green' /> */}
            {/* <Controls /> */}
            {/* <Panel position="top-right">
                <button onClick={() => zoomIn({ duration: 800 })}>zoom in</button>
                <button onClick={() => zoomOut({ duration: 800 })}>zoom out</button>
                <button onClick={handleTransform}>pan to center(0,0,1)</button>
            </Panel> */}
        </ReactFlow>
    );
}