import React, { useEffect } from 'react';
import { Alert, Backdrop, Box, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import * as Yup from "yup";
import { Form, Formik } from 'formik';
import logo from '../../assets/images/logo.png';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import eye from '../../assets/icons/eye.svg';
import eyeslash from '../../assets/icons/eye-slash.svg';
import { LoadingButton } from '@mui/lab';
import { Link, useLocation } from 'react-router-dom';
import { getUserDetail, updateUserPassword } from '../../services/UserService';


const formInitialValues = {
    userUid: 0,
    clientUid: 0,
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    ShowConfirmPassword: false
};
export default function SetPassword() {
    const [formValues, setFormValues] = React.useState(formInitialValues);
    const [isDataLoading, setDataLoading] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showAlert, setAlertMessage] = React.useState({
        isVisible: false,
        type: "",
        message: "",
    });

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const passwordValidation: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/;

    const valildationSchema = Yup.object().shape({
        // newPassword: Yup.string().matches(passwordValidation, 'Must include an uppercase letter, a lowercase letter and a number')
        //     .required("New password is required.")
        // ,
        newPassword: Yup.string().required("New password is required."),
        confirmPassword: Yup.string()
            .required("Confirm password is required.")
            .oneOf([Yup.ref("newPassword"), null], "Confirm password doesn't match with the New password."),
    });

    const handleClickShowNewPassword = () => {
        setFormValues({
            ...formValues,
            showNewPassword: !formValues.showNewPassword,
        });
    }

    const handleClickShowConfirmPassword = () => {
        setFormValues({
            ...formValues,
            ShowConfirmPassword: !formValues.ShowConfirmPassword,
        });

    };
    let urlParams = useQuery();
    async function fetchuserDetail(forgot_password_token: any) {
        setDataLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("token", forgot_password_token.toString());
            const userDetail: any = await getUserDetail(params);
            if (userDetail !== null && userDetail.ok === true && userDetail.data !== null && userDetail.data.data !== null && userDetail.data.data !== undefined) {
                let data = userDetail.data.data;
                let userData = formInitialValues;
                userData.clientUid = data.CLIENT_UID;
                userData.userUid = data.UID;
                setFormValues(userData);
            } else {
                setAlertMessage({
                    isVisible: true,
                    type: "error",
                    message: userDetail.data.message,
                });
            }
            setDataLoading(false);
        } catch (error) {
            setDataLoading(false);
            if (error) {
                setAlertMessage({
                    isVisible: true,
                    type: "error",
                    message: error as string,
                });
            }
        }
    }

    async function submitSetPasswordForm(values: any, resetForm: any) {
        setLoading(true);
        try {
            const user: any = await updateUserPassword({
                clientUid: formValues.clientUid,
                userUid: formValues.userUid,
                password: values.newPassword,
            });
            if (user !== undefined && user !== null && user.data === true) {
                setAlertMessage({
                    isVisible: true,
                    type: "success",
                    message: "Password changed successfully.",
                });
                let userData = formInitialValues;
                userData.clientUid = 0;
                userData.userUid = 0;
                setFormValues(userData);
            } else {
                setAlertMessage({
                    isVisible: true,
                    type: "error",
                    message: "Something went wrong!",
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isVisible: true,
                    type: "error",
                    message: error as string,
                });
            }
        }
        setTimeout(() => {
            setAlertMessage({
                isVisible: false,
                type: "",
                message: "",
            })
        }, 7000);
        resetForm();
    }
    useEffect(() => {
        let forgot_password_token = urlParams.get("t");
        if (forgot_password_token !== null && forgot_password_token !== undefined) {
            fetchuserDetail(forgot_password_token)
        }

    }, []);
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isDataLoading}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img loading="lazy" alt="imprend" src='/loader.gif' style={{ height: '250px', width: 'auto' }} />
            </Backdrop>
            <Grid className="set-password-main-grid">
                <Paper elevation={3} className="set-password-paper">
                    <Grid className="set-password-wrapper">
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues}
                            onSubmit={(values, { resetForm }) => {
                                submitSetPasswordForm(values, resetForm);
                            }}
                            validationSchema={valildationSchema}
                        >
                            {(props) => {
                                return (
                                    <React.Fragment>
                                        <Box className="set-password-box-container" sx={{ my: 2 }}>
                                            <Paper className="set-password-paper-container">
                                                <Grid justifyContent="center">
                                                    <a href="https://www.imprend.com">
                                                        <img loading="lazy" className={showAlert.isVisible ? 'set-password-img-page-alert' : 'set-password-img-page'} src={logo} alt="logo" />
                                                    </a>
                                                </Grid>
                                                {showAlert.isVisible ? (
                                                    <Grid
                                                        marginBottom={1}
                                                    >
                                                        <Alert severity={showAlert.type === "success" ? 'success' : 'error'}>{showAlert.message}</Alert>
                                                    </Grid>) : <></>}

                                                <Form>
                                                    <Typography sx={{ textAlign: "left" }}>New password</Typography>
                                                    <TextField
                                                        id='newPassword'
                                                        sx={{ marginTop: 1 }}
                                                        fullWidth
                                                        placeholder='New password'
                                                        value={props.values.newPassword}
                                                        onChange={props.handleChange("newPassword")}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.newPassword && props.touched.newPassword
                                                                ? true
                                                                : false
                                                        }
                                                        type={formValues.showNewPassword ? "text" : "password"}
                                                        InputProps={{

                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowNewPassword}
                                                                        edge="end"
                                                                    >
                                                                        {formValues.showNewPassword ? (
                                                                            <img loading="lazy" className='set-password-eye-icon' src={eyeslash} alt="imprend" />
                                                                        ) : (
                                                                            <img loading="lazy" className='set-password-eye-icon' src={eye} alt="imprend" />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    >
                                                    </TextField>
                                                    {props.errors.newPassword && props.touched.newPassword ?
                                                        <Typography className="set-password-validation-message" >
                                                            {props.errors.newPassword}
                                                        </Typography> : <></>}

                                                    <Typography
                                                        sx={{ textAlign: "left", marginTop: 3, }}
                                                    >Confirm password</Typography>
                                                    <TextField
                                                        id='confirmPassword'
                                                        sx={{ marginTop: 1 }}
                                                        fullWidth
                                                        placeholder='Confirm password'
                                                        value={props.values.confirmPassword}
                                                        onChange={props.handleChange("confirmPassword")}
                                                        onBlur={props.handleBlur}
                                                        error={
                                                            props.errors.confirmPassword && props.touched.confirmPassword
                                                                ? true
                                                                : false
                                                        }
                                                        type={formValues.ShowConfirmPassword ? "text" : "password"}
                                                        InputProps={{

                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowConfirmPassword}
                                                                        edge="end"
                                                                    >
                                                                        {formValues.ShowConfirmPassword ? (
                                                                            <img loading="lazy" className='set-password-eye-icon' src={eyeslash} alt="imprend" />
                                                                        ) : (
                                                                            <img loading="lazy" className='set-password-eye-icon' src={eye} alt="imprend" />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    >
                                                    </TextField>
                                                    {props.errors.confirmPassword && props.touched.confirmPassword ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.confirmPassword}
                                                        </Typography> : <></>}
                                                    <LoadingButton
                                                        color="primary"
                                                        sx={{ marginTop: 3 }}
                                                        loading={loading}
                                                        fullWidth
                                                        loadingPosition="start"
                                                        variant="contained"
                                                        type="submit"
                                                        startIcon={<></>}
                                                    >
                                                        Submit
                                                    </LoadingButton>

                                                </Form>
                                            </Paper>
                                        </Box>
                                    </React.Fragment>
                                );
                            }}
                        </Formik>
                    </Grid>
                    <Grid className='set-password-img-wrapper' item  >
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
}