import React, { useContext, useEffect, useState } from 'react';
import './BillingHistory.scss';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Fade, Grid, IconButton, Paper, Skeleton, Step, StepConnector, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, stepConnectorClasses, styled, tableCellClasses } from '@mui/material';
import _ from 'lodash';
import { AlertType } from "../../../shared/AlertType";
import { AddPaymentMethod, DeleteCard, GetAllCountries, GetCustormerDetail } from '../../../services/PlansService';
import { PreferencesContext } from '../../../PreferenceContext';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import Cards, { Focused } from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { IMaskInput } from 'react-imask';
import { getDateFromTimestamp, getFlagSvgUrl, getSingleSelected, getValueFromName } from '../../../services/common/commonFunction';
import DownloadIcon from '@mui/icons-material/Download';
import getSymbolFromCurrency from 'currency-symbol-map';
import CreditCardIcon from '../../../assets/icons/credit_card_default.svg';
import ClearIcon from '@mui/icons-material/Clear';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#2C2449",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E6E8F0",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const Connector = styled(StepConnector)(({ theme }: any) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#680001',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#680001',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const CardNumberMask = React.forwardRef<HTMLElement, CustomProps>(
    function CardNumberMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="0000 0000 0000 00000000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const CardDateMask = React.forwardRef<HTMLElement, CustomProps>(
    function CardNumberMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00/00"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const CardCVCMask = React.forwardRef<HTMLElement, CustomProps>(
    function CardNumberMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const steps = ['Add billing address', 'Add card details'];

const billingInitialValues = {
    name: "",
    email: "",
    address: "",
    address2: "",
    city: "",
    zipcode: "",
    state: "",
    country: ""
};

const paymentCardInitialValues = {
    cardnumber: "",
    cardholdername: "",
    expiredate: "",
    cvc: ""
}

const countryInitialValues = {
    name: "",
    code: "",
}

const initData: any = [];

export default function BillingHistory() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = useState(false);
    const [cardDetaildata, setCardDetailData] = useState(initData);
    const [custmerInvoiceData, setCustmerInvoiceData] = useState(initData);
    const [custmerDetailData, setCustmerDetailData] = useState(initData);
    const [openRemovePaymentCardModal, setRemovePaymentCardModal] = useState(false);
    const [openPaymentModal, setPaymentModal] = useState(false);
    const [countryData, setCountryData] = useState([countryInitialValues]);
    const [activeStep, setActiveStep] = useState(0);
    const [billingData, setBillingData] = useState(billingInitialValues);
    const [pageChanged, setPageChanged] = useState(true);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [isProceedBtnLoading, setProceedBtnLoading] = useState(false);
    // const [isPdfButtonLoading, setPdfButtonLoading] = useState(false);

    const [focusField, setFocusField] = useState();

    const billingValidateSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email("Enter an valid email").required("Email is required"),
        address: Yup.string().required("Address is required"),
        city: Yup.string().required("City is required"),
        zipcode: Yup.string().required("Zip code is required"),
        state: Yup.string().required("State is required"),
        country: Yup.string().required("Country is required"),
    });

    const paymentCardValidateSchema = Yup.object().shape({
        cardnumber: Yup.string().required("Number is required"),
        cardholdername: Yup.string().required("Name is required"),
        expiredate: Yup.string().required("Date is required"),
        cvc: Yup.string().required("CVC number is required")
    });

    const handleOpenRemoveCardModal = () => {
        setRemovePaymentCardModal(true);
    };

    const handleOpenPaymentCardModal = () => {
        setPaymentModal(true);
        setActiveStep(0)
    }

    const closePaymentModal = () => {
        setPaymentModal(false);
        setBillingData(billingInitialValues);
    }
    const handleCloseRemoveCardModal = () => {
        setRemovePaymentCardModal(false);
    };

    async function removeCard() {
        setBtnLoading(true);
        try {
            let requestData = {
                paymentMethodId: cardDetaildata[0].id,
            }
            const result: any = await DeleteCard(requestData);
            if (result.data !== null) {
                fetchData();
                setRemovePaymentCardModal(false);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    };

    const handleNext = (values: any, resetForm: any) => {
        let billingPaymentData: any = {
            name: values.name,
            email: values.email,
            address: values.address,
            address2: values.address2,
            city: values.city,
            zipcode: values.zipcode,
            state: values.state,
            country: values.country,
        }
        setBillingData(billingPaymentData);
        setPageChanged(false);
        setActiveStep(1);
    };

    async function submitForm(values: any, resetForm: any) {
        setProceedBtnLoading(true);
        try {
            let requestData = {
                cardNumber: values.cardnumber,
                cardHolderName: values.cardholdername,
                expireYear: values.expiredate.split('/')[1],
                expireMonth: values.expiredate.split('/')[0],
                cvc: values.cvc,
                name: billingData.name,
                email: billingData.email,
                address: billingData.address,
                address2: billingData.address2,
                city: billingData.city,
                zipCode: billingData.zipcode,
                state: billingData.state,
                country: getValueFromName(billingData.country, countryData).code,
                customerId: custmerDetailData.id
            }

            let data: any = await AddPaymentMethod(requestData);
            setBillingData(billingInitialValues);
            resetForm();
            setPaymentModal(false);
            fetchData();
            setProceedBtnLoading(false);
        }
        catch (error: any) {
            setProceedBtnLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }

    };

    function handleFocus(target: any) {
        setFocusField(target.target.name);
    }

    async function fetchData() {
        setLoading(true)
        try {
            const result: any = await GetCustormerDetail(userContext.clientId);
            if (result !== undefined && result !== null && result.data !== null && result.data.data !== null) {
                let cardDetail = result.data.data.customerCardDetail;
                let customerInvoiceDetail = result.data.data.invoices;
                let customerDetail = result.data.data.customerDetail;
                setCardDetailData(cardDetail);
                setCustmerInvoiceData(customerInvoiceDetail);
                setCustmerDetailData(customerDetail);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setLoading(false)
    }

    async function fetchAllCountries() {
        try {
            const country: any = await GetAllCountries();

            if (country !== undefined && country !== null && country.data !== null && country.data.data !== null) {
                setCountryData(country.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    function downloadInvoice(url: any) {
        const link = document.createElement("a");
        //link.download = `download.txt`;
        link.href = url;
        link.click();
    }

    useEffect(() => {
        fetchData();
        fetchAllCountries();
    }, []);

    useEffect(() => {
        if (activeStep === 0) {
            setPageChanged(true);
        }
    }, [activeStep]);

    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box className='billing-history-wrapper'>
                    <Box className='billing-history-body-box'>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Due date</StyledTableCell>
                                        <StyledTableCell align="left">Payment date</StyledTableCell>
                                        <StyledTableCell align="center">Pricing plan</StyledTableCell>
                                        <StyledTableCell align="right">Amount</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {isLoading === true ? (
                                            _.times(4, (i) => (
                                                <TableRow key={i}>
                                                    <TableCell colSpan={5}>
                                                        <Skeleton variant="rounded" width='auto' height={25} />
                                                    </TableCell>
                                                </TableRow>
                                            ))) :
                                            custmerInvoiceData.length > 0 ? (custmerInvoiceData && custmerInvoiceData.map((row: any, index: any) => {
                                                return (
                                                    <StyledTableRow
                                                        sx={{ fontSize: 12, height: 50, cursor: 'pointer' }}
                                                        key={row.id}
                                                    >
                                                        <StyledTableCell align="left">{getDateFromTimestamp(row.due_date)}</StyledTableCell>
                                                        <StyledTableCell align="left">{getDateFromTimestamp(row.status_transitions.paid_at)}</StyledTableCell>
                                                        <StyledTableCell align="center"></StyledTableCell>
                                                        <StyledTableCell align="right">{getSymbolFromCurrency(row.currency)}{row.total}</StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <Tooltip title='Download invoice'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='download-btn'
                                                                    // startIcon={}
                                                                    onClick={() => downloadInvoice(row.invoice_pdf)}
                                                                >
                                                                    <DownloadIcon />
                                                                </Button>
                                                            </Tooltip>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            }
                                            )) : (
                                                <TableRow key={1}>
                                                    <TableCell colSpan={6} align='center'>
                                                        None added yet!
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                    </>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box className='billing-history-foot-box'>
                        <Box className='billing-history-foot-title-box'>
                            <Typography className='billing-history-foot-title'>Payment Method</Typography>
                        </Box>
                        <Box className='billing-history-foot-body-box'>
                            {isLoading === true ?
                                <Skeleton variant="rounded" width={250} height={25} /> :
                                cardDetaildata.length > 0 ?
                                    (
                                        cardDetaildata.map((row: any, index: any) =>
                                        (
                                            <Box className='card-detail-box' key={index}>
                                                <img loading="lazy" style={{ height: "30px" }} src={getFlagSvgUrl(row.card.brand, false) === undefined ? CreditCardIcon : getFlagSvgUrl(row.card.brand, false)} alt='imprend' />
                                                <Typography className='billing-history-foot-body'>**** **** **** {row.card.last4}</Typography>
                                            </Box>
                                        ))) :
                                    (
                                        <></>
                                    )
                            }
                        </Box>
                        <Box className='billing-history-foot-button-box'>
                            {isLoading === true ? <Skeleton variant="rounded" width={250} height={40} /> :
                                cardDetaildata.length > 0 ?
                                    <Button className='billing-history-foot-button' variant="contained" onClick={handleOpenRemoveCardModal}>Remove payment method</Button>
                                    :
                                    <Button className='billing-history-foot-button' variant="contained" onClick={handleOpenPaymentCardModal}>Add payment method</Button>
                            }

                        </Box>
                    </Box>
                </Box>
            </Fade>
            <Dialog
                open={openRemovePaymentCardModal}
                //onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this card?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='outlined'
                        onClick={() => removeCard()}
                        className="popup-btn"
                        loading={isBtnLoading}>
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={() => handleCloseRemoveCardModal()}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="md"
                fullWidth={true}
                scroll={"body"}
                open={openPaymentModal}
                // TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='for-billing-history-stepper-dialog-box'
            >
                <Box className='close-box'>
                    <IconButton
                        //sx={{ marginRight: "4px !important", marginTop: "7px !important" }}
                        className='close-btn'
                        aria-label="close"
                        onClick={closePaymentModal}
                    //style={{ marginTop: "-10px !important" }}
                    >
                        <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                    </IconButton>
                </Box>

                <Box className="for-billing-history-stepper-dialog-box-head">
                    <Typography className="for-billing-history-stepper-dialog-box-title">{activeStep === 0 ? "Billing address" : "Add card"}</Typography>
                    <Box className="for-billing-history-stepper-dialog-box-step">
                        <Stepper activeStep={activeStep} className="stepper" connector={<Connector />}>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                return (
                                    <Step key={label} {...stepProps} className="stepper-step">
                                        <StepLabel className="for-billing-history-stepper-step-label" {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                </Box>

                <DialogContent className='for-billing-history-payment-dialog-content'>

                    {activeStep === 0 ? (
                        <Formik
                            initialValues={billingData}
                            validationSchema={billingValidateSchema}
                            onSubmit={(values: any, { resetForm }) => {
                                if (pageChanged === true) {
                                    handleNext(values, resetForm);
                                }
                            }}
                        >
                            {(props: any) => {
                                return (
                                    <Form id="billing" className="for-billing-history-billing-form">
                                        <Box className="for-billing-history-billing-step-main-box">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="for-billing-history-billing-step-main-grid-textfield">
                                                    <Typography>Name</Typography>
                                                    <TextField
                                                        id='name'
                                                        fullWidth
                                                        type='text'
                                                        placeholder='Name'
                                                        onChange={props.handleChange.bind("name")}
                                                        value={props.values.name}
                                                        // onChange={(e: any) => {
                                                        //     props.setFieldValue(
                                                        //         `name`,
                                                        //         e.target.value !== null ? e.target.value : "",
                                                        //         false
                                                        //     );
                                                        // }}
                                                        // value={userData.fullName}
                                                        error={
                                                            props.errors.name && props.touched.name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.name && props.touched.name ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.name}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="for-billing-history-billing-step-main-grid-textfield">
                                                    <Typography>Email</Typography>
                                                    <TextField
                                                        id='email'
                                                        fullWidth
                                                        type='email'
                                                        placeholder='Email'
                                                        value={props.values.email}
                                                        onChange={props.handleChange.bind("email")}
                                                        error={
                                                            props.errors.email && props.touched.email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.email && props.touched.email ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.email}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="for-billing-history-billing-step-main-grid-textfield">
                                                    <Typography>Address</Typography>
                                                    <TextField
                                                        id='address'
                                                        fullWidth
                                                        type='text'
                                                        multiline={true}
                                                        rows={3}
                                                        placeholder='Address'
                                                        onChange={props.handleChange.bind("address")}
                                                        value={props.values.address}
                                                        error={
                                                            props.errors.address && props.touched.address
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.address && props.touched.address ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.address}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="for-billing-history-billing-step-main-grid-textfield">
                                                    <Typography>Address (line 2)</Typography>
                                                    <TextField
                                                        id='address2'
                                                        fullWidth
                                                        type='text'
                                                        multiline={true}
                                                        rows={3}
                                                        placeholder='Address (optional)'
                                                        onChange={props.handleChange.bind("address2")}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="for-billing-history-billing-step-main-grid-textfield">
                                                    <Typography>City</Typography>
                                                    <TextField
                                                        id='city'
                                                        fullWidth
                                                        type='text'
                                                        placeholder='City'
                                                        onChange={props.handleChange.bind("city")}
                                                        value={props.values.city}
                                                        error={
                                                            props.errors.city && props.touched.city
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.city && props.touched.city ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.city}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="for-billing-history-billing-step-main-grid-textfield">
                                                    <Typography>Zip/Postal code</Typography>
                                                    <TextField
                                                        id='zipcode'
                                                        fullWidth
                                                        type='text'
                                                        placeholder='Zip/postal code'
                                                        onChange={props.handleChange.bind("zipcode")}
                                                        value={props.values.zipcode}
                                                        error={
                                                            props.errors.zipcode && props.touched.zipcode
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.zipcode && props.touched.zipcode ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.zipcode}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="for-billing-history-billing-step-main-grid-textfield">
                                                    <Typography>State/Province</Typography>
                                                    <TextField
                                                        id='state'
                                                        fullWidth
                                                        type='text'
                                                        placeholder='State/province'
                                                        onChange={props.handleChange.bind("state")}
                                                        value={props.values.state}
                                                        error={
                                                            props.errors.state && props.touched.state
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {props.errors.state && props.touched.state ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.state}
                                                        </Typography> : <></>}
                                                </Grid>
                                                <Grid item xs={12} className="for-billing-history-billing-step-main-grid-textfield">
                                                    <Typography>Country</Typography>
                                                    <Autocomplete
                                                        id="country"
                                                        // sx={{ width: 300 }}
                                                        fullWidth
                                                        inputValue={props.values.country}
                                                        options={countryData}
                                                        value={getValueFromName(props.values.country, countryData)}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.name}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                <img
                                                                    loading="lazy"
                                                                    width="20"
                                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                    alt=""
                                                                />
                                                                {option.name} ({option.code})
                                                            </Box>
                                                        )}
                                                        onInputChange={(e, value, reason) => {
                                                            props.setFieldValue(
                                                                "country",
                                                                value,
                                                                true
                                                            );
                                                        }}
                                                        forcePopupIcon={false}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder="Country"
                                                                error={
                                                                    props.errors.country && props.touched.country
                                                                        ? true
                                                                        : false
                                                                }

                                                            />
                                                        )}
                                                    />
                                                    {props.errors.country && props.touched.country ?
                                                        <Typography className="validation-message" >
                                                            {props.errors.country}
                                                        </Typography> : <></>}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="for-billing-history-billing-button-box">
                                            <Button
                                                variant='contained'
                                                type="submit"
                                                className="for-billing-history-stepper-procced-button"
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    ) : (
                        <Formik
                            initialValues={paymentCardInitialValues}
                            validationSchema={paymentCardValidateSchema}
                            onSubmit={(values: any, { resetForm }) => {
                                submitForm(values, resetForm);
                            }}
                        >
                            {(props: any) => {
                                return (
                                    <Form id="payment" className="for-billing-history-payment-form">
                                        <Box className="for-billing-history-payment-step-main-box">
                                            <Box>
                                                <Cards
                                                    number={props.values.cardnumber === undefined ? "" : props.values.cardnumber}
                                                    expiry={props.values.expiredate === undefined ? "" : props.values.expiredate}
                                                    cvc={props.values.cvc === undefined ? "" : props.values.cvc}
                                                    name={props.values.cardholdername === undefined ? "" : props.values.cardholdername}
                                                    focused={focusField}
                                                />
                                            </Box>
                                            <Box className="for-billing-history-payment-step-detail-box">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} className="for-billing-history-payment-step-main-grid-textfield">
                                                        <Typography>Card number</Typography>
                                                        <TextField
                                                            id='cardnumber'
                                                            fullWidth
                                                            name="number"
                                                            //type='number'
                                                            // inputProps={{ pattern: '[\\d| ]{16,22}', maxLength: 16 }}
                                                            InputProps={{
                                                                inputComponent: CardNumberMask as any,
                                                            }}
                                                            placeholder='Card number'
                                                            className="for-billing-history-payment-step-grid-textfield"
                                                            onFocus={handleFocus}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `cardnumber`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                            }}
                                                            value={props.values.cardnumber}
                                                            error={
                                                                props.errors.cardnumber && props.touched.cardnumber
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {
                                                            props.errors.cardnumber && props.touched.cardnumber ?
                                                                <Typography className="validation-message" >
                                                                    {props.errors.cardnumber}
                                                                </Typography> : <></>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} className="for-billing-history-payment-step-main-grid-textfield">
                                                        <Typography>Card holder name</Typography>
                                                        <TextField
                                                            id='cardholdername'
                                                            fullWidth
                                                            type='text'
                                                            name="name"
                                                            inputProps={{ pattern: '[a-z A-Z-]+' }}
                                                            placeholder='Card holder name'
                                                            className="for-billing-history-payment-step-grid-textfield"
                                                            onFocus={handleFocus}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `cardholdername`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                            }}
                                                            value={props.values.cardholdername}
                                                            error={
                                                                props.errors.cardholdername && props.touched.cardholdername
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {props.errors.cardholdername && props.touched.cardholdername ?
                                                            <Typography className="validation-message" >
                                                                {props.errors.cardholdername}
                                                            </Typography> : <></>}
                                                    </Grid>

                                                    <Grid item xs={12} className="for-billing-history-payment-step-main-grid-textfield2">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} className="for-billing-history-payment-step-main-grid-textfield">
                                                                <Typography>Expire </Typography>
                                                                <TextField
                                                                    id='expiredate'
                                                                    fullWidth
                                                                    type='tel'
                                                                    name="expiry"
                                                                    InputProps={{
                                                                        inputComponent: CardDateMask as any,
                                                                    }}
                                                                    placeholder='MM/YY'
                                                                    className="for-billing-history-payment-step-grid-textfield"
                                                                    onFocus={handleFocus}
                                                                    onChange={(e: any) => {
                                                                        props.setFieldValue(
                                                                            `expiredate`,
                                                                            e.target.value !== null ? e.target.value : "",
                                                                            true
                                                                        );
                                                                    }}
                                                                    value={props.values.expiredate}
                                                                    error={
                                                                        props.errors.expiredate && props.touched.expiredate
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {props.errors.expiredate && props.touched.expiredate ?
                                                                    <Typography className="validation-message" >
                                                                        {props.errors.expiredate}
                                                                    </Typography> : <></>}
                                                            </Grid>

                                                            <Grid item xs={6} className="for-billing-history-payment-step-main-grid-textfield">
                                                                <Typography>CVC</Typography>
                                                                <TextField
                                                                    id='cvc'
                                                                    fullWidth
                                                                    name='cvc'
                                                                    InputProps={{
                                                                        inputComponent: CardCVCMask as any,
                                                                    }}
                                                                    placeholder='CVC'
                                                                    className="for-billing-history-payment-step-grid-textfield"
                                                                    onFocus={handleFocus}
                                                                    onChange={(e: any) => {
                                                                        props.setFieldValue(
                                                                            `cvc`,
                                                                            e.target.value !== null ? e.target.value : "",
                                                                            true
                                                                        );
                                                                    }}
                                                                    value={props.values.cvc}
                                                                    error={
                                                                        props.errors.expiredate && props.touched.cvc
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                {props.errors.cvc && props.touched.cvc ?
                                                                    <Typography className="validation-message" >
                                                                        {props.errors.cvc}
                                                                    </Typography> : <></>}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Box>
                                        </Box>

                                        <Box className="for-billing-history-payment-button-box">
                                            <Button
                                                id="back"
                                                variant='outlined'
                                                // onClick={() => {setActiveStep(0);
                                                //     setPageChanged(true);}}
                                                //onClick={handleBack}
                                                onClick={() => { setActiveStep(0); }}
                                                className="for-billing-history-stepper-procced-button"
                                                sx={{ mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                            <LoadingButton
                                                variant='contained'
                                                type="submit"
                                                className="for-billing-history-stepper-procced-button"
                                                loading={isProceedBtnLoading}
                                            >
                                                Proceed
                                            </LoadingButton>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}