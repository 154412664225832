import { NodeTypes } from 'reactflow';
import HiagramNode from './HiagramNode';
import InitialNode from './InitialNode';
import StepNode from './StepNode';


// two different node types are needed for our example: workflow and placeholder nodes
const nodeTypes: NodeTypes = {
  initial: InitialNode,
  step: StepNode,
  hiagram: HiagramNode
};

export default nodeTypes;
