import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const CreateHint = (data: any) =>
    POST(`${BASE_URL}/hint/create`, data);

export const UpdateHint = (data: any) =>
    POST(`${BASE_URL}/hint/update`, data);

export const GetHintList = (params: any) =>
    GET(`${BASE_URL}/hint/list?` + params, null);

export const GetHintDetail = (params: any) =>
    GET(`${BASE_URL}/hint/getdetail?` + params, null);

export const DeleteHint = (id: any, data: any) =>
    DELETE(`${BASE_URL}/hint/` + id, data);

export const GetQuestionAnswer = (params: any) =>
    GET(`${BASE_URL}/hint/getquestionanswer?` + params, null);

export const CreateQusAnswer = (data: any) =>
    POST(`${BASE_URL}/hint/createqusanswer`, data);

export const CreateHintMapping = (data: any) =>
    POST(`${BASE_URL}/hint/createmapping`, data);

export const GetAllHintList = (params: any) =>
    GET(`${BASE_URL}/hint/allhintlist?` + params, null);

export const GetHiagramHintList = (params: any) =>
    GET(`${BASE_URL}/hint/hiagramallhintlist?` + params, null);