import { Grid, DialogContentText, DialogActions, Skeleton, Button, TableContainer, Paper, Table, TableHead, TableRow, Tooltip, TableCell, TableBody, tableCellClasses, styled, Typography, Box, TooltipProps, tooltipClasses } from "@mui/material";
import * as React from "react";
import editIcon from '../../../assets/icons/edit-2.svg'
import trashIcon from '../../../assets/icons/trash.svg'
import Training from '../../../assets/icons/training.svg'
import Bookmark from '../../../assets/icons/bookmark.svg'
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import ItemTrgAddModel from './ItemTrgAddModel'
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { TransitionProps } from '@mui/material/transitions';
import "./ItemTrgAdd.scss";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { AlertType } from "../../../shared/AlertType";
import { PreferencesContext } from "../../../PreferenceContext";
import { CreateQusAnswer, DeleteTrg, GetQuestionAnswer, GetTrgList } from "../../../services/TrgService";
import { getNameSelected } from "../../../services/common/commonFunction";
import { GetNeedRatingList } from "../../../services/NeedRatingService";


interface ITrgDetail {
    id: any;
    trgMappingId: any;
    name: any;
    description: any;
    estimateDays: any;
    needRating: any;
}

const initTrgDetail: ITrgDetail = {
    id: 0,
    trgMappingId: 0,
    name: "",
    description: "",
    estimateDays: "",
    needRating: ""
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#EF4646",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: "#FFEFEF",
        backgroundColor: "#FAF9F6",
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
        paddingTop: "0px ! important",
        paddingBottom: "0px ! important"
    },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        describeChild={true}
        {...props}
        classes={{ popper: className }}
        slotProps={{ popper: { disablePortal: true } }}
        PopperProps={{
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['right', 'left', 'top', 'bottom'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundariesElement: 'window',
                    },
                },
            ],
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        minWidth: 500,
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: '10px',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    [`& .${tooltipClasses.popper}`]: {
        marginTop: theme.spacing(1),
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// export default function ItemTRGAAdd() {
const ItemTRGAdd = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isLoading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editMappingId, setEditMappingId] = useState(0);
    const [questionAnswerId, setQuestionAnswerId] = useState(0);
    const [trgList, setTrgList] = useState([initTrgDetail]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openTrgDeleteModal, setTrgDeleteModal] = useState(false);
    const [isBtnLoading, setBtnLoading] = useState(false);
    const [needRatingData, setNeedRatingData] = useState([]);

    useImperativeHandle(ref, () => ({
        submitClick() {
            SaveAnswer();
        },
        getData() {
            fetchNeedRating();
            fetchTrgList();
            fetchTrgQuestionAnswer();
        }
    }),);

    const handleClickOpen = () => {
        setEditId(0);
        setEditMappingId(0);
        setIsUpdate(false);
        setOpenDialog(true);
    };
    const handleClickOpenForEdit = (id: any, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setIsUpdate(true);
        setOpenDialog(true);
    };

    const handleClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialog(false);
    };

    const handleSubmitClose = () => {
        setOpenDialog(false);
        fetchTrgList();
        fetchTrgQuestionAnswer();
    };

    const handleOpenTrgDeleteModal = (id: number, mappingId: any) => {
        setEditId(id);
        setEditMappingId(mappingId);
        setTrgDeleteModal(true);
    };
    const handleCloseTrgDeleteModal = () => {
        setTrgDeleteModal(false);
    };

    async function fetchNeedRating() {
        try {
            const needRatingGetData: any = await GetNeedRatingList();
            if (needRatingGetData !== undefined && needRatingGetData !== null && needRatingGetData.data !== null && needRatingGetData.data.data !== null && needRatingGetData.data.data.data !== null) {
                setNeedRatingData(needRatingGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }


    async function fetchTrgList() {
        try {
            setLoading(true);
            let appendType = "";
            let appendUID = 0;
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            if (+param.curQuestion.number === 12) {
                appendType = "ROLE";
                appendUID = userContext.roleId;

            } else if (+param.curQuestion.number === 21) {
                appendType = "ACTIVITY";
                appendUID = userContext.activityId;

            } else if (+param.curQuestion.number === 27) {
                appendType = "CESS";
                appendUID = userContext.cessId;

            } else if (+param.curQuestion.number === 0) {
                appendType = "NONE";
                appendUID = 0;
            }
            if (param.curQuestion.hiagram !== undefined) {
                appendType = param.curQuestion.hiagram.type;
                appendUID = param.curQuestion.hiagram.typeId;
            }
            params.append("appendType", appendType.toString());
            params.append("appendUid", appendUID.toString());
            const actuionres: any = await GetTrgList(params);
            if (actuionres !== null && actuionres.data !== null && actuionres.data.data !== null) {
                let data = actuionres.data.data;
                let list: any[] = [];
                data.forEach((element: any) => {
                    let item: ITrgDetail = {
                        id: element.uid,
                        name: element.name,
                        description: element.description,
                        estimateDays: element.estimate_days,
                        needRating: element.trgMapping === undefined ? 0 : element.trgMapping[0].need_rating,
                        trgMappingId: element.trgMapping === undefined ? 0 : element.trgMapping[0].uid,
                    }
                    list.push(item);
                });
                setTrgList(list);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchTrgQuestionAnswer() {
        try {
            setLoading(true);
            let projUID = 0;
            let teamUID = 0;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            params.append("tuid", teamUID.toString());
            params.append("puid", projUID.toString());
            params.append("ruid", userContext.roleId.toString());
            params.append("auid", userContext.activityId.toString());
            params.append("cuid", userContext.cessId.toString());
            const questionAnswer: any = await GetQuestionAnswer(params);
            if (questionAnswer !== null && questionAnswer.data !== null && questionAnswer.data.data !== null) {
                let data = questionAnswer.data.data;
                setQuestionAnswerId(data.uid);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function deleteTrg() {
        setBtnLoading(true);
        try {
            const trg: any = await DeleteTrg(editId, {
                huid: userContext.hiagramId,
                mappingUid: editMappingId,
                is_deleted: true,
                row_managed_by: userContext.userId
            });
            if (trg.data === true) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: trg.message,
                    alertType: AlertType.Success,
                    isItemCountUpdated: true
                });
                setTrgDeleteModal(false);
                fetchTrgList();
                fetchTrgQuestionAnswer();
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setBtnLoading(false);
    }

    async function SaveAnswer() {
        param.startLoading();
        if (userContext.isEditAccess === true) {
            let projUID = 0;
            let teamUID = 0;
            let roleUID = userContext.roleId;
            let cessUID = userContext.cessId;
            let activityUID = userContext.activityId;
            if (userContext.isProj.toString() === "false") {
                teamUID = userContext.teamProjId;
            } else if (userContext.isProj.toString() === "true") {
                projUID = userContext.teamProjId;
            }
            try {
                let requestData = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: questionAnswerId,
                    teamUid: teamUID,
                    projUid: projUID,
                    roleUid: roleUID,
                    activityUid: activityUID,
                    cessUid: cessUID,
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }

                let data: any;
                data = await CreateQusAnswer(requestData);
                if (data !== null && data.data === true) {
                    param.changeNextQuestion();
                }
            }
            catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            param.changeNextQuestion();
        }
    }

    // useEffect(() => {
    //     fetchTrgList();
    // }, []);

    useEffect(() => {
    }, [trgList]);


    return (
        <Grid container className="trg-list-grid" >
            {param.QuickView === false ?
                <Grid item xs={12} className="trg-icon-group" ><img loading="lazy" alt="imprend" className='icon-trg' src={Training} />
                    <Typography className="trg-icon-name">Skills</Typography>
                </Grid> : <Grid item xs={12} className="heading-container-grid-trg">
                    <Grid className="header-css" item xs={12}>
                        <img loading="lazy" alt="imprend" className='header-icon' src={Training} />
                        <Typography className="icon-text">Skills</Typography>
                    </Grid>
                    {/* <Grid item xs={1} className="clear-icon-grid">
                        <Button
                            disableRipple
                            className="btn-cancel"
                            onClick={param.onClose}
                        >
                            <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                        </Button>
                    </Grid> */}
                </Grid>}
            <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Skill</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Description</StyledTableCell>
                            {/* <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Time needed</StyledTableCell> */}
                            {+param.curQuestion.number === 0 ? <></> : <StyledTableCell align="left" sx={{ width: '20%', fontSize: 12 }}>Must have skill?</StyledTableCell>}
                            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell align="center" sx={{ width: '20%', fontSize: 12 }}>Action</StyledTableCell> : <></>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {isLoading === true ? (
                                _.times(4, (i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={6}>
                                            <Skeleton variant="rounded" width='auto' height={25} />
                                        </TableCell>
                                    </TableRow>
                                ))) : trgList.filter((x) => x.id > 0).length > 0 ? (trgList && trgList.map((row: any, index: any) => {
                                    const placement = index < 3 ? 'bottom' : 'top';
                                    return (
                                        <HtmlTooltip
                                            key={index}
                                            placement={placement}
                                            title={
                                                <React.Fragment>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.name)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Skill:</b> {row.name}</Typography>
                                                    <Typography onClick={() => navigator.clipboard.writeText(row.description)} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Description:</b> {row.description}</Typography>
                                                    {+param.curQuestion.number > 0 ? <Typography onClick={() => navigator.clipboard.writeText(getNameSelected(row.needRating, needRatingData))} sx={{ p: 1, cursor: 'copy', fontFamily: 'Roboto' }}><b style={{ fontFamily: 'RoseauSlab-Light' }}>Must have skill?:</b> {getNameSelected(row.needRating, needRatingData)}</Typography> : <></>}
                                                </React.Fragment>
                                            }
                                        >
                                            <StyledTableRow
                                                sx={{ fontSize: 12, height: 50, cursor: 'pointer' }}
                                                key={row.id}>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.name}</StyledTableCell>
                                                <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">{row.description}</StyledTableCell>
                                                {/* <StyledTableCell align="left">{row.estimateDays}</StyledTableCell> */}
                                                {+param.curQuestion.number === 0 ? <></> : <StyledTableCell
                                                    align="left"
                                                    className="text-show-less">
                                                    {getNameSelected(
                                                        row.needRating,
                                                        needRatingData
                                                    )}</StyledTableCell>}
                                                {+param.curQuestion.number > 0 && userContext.isEditAccess === true ? <StyledTableCell
                                                    sx={{ minWidth: "115px" }}>
                                                    <Grid container spacing={2} className='btn-grid'>
                                                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                                                            <Tooltip title='Edit'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='edit-btn'
                                                                    onClick={() => handleClickOpenForEdit(row.id, row.trgMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='edit-btn-logo' src={editIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ textAlign: "start", paddingLeft: "24px" }}>
                                                            <Tooltip title='Delete'>
                                                                <Button
                                                                    variant='contained'
                                                                    className='delete-btn'
                                                                    onClick={() => handleOpenTrgDeleteModal(row.id, row.trgMappingId)}
                                                                >
                                                                    <img loading="lazy" alt="imprend" className='delete-btn-logo' src={trashIcon} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell> : <></>}
                                            </StyledTableRow>
                                        </HtmlTooltip>
                                    )
                                })) : (
                                <TableRow key={1}>
                                    <TableCell colSpan={6} align='center'>
                                        None added yet!
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    </TableBody>
                </Table>

            </TableContainer>
            {/* {+param.curQuestion.number === 0 || param.QuickView === true ? */}
            {+param.curQuestion.number > 0 && userContext.isEditAccess === true ?
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Button className="trg-btn"
                        onClick={handleClickOpen}
                        startIcon={<AddIcon sx={{ color: "#EF4646" }} />}
                        endIcon={<img loading="lazy" alt="imprend" className='icon-trg' src={Bookmark} />}
                    >
                        Add Skills
                    </Button>
                </Grid> : <></>}
            <Dialog
                maxWidth={isUpdate === true ? "md" : "xl"}
                fullWidth={true}
                open={openDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ paddingBottom: "50px" }}>
                    <ItemTrgAddModel
                        QuickView={param.QuickView}
                        isOpen={openDialog}
                        isUpdate={isUpdate}
                        id={editId}
                        mappingId={editMappingId}
                        onClose={handleClose}
                        onSubmitClose={handleSubmitClose}
                        curQuestion={param.curQuestion}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openTrgDeleteModal}
                onClose={handleCloseTrgDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure you want to delete this Skills?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => deleteTrg()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseTrgDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {param.QuickView === true ?
                <Box className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={param.onClose}
                    >
                        <ClearIcon className="close-btn-icon" />
                    </Button>
                </Box> : <></>}
        </Grid >
    );
});
export default ItemTRGAdd;
