import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ForgotPassword from "../pages/Account/ForgotPassword";
import PreSignIn from "../pages/Account/PreSignIn";
import SignIn from "../pages/Account/SignIn";
import SignUp from "../pages/Account/SignUp";
import Client from "../pages/Client/Client";
import User from "../pages/User/User";
import VerifyEmail from "../pages/Account/VerifyEmail";
// import ResetPassword from "../pages/Account/ResetPassword";
// import SetPassword from "../pages/Account/SetPassword";
// import ForgotPassword from "../pages/Account/ForgotPassword";
//import NotFound from "../pages/Account/NotFound";
//import ClientList from "../pages/client/ClientList";
import Dashboard from "../pages/Dashboard/Dashboard";

import Question from "../pages/Question/Question";
import SharepartyAdd from "../Components/AnswerType/SharepartyAdd/SharepartyAdd";
import MyProfile from "../pages/MyProfile/MyProfile";
import ResumeHiagram from "../pages/ResumeHiagram/ResumeHiagram";
import PostLogin from "./PostLogin";
import PreLogin from "./PreLogin";
import Hiagram from "../pages/Hiagram/Hiagram";
import { PreferencesContext } from "../PreferenceContext";
import SetupAccount from "../pages/Account/SetupAccount";
import SetPassword from "../pages/Account/SetPassword";
import Plans from "../pages/Subscription/Plans";
import InvitedUser from "../pages/InvitedUser/InvitedUser";
import DeletedHiagram from "../pages/DeletedHiagram/DeletedHiagram";
import AllHiagram from "../pages/AllHiagram/AllHiagram";
import FreeUserList from "../pages/FreeUserList/FreeUserList";

export default function RootNavigation({ colorScheme }: { colorScheme: any }) {
  return (
    <RootNavigator colorScheme={colorScheme} />
  );
}

function AuthRoute(props: any) {
  const { userContext, updateUserContext } = React.useContext(PreferencesContext);
  if (userContext.userId > 0) {
    return props.children;
  } else {
    return <Navigate to="/" replace />;
  }

}


function RootNavigator({ colorScheme }: { colorScheme: any }) {
  return (
    <>

      {/* <Routes>
        <Route path='/*' element={<PreContainer colorScheme={colorScheme} />} />
      </Routes>
      <Routes>
        <Route path='/*' element={<PostContainer colorScheme={colorScheme} />} />
      </Routes> */}
      <Routes>
        <Route element={<PreLogin />}>
          <Route element={<PreSignIn />} path="/" />
          <Route element={<PreSignIn />} path="/free" />
          <Route element={<PreSignIn />} path="/free-login" />
          <Route element={<SignIn />} path="/sign-in" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<SignUp />} path="/sign-up" />
          <Route element={<SetupAccount />} path="/set-up-account" />
          <Route element={<SetPassword />} path="/set-password" />
          <Route element={<Plans />} path="/plans" />
          <Route element={<VerifyEmail />} path="/verify-email" />
          <Route element={<Hiagram />} path="/hiagram/share" />
        </Route>
        <Route element={<PostLogin />}>
          <Route element={
            <AuthRoute>
              <Dashboard />
            </AuthRoute>
          } path="/dashboard" />
          <Route element={
            <AuthRoute>
              <ResumeHiagram />
            </AuthRoute>
          } path="/resumehiagram" />
          <Route element={
            <AuthRoute>
              <SharepartyAdd />
            </AuthRoute>
          } path="/sharepartyAdd" />
          <Route element={
            <AuthRoute>
              <MyProfile />
            </AuthRoute>
          } path="/myprofile" />
          <Route element={
            <AuthRoute>
              <AllHiagram />
            </AuthRoute>
          } path="/all-hiagram" />
          <Route element={
            <AuthRoute>
              <Hiagram />
            </AuthRoute>
          } path="/hiagram/:id" />
          <Route element={
            <AuthRoute>
              <Question />
            </AuthRoute>
          } path="/question/:id" />
          <Route element={
            <AuthRoute>
              <Client />
            </AuthRoute>
          } path="/client" />
          <Route element={
            <AuthRoute>
              <User />
            </AuthRoute>
          } path="/user" />
          <Route element={
            <AuthRoute>
              <InvitedUser />
            </AuthRoute>
          } path="/inviteduser" />
          <Route element={
            <AuthRoute>
              <DeletedHiagram />
            </AuthRoute>
          } path="/archive-hiagram" />

          <Route element={
            <AuthRoute>
            <FreeUserList />
          </AuthRoute>
          } path="view-user-hiagram/:id/:fullName" />
        </Route>

        <Route path='*' element={<Navigate to="/" replace />} />
      </Routes>
      {/* <Routes>
          <Route path='/*' element={<PreContainer colorScheme={colorScheme} />} /> 
          <Route  path='/*' element={<PostContainer colorScheme={colorScheme} />} />
      </Routes> */}
    </ >
  );
}

// function PreContainer({ colorScheme }: { colorScheme: any }) {
//   return (
//     <>
//       <Route element={<PreLogin />}>
//         <Route element={<SignIn />} path="/" />
//         <Route element={<ForgotPassword />} path="/forgot-password" />
//         <Route element={<SignUp />} path="/sign-up" />
//       </Route>
//     </>
//   );
// }

// function PostContainer({ colorScheme }: { colorScheme: any }) {
//   return (
//     <>
//       <Route element={<PostLogin />}>
//         <Route element={<Dashboard />} path="/dashboard" />
//       </Route>
//     </>
//   );
// }