import React, { useEffect, useState } from 'react';
import { createTheme, CssBaseline,  ThemeProvider,} from '@mui/material';
import { Context } from './services/models/Context';
import { getStorage, setStorage } from './services/common/storage';
import {useNavigate  } from "react-router-dom";
import { PreferencesContext } from './PreferenceContext';
import RootNavigation from './navigations/RootNavigation';
import "./theme/variables.scss";
import "./theme/style.scss";
import Alerts from './Components/Alerts';

//  const sass = require('sass');
//  const result = sass.compile('./theme/style.scss');

export default function App(props:any) {

  const [mode, setMode] = useState<"light" | "dark" | undefined>(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"
  );
  const [userContext, setUserContext] = React.useState<Context>(getStorage());

  let navigate = useNavigate();

  const theme = createTheme({
    typography: {
      fontFamily: [
         'RoseauSlab-Light',
         'sans-serif',
      ].join(','),
    },
    palette: {
      primary: { main: '#680001' ,light:'#FAF9F6'}
      , secondary: { main: '#091C69', light:'#E6E8F0' }
      , action: { hover: '#8c00040d' },
    },
    components: {
    // MuiPaper:{
    //   styleOverrides: { 
    //     root: { 
    //     borderRadius:'10px'
    //   } 
    // }
    // },
      MuiButton: { 
        styleOverrides: { 
          root: { textTransform: 'none', 
          fontSize:'14px', fontWeight: 600, 
          padding:'16px 40px',
          borderRadius:'8px'
        } 
        } 
      }
    }
  });

  const updateUserContext = React.useCallback((ctx:any) => {
    setStorage(ctx);
    return (setUserContext(ctx));
  }, [userContext]);

  const preferences = React.useMemo(
    () => ({
      updateUserContext, userContext,
    }), [userContext]
  );

  const changeMode = (newMode: any) => {
    setMode(newMode);
  }

  useEffect(() => {
    const checkIfLoggedIn = () => {
      
      const modeMe = (e: any) => {
        setMode(e.matches ? "dark" : "light");
      }

      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', modeMe);

      let value = getStorage();

      if (value && value.token && value.token.length > 0) {
        if (value.userId > 0) {
          navigate("/dashboard");
        }
        else {
          navigate("/");
        }
      }
      else {
        navigate("/");
      }

    }

    //checkIfLoggedIn();
  }, []);



  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <PreferencesContext.Provider value={preferences}>
      {userContext &&
          <>
          <Alerts isOpen={userContext.isAlert} displayMessage={userContext.alertMessage} type={userContext.alertType}></Alerts>
          {/* <Alerts isOpen={userContext.isAlert} displayMessage="Hello" type="Success"></Alerts> */}
          </>
        }
      <RootNavigation colorScheme={(userContext.isThemeDark ? 'dark' : 'light')} />
    </PreferencesContext.Provider>
  </ThemeProvider>
  );
}


