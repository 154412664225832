import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetQuestionById = (id: any, mvid: any) =>
    GET(`${BASE_URL}/question/` + id + "/" + mvid, null);

export const GetLastAttemptedQuestion = (params: any) =>
    GET(`${BASE_URL}/question/getlastattemptedquestion?` + params, null);

export const GetQuestionAnswer = (params: any) =>
    GET(`${BASE_URL}/question/getquestionanswer?` + params, null);

export const UpdateQuestionAnswer = (data: any) =>
    POST(`${BASE_URL}/question/updatequestionanswer`, data);