import { GET, POST, DELETE } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetTypeList = () =>
    GET(`${BASE_URL}/event/types?`, null);

export const GetSchedulesList = () =>
    GET(`${BASE_URL}/event/schedules?`, null);

export const GetDayOfWeekList = () =>
    GET(`${BASE_URL}/event/dayofweek?`, null);

export const CreateEvent = (data: any) =>
    POST(`${BASE_URL}/event/create`, data);

export const UpdateEvent = (data: any) =>
    POST(`${BASE_URL}/event/update`, data);

export const GetEventList = (params: any) =>
    GET(`${BASE_URL}/event/list?` + params, null);

export const GetEventDetail = (params: any) =>
    GET(`${BASE_URL}/event/getdetail?` + params, null);

export const DeleteEvent = (id: any, data: any) =>
    DELETE(`${BASE_URL}/event/` + id, data);

export const GetQuestionAnswer = (params: any) =>
    GET(`${BASE_URL}/event/getquestionanswer?` + params, null);

export const CreateQusAnswer = (data: any) =>
    POST(`${BASE_URL}/event/createqusanswer`, data);

export const CreateEventMapping = (data: any) =>
    POST(`${BASE_URL}/event/createmapping`, data);

export const GetAllEventList = (params: any) =>
    GET(`${BASE_URL}/event/alleventlist?` + params, null);
    
export const GetHiagramEventList = (params: any) =>
    GET(`${BASE_URL}/event/hiagramalleventlist?` + params, null);